import vi from 'date-fns/locale/vi';
import * as firebase from 'firebase';
import md5 from 'md5';
import React from 'react';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Modal } from 'semantic-ui-react';
import Logofooter from '../../src/image/hoc-phong-thuy-tuong-minh_1.jpg';
import '../App.css';
import globalUtils from '../globalUntils.js';

const mdfive = md5('HuyenKhongTamNguyenFengShuiWebSiteHitekTuongMinh');
registerLocale('vi', vi);

class Footer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="to-hide-print">
        <footer className="to-hide-print">
          <div className="coppyright to-hide-print" style={{ minWidth: '1024px' }}>
            <div className="contentner">
              <div className="contentner-int">
                <div className="logo-footer">
                  <div className="ads-show">
                    <a href="/H%E1%BB%8Dc%20phong%20thu%E1%BB%B7%20%E1%BB%9F%20%C4%91%C3%A2u%20t%E1%BB%91t" target="_self">
                      <img typeof="foaf:Image" src={Logofooter} alt="" />
                    </a>
                  </div>
                </div>
                <div className="address">
                  <div>Địa chỉ: A4 &amp; đường D7, KDC Nam Long, Phường Phước Long B, TP Thủ Đức</div>
                  <div>Điện thoại: (+84) 28 66814141 - &nbsp;Mobile: (+84)&nbsp;0981 229 461</div>
                  <div>Email: info@tuongminhfengshui.com&nbsp;</div>
                </div>
                <div className="social">
                  <a className="facebook-link" href="https://business.facebook.com/hocvienphongthuytuongminh/">
                    <i className="fa fa-facebook" aria-hidden="true" style={{ color: 'white' }}></i>
                  </a>
                  <a className="twitter-link" href="https://www.facebook.com/phongthuytuongminh/">
                    <i className="fa fa-twitter" aria-hidden="true" style={{ color: 'white' }}></i>
                  </a>
                  <a className="google-link" href="https://www.facebook.com/phongthuytuongminh/">
                    <i className="fa fa-google-plus" aria-hidden="true" style={{ color: 'white' }}></i>
                  </a>
                  <a className="youtube-link" href="https://www.youtube.com/channel/UCxVMAH7gUiM9-zszIDKj9uA">
                    <i className="fa fa-youtube" aria-hidden="true" style={{ color: 'white' }}></i>
                  </a>
                </div>
                <div className="footer-bottom">
                  <div className="menu-footer-b">
                    <ul className="menu clearfix">
                      <li className="first expanded">
                        <a href="http://phongthuytuongminh.com/gioi-thieu-ve-tuong-minh-phong-thuy.html" title="">
                          Về chúng tôi
                        </a>
                        <ul className="menu clearfix">
                          <li className="first leaf">
                            <a href="http://phongthuytuongminh.com/gioi-thieu-ve-tuong-minh-phong-thuy.html" title="">
                              Về Tường Minh phong thuỷ
                            </a>
                          </li>
                          <li className="leaf">
                            <a href="http://phongthuytuongminh.com/master-phuong-nguyen.html" title="">
                              Thầy Nguyễn Thành Phương
                            </a>
                          </li>
                          <li className="last leaf">
                            <a href="http://phongthuytuongminh.com/thu-vien-anh.html" title="">
                              Thư viện ảnh
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="expanded">
                        <a href="http://phongthuytuongminh.com/tu-van-phong-thuy.html" title="">
                          Dịch Vụ Tư vấn
                        </a>
                        <ul className="menu clearfix">
                          <li className="first leaf">
                            <a href="http://phongthuytuongminh.com/phong-thuy-nha-o.html" title="">
                              Phong thủy Nhà ở
                            </a>
                          </li>
                          <li className="leaf">
                            <a href="http://phongthuytuongminh.com/chon-ngay-tot-nhat-trach.html-0" title="">
                              Chọn ngày tốt (Trạch Nhật)
                            </a>
                          </li>
                          <li className="last leaf">
                            <a href="http://phongthuytuongminh.com/phong-thuy-mo-ma-am-trach.html" title="">
                              Mộ Phần (Âm trạch)
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="expanded">
                        <a href="http://phongthuytuongminh.com/hoc-phong-thuy.html" title="">
                          Học phong thủy
                        </a>
                        <ul className="menu clearfix">
                          <li className="first leaf">
                            <a href="http://phongthuytuongminh.com/phong-thuy-dia-ly.html" title="">
                              Phong thủy địa lý
                            </a>
                          </li>
                          <li className="leaf">
                            <a href="http://phongthuytuongminh.com/bat-tu-tu-vi.html" title="">
                              Bát tự tử vi
                            </a>
                          </li>
                          <li className="leaf">
                            <a href="/" title="" className="active">
                              Trạch Nhật (Chọn Ngày Giờ)
                            </a>
                          </li>
                          <li className="last leaf">
                            <a href="/lich-hoc-phong-thuy" title="">
                              Lịch học phong thủy
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="expanded">
                        <a href="http://phongthuytuongminh.com/kien-thuc-phong-thuy.html-0&gt;" title="">
                          Kiến thức phong thủy
                        </a>
                        <ul className="menu clearfix">
                          <li className="first leaf">
                            <a href="http://phongthuytuongminh.com/tai-lieu-phong-thuy.html" title="">
                              Tài liệu phong thủy
                            </a>
                          </li>
                          <li className="last leaf">
                            <a href="http://phongthuytuongminh.com/bai-viet-phong-thuy.html-1" title="">
                              Bài viết phong thủy
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="last leaf">
                        <a href="/contact" title="Liên hệ">
                          Liên hệ
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="coppyright to-hide-print" style={{ minWidth: '1024px' }}>
            <p>by Phong Thuỷ Tường Minh - Hocphongthuy.net 2019 Allright reserved</p>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
