import React, { useState } from "react";
import { useEffect } from "react";
import "../App.css";
import Const from "../Const";
import {
  getBlueTextHanViet,
  GiaiThichKhacUngBatMon,
  getPhiTinhMainNumber,
  GiaiThichKhacUngChoBatThan,
  GiaiThichKhacUngChoCanGio,
  GiaiThichKhacUngChoTruChiGio,
  GiaiThichKyMonKhacUng,
  GiaiThichTamKyPhuXuKhacUng,
  phiTinhNam,
  phiTinhThang,
  GiaiThichKhacUngTamKy,
  GiaiThichBatMonBatThan,
  GiaiThichCuuTinhTrucThoi,
  GiaiThichTamKyBatMon,
  GiaiThichDinhBatThan,
  GiaiThichThienBanDiaBan,
  GiaiThichBatMonBatQuai,
} from "../convert";
import background from "../logo.png";
import dichMa from "../image/dichma.png";
import { Modal } from "semantic-ui-react";

export default function CuuCungPhiTinh(props) {
  const data = props.data1080cuc;
console.log('Cuong xem data',data)
  const [mainNumber, setmainNumber] = useState(
    props.searchKyMonDonGiap ? Number(data.mainHourPhiTinh) : 0
  );

  useEffect(() => {
    if (!props.searchKyMonDonGiap) {
      getMainNumberHour();
    }
  }, [
    mainNumber,
    props.day,
    props.hour,
    props.month,
    props.year,
    props.chiDay,
    props.isSauDongChi,
  ]);
  function getMainNumberHour() {
    let mainNumber = 0;
    if (typeof props.hour === "number") {
      const getChiDayData = Const.ChiArr.find((v) => v.name === props.chiDay);
      mainNumber = getPhiTinhMainNumber(
        getChiDayData.code,
        props.hour,
        props.isSauDongChi
      );
    } else if (props.day) {
      mainNumber = props.day;
    } else if (props.month) {
      mainNumber = phiTinhThang(props.month ? props.month : "");
      if (props.month?.getMonth() === 0) {
        mainNumber = phiTinhThang(
          props.month?.getFullYear() + 1,
          props.month?.getMonth(),
          props.month?.getDate()
        );
      }
    } else if (props.year) {
      mainNumber = phiTinhNam(props.year);
    }

    setmainNumber(mainNumber);
  }
  const DDOrAD =
    data?.sheet?.split(" ")[0] === "Duong" ? "Dương Độn" : "Âm Độn";
  return (
    <div>
      <div style={{ display: "flex", width: "100%" }}>
        <p
          style={{
            whiteSpace: "pre-line",
            textAlign: "center",
            margin: "auto",
          }}
        >{`Độn:\n${DDOrAD}`}</p>
        <p
          style={{
            whiteSpace: "pre-line",
            textAlign: "center",
            margin: "auto",
          }}
        >{`Cục:\n${data?.sheet?.split(" ")[2]}`}</p>
        <p
          style={{
            whiteSpace: "pre-line",
            textAlign: "center",
            margin: "auto",
          }}
        >{`Tuần Thủ:\n${data?.phuDau ? data?.phuDau : "Không có"}`}</p>
        <p
          style={{
            whiteSpace: "pre-line",
            textAlign: "center",
            margin: "auto",
          }}
        >{`Trực Tinh:\n${data?.trucPhu ? data?.trucPhu : "Không có"}`}</p>
        {/* <p style={{ whiteSpace: 'pre-line', textAlign: 'center', margin: 'auto' }}>{`Trực Tinh:\n${data?.trucTinh ? data?.trucTinh : "Không có"
          }`}</p> */}
        <p
          style={{
            whiteSpace: "pre-line",
            textAlign: "center",
            margin: "auto",
          }}
        >{`Trực Xử:\n${data?.trucXu ? data?.trucXu : "Không có"}`}</p>
      </div>

      <table
        style={{
          backgroundColor: "rgb(240, 88, 40)",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <tbody>
          <tr>
            <td style={{ color: "#fff" }}>ĐN</td>
            <td style={{ color: "#fff" }}>N</td>
            <td style={{ color: "#fff" }}>TN</td>
          </tr>
          <tr>
            <td style={{ color: "#fff" }}>Đ</td>
            <td
              style={{
                backgroundColor: "white",
              }}
            >
              {/* style={{ backgroundImage: `url(${background})`, backgroundSize: '70px', backgroundRepeat: 'no-repeat' }} */}

              {renderCuuCungBasic()}
            </td>
            <td style={{ color: "#fff" }}>T</td>
          </tr>
          <tr>
            <td style={{ color: "#fff" }}>ĐB</td>
            <td style={{ color: "#fff" }}>B</td>
            <td style={{ color: "#fff" }}>TB</td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  function renderEachCellInCuuCung(dataBox, phiTinh, blueText, KVOrDM, image) {
    const showMainNumber =
      phiTinh === 10 ? 1 : phiTinh > 10 ? phiTinh - 9 : phiTinh;
    const showMainNumber_ = dataBox.number ? +dataBox.number : "";
    const kyMonKhacUng = GiaiThichKyMonKhacUng(
      dataBox.thienBan,
      dataBox?.batMon
    );
    const TamkyPhuXuKhacUng = GiaiThichTamKyPhuXuKhacUng(
      dataBox.thienBan,
      dataBox.cuuTinh,
      dataBox.batMon,
      data.CanChi?.split(" ")[0]
    );
    const batMonKhacUng = GiaiThichKhacUngBatMon(dataBox?.batMon);
    const canKhacUng = GiaiThichKhacUngChoCanGio(data.CanChi?.split(" ")[0]);
    const chiKhacUng = GiaiThichKhacUngChoTruChiGio(data.CanChi?.split(" ")[1]);
    const batThanKhacung = GiaiThichKhacUngChoBatThan(dataBox.batThan);
    const tamKyKhacUng = GiaiThichKhacUngTamKy(
      dataBox.thienBan,
      dataBox.batQuai
    );
    const batMonBatThanKhacUng = GiaiThichBatMonBatThan(
      dataBox?.batMon,
      dataBox.batThan
    );
    const cuuTinhTrucThoi = GiaiThichCuuTinhTrucThoi(
      data.CanChi?.split(" ")[1],
      dataBox.cuuTinh
    );
    const tamKyBatMon = GiaiThichTamKyBatMon(dataBox.thienBan, dataBox?.batMon);
    const dinhBatThanKhacung = GiaiThichDinhBatThan(dataBox.batThan);
    const giaiThichThienBanDiaBan = GiaiThichThienBanDiaBan(dataBox.thienBan);
    const giaiThichBatMonBatQuai = GiaiThichBatMonBatQuai(
      dataBox?.batMon,
      dataBox.batQuai
    );
    return (
      <Modal
        // size="large"
        closeIcon
        style={{ margin: "auto" }}
        trigger={
          <table>
            {image && (
              <img
                style={{
                  position: "absolute",
                  width: "70px",
                  height: "70px",
                  opacity: "0.3",
                  marginTop: "8px",
                  marginLeft: "20px",
                }}
                src={background}
                alt="logo"
              />
            )}

            <tbody>
              <tr height="26px">
                <td
                  style={{
                    color:
                      dataBox.thienBan === "Giáp" ||
                      dataBox.thienBan === "Ất" ||
                      dataBox.thienBan === "Bính" ||
                      dataBox.thienBan === "Đinh" ||
                      dataBox.thienBan === "Mậu"
                        ? "red"
                        : null,
                  }}
                  className="width-each-cell-CuuCungDetail"
                >
                  {dataBox.thienBan || ""}
                </td>
                {dataBox.cuuTinh && (
                  <td
                    style={{
                      color:
                        dataBox.cuuTinh === "Phụ" ||
                        dataBox.cuuTinh === "Tâm" ||
                        dataBox.cuuTinh === "Cầm" ||
                        dataBox.cuuTinh === "Nhậm" ||
                        dataBox.cuuTinh === "Anh"
                          ? "red"
                          : null,
                    }}
                    className="width-each-cell-CuuCungDetail"
                  >
                    {dataBox.cuuTinh || ""}
                  </td>
                )}

                <td
                  className="width-each-cell-CuuCungDetail"
                  style={{
                    color:
                      showMainNumber === 1 ||
                      showMainNumber === 8 ||
                      showMainNumber === 6 ||
                      showMainNumber === 9
                        ? "red"
                        : null,
                  }}
                >
                  {showMainNumber}
                </td>
              </tr>
              <tr height="26px">
                <td
                  colSpan="2"
                  className="width-each-cell-CuuCungDetail"
                  style={{
                    color: "blue",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  <span style={{ whiteSpace: "nowrap" }}>{blueText || ""}</span>
                </td>
                {/* <td className="width-each-cell-CuuCungDetail">{R2C2 || ''}</td> */}
                <td
                  className="width-each-cell-CuuCungDetail"
                  style={{ textAlign: "end", height: "26px" }}
                >
                  <div
                    style={{ display: "flex", float: "right", height: "26px" }}
                  >
                    {KVOrDM?.includes("DM") ? (
                      <img
                        src={dichMa}
                        alt="dichma"
                        width="20px"
                        height="20px"
                      />
                    ) : (
                      ""
                    )}
                    {KVOrDM?.includes("KV") ? <p>KV</p> : ""}
                  </div>
                </td>
              </tr>
              <tr height="26px">
                <td
                  style={{
                    color:
                      dataBox.diaBan === "Giáp" ||
                      dataBox.diaBan === "Ất" ||
                      dataBox.diaBan === "Bính" ||
                      dataBox.diaBan === "Đinh" ||
                      dataBox.diaBan === "Mậu"
                        ? "red"
                        : null,
                  }}
                  className="width-each-cell-CuuCungDetail"
                >
                  {dataBox.diaBan || ""}
                </td>
                <td
                  style={{
                    color:
                      dataBox.batMon === "Khai" ||
                      dataBox.batMon === "Hưu" ||
                      dataBox.batMon === "Sinh" ||
                      dataBox.batMon === "Cảnh"
                        ? "red"
                        : null,
                  }}
                  className="width-each-cell-CuuCungDetail"
                >
                  {dataBox.batMon || ""}
                </td>
                <td
                  style={{
                    color:
                      dataBox.batThan === "Phù" ||
                      dataBox.batThan === "Âm" ||
                      dataBox.batThan === "Hợp" ||
                      dataBox.batThan === "Địa" ||
                      dataBox.batThan === "Thiên"
                        ? "red"
                        : null,
                  }}
                  className="width-each-cell-CuuCungDetail"
                >
                  {dataBox.batThan || ""}
                </td>
              </tr>
            </tbody>
          </table>
        }
      >
        <Modal.Header>Diễn Giải</Modal.Header>
        <Modal.Content className="modal-explain">
          <div style={{ width: "100%" }}>
            <table className="explainKyMon">
              <tr>
                <th className="explainKyMon">Hướng</th>
                <th className="explainKyMon">Thiên Bàn</th>
                <th className="explainKyMon">Địa Bàn</th>
                <th className="explainKyMon">Cửu Tinh</th>
              </tr>
              <tr>
                <td className="explainKyMon">{dataBox.huong?.show}</td>
                <td className="explainKyMon">{dataBox.thienBan}</td>
                <td className="explainKyMon">{dataBox.diaBan}</td>
                <td className="explainKyMon">{dataBox.cuuTinh}</td>
              </tr>
            </table>
          </div>
          <div style={{ width: "100%" }}>
            <table className="explainKyMon" style={{ margin: "auto" }}>
              <tr>
                <th className="explainKyMon">Bát Môn</th>
                <th className="explainKyMon">Bát Thần</th>
                <th className="explainKyMon">Phi Tinh</th>
              </tr>
              <tr>
                <td className="explainKyMon">{dataBox.batMon}</td>
                <td className="explainKyMon">{dataBox.batThan}</td>
                <td className="explainKyMon">{showMainNumber}</td>
              </tr>
            </table>
          </div>
          {renderGiaiThich("KHẮC ỨNG KỲ MÔN", kyMonKhacUng)}
          {renderGiaiThich("KHẮC ỨNG TAM KỲ PHU XUNG", TamkyPhuXuKhacUng)}
          {renderGiaiThich("KHẮC ỨNG CHO BÁT MÔN", batMonKhacUng)}
          {renderGiaiThich("KHẮC ỨNG CHO CAN", canKhacUng)}
          {renderGiaiThich("KHẮC ỨNG CHO TRỤ CHI", chiKhacUng)}
          {renderGiaiThich("KHẮC ỨNG CHO BÁT THẦN", batThanKhacung)}
          {renderGiaiThich("KHẮC ỨNG TAM KỲ", tamKyKhacUng)}
          {renderGiaiThich("CỬU TINH TRỰC THỜI KHẮC ỨNG", cuuTinhTrucThoi)}
          {renderGiaiThich("KHẮC ỨNG ĐINH VÀ BÁT THẦN", dinhBatThanKhacung)}
          {renderGiaiThich(
            "TƯƠNG TÁC GIỮA BÁT MÔN VÀ BÁT THẦN",
            batMonBatThanKhacUng
          )}
          {renderGiaiThich("KHẮC ỨNG TAM KỲ BÁT MÔN", tamKyBatMon)}
          {renderGiaiThich(
            "TƯƠNG TÁC GIỮA THIÊN BÀN VÀ ĐỊA BÀN",
            giaiThichThienBanDiaBan
          )}
          {renderGiaiThich(
            "TƯƠNG TÁC GIỮA BÁT MÔN VÀ BÁT QUÁI",
            giaiThichBatMonBatQuai
          )}
        </Modal.Content>
      </Modal>
    );
  }
  function renderGiaiThich(header, content) {
    if (content) {
      return (
        <div style={{ marginBottom: "10px" }}>
          <h3 style={{ margin: "0px" }}>{header}</h3>
          <p>{content}</p>
        </div>
      );
    }
  }
  function isDichMa(CanChi, huong) {
    const chiConGiap = CanChi?.split(" ")[1];
    if (CanChi == null || huong == null) {
      return false;
    }

    switch (chiConGiap) {
      case Const.Chi.Than.name:
      case Const.Chi.Ty.name:
      case Const.Chi.Thin.name:
        if (huong === Const.huong.DONG_BAC.show) {
          return "DM";
        }
        return "";

      case Const.Chi.Ti.name:
      case Const.Chi.Dau.name:
      case Const.Chi.Suu.name:
        if (huong === Const.huong.TAY_BAC.show) {
          return "DM";
        }
        return "";

      case Const.Chi.Hoi.name:
      case Const.Chi.Mao.name:
      case Const.Chi.Mui.name:
        if (huong === Const.huong.DONG_NAM.show) {
          return "DM";
        }
        return "";

      case Const.Chi.Dan.name:
      case Const.Chi.Ngo.name:
      case Const.Chi.Tuat.name:
        if (huong === Const.huong.TAY_NAM.show) {
          return "DM";
        }
        return "";

      default:
        return "";
    }
  }
  function isKhongVong(CanChi, huong) {
    //lấy ra list con giáp theo hướng
    const listConGiapTheoHuong =
      Const.HuongModal.find((v) => v.huong.show === huong)?.lisConGiap || [];
    //lấy ra list con giáp theo canchi
    const listConGiapTheoCanChi =
      Const.KhongVongModal.find((v) => v.listCanChi.includes(CanChi))
        ?.listConGiap || [];
    //nếu con giáp theo hướng có chứa con giáp theo can chi thì đó là không vong
    for (let index = 0; index < listConGiapTheoHuong.length; index++) {
      if (listConGiapTheoCanChi.includes(listConGiapTheoHuong[index])) {
        return "KV";
      }
    }
  }

  function renderCuuCungBasic() {
    const blueTextBox1 = getBlueTextHanViet(
      data.box1,
      data.trucTinh,
      data.CanChi
    );
    const blueTextBox2 = getBlueTextHanViet(
      data.box2,
      data.trucTinh,
      data.CanChi
    );
    const blueTextBox3 = getBlueTextHanViet(
      data.box3,
      data.trucTinh,
      data.CanChi
    );
    const blueTextBox4 = getBlueTextHanViet(
      data.box4,
      data.trucTinh,
      data.CanChi
    );
    const blueTextBox5 = getBlueTextHanViet(
      data.box5,
      data.trucTinh,
      data.CanChi
    );
    const blueTextBox6 = getBlueTextHanViet(
      data.box6,
      data.trucTinh,
      data.CanChi
    );
    const blueTextBox7 = getBlueTextHanViet(
      data.box7,
      data.trucTinh,
      data.CanChi
    );
    const blueTextBox8 = getBlueTextHanViet(
      data.box8,
      data.trucTinh,
      data.CanChi
    );
    const blueTextBox9 = getBlueTextHanViet(
      data.box9,
      data.trucTinh,
      data.CanChi
    );

    const DMOrKVBox1 =
      isDichMa(data.CanChi, Const.huong.DONG_NAM.show) +
      (isKhongVong(data.CanChi, Const.huong.DONG_NAM.show) ? "KV" : "");
    const DMOrKVBox2 =
      isDichMa(data.CanChi, Const.huong.NAM.show) +
      (isKhongVong(data.CanChi, Const.huong.NAM.show) ? "KV" : "");
    const DMOrKVBox3 =
      isDichMa(data.CanChi, Const.huong.TAY_NAM.show) +
      (isKhongVong(data.CanChi, Const.huong.TAY_NAM.show) ? "KV" : "");
    const DMOrKVBox4 =
      isDichMa(data.CanChi, Const.huong.DONG.show) +
      (isKhongVong(data.CanChi, Const.huong.DONG.show) ? "KV" : "");

    const DMOrKVBox6 =
      isDichMa(data.CanChi, Const.huong.TAY.show) +
      (isKhongVong(data.CanChi, Const.huong.TAY.show) ? "KV" : "");
    const DMOrKVBox7 =
      isDichMa(data.CanChi, Const.huong.DONG_BAC.show) +
      (isKhongVong(data.CanChi, Const.huong.DONG_BAC.show) ? "KV" : "");
    const DMOrKVBox8 =
      isDichMa(data.CanChi, Const.huong.BAC.show) +
      (isKhongVong(data.CanChi, Const.huong.BAC.show) ? "KV" : "");
    const DMOrKVBox9 =
      isDichMa(data.CanChi, Const.huong.TAY_BAC.show) +
      (isKhongVong(data.CanChi, Const.huong.TAY_BAC.show) ? "KV" : "");

    return (
      <div
      // style={{ backgroundImage: `url(${background})`, backgroundSize: '70px', backgroundRepeat: 'no-repeat' }}
      >
        {/* <div>
          <img style={{ position: 'absolute', width: '70px', height: '70px', opacity: '0.5' }} src={background} alt="logo" />
        </div> */}
        <table className="CuuCungDetail">
          {/* <div>
            <img style={{ position: 'absolute', width: '70px', height: '70px', opacity: '0.5' }} src={background} alt="logo" />
          </div> */}
          <tbody>
            <tr>
              <td className="CuuCungCell">
                {renderEachCellInCuuCung(
                  data?.box1,
                  mainNumber + 8,
                  blueTextBox1,
                  DMOrKVBox1
                )}
              </td>
              <td className="CuuCungCell">
                {renderEachCellInCuuCung(
                  data?.box2,
                  mainNumber + 4,
                  blueTextBox2,
                  DMOrKVBox2
                )}
              </td>
              <td className="CuuCungCell">
                {renderEachCellInCuuCung(
                  data?.box3,
                  mainNumber + 6,
                  blueTextBox3,
                  DMOrKVBox3
                )}
              </td>
            </tr>
            <tr>
              <td className="CuuCungCell">
                {renderEachCellInCuuCung(
                  data?.box4,
                  mainNumber + 7,
                  blueTextBox4,
                  DMOrKVBox4
                )}
              </td>
              <td className="CuuCungCell">
                {renderEachCellInCuuCung(
                  data?.box5,
                  mainNumber,
                  blueTextBox5,
                  "",
                  props.showLogo
                )}
              </td>
              <td className="CuuCungCell">
                {renderEachCellInCuuCung(
                  data?.box6,
                  mainNumber + 2,
                  blueTextBox6,
                  DMOrKVBox6
                )}
              </td>
            </tr>
            <tr>
              <td className="CuuCungCell">
                {renderEachCellInCuuCung(
                  data?.box7,
                  mainNumber + 3,
                  blueTextBox7,
                  DMOrKVBox7
                )}
              </td>
              <td className="CuuCungCell">
                {renderEachCellInCuuCung(
                  data?.box8,
                  mainNumber + 5,
                  blueTextBox8,
                  DMOrKVBox8
                )}
              </td>
              <td className="CuuCungCell">
                {renderEachCellInCuuCung(
                  data?.box9,
                  mainNumber + 1,
                  blueTextBox9,
                  DMOrKVBox9
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
