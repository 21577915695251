import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import classNames from "classnames/bind";
import styles from "./Editor.module.scss";
import React, { useState } from "react";

const cx = classNames.bind(styles);

const Editor = ({ className, value, dataContent }) => {
  const classes = cx("wrapper", {
    [className]: className,
  });

  const [editor, setEditor] = useState(null);

  return (
    <div className={classes}>
      <CKEditor
        onReady={(editor) => {
          editor.ui
            .getEditableElement()
            .parentElement.insertBefore(
              editor.ui.view.toolbar.element,
              editor.ui.getEditableElement()
            );

          setEditor(editor);
        }}
        onError={({ willEditorRestart }) => {
          if (willEditorRestart) {
            editor.ui.view.toolbar.element.remove();
          }
        }}
        onChange={(event, editor) => {
          let data = editor.getData();
          value(data);
          //   setValue(name, data);
        }}
        editor={DecoupledEditor}
        data={dataContent || ""}
        config={{
          removePlugins: ["ImageToolbar"],

          toolbar: {
            shouldGroupWhenFull: true,
            items: [
              "heading",
              "|",
              "fontSize",
              "fontColor",
              "|",
              "bold",
              "italic",
              "link",
              "bulletedList",
              "numberedList",
              "alignment",
              "|",
              "indent",
              "outdent",
              "|",
              // 'horizontalLine',
              "blockQuote",
              "insertTable",
              "undo",
              "redo",
            ],
          },

          mediaEmbed: {
            elementName: "iframe",
            previewsInData: true,
          },
        }}
      />
    </div>
  );
};

export default Editor;
