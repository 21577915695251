import DanImage from "../src/image/con_giap/dan.svg";
import DanRedImage from "../src/image/con_giap/danRed.svg";
import DauImage from "../src/image/con_giap/dau.svg";
import DauRedImage from "../src/image/con_giap/dauRed.svg";
import HoiImage from "../src/image/con_giap/hoi.svg";
import HoiRedImage from "../src/image/con_giap/hoiRed.svg";
import MaoImage from "../src/image/con_giap/mao.svg";
import MaoRedImage from "../src/image/con_giap/maoRed.svg";
import MuiImage from "../src/image/con_giap/mui.svg";
import MuiRedImage from "../src/image/con_giap/muiRed.svg";
import NgoImage from "../src/image/con_giap/ngo.svg";
import NgoRedImage from "../src/image/con_giap/ngoRed.svg";
import SuuImage from "../src/image/con_giap/suu.svg";
import SuuRedImage from "../src/image/con_giap/suuRed.svg";
import ThanImage from "../src/image/con_giap/than.svg";
import ThanRedImage from "../src/image/con_giap/thanRed.svg";
import ThinImage from "../src/image/con_giap/thin.svg";
import ThinRedImage from "../src/image/con_giap/thinRed.svg";
import TiImage from "../src/image/con_giap/ti.svg";
import TiRedImage from "../src/image/con_giap/tiRed.svg";
import TuatImage from "../src/image/con_giap/tuat.svg";
import TuatRedImage from "../src/image/con_giap/tuatRed.svg";
import TyImage from "../src/image/con_giap/ty.svg";
import TyRedImage from "../src/image/con_giap/tyRed.svg";
export default class Const {
  static giai_thich_at_sinh =
    "Sẽ thấy 2 con chuột hay một người mặc quần áo tang. Đàn chim tụ tập, có gió, mưa nhỏ, xe và ngựa chạy ngang qua. Biểu thị thịnh vượng cho con cháu.";
  static giai_thich_at_huu =
    "Thấy bò và ngựa hay người nào đó mang gỗ xuất hiện.";
  static giai_thich_at_khai =
    "Quan chức chính phủ, nhân viên mặc đồng phục hay người nào mặc quần áo đỏ xuất hiện.";
  static giai_thich_binh_sinh =
    "Một người có bệnh về mắt hay có 1 cuộc cãi vã.";
  static giai_thich_binh_huu =
    "Trong vòng 50 km, sẽ nghe tiếng trống, nhạc cụ, quạ hay cò trắng xuất hiện. Biểu thị tài lộc cho con cháu..";
  static giai_thich_binh_khai =
    "Ông già cầm theo 1 cái gậy xuất hiện. Có thể nghe thấy tiếng khóc.";
  static giai_thich_dinh_sinh = "Gặp cuộc đi săn hay chó.";
  static giai_thich_dinh_huu =
    "Trong vòng 20 km, thấy con cọp trắng, người mặc đồ đen hay phụ nữ.";
  static giai_thich_dinh_khai =
    "Xe hơi lớn, xe hơi nhỏ, mây trắng, băng rôn, hay một đứa trẻ trai cầm theo cây gậy xuất hiện. Biểu thị tài lộc cho con cái.";

  static giai_thich_at_thienphu_sinh_atcanh =
    "Sấm và chớp xuất hiện. Sự xuất hiện của 2 con chim. Rất tốt.";
  static giai_thich_binh_thiennhue_huu_giapky =
    "Sự xuất hiện của cò và chim. 2 người cưỡi ngựa. Rất tốt.";
  static giai_thich_dinh_thienanh_khai_mauquy =
    "Xuất hiện chim đen với đầu trắng và tiếng gầm như sấm. Rất tốt.";

  static giai_thich_huu_mon =
    "Bắc, tốt – khoảng cách có thể xét theo km hay km. Khi bạn đi qua cửa Hưu, bạn sẽ thấy một quý nhân ở khoảng cách 30 km HOẶC thấy rắn, chuột và con vật nước nào trong khoảng 50 km.";
  static giai_thich_sinh_mon =
    "Đông bắc, tốt – khi bạn đi qua cửa Sinh, bạn sẽ thấy một quý nhân và xe hơi đắt tiền (ngày xưa là xe ngựa) ở khoảng cách 60 km HOẶC thấy quan chức ở khoảng cách 50 km.";
  static giai_thich_thuong_mon =
    "Đông, xấu – khi bạn đi qua cửa Thương, bạn sẽ thấy tranh cãi, đánh nhau, đổ máu hay tai nạn đẫm máu hay thứ gì đó bị tổn hại, người bị thương tật.";
  static giai_thich_do_mon =
    "Đông nam, trung – khi bạn đi qua cửa Đỗ, bạn sẽ thấy một cặp đôi mặc đồ màu xanh lá ở khoảng cách 20 km HOẶC thấy sửa chữa, xây dựng HOẶC các sự việc gây sốc, sợ hãi, hay công trường sửa đường, kẹt xe trong 40 km.";
  static giai_thich_canh_mon =
    "Nam, hơi tốt – khi bạn đi qua cửa Cảnh, bạn sẽ thấy một sự kiện đáng sợ, gây sốc hay hỏa hoạn, HOẶC thấy 1 người mặc đồ màu đỏ trong 20 km.";
  static giai_thich_tu_mon =
    "Tây nam, rất xấu – khi bạn đi qua cửa Tử, bạn sẽ thấy 1 tang lễ hay người chết HOẶC thấy người than khóc, xót thương, bệnh nhân hay tù nhân trong 20 km.";
  static giai_thich_kinh_mon =
    "Tây, xấu – khi bạn đi qua cửa Kinh, bạn sẽ thấy sự kiện sốc và đáng sợ hay thấy người đáng nhau trong khoảng 40 km.";
  static giai_thich_khai_mon =
    "Tây bắc, RẤT tốt – khi bạn đi qua cửa Khai, bạn sẽ thấy 1 quý nhân hay lễ hội hay yến tiệc.";

  static giai_thich_can_giap =
    "Thấy 1 người phụ nữ mặc đồ màu xanh lá trong ngày Âm, người đàn ông mặc đồ màu xanh lá trong ngày Dương.";
  static giai_thich_can_at =
    "Thấy 1 Đạo Sĩ, người mù hay thầy bói trong ngày Âm, khách quý nhân trong ngày Dương.";
  static giai_thich_can_binh =
    "Thấy quý nhân mặc đồ xanh lá cưỡi ngựa đỏ trong ngày Âm, quý nhân mặc đồ xanh cưỡi ngựa trắng trong ngày Dương.";
  static giai_thich_can_dinh =
    "Người phụ nữ đẹp mặc đồ nhiều màu cưỡi ngựa/đi xe trong ngày Âm, mặc đầm đỏ trong ngày Dương.";
  static giai_thich_can_mau =
    "Tiệc với nhạc cùng bạn bè và người thân trong ngày Âm, trống và tiếng súng trong ngày Dương.";
  static giai_thich_can_ky =
    "Một đàn ông và một phụ nữ mặc đồ màu trắng trong ngày Âm, mặc đồ màu vàng trong ngày Dương.";
  static giai_thich_can_canh =
    "Người đàn ông mặc đồ tang trắng trong ngày Âm, binh sĩ/lính cai ngục trong ngày Dương.";
  static giai_thich_can_tan =
    "Quạ và chim ác là la oai oải trong ngày Âm, người mặc đồ màu trắng trong ngày Dương.";
  static giai_thich_can_nham =
    "Sấm, mưa và tuyết, người mặc đồ màu đen trong ngày Âm, phụ nữ mặc đồ trắng cầm lọ hoa trong ngày Dương.";
  static giai_thich_can_quy =
    "Phụ nữ có thai trong ngày Âm, ngư dân trong ngày Dương.";

  static giai_thich_chi_ty =
    "Phụ nữ ăn hay cầm theo thức ăn và rượu, vật bằng đồng/sắt/chì/sứ.";
  static giai_thich_chi_suu =
    "Người mặc đồ màu đen và quý nhân cưỡi ngựa (xe); công chức.";
  static giai_thich_chi_dan =
    "Thầy tu, Đạo sĩ, ni cô, nhân viên công chức nhà nước cầm baton gậy.";
  static giai_thich_chi_meo = "Thầy bói hay người mặc đồ đen cầm theo gậy.";
  static giai_thich_chi_thin =
    "Phụ nữ mặc đồ xanh lá có cử chỉ tay khoa trương.";
  static giai_thich_chi_tyj =
    "Ngư dân, cá, baba, người lưng trần và ngực trần cầm theo đồ gì đó.";
  static giai_thich_chi_ngo =
    "Người cầm thức ăn và rượu hay phụ nữ đi song song.";
  static giai_thich_chi_mui = "Phụ nữ cầm thức ăn, rượu và trái cây.";
  static giai_thich_chi_than =
    "Người cầm rượu, người giao hàng, khách, đi ra ngoài cùng với nhóm bạn.";
  static giai_thich_chi_dau = "Phụ nữ nói với nhau.";
  static giai_thich_chi_tuat = "Người mặc đồ xanh lá, đàn ông ác và phụ nữ.";
  static giai_thich_chi_hoi =
    "Người mặc đồ xanh lá, lễ tang, người tìm đồ vật bị mất.";

  static giai_thich_truc_phu = "Tiền từ người già, quý nhân, quan chức.";
  static giai_thich_dang_xa =
    "Kiện tụng, bị sập bẫy, mưa gió, sự kiện đáng sợ, siêu nhiên.";
  static giai_thich_thai_am = "Người chính đáng, cặp đôi, sự kiện riêng tư.";
  static giai_thich_luc_hop =
    "Xe hơi, sách, rượu và ăn, tiệc tùng, hôn nhân, mất tiền.";
  static giai_thich_cau_tran = "Thầy pháp, chết, tử khí, tiền và đồ.";
  static giai_thich_chu_tuoc = "Ngạc nhiên, sợ hãi, kẻ trộm, mưa mây.";
  static giai_thich_cuu_dia = "Váy phụ nữ, đậu, chôn cất, con vật chạy trốn.";
  static giai_thich_cuu_thien =
    "Tài liệu giấy tờ, súng, gậy, lửa cháy, chim bay.";

  static giai_thich_at_can =
    "Người mặc đồ màu vàng cầm theo tiền, người mặc đồ tang. Người mặc quần áo màu vàng đến hay ai đó đem tiền đi ngang qua. 60 ngày sau kinh doanh sẽ cất cánh. Hiệu ứng: Tiền hay tài lộc sẽ đến trong vòng 100 ngày – 1 năm.";
  static giai_thich_at_khon =
    "Người mặc đồ tang, người có bệnh về mắt; gà, chó, ngựa hay bò; sấm chớp. Có 3 – 5 người đến, may mắn sẽ xuất hiện trong vòng 7 ngày. 60 ngày nhận được từ phước đức. Nếu bạn nghe rằng có tiếng sấm và người đem gia súc đến phía Nam, tiền sẽ đến. Hiệu ứng: Biểu thị tài lộc đến – kiếm tiền trong vòng 21 ngày với gia cầm và heo; mua bất động sản trong vòng 60 ngày.";
  static giai_thich_at_ly =
    "Người có bệnh về mắt, người có tật ở chân, cưỡi bò, chim trắng, chó, lợn, con nít dắt bò, chim trắng và đen bay từ phía Đông Nam đến. Người mặc quần áo nhiều màu sắc đi ngang qua. Sau 30 ngày nhận được tài lộc bất ngờ. Nếu nhận được tin rằng ai đó cầm dao tự sát, may mắn sẽ đến. Hiệu ứng: Kiếm tiền trong vòng 21 ngày với heo và chó. Biểu thị tài lộc đến trong vòng 1 năm.";
  static giai_thich_at_kham =
    "Người mặc đồ đen, có tiếng trống, nếu thấy 1 người có đồ nhiều màu, thì biểu thị tài lộc đến. Người mặc quần áo màu đen đến hay nghe thấy tiếng trống ồn ào. 7 ngày sau tiền sẽ đến. Hiệu ứng: Nếu nhặt được bạc hay vàng, biểu thị có thai hay tài lộc đến trong 7 ngày.";
  static giai_thich_at_chan =
    "Ngư dân, thợ săn, người phụ nữ có thai, đám đông gồm nhiều đứa trẻ, quân lính và cảnh sát cầm súng hoặc gươm, âm tranh nhạc khí. Người săn bắt cá và 2 trẻ con xuất hiện. Sau 7 ngày, nhận được kho báu. Nếu nghe được tin tức về cái chết, sẽ có kết quả may mắn. Hiệu ứng: Lợi nhuận và tài lộc đến trong vòng 21 ngày. Nếu có sẩy thai hay vấn đề sinh nở ở Đông, tiền bạc sẽ đến nhiều.";
  static giai_thich_at_ton =
    "Người mặc quần áo màu trắng cưỡi ngựa, hoặc trẻ con chơi với nhau. Nếu nhận được tin có nhà cháy, hay ai đó treo cổ thì sẽ gặp may mắn. Hiệu ứng: Trong vòng 3 năm, sẽ sinh được quý tử và thừa kế tiền từ phương Đông.";
  static giai_thich_at_doai =
    "Thiếu nữ, người cầm đồ bằng kim loại, đồ bị gãy, gia đình ở phía Đông có ngựa/bò và đồ bị phá hoại, 3-5 nhóm phụ nữ. 3 đến 5 người phụ nữ đã lập gia đình đi đến, hay một đàn chim chóc xuất hiện. Sau 3 hay 30 ngày, tài lộc bất ngờ xuất hiện. Hiệu ứng: Giao dịch bất động sản và gia súc với người khác trong vòng 21 ngày.";
  static giai_thich_at_canas =
    "2 con gà đến từ phía Bắc, một người tên hành Thủy và Tam đem đồ đến, mặc đồ màu xanh lá, người cầm theo đồ kim, gà đen và trắng. Người mặc quần áo màu trắng xuất hiện, hay một món đồ được gói kỹ càng, hay xuất hiện cá trong lưới. Trong vòng 1 năm, sẽ được tuyển dụng và thăng chức. Hiệu ứng: Tài lộc đến trong vòng 1 năm và nhiều tiền đến trong 3 năm.";

  static giai_thich_binh_can =
    "Người mặc đồ đen, 2 con chim bay, đồ vật bay, phụ nữ ở phía Nam có rắc rối vấn đề sinh nở. Người quấn khăn choàng, hay một bàn chim bay đến. Sau 1 tháng nhận được tài sản và công đức. Nếu nghe tin tức rằng có ai đó sinh nở sẽ gặp thịnh vượng. Hiệu ứng: Tài lộc từ người phụ nữ sẽ đến trong vòng 100 ngày; hay gặp người quý nhân là phụ nữ.";
  static giai_thich_binh_khon =
    "Người mang đồ màu nâu, nghe tiếng quạ/nhạc/chim sáo từ phía Đông. Người mặc quần áo màu đen xuất hiện, hay chim hót líu lo ở phía Nam. Sau 2 đến 7 ngày, nhận được tài sản phía Nam, hay trong vòng 1 năm nhận gia súc và dê cừu và may mắn. Nhiều may mắn hơn nếu nghe tiếng nhạc, trống đến từ phía Đông. Hiệu ứng: Tiền hay tài lộc đến từ người nữ trong vòng 21 ngày; tài lộc lớn trong vòng 1 năm khi mua bất động sản.";
  static giai_thich_binh_ly =
    "Chim màu vàng hay đen, vật thể bay, tìm sâu tằm gần ruộng, nông sản. Một đàn chim màu vàng bay qua. Hoặc trong vòng 1 ngày hay 60 ngày, sẽ gặp may mắn. Hiệu ứng: Tài lộc không ngờ đến trong vòng 7 – 60 ngày. Mau chóng giàu có trong vòng 1 năm thông qua bất động sản.";
  static giai_thich_binh_kham =
    "Người cầm theo gậy hay con gà màu trắng đến từ phía Đông, nhưng chạy sợ hãi về phía Tây, gia đình ở phía Đông có cháy hay thắng lửa/nước/sấm… Một người mù và chim chóc bay từ phía Bắc. Sau 100 ngày hay 1 năm, sẽ thành công. Hiệu ứng: Sau khi giàu có, sẽ có giấy tờ đất đai đứng tên, hợp đồng trong vòng 60 – 120 ngày.";
  static giai_thich_binh_chan =
    "Lính, cảnh sát, chiến sĩ, người với đồ kim loại, nghe sấm và nhạc/trống, ngư dân, thợ săn, đám đông có trẻ con đến. Sự xuất hiện của vũ khí và thiết bị của tướng lĩnh. Tiếng sấm hay tiếng trống được nghe thấy trong tháng mùa xuân. Sau 10 ngày, sẽ nhận được đồ bằng đồng. Trong vòng 1 năm, sẽ thụ thai 1 đứa con. Nếu phía Bắc có con rồng và tiếng sấm, sẽ có may mắn. Hiệu ứng: Sẽ có gia súc hay cây cối trong vòng 27 ngày, sinh con trai hay trẻ con trở nên nổi tiếng trong vòng 1 năm.";
  static giai_thich_binh_ton =
    "Người mặc đồ màu sắc hay quan chứ đến, nghe trống/nhạc, lửa, điều xấu xảy đến ở phương Nam. Nghe thấy tiếng trống và nhạc. Người mặc quần áo sặc sỡ sẽ xuất hiện sau 7 ngày và gia đình sẽ nhận được may mắn. Nếu một người nhận được tin tức rằng phía Nam có tin tức bất ngờ, sẽ có tài lộc bất ngờ. Hiệu ứng: Tài lộc nhiều đến – quý nhân đến trong vòng 7 ngày, tài lộc đạt được từ người có quần áo nhiều màu sắc, được hợp đồng giấy tờ bất động sản.";
  static giai_thich_binh_doai =
    "Người đến cầm theo gậy, người khác bồng treo đứa trẻ, nghe nhạc/trống, có người già mất đi ở phía Đông Bắc và Tây Nam. Một người cầm theo gậy và chai rượu và dẫn theo 1 đứa bé. Nếu có tiếng trống, sau 7 ngày nhận được tài lộc và trong vòng 1 năm, tài sản của bạn sẽ thịnh vượng. Hiệu ứng: Tài lộc đến – tiền và quần áo trong vòng 7 ngày; mang thai con cái giỏi giang trong vòng 100 ngày, có được bất động sản trong vòng 1 năm.";
  static giai_thich_binh_canas =
    "Thấy trẻ con và người cầm theo đồ kim loại, ngựa, bò, người có đồ màu xanh lá, quan chức bước đến, trẻ con khóc, ngư dân, thợ săn. Một người mặc quần áo màu xanh lá cây đi đến, trẻ con khóc, hay một chàng trai cầm dao nĩa bằng kim loại. Sau 7 ngày nhận được tài sản, sau 1 năm nhận được 1 con ngựa trắng và thịnh vượng. Hiệu ứng: Có vàng/bạc trang sức và đồ màu xanh lá trong vòng 21 ngày. Giấy tờ bất động sản trong vòng 100 ngày. Có 1 con ngựa hay xe màu trắng trong vòng 1 năm.";

  static giai_thich_dinh_can =
    "Người cầm theo búa (hay vật sắc bén), người cầm theo lợn. Người cầm theo dao, hay cưỡi ngựa sẽ xuất hiện. Trong vòng 20 đến 70 ngày, động thổ sẽ đem lại tài lộc. Hiệu ứng: Tiền hay vàng/bạc đến trong vòng 21 ngày; trở nên rất giàu có khi vật màu đen hay trắng trong vòng 1 năm.";
  static giai_thich_dinh_khon =
    "Chim màu đen bay hoặc đồ đen, phụ nữ cầm theo nước, người mặc đồ màu xanh lá. Người phụ nữ mặc quần áo màu xanh lá cây bước ngang qua vị sư hay linh mục. Hay trâu bò màu đen kéo xe. Trong vòng 70 ngày, phải chịu lỗ tài chính do nước ngập lụt. Hiệu ứng: Vật bằng nước đến trong vòng 21 ngày; tài lộc hay tiền đến từ phía nữ đến trong vòng 21 ngày; trở nên giàu có khi nước ở phía Tây Nam tràn ngập ruộng đồng.";
  static giai_thich_dinh_ly =
    "Chim vàng và đen, đồ bay, nhìn sâu sằm gần ruộng, nông sản. Người mù hay tàn tật chân tay xuất hiện. Trẻ con cưỡi ngựa, trong vòng 70 ngày sẽ trở nên giàu có. Hiệu ứng: Tài lộc bất ngờ đến trong vòng 7 hay 60 ngày. Rất giàu có trong vòng 1 năm thông qua bất động sản.";
  static giai_thich_dinh_kham =
    "Người bồng theo 1 đứa trẻ, mưa và gió ở Tây Bắc, người ở Tây Bắc treo cổ tự tử. Người đến dẫn theo 1 đứa trẻ con. Mây và mưa từ phía Nam đến. Chim đen và thú hoang đến từ phía Tây. Trong vòng 100 ngày, sẽ có ăn mừng hỉ sự như lễ cưới. Hiệu ứng: Sẽ rất giàu có; sẽ có giàu có “đen” trong vòng 14 ngày.";
  static giai_thich_dinh_chan =
    "Người dẫn theo con vật hay rượu/thức ăn đến, chim bay hay vật thể bay đến từ phía Nam, 2 người phụ nữ đến từ phía Nam, người đem rượu. 2 người phụ nữ mặc quần áo màu xanh lá cây bước ngang qua, hay một cặp vợ chồng, hay chim màu đen hay trắng và thú hoang đến từ phía Nam. Trong vòng 70 ngày, thú vật màu vàng và trắng sẽ nhận được và sẽ có tài lộc. Hiệu ứng: Sẽ đạt được tài lộc về gia súc và vật thể màu vàng/trắng trong vòng 7 ngày.";
  static giai_thich_dinh_ton =
    "Trẻ con cưỡi trâu (hay xe hơi/gắn máy), mưa và gió, người nhảy vào nước, chơi với nước. Trẻ con cưỡi ngựa đi qua phía Nam. Mây đến từ phía Bắc kéo đến cho đến khi mưa. Sau lễ kỷ niệm, người rơi xuống nước sẽ bị chết chìm. Người phụ nữ có thai sẽ khó sinh con. Hiệu ứng: Mang thai trẻ con có tài năng trong vòng 7 ngày; tài lộc bất ngờ đến trong vòng 21 ngày.";
  static giai_thich_dinh_doai =
    "Người cầm giấy và bút, người giăng lưới, chim vàng hay vật bay. Sự xuất hiện của sách và tài liệu chứng từ. Hay theo đuổi gia súc, cừu và hươu nai. Trong vòng 60 ngày, sẽ giàu có. Hiệu ứng: Tài lộc nhiều đến nếu ở phía Đông Bắc hay Tây Nam có lửa hay người chết vì bệnh tật nặng, tài lộc dưới dạng lợn hay gà đến trong vòng 7 ngày; có bất động sản trong vòng 14 ngày.";
  static giai_thich_dinh_canas =
    "Xem trẻ con cầm 1 đồ màu xanh lá hay vàng, trẻ con cầm theo đồ kim loại, con người cầm bút, giấy và sách. Một người dẫn theo trẻ con đánh 1 con chó. Sau 7 ngày hay trong vòng 70 ngày, sinh vật màu vàng hay đen sẽ đến. Trong vòng 6 tháng, người và công đức sẽ nhận được. Thịnh vượng. Hiệu ứng: Đạt được tài lộc thông qua vật màu xanh lá, vàng và trắng trong vòng 21 ngày. Bất động sản và mang thai trong vòng 100 ngày.";

  static giai_thich_truc_phu_do_mon =
    "Trời quang có nhiều mây nhưng không mưa.";
  static giai_thich_truc_phu_canh_mon =
    "Mây và mưa rải rác. Tới ngày Sửu, sẽ có mưa.";
  static giai_thich_truc_phu_tu_mon =
    "Mưa cả ngày. Đợi gió Đông Nam thì trời sẽ quang. Tháng 2 hay 8 thì sấm sẽ đến từ dưới đất.";
  static giai_thich_truc_phu_thuong_mon = "Mặt trời bị che mờ. Bão bất ngờ.";
  static giai_thich_truc_phu_kinh_mon =
    "Hầu như mưa nhưng sẽ dừng lại và sẽ trở nên quang đãng khi đến ngày Thìn và Tỵ.";
  static giai_thich_truc_phu_sinh_mon =
    "Sấm chớp lớn, kết thúc vào giờ Mùi. Gió từ phía Bắc đến và dừng lại sau 3 ngày.";
  static giai_thich_truc_phu_huu_mon =
    "Thời tiết nắng. Gió vào tháng 3 và 4. Sấm vào tháng 6. Tuyết vào tháng 10.";
  static giai_thich_truc_phu_khai_mon =
    "Nắng nếu có Bính. Nếu không, sẽ có mưa.";

  static giai_thich_dang_xa_do_mon =
    "Mây dày nhưng không có mưa. Nhưng hiện diện của Thiên Phụ sẽ dẫn đến mưa trong 3 ngày.";
  static giai_thich_dang_xa_canh_mon =
    "Ngày nắng rực rỡ. Hỏa ở phía Tây trong vòng 3 ngày.";
  static giai_thich_dang_xa_tu_mon =
    "Trời nắng nếu thiếu Kỳ. Nếu có Kỳ và nhiều mây, mưa trong 2 ngày.";
  static giai_thich_dang_xa_thuong_mon = "Mưa hay sương mù mà không có mưa.";
  static giai_thich_dang_xa_kinh_mon =
    "Trời nhiều mây và sương mù. Vào ngày Sửu, trời quang với sự xuất hiện của gió Đông Bắc.";
  static giai_thich_dang_xa_sinh_mon =
    "Có gió. Sắp sửa mưa. Khi gió dừng, trời mưa nhẹ. Ngày Tỵ sẽ có nắng.";
  static giai_thich_dang_xa_huu_mon =
    "Ngày sẽ có nhiều mưa hơn. Sẽ trở thành nắng trước khi đến ngày Bính hay Thìn. Ngày Bính sẽ thấy sấm chớp.";
  static giai_thich_dang_xa_khai_mon = "Ngày có mưa liên tục.";

  static giai_thich_thai_am_do_mon =
    "Xuất hiện của Bính và Đinh sẽ thúc đẩy gió từ phía Nam.";
  static giai_thich_thai_am_canh_mon = "Nhiều gió và sấm chớp, ít mưa.";
  static giai_thich_thai_am_tu_mon =
    "Hôm nay sẽ có mưa. Ngày tiếp theo sẽ nắng. Xuất hiện của gió Tây sẽ có trời quang đãng.";
  static giai_thich_thai_am_thuong_mon =
    "Thời tiết quang đãng và có gió Đông Bắc. Bắt đầu từ giờ Tỵ và dừng vào Thân. Nếu Thiên Phụ xuất hiện, mưa sẽ đến.";
  static giai_thich_thai_am_kinh_mon = "Mưa liên tục.";
  static giai_thich_thai_am_sinh_mon =
    "Với sự xuất hiện của Bính hay Đinh, trời trong và gió thổi từ Đông Nam. Xuất hiện của Ất sẽ đem đến mưa. Thiếu Tam Kỳ, mưa nhỏ xuất hiện bất ngờ.";
  static giai_thich_thai_am_huu_mon =
    "Ngày thường âm u. Sẽ có mưa khi Thiên Bồng xuất hiện. Xuất hiện vào ngày 2 và 8.";
  static giai_thich_thai_am_khai_mon = "Mưa, nhưng sẽ nhanh chóng ngớt.";

  static giai_thich_luc_hop_canh_mon = "Thỉnh thoảng có sấm chớp.";
  static giai_thich_luc_hop_tu_mon =
    "Thời tiết có sấm và chớp. Trời nắng nếu không có Kỳ.";
  static giai_thich_luc_hop_thuong_mon =
    "Sự xuất hiện của Thiên Tâm, gió và mưa xuất hiện cùng lúc.";
  static giai_thich_luc_hop_kinh_mon =
    "Thời tiết nắng. Có Đinh sẽ dẫn đến hạn hán.";
  static giai_thich_luc_hop_sinh_mon =
    "Hiện diện ở Thiên Bồng. Xuất hiện mưa bão trong giờ Ngọ. Thiếu sao Thiên Bồng sẽ có sấm nhưng không có mưa.";
  static giai_thich_luc_hop_huu_mon =
    "Ất kỳ, nắng nôi. Giờ Thân và thiếu Kỳ, gió từ phía Bắc sẽ đến.";
  static giai_thich_luc_hop_khai_mon = "Vào tiết Hàn Lộ, nghe thấy tiếng sấm.";

  static giai_thich_bach_ho_do_mon =
    "Sương mù nặng. Trời quang khi gió ẩm mang hơi nước đến.";
  static giai_thich_bach_ho_canh_mon =
    "Thiên Tâm xuất hiện thì vừa có mưa vừa có nắng. Sau 3 ngày thì quang đãng.";
  static giai_thich_bach_ho_tu_mon =
    "Mây vàng tụ ở cung Tây Bắc. Mưa sau 5 ngày.";
  static giai_thich_bach_ho_thuong_mon =
    "Đinh ở cung Khảm dẫn đến sấm chớp. Thiếu Tam Kỳ, trời quang.";
  static giai_thich_bach_ho_kinh_mon =
    "Sau giờ Ngọ, mây hình thù lạ xuất hiện. Sau 3 ngày, gió rất mạnh làm đổ căn nhà.";
  static giai_thich_bach_ho_sinh_mon =
    "Ngày nắng. Gió đông nam sau 12h trưa. Kéo dài trong 3 ngày.";
  static giai_thich_bach_ho_huu_mon =
    "Thời tiết mưa. Sau 1 ngày, trời trong sáng.";
  static giai_thich_bach_ho_khai_mon =
    "Trời quang nếu có Kỳ. Mưa nếu không có Kỳ.";

  static giai_thich_huyen_vu_do_mon =
    "Xuất hiện gió và sấm. Dừng vào ngày Giáp Dần. Tia nắng mặt trời xuất hiện.";
  static giai_thich_huyen_vu_canh_mon = "Trời nắng.";
  static giai_thich_huyen_vu_tu_mon = "Có Thiên Bồng – mưa lớn trong 3 ngày.";
  static giai_thich_huyen_vu_thuong_mon =
    "Mưa nặng liên tục. Giảm bớt hạn hán nắng gắt nghiêm trọng. Mưa rải rác vào ngày Tuất.";
  static giai_thich_huyen_vu_kinh_mon =
    "Xuất hiện Đinh thì thời tiết quang đãng từ giờ Thìn đến Tỵ. Nhưng mưa sẽ đến vào giờ Ngọ đến Mùi, sau đó sẽ quang đãng.";
  static giai_thich_huyen_vu_sinh_mon =
    "Mưa rào qua. Xảy ra vào ngày Ngọ hay Tuất.";
  static giai_thich_huyen_vu_huu_mon =
    "Mây trắng và trời trong sáng. Trời chuyển sang màu trong sáng sau khi 3 ngày có mây màu vàng và mưa lớn.";
  static giai_thich_huyen_vu_khai_mon =
    "Trong ngày Canh Ngọ, giờ Mùi, gió Đông Bắc đến. Không mưa.";

  static giai_thich_cuu_dia_do_mon = "Mây. Gió từ bắc nhưng không có mưa.";
  static giai_thich_cuu_dia_canh_mon =
    "Mưa liên tục. Xuất hiện Kỳ ở ngày Tỵ và Ngọ sẽ xuất hiện nắng. Mưa, nếu không có Kỳ.";
  static giai_thich_cuu_dia_tu_mon =
    "Thời tiết nhiều nắng. Xuất hiện sao Dực hay Chẩn thì sẽ có mưa nhỏ.";
  static giai_thich_cuu_dia_thuong_mon =
    "Nếu vào ngày Sửu hay Mùi thì dễ gặp yến tiệc, trên đường đi gặp sự cố, bất an.";
  static giai_thich_cuu_dia_kinh_mon =
    "Mây và gió vào giờ Tỵ và Ngọ nhưng sẽ không có mưa.";
  static giai_thich_cuu_dia_sinh_mon =
    "Ngày nhiều mây. Gió từ phía Bắc nhưng không mưa.";
  static giai_thich_cuu_dia_huu_mon =
    "Đòi phải có mưa nhưng thay vì đó lại có mưa. Và ngược lại. Xuất hiện Thiên Bồng, mưa bão.";
  static giai_thich_cuu_dia_khai_mon =
    "Trời nắng nhưng sẽ có mưa, nhưng chỉ là 1 cơn mưa rào nhỏ.";

  static giai_thich_cuu_thien_do_mon =
    "Ngày nắng. Nếu có mây màu vàng hay cát bụi, sẽ có mưa vào buổi chiều.";
  static giai_thich_cuu_thien_canh_mon =
    "Sẽ có mưa trong vòng 2 đến 3 ngày. Khi gió Đông đến vào ngày Ngọ, sẽ có mưa theo sau.";
  static giai_thich_cuu_thien_tu_mon =
    "Trời âm u nhiều gió, gặp chuyện tiểu cát.";
  static giai_thich_cuu_thien_thuong_mon =
    "Có mưa nặng liên tục. Hạn hán nặng nề.";
  static giai_thich_cuu_thien_kinh_mon =
    "Nắng vào ngày Dần và Tỵ. Mưa vào ngày Ngọ và Mùi. Sấm vào ngày Tý hay Sửu.";
  static giai_thich_cuu_thien_sinh_mon =
    "Xuất hiện Thiên Nhậm sẽ có mưa cả ngày. Mùa đông sẽ có tuyết cả ngày. Trời nắng nhưng trời không trong.";
  static giai_thich_cuu_thien_huu_mon =
    "Mây tán, mưa ngừng. Mặt trời xuất hiện. Ngày Ngọ và Mùi, nắng.";
  static giai_thich_cuu_thien_khai_mon =
    "Trời quang. Rất gió vào ngày Ngọ và Mùi. Trong mùa đông, sẽ có tuyết vào ngày Hợi và Tỵ. Hầu như không có nắng.";

  static giai_thich_ty_thien_bong =
    "Gà gáy, chó sủa, đàn chim, chim hót tại phía Bắc. Trong lúc xây dựng hay chôn cất, một người đàn ông bị sứt môi sẽ đi đến. Trong vòng 60 ngày, gà sẽ ấp trứng, có quan tụng, chủ thoái tài.";
  static giai_thich_ty_thien_nhue =
    "Chim sợ hãi và bay đi, phía Tây Nam có ánh lửa, 2 người chạy đuổi nhau. Sau khi tạo tang, thì có chó mèo cắn gây tổn thương cho người, gặp kiện tụng. Trong vòng 60 ngày, có người phụ nữ tự sát. Mọi việc xảy ra trong mùa Thu Đông và có người sẽ thừa kế gia sản và phụ nữ.";
  static giai_thich_ty_thien_xung =
    "Có gió lớn mưa bão đến. Chim và chuông đồng. Sau khi tạo táng, 60 ngày, sinh khí sẽ nhập vào nhà, trong vòng 1 năm, thu hoạch sẽ tăng gấp đôi. Nhưng cẩn thận, cô dâu mới sẽ chết trong khi sinh nở. Sau khi mắc họa khẩu thiệt, cãi nhau sẽ có nhiều tiền.";
  static giai_thich_ty_thien_phu =
    "Một người mặc đồ đỏ hoặc trắng đến từ phía Tây và bắt đầu la hét. 60 ngày sau khi tạo tang thì sẽ thừa hưởng gia tài. Tiền bạc sẽ đổ vào nhà và tạo ra âm thanh ồn ào. Điều này ám chỉ thăng tiến và sinh quý tử. Nếu Kỳ Môn có, 12 năm thịnh vượng.";
  static giai_thich_ty_thien_cam =
    "Người phụ nữ có bầu đi đến và có người mặc đồ màu tím. 60 ngày sau khi tạo tang thì gà trống sẽ bay lên hàng rào, chó cắn hoa, và một học giả sẽ đem 1 vật đến. Ám chỉ thăng tiến, nhận được tài sản. Sau 20 năm, tài lộc thịnh vượng và con cháu đầy đàn.";
  static giai_thich_ty_thien_tam =
    "Tranh cãi và đánh nhau. Trống đánh từ phía Tây Bắc. 100 ngày sau khi chôn cất hay tạo tang, người mặt đỏ sẽ nhận đồ cổ và tranh ảnh. Gà màu trắng sẽ được sinh ra trong nhà. Trong vòng 12 năm, thu hoạch sẽ nhiều nhưng sau đó người này sẽ phá sản vì kiện tụng.";
  static giai_thich_ty_thien_tru =
    "Gió lớn, lửa cháy từ phía Đông, người đàn ông sứt môi đến. 60 ngày sau khi tạo tang, sẽ có rắn và chó cắn người, người cầm dao gây thương tật cho người khác. Mất tiền.";
  static giai_thich_ty_thien_nham =
    "Mưa bão. Gà mái tạo ra âm thanh gần nước. Sẽ thấy một người cầm dao ở Đông Nam. 100 ngày sau khi tạo táng, người phụ nữ sẽ tự sát, một người có họ là mộc hay thủy sẽ đến nhà bạn để cãi lộn. Trong nhà sẽ xuất hiện nam tặc nữ gian sau khi thu hồi ruộng đất điền sản.";
  static giai_thich_ty_thien_anh =
    "Có tiếng cồng từ Tây Bắc. 3 đến 5 người cầm đuốc và búa. Một người sứt môi xuất hiện. Trong vòng 1 năm sau khi tạo tang, gia đình sẽ phá sản. Trong vòng 3 năm sẽ có nạn đổ máu và trẻ nhỏ sẽ bị lửa hại.";

  static giai_thich_suu_thien_bong =
    "Cây đổ đè thương người. Có sâm chớp, mưa gió là ứng. Sau khi táng xong trong vòng 7 ngày, gà sinh chim tý mão, chó làm chủ nhà, chủ trẻ con bị chết. 3 năm sau ngày táng thì thì có ông già tóc bạc, ruộng vườn tăng, đại vượng tài lộc, sau 10 năm tức suy bại.";
  static giai_thich_suu_thien_nhue =
    "Có tiếng cồng từ phía tây bắc. Bảy ngày sau khi chôn cất hoặc xây dựng, con rùa sẽ bò ra từ rừng. Sau 60 ngày sẽ gặp cướp, kiện tụng, hao tài...";
  static giai_thich_suu_thien_xung =
    "Nhiều mây và sương mù. Trẻ em và phụ nữ xuất hiện, sau khi chôn cất và xây dựng, mèo đen sẽ sinh r mèo trắng. Phát hiện đồ cổ. Trong vòng 1 năm sẽ nhận được tước hiệu từ vị sư hay giáo sĩ. Sinh quý tử.";
  static giai_thich_suu_thien_phu =
    "Chó sủa ở phía đông. Người cầm dao và đâm. Sau khi chôn cất hoặc xây dựng, mèo, thỏ và gà hoang sẽ xuất hiện. Trong vòng 60 ngày, nhận được quà từ một vị sư hay linh mục. Nhận được thư từ của Dục Âm Nhân từ phía đông nam. Trong vòng 1 năm sẽ nhận được tài lộc và thưởng.";
  static giai_thich_suu_thien_cam =
    "Phụ nữ mặc quần áo tang cầm theo ca nước. Một đứa trẻ vỗ tay, huýt sáo hoặc nhảy nhót. Sau khi chôn cất hay xây dựng sẽ giàu có thông qua đánh bạc, hoặc phát hiện kho tàng ẩn dấu. Hoặc sau 3 năm sẽ giàu có vì bắt được bọn cướp, thổ phỉ.";
  static giai_thich_suu_thien_tam =
    "Ánh sáng từ phía nam. Một người tàn tật sẽ giao một món hàng. Trong vòng 5 ngày, 2 con mèo sẽ đến. 40 ngày sau khi chôn cất hoặc xây dựng, người nào đó sẽ mang đến giấy tờ, tiền bạc, quà cáp. Nếu là quan chức chính phủ thì sẽ sống thọ.";
  static giai_thich_suu_thien_tru =
    "Thợ mộc từ phía bắc cầm theo 1 cái rìu. Hoa trắng nở trên cây. 60 ngày sau khi chôn cất hoặc xây dựng sẽ nhận được tiền, vàng, bạc. 3 năm sau sẽ bị cháy dẫn đến phá sản. Sau đó sẽ chuyển sang làm nghề nuôi, huân luyện chó, rắn.";
  static giai_thich_suu_thien_nham =
    "Người mặc áo màu xanh lá cây sẽ cầm theo rượu và nghe thấy tiếng trống. Sau 6 tháng kể từ khi xây cất hoặc chôn cất sẽ nhận được tiền từ người vô danh. 1 năm sau, 1 con két bay vào nhà và nhận được tiền từ cuộc cãi vả. 3 năm sau, chó và mèo cắn nhau và người nhà sẽ nhận được thành tích học tập tốt.";
  static giai_thich_suu_thien_anh =
    "Phù thủy xuất hiện từ phía đông bắc. nghe được tiếng cồng. Sau 1 tháng kể từ xây cất hoặc chôn cất, nhà bị cháy. Trong vòng 1 năm, nhiều điều lạ xuất hiện, như chó nói được tiếng người. nên nhanh chóng dọn nhà đi để tránh cái chết.";

  static giai_thich_dan_thien_bong =
    "Trẻ con mặc quần áo xanh lá cầm theo bông hoa. Nhà sư hay đạo sĩ có khăn choàng đầu ở phía bắc. phụ nữ mặc váy. Sau khi chôn cất hay xây dựng sẽ có trộm. trong vòng 60 tháng, rắn sẽ bò vào nhà và cắn một ai đó. Bò hoặc ngựa sẽ chết. Nhưng sau 3 năm gia đình sẽ giàu có.";
  static giai_thich_dan_thien_nhue =
    "Một phụ nữ gầy mang thai và một người mặc áo mưa xuất hiện. nghe thấy tiếng nhạc. Sauk hi chôn cất hoặc xây dựng sẽ thịnh vượng nếu như có kỳ và môn. Trâu vào nhà trong 60 ngày. Tiền đi theo. Quan chức sẽ được thắng tiến, con cháu thịnh vượng.";
  static giai_thich_dan_thien_xung =
    "Quý nhân sẽ xuất hiện trên kiệu và 1 bé trai sẽ cầm theo vàng và đồ bạc. Sau khi chôn cất hoặc xây dựng sẽ nhận được tước hiệu, xúc vật và đồ quy. Trong vòng 60 ngày, gà sẽ gáy và điều này ám chỉ tai họa sẽ rơi xuống nhà. Người sinh năm Ất, Kỷ, Đinh sẽ gặp may mắn sau một cuộc tranh cãi thị phi.";
  static giai_thich_dan_thien_phu =
    "Cảnh sát trưởng mang theo vật kim loại và nghệ sĩ xuất hiện. trong vòng 60 ngày, mèo sẽ cắn gà. Kẻ trộm sẽ phải trả lại tiền. Trong vòng 12 năm sẽ nhận được tài sản và con cháu.";
  static giai_thich_dan_thien_cam =
    "Gà và chó tạo nên tiếng ồn. người mang mũ rơm xuất hiện. Trong vòng 60 ngày sẽ nhận được tài lộc và thu hoạch tốt trong mọi lĩnh vực.";
  static giai_thich_dan_thien_tam =
    "Cò trắng xuất hiên. Nghe thấy tiếng chuông và tiếng trống. Phụ nữ mặc đồ đỏ và vàng cầm theo túi. Sau khi chôn cất hoặc xây dựng, trẻ em bị bỏng. 60 ngày sau nhận được vàng và bạc. Sau 3 năm nhận được số tiền bì vợ sinh con trai.";
  static giai_thich_dan_thien_tru =
    "Tiếng động từ phía bắc. Đạo sĩ hay nhà sư xuất hiện với một chiếc dù. Mưa và sấm chớp, chuông và trống phát ra âm thanh. 60 ngày sau khi xây cất hoặc chôn cất sẽ bị cướp, kiện tụng, mất tiền. Người phụ nữ có thai chết trong khi thai sản.";
  static giai_thich_dan_thien_nham =
    "1 nhóm phụ nữ cầm theo đuốc, con trai vỗ tay và cười. Người đi kiệu và ngựa đến từ tây bắc. Trong vòng 60 ngày từ khi chôn cất hoặc xât cất, phụ nữ già chết, tiếng hơi nước reo. Trong vòng 100 ngày sẽ nhận được súc vật, tài sản, tài lộc. Sau đó hôn nhân thất bại vì cãi nhau với người không có môi.";
  static giai_thich_dan_thien_anh =
    "Quân lính và ngựa, ngư dân cầm theo lưới và thợ săn xuất hiện từ phía đông. Sau khi chôn cất hoặc xây cất phụ nữ sẽ vấp phải kho tàng. Sẽ nhận được tước hiệu từ 1 quả phụ trong vòng 60 ngày. Trong vòng 100 ngày sấm sẽ đánh vào ngôi nhà dẫn đến ngôi nhà bị sụp đổ.";

  static giai_thich_thin_thien_bong =
    "1 cái cây ở phía đông bắc ngã và gây chấn thương cho 1 người. Nghe thấy tiếng trống. Phụ nữ mặc quần áo đỏ xuất hiện. Sau khi chôn cất hay xây dựng, quạ sẽ đậu trên mái nhà, trộm sẽ đến. Trong vòng 60 ngày 1 người có tật ở chân sẽ đến tranh cãi. Trong vòng 3 năm sẽ có tài lộc và sinh quý tử.";
  static giai_thich_thin_thien_nhue =
    "1 cái cây ở phía đông bắc ngã và gây chấn thương cho 1 người. Nghe thấy tiếng trống. Phụ nữ mặc quần áo đỏ xuất hiện. Sau khi chôn cất hay xây dựng, quạ sẽ đậu trên mái nhà, trộm sẽ đến. Trong vòng 60 ngày 1 người có tật ở chân sẽ đến tranh cãi. Sau đám cưới sẽ có tài lộc và sinh quý tử.";
  static giai_thich_thin_thien_xung =
    "Ca Koi nhảy lên cây. Hổ trắng xuất hiện từ trong núi. Sư sãi và đạo sĩ xuất hiện cùng nhau. Sau khi chôn cất hay xây dựng, có khả năng phát tài. Trong vòng 70 ngày, vợ chồng bị chấn thương..";
  static giai_thich_thin_thien_phu =
    "Dê và chó đụng độ nhau. Người bán rau và người bán dầu tranh cãi. 1 đứa trẻ mặc quần áo trắng khóc trên đường về nhà. Người phụ nữ có thai xuất hiện. Sau khi chôn cất hay xây dựng, nhận được tài lộc và trong vòng 1 năm sẽ sinh đôi.";
  static giai_thich_thin_thien_cam =
    "Phù thủy cạnh tranh nhau. Chim hót ở phía đông. Trong vòng 100 ngày sau khi chôn cất hay xây dựng, đạo sĩ hay vị sư sẽ đem đến một món quà.";
  static giai_thich_thin_thien_tam =
    "Mây mù xuất hiện ở tây bắc. một người mặc quần áo xanh lá đem cá đến. Phụ nữ xuất hiện cùng sư và đạo sĩ. Trong vòng 3 ngày sẽ sinh con trai và nhận được tài lộc, thi cử đổ đạt.";
  static giai_thich_thin_thien_tru =
    "Người cầm theo cây. 1 người đàn ông đem theo trống. 1 ông già cầm theo cuốc. Trong vòng 60 ngày sau khi chôn cất hay xây dựng, mèo đen sẽ sinh ra mèo trắng. Gà mái đẻ trứng có 2 lòng đỏ. Người từ phía bắc sẽ nhận được tiền từ một góa phụ. Trong vòng 3 năm sẽ giàu có.";
  static giai_thich_thin_thien_nham =
    "Người già hàn gắn với anh em trai. Cặp đôi mặt quần áo trắng đi cùng nhau. Người phụ nữ có thai mang theo 1 đứa trẻ. Chim phát ra tiếng động. chó sủa. Sau khi chôn cất hay xây dựng, sẽ có người đem súc vật đến tặng.";
  static giai_thich_thin_thien_anh =
    "Mưa đến từ tây bắc, gà bay lên cây. Phụ nữ mặc quần áo đỏ cầm theo giỏ. Sau khi chôn cất hay xây dụng trong vòng 70 ngày, thú hoang sẽ vào nhà, đại phát tài lộc.";

  static giai_thich_tys_thien_bong =
    "Người bị gù lưng, phụ nữ cầm theo rượu và phù thủy xuất hiện. trong vòng 100 ngày đại phát hoạnh tài. Trong vòng 1 năm, thăng quan tiến chức.";
  static giai_thich_tys_thien_nhue =
    "Phụ nữ có thai mặc đồ màu vàng xuất hiện.Chim bay đến từ phía bắc. Trong vòng 60 ngày sau khi chôn cất hay xây dựng, thừa kế tước hiệu. trong vòng 1 năm sẽ giàu có vì Thủy.";
  static giai_thich_tys_thien_xung =
    "Bò húc nhau, dê đụng độ và 2 người phụ nữ cãi nhau. Động vật từ phía nam gây ồn ào, tiếng trống từ phía tây. Trong vòng 60 ngày sau khi chôn cất hay xây dựng, rắn cắn gà và 1 con bò đi vào nhà. Phụ nữ đem đến tài lộc. sinh quý tử trong vòng 100 ngày.";
  static giai_thich_tys_thien_phu =
    "2 người cãi nhau. Phụ nữ cầm theo vải và gió thổi. 1 đứa trẻ khóc. Trong vòng 60 ngày sau khi chôn cất hay xây dựng, ngũ quỷ vận tài dẫn đến đại phát.";
  static giai_thich_tys_thien_cam =
    "Bầy chim xuất hiện. phù thủy đấu nhau. Quý nhân cưỡi ngựa đến. Trong vòng 70 ngày sau khi chôn cất hay xây dựng, phụ nữ sinh còn trai. Thu hoạch tốt sau 3 năm.";
  static giai_thich_tys_thien_tam =
    "Phụ nữ mặc quần áo màu xanh lá dắt theo đứa trẻ. 1 người mặc đầm tím cưỡi ngựa ngang qua. Trong vòng nửa tháng sẽ nhận được tền từ nơi xa. Gia đình thịnh vượng. Trong vòng 3 năm, quả phụ sẽ cai quản ngôi nhà.";
  static giai_thich_tys_thien_tru =
    "Chuông kêu, Bò đen đi ngang. Lợn đi vào rừng. Nhận được tiền sau khi chôn cất hay xây dựng. Trong vòng 60 ngày, phụ nữ dầm nước. Thú hoang vào nhà, Trong vòng 1 năm sinh quý tử và thịnh vượng.";
  static giai_thich_tys_thien_nham =
    "2 chó cắn nhau. Người đem gỗ đến. Một vị quan cầm ô đi ngang qua, trẻ con vỗ tay và la hét. Nhận được tiền sau khi chôn cất hay xây dựng. Người giao cá đến từ phía nam. Thịnh vượng.";
  static giai_thich_tys_thien_anh =
    "Người cầm theo dù và sách xuất hiện. Phụ nữ mặc quần áo đỏ cãi nhau về chuyện cưới hỏi. Nhận được tiền, trẻ con và giấy tờ trong vòng 60 ngày sau khi chôn cất hay xây dựng.";

  static giai_thich_ngo_thien_bong =
    "Phụ nữ mặc quần áo xanh lá đi kèm với đứa trẻ. Người mang dao đến sườn núi. Ở phía đông bắc có người thợ giày. Trong vòng 40 ngày, chủ nhà chết. trong vòng 60 ngày, chó nói tiếng người và bước vào nhà. 1 người có tật ở chân phạm tội và mất tiền. Trong vòng 3 năm, giàu có bất ngờ.";
  static giai_thich_ngo_thien_nhue =
    "Người không có môi, 1 người mặc quần áo màu trắng kéo theo 1 con bò vàng và phụ nữ có thai đi ngang qua. Trong vòng 60 ngày sau khi chôn cất hay xây dựng, mèo cắn người. Người làm kinh doanh sẽ giàu có.";
  static giai_thich_ngo_thien_xung =
    "Nhà ở phía đông bị cháy, người mặc quần áo trắng la hét. Chim hót. Trong vòng 60 ngày sau khi chôn cất hay xây dựng, sẽ nhặt được đồ cổ có giá trị.";
  static giai_thich_ngo_thien_phu =
    "Sư cầm theo dù và phụ nữ mắc đồ đỏ xuất hiện. Thấy có ánh chớp. Trong 60 ngày, quý nhân đem vật quý đến. Trong 1 năm được thừa kế từ quả phụ.";
  static giai_thich_ngo_thien_cam =
    "Phụ nữ mặc đồ trắng, chó cắn hoa, gà đá nhau. Mưa nhỏ ở phía đông. Trong vòng 60 ngày, người tàn tật sẽ đem đồ cổ đến. Sẽ nhận được tiền. Trong vòng 1 năm, gà đen sẽ đẻ trứng trắng, thừa kế tài sản và trở nên giàu có.";
  static giai_thich_ngo_thien_tam =
    "Mưa bất ngờ. rắn ở giữa đường. Phụ nữ mặc quần áo đỏ cầm theo rượu xuất hiện. Người tàn tật đem súc vật đến. Trong vòng 5 năm đại phát tài sản.";
  static giai_thich_ngo_thien_tru =
    "Quạ bay từ phía tây, có người cưới ngựa đến. Mây tụ lại. 5 ngày sau khi chôn cất hay xây dựng, phụ nữ có thai bị ốm, mặc quần áo tang và khóc. Trong vòng 60 ngày, tìm được đồ cổ dưới nước nhưng bị mất tài lộc.";
  static giai_thich_ngo_thien_nham =
    "Chim vàng bay đến từ phía tây. 1 vị ni và học giả đến từ tây bắc. 40 ngày sau khi chôn cất hay xây dựng nhận được vật giá trị từ quý nhân. 1 người mặc quần áo màu tím đến nhà. Sinh quý tử.";
  static giai_thich_ngo_thien_anh =
    "Người đến từ phía nam, người mặc quần áo màu đổ cưỡi ngựa và cầm theo giấy tờ. 60 ngày sau khi chôn cất hay xây dựng bị thương vì đá mà gỗ. Treo cổ hoặc bị chết chìm.";

  static giai_thich_mui_thien_bong =
    "1 đứa trẻ đuổi bò và ngựa. Chim bay đến từ bắc. Phụ nữ mặc quần áo đỏ xuất hiện. 60 ngày sau khi chôn cất hay xây dựng sẽ bị cướp, kiên tụng.";
  static giai_thich_mui_thien_nhue =
    "Thợ săn xuất hiện. Sư mặc đồ trắng cầm theo 1 túi trà. 7 ngày sau khi chôn cất hay xây dựng quạ bay trên mái nhà. Trong vòng 1 năm, bệnh tật tràn lan. Nhà bị cháy và người bị thương.";
  static giai_thich_mui_thien_xung =
    "Tiếng trống từ phía tây bắc, 1 đứa trẻ mặc quần áo tang, bò và ngựa đi ngang, tiếng la hét và cãi nhau từ tây bắc. 60 ngày sau khi chôn cất hay xây dựng, cần phải tránh kẻ xấu phá hoại. dê trắng vào nhà và dẫn đến thịnh vượng.";
  static giai_thich_mui_thien_phu =
    "Chó sủa. Người đem theo da và lông đến. Sư hay đạo sĩ đến. Saukhi chôn cất hay xây dựng, người đến từ tây bắc cãi nhau vì tiền. Trong vòng 100 ngày nhận được tiền và tài sản có giá trị.";
  static giai_thich_mui_thien_cam =
    "Người lớn tuổi, người tàn tật cầm theo hoa và trái cây đi ngang qua. Người mặc đồ vàng hay xanh lá cầm theo rượu. 60 ngày sau khi chôn cất hay xây dựng, trở nên giàu có.";
  static giai_thich_mui_thien_tam =
    "Mưa nhỏ, người đàn ông mặc áo vàng. Phụ nữ mặc quần áo sặc sỡ cầm theo rượu. Phù thủy cầm theo 1 vật đi ngang qua. Người già mặc đồ trắng xuất hiện. Người tàn tật cưỡi ngựa hay xe. Giàu có.";
  static giai_thich_mui_thien_tru =
    "Phụ nữ mảnh mai đi cùng 1 nhà sư. Người cưỡi ngựa cầm theo cờ, đến từ đông bắc. Sau khi chôn cất hay xây dựng, phụ nữ thấy cáo và dẫn đến tài lộc xấu.";
  static giai_thich_mui_thien_nham =
    "Cò trắng và chim bay đến từ tây nam. Mưa ở phía bắc. Nghe thấy tiếng trống. 7 ngày sau khi chôn cất hay xây dựng, phụ nữ giao giấy trắng hay vải trắng. Thịnh vượng.";
  static giai_thich_mui_thien_anh =
    "Phụ nữ có thai xuất hiện, tiếng trống từ phía tây. 60 ngày sau khi chôn cất hay xây dựng, người chủ nhà bị chết đuối. Trong vòng 1 năm bị bệnh tật.";

  static giai_thich_than_thien_bong =
    "Người giao nước cầm dù đi đến từ phía tây. Một đúa trẻ nghịch nước, tiếng la hét và cười đùa. 10 ngày sau khi chôn cất rắn cắn tổ gà. Trong vòng 100 ngày thiếu nữ treo cổ vì tai tiếng tình dục. Đừng tham gia kiện tụng.";
  static giai_thich_than_thien_nhue =
    "Người đem theo dù, nhà sư và người có râu xuất hiện. Treo và ngựa, thú hoang tấn công người. Trong vòng 100 ngày sau khi chôn cất hay xây dựng nhận thừa kế bất động sản. Trong vòng 1 năm, trâu và chim đi vào nhà, chủ nhà bị bệnh.";
  static giai_thich_than_thien_xung =
    "Người mặc đồ trắng cưới ngựa đến từ phía nam. 2 người mặc đồng phục cãi nhau. Trong vòng 100 ngày sau khi chôn cất hoặc xây dựng, phụ nữ gặp rắc rối với hôn nhân, tăng nhân đinh và tài lộc. Quả phụ bị cướp.";
  static giai_thich_than_thien_phu =
    "Người bị tật ở chân cầm theo rượu. Người có đạo mặc quần áo 3 màu. Tiếng trống từ tây bắc. 6 tháng sau khi chôn cất hoặc xây dựng, rắn bò ra khỏi giếng. Người mặc đồ trắng dẫn theo bò hoặc dê. Giàu có nhờ phụ nữ.";
  static giai_thich_than_thien_cam =
    "Chim hót. Phù thủy cầm theo giấy xuất hiện. Trong vòng 100 ngày sau khi chôn cất hoặc xây dựng, phụ nữ nhặt được châu báu, ngọc ngà. Trong vòng 1 năm cặp đôi mới cưới sẽ sinh con. Thu nhập tốt.";
  static giai_thich_than_thien_tam =
    "Sư xuất hiện, tiếng nhạc và tiếng trống. Chim hót ầm ĩ. Phụ nữ mặc váy đỏ cầm theo rượu. 3 năm sau khi chôn cất hoặc xây dựng, quả phụ cai quản ngôi nhà và trở nên giàu có.";
  static giai_thich_than_thien_tru =
    "Đại bang săn chim trên mặt đất. Người mặc quần áo xanh lá cây cầm dù đi đến. Trong vòng 100 ngày sau khi chôn cất hay xây dựng, nhà bị cháy và giết chết người.";
  static giai_thich_than_thien_nham =
    "Mưa bão lớn. Người chơi trống. Sư mặc đồ màu vàng đi ngang qua. 7 ngày sau khi chôn cất hay xây dựng, phụ nữ bi bổng. Xe cán động vật. Thất bại.";
  static giai_thich_than_thien_anh =
    "Phụ nữ có thai khóc. Tiếng trống và tiếng chuông. Sư cầm theo 1 vật đi ngang qua. 60 ngày sau khi chôn cất và xây dựng, dễ bị tang sự.";

  static giai_thich_dau_thien_bong =
    "Ngựa đỏ đến từ phía Tây. Quạ ồn ào. Sau 100 ngày, sinh con trai. Sư thực hiện nghi lễ và nhận được tiền. Trong vòng 3 năm, gà đẻ trứng có 2 lòng đỏ và mèo đen sinh ra mèo trắng. Con trai đỗ đạt.";
  static giai_thich_dau_thien_nhue =
    "Ngựa kéo xe từ phía Tây. Quả phụ cầm dù. 2 người có râu cãi nhau. Chó sủa người. Chim bay qua phát tiếng ồn. 100 ngày sau khi chôn cất hay xây dựng, nhận được tài sản. Trong vòng 1 năm, ngựa và chim vào nhà, có người ốm.";
  static giai_thich_dau_thien_xung =
    "Người từ xa đem giấy tờ đến phía Đông. Cáo phát ra tiếng kêu. Phụ nữ cầm đuốc đến. Trong vòng 1 năm sau khi chôn cất hay xây dựng, nhận được con cầu tự. May mắn.";
  static giai_thich_dau_thien_phu =
    "Người từ phương xa đem giấy tờ đến phía Đông. Cáo phát ra âm thanh. Phụ nữ cầm đuốc đi đến. Trong vòng 1 năm sau khi chôn cất hay xây dựng, nhận được con cầu tự. May mắn.";
  static giai_thich_dau_thien_cam =
    "Lửa bắt đầu từ phía Tây và người la hét. Tiếng trống. Chim bay và phát ra âm thanh. Trong vòng 1 năm sau khi chôn cất hay xây dựng, sinh ra con cầu tự, giàu có. Bồ câu báo tin vui.";
  static giai_thich_dau_thien_tam =
    "Sư và ni đến. Lửa cháy từ Tây Nam. Tiếng chuông và trống đến từ phía Bắc. Sau khi chôn cất hay xây dựng, nhận được bò và ngựa, và tiền từ chính phủ. Trong vòng 7 ngày, sẽ có đám cưới.";
  static giai_thich_dau_thien_tru =
    "Đại bang trắng tấn công bồ câu. Người hiếp đáp người mặc đồ màu xanh lá cây. Trở thành góa phụ do nhà bị cháy. Nhận được trang sức từ phụ nữ và trở nên giàu có. Cặp đôi hòa hợp và đi lại tốt.";
  static giai_thich_dau_thien_nham =
    "Ni cầm theo đuốc đến từ phía Tây Nam. Trống kêu và chuông từ phía Bắc. Chó về nhà và trẻ con vỗ tay và cười con cáo, thiếu nữ vui vẻ. 60 ngày sau khi chôn cất hay xây dựng, nhận được con cầu tự và nhiều may mắn.";
  static giai_thich_dau_thien_anh =
    "Cãi nhau từ phía Tây. Chim ồn ào. Phụ nữ mặc quần áo màu trắng đi ngang qua. Sau khi chôn cất hay xây dựng, phụ nữ bị thương ở chân. Trong vòng 100 ngày, nhận được tiền do tranh cãi.";

  static giai_thich_tuat_thien_bong =
    "Người có tuổi cầm theo gậy. Mưa nhỏ từ Tây Bắc. Người có râu cầm theo giỏ rơm. Chó màu trắng đi ngang qua. Trong vòng 1 tháng, người nhận được đồ quân sự và giàu có.";
  static giai_thich_tuat_thien_nhue =
    "Bò vàng đi đến. Phụ nữ mang thai cầm theo dù. Chó gây chấn thương người. Trong vòng 1 năm, nhận được đồ giá trị. Đàn chim bay qua. Xe hơi rơi xuống rãnh.";
  static giai_thich_tuat_thien_xung =
    "3 đến 5 người cầm đuốc tìm đồ đánh rơi. Phù thủy đi ngang qua. 60 ngày sau khi chôn cất hay xây dựng, gà gáy và bay lên cây. Trong vòng 1 năm, nam thiếu niên bị bò giẫm.";
  static giai_thich_tuat_thien_phu =
    "3 đến 5 người cầm đuốc tìm đồ đánh rơi. Phù thủy đi ngang qua. 60 ngày sau khi chôn cất hay xây dựng, gà gáy và bay lên cây. Trong vòng 1 năm, nam thiếu niên bị bò giẫm.";
  static giai_thich_tuat_thien_cam =
    "Trống khua, chuông kêu từ phía Tây Bắc. Trẻ con mặc đồ màu xanh lá cây cầm theo giỏ. Trong vòng 1 tháng, chuột trắng xuất.";
  static giai_thich_tuat_thien_tam =
    "Người từ phía Nam la hét nhờ giúp bắt giữ trộm. Trẻ con cưỡi bò. Trong vòng 100 ngày, sinh quý tử. Gà trống gáy. Chó sủa. Sau 2 năm, thi đỗ.";
  static giai_thich_tuat_thien_tru =
    "Phụ nữ cầm theo rượu mặc đồ màu trắng đi đến. Cây ngã ở phía Bắc. Trống kêu ở phía Tây. 60 ngày sau khi chôn cất hay xây dựng, rắn vào nhà và cắn người. Gia đình bệnh tật.";
  static giai_thich_tuat_thien_nham =
    "Trẻ con đến nhà và làm ồn. Chim vàng bay vào. Ni đến. 100 ngày sau khi chôn cất hay xây dựng, một người nhận được tài sản từ người mặc đồ màu tím.";
  static giai_thich_tuat_thien_anh =
    "Phụ nữ cầm theo đồ bằng kim loại la mắng người khác. Trong vòng 100 ngày sau khi chôn cất hay xây dựng, bị thua lỗ tiền bạc do tranh cãi.";

  static giai_thich_hoi_thien_bong =
    "Một nhóm trẻ em đến. Phụ nữ mặc đồ tang. 60 ngày sau khi chôn cất hay xây dựng, nhận được tài lộc sau khi bắt kẻ cướp. Trong vòng 3 năm, người tham gia Đạo và trở nên giàu có nhờ bán thuốc và nước thánh.";
  static giai_thich_hoi_thien_nhue =
    "Thú vật bị sốc và chạy đi xa. Trẻ con tụ tập. Phụ nữ mặc quần áo tang. Tốt trong mùa thu và đông và không phù hợp trong mùa hè và xuân. Lửa bắt đầu ở Tây Nam. Chó và mèo làm tổn thương người. Phụ nữ có thai bị ốm và treo cổ.";
  static giai_thich_hoi_thien_xung =
    "Người bị thương ở chân. Người mặc đồ màu xanh lá cây. Nhà ở Đông Bắc bị cháy. Trong vòng 100 ngày, mèo săn chuột trắng. Thịnh vượng.";
  static giai_thich_hoi_thien_phu =
    "Người bị thương ở chân. Người mặc đồ màu xanh lá cây. Nhà ở Đông Bắc bị cháy. Trong vòng 100 ngày, mèo săn chuột trắng. Thịnh vượng.";
  static giai_thich_hoi_thien_cam =
    "Gió đến từ phía Tây. Phụ nữ từ Tây Băc cười và khóc. Gió mạnh làm trốc rễ cây và phá hủy căn nhà. Người la hét. Sau 60 ngày, người nhận được tiền từ thợ rèn hay sư sãi. Bồ câu đến để báo tin tốt.";
  static giai_thich_hoi_thien_tam =
    "Gà và chó gây tiếng ồn. Người già mang quần áo da và nón xuất hiện với vật bằng kim loại trên tay. Trong vòng 7 ngày, người lữ hành bỏ tiền lại phía sau sau khi ngủ 1 đêm.";
  static giai_thich_hoi_thien_tru =
    "Chuông kêu ở phía Tây. Người la hét ở dưới chân núi. Trong vòng 100 ngày, nhận được tài lộc sau khi cứu người thoát khỏi đám cháy.";
  static giai_thich_hoi_thien_nham =
    "Người cầm theo đuốc đến từ phía Tây la hét. Sau khi chôn cất hay xây dựng, bị thương ở tay do vật bằng kim loại. Trong vòng 100 ngày, tài lộc đến.";
  static giai_thich_hoi_thien_anh =
    "Phụ nữ cầm theo đuốc. Sau khi chôn cất hay xây dựng, trong vòng 100 ngày, người bị bệnh tâm thần đi về nhà tranh cãi. Cây ở phía Bắc ngã và gây chấn thương.";

  static giai_thich_huu_mon_at =
    "Đàn bò và ngựa di chuyển đồ vật/thiết bị, chôn cất, xây dựng. Hiệu ứng: sinh nở tốt (con cái), phát triển thành công về tài lộc và danh tiếng.";
  static giai_thich_huu_mon_binh =
    "Nghe nhạc (trống) trong vòng 50 km, chôn cất, xây dựng, trời đầy mây với cò trắng Hiệu ứng: con cái giàu có và nổi tiếng.";
  static giai_thich_huu_mon_dinh =
    "Gặp người mặc đồ màu đen hay phụ nữ trong vòng 20 km, chôn cất, xây dựng Hiệu ứng: con cái giàu có và nổi tiếng.";

  static giai_thich_sinh_mon_at =
    "Gặp 2 con chuột đánh nhau, người mặc đồ tang, chim, mây và trời đầy gió mưa rào, xe hơi, xe ngựa, động thổ, chôn cất, xây dựng. Hiệu ứng: con cháu sinh nở nhiều, các thế hệ tiếp theo đạt nhiều may mắn thi cử và sự nghiệp quan chức.";
  static giai_thich_sinh_mon_binh =
    "Người có tật ở mắt, người đánh nhau, chôn cất, động thổ. Hiệu ứng: giàu có và con cái nổi tiếng.";
  static giai_thich_sinh_mon_dinh =
    "Săn chim, săn chó, chôn cất, xây cất. Hiệu ứng: con cái sinh nở tốt, tạo ra tài lộc và danh tiếng.";

  static giai_thich_khai_mon_at =
    "Ông già bước với gậy, quan chức mặc đồ màu đỏ khóc, chôn cất, xây dựng. Hiệu ứng: con cái giàu có và nổi tiếng.";
  static giai_thich_khai_mon_binh =
    "Ông già nghèo bước đi với 1 cây chổi, chôn cất, xây dựng. Hiệu ứng: con cái giàu có và nổi tiếng.";
  static giai_thich_khai_mon_dinh =
    "Con nhỏ có gậy, nếu là ngày Mậu Quý với sao Thiên Anh, nghe cò trắng hót và sấm chớp. Hiệu ứng: tài lộc và danh tiếng.";

  static giai_thich_dinh_bat_than_dang_xa = "Ngựa chạy.";
  static giai_thich_dinh_bat_than_thai_am = "Đàn bà chạy.";
  static giai_thich_dinh_bat_than_luc_hop = "Con cái đi xa.";
  static giai_thich_dinh_bat_than_cau_tran =
    "Chiến tranh bắt đầu Đồ tang (có người chết).";
  static giai_thich_dinh_bat_than_chu_tuoc = "Cay đắng và cãi nhau.";
  static giai_thich_dinh_bat_than_giap_at = "Ngựa phi nước đại.";
  static giai_thich_dinh_bat_than_canh = "Xuất hành xấu.";
  static giai_thich_dinh_bat_than_tan = "Xuất hành xấu.";

  static giai_thich_thien_dia_mau_nghi = "Mục đồng cưỡi trâu.";
  static giai_thich_thien_dia_ky_nghi = "Cây mục, lửa củi.";
  static giai_thich_thien_dia_canh_nghi = "Dao, bùa và đồ kim loại.";
  static giai_thich_thien_dia_tan_nghi = "Cối xay thuốc, lừa, ngựa.";
  static giai_thich_thien_dia_nham_nghi = "Cá và rùa đấu nhau.";
  static giai_thich_thien_dia_quy_nghi = "Hoa, trái cây, gia súc.";

  static giai_thich_huu_kham =
    "1 km hay 11 km, thấy cặp đôi mặc quần áo xanh lá cây ca hát.";
  static giai_thich_huu_khon =
    "2 km hay 12 km, thấy người mặc đồ tang, có người mặc đồ màu xanh lá đi cùng.";
  static giai_thich_huu_chan =
    "3 km hay 13 km, thấy thợ mộc với gậy hay đàn ông mặc dong phục màu đen.";
  static giai_thich_huu_ton =
    "4 km hay 14 km,thấy phụ nữ mặc đồ xanh lá cây và trẻ em đi lại và ca hát.";
  static giai_thich_huu_can =
    "6 km hay 16 km, thấy người ta đánh nhau và thở dài.";
  static giai_thich_huu_doan =
    "7 km hay 17 km, thấy người mặc quần áo màu đen đánh vào chân và phụ nữ kéo và đem theo đứa trẻ.";
  static giai_thich_huu_canas =
    "8 km hay 18 km, thấy người phụ nữ đổ đất màu đen và vàng và người mặc đồng phục màu đen.";
  static giai_thich_huu_ly =
    "9 km hay 19 km, thấy người mặc đồng phục màu đen cưỡi ngựa.";

  static giai_thich_sinh_kham =
    "1 km hay 11 km, thấy người mặc đồ đen và mang theo vật quý giá.";
  static giai_thich_sinh_khon =
    "2 km hay 12 km, thấy người mặc quần áo tang khóc lóc.";
  static giai_thich_sinh_chan =
    "3 km hay 13 km, thấy quan chức cầm gậy hay trồng cây.";
  static giai_thich_sinh_ton =
    "4 km hay 14 km, thấy người mang vật nhiều màu sắc và thở dài.";
  static giai_thich_sinh_can =
    "6 km hay 16 km, thấy quý nhân, xe đẩy và ngựa. Rắn cắn lợn.";
  static giai_thich_sinh_doan =
    "7 km hay 17 km, thấy người xua đuổi gia súc và nói chuyện pháp lý hay than phiền.";
  static giai_thich_sinh_canas = "8 km hay 18 km, thấy quý nhân mặc đồ màu đỏ.";
  static giai_thich_sinh_ly =
    "9 km hay 19 km, thấy quý nhân, xe cộ, ngựa và nhiều người theo hầu.";

  static giai_thich_thuong_kham =
    "1 km hay 11 km, thấy phụ nữ lớn tuổi và thanh niên đi cùng nhau.";
  static giai_thich_thuong_khon =
    "2 km hay 12 km, thấy tang lễ và người mặc quần áo tang khóc lóc.";
  static giai_thich_thuong_chan =
    "3 km hay 13 km, thấy 2 xe trên đường tranh nhau không nhường đường.";
  static giai_thich_thuong_ton =
    "4 km hay 14 km, thấy người té cây và phụ nữ ẵm trẻ.";
  static giai_thich_thuong_can =
    "6 km hay 16 km, thấy người dỡ tường, lắp tường hay 2 heo cắn nhau.";
  static giai_thich_thuong_doan =
    "7 km hay 17 km, thấy người đánh nhau hay xua súc vật, phụ nữ và thiếu nữ đi cùng nhau.";
  static giai_thich_thuong_canas =
    "8 km hay 18 km, thấy cây ngã hay đất sạt lở.";
  static giai_thich_thuong_ly = "9 km hay 19 km, thấy người cưỡi ngựa hay lừa.";

  static giai_thich_do_kham =
    "1 km hay 11 km, thấy phụ nữ mặc đồ đen ẵm đứa trẻ hay người hát.";
  static giai_thich_do_khon =
    "2 km hay 12 km, thấy người mặc đồ tang khóc lóc).";
  static giai_thich_do_chan = "3 km hay 13 km, thấy thợ mộc cầm gậy.";
  static giai_thich_do_ton =
    "4 km hay 14 km, thấy phụ nữ và trẻ con mặc đồ xanh lá.";
  static giai_thich_do_can =
    "6 km hay 16 km, nghe tiếng nhạc và bài hát, chó cắn heo.";
  static giai_thich_do_doan =
    "7 km hay 17 km, nghe nhạc và bài hát, tiếng trống hay người nói về than phiền, cãi nhau.";
  static giai_thich_do_canas =
    "8 km hay 18 km, thấy người đem theo vật quý giá hay người cầm theo thức ăn và hát.";
  static giai_thich_do_ly =
    "9 km hay 19 km, thấy phụ nữ mang thai hay người mặc đồng phục cưỡi ngựa đỏ.";

  static giai_thich_canh_kham =
    "1 km hay 11 km, thấy phụ nữ sụt sùi và ngư dân đi cùng.";
  static giai_thich_canh_khon =
    "2 km hay 12 km, thấy người mặc quần áo màu xanh dương khóc và người cưỡi ngựa.";
  static giai_thich_canh_chan =
    "3 km hay 13 km, thấy phụ nữ ngồi kiệu hay cưỡi ngựa.";
  static giai_thich_canh_ton =
    "4 km hay 14 km, sẽ thấy phụ nữ già và trẻ đi cùng đứa trẻ mặc đồ đen.";
  static giai_thich_canh_can =
    "6 km hay 16 km, sẽ thấy người đi thành nhóm và cảnh sát cưỡi ngựa.";
  static giai_thich_canh_doan =
    "6 km hay 16 km, sẽ thấy người đi thành nhóm và cảnh sát cưỡi ngựa.";
  static giai_thich_canh_canas =
    "8 km hay 18 km, thấy trẻ con xua đuổi bò và người có túi chứa vật có giá trị.";
  static giai_thich_canh_ly =
    "9 km hay 19 km, thấy người mang theo sách và giấy tờ, tai họa cháy nổ và xui xẻo.";

  static giai_thich_tu_kham =
    "1 km hay 11 km, thấy người mặc quần áo xanh lá khóc.";
  static giai_thich_tu_khon = "2 km hay 12 km, thấy phụ nữ khóc.";
  static giai_thich_tu_chan = "3 km hay 13 km, thấy người mang quan tài.";
  static giai_thich_tu_ton =
    "4 km hay 14 km, thấy tang lễ và giấy nghệ thuật nhiều màu.";
  static giai_thich_tu_can =
    "6 km hay 16 km, thấy người đào mộ, khóc hay súc vật đánh nhau và bị thương.";
  static giai_thich_tu_doan =
    "7 km hay 17 km, thấy tang lễ, khóc và động vật chết.";
  static giai_thich_tu_canas =
    "8 km hay 18 km, thấy người mặc đồ tang mang theo động vật sống khóc than.";
  static giai_thich_tu_ly =
    "9 km hay 19 km, thấy phụ nữ khóc. Rút lui và không tiến về phía trước.";

  static giai_thich_kinh_kham =
    "1 km hay 11 km, thấy phụ nữ mặc quần áo xanh lá nói về kiện tụng.";
  static giai_thich_kinh_khon =
    "2 km hay 12 km, thấy phụ nữ khóc và có người đang chịu tang.";
  static giai_thich_kinh_chan =
    "3 km hay 13 km, thấy cặp đôi cãi nhau và giáo huấn trẻ con. Khuyên nên rút lui. Nếu không, sẽ chịu xấu.";
  static giai_thich_kinh_ton =
    "4 km hay 14 km, thấy nhà sư và đạo sĩ đi cùng nhau hay cặp đôi đang nói chuyện.";
  static giai_thich_kinh_can =
    "6 km hay 16 km, thấy quan chức và quân đội cãi nhau.";
  static giai_thich_kinh_doan =
    "7 km hay 17 km, tháy 2 phụ nữ nói móc, cạnh khóe về kiện tụng pháp lý.";
  static giai_thich_kinh_canas =
    "8 km hay 18 km, thấy phụ nữ và trẻ con đuổi bo2. Trẻ con cầm thức ăn.";
  static giai_thich_kinh_ly = "9 km hay 19 km, thấy phụ nữ nói về pháp lý.";

  static giai_thich_khai_kham =
    "1 km hay 11 km, thấy động vật 4 chân cắn nhau, phụ nữ mặc đồ đen và một học giả nói chuyện.";
  static giai_thich_khai_khon =
    "2 km hay 12 km, sẽ thấy người già nức nở hay đào xới, chôn cất.";
  static giai_thich_khai_chan =
    "3 km hay 13 km, sẽ thấy phụ nữ, ngựa, xe và lửa.";
  static giai_thich_khai_ton =
    "4 km hay 14 km, sẽ thấy đàn ông ca hát hay người mộ đạo).";
  static giai_thich_khai_can =
    "6 km hay 16 km, thấy quý nhân và người thường đánh nhau.";
  static giai_thich_khai_doan =
    "7 km hay 17 km, sẽ thấy anh em và chị em cùng nhau.";
  static giai_thich_khai_canas =
    "8 km hay 18 km, thấy phụ nữ và động vật 4 chân. Hay đàn ông tranh cãi về tiền bạc.";
  static giai_thich_khai_ly =
    "9 km hay 19 km, sẽ thấy quý nhân cưỡi ngựa hoặc mang theo sách và giấy tờ chứng từ.";

  static tam_ky_vuong_tuong = "TAM KỲ VƯỢNG TƯỚNG";
  static hoan_dich_cach = "HOAN DỊCH CÁCH";
  static tuong_ta_cach = "TƯƠNG TẢ CÁCH";
  static thien_don = "THIÊN ĐỘN";
  static dia_don = "ĐỊA ĐỘN";
  static nhan_don = "NHÂN ĐỘN";
  static van_don = "VÂN ĐỘN";
  static phong_don = "PHONG ĐỘN";
  static long_don = "LONG ĐỘN";
  static ho_don = "HỔ ĐỘN";
  static than_don = "THẦN ĐỘN";
  static quy_don = "QUỶ ĐỘN";
  static nhat_ky_dac_xu = "NHẬT KỲ ĐẮC XỬ";
  static nguyet_ky_dac_xu = "NGUYỆT KỲ ĐẮC XỬ";
  static tinh_ky_dac_xu = "TINH KỲ ĐẮC XỬ";
  static nhat_ky_thang_dien = "NHẬT KỲ THĂNG ĐIỆN";
  static nguyet_ky_thang_dien = "NGUYỆT KỲ THĂNG ĐIỆN";
  static tinh_ky_thang_dien = "TINH KỲ THĂNG ĐIỆN";
  static chan_tam_tra = "CHÂN TAM TRÁ";
  static huu_tam_tra = "HƯU TAM TRÁ";
  static trung_tam_tra = "TRÙNG TAM TRÁ";
  static thien_gia = "THIÊN GIẢ";
  static dia_gia = "ĐỊA GIẢ";
  static nhan_gia = "NHÂN GIẢ";
  static than_gia = "THẦN GIẢ";
  static quy_gia = "QUỶ GIẢ";
  static thien_phu_chi_thoi = "THIÊN PHỤ CHI THỜI";
  static thanh_long_dao_tau = "THANH LONG ĐÀO TẨU";
  static bach_ho_cuong_vong = "BẠCH HỔ CUỒNG VONG";
  static chu_tuoc_dau_giang = "CHU TƯỚC ĐẦU GIANG";
  static dang_xa_yeu_kieu = "ĐẰNG XÀ YÊU KIỀU";
  static thai_bach_nhap_lao = "THÁI BẠCH NHẬP LAO";
  static lao_nhap_thai_bach = "LAO NHẬP THÁI BẠCH";
  static nhat_cach = "NHẬT CÁCH (THIÊN ẤT PHỤC CAN CÁCH)";
  static thien_at_phi_can_cach = "THIÊN ẤT PHI CAN CÁCH";
  static thoi_cach = "THỜI CÁCH";
  static dai_cach = "ĐẠI CÁCH";
  static thuong_cach = "THƯỢNG CÁCH";
  static hinh_cach = "HÌNH CÁCH";
  static ky_cach = "KỲ CÁCH";
  static chien_cach = "CHIẾN CÁCH";
  static tam_ky_nhap_mo = "TAM KỲ NHẬP MỘ";
  static tam_ky_tho_che = "TAM KỲ THỌ CHẾ";
  static luc_nghi_kich_hinh = "LỤC NGHI KỊCH HÌNH";
  static thien_vong_tu_truong = "THIÊN VÕNG TỨ TRƯỚNG";
  static dia_vong = "ĐỊA VÕNG";
  static thien_at_phuc_cung_cach = "THIÊN ẤT PHỤC CUNG CÁCH";
  static thien_at_phi_cung_cach = "THIÊN ẤT PHI CUNG CÁCH";
  static bot_cach = "BỘT CÁCH";
  static huong = {
    DONG: { id: 0, show: "Đông" },
    TAY: { id: 1, show: "Tây" },
    NAM: { id: 2, show: "Nam" },
    BAC: { id: 3, show: "Bắc" },
    DONG_NAM: { id: 4, show: "Đông Nam" },
    DONG_BAC: { id: 5, show: "Đông Bắc" },
    TAY_NAM: { id: 6, show: "Tây Nam" },
    TAY_BAC: { id: 7, show: "Tây Bắc" },
  };
  static trucSao = {
    Kien: { id: 1, name: "Kiến" },
    Man: { id: 2, name: "Mãn" },
    Dinh: { id: 3, name: "Định" },
    Thanh: { id: 4, name: "Thành" },
    Khai: { id: 5, name: "Khai" },
    Be: { id: 6, name: "Bế" },
    Tru: { id: 7, name: "Trừ" },
    Binh: { id: 8, name: "Bình" },
    Chap: { id: 9, name: "Chấp" },
    Pha: { id: 10, name: "Phá" },
    Nguy: { id: 11, name: "Nguy" },
    Thu: { id: 12, name: "Thu" },
  };
  static timeSelection = [
    { id: 13, time: "Giờ Tý 0h-01h", value: 0 }, // ngày hôm trước
    { id: 2, time: "Giờ Sửu 01h-03h", value: 2 },
    { id: 3, time: "Giờ Dần 03h-05h", value: 4 },
    { id: 4, time: "Giờ Mẹo 05h-07h", value: 6 },
    { id: 5, time: "Giờ Thìn 07h-09h", value: 8 },
    { id: 6, time: "Giờ Tỵ 09h-11h", value: 10 },
    { id: 7, time: "Giờ Ngọ 11h-13h", value: 12 },
    { id: 8, time: "Giờ Mùi 13h-15h", value: 14 },
    { id: 9, time: "Giờ Thân 15h-17h", value: 16 },
    { id: 10, time: "Giờ Dậu 17h-19h", value: 18 },
    { id: 11, time: "Giờ Tuất 19h-21h", value: 20 },
    { id: 12, time: "Giờ Hợi 21h-23h", value: 22 },
    { id: 1, time: "Giờ Tý 23h-24h", value: 23 }, // ngày hiện tại
  ];
  static initDateData = [
    {
      header: "Giờ",
      canChi: "-",
      diaChi: "-",
      qua: "-",
      que: {
        tenque: "-",
        stt: 0,
        que: "-",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
    },
    {
      header: "Ngày",
      canChi: "-",
      diaChi: "-",
      qua: "-",
      que: {
        tenque: "-",
        stt: 0,
        que: "-",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
    },
    {
      header: "Tháng",
      canChi: "-",
      diaChi: "-",
      qua: "-",
      que: {
        tenque: "-",
        stt: 0,
        que: "-",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
    },
    {
      header: "Năm",
      canChi: "-",
      diaChi: "-",
      qua: "-",
      que: {
        tenque: "-",
        stt: 0,
        que: "-",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
    },
    {
      header: "Tọa",
      canChi: "-",
      diaChi: "-",
      qua: "-",
      que: {
        tenque: "-",
        stt: 0,
        que: "-",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
    },
    {
      header: "Năm sinh",
      canChi: "-",
      diaChi: "-",
      qua: "-",
      que: {
        tenque: "-",
        stt: 0,
        que: "-",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
    },
  ];
  static initHourDate = [
    {
      header: "23:00\n-\n01:00",
      picture: TyImage,
      pictureRed: TyRedImage,
      canChi: "Bính",
      diaChi: "Tý",
      qua: "Tốn",
      que: {
        tenque: "",
        stt: 0,
        que: "",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
      hoangDao: "Bạch Hổ",
      phiTinhGio: null,
      haDo: "Hà Đồ",
      hacDao: "Bạch Hổ",
    },
    {
      header: "01:00\n-\n03:00",
      picture: SuuImage,
      pictureRed: SuuRedImage,

      canChi: "Đinh",
      diaChi: "Sửu",
      qua: "Cấn",
      que: {
        tenque: "",
        stt: 0,
        que: "",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
      hoangDao: "Ngọc Đường",
      phiTinhGio: null,
      haDo: "Hà Đồ",
      hacDao: "Nguyên Vũ",
    },
    {
      header: "03:00\n-\n05:00",
      picture: DanImage,
      pictureRed: DanRedImage,

      canChi: "Mậu",
      diaChi: "Dần",
      qua: "Khảm",
      que: {
        tenque: "",
        stt: 0,
        que: "",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
      hoangDao: "Thiên Lao",
      phiTinhGio: null,
      haDo: "Hà Đồ",
      hacDao: "Câu Trận",
    },
    {
      header: "05:00\n-\n07:00",
      picture: MaoImage,
      pictureRed: MaoRedImage,

      canChi: "Kỷ",
      diaChi: "Mão",
      qua: "Khôn",
      que: {
        tenque: "",
        stt: 0,
        que: "",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
      hoangDao: "Huyền Vũ",
      phiTinhGio: null,
      haDo: "Hà Đồ",
      hacDao: "Chu Tước",
    },
    {
      header: "07:00\n-\n09:00",
      picture: ThinImage,
      pictureRed: ThinRedImage,

      canChi: "Canh",
      diaChi: "Thìn",
      qua: "Đoàn",
      que: {
        tenque: "",
        stt: 0,
        que: "",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
      hoangDao: "Ti Mệnh",
      phiTinhGio: null,
      haDo: "Hà Đồ",
      hacDao: "Bạc Hổ",
    },
    {
      header: "09:00\n-\n11:00",
      picture: TiImage,
      pictureRed: TiRedImage,

      canChi: "Tân",
      diaChi: "Tỵ",
      qua: "Tốn",
      que: {
        tenque: "",
        stt: 0,
        que: "",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
      hoangDao: "Câu Trần",
      phiTinhGio: null,
      haDo: "Hà Đồ",
      hacDao: "Chu Tước",
    },
    {
      header: "11:00\n-\n13:00",
      picture: NgoImage,
      pictureRed: NgoRedImage,

      canChi: "Nhâm",
      diaChi: "Ngọ",
      qua: "Đoài",
      que: {
        tenque: "",
        stt: 0,
        que: "",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
      hoangDao: "Thanh Lao",
      phiTinhGio: null,
      haDo: "Hà Đồ",
      hacDao: "Chu Tước",
    },
    {
      header: "13:00\n-\n15:00",
      picture: MuiImage,
      pictureRed: MuiRedImage,

      canChi: "Quý",
      diaChi: "Mùi",
      qua: "Ly",
      que: {
        tenque: "",
        stt: 0,
        que: "",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
      hoangDao: "Minh Đường",
      phiTinhGio: null,
      haDo: "Hà Đồ",
      hacDao: "Chu Tước",
    },
    {
      header: "15:00\n-\n17:00",
      picture: ThanImage,
      pictureRed: ThanRedImage,

      canChi: "Giáp",
      diaChi: "Thân",
      qua: "Càng",
      que: {
        tenque: "",
        stt: 0,
        que: "",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
      hoangDao: "Thiên Hình",
      phiTinhGio: null,
      haDo: "Hà Đồ",
      hacDao: "Câu Trận",
    },
    {
      header: "17:00\n-\n19:00",
      picture: DauImage,
      pictureRed: DauRedImage,

      canChi: "Ất",
      diaChi: "Dậu",
      qua: "Chấn",
      que: {
        tenque: "",
        stt: 0,
        que: "",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
      hoangDao: "Chu Tước",
      phiTinhGio: null,
      haDo: "Hà Đồ",
      hacDao: "Câu Trận",
    },
    {
      header: "19:00\n-\n21:00",
      picture: TuatImage,
      pictureRed: TuatRedImage,

      canChi: "Bính",
      diaChi: "Tuất",
      qua: "Cấn",
      que: {
        tenque: "",
        stt: 0,
        que: "",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
      hoangDao: "Kim Quỹ",
      phiTinhGio: null,
      haDo: "Hà Đồ",
      hacDao: "Câu Trận",
    },
    {
      header: "21:00\n-\n23:00",
      picture: HoiImage,
      pictureRed: HoiRedImage,

      canChi: "Đinh",
      diaChi: "Hợi",
      qua: "Khảm",
      que: {
        tenque: "",
        stt: 0,
        que: "",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
      hoangDao: "Bảo Quang",
      phiTinhGio: null,
      haDo: "Hà Đồ",
      hacDao: "Câu Trận",
    },
  ];
  static ngayKy = {
    maVuongKyNhat: { code: 1, name: "Ma Vương" },
    duongCongKyNhat: { code: 2, name: "Dương Công" },
    tuLy: { code: 3, name: "Tứ Ly" },
    tuTuyet: { code: 4, name: "Tứ Tuyệt" },
  };
  static Can = {
    Canh: { code: 0, name: "Canh", hanViet: "Geng" },
    Tan: { code: 1, name: "Tân", hanViet: "Xin" },
    Nham: { code: 2, name: "Nhâm", hanViet: "Ren" },
    Qui: { code: 3, name: "Quý", hanViet: "Gui" },
    Giap: { code: 4, name: "Giáp", hanViet: "Jia" },
    At: { code: 5, name: "Ất", hanViet: "Yi" },
    Binh: { code: 6, name: "Bính", hanViet: "Bing" },
    Dinh: { code: 7, name: "Đinh", hanViet: "Ding" },
    Mau: { code: 8, name: "Mậu", hanViet: "Wu" },
    Ky: { code: 9, name: "Kỷ", hanViet: "Ji" },
  };
  static CanArr = [
    { code: 0, name: "Canh", hanViet: "Geng" },
    { code: 1, name: "Tân", hanViet: "Xin" },
    { code: 2, name: "Nhâm", hanViet: "Ren" },
    { code: 3, name: "Quý", hanViet: "Gui" },
    { code: 4, name: "Giáp", hanViet: "Jia" },
    { code: 5, name: "Ất", hanViet: "Yi" },
    { code: 6, name: "Bính", hanViet: "Bing" },
    { code: 7, name: "Đinh", hanViet: "Ding" },
    { code: 8, name: "Mậu", hanViet: "Wu" },
    { code: 9, name: "Kỷ", hanViet: "Ji" },
  ];
  static THIENBAN = [
    { code: 0, name: "Tuần Thủ", hanViet: "TT" },
    { code: 1, name: "Mậu", hanViet: "Wu" },
    { code: 2, name: "Kỷ", hanViet: "Ji" },
    { code: 3, name: "Canh", hanViet: "Geng" },
    { code: 4, name: "Tân", hanViet: "Xin" },
    { code: 5, name: "Nhâm", hanViet: "Ren" },
    { code: 6, name: "Quý", hanViet: "Gui" },
    { code: 7, name: "Đinh", hanViet: "Ding" },
    { code: 8, name: "Bính", hanViet: "Bing" },
    { code: 9, name: "Ất", hanViet: "Yi" },
  ];
  static DIABAN = [
    { code: 0, name: "Tuần Thủ", hanViet: "TT" },
    { code: 1, name: "Mậu", hanViet: "Wu" },
    { code: 2, name: "Kỷ", hanViet: "Ji" },
    { code: 3, name: "Canh", hanViet: "Geng" },
    { code: 4, name: "Tân", hanViet: "Xin" },
    { code: 5, name: "Nhâm", hanViet: "Ren" },
    { code: 6, name: "Quý", hanViet: "Gui" },
    { code: 7, name: "Đinh", hanViet: "Ding" },
    { code: 8, name: "Bính", hanViet: "Bing" },
    { code: 9, name: "Ất", hanViet: "Yi" },
  ];
  static Chi = {
    Ty: { code: 0, name: "Tý", hanViet: "Zi", coordinate: "0deg" },
    Suu: { code: 1, name: "Sửu", hanViet: "Chou", coordinate: "31deg" },
    Dan: { code: 2, name: "Dần", hanViet: "Yin", coordinate: "59deg" },
    Mao: { code: 3, name: "Mão", hanViet: "Mao", coordinate: "90deg" },
    Thin: { code: 4, name: "Thìn", hanViet: "Chen", coordinate: "121deg" },
    Ti: { code: 5, name: "Tỵ", hanViet: "Si", coordinate: "149deg" },
    Ngo: { code: 6, name: "Ngọ", hanViet: "Wu", coordinate: "180deg" },
    Mui: { code: 7, name: "Mùi", hanViet: "Wei", coordinate: "210deg" },
    Than: { code: 8, name: "Thân", hanViet: "Shen", coordinate: "240deg" },
    Dau: { code: 9, name: "Dậu", hanViet: "You", coordinate: "270deg" },
    Tuat: { code: 10, name: "Tuất", hanViet: "Xu", coordinate: "300deg" },
    Hoi: { code: 11, name: "Hợi", hanViet: "Hai", coordinate: "330deg" },
  };
  static ChiArr = [
    { code: 0, name: "Tý", hanViet: "Zi" },
    { code: 1, name: "Sửu", hanViet: "Chou" },
    { code: 2, name: "Dần", hanViet: "Yin" },
    { code: 3, name: "Mão", hanViet: "Mao" },
    { code: 4, name: "Thìn", hanViet: "Chen" },
    { code: 5, name: "Tỵ", hanViet: "Si" },
    { code: 6, name: "Ngọ", hanViet: "Wu" },
    { code: 7, name: "Mùi", hanViet: "Wei" },
    { code: 8, name: "Thân", hanViet: "Shen" },
    { code: 9, name: "Dậu", hanViet: "You" },
    { code: 10, name: "Tuất", hanViet: "Xu" },
    { code: 11, name: "Hợi", hanViet: "Hai" },
  ];
  static CanChi = {
    giapTy: {
      code: 0,
      name: "Giáp Tý",
      taiThan: "Đông Nam",
      hyThan: "Đông Bắc",
    },
    atSuu: { code: 1, name: "Ất Sửu", taiThan: "Đông Nam", hyThan: "Tây Bắc" },
    binhDan: { code: 2, name: "Bính Dần", taiThan: "Tây", hyThan: "Tây Nam" },
    dinhMao: { code: 3, name: "Đinh Mão", taiThan: "Nam", hyThan: "Tây" },
    mauThin: { code: 4, name: "Mậu Thìn", taiThan: "Bắc", hyThan: "Đông Nam" },
    kyTi: { code: 5, name: "Kỷ Tỵ", taiThan: "Bắc", hyThan: "Đông Bắc" },
    canhNgo: { code: 6, name: "Canh Ngọ", taiThan: "Đông", hyThan: "Tây Bắc" },
    tanMui: { code: 7, name: "Tân Mùi", taiThan: "Đông", hyThan: "Tây Nam" },
    nhamThan: { code: 8, name: "Nhâm Thân", taiThan: "Nam", hyThan: "Nam" },
    quyDau: { code: 9, name: "Quý Dậu", taiThan: "Nam", hyThan: "Đông Nam" },
    giapTuat: {
      code: 10,
      name: "Giáp Tuất",
      taiThan: "Đông Nam",
      hyThan: "Đông Bắc",
    },
    atHoi: { code: 11, name: "Ất Hợi", taiThan: "Đông Nam", hyThan: "Tây Bắc" },
    binhTy: { code: 12, name: "Bính Tý", taiThan: "Tây", hyThan: "Tây Nam" },
    dinhSuu: { code: 13, name: "Đinh Sửu", taiThan: "Tây", hyThan: "Nam" },
    mauDan: { code: 14, name: "Mậu Dần", taiThan: "Bắc", hyThan: "Đông Nam" },
    kyMao: { code: 15, name: "Kỷ Mão", taiThan: "Bắc", hyThan: "Đông Bắc" },
    canhThin: {
      code: 16,
      name: "Canh Thìn",
      taiThan: "Đông",
      hyThan: "Tây Bắc",
    },
    tanTi: { code: 17, name: "Tân Tỵ", taiThan: "Đông", hyThan: "Tây Nam" },
    nhamNgo: { code: 18, name: "Nhâm Ngọ", taiThan: "Nam", hyThan: "Nam" },
    quiMui: { code: 19, name: "Quý Mùi", taiThan: "Nam", hyThan: "Đông Nam" },
    giapThan: {
      code: 20,
      name: "Giáp Thân",
      taiThan: "Đông Nam",
      hyThan: "Đông Bắc",
    },
    atDau: { code: 21, name: "Ất Dậu", taiThan: "Đông Nam", hyThan: "Tây Bắc" },
    binhTuat: {
      code: 22,
      name: "Bính Tuất",
      taiThan: "Tây",
      hyThan: "Tây Nam",
    },
    dinhHoi: { code: 23, name: "Đinh Hợi", taiThan: "Tây", hyThan: "Nam" },
    mauTy: { code: 24, name: "Mậu Tý", taiThan: "Bắc", hyThan: "Đông Nam" },
    kySuu: { code: 25, name: "Kỷ Sửu", taiThan: "Bắc", hyThan: "Đông Bắc" },
    canhDan: { code: 26, name: "Canh Dần", taiThan: "Đông", hyThan: "Tây Bắc" },
    tanMao: { code: 27, name: "Tân Mão", taiThan: "Đông", hyThan: "Tây Nam" },
    nhamThin: { code: 28, name: "Nhâm Thìn", taiThan: "Nam", hyThan: "Nam" },
    quyTi: { code: 29, name: "Quý Tỵ", taiThan: "Nam", hyThan: "Đông Nam" },
    giapNgo: {
      code: 30,
      name: "Giáp Ngọ",
      taiThan: "Đông Nam",
      hyThan: "Đông Bắc",
    },
    atMui: { code: 31, name: "Ất Mùi", taiThan: "Đông Nam", hyThan: "Tây Bắc" },
    binhThan: {
      code: 32,
      name: "Bính Thân",
      taiThan: "Tây",
      hyThan: "Tây Nam",
    },
    dinhDau: { code: 33, name: "Đinh Dậu", taiThan: "Tây", hyThan: "Nam" },
    mauTuat: { code: 34, name: "Mậu Tuất", taiThan: "Bắc", hyThan: "Đông Nam" },
    kyHoi: { code: 35, name: "Kỷ Hợi", taiThan: "Bắc", hyThan: "Đông Bắc" },
    canhTy: { code: 36, name: "Canh Tý", taiThan: "Đông", hyThan: "Tây Bắc" },
    tanSuu: { code: 37, name: "Tân Sửu", taiThan: "Đông", hyThan: "Tây Nam" },
    nhamDan: { code: 38, name: "Nhâm Dần", taiThan: "Nam", hyThan: "Nam" },
    quiMao: { code: 39, name: "Quý Mão", taiThan: "Nam", hyThan: "Đông Nam" },
    giapThin: {
      code: 40,
      name: "Giáp Thìn",
      taiThan: "Đông Nam",
      hyThan: "Đông Bắc",
    },
    atTi: { code: 41, name: "Ất Tỵ", taiThan: "Đông Nam", hyThan: "Tây Bắc" },
    binhNgo: { code: 42, name: "Bính Ngọ", taiThan: "Tây", hyThan: "Tây Nam" },
    dinhMui: { code: 43, name: "Đinh Mùi", taiThan: "Tây", hyThan: "Nam" },
    mauThan: { code: 44, name: "Mậu Thân", taiThan: "Bắc", hyThan: "Đông Nam" },
    kydau: { code: 45, name: "Kỷ Dậu", taiThan: "Bắc", hyThan: "Đông Bắc" },
    canhTuat: {
      code: 46,
      name: "Canh Tuất",
      taiThan: "Đông",
      hyThan: "Tây Bắc",
    },
    tanHoi: { code: 47, name: "Tân Hợi", taiThan: "Đông", hyThan: "Tây Nam" },
    nhamTy: { code: 48, name: "Nhâm Tý", taiThan: "Nam", hyThan: "Nam" },
    quiSuu: { code: 49, name: "Quý Sửu", taiThan: "Nam", hyThan: "Đông Nam" },
    giapDan: {
      code: 50,
      name: "Giáp Dần",
      taiThan: "Đông Nam",
      hyThan: "Đông Bắc",
    },
    atMao: { code: 51, name: "Ất Mão", taiThan: "Đông Nam", hyThan: "Tây Bắc" },
    binhThin: {
      code: 52,
      name: "Bính Thìn",
      taiThan: "Tây",
      hyThan: "Tây Nam",
    },
    dinhTi: { code: 53, name: "Đinh Tỵ", taiThan: "Tây", hyThan: "Nam" },
    mauNgo: { code: 54, name: "Mậu Ngọ", taiThan: "Bắc", hyThan: "Đông Nam" },
    kyMui: { code: 55, name: "Kỷ Mùi", taiThan: "Bắc", hyThan: "Đông Bắc" },
    canhThan: {
      code: 56,
      name: "Canh Thân",
      taiThan: "Đông",
      hyThan: "Tây Bắc",
    },
    tanDau: { code: 57, name: "Tân Dậu", taiThan: "Đông", hyThan: "Tây Nam" },
    nhamTuat: { code: 58, name: "Nhâm Tuất", taiThan: "Nam", hyThan: "Nam" },
    quyHoi: { code: 59, name: "Quý Hợi", taiThan: "Nam", hyThan: "Đông Nam" },
  };
  static CanChiArr = [
    {
      code: 1,
      name: "Giáp Tý",
      taiThan: "Đông Nam",
      hyThan: "Đông Bắc",
      coordinate: "357.5deg",
    },
    {
      code: 2,
      name: "Ất Sửu",
      taiThan: "Đông Nam",
      hyThan: "Tây Bắc",
      coordinate: "31deg",
    },
    {
      code: 3,
      name: "Bính Dần",
      taiThan: "Tây",
      hyThan: "Tây Nam",
      coordinate: "65deg",
    },
    {
      code: 4,
      name: "Đinh Mão",
      taiThan: "Nam",
      hyThan: "Tây",
      coordinate: "98deg",
    },
    {
      code: 5,
      name: "Mậu Thìn",
      taiThan: "Bắc",
      hyThan: "Đông Nam",
      coordinate: "132.5deg",
    },
    {
      code: 6,
      name: "Kỷ Tỵ",
      taiThan: "Bắc",
      hyThan: "Đông Bắc",
      coordinate: "160deg",
    },
    {
      code: 7,
      name: "Canh Ngọ",
      taiThan: "Đông",
      hyThan: "Tây Bắc",
      coordinate: "200deg",
    },
    {
      code: 8,
      name: "Tân Mùi",
      taiThan: "Đông",
      hyThan: "Tây Nam",
      coordinate: "232.5deg",
    },
    {
      code: 9,
      name: "Nhâm Thân",
      taiThan: "Nam",
      hyThan: "Nam",
      coordinate: "267.5deg",
    },
    {
      code: 10,
      name: "Quý Dậu",
      taiThan: "Nam",
      hyThan: "Đông Nam",
      coordinate: "295deg",
    },
    {
      code: 11,
      name: "Giáp Tuất",
      taiThan: "Đông Nam",
      hyThan: "Đông Bắc",
      coordinate: "301deg",
    },
    {
      code: 12,
      name: "Ất Hợi",
      taiThan: "Đông Nam",
      hyThan: "Tây Bắc",
      coordinate: "329deg",
    },
    {
      code: 13,
      name: "Bính Tý",
      taiThan: "Tây",
      hyThan: "Tây Nam",
      coordinate: "9deg",
    },
    {
      code: 14,
      name: "Đinh Sửu",
      taiThan: "Tây",
      hyThan: "Nam",
      coordinate: "37deg",
    },
    {
      code: 15,
      name: "Mậu Dần",
      taiThan: "Bắc",
      hyThan: "Đông Nam",
      coordinate: "70deg",
    },
    {
      code: 16,
      name: "Kỷ Mão",
      taiThan: "Bắc",
      hyThan: "Đông Bắc",
      coordinate: "deg",
    },
    {
      code: 17,
      name: "Canh Thìn",
      taiThan: "Đông",
      hyThan: "Tây Bắc",
      coordinate: "deg",
    },
    {
      code: 18,
      name: "Tân Tỵ",
      taiThan: "Đông",
      hyThan: "Tây Nam",
      coordinate: "deg",
    },
    {
      code: 19,
      name: "Nhâm Ngọ",
      taiThan: "Nam",
      hyThan: "Nam",
      coordinate: "deg",
    },
    {
      code: 20,
      name: "Quý Mùi",
      taiThan: "Nam",
      hyThan: "Đông Nam",
      coordinate: "deg",
    },
    {
      code: 21,
      name: "Giáp Thân",
      taiThan: "Đông Nam",
      hyThan: "Đông Bắc",
      coordinate: "deg",
    },
    {
      code: 22,
      name: "Ất Dậu",
      taiThan: "Đông Nam",
      hyThan: "Tây Bắc",
      coordinate: "deg",
    },
    {
      code: 23,
      name: "Bính Tuất",
      taiThan: "Tây",
      hyThan: "Tây Nam",
      coordinate: "deg",
    },
    {
      code: 24,
      name: "Đinh Hợi",
      taiThan: "Tây",
      hyThan: "Nam",
      coordinate: "deg",
    },
    {
      code: 25,
      name: "Mậu Tý",
      taiThan: "Bắc",
      hyThan: "Đông Nam",
      coordinate: "14deg",
    },
    {
      code: 26,
      name: "Kỷ Sửu",
      taiThan: "Bắc",
      hyThan: "Đông Bắc",
      coordinate: "42.5deg",
    },
    {
      code: 27,
      name: "Canh Dần",
      taiThan: "Đông",
      hyThan: "Tây Bắc",
      coordinate: "76deg",
    },
    {
      code: 28,
      name: "Tân Mão",
      taiThan: "Đông",
      hyThan: "Tây Nam",
      coordinate: "deg",
    },
    {
      code: 29,
      name: "Nhâm Thìn",
      taiThan: "Nam",
      hyThan: "Nam",
      coordinate: "deg",
    },
    {
      code: 30,
      name: "Quý Tỵ",
      taiThan: "Nam",
      hyThan: "Đông Nam",
      coordinate: "deg",
    },
    {
      code: 31,
      name: "Giáp Ngọ",
      taiThan: "Đông Nam",
      hyThan: "Đông Bắc",
      coordinate: "deg",
    },
    {
      code: 32,
      name: "Ất Mùi",
      taiThan: "Đông Nam",
      hyThan: "Tây Bắc",
      coordinate: "deg",
    },
    {
      code: 33,
      name: "Bính Thân",
      taiThan: "Tây",
      hyThan: "Tây Nam",
      coordinate: "deg",
    },
    {
      code: 34,
      name: "Đinh Dậu",
      taiThan: "Tây",
      hyThan: "Nam",
      coordinate: "deg",
    },
    {
      code: 35,
      name: "Mậu Tuất",
      taiThan: "Bắc",
      hyThan: "Đông Nam",
      coordinate: "deg",
    },
    {
      code: 36,
      name: "Kỷ Hợi",
      taiThan: "Bắc",
      hyThan: "Đông Bắc",
      coordinate: "deg",
    },
    {
      code: 37,
      name: "Canh Tý",
      taiThan: "Đông",
      hyThan: "Tây Bắc",
      coordinate: "20deg",
    },
    {
      code: 38,
      name: "Tân Sửu",
      taiThan: "Đông",
      hyThan: "Tây Nam",
      coordinate: "47.5deg",
    },
    {
      code: 39,
      name: "Nhâm Dần",
      taiThan: "Nam",
      hyThan: "Nam",
      coordinate: "deg",
    },
    {
      code: 40,
      name: "Quý Mão",
      taiThan: "Nam",
      hyThan: "Đông Nam",
      coordinate: "deg",
    },
    {
      code: 41,
      name: "Giáp Thìn",
      taiThan: "Đông Nam",
      hyThan: "Đông Bắc",
      coordinate: "deg",
    },
    {
      code: 42,
      name: "Ất Tỵ",
      taiThan: "Đông Nam",
      hyThan: "Tây Bắc",
      coordinate: "deg",
    },
    {
      code: 43,
      name: "Bính Ngọ",
      taiThan: "Tây",
      hyThan: "Tây Nam",
      coordinate: "deg",
    },
    {
      code: 44,
      name: "Đinh Mùi",
      taiThan: "Tây",
      hyThan: "Nam",
      coordinate: "deg",
    },
    {
      code: 45,
      name: "Mậu Thân",
      taiThan: "Bắc",
      hyThan: "Đông Nam",
      coordinate: "deg",
    },
    {
      code: 46,
      name: "Kỷ Dậu",
      taiThan: "Bắc",
      hyThan: "Đông Bắc",
      coordinate: "deg",
    },
    {
      code: 47,
      name: "Canh Tuất",
      taiThan: "Đông",
      hyThan: "Tây Bắc",
      coordinate: "deg",
    },
    {
      code: 48,
      name: "Tân Hợi",
      taiThan: "Đông",
      hyThan: "Tây Nam",
      coordinate: "deg",
    },
    {
      code: 49,
      name: "Nhâm Tý",
      taiThan: "Nam",
      hyThan: "Nam",
      coordinate: "25deg",
    },
    {
      code: 50,
      name: "Quý Sửu",
      taiThan: "Nam",
      hyThan: "Đông Nam",
      coordinate: "53deg",
    },
    {
      code: 51,
      name: "Giáp Dần",
      taiThan: "Đông Nam",
      hyThan: "Đông Bắc",
      coordinate: "59deg",
    },
    {
      code: 52,
      name: "Ất Mão",
      taiThan: "Đông Nam",
      hyThan: "Tây Bắc",
      coordinate: "deg",
    },
    {
      code: 53,
      name: "Bính Thìn",
      taiThan: "Tây",
      hyThan: "Tây Nam",
      coordinate: "deg",
    },
    {
      code: 54,
      name: "Đinh Tỵ",
      taiThan: "Tây",
      hyThan: "Nam",
      coordinate: "deg",
    },
    {
      code: 55,
      name: "Mậu Ngọ",
      taiThan: "Bắc",
      hyThan: "Đông Nam",
      coordinate: "deg",
    },
    {
      code: 56,
      name: "Kỷ Mùi",
      taiThan: "Bắc",
      hyThan: "Đông Bắc",
      coordinate: "deg",
    },
    {
      code: 57,
      name: "Canh Thân",
      taiThan: "Đông",
      hyThan: "Tây Bắc",
      coordinate: "deg",
    },
    {
      code: 58,
      name: "Tân Dậu",
      taiThan: "Đông",
      hyThan: "Tây Nam",
      coordinate: "deg",
    },
    {
      code: 59,
      name: "Nhâm Tuất",
      taiThan: "Nam",
      hyThan: "Nam",
      coordinate: "deg",
    },
    {
      code: 60,
      name: "Quý Hợi",
      taiThan: "Nam",
      hyThan: "Đông Nam",
      coordinate: "deg",
    },
  ];
  static HourArr = {
    GioTy: { code: 0, name: "Tý", value: 0 },
    GioTy2: { code: 12, name: "Tý", value: 23 },
    GioSuu: { code: 1, name: "Sửu", value: 2 },
    GioDan: { code: 2, name: "Dần", value: 4 },
    GioMao: { code: 3, name: "Mão", value: 6 },
    GioThin: { code: 4, name: "Thìn", value: 8 },
    GioTi: { code: 5, name: "Tỵ", value: 10 },
    GioNgo: { code: 6, name: "Ngọ", value: 12 },
    GioMui: { code: 7, name: "Mùi", value: 14 },
    GioThan: { code: 8, name: "Thân", value: 16 },
    GioDau: { code: 9, name: "Dậu", value: 18 },
    GioTuat: { code: 10, name: "Tuất", value: 20 },
    GioHoi: { code: 11, name: "Hợi", value: 22 },
  };
  static Que = [
    {
      tenque: `Khôn Vị Địa \n(Earth)`,
      stt: 1,
      que: "Jia Zi",
      queViet: "Giáp Tý",
      xungTuoi: "Canh Ngọ, Mậu Ngọ",
      cordCompass: "357.5deg",
      napAm: "Hải Trung Kim",
      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 1,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Địa Lôi Phục \n(Earth)",
      stt: 1,
      que: "Jia Zi",
      cordCompass: "2.5deg",

      queViet: "Giáp Tý",
      xungTuoi: "Canh Ngọ, Mậu Ngọ",

      napAm: "Hải Trung Kim",

      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 8,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Hỏa Lôi Phệ Hạp\n(Chewing)",
      stt: 2,
      que: "Yi Chou",
      queViet: "Ất Sửu",
      xungTuoi: "Tân Mùi, Kỷ Mùi",

      napAm: "Hải Trung Kim",
      cordCompass: "31deg",

      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 6,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Phong Hỏa Gia Nhân\n(Family)",
      stt: 3,
      que: "Bing Yin",
      queViet: "Bính Dần",
      xungTuoi: "Canh Thân, Nhâm Thân",

      cordCompass: "65deg",

      napAm: "Lư Trung Hỏa",

      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 4,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Sơn Trạch Tổn\n(Diminishing)",
      stt: 4,
      que: "Ding Mao",
      queViet: "Đinh Mão",
      xungTuoi: "Tân Dậu, Quý Dậu",

      cordCompass: "98deg",

      napAm: "Lư Trung Hỏa",

      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 9,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thiên Trạch Lý\n(Treading)",
      stt: 5,
      que: "Wu Chen",
      queViet: "Mậu Thìn",
      xungTuoi: "Giáp Tuất, Nhâm Tuất",

      cordCompass: "132.5deg",

      napAm: "Đại Lâm Mộc",

      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 6,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Lôi Thiên Đại Tráng\n(Invigorating)",
      stt: 6,
      que: "Ji Si",
      queViet: "Kỷ Tỵ",
      xungTuoi: "Ất Hợi, Quý Hợi",

      cordCompass: "160deg",

      napAm: "Đại Lâm Mộc",

      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 2,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Lôi Phong Hằng\n(Constancy)",
      stt: 7,
      que: "Geng Wu",
      queViet: "Canh Ngọ",
      xungTuoi: "Giáp Tý, Bính Tý",

      napAm: "Lộ Bàng Thổ",
      cordCompass: "199.5deg",

      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 9,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Thiên Thủy Tụng\n(Arguing)",
      stt: 8,
      que: "Xin Wei",
      queViet: "Tân Mùi",
      xungTuoi: "Ất Sửu, Đinh Sửu",

      napAm: "Lộ Bàng Thổ",
      cordCompass: "232.5deg",

      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 3,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Địa Thủy Sư\n(Troop)",
      stt: 9,
      que: "Ren Shen",
      queViet: "Nhâm Thân",
      xungTuoi: "Mậu Dần, Bính Dần",

      napAm: "Kiếm Phong Kim",
      cordCompass: "267deg",

      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 7,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Phong Sơn Tiệm\n(Gradual Progress)",
      stt: 10,
      que: "Gui You",
      queViet: "Quý Dậu",
      xungTuoi: "Đinh Mão, Kỷ Mão",

      napAm: "Kiếm Phong Kim",
      cordCompass: "295deg",

      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 7,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Thủy Sơn Kiển\n(Impeding)",
      stt: 11,
      que: "Jia Xu",
      queViet: "Giáp Tuất",
      xungTuoi: "Canh Thìn, Mậu Thìn",

      napAm: "Sơn Đầu Hỏa",
      cordCompass: "301deg",

      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 2,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Hỏa Địa Tấn\n(Advancing)",
      stt: 12,
      que: "Yi Hai",
      queViet: "Ất Hợi",
      xungTuoi: "Tân Tỵ, Kỷ Tỵ",

      napAm: "Sơn Đầu Hỏa",
      cordCompass: "329deg",

      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 3,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Sơn Lôi Di\n(Nurture)",
      stt: 13,
      que: "Bing Zi",
      queViet: "Bính Tý",
      xungTuoi: "Canh Ngọ, Nhâm Ngọ",

      napAm: "Giảm Hạ Thủy",
      cordCompass: "9deg",

      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 3,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Trạch Lôi Tùy\n(Following)",
      stt: 14,
      que: "Ding Chou",
      queViet: "Đinh Sửu",
      xungTuoi: "Tân Mùi, Quý Mùi",

      napAm: "Giảm Hạ Thủy",
      cordCompass: "37deg",

      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 7,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Lôi Hỏa Phong\n(Abundance)",
      stt: 15,
      que: "Wu Yin",
      queViet: "Mậu Dần",
      xungTuoi: "Giáp Thân, Nhâm Thân",

      napAm: "Thành Đầu Thổ",
      cordCompass: "70deg",

      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 6,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thủy Trạch Tiết\n(Regulating)",
      stt: 16,
      que: "Ji Mao",
      queViet: "Kỷ Mão",
      xungTuoi: "Ất Dậu, Quý Dậu",

      napAm: "Thành Đầu Thổ",
      cordCompass: "104deg",

      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 8,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },

    {
      tenque: "Địa Thiên Thái\n(Greatness)",
      stt: 17,
      que: "Geng Chen",
      queViet: "Canh Thìn",
      xungTuoi: "Giáp Tuất, Bính Tuất",

      napAm: "Bạch Lạp Kim",
      cordCompass: "138deg",

      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 9,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },

    {
      tenque: "Hỏa Thiên Đại Hũu\n(Great Harvest)",
      stt: 18,
      que: "Xin Si",
      queViet: "Tân Tỵ",
      xungTuoi: "Ất Hợi, Đinh Hợi",

      napAm: "Bạch Lạp Kim",
      cordCompass: "169deg",

      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 7,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Tốn Vi Phong\n(Wind)",
      stt: 19,
      que: "Ren Wu",
      queViet: "Nhâm Ngọ",
      xungTuoi: "Mậu Tý, Bính Tý",

      napAm: "Dương Liễu Mộc",
      cordCompass: "205deg",

      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 1,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Trạch Thủy Khốn\n(Trapped)",
      stt: 20,
      que: "Gui Wei",
      queViet: "Quý Mùi",
      xungTuoi: "Đinh Sửu, Kỷ Sửu",

      napAm: "Dương Liễu Mộc",
      cordCompass: "233deg",

      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 8,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Hỏa Thủy Vị Tế\n(Unaccomplished)",
      stt: 21,
      que: "Jia Shen",
      queViet: "Giáp Thân",
      xungTuoi: "Canh Dần, Mậu Dần",

      napAm: "Tuyền Trung Thủy",
      cordCompass: "239deg",

      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 9,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Thiên Sơn Độn\n(Retreat)",
      stt: 22,
      que: "Yi You",
      queViet: "Ất Dậu",
      xungTuoi: "Tân Mão, Kỷ Mão",

      napAm: "Tuyền Trung Thủy",
      cordCompass: "273deg",

      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 4,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Cấn Vi Sơn\n(Mountain)",
      stt: 23,
      que: "Bing Xu",
      queViet: "Bính Tuất",
      xungTuoi: "Canh Thìn, Nhâm Thìn",

      napAm: "Ốc Thượng Thổ",
      cordCompass: "307deg",

      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 1,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Lôi Địa Dự\n(Elated)",
      stt: 24,
      que: "Ding Hai",
      queViet: "Đinh Hợi",
      xungTuoi: "Tân Tỵ, Quý Tỵ",

      napAm: "Ốc Thượng Thổ",
      cordCompass: "335deg",

      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 8,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Thủy Lôi Truân\n(Sprouting)",
      stt: 25,
      que: "Wu Zi",
      queViet: "Mậu Tý",
      xungTuoi: "Giáp Ngọ, Nhâm Ngọ",

      napAm: "Tích Lịch Hỏa",
      cordCompass: "14deg",

      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 4,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thiên Lôi Vô Vọng\n(WithoutEmbroiling)",
      stt: 26,
      que: "Ji Chou",
      queViet: "Kỷ Sửu",
      xungTuoi: "Ất Mùi, Quý Mùi",

      napAm: "Tích Lịch Hỏa",
      cordCompass: "42deg",

      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 2,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Ly Vi Hỏa\n(Fire)",
      stt: 27,
      que: "Geng Yin",
      queViet: "Canh Dần",
      xungTuoi: "Giáp Thân, Bính Thân",

      napAm: "Tùng Bách Mộc",
      cordCompass: "76deg",

      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 1,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },

    {
      tenque: "Trạch Hỏa Cách\n(Fire)",
      stt: 27,
      que: "Geng Yin",
      queViet: "Canh Dần",
      xungTuoi: "Giáp Thân, Bính Thân",

      cordCompass: "82deg",

      napAm: "Tùng Bách Mộc",

      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 2,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },

    {
      tenque: "Phong Trạch Trung Phu (Trustworthy)",
      stt: 28,
      que: "Xin Mao",
      queViet: "Tân Mão",
      xungTuoi: "Ất Dậu, Đinh Dậu",

      napAm: "Tùng Bách Mộc",
      cordCompass: "110deg",

      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 3,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },

    {
      tenque: "Sơn Thiên Đại Súc\n(Big Accruing)",
      stt: 29,
      que: "Ren Chen",
      queViet: "Nhâm Thìn",
      xungTuoi: "Mậu Tuất, Bính Tuất",

      cordCompass: "143deg",

      napAm: "Trường Lưu Thủy",

      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 4,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Trạch Thiên Quải\n(Eradicating)",
      stt: 30,
      que: "Gui Si",
      queViet: "Quý Tỵ",
      xungTuoi: "Đinh Hợi, Kỷ Hợi",

      napAm: "Trường Lưu Thủy",
      cordCompass: "172deg",

      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 6,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },

    {
      tenque: "Càn Vi Thiên\n(Heaven)",
      stt: 31,
      que: "Jia Wu",
      queViet: "Giáp Ngọ",
      xungTuoi: "Canh Tý, Mậu Tý",

      cordCompass: "177deg",

      napAm: "Sa Trung Kim",

      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 1,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thiên Phong Cấu\n(Heaven)",
      stt: 31,
      que: "Jia Wu",
      queViet: "Giáp Ngọ",
      xungTuoi: "Canh Tý, Mậu Tý",

      cordCompass: "183deg",

      napAm: "Sa Trung Kim",

      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 8,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Thủy Phong Tỉnh\n(Well)",
      stt: 32,
      que: "Yi Wei",
      queViet: "Ất Mùi",
      xungTuoi: "Tân Sửu, Kỷ Sửu",

      cordCompass: "214deg",

      napAm: "Sa Trung Kim",

      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 6,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Lôi Thủy Giải\n(Relief)",
      stt: 33,
      que: "Bing Shen",
      queViet: "Bính Thân",
      xungTuoi: "Canh Dần, Nhâm Dần",

      cordCompass: "245deg",

      napAm: "Sơn Hạ Hỏa",

      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 4,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },

    {
      tenque: "Trạch Sơn Hàm\n(Conjoining)",
      stt: 34,
      que: "Ding You",
      queViet: "Đinh Dậu",
      xungTuoi: "Tân Mão, Quý Mão",

      napAm: "Sơn Hạ Hỏa",
      cordCompass: "278deg",

      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 9,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },

    {
      tenque: "Địa Sơn Khiêm\n(Humbling)",
      stt: 35,
      que: "Wu Xu",
      queViet: "Mậu Tuất",
      xungTuoi: "Giáp Thìn, Nhâm Thìn",

      cordCompass: "312deg",

      napAm: "Bình Địa Mộc",

      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 6,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Phong Địa Quan\n(Observing)",
      stt: 36,
      que: "Ji Hai",
      queViet: "Kỷ Hợi",
      xungTuoi: "Ất Tỵ, Quý Tỵ",

      cordCompass: "340deg",

      napAm: "Bình Địa Mộc",

      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 2,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Phong Lôi Ích\n(Benefiting)",
      stt: 37,
      que: "Geng Zi",
      queViet: "Canh Tý",
      xungTuoi: "Giáp Ngọ, Bính Ngọ",

      cordCompass: "20deg",

      napAm: "Bích Thượng Thổ",

      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 9,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Địa Hỏa Minh Di\n(Lackluster)",
      stt: 38,
      que: "Xin Chou",
      queViet: "Tân Sửu",
      xungTuoi: "Ất Mùi, Đinh Mùi",

      cordCompass: "48deg",

      napAm: "Bích Thượng Thổ",

      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 3,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thiên Hỏa Đồng Nhân (Fraternity)",
      stt: 39,
      que: "Ren Yin",
      queViet: "Nhâm Dần",
      xungTuoi: "Mậu Thân, Bính Thân",

      cordCompass: "87deg",

      napAm: "Kim Bạch Kim",

      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 7,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Lôi Trạch Quy Muội\n(Marrying)",
      stt: 40,
      que: "Gui Mao",
      queViet: "Quý Mão",
      xungTuoi: "Đinh Dậu, Kỷ Dậu",

      cordCompass: "115deg",

      napAm: "Kim Bạch Kim",

      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 7,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Hỏa Trạch Khuê\n(Conflicting)",
      stt: 41,
      que: "Jia Chen",
      queViet: "Giáp Thìn",
      xungTuoi: "Canh Tuất, Mậu Tuất",

      cordCompass: "121deg",

      napAm: "Phúc Đăng Hỏa",

      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 2,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thủy Thiên Nhu\n(Waiting)",
      stt: 42,
      que: "Yi Si",
      queViet: "Ất Tỵ",
      xungTuoi: "Tân Hợi, Kỷ Hợi",

      cordCompass: "149deg",

      napAm: "Phúc Đăng Hỏa",

      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 3,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Trạch Phong Đại Quá (Exceedingly)",
      stt: 43,
      que: "Bing Wu",
      queViet: "Bính Ngọ",
      xungTuoi: "Canh Tý, Nhâm Tý",

      cordCompass: "188deg",

      napAm: "Thiên Hà Thủy",

      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 3,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Sơn Phong Cổ\n(Corrupting)",
      stt: 44,
      que: "Ding Wei",
      queViet: "Đinh Mùi",
      xungTuoi: "Tân Sửu, Quý Sửu",

      cordCompass: "216deg",

      napAm: "Thiên Hà Thủy",

      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 7,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Phong Thủy Hoán\n(Dispersing)",
      stt: 45,
      que: "Wu Shen",
      queViet: "Mậu Thân",
      xungTuoi: "Giáp Dần, Nhâm Dần",

      cordCompass: "250deg",

      napAm: "Đại Trạch Thổ",

      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 6,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Hỏa Sơn Lữ\n(Traveling)",
      stt: 46,
      que: "Ji You",
      queViet: "Kỷ Dậu",
      xungTuoi: "Ất Mão, Quý Mão",

      cordCompass: "284deg",

      napAm: "Đại Trạch Thổ",

      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 8,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },

    {
      tenque: "Thiên Địa Bĩ\n(Obstruction)",
      stt: 47,
      que: "Geng Xu",
      queViet: "Canh Tuất",
      xungTuoi: "Giáp Thìn, Bính Thìn",

      cordCompass: "318deg",

      napAm: "Thoa Xuyến Kim",

      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 9,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },

    {
      tenque: "Thủy Địa Tỷ\n(Alliance)",
      stt: 48,
      que: "Xin Hai",
      queViet: "Tân Hợi",
      xungTuoi: "Ất Tỵ, Đinh Tỵ",
      cordCompass: "346deg",
      napAm: "Thoa Xuyến Kim",
      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 7,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Chấn Vi Lôi\n(Thunder)",
      stt: 49,
      que: "Ren Zi",
      queViet: "Nhâm Tý",
      xungTuoi: "Mậu Ngọ, Bính Ngọ",
      cordCompass: "26deg",
      napAm: "Tang Đố Mộc",
      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 1,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Sơn Hỏa bí\n(Beautifying)",
      stt: 50,
      que: "Gui Chou",
      queViet: "Quý Sửu",
      xungTuoi: "Đinh Mùi, Kỷ Mùi",

      cordCompass: "52.5deg",

      napAm: "Tang Đố Mộc",

      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 8,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thủy Hỏa Ký Tế\n(Success)",
      stt: 51,
      que: "Jia Yin",
      queViet: "Giáp Dần",
      xungTuoi: "Canh Thân, Mậu Thân",

      cordCompass: "59deg",

      napAm: "Đại Khe Thủy",

      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 9,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Địa Trạch Lâm\n(Approaching)",
      stt: 52,
      que: "Yi Mao",
      queViet: "Ất Mão",
      xungTuoi: "Tân Dậu, Kỷ Dậu",

      cordCompass: "93deg",

      napAm: "Đại Khe Thủy",

      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 4,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Đoài Vi Trạch\n(Marsh)",
      stt: 53,
      que: "Bing Chen",
      queViet: "Bính Thìn",
      xungTuoi: "Canh Tý, Nhâm Tý",

      cordCompass: "126.5deg",

      napAm: "Sa Trung Thổ",

      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 1,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },

    {
      tenque: "Phong Thiên Tiểu Súc(SmallAccruing)",
      stt: 54,
      que: "Ding Si",
      queViet: "Đinh Tỵ",
      xungTuoi: "Tân Hợi, Quý Hợi",

      cordCompass: "155deg",

      napAm: "Sa Trung Thổ",

      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 8,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Hỏa Phong Đỉnh\n(Holding)",
      stt: 55,
      que: "Wu Wu",
      queViet: "Mậu Ngọ",
      xungTuoi: "Giáp Tý, Nhâm Tý",

      cordCompass: "164deg",

      napAm: "Thiên Thượng Hỏa",

      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 4,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Địa Phong Thăng\n(Ascending)",
      stt: 56,
      que: "Ji Wei",
      queViet: "Kỷ Mùi",
      xungTuoi: "Ất Sửu, Quý Sửu",

      cordCompass: "222deg",

      napAm: "Thiên Thượng Hỏa",

      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 2,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },

    {
      tenque: "Sơn Thủy Mông\n(Water)",
      stt: 57,
      que: "Geng Shen",
      queViet: "Canh Thân",
      xungTuoi: "Giáp Dần, Bính Dần",

      cordCompass: "262deg",

      napAm: "Thạch Lựu Mộc",

      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 1,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Khảm Vi Thủy\n(Water)",
      stt: 57,
      que: "Geng Shen",
      queViet: "Canh Thân",
      xungTuoi: "Giáp Dần, Bính Dần",

      cordCompass: "256deg",

      napAm: "Thạch Lựu Mộc",

      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 2,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },

    {
      tenque: "Lôi Sơn Tiểu Quá\n(Less Exceeding)",
      stt: 58,
      que: "Xin You",
      queViet: "Tân Dậu",
      xungTuoi: "Ất Mão, Đinh Mão",

      cordCompass: "290deg",

      napAm: "Thạch Lựu Mộc",

      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 3,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },

    {
      tenque: "Trạch Địa Tụy\n(Gathering)",
      stt: 59,
      que: "Ren Xu",
      queViet: "Nhâm Tuất",
      xungTuoi: "Mậu Thìn, Bính Thìn",

      cordCompass: "323.5deg",

      napAm: "Đại Hải Thủy",

      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 4,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Sơn Địa Bác\n(Exfoliating)",
      stt: 60,
      que: "Gui Hai",
      queViet: "Quý Hợi",
      xungTuoi: "Đinh Tỵ, Kỷ Tỵ",

      cordCompass: "351.5deg",

      napAm: "Đại Hải Thủy",

      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 6,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
  ];
  static QueSortConGiap = [
    {
      tenque: `Khôn Vị Địa \n(Earth)`,
      stt: 1,
      que: "Jia Zi",
      queViet: "Giáp Tý",
      xungTuoi: "Canh Ngọ, Mậu Ngọ",
      cordCompass: "357.5deg",
      napAm: "Hải Trung Kim",
      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 1,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Địa Lôi Phục \n(Earth)",
      stt: 1,
      que: "Jia Zi",
      cordCompass: "2.5deg",
      queViet: "Giáp Tý",
      xungTuoi: "Canh Ngọ, Mậu Ngọ",
      napAm: "Hải Trung Kim",
      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 8,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Sơn Lôi Di\n(Nurture)",
      stt: 13,
      que: "Bing Zi",
      queViet: "Bính Tý",
      xungTuoi: "Canh Ngọ, Nhâm Ngọ",

      napAm: "Giảm Hạ Thủy",
      cordCompass: "9deg",

      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 3,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thủy Lôi Truân\n(Sprouting)",
      stt: 25,
      que: "Wu Zi",
      queViet: "Mậu Tý",
      xungTuoi: "Giáp Ngọ, Nhâm Ngọ",

      napAm: "Tích Lịch Hỏa",
      cordCompass: "14deg",

      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 4,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Phong Lôi Ích\n(Benefiting)",
      stt: 36,
      que: "Geng Zi",
      queViet: "Canh Tý",
      xungTuoi: "Giáp Ngọ, Bính Ngọ",

      cordCompass: "20deg",

      napAm: "Bích Thượng Thổ",

      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 9,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Chấn Vi Lôi\n(Thunder)",
      stt: 49,
      que: "Ren Zi",
      queViet: "Nhâm Tý",
      xungTuoi: "Mậu Ngọ, Bính Ngọ",

      cordCompass: "26deg",

      napAm: "Tang Đố Mộc",

      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 1,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Hỏa Lôi Phệ Hạp\n(Chewing)",
      stt: 2,
      que: "Yi Chou",
      queViet: "Ất Sửu",
      xungTuoi: "Tân Mùi, Kỷ Mùi",

      napAm: "Hải Trung Kim",
      cordCompass: "31deg",

      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 6,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Trạch Lôi Tùy\n(Following)",
      stt: 14,
      que: "Ding Chou",
      queViet: "Đinh Sửu",
      xungTuoi: "Tân Mùi, Quý Mùi",

      napAm: "Giảm Hạ Thủy",
      cordCompass: "37deg",

      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 7,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thiên Lôi Vô Vọng\n(WithoutEmbroiling)",
      stt: 26,
      que: "Ji Chou",
      queViet: "Kỷ Sửu",
      xungTuoi: "Ất Mùi, Quý Mùi",

      napAm: "Tích Lịch Hỏa",
      cordCompass: "42deg",

      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 2,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Địa Hỏa Minh Di\n(Lackluster)",
      stt: 37,
      que: "Xin Chou",
      queViet: "Tân Sửu",
      xungTuoi: "Ất Mùi, Đinh Mùi",

      cordCompass: "48deg",

      napAm: "Bích Thượng Thổ",

      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 3,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Sơn Hỏa bí\n(Beautifying)",
      stt: 50,
      que: "Gui Chou",
      queViet: "Quý Sửu",
      xungTuoi: "Đinh Mùi, Kỷ Mùi",

      cordCompass: "52.5deg",

      napAm: "Tang Đố Mộc",

      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 8,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thủy Hỏa Ký Tế\n(Success)",
      stt: 51,
      que: "Jia Yin",
      queViet: "Giáp Dần",
      xungTuoi: "Canh Thân, Mậu Thân",

      cordCompass: "59deg",

      napAm: "Đại Khe Thủy",

      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 9,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Phong Hỏa Gia Nhân\n(Family)",
      stt: 3,
      que: "Bing Yin",
      queViet: "Bính Dần",
      xungTuoi: "Canh Thân, Nhâm Thân",

      cordCompass: "65deg",

      napAm: "Lư Trung Hỏa",

      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 4,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Lôi Hỏa Phong\n(Abundance)",
      stt: 15,
      que: "Wu Yin",
      queViet: "Mậu Dần",
      xungTuoi: "Giáp Thân, Nhâm Thân",

      napAm: "Thành Đầu Thổ",
      cordCompass: "70deg",

      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 6,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Ly Vi Hỏa\n(Fire)",
      stt: 27,
      que: "Geng Yin",
      queViet: "Canh Dần",
      xungTuoi: "Giáp Thân, Bính Thân",

      napAm: "Tùng Bách Mộc",
      cordCompass: "76deg",

      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 1,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Trạch Hỏa Cách\n(Fire)",
      stt: 27,
      que: "Geng Yin",
      queViet: "Canh Dần",
      xungTuoi: "Giáp Thân, Bính Thân",

      cordCompass: "82deg",

      napAm: "Tùng Bách Mộc",

      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 2,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thiên Hỏa Đồng Nhân (Fraternity)",
      stt: 38,
      que: "Ren Yin",
      queViet: "Nhâm Dần",
      xungTuoi: "Mậu Thân, Bính Thân",

      cordCompass: "87deg",

      napAm: "Kim Bạch Kim",

      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 7,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Địa Trạch Lâm\n(Approaching)",
      stt: 52,
      que: "Yi Mao",
      queViet: "Ất Mão",
      xungTuoi: "Tân Dậu, Kỷ Dậu",

      cordCompass: "93deg",

      napAm: "Đại Khe Thủy",

      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 4,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Sơn Trạch Tổn\n(Diminishing)",
      stt: 4,
      que: "Ding Mao",
      queViet: "Đinh Mão",
      xungTuoi: "Tân Dậu, Quý Dậu",

      cordCompass: "98deg",

      napAm: "Lư Trung Hỏa",

      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 9,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thủy Trạch Tiết\n(Regulating)",
      stt: 16,
      que: "Ji Mao",
      queViet: "Kỷ Mão",
      xungTuoi: "Ất Dậu, Quý Dậu",

      napAm: "Thành Đầu Thổ",
      cordCompass: "104deg",

      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 8,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Phong Trạch Trung Phu (Trustworthy)",
      stt: 27,
      que: "Xin Mao",
      queViet: "Tân Mão",
      xungTuoi: "Ất Dậu, Đinh Dậu",

      napAm: "Tùng Bách Mộc",
      cordCompass: "110deg",

      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 3,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Lôi Trạch Quy Muội\n(Marrying)",
      stt: 39,
      que: "Gui Mao",
      queViet: "Quý Mão",
      xungTuoi: "Đinh Dậu, Kỷ Dậu",

      cordCompass: "115deg",

      napAm: "Kim Bạch Kim",

      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 7,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Hỏa Trạch Khuê\n(Conflicting)",
      stt: 40,
      que: "Jia Chen",
      queViet: "Giáp Thìn",
      xungTuoi: "Canh Tuất, Mậu Tuất",

      cordCompass: "121deg",

      napAm: "Phúc Đăng Hỏa",

      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 2,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Đoài Vi Trạch\n(Marsh)",
      stt: 53,
      que: "Bing Chen",
      queViet: "Bính Thìn",
      xungTuoi: "Canh Tý, Nhâm Tý",

      cordCompass: "126.5deg",

      napAm: "Sa Trung Thổ",

      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 1,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thiên Trạch Lý\n(Treading)",
      stt: 5,
      que: "Wu Chen",
      queViet: "Mậu Thìn",
      xungTuoi: "Giáp Tuất, Nhâm Tuất",

      cordCompass: "132.5deg",

      napAm: "Đại Lâm Mộc",

      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 6,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Địa Thiên Thái\n(Greatness)",
      stt: 17,
      que: "Geng Chen",
      queViet: "Canh Thìn",
      xungTuoi: "Giáp Tuất, Bính Tuất",

      napAm: "Bạch Lạp Kim",
      cordCompass: "138deg",

      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 9,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Sơn Thiên Đại Súc\n(Big Accruing)",
      stt: 28,
      que: "Ren Chen",
      queViet: "Nhâm Thìn",
      xungTuoi: "Mậu Tuất, Bính Tuất",

      cordCompass: "143deg",

      napAm: "Trường Lưu Thủy",

      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 4,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thủy Thiên Nhu\n(Waiting)",
      stt: 41,
      que: "Yi Si",
      queViet: "Ất Tỵ",
      xungTuoi: "Tân Hợi, Kỷ Hợi",

      cordCompass: "149deg",

      napAm: "Phúc Đăng Hỏa",

      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 3,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Phong Thiên Tiểu Súc(SmallAccruing)",
      stt: 54,
      que: "Ding Si",
      queViet: "Đinh Tỵ",
      xungTuoi: "Tân Hợi, Quý Hợi",

      cordCompass: "155deg",

      napAm: "Sa Trung Thổ",

      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 8,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Lôi Thiên Đại Tráng\n(Invigorating)",
      stt: 6,
      que: "Ji Si",
      queViet: "Kỷ Tỵ",
      xungTuoi: "Ất Hợi, Quý Hợi",

      cordCompass: "160deg",

      napAm: "Đại Lâm Mộc",

      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 2,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Hỏa Thiên Đại Hũu\n(Great Harvest)",
      stt: 18,
      que: "Xin Si",
      queViet: "Tân Tỵ",
      xungTuoi: "Ất Hợi, Đinh Hợi",

      napAm: "Bạch Lạp Kim",
      cordCompass: "169deg",

      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 7,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Trạch Thiên Quải\n(Eradicating)",
      stt: 29,
      que: "Gui Si",
      queViet: "Quý Tỵ",
      xungTuoi: "Đinh Hợi, Kỷ Hợi",

      napAm: "Trường Lưu Thủy",
      cordCompass: "172deg",

      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 6,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Càn Vi Thiên\n(Heaven)",
      stt: 30,
      que: "Jia Wu",
      queViet: "Giáp Ngọ",
      xungTuoi: "Canh Tý, Mậu Tý",

      cordCompass: "177deg",

      napAm: "Sa Trung Kim",

      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 1,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thiên Phong Cấu\n(Heaven)",
      stt: 30,
      que: "Jia Wu",
      queViet: "Giáp Ngọ",
      xungTuoi: "Canh Tý, Mậu Tý",

      cordCompass: "183deg",

      napAm: "Sa Trung Kim",

      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 8,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Trạch Phong Đại Quá (Exceedingly)",
      stt: 42,
      que: "Bing Wu",
      queViet: "Bính Ngọ",
      xungTuoi: "Canh Tý, Nhâm Tý",

      cordCompass: "188deg",

      napAm: "Thiên Hà Thủy",

      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 3,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Hỏa Phong Đỉnh\n(Holding)",
      stt: 55,
      que: "Wu Wu",
      queViet: "Mậu Ngọ",
      xungTuoi: "Giáp Tý, Nhâm Tý",

      cordCompass: "164deg",

      napAm: "Thiên Thượng Hỏa",

      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 4,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Lôi Phong Hằng\n(Constancy)",
      stt: 7,
      que: "Geng Wu",
      queViet: "Canh Ngọ",
      xungTuoi: "Giáp Tý, Bính Tý",

      napAm: "Lộ Bàng Thổ",
      cordCompass: "199.5deg",

      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 9,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Tốn Vi Phong\n(Wind)",
      stt: 19,
      que: "Ren Wu",
      queViet: "Nhâm Ngọ",
      xungTuoi: "Mậu Tý, Bính Tý",

      napAm: "Dương Liễu Mộc",
      cordCompass: "205deg",

      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 1,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Thủy Phong Tỉnh\n(Well)",
      stt: 31,
      que: "Yi Wei",
      queViet: "Ất Mùi",
      xungTuoi: "Tân Sửu, Kỷ Sửu",

      cordCompass: "214deg",

      napAm: "Sa Trung Kim",

      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 6,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Sơn Phong Cổ\n(Corrupting)",
      stt: 43,
      que: "Ding Wei",
      queViet: "Đinh Mùi",
      xungTuoi: "Tân Sửu, Quý Sửu",

      cordCompass: "216deg",

      napAm: "Thiên Hà Thủy",

      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 7,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Địa Phong Thăng\n(Ascending)",
      stt: 56,
      que: "Ji Wei",
      queViet: "Kỷ Mùi",
      xungTuoi: "Ất Sửu, Quý Sửu",

      cordCompass: "-138deg",

      napAm: "Thiên Thượng Hỏa",

      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 2,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Thiên Thủy Tụng\n(Arguing)",
      stt: 8,
      que: "Xin Wei",
      queViet: "Tân Mùi",
      xungTuoi: "Ất Sửu, Đinh Sửu",

      napAm: "Lộ Bàng Thổ",
      cordCompass: "232.5deg",

      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 3,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Trạch Thủy Khốn\n(Trapped)",
      stt: 20,
      que: "Gui Wei",
      queViet: "Quý Mùi",
      xungTuoi: "Đinh Sửu, Kỷ Sửu",

      napAm: "Dương Liễu Mộc",
      cordCompass: "233deg",

      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 8,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Hỏa Thủy Vị Tế\n(Unaccomplished)",
      stt: 21,
      que: "Jia Shen",
      queViet: "Giáp Thân",
      xungTuoi: "Canh Dần, Mậu Dần",

      napAm: "Tuyền Trung Thủy",
      cordCompass: "239deg",

      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 9,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Lôi Thủy Giải\n(Relief)",
      stt: 32,
      que: "Bing Shen",
      queViet: "Bính Thân",
      xungTuoi: "Canh Dần, Nhâm Dần",

      cordCompass: "245deg",

      napAm: "Sơn Hạ Hỏa",

      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 4,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Phong Thủy Hoán\n(Dispersing)",
      stt: 44,
      que: "Wu Shen",
      queViet: "Mậu Thân",
      xungTuoi: "Giáp Dần, Nhâm Dần",

      cordCompass: "250deg",

      napAm: "Đại Trạch Thổ",

      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 6,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Sơn Thủy Mông\n(Water)",
      stt: 57,
      que: "Geng Shen",
      queViet: "Canh Thân",
      xungTuoi: "Giáp Dần, Bính Dần",

      cordCompass: "262deg",

      napAm: "Thạch Lựu Mộc",

      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 2,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Khảm Vi Thủy\n(Water)",
      stt: 57,
      que: "Geng Shen",
      queViet: "Canh Thân",
      xungTuoi: "Giáp Dần, Bính Dần",

      cordCompass: "256deg",

      napAm: "Thạch Lựu Mộc",

      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 1,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Địa Thủy Sư\n(Troop)",
      stt: 9,
      que: "Ren Shen",
      queViet: "Nhâm Thân",
      xungTuoi: "Mậu Dần, Bính Dần",

      napAm: "Kiếm Phong Kim",
      cordCompass: "267deg",

      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 7,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Thiên Sơn Độn\n(Retreat)",
      stt: 22,
      que: "Yi You",
      queViet: "Ất Dậu",
      xungTuoi: "Tân Mão, Kỷ Mão",

      napAm: "Tuyền Trung Thủy",
      cordCompass: "273deg",

      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 4,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Trạch Sơn Hàm\n(Conjoining)",
      stt: 33,
      que: "Ding You",
      queViet: "Đinh Dậu",
      xungTuoi: "Tân Mão, Quý Mão",

      napAm: "Sơn Hạ Hỏa",
      cordCompass: "278deg",

      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 9,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Hỏa Sơn Lữ\n(Traveling)",
      stt: 46,
      que: "Ji You",
      queViet: "Kỷ Dậu",
      xungTuoi: "Ất Mão, Quý Mão",

      cordCompass: "284deg",

      napAm: "Đại Trạch Thổ",

      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 8,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Lôi Sơn Tiểu Quá\n(Less Exceeding)",
      stt: 58,
      que: "Xin You",
      queViet: "Tân Dậu",
      xungTuoi: "Ất Mão, Đinh Mão",

      cordCompass: "290deg",

      napAm: "Thạch Lựu Mộc",

      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 3,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Phong Sơn Tiệm\n(Gradual Progress)",
      stt: 10,
      que: "Gui You",
      queViet: "Quý Dậu",
      xungTuoi: "Đinh Mão, Kỷ Mão",

      napAm: "Kiếm Phong Kim",
      cordCompass: "295deg",

      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 7,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Thủy Sơn Kiển\n(Impeding)",
      stt: 11,
      que: "Jia Xu",
      queViet: "Giáp Tuất",
      xungTuoi: "Canh Thìn, Mậu Thìn",

      napAm: "Sơn Đầu Hỏa",
      cordCompass: "301deg",

      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 2,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Cấn Vi Sơn\n(Mountain)",
      stt: 23,
      que: "Bing Xu",
      queViet: "Bính Tuất",
      xungTuoi: "Canh Thìn, Nhâm Thìn",

      napAm: "Ốc Thượng Thổ",
      cordCompass: "307deg",

      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 1,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Địa Sơn Khiêm\n(Humbling)",
      stt: 34,
      que: "Wu Xu",
      queViet: "Mậu Tuất",
      xungTuoi: "Giáp Thìn, Nhâm Thìn",

      cordCompass: "312deg",

      napAm: "Bình Địa Mộc",

      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 6,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Thiên Địa Bĩ\n(Obstruction)",
      stt: 47,
      que: "Geng Xu",
      queViet: "Canh Tuất",
      xungTuoi: "Giáp Thìn, Bính Thìn",

      cordCompass: "318deg",

      napAm: "Thoa Xuyến Kim",

      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 9,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Trạch Địa Tụy\n(Gathering)",
      stt: 59,
      que: "Ren Xu",
      queViet: "Nhâm Tuất",
      xungTuoi: "Mậu Thìn, Bính Thìn",

      cordCompass: "323.5deg",

      napAm: "Đại Hải Thủy",

      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 4,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },

    {
      tenque: "Hỏa Địa Tấn\n(Advancing)",
      stt: 12,
      que: "Yi Hai",
      queViet: "Ất Hợi",
      xungTuoi: "Tân Tỵ, Kỷ Tỵ",

      napAm: "Sơn Đầu Hỏa",
      cordCompass: "329deg",

      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 3,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },

    {
      tenque: "Lôi Địa Dự\n(Elated)",
      stt: 24,
      que: "Ding Hai",
      queViet: "Đinh Hợi",
      xungTuoi: "Tân Tỵ, Quý Tỵ",

      napAm: "Ốc Thượng Thổ",
      cordCompass: "335deg",

      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 8,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },

    {
      tenque: "Phong Địa Quan\n(Observing)",
      stt: 35,
      que: "Ji Hai",
      queViet: "Kỷ Hợi",
      xungTuoi: "Ất Tỵ, Quý Tỵ",

      cordCompass: "340deg",

      napAm: "Bình Địa Mộc",

      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 2,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },

    {
      tenque: "Thủy Địa Tỷ\n(Alliance)",
      stt: 48,
      que: "Xin Hai",
      queViet: "Tân Hợi",
      xungTuoi: "Ất Tỵ, Đinh Tỵ",

      cordCompass: "346deg",

      napAm: "Thoa Xuyến Kim",

      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 7,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },

    {
      tenque: "Sơn Địa Bác\n(Exfoliating)",
      stt: 60,
      que: "Gui Hai",
      queViet: "Quý Hợi",
      xungTuoi: "Đinh Tỵ, Kỷ Tỵ",

      cordCompass: "351.5deg",

      napAm: "Đại Hải Thủy",

      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 6,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
  ];
  static nhiThapBatTu = [
    { code: 0, name: "Giác", good: true, additional: ", riêng mai táng xấu" },
    { code: 1, name: "Cang", good: false, additional: "" },
    { code: 2, name: "Đê", good: false, additional: "" },
    { code: 3, name: "Phòng", good: true, additional: "" },
    { code: 4, name: "Tâm", good: false, additional: "" },
    { code: 5, name: "Vĩ", good: true, additional: "" },
    { code: 6, name: "Cơ", good: true, additional: "" },
    { code: 7, name: "Đẩu", good: true, additional: "" },
    { code: 8, name: "Ngưu", good: false, additional: "" },
    { code: 9, name: "Nữ", good: false, additional: "" },
    { code: 10, name: "Hư", good: false, additional: "" },
    { code: 11, name: Const.trucSao.Nguy.name, good: false, additional: "" },
    { code: 12, name: "Thất", good: true, additional: "" },
    { code: 13, name: "Bích", good: true, additional: "" },
    { code: 14, name: "Khuê", good: false, additional: ", riêng xây dựng tốt" },
    { code: 15, name: "Lâu", good: true, additional: "" },
    { code: 16, name: "Vị", good: true, additional: "" },
    { code: 17, name: "Mão", good: false, additional: "" },
    { code: 18, name: "Tất", good: true, additional: "" },
    { code: 19, name: "Chủy", good: false, additional: ", riêng xây dựng tốt" },
    { code: 20, name: "Sâm", good: false, additional: ", riêng xây dựng tốt" },
    { code: 21, name: "Tỉnh", good: true, additional: "" },
    { code: 22, name: "Quỷ", good: false, additional: ", riêng mai táng tốt" },
    { code: 23, name: "Liễu", good: false, additional: "" },
    { code: 24, name: "Tinh", good: false, additional: ", riêng xây dựng tốt" },
    { code: 25, name: "Trương", good: true, additional: "" },
    { code: 26, name: "Dực", good: false, additional: "" },
    { code: 27, name: "Chẩn", good: true, additional: "" },
  ];
  static HoangDao = {
    thanhLong: { code: 1, Name: "Thanh Long", isHoangDao: true },
    minhDuong: { code: 2, Name: "Minh Đường", isHoangDao: true },
    kimQuy: { code: 3, Name: "Kim Quỹ", isHoangDao: true },
    thienDuc: { code: 4, Name: "Thiên Đức", isHoangDao: true },
    ngocDuong: { code: 5, Name: "Ngọc Đường", isHoangDao: true },
    tuMenh: { code: 6, Name: "Tư Mệnh", isHoangDao: true },
    thienHinh: { code: 7, Name: "Thiên Hình", isHoangDao: false },
    chuTuoc: { code: 8, Name: "Chu Tước", isHoangDao: false },
    bachHo: { code: 9, Name: "Bạch Hổ", isHoangDao: false },
    thienLao: { code: 10, Name: "Thiên Lao", isHoangDao: false },
    huyenVu: { code: 11, Name: "Huyền Vũ", isHoangDao: false },
    cauTran: { code: 12, Name: "Câu Trần", isHoangDao: false },
  };
  static saoThanSatThangThienDucToTaiSat = {
    thangTy: [
      "Tỵ",
      "Thân",
      "Nhâm",
      "Đinh",
      "Đông Nam",
      "Bính",
      "Ngọ",
      "Tý",
      "Tỵ",
      "Ngọ",
    ],
    thangSuu: [
      "Canh",
      "Ất",
      "Canh",
      "Ất",
      "Tây",
      "Giáp",
      "Mùi",
      "Hợi",
      "Dần",
      "Mão",
    ],
    thangDan: [
      "Đinh",
      "Nhâm",
      "Bính",
      "Tân",
      "Nam",
      "Nhâm",
      "Thân",
      "Tuất",
      "Hợi",
      "Tý",
    ],
    thangMao: [
      "Thân",
      "Tỵ",
      "Giáp",
      "Tỵ",
      "Tây Nam",
      "Canh",
      "Dậu",
      "Dậu",
      "Thân",
      "Dậu",
    ],
    thangThin: [
      "Nhâm",
      "Đinh",
      "Nhâm",
      "Đinh",
      "Bắc",
      "Bính",
      "Tuất",
      "Thân",
      "Tỵ",
      "Ngọ",
    ],
    thangTi: [
      "Tân",
      "Bính",
      "Canh",
      "Ất",
      "Tây",
      "Giáp",
      "Hợi",
      "Mùi",
      "Dần",
      "Mão",
    ],
    thangNgo: [
      "Hợi",
      "Dần",
      "Bính",
      "Tân",
      "Tây Bắc",
      "Nhâm",
      "Tý",
      "Ngọ",
      "Hợi",
      "Tý",
    ],
    thangMui: [
      "Giáp",
      "Kỷ",
      "Giáp",
      "Kỷ",
      "Đông",
      "Canh",
      "Sửu",
      "Tỵ",
      "Thân",
      "Dậu",
    ],
    thangThan: [
      "Quý",
      "Mậu",
      "Nhâm",
      "Đinh",
      "Bắc",
      "Bính",
      "Dần",
      "Thìn",
      "Tỵ",
      "Ngọ",
    ],
    thangDau: [
      "Cấn",
      " ",
      "Canh",
      "Ất",
      "Đông Bắc",
      "Giáp",
      "Mão",
      "Mão",
      "Dần",
      "Mão",
    ],
    thangTuat: [
      "Bính",
      "Tân",
      "Bính",
      "Tân",
      "Nam",
      "Nhâm",
      "Thìn",
      "Dần",
      "Hợi",
      "Tý",
    ],
    thangHoi: [
      "Ất",
      "Canh",
      "Giáp",
      "Kỷ",
      "Đông",
      "Canh",
      "Tỵ",
      "Sửu",
      "Thân",
      "Dậu",
    ],
  };
  static kiengKiTrongNgay = [
    [
      {
        catThan:
          " Nguyệt Không, Thiên Ân, Thiên Xá, Thiên Nguyệt, Tứ Tướng, Quan Nhật, Lục Hợp, Tục Thế.",
        hungThan:
          " Thiên Lại, Chí Tử, Huyết Chi, Thổ Phù, Qui Kị, Huyết Kị, Thiên Hình.",
        nen: " Cúng Tế, Tắm Gội, Cắt May, Đan Dệt, Nấu Rượu, An Táng.",
        khongNen: " ",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Đức Hợp, Thiên Ân, Tứ Tướng, Thủ Nhật, Yếu An.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Vãng Vong, Chu Tước.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Đính Hôn, Ăn Hỏi, Giải Trừ, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Nạp Tài, Mở Kho Xuất Tiền Hàng, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan:
          " Thiên Ân, Thời Đức, Tướng Nhật, Cát Kì, Bất Tương, Ngọc Vũ, Ngũ Hợp, Kim Quĩ, Ô Phệ Đối.",
        hungThan: " Kiếp Sát, Thiên Tặc, Ngũ Hư.",
        nen: " Tắm Gội, Quét Dọn.",
        khongNen:
          " Cúng Tế, Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Mời Thầy Chữa Bệnh, Sửa Kho, Mở Kho Xuất Tiền Hàng.",
      },
      {
        catThan:
          " Thiên Ân, Dân Nhật, Thiên Vu, Phúc Đức, Thiên Thương, Bất Tương, Kim Quĩ, Ngũ Hợp, Bảo Quang, Ô Phệ Đối.",
        hungThan: " Tai Sát, Thiên Hỏa.",
        nen: " Cúng Tế, ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương, Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường,  Chặt Cây, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Ân, Thiên Mã.",
        hungThan: " Hà Khôi, Tử Thần, Nguyệt Sát, Nguyệt Hư, Ngũ Mộ, Bạch Hổ.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Tam Hợp, Thời Âm, Lục Nghi, Ngọc Đường",
        hungThan:
          " Yếm Đối, Chiêu Dao, Tử Khí, Cửu Khảm, Cửu Tiêu, Phục Nhật, Trùng Nhật.",
        nen: " Họp Thân Hữu, Đội Mũ Cài Trâm, Gặp Dân, Đính Hôn, Ăn Hỏi, Thu Nạp Người, Cắt May, Tu Tạo, Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Xuất Hành, Cưới Gả, Giải Trừ, Mời Thầy Chữa Bệnh, Rèn Đúc, Xếp Đặt Buồng Đẻ, Lấp Hang Hố, Đánh Cá, Đi Thuyền, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Thiên Đức, Nguyệt Đức, Kính An, Giải Thần, Ô Phệ.",
        hungThan:
          " Nguyệt Hại, Đại Thời, Đại Bại, Hàm Trì, Tiểu Hao, Ngũ Hư, Thiên Hình.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Chặt Cây, Đuổi Bắt, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng. ",
        khongNen:
          " Mời Thầy Chữa Bệnh, Lợp Mái, Gác Đòn Dông, Đan Dệt, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Nguyệt Ân, Phổ Hộ",
        hungThan: " Nguyệt Phá, Đại Hao, Tứ Kích, Cửu Không, Huyền Vũ.",
        nen: " Cúng Tế, Giải Trừ, Dỡ Nhà Phá Tường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Chặt Cây, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Mẫu Thương, Dương Đức, Ngũ Phú, Phúc Sinh, Trừ Thần, Tư Mệnh, Ô Phệ.",
        hungThan: " Du Họa, Ngũ Li.",
        nen: " Cúng Tế, Tắm Gội, Cắt Tóc, Sửa Móng, Dan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Quét Dọn, Chặt Cây, Săn Bắn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng. ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Họp Thân Hữu, Dính Hôn, An Hỏi, Giải Trừ, Mời Thầy Chữa Bệnh, Lập Ước Giao Dịch, Khơi Mương.",
      },
      {
        catThan:
          " Mẫu Thương, Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y, Trừ Thần, Ô Phệ.",
        hungThan: " Địa Nang, Đại Sát, Ngũ Li, Câu Trần.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Quét Dọn, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Họp Thân Hữu, Đắp Đê, Tu Tạo, Động Thổ, Sửa Kho, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan: " Nguyệt Không, Tứ Tướng, Thánh Tâm, Thanh Long.",
        hungThan: " Thiên Cương, Nguyệt Hình, Ngũ Hư, Bát Phong.",
        nen: " Cúng Tế, Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo, Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho, Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Đức Hợp, Tứ Tướng, Âm Đức, Vương Nhật, Dịch Mã, Thiên Hậu, Thời Dương, Sinh Khí, Ích Hậu, Minh Đường.",
        hungThan: " Nguyệt Yếm, Địa Hỏa, Trùng Nhật.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Giải Trừ, Tắm Gội, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển,Đi Xa Trở Về, Mời Thầy Chữa Bệnh, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng.",
      },
      {
        catThan: " Quan Nhật, Lục Hợp, Bất Tương, Tục Thế, Ô Phệ Đối.",
        hungThan:
          " Thiên Lại, Chí Tử, Huyết Chi, Thổ Phù, Qui Kị, Huyết Kị, Xúc Thủy Long, Thiên Hình.",
        nen: " Cúng Tế, Tắm Gội, Đan Dệt, Nấu Rượu, An Táng, Cải Táng.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ.",
      },
      {
        catThan: " Thủ Nhật, Bất Tương, Yếu An.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Vãng Vong, Chu Tước.",
        nen: " ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo, Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Thời Đức, Tướng Nhật, Cát Kì, Ngọc Đường, Ngũ Hợp, Kim Quỹ.",
        hungThan: " Kiếp Sát, Thiên Tặc, Ngũ Hư.",
        nen: " Tắm Gội, Quét Dọn.",
        khongNen:
          " Cúng Tế, Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Mời Thầy Chữa Bệnh, Sửa Kho, Mở Kho Xuất Tiền Hàng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Thiên Ân, Dân Nhật, Thiên Vu, Phúc Đức, Thiên Thương, Bất Tương, Kim Đường, Ngũ Hợp, Bảo Quang.",
        hungThan: " Tai Sát, Thiên Hỏa, Phục Nhật.",
        nen: " Cúng Tế, ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Đức, Nguyệt Đức, Thiên Ân, Thiên Mã, Bất Tương.",
        hungThan: " Hà Khôi, Tử Thần, Nguyệt Sát, Nguyệt Hư, Bạch Hổ.",
        nen: " Cúng Tế, Sửa Đường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Đắp Đê, Tu Tạo, Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương, Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà, Phá Tường, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Thiên Ân, Nguyệt Ân, Tam Hợp, Thời Âm, Lục Nghi, Ngọc Đường.",
        hungThan:
          " Yếm Đối, Chiêu Dao, Tử Khí, Cửu Khảm, Cửu Tiêu, Trùng Nhật.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Đội Mũ Cài Trâm, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Thu Nạp Người, Di Chuyển, Cắt May, Tu Tạo, Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Xuất Hành, Cưới Gả, Giải Trừ, Mời Thầy Chữa Bệnh, Rèn Đúc, Nấu Rượu, Xếp Đặt Buồng Đẻ, Lấp Hang Hố, Đánh Cá, Đi Thuyền, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Thiên Ân, Kính An, Giải Trừ, Ô Phệ.",
        hungThan:
          " Nguyệt Hại, Đại Thời, Đại Bại, Hàm Trì, Tiểu Hao, Ngũ Hư, Thiên Hình.",
        nen: " Tắm Gội, Cắt Tóc, Sửa Móng, Chặt Cây, Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Ân, Phổ Hộ.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tứ Kích, Cửu Không, Xúc Thủy Long, Huyền Vũ.",
        nen: " Cúng Tế, Dỡ Nhà Phá Tường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo, Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Không, Mẫu Thương, Tứ Tướng, Dương Đức, Ngũ Phú, Phúc Sinh, Trừ Thần, Tư Mệnh, Ô Phệ.",
        hungThan: " Du Họa, Ngũ Li.",
        nen: " Cúng Tế, Dâng Biểu Sớ, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Di Chuyển, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Tu Tạo, Dộng Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Dan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Nạp Tài, Quét Dọn, Chặt Cây, Săn Bắn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng. ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Họp Thân Hữu, Dính Hôn, An Hỏi, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Lập Ước Giao Dịch, Mở Kho Xuất Tiền Hàng.",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Đức Hợp, Mẫu Thương, Tứ Tướng, Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y.",
        hungThan: " Đại Sát, Ngũ Li, Câu Trần.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Quét Dọn, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng.",
        khongNen: " Họp Thân Hữu, Săn Bắn, Đánh Cá, Gieo Trồng.",
      },
      {
        catThan: " Thánh Tâm, Thanh Long.",
        hungThan: " Thiên Cương, Nguyệt Hình, Ngũ Hư.",
        nen: " Cúng Tế, Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo, Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Âm Đức, Vương Nhật, Dịch Mã, Thiên Hậu, Thời Dương, Sinh Khí, Ích Hậu, Minh Đường.",
        hungThan: " Nguyệt Yếm, Địa Hỏa, Trùng Nhật.",
        nen: " Cúng Tế, Nhập Học, Tắm Gội.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Chặt Cây, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Quan Nhật, Lục Hợp, Tục Thế.",
        hungThan:
          " Thiên Lại, Chí Tử, Huyết Chi, Thổ Phù, Qui Kị, Huyết Kị, Thiên Hình, Trục Trận.",
        nen: " Cúng Tế, Tắm Gội.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Mời Thầy Chữa Bệnh, Chữa Mắt, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường,  Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ.",
      },
      {
        catThan: " Thủ Nhật, Bất Tương, Yếu An.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Vãng Vong, Phục Nhật, Chu Tước.",
        nen: " Cắt May.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá,  Gieo Trồng, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Thiên Đức, Nguyệt Đức, Thời Đức, Tướng Nhật, Cát Kì, Bất Tương, Ngọc Vũ, Ngũ Hợp, Kim Quĩ, Ô Phệ Đối.",
        hungThan: " Kiếp Sát, Thiên Tặc, Ngũ Hư.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Lập Ước, Giao Dịch, Nạp Tài, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
        khongNen:
          " Cúng Tế, Xuất Hành, Mời Thầy Chữa Bệnh, Sửa Kho, Đan Dệt, Mở Kho Xuất Tiền Hàng, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Nguyệt Ân, Dân Nhật, Thiên Vu, Phúc Đức, Thiên Thương, Bất Tương, Kim Đường, Ngũ Hợp, Bảo Quang, Ô Phệ Đối.",
        hungThan: " Tai Sát, Thiên Hỏa.",
        nen: " Cúng Tế, ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường,  Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Mã.",
        hungThan: " Hà Khôi, Tử Khí, Nguyệt Sát, Nguyệt Hư, Bạch Hổ.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Tam Hợp, Âm Đức, Lục Nghi, Ngọc Đường.",
        hungThan:
          " Yếm Đối, Chiêu Dao, Tử Khí, Cửu Khảm, Cửu Tiêu, Trùng Nhật.",
        nen: " Họp Thân Hữu, Đội Mũ Cài Trâm, Gặp Dân, Đính Hôn, Ăn Hỏi, Thu Nạp Người, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Xuất Hành, Cưới Gả, Giải Trừ, Mời Thầy Chữa Bệnh, Rèn Đúc, Xếp Đặt Buồng Đẻ, Lấp Hang Hố, Đánh Cá, Đi Thuyền, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Nguyệt Không, Tứ Tướng, Kính An, Giải Thần, Ô Phệ.",
        hungThan:
          "  Nguyệt Hại, Đại Thời, Đại Bại, Hàm Trì, Tiểu Hao, Ngũ Hư, Thiên Lao.",
        nen: " Cúng Tế, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Chặt Cây, Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Đức Hợp, Nguyệt Đức Hợp, Tứ Tướng, Phổ Hộ.",
        hungThan: " Nguyệt Phá, Đại Hao, Tứ Kích, Cửu Không, Huyền Vũ.",
        nen: " Cúng Tế, Giải Trừ, Dỡ Nhà Phá Tường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Mẫu Thương, Dương Đức, Ngũ Phú, Phúc Sinh, Trừ Thần, Tư Mệnh, Ô Phệ.",
        hungThan: "  Du Họa, Ngũ Li.",
        nen: " Cúng Tế, Tắm Gội, Cắt Tóc, Sửa Móng, Dan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Quét Dọn, Chặt Cây, Săn Bắn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng. ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Họp Thân Hữu, Dính Hôn, An Hỏi, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Lập Ước Giao Dịch.",
      },
      {
        catThan:
          " Mẫu Thương, Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y, Trừ Thần, Ô Phệ.",
        hungThan: " Đại Sát, Ngũ Li, Câu Trần.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Sửa Móng, Chân Tay, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
        khongNen: " Họp Thân Hữu, Cắt Tóc .",
      },
      {
        catThan: " Thánh Tâm, Thanh Long",
        hungThan: " Thiên Cương, Nguyệt Hình, Ngũ Hư.",
        nen: " Cúng Tế, Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Chặt Cây, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Âm Đức, Vương Nhật, Dịch Mã, Thiên Hậu, Thời Dương, Sinh Khí, Ích Hậu, Minh Đường.",
        hungThan: "  Nguyệt Yếm, Địa Hỏa, Phục Nhật, Trùng Nhật.",
        nen: " Cúng Tế, Nhập Học, Tắm Gội.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường,  Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Thiên Đức, Nguyệt Đức, Quan Nhật, Lục Hợp, Bất Tương, Tục Thế, Ô Phệ Đối.",
        hungThan:
          " Thiên Lại, Chí Tử, Huyết Chi, Thổ Phù, Qui Kị, Huyết Kị, Thiên Hình.",
        nen: " Cúng Tế, Tắm Gội, Cắt May, Nấu Rượu, An Táng, Cải Táng.",
        khongNen:
          " Di Chuyển, Đi Xa Trở Về, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan: " Nguyệt Ân, Thủ Nhật, Bất Tương, Yếu An",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Vãng Vong, Chu Tước.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Đính Hôn, Ăn Hỏi, Giải Trừ, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Nạp Tài, Mở Kho Xuất Tiền Hàng, Chăn Nuôi.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Nấu Rượu, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan:
          " Thời Đức, Tướng Nhật, Cát Kì, Ngọc Vũ, Ngũ Hợp, Kim Quĩ, Ô Phệ Đối.",
        hungThan: " Kiếp Sát, Thiên Tặc, Ngũ Hư.",
        nen: " Tắm Gội, Quét Dọn.",
        khongNen:
          " Cúng Tế, Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Mời Thầy Chữa Bệnh, Sửa Kho, Mở Kho Xuất Tiền Hàng, Đào Mương.",
      },
      {
        catThan:
          " Dân Nhật, Thiên Vu, Phúc Đức, Thiên Hương, Kim Đường, Ngũ Hợp, Bảo Quang, Ô Phệ Đối.",
        hungThan: " Tai Sát, Thiên Hỏa.",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Nguyệt Không, Tứ Tướng, Thiên Mã.",
        hungThan: " Hà Khôi, Chí Tử, Nguyệt Sát, Nguyệt Hư, Bạch Hổ.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Đức Hợp, Tứ Tướng, Tam Hợp, Thời Âm, Lục Nghi, Ngọc Đường.",
        hungThan:
          "  Yếm Đối, Chiêu Dao, Tử Khí, Cửu Khảm, Cửu Tiêu, Trùng Nhật.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Xuất Hành, Mời Thầy Chữa Bệnh, Rèn Đúc, Lấp Hang Hố, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng.",
      },
      {
        catThan: " Kính An, Giải Thần, Ô Phệ.",
        hungThan:
          "  Nguyệt Hại, Đại Thời, Đại Bại, Hàm Trì, Tiểu Hao, Tứ Phế, Ngũ Hư, Thiên Lao.",
        nen: " Tắm Gội, Cắt Tóc, Sửa Móng, Chặt Cây, Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Phổ Hộ",
        hungThan:
          "  Nguyệt Phá, Đại Hao, Tứ Kích, Cửu Không, Bát Chuyên, Huyền Vũ, Dương Phá Âm Xung.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan:
          " Mẫu Thương, Dương Đức, Ngũ Phú, Phúc Sinh, Trừ Thần, Tư Mệnh.",
        hungThan: " Du Họa, Ngũ Hư.",
        nen: " Cúng Tế, Tắm Gội, Cắt Tóc, Sửa Móng, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Quét Dọn, Chặt Cây, Săn Bắn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Họp Thân Hữu, Đính Hôn, Ăn Hỏi, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Lập Ước Giao Dịch.",
      },
      {
        catThan:
          " Thiên Ân, Mẫu Thương, Tam Hợp, Lâm Nhật, Thiên Kỉ, Thiên Y, Trừ Thần, Ô Phệ.",
        hungThan: " Đại Sát, Phục Nhật, Ngũ Li, Câu Trần.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Họp Thân Hữu, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Thiên Đức, Nguyệt Đức, Thiên Ân, Thánh Tâm, Thanh Long.",
        hungThan: " Thiên Cương, Nguyệt Hình, Ngũ Hư.",
        nen: " Cúng Tế, Đuổi Bắt.",
        khongNen: " Mời Thầy Chữa Bệnh, Đan Dệt, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Thiên Ân, Nguyệt Ân, Âm Đức, Vương Nhật, Dịch Mã, Thiên Hậu, Thời Dương, Sinh Khí, Ích Hậu, Minh Đường.",
        hungThan: " Nguyệt Yếm, Địa Hỏa, Trùng Nhật.",
        nen: " Cúng Tế, Nhập Học, Tắm Gội.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Thiên Ân, Quan Nhật, Lục Hợp, Tục Thế.",
        hungThan:
          " Thiên Lại, Chí Tử, Huyết Chi, Tứ Kị, Lục Xà, Thổ Phù, Qui Kị, Huyết Kị, Thiên Hình, Trục Trận.",
        nen: " Cúng Tế, Tắm Gội.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Ân, Thủ Nhật, Yếu An.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Vãng Vong, Bát Chuyên, Xúc Thủy Long, Chu Tước, Dương Thác. ",
        nen: " Họp Thân Hữu.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Lấp Hang Hố, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Dỡ Nhà Phá Tường, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá,  Đi Thuyền, Gieo Trồng, Chăn Nuôi, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Không, Tứ Tướng, Thời Đức, Tướng Nhật, Cát Kì, Ngọc Vũ, Ngũ Hợp, Kim Quĩ, Ô Phệ Đối.",
        hungThan: " Kiếp Sát, Thiên Tặc, Ngũ Hư, Bát Phong, Bát Chuyên.",
        nen: " Tắm Gội, Quét Dọn.",
        khongNen:
          " Cúng Tế, Xuất Hành, Đính Hôn, Ăn Hỏi, Cưới Gả, Mời Thầy Chữa Bệnh, Sửa Kho, Mở Kho Xuất Tiền Hàng, Đánh Cá, Đi Thuyền.",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Đức Hợp, Tứ Tướng, Dân Nhật, Thiên Vu, Phúc Đức, Thiên Thương, Kim Đường, Ngũ Hợp, Bảo Quang, Ô Phệ Đối.",
        hungThan: " Tai Sát, Thiên Hỏa, Địa Nang.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Chăn Nuôi, Nạp Gia Súc, An Táng, Cải Táng.",
        khongNen:
          " Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan: " Thiên Mã, Bất Tương.",
        hungThan: " Hà Khôi, Tử Thần, Nguyệt Sát, Nguyệt Hư, Bạch Hổ",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan: " Tam Hợp, Thời Âm, Lục Nghi, Ngọc Đường",
        hungThan:
          " Yếm Đối, Chiêu Dao, Tử Khí, Tứ Phế, Cửu Khảm, Cửu Tiêu, Trùng Nhật.",
        nen: " ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá,Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá,  Đi Thuyền, Gieo Trồng, Chăn Nuôi, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Kính An, Giải Thần.",
        hungThan:
          " Nguyệt Hại, Đại Thời, Đại Bại, Hàm Trì, Tiểu Hao, Ngũ Hư, Thiên Lao.",
        nen: " Tắm Gội, Cắt Tóc, Sửa Móng, Chặt Cây, Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thổ Hộ.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tứ Kích, Cửu Không, Phục Nhật, Bát Chuyên, Huyền Vũ.",
        nen: " Cúng Tế, Dỡ Nhà Phá Tường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Chặt Cây, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Thiên Đức, Nguyệt Đức, Mẫu Thương, Dương Đức, Ngũ Phú, Phúc Sinh, Trừ Thần, Tư Mệnh, Ô Phệ.",
        hungThan: " Du Họa, Ngũ Li, Bát Chuyên.",
        nen: " Cúng Tế, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Di Chuyển, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Quét Dọn, Chặt Cây, Đuổi Bắt, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Đính Hôn, Ăn Hỏi, Cưới Gả, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Đan Dệt, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Mẫu Thương, Nguyệt Ân, Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y, Trừ Thần, Ô Phệ.",
        hungThan: " Tứ Hao, Đại Sát, Ngũ Li, Câu Trần.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May,  Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Quét Dọn, Chặt Cây, Đuổi Bắt, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng.",
        khongNen: " Họp Thân Hữu, Nấu Rượu.",
      },
      {
        catThan: " Thánh Tâm, Thanh Long.",
        hungThan: " Thiên Cương, Nguyệt Hình, Ngũ Hư.",
        nen: " Cúng Tế, Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Dỡ Nhà Phá Tường, Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Âm Đức, Vương Nhật, Dịch Mã, Thiên Hậu, Thời Dương, Sinh Khí, Ích Hậu, Minh Đường.",
        hungThan:
          " Nguyệt Yếm, Địa Hỏa, Tứ Cùng, Lục Xà, Trùng Nhật, Đại Hội, Âm Thác",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm",
      },
    ],
    [
      {
        catThan:
          " Thiên Ân, Mẫu Thương, Thời Dương, Sinh Khí, Ích Hậu, Thanh Long.",
        hungThan: " Tai Sát, Thiên Hỏa, Tứ Kị, Bát Long, Phục Nhật.",
        nen: " Cúng Tế, Nhập Học, Tắm Gội.",
        khongNen:
          " Đội Mũ Cài Trâm, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Mời Thầy Chữa Bệnh, Đan Dệt, Nấu Rượu, Mở Kho Xuất Tiền Hàng, Chặt Cây, Săn Bắn, Đánh Cá, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Thiên Ân, Tục Thế, Minh Đường.",
        hungThan:
          " Nguyệt Sát, Nguyệt Hư, Huyết Chi, Thiên Tặc, Ngũ Hư, Thổ Phù, Qui Kị, Huyết Kị.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan:
          " Nguyệt Đức, Thiên Ân, Nguyệt Ân, Tứ Tướng, Thiên Thương, Vương Nhật, Bất Tương, Yếu An, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Vãng Vong, Thiên Hình.",
        nen: " Họp Thân Hữu, Đính Hôn, Ăn Hỏi, Giải Trừ, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Chăn Nuôi , Nạp Gia Súc, An Táng.",
        khongNen:
          " Cúng Tế, Dâng Biểu Sớ, Nhận Phong Tước Vị, Lên Quan Nhậm Chức, Gặp Dân, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà, Phá Tường, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng.",
      },
      {
        catThan:
          " Thiên Đức, Thiên Ân, Tứ Tướng, Quan Nhật, Cát Kì, Bất Tương, Ngọc Vũ, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Đại Thời, Đại Bại, Hàm Trì, Chu Tước.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Tắm Gội, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
        khongNen: " Cắt Tóc, Đào Giếng, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Thiên Ân, Thủ Nhật, Thiên Vu, Phúc Đức, Lục Nghi, Kim Đường, Kim Quĩ.",
        hungThan: " Yếm Đối, Chiếu Dao, Cửu Không, Cửu Khảm, Cửu Tiêu.",
        nen: " Cúng Tế, Cầu Phúc, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Cắt May, Đan Dệt.",
        khongNen:
          " Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Mời Thầy Chữa Bệnh, Sửa Kho, Rèn Đúc, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Lấp Hang Hố, Đánh Cá, Đi Thuyền, Gieo Trồng.",
      },
      {
        catThan: " Tướng Nhật, Bảo Quang.",
        hungThan:
          " Thiên Cương, Tử Thần, Nguyệt Hình, Nguyệt Hại, Du Họa, Ngũ Hư, Trùng Nhật.",
        nen: " Sửa Đường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Thời Đức, Dân Nhật, Tam Hợp, Lâm Nhật, Thiên Mã, Thời Âm, Ô Phệ.",
        hungThan: " Tử Khí, Địa Nang, Bạch Hổ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Lợp Mái, Gác Đòn Dông, Đan Dệt, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan: " Nguyệt Đức Hợp, Kính An, Ngọc Đường.",
        hungThan: " Tiểu Hao.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đuổi Bắt, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen: " Mời Thầy Chữa Bệnh, Nấu Rượu, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Không, Dịch Mã, Thiên Hậu, Phổ Hộ, Giải Thần, Ô Phệ.",
        hungThan: " Nguyệt Phá, Đại Hao, Ngũ Li, Thiên Lao.",
        nen: " Cúng Tế, Giải Trừ, Tắm Gội, Mời Thầy Chữa Bệnh, Quét Dọn, Dỡ Nhà Phá Tường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Cắt May, Đắp Đê,Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Âm Đức, Phúc Sinh, Trừ Thần, Ô Phệ.",
        hungThan: " Thiên Lại, Chí Tử, Ngũ Hư, Ngũ Li, Huyền Vũ.",
        nen: " Cúng Tế, Tắm Gội, Cắt Tóc, Sửa Móng, Quét Dọn, Đánh Cá, Phá Thổ, An Táng.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
      },
      {
        catThan: " Dương Đức, Tam Hợp, Thiên Hỷ, Thiên Y, Tư Mệnh.",
        hungThan: " Nguyệt Yếm, Địa Hỏa, Tứ Kích, Đại Sát, Phục Nhật, Đại Hội.",
        nen: "",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Mẫu Thương, Thiên Nguyện, Lục Hợp, Ngũ Phú, Thánh Tâm.",
        hungThan:
          " Hà Khôi, Kiếp Sát, Tứ Cùng, Bát Long, Trùng Nhật, Câu Trần.",
        nen: " Cúng Tế Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Tắm Gội, Cắt May, Tu Tạo Động Thổ, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đuổi Bắt, Đánh Cá, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Cưới Gả, Mời Thầy Chữa Bệnh, Gieo Trồng.",
      },
      {
        catThan:
          " Nguyệt Đức, Mẫu Thương, Nguyệt Ân, Tứ Tướng, Thời Dương, Sinh Khí, Bất Tương, Ích Hậu, Thanh Long, Ô Phệ Đối.",
        hungThan: " Tai Sát, Thiên Hỏa, Xúc Thủy Long.",
        nen: " Cúng Tế Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Tắm Gội, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Khơi Mương Đào Giếng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Mời Thầy Chữa Bệnh, Chặt Cây, Săn Bắn, Đánh Cá, Đi Thuyền.",
      },
      {
        catThan: " Thiên Đức, Tứ Tướng, Bất Tương, Tục Thế, Minh Đường.",
        hungThan:
          " Nguyệt Sát, Nguyệt Hư, Huyết Chi, Thiên Tặc, Ngũ Hư, Bát Phong, Thổ Phù, Qui Kị, Huyết Kị.",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Thiên Xá, Vượng Nhật, Thiên Thương, Yếu An, Ngũ Hợp.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Vãng Vong, Thiên Hình.",
        nen: " Họp Thân Hữu, Đính Hôn, Ăn Hỏi, Giải Trừ, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Lập Ước Giao Dịch, Nạp Tài, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Cúng Tế, Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan: " Thiên Ân, Quan Nhật, Cát Kì, Bất Tương, Ngọc Vũ, Ngũ Hợp.",
        hungThan: " Đại Thời, Đại Bại, Hàm Trì, Chu Tước.",
        nen: " Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Lập Ước Giao Dịch, Quét Dọn.",
        khongNen: " Đào Giếng.",
      },
      {
        catThan:
          " Thiên Ân, Thủ Nhật, Thiên Vu, Phúc Đức, Lục Nghi, Kim Đường, Kim Quĩ.",
        hungThan: " Yếm Đối, Chiêu Dao, Cửu Không, Cửu Khảm, Cửu Tiêu.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Cắt May.",
        khongNen:
          " Lên Quan, Nhậm Chức, Gặp Dân, Đính Hôn, Cưới Gả, Thu Nạp Người, Mời Thầy Chữa Bệnh, Sửa Kho, Rèn Đúc, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Lấp Hang Hố, Đánh Cá, Đi Thuyền, Gieo Trồng.",
      },
      {
        catThan: " Nguyệt Đức Hợp, Thiên Ân, Tướng Nhật, Bảo Quang.",
        hungThan:
          " Thiên Cương, Tử Thần, Nguyệt Hình, Nguyệt Hại, Du Họa, Ngũ Hư, Trùng Nhật.",
        nen: " Cúng Tế, Sửa Đường",
        khongNen:
          " Cầu Phúc, Cầu Tự, Xuất Hành, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Nấu Rượu, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Không, Thiên Ân, Thời Đức, Dân Nhật, Tam Hợp, Lâm Nhật, Thiên Mã, Thời Âm, Ô Phệ.",
        hungThan: " Tử Khí, Bạch Hổ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng.",
        khongNen:
          " Mời Thầy Chữa Bệnh, Lợp Mái, Gác Đòn Dông, Khơi Mương, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Thiên Ân, Kính An, Ngọc Đường.",
        hungThan: " Tiểu Hao, Xúc Thủy Long.",
        nen: " Họp Thân Hữu, Đuổi Bắt.",
        khongNen:
          " Mời Thầy Chữa Bệnh, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đánh Cá, Đi Thuyền.",
      },
      {
        catThan: " Dịch Mã, Thiên Hậu, Phổ Hộ, Giải Thần, Trừ Thần, Ô Phệ.",
        hungThan: " Nguyệt Phá, Đại Hao, Phục Nhật, Ngũ Li, Thiên Lao.",
        nen: " Cúng Tế, Giải Trừ, Tắm Gội, Mời Thầy Chữa Bệnh, Quét Dọn, Dỡ Nhà Phá Tường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Chặt Cây, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Âm Đức, Phúc Sinh, Trừ Thần, Ô Phệ.",
        hungThan: " Thiên Lại, Chí Tử, Ngũ Hư, Ngũ Li, Huyền Vũ.",
        nen: " Cúng Tế, Tắm Gội, Cắt Tóc, Sửa Móng, Quét Dọn, Đánh Cá, Phá Thổ, An Táng.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
      },
      {
        catThan:
          " Nguyệt Đức, Nguyệt Ân, Tứ Tướng, Dương Đức, Tam Hợp, Thiên Hỉ, Thiên Y, Tư Mệnh.",
        hungThan: " Nguyệt Yếm, Địa Hỏa, Tứ Kích, Đại Sát.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Thu Nạp Người, Giải Trừ, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Đi Xa Trở Về, Mời Thầy Chữa Bệnh, Săn Bắn, Đánh Cá, Gieo Trồng.",
      },
      {
        catThan:
          " Thiên Đức, Mẫu Thương, Tứ Tướng, Lục Hợp, Ngũ Phúc, Bất Tương, Thánh Tâm.",
        hungThan: " Hà Khôi, Kiếp Sát, Trùng Nhật, Cầu Tần.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đuổi Bắt, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Cưới Gả, Cắt Tóc, Mời Thầy Chữa Bệnh, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Mẫu Thương, Thời Dương, Sinh Khí, Ích Hậu, Thanh Long.",
        hungThan: " Tai Sát, Thiên Hỏa.",
        nen: " Cúng Tế, Nhập Học, Tắm Gội.",
        khongNen:
          " Đội Mũ Cài Trâm, Đính Hôn, Ăn Hỏi, Cưới Gả, Mời Thầy Chữa Bệnh, Thu Nạp Người, Đan Dệt, Nấu Rượu, Chặt Cây, Săn Bắn, Đánh Cá, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Bất Tương, Tục Thế, Minh Đường.",
        hungThan:
          " Nguyệt Sát, Nguyệt Hư, Huyết Chi, Thiên Tặc, Ngũ Hư, Thổ Phù, Qui Kị, Huyết Kị.",
        nen: "",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan:
          " Vượng Nhật, Thiên Thương, Bất Tương, Yếu An, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Vãng Vong, Thiên Hình.",
        nen: " Họp Thân Hữu, Cắt May, Lập Ước Giao Dịch, Nạp Tài, Nạp Gia Súc.",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Quan Nhật, Cát Kì, Bất Tương, Ngọc Vũ, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Đại Thời, Đại Bại, Hàm Trì.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Lập Ước Giao Dịch, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
        khongNen: "",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Không, Thủ Nhật, Thiên Vu, Phúc Đức, Lục Nghi, Kim Đường, Kim Quĩ.",
        hungThan: " Yếm Đối, Chiêu Dao, Cửu Không, Cửu Khảm, Cửu Tiêu.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Rèn Đúc, Khơi Mương Đào Giếng, Lấp Hang Hố, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng.",
      },
      {
        catThan: " Tướng Nhật, Bảo Quang.",
        hungThan:
          " Thiên Cương, Tử Thần, Nguyệt Hình, Nguyệt Hại, Du Họa, Ngũ Hư, Trùng Nhật.",
        nen: " Sửa Đường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Thời Đức, Dân Nhật, Tam Hợp, Lâm Nhật, Thiên Mã, Thời Âm, Ô Phệ.",
        hungThan: " Tử Khí, Phục Nhật, Bạch Hổ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Giải Trừ, Mời Thầy Chữa Bệnh, Lợp Mái, Gác Đòn Dông, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Kính An, Ngọc Đường.",
        hungThan: " Tiểu Hao, Ngũ Mộ.",
        nen: " Đuổi Bắt, Đánh Cá",
        khongNen:
          " Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Nguyệt Đức, Nguyệt Ân, Tứ Tướng, Dịch Mã, Thiên Hậu, Phổ Hộ, Giải Thần, Trừ Thần, Ô Phệ.",
        hungThan: " Nguyệt Phá, Đại Hao, Ngũ Li, Thiên Lao.",
        nen: " Cúng Tế, Giải Trừ, Tắm Gội, Mời Thầy Chữa Bệnh, Quét Dọn, Dỡ Nhà Phá Tường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Thiên Đức, Tứ Tướng, Âm Đức, Phúc Sinh, Trừ Thần, Ô Phệ.",
        hungThan: " Thiên Lại, Chí Tử, Ngũ Hư, Ngũ Li, Huyền Vũ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự , Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Kê Giường, Giải Trừ, Tắm Gội, Sửa Móng, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Nạp Tài, Mở Kho Xuất Tiền Hàng, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng.",
        khongNen:
          " Họp Thân Hữu, Cắt Tóc, Mời Thầy Chữa Bệnh, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Dương Đức, Tam Hợp, Thiên Hỉ, Thiên Y, Tư Mệnh.",
        hungThan: " Nguyệt Yếm, Địa Hỏa, Tử Kích, Đại Sát.",
        nen: " Nhập Học",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Xin, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Mẫu Thương, Lục Hợp, Ngũ Phú, Bất Tương, Thánh Tâm.",
        hungThan: " Hà Khôi, Kiếp Sát, Trùng Nhật, Câu Trần.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Đính Hôn, Thu Nạp Người, Tắm Gội, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đuổi Bắt, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Cưới Gả, Mời Thầy Chữa Bệnh, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Mẫu Thương, Thời Dương, Sinh Khí, Bất Tương, Ích Hậu, Thanh Long, Ô Phệ Đối.",
        hungThan: " Tai Sát, Thiên Hỏa, Địa Nang.",
        nen: " Cúng Tế, Nhập Học, Tắm Gội.",
        khongNen:
          " Đội Mũ Cài Trâm, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Đan Dệt, Nấu Rượu, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Đường, Dỡ Nhà Phá Tường, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan: " Nguyệt Đức Hợp, Bất Tương, Tục Thế, Minh Đường.",
        hungThan:
          " Nguyệt Sát, Nguyệt Hư, Huyết Chi, Thiên Tặc, Ngũ Hư, Thổ Phù, Qui Kị, Huyết Kị.",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Không, Vương Nhật, Thiên Thương, Yếu An, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Vãng Vong, Thiên Hình.",
        nen: " Họp Thân Hữu, Đính Hôn, Ăn Hỏi, Giải Trừ, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Lập Ước Giao Dịch, Nạp Tài, Chăn Nuôi, Nạp Gia Súc, An Táng, Cải Táng.",
        khongNen:
          " Cúng Tế, Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan: " Quan Nhật, Cát Kì, Ngọc Vũ, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Đại Thời, Đại Bại, Hàm Trì, Chu Tước.",
        nen: " Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Lập Ước Giao Dịch, Quét Dọn, Phá Thổ, Cải Táng.",
        khongNen: " Đào Giếng.",
      },
      {
        catThan: " Thủ Nhật, Thiên Vu, Phúc Đức, Lục Nghi, Kim Đường, Kim Quĩ.",
        hungThan:
          " Yếm Đối, Chiêu Dao, Cửu Không, Cửu Khảm, Cửu Tiêu, Phục Nhật.",
        nen: " Cúng Tế, Cầu Phúc, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Cắt May, Đan Dệt.",
        khongNen:
          " Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Mời Thầy Chữa Bệnh, Sửa Kho, Rèn Đúc, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Lấp Hang Hố, Đánh Cá, Đi Thuyền, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Tướng Nhật, Bảo Quang.",
        hungThan:
          " Thiên Cương, Tử Thần, Nguyệt Hại, Du Họa, Ngũ Hư, Trùng Nhật.",
        nen: " Sửa Đường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Nguyệt Đức, Nguyệt Ân, Tứ Tướng, Thời Đức, Dân Nhật, Tam Hợp, Lâm Nhật, Thiên Mã, Thời Âm, Ô Phệ.",
        hungThan: " Tử Khí, Bạch Hổ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng.",
        khongNen:
          " Mời Thầy Chữa Bệnh, Lợp Mái, Gác Đòn Dông, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Thiên Đức, Tứ Tướng, Kính An, Ngọc Đường.",
        hungThan: " Tiểu Hao, Bát Chuyên.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Di Chuyển, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đuổi Bắt, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Đính Hôn, Ăn Hỏi, Cưới Gả, Cắt Tóc, Mời Thầy Chữa Bệnh, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Dịch Mã, Thiên Hậu, Phổ Hộ, Giải Thần, Trừ Thần.",
        hungThan: " Nguyệt Phá, Đại Hao, Ngũ Li, Thiên Hao.",
        nen: " Cúng Tế, Giải Trừ, Tắm Gội, Mời Thầy Chữa Bệnh, Quét Dọn, Dỡ Nhà Phá Tường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Sửa Kho, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường,Chặt Cây, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Thiên Ân, Âm Đức, Phúc Sinh, Trừ Thần, Ô Phệ.",
        hungThan: " Thiên Lại, Chí Tử, Ngũ Hư, Ngũ Li, Huyền Vũ.",
        nen: " Cúng Tế, Tắm Gội, Cắt Tóc, Sửa Móng, Quét Dọn, Đánh Cá, Phá Thổ, An Táng.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê,Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
      },
      {
        catThan: " Thiên Ân, Dương Đức, Tam Hợp, Thiên Hỉ, Thiên Y, Tư Mệnh. ",
        hungThan: " Nguyệt Yếm, Địa Hỏa, Tứ Kích, Đại Sát, Âm Thác.",
        nen: " Nhập Học.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Thiên Ân, Mẫu Thương, Lục Hợp, Ngũ Phú, Bất Tương, Thánh Tâm.",
        hungThan: " Hà Khôi, Kiếp Sát, Trùng Nhật, Câu Trần.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đuổi Bắt, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Cưới Gả, Mời Thầy Chữa Bệnh, Nấu Rượu, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Không, Thiên Ân, Mẫu Thương, Thời Dương, Sinh Khí, Ích Hậu, Thanh Long, Ô Phệ Đối.",
        hungThan: " Tai Sát, Thiên Hỏa, Tứ Hao.",
        nen: " Cúng Tế Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Tắm Gội, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Nạp Tài, Xếp Đặt Buồng Đẻ, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Mời Thầy Chữa Bệnh, Khơi Mương Đào Giếng, Chặt Cây, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Thiên Ân, Tục Thế, Minh Đường.",
        hungThan:
          " Nguyệt Sát, Nguyệt Hư, Huyết Chi, Thiên Tặc, Ngũ Hư, Thổ Phù, Qui Kị, Huyết Kị, Bát Chuyên, Xúc Thủy Long.",
        nen: "",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Vương Nhật, Thiên Thương, Yếu An, Ngũ Hợp, Ô Phệ Đối.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Vãng Vong, Phục Nhật, Bát Chuyên, Thiên Hình, Dương Thác.",
        nen: " Họp Thân Hữu, Cắt May, Lập Ước Giao Dịch, Nạp Tài, Nạp Gia Súc.",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Quan Nhật, Cát Kì, Ngọc Vũ, Vũ Hợp, Ô Phệ Đối.",
        hungThan: " Đại Thời, Đại Bại, Hàm Trì, Chu Tước.",
        nen: " Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Lập Ước, Giao Dịch Quét Dọn, Phá Thổ, Cải Táng.",
        khongNen: " Đào Giếng, Gieo Trồng.",
      },
      {
        catThan:
          " Nguyệt Đức, Nguyệt Ân, Tứ Tướng, Thủ Nhật, Thiên Vu, Phúc Đức, Lục Nghi, Kim Đường, Kim Quĩ.",
        hungThan: " Yếm Đối, Chiêu Dao, Cửu Không, Cửu Khảm, Cửu Tiêu.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Rèn Đúc, Lấp Hang Hố, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng.",
      },
      {
        catThan: " Thiên Đức, Tứ Tướng, Tướng Nhật, Bảo Quang.",
        hungThan:
          " Thiên Cương, Tử Thần, Nguyệt Hình, Nguyệt Hại, Du Họa, Ngũ Hư, Bát Phong, Trùng Nhật.",
        nen: " Cúng Tế, Sửa Đường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Xuất Hành, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Thời Đức, Dân Nhật, Tam Hợp, Lâm Nhật, Thiên Mã, Thời Dương.",
        hungThan: " Tử Khí, Bạch Hổ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Giải Trừ, Mời Thầy Chữa Bệnh, Lợp Mái, Gác Đòn Dông, Xếp Đặt Buồng Đẻ, Gieo Trồng.",
      },
      {
        catThan: " Kính An, Ngọc Đường.",
        hungThan: " Tiểu Hao, Bát Chuyên.",
        nen: " Đuổi Bắt, Đánh Cá.",
        khongNen:
          " Đính Hôn, Ăn Hỏi, Cưới Gả, Mời Thầy Chữa Bệnh, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng.",
      },
      {
        catThan: " Dịch Mã, Thiên Hậu, Phổ Hộ, Giải Thần, Trừ Thần, Ô Phệ.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tứ Phế, Ngũ Li, Bát Chuyên, Thiên Lao.",
        nen: "",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan: " Nguyệt Đức Hợp, Âm Đức, Phúc Sinh, Trừ Thần, Ô Phệ.",
        hungThan:
          " Thiên Lại, Chí Tử, Tứ Phệ, Ngũ Hư, Ngũ Li, Huyền Vũ, Tam Âm.",
        nen: "",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Không, Dương Đức, Tam Hợp, Thiên Hỉ, Thiên Y, Tư Mệnh.",
        hungThan: " Nguyệt Yếm, Địa Hỏa, Tứ Kích, Đại Sát.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Thu Nạp Người, Giải Trừ, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Đi Xa Trở Về, Mời Thầy Chữa Bệnh, Khơi Mương Đào Giếng, Săn Bắn, Đánh Cá, Gieo Trồng.",
      },
      {
        catThan: " Mẫu Thương, Lục Hợp, Ngũ Phú, Thánh Tâm.",
        hungThan: " Hà Khôi, Kiếp Sát, Trùng Nhật, Câu Trần.",
        nen: " Cúng Tế, Tắm Gội.",
        khongNen: " Cưới Gả, Mời Thầy Chữa Bệnh, Phá Thổ, An Táng, Cải Táng.",
      },
    ],
    [
      {
        catThan: " Nguyệt Đức, Thiên Ân, Mẫu Thương, Dương Đức, Tư Mệnh.",
        hungThan:
          " Thiên Cương, Nguyệt Hình, Đại Thời, Đại Bại, Hàm Trì, Thiên Tặc, Tứ Kị, Bát Long.",
        nen: " Cúng Tế, Tắm Gội, Đuổi Bắt.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Thiên Ân, Thời Dương, Sinh Khí, Thiên Thương, Bất Tương, Kính An.",
        hungThan:
          " Ngũ Hư, Cửu Không, Cửu Khảm, Cửu Tiêu, Phục Nhật, Câu Trần.",
        nen: " Cúng Tế, Cầu Tự,Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Cưới Gả, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Đội Mũ Cài Trâm, Thu Nạp Người, Sửa Kho, Rèn Đúc, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Lấp Hang Hố, Chặt Cây, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Thiên Ân, Tứ Tướng, Vương Nhật, Ngũ Phú, Bất Tương, Phổ Hộ, Ngũ Hợp, Thanh Long, Ô Phệ Đối.",
        hungThan: " Du Họa, Huyết Chi, Qui Kị.",
        nen: " Cắt May, Dắp Dê, Sửa Kho, Dan Dệt, Nấu Rượu, Lập Ước, Giao Dịch, Nạp Tài, Lấp Hang Hố, Gieo Trồng Chăn Nuôi, Nạp Gia Súc, Phá Thổ, Cải Táng.",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Dính Hôn, An Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Di Xa Trở Về, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Tu Tạo Dộng Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Khai Trương, Nhập Trạch, Mở Kho Xuất Tiền Hàng, Xếp Dặt Buồng Dẻ, Khơi Mương Dào Giếng.",
      },
      {
        catThan:
          " Thiên Ân, Nguyệt Ân, Tứ Tướng, Quan Nhật, Cát Kỳ, Lục Nghi, Phúc Sinh, Ngũ Hợp, Minh Đường, Ô Phệ Đối.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Yếm Đối, Chiêu Dao.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Chăn Nuôi, Cải Táng.",
        khongNen:
          " Cưới Gả, Cắt Tóc, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan: " Thiên Ân, Thủ Nhật, Cát Kì.",
        hungThan: " Nguyệt Hại, Thiên Hình.",
        nen: " Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Quét Dọn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Mời Thầy Chữa Bệnh, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Tướng Nhật, Dịch Mã, Thiên Hậu, Thiên Vu, Phúc Đức, Thánh Tâm.",
        hungThan: " Ngũ Hư, Thổ Phù, Đại Sát, Vãng Vong, Trùng Nhật, Chu Tước.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Đính Hôn, Ăn Hỏi, Giải Trừ, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Dỡ Nhà Phá Tường, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan: " Nguyệt Không, Thời Đức, Dân Nhật, Ích Hậu, Kim Quĩ, Ô Phệ.",
        hungThan: " Hà Khôi, Tử Thần, Thiên Lại, Chí Tử.",
        nen: " Cúng Tế, Sửa Đường, Sửa Tường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Âm Đức, Tam Hợp, Thời Âm, Tục Thế, Bảo Quang.",
        hungThan: " Tử Khí, Huyết Kị",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Đội Mũ Cài Trâm, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Nạp Gia Súc.",
        khongNen:
          " Giải Trừ, Mời Thầy Chữa Bệnh, Châm Cứu, Nấu Rượu, Xếp Đặt Buồng Đẻ, Gieo Trồng.",
      },
      {
        catThan: " Thiên Mã, Yến An, Giải Thần, Trừ Thần, Ô Phệ.",
        hungThan: " Kiếp Sát, Tiểu Hao, Ngũ Li, Bạch Hổ.",
        nen: " Tắm Gội, Quét Dọn, Đuổi Bắt, Đánh Cá.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kkê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Ngọc Vũ, Trừ Thần, Ngọc Đường, Ô Phệ.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tai Sát, Thiên Hỏa, Nguyệt Yếm, Địa Hỏa, Ngũ Hư, Ngũ Li.",
        nen: "",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Nguyệt Đức, Thiên Nguyện, Lục Hợp, Kim Đường.",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Tứ Kích, Thiên Lao.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng, Cải Táng.",
        khongNen: " ",
      },
      {
        catThan: " Mẫu Thương, Tam Hợp, Lâm Nhật, Thiên Y, Bất Tương.",
        hungThan: " Tứ Cùng, Bát Long, Phục Nhật, Trùng Nhật, Huyền Vũ.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Di Chuyển, Tắm Gội, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Nấu Rượu, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Mẫu Thương, Tứ Tướng, Dương Đức, Bất Tương, Tư Mệnh, Ô Phệ Đối.",
        hungThan:
          " Thiên Cương, Nguyệt Hình, Đại Thời, Thiên Tặc, Hàm Trì, Đại Bại, Xúc Thủy Long.",
        nen: "",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan:
          " Nguyệt Ân, Tứ Tường, Thời Dương, Sinh Khí, Thiên Thương, Bất Tương, Kính An.",
        hungThan:
          " Ngũ Hư, Bát Phong, Cửu Không, Cửu Khảm, Cửu Tiêu, Câu Trần.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Đội Mũ Cài Trâm, Thu Nạp Người, Cắt Tóc, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Lấp Hang Hố, Chặt Cây, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng.",
      },
      {
        catThan: " Thiên Xá, Vương Nhật, Ngũ Phú, Phổ Hộ, Ngũ Hợp, Thanh Long.",
        hungThan: " Du Họa, Huyết Chi, Qui Kị.",
        nen: " Cắt May, Dắp Dê, Sửa Kho, Dan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Lấp Hang Hố, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Di Chuyển, Di Xa Trở Về, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Săn Bắn, Dánh Cá.",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Thiên Ân, Quan Nhật, Lục Nghi, Phúc Sinh, Ngũ Hợp, Minh Đường.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phù, Yếm Đối, Chiêu Dao, Tiểu Hội.",
        nen: "",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Thiên Không, Thiên Ân,Thủ Nhật, Cát Kì.",
        hungThan: " Nguyệt Hại, Thiên Hình.",
        nen: " Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Giải Trừ, Tắm Gội, Cắt Sửa Móng, Quét Dọn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Mời Thầy Chữa Bệnh, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Thiên Ân, Tướng Nhật, Dịch Mã, Thiên Hậu, Thiên Vu, Phúc Đức, Thánh Tâm.",
        hungThan: " Ngũ Hư, Thổ Phù, Đại Sát, Vãng Vong, Trùng Nhật, Chu Tước.",
        nen: " Cúng Tế, Cầu Phúc, Họp Thân Hữu, Cắt May, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Nấu Rượu, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Đuổi Bắt, Săn Bắn Đánh Cá, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Thiên Ân, Thời Đức, Dân Nhật, Ích Hậu, Kim Quĩ, Ô Phệ. ",
        hungThan: " Hà Khôi, Tử Thần, Thiên Lại, Chí Tử.",
        nen: " Cúng Tế, Sửa Tường, Sửa Đường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Thiên Ân, Âm Đức, Tam Hợp, Thời Âm, Tục Thế, Bảo Quang.",
        hungThan: " Tử Khí, Huyết Kị, Xúc Thủy Long.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Đội Mũ Cài Trâm, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Lập Ước, Giao Dịch, Nạp Tài, Đặt Cối Đá, Nạp Gia Súc.",
        khongNen:
          " Giải Trừ, Mời Thầy Chữa Bệnh, Châm Cứu, Xếp Đặt Buồng Đẻ, Đánh Cá, Đi Thuyền, Gieo Trồng.",
      },
      {
        catThan: " Nguyệt Đức, Thiên Mã, Yếu An, Giải Thần, Trừ Thần, Ô Phệ.",
        hungThan: " Kiếp Sát, Tiểu Hao, Ngũ Li, Bạch Hổ.",
        nen: " Cúng Tế, Tắm Gội, Quét Dọn, Đuổi Bắt.",
        khongNen:
          " Kê Giường, Mời Thầy Chữa Bệnh, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Ngọc Vũ, Trừ Thần, Ngọc Đường, Ô Phệ.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tai Sát, Thiên Hỏa, Nguyệt Yếm, Địa Hỏa, Ngũ Hư, Phục Nhật, Ngũ Li, Đại Hội.",
        nen: "",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan: " Tứ Tướng, Lục Hợp, Bất Tương, Kim Đường.",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Tứ Kích, Thiên Hao.",
        nen: " Cúng Tế, Đánh Cá.",
        khongNen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Mời Thầy Chữa Bệnh.",
      },
      {
        catThan:
          " Mẫu Thương, Nguyệt Ân, Tứ Tướng, Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y, Bất Tương.",
        hungThan: " Trùng Nguyệt, Huyền Vũ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Cưới Gả, Cắt Tóc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Mẫu Thương, Dương Đức, Tư Mệnh.",
        hungThan:
          " Thiên Cương, Nguyệt Hình, Đại Thời, Đại Bại, Hàm Trì, Thiên Tặc.",
        nen: "",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Thời Dương, Sinh Khí, Thiên Thương, Bất Tương, Kính An.",
        hungThan: " Ngũ Hư, Cửu Không, Cửu Khảm, Cửu Tiêu, Câu Trần.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Nạp Tài, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Đội Mũ Cài Trâm, Rèn Đúc, Sửa Tường, Lấp Hang Hố, Chặt Cây, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng.",
      },
      {
        catThan:
          " Nguyệt Không, Vượng Nhật, Ngũ Phú, Bất Tương, Phổ Hộ, Ngũ Hợp, Thanh Long, Ô Phệ Đối.",
        hungThan: " Du Họa, Huyết Chi, Qui Kị.",
        nen: " Cắt May, Dắp Dê, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Lấp Hang Hố, Sửa Dường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, Cải Táng.",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Dính Hôn, An Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Di Xa Trở Về, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Tu Tạo Dộng Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Dan Dệt, Khai Trương, Nhập Trạch, Mở Kho Xuất Tiền Hàng, Xếp Dặt Buồng Dẻ, Khơi Mương Dào Giếng.",
      },
      {
        catThan:
          " Quan Nhật, Lục Nghi, Phúc Sinh, Ngũ Hợp, Minh Đường, Ô Phệ Đối.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Yếm Đối, Chiêu Dao.",
        nen: " Cúng Tế, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Lập Ước Giao Dịch.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Đính Hôn, Ăn Hỏi, Cưới Gả, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Nấu Rượu, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Thủ Nhật, Cát Kỳ.",
        hungThan: " Nguyệt Hại, Thiên Hình.",
        nen: " Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Quét Dọn Nhà Cửa.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Mời Thầy Chữa Bệnh, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Tướng Nhật, Dịch Mã, Thiên Hậu, Thiên Vu, Phúc Đức, Thánh Tâm.",
        hungThan: " Ngũ Hư, Thổ Phù, Đại Sát, Vãng Vong, Trùng Nhật, Chu Tước.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Cắt May, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Dỡ Nhà Phá Tường, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Nguyệt Đức, Thời Đức, Dân Nhật, Ích Hậu, Kim Quỹ, Ô Phệ.",
        hungThan: " Hà Khôi, Tử Thần, Thiên Lại, Chí Tử.",
        nen: " Cúng Tế, Sửa Đường, Sửa Tường.",
        khongNen:
          " Mời Thầy Chữa Bệnh, Lợp Mái, Gác Đòn Dông, Mở Kho Xuất Tiền Hàng, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Âm Đức, Tam Hợp, Thời Âm, Tục Thế, Bảo Quang.",
        hungThan: " Tử Khí, Ngũ Mộ, Địa Nang, Huyết Kỵ, Phục Nhật.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Cắt May, Đan Dệt, Nấu Rượu, Nạp Tài.",
        khongNen:
          " Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng, Cải Táng.",
      },
      {
        catThan: " Tứ Tướng, Thiên Mã, Yếu An, Giải Thần, Trừ Thần, Ô Phệ.",
        hungThan: " Kiếp Sát, Tiểu Hao, Ngũ Li, Bạch Hổ.",
        nen: " Cúng Tế, Tắm Gội, Quét Dọn, Đuổi Bắt, Đánh Cá.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Nguyệt Ân, Tứ Tướng, Ngọc Vũ, Trừ Thần, Ngọc Đường, Ô Phệ.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tai Sát, Thiên Hỏa, Nguyệt Yếm, Địa Hỏa, Ngũ Hư, Ngũ Li.",
        nen: "",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Lục Hợp, Kim Đường.",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Tứ Kích, Thiên Lao.",
        nen: " Đánh Cá.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi.",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Mẫu Thương, Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y, Bất Tương.",
        hungThan: " Trùng Nhật, Huyền Vũ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Cưới Gả, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Nguyệt Không, Mẫu Thương, Dương Đức, Bất Tương, Tư Mệnh, Ô Phệ Đối.",
        hungThan:
          " Thiên Cương, Nguyệt Hình, Đại Thời, Đại Bại, Hàm Trì, Thiên Tặc.",
        nen: "",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Thời Dương, Sinh Khí, Thiên Thương, Kính An.",
        hungThan: " Ngũ Hư, Cửu Không, Cửu Khảm, Cửu Tiêu, Câu Trần.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Đội Mũ Cài Trâm, Thu Nạp Người, Sửa Kho, Rèn Đúc, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Lấp Hang Hố, Chặt Cây, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng.",
      },
      {
        catThan:
          " Vương Nhật, Ngũ Phúc, Phổ Hộ, Ngũ Hợp, Thanh Long, Ô Phệ Đối.",
        hungThan: " Du Họa, Huyết Chi, Qui Kị.",
        nen: " Cắt May, Dắp Dê, Dan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Lấp Hang Hố, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, Cải Táng.",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Dính Hôn, Cưới Gả, Thu Nạp Người, Di Chuyển, Di Xa Trở Về, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Tu Tạo Dộng Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Khai Trương, Nhập Trạch, Mở Kho Xuất Tiền Hàng, Xếp Dặt Buồng Dẻ, Khơi Mương Dào Giếng.",
      },
      {
        catThan:
          " Quan Nhật, Lục Nghi, Phúc Sinh, Ngũ Hợp, Minh Đường, Ô Phệ Đối.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Yếm Đối, Chiêu Dao.",
        nen: " Cúng Tế, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Lập Ước Giao Dịch.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Đính Hôn, Ăn Hỏi, Cưới Gả, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Nguyệt Đức, Thủ Nhật, Cát Kỳ.",
        hungThan: " Nguyệt Hại, Thiên Hình.",
        nen: " Cúng Tế, Cầu Phúc, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Mời Thầy Chữa Bệnh, Mở Kho Xuất Tiền Hàng, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Tướng Nhật, Dịch Mã, Thiên Hậu, Thiên Vu, Phúc Đức, Thánh Tâm.",
        hungThan:
          " Ngũ Hư, Thổ Phù, Đại Sát, Vãng Vong, Phục Nhật, Trùng Nhật, Chu Tước.",
        nen: " Cúng Tế, Cầu Phúc, Họp Thân Hữu, Cắt May, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Tứ Tướng, Thời Đức, Dân Nhật, Ích Hậu, Kim Quĩ, Ô Phệ.",
        hungThan: " Hà Khôi, Tử Thần, Thiên Lại, Chí Tử.",
        nen: " Cúng Tế, Sửa Tường, Sửa Đường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Tang, Cải Táng.",
      },
      {
        catThan:
          " Nguyệt Ân, Tứ Tướng, Âm Đức, Tam Hợp, Thời Âm, Tục Thế, Bảo Quang.",
        hungThan: " Tử Khí, Huyết Kị, Bát Chuyên.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Thu Nạp Người, Di Chuyển, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Đính Hôn, Ăn Hỏi, Cưới Gả, Giải Trừ, Cắt Tóc, Mời Thầy Chữa Bệnh, Châm Cứu, Xếp Đặt Buồng Đẻ, Gieo Trồng.",
      },
      {
        catThan: " Thiên Mã, Yếu An, Giải Thần, Trừ Thần.",
        hungThan: " Kiếp Sát, Tiểu Hao, Ngũ Li, Bạch Hổ.",
        nen: " Tắm Gội, Quét Dọn, Đuổi Bắt, Đánh Cá.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Thiên Ân, Ngọc Vũ, Trừ Thần, Ngọc Đường, Ô Phệ.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tai Sát, Thiên Hỏa, Nguyệt Yếm, Địa Hỏa, Ngũ Hư, Ngũ Li, Âm Đạo Xung Dương.",
        nen: "",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan: " Nguyệt Không, Thiên Ân, Lục Hợp, Bất Tương, Kim Đường.",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Tứ Kích, Thiên Lao.",
        nen: " Đánh Cá",
        khongNen:
          " Cầu Phúc, Cầu Tự, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi.",
      },
      {
        catThan: " Thiên Ân, Mẫu Thương, Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y.",
        hungThan: " Trùng Nhật, Huyền Vũ.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Thu Nạp Người, Di Chuyển, Tắm Gội, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Cưới Gả, Nấu Rượu, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Thiên Ân, Mẫu Thương, Dương Đức, Tư Mệnh, Ô Phệ Đối.",
        hungThan:
          " Thiên Cương, Nguyệt Hình, Đại Thời, Đại Bại, Hàm Trì, Thiên Tặc, Tứ Hao.",
        nen: "",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Thiên Ân, Thời Dương, Sinh Khí, Thiên Thương, Kính An.",
        hungThan:
          " Ngũ Hư, Cửu Không, Cửu Khảm, Cửu Tiêu, Địa Nang, Bát Chuyên, Xúc Thủy Long, Câu Trần.",
        nen: " Cúng Tế, Cầu Phúc, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Chăn Nuôi, Nạp Gia Súc",
        khongNen:
          " Đội Mũ Cài Trâm, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Rèn Đúc, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan:
          " Nguyệt Đức, Vương Nhật, Ngũ Phú, Phổ Hộ, Ngũ Hợp, Thanh Long, Ô Phệ Đối.",
        hungThan: " Du Họa, Huyết Chi, Qui Kị, Bát Chuyên.",
        nen: " Cắt May, Đắp Đê, Sửa Kho, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Lấp Hang Hố, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Đi Xa Trở Về, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Quan Nhật, Lục Nghi, Phúc Sinh, Ngũ Hợp, Minh Đường, Ô Phệ Đối.",
        hungThan:
          " Nguyệt Kiến, Tiểu Hao, Thổ Phủ, Yếm Đối, Chiêu Dao, Phục Nhật, Dương Thác.",
        nen: " Cúng Tế, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Cắt May, Lập Ước Giao Dịch.",
        khongNen:
          " Cầu Phúc, Dâng Biểu Sớ, Nhận Phong Tước Vị, Đính Hôn, Ăn Hỏi, Cưới Gả, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Đỡ Nhà Phá Tường, Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Phá Thổ, An Táng, Cải Tang.",
      },
      {
        catThan: " Tứ Tướng, Thủ Nhật, Cát Kì.",
        hungThan: " Nguyệt Hại, Thiên Hình.",
        nen: " Cúng Tế, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Quét Dọn, Gieo Trồng.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Mời Thầy Chữa Bệnh, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Nguyệt Ân, Tứ Tướng, Tướng Nhật, Dịch Mã, Thiên Hậu, Thiên Vu, Phúc Đức, Thánh Tâm.",
        hungThan:
          " Ngũ Hư, Bát Phong, Thổ Phù, Đại Sát, Vãng Vong, Trùng Nhật, Chu Tước.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Đính Hôn, Ăn Hỏi, Giải Trừ, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Chăn Nuôi.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Cưới Gả, Thu Nạp Người, Di Chuyển, Cắt Tóc, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Đuổi Bắt, Săn Bắn, Đánh Cá, Đi Thuyền, Đieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Thời Đức, Dân Nhật, Ích Hậu, Kim Quĩ.",
        hungThan: " Hà Khôi, Tử Thần, Thiên Lại, Chí Tử.",
        nen: " Cúng Tế, Sửa Tường, Sửa Đường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Âm Đức, Tam Hợp, Thời Âm, Tục Thế, Bảo Quang.",
        hungThan: " Tử Khí, Huyết Kị, Bát Chuyên.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Thu Nạp Người, Di Chuyển, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Đính Hôn, Ăn Hỏi, Cưới Gả, Mời Thầy Chữa Bệnh, Châm Cứu, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Nguyệt Không, Thiên Mã, Yến An, Giải Thần, Trừ Thần, Ô Phệ.",
        hungThan: " Kiếp Sát, Tiểu Hao, Tứ Phế, Ngũ Li, Bát Chuyên, Bạch Hổ.",
        nen: " Tắm Gội, Quét Dọn, Đuổi Bắt, Đánh Cá.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Ngọc Vũ, Ngọc Đường, Trừ Thần, Ô Phệ.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tai Sát, Thiên Hỏa, Nguyệt Yếm, Địa Hỏa, Tứ Phế, Ngũ Li, Âm Thác.",
        nen: "",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Lục Hợp, Kim Đường.",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Tứ Kích, Thiên Lao.",
        nen: " Đánh Cá.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi.",
      },
      {
        catThan: " Mẫu Thương, Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y.",
        hungThan: " Trùng Nhật, Huyền Vũ.",
        nen: " Tắm Gội.",
        khongNen: " Cưới Xin, Phá Thổ, An Táng, Cải Táng},",
      },
    ],
    [
      {
        canThan:
          " Thiên Ân, Mẫu Thương, Tam Hợp, Thiên Hỉ, Thiên Y, Thiên Tương, Bất Tương, Thánh Tâm.",
        hungThan: " Tứ Kị, Bát Long, Địa Nang, Qui Kị, Thiên Lao.",
        nen: " Cúng Tế, Cầu Phúc, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Thu Nạp Người, Tắm Gội, Mời Thầy Chữa Bệnh, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Đi Xa Trở Về, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương, Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Phá Thổ, An Táng.",
      },
      {
        canThan: " Thiên Ân, Bất Tương, Ích Hậu.",
        hungThan: " Hà Khôi, Ngũ Hư, Huyền Vũ.",
        nen: " Cúng Tế, Thu Nạp Người, Nạp Tài, Đuổi Bắt, Đánh Cá, Nạp Gia Súc.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        canThan:
          " Nguyệt Không, Thiên Ân, Tứ Tướng, Dương Đức, Vương Nhật, Dịch Mã, Thiên Hậu, Thời Dương, Sinh Khí, Lục Nghi, Tục Thế, Ngũ Hợp, Tư Mệnh, Ô Phệ Đối.",
        hungThan: " Yếm Đối, Chiêu Dao, Huyết Kị.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi.",
        khongNen:
          " Cúng Tế, Cưới Gả, Châm Cứu, Chặt Cây, Săn Bắn, Đánh Cá, Đi Thuyền.",
      },
      {
        canThan:
          " Thiên Đức Hợp, Nguyệt Đức Hợp, Thiên Ân, Tứ Tướng, Quan Nhật, Yếu An, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Nguyệt Hại, Thiên Lại, Chí Tử, Huyết Chi, Câu Trần.",
        nen: "  Cúng Tế, Cắt May, Lấp Hang Hố.",
        khongNen:
          " Cắt Tóc, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Đào Giếng, Săn Bắn, Đánh Cá.",
      },
      {
        canThan: " Thiên Ân, Thủ Nhật, Ngọc Vũ, Thanh Long.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Nguyệt Hình, Ngũ Mộ, Phục Nhật, Tiểu Hội, Đơn Âm.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        canThan: " Âm Đức, Tướng Nhật, Cát Kì, Ngũ Phú, Kim Đường, Minh Đường.",
        hungThan: " Kiếp Sát, Ngũ Hư, Trùng Nhật.",
        nen: " Tắm Gội, Quét Dọn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        canThan: " Nguyệt Ân, Thời Đức, Dân Nhật, Thiên Vu, Phúc Đức, Ô Phệ.",
        hungThan: " Tai Sát, Thiên Hỏa, Đại Sát, Thiên Hình.",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        canThan: " ",
        hungThan: " Thiên Cương, Tử Thần, Nguyệt Sát, Nguyệt Hư, Chu Tước.",
        nen: "",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        canThan:
          " Thiên Đức, Nguyệt Đức, Tam Hợp, Lâm Nhật, Thời Âm, Kính An, Trừ Thần, Kim Quĩ, Ô Phệ.",
        hungThan: " Nguyệt Yếm, Địa Hỏa, Tử Khí, Vãng Vong, Ngũ Li, Cô Thần.",
        nen: " Cúng Tế, Tắm Gội, Quét Dọn.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Mời Thầy Chữa Bệnh, Khơi Mương, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng.",
      },
      {
        canThan: " Lục Hợp, Phổ Hộ, Trừ Thần, Bảo Quang, Ô Phệ.",
        hungThan:
          " Đại Thời, Đại Bại, Hàm Trì, Tiểu Hao, Ngũ Hư, Thổ Phù, Ngũ Li.",
        nen: " Cúng Tế, Cầu Phúc, Đính Hôn, Cưới Gả, Thu Nạp Người, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đan Dệt, Nấu Rượu, Quét Dọn, Đuổi Bắt, Đánh Cá, Nạp Gia Súc, An Táng.",
        khongNen:
          " Họp Thân Hữu, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Phá Thổ.",
      },
      {
        canThan: " Thiên Mã, Bất Tương, Phúc Sinh, Giải Thần.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tứ Kích, Cửu Không, Cửu Khảm, Cửu Tiêu, Bạch Hổ.",
        nen: " Cúng Tế, Giải Trừ, Tắm Gội, Mời Thầy Chữa Bệnh, Dỡ Nhà Phá Tường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        canThan: " Mẫu Thương, Bất Tương, Ngọc Đường.",
        hungThan: " Du Họa, Thiên Tặc, Tứ Cùng, Bát Long, Trùng Nhật.",
        nen: " Kê Giường, Tắm Gội, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Giải Trừ, Mời Thầy Chữa Bệnh, Sửa Kho, Mở Kho Xuất Tiền Hàng, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        canThan:
          " Nguyệt Không, Mẫu Thương, Tứ Tướng, Tam Hợp, Thiên Hỉ, Thiên Y, Thiên Thương, Bất Tương, Thánh Tâm, Ô Phệ Đối.",
        hungThan: " Qui Kị, Xúc Thủy Long, Thiên Lao.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Giải Trừ, Tắm Gội, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, Cải Táng",
        khongNen: " Di Chuyển, Đi Xa Trở Về, Đánh Cá, Đi Thuyền.",
      },
      {
        canThan:
          " Thiên Đức Hợp, Nguyệt Đức Hợp, Tứ Tướng, Bất Tương, Ích Hậu.",
        hungThan: " Hà Khôi, Ngũ Hư, Bát Phong, Huyền Vũ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đuổi Bắt, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Đội Mũ Cài Trâm, Cắt Tóc, Mời Thầy Chữa Bệnh , Săn Bắn, Đánh Cá.",
      },
      {
        canThan:
          " Thiên Xá, Dương Đức, Vương Nhật, Dịch Mã, Thiên Hậu, Thời Dương, Sinh Khí, Lục Nghi, Tục Thế, Ngũ Hợp, Tư Mệnh.",
        hungThan: " Yếm Đối, Chiêu Dao, Huyết Kị, Phục Nhật.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Cúng Tế, Châm Cứu, Chặt Cây, Săn Bắn, Đánh Cá.",
      },
      {
        canThan: "  Thiên Ân, Quan Nhật, Yếu An, Ngũ Hợp.",
        hungThan: "  Nguyệt Hại, Thiên Lại, Chí Tử, Huyết Chi, Câu Trần.",
        nen: " Lấp Hang Hố.",
        khongNen:
          " Cầu Phú, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Ddội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        canThan: " Thiên Ân,Nguyệt Ân, Thủ Nhật, Ngọc Vũ, Thanh Long.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Nguyệt Hình, Âm Vị.",
        nen: "",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        canThan:
          " Thiên Ân, Âm Đức, Tướng Nhật, Cát Kì, Ngũ Phú, Kim Đường, Minh Đường.",
        hungThan: " Kiếp Sát, Ngũ Hư, Trùng Nhật.",
        nen: " Tắm Gội, Quét Dọn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Đội Mũ Cài Trâm, Xuất Hành, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Nấu Rượu, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        canThan:
          " Thiên Đức, Nguyệt Đức, Thiên Ân, Thời Đức, Dân Nhật, Thiên Vu, Phúc Đức, Ô Phệ, ",
        hungThan: " Tai Sát, Thiên Hỏa, Địa Nang, Đại Sát, Thiên Hành.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Lợp Mái, Gác Đòn Dông, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Dỡ Nhà Phá Tường, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ.",
      },
      {
        canThan: " Thiên Ân.",
        hungThan:
          " Thiên Cương, Tử Thần, Nguyệt Sát, Nguyệt Hư, Xúc Thủy Long, Chu Tước",
        nen: "",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        canThan:
          "  Tam Hợp, Lâm Nhật, Thời Âm, Kính An, Trừ Thần, Kim Quĩ, Ô Phệ.",
        hungThan: " Nguyệt Yếm, Địa Hỏa, Tử Khí, Vãng Vong, Ngũ Li, Hành Ngận.",
        nen: " Tắm Gội, Quét Dọn. ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh,  Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        canThan:
          " Thiên Nguyện, Lục Hợp, Bất Tương, Phổ Hộ, Trừ Thần, Bảo Quang, Ô Phệ",
        hungThan:
          " Đại Thời, Đại Bại, Hàm Trì, Tiểu Hao, Ngũ Hư, Thổ Phù, Ngũ Li.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Quét Dọn, Đuổi Bắt, Đánh Cá, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Họp Thân Hữu, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Phá Thổ.",
      },
      {
        canThan:
          " Nguyệt Không, Tứ Tướng, Thiên Mã, Bất Tương, Phúc Sinh, Giải Thần.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tứ Kích, Cửu Không, Cửu Khảm, Cửu Tiêu, Bạch Hổ",
        nen: " Cúng Tế, Giải Trừ, Tắm Gội, Mời Thầy Chữa Bệnh, Dỡ Nhà Phá Tường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        canThan:
          " Thiên Đức Hợp, Nguyệt Đức Hợp, Mẫu Thương, Tứ Tương, Bất Tương, Ngọc Đường.",
        hungThan: " Du Họa, Thiên Tặc, Trùng Nhật.",
        nen: " Cúng Tế, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Di Chuyển, Kê Giường, Tắm Gội, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Nạp Tài, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Xuất Hành, Cưới Gả, Giải Trừ, Cắt Tóc, Mời Thầy Chữa Bệnh, Sửa Kho, Mở Kho Xuất Tiền Hàng, Săn Bắn, Đánh Cá.",
      },
      {
        canThan:
          " Mẫu Thương, Tam Hợp, Thiên Hỉ, Thiên Y, Thiên Thương, Thánh Tâm.",
        hungThan: " Qui Kị, Phục Nhật, Thiên Lao.",
        nen: " Cúng Tế, Cầu Phúc, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Tắm Gội, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Di Chuyển, Đi Xa Trở Về, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        canThan: " Bất Tương, Ích Hậu.",
        hungThan: " Hà Khôi, Ngũ Hư, Huyền Vũ",
        nen: " Cúng Tế, Thu Nạp Người, Nạp Tài, Đuổi Bắt, Đánh Cá, Nạp Gia Súc.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Phá Thổ, An Táng, Cải Táng.  ",
      },
      {
        canThan:
          " Nguyệt Ân, Dương Đức, Vương Nhật, Dịch Mã, Thiên Hậu, Thời Dương, Sinh Khí, Lục Nghi, Tục Thế, Ngũ Hợp, Tư Mệnh, Ô Phệ Đối.",
        hungThan: " Yếm Đối, Chiêu Dao, Huyết Kị.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi.",
        khongNen:
          " Cúng Tế, Cưới Gả, Châm Cứu, Đan Dệt, Chặt Cây, Săn Bắn, Đánh Cá, Đi Thuyền.",
      },
      {
        canThan: " Quan Nhật, Yếu An, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Nguyệt Hại, Thiên Lại, Chí Tử, Huyết Chi, Câu Trần.",
        nen: " Lấp Hang Hố.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        canThan: " Thiên Đức, Nguyệt Đức, Thổ Phủ, Thanh Long.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thủ Nhật, Ngọc Vũ, Nguyệt Hình.",
        nen: " Cúng Tế.",
        khongNen:
          " Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ.",
      },
      {
        canThan: " Âm Đức, Tướng Nhật, Cát Kì, Ngũ Phú, Kim Đường, Minh Đường.",
        hungThan: " Kiếp Sát, Ngũ Hư, Trùng Nhật.",
        nen: " Tắm Gội, Quét Dọn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        canThan: " Thời Đức, Dân Nhật, Thiên Vu, Phúc Đức, Ô Phệ",
        hungThan: " Tai Sát, Thiên Hỏa, Đại Sát, Thiên Hình",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng, Cải Táng. ",
      },
      {
        canThan: "",
        hungThan: " Thiên Cương, Tử Thần, Nguyệt Sát, Nguyệt Hư, Chu Tước.",
        nen: "",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        canThan:
          " Nguyệt Không, Tứ Tướng, Tam Hợp, Lâm Nhật, Thời Âm, Kính An, Trừ Thần, Kim Quĩ, Ô Phệ.",
        hungThan: " Nguyệt Yếm, Địa Hỏa, Tử Khí, Vãng Vong, Ngũ Li, Liễu Lệ.",
        nen: " Cúng Tế, Tắm Gội, Quét Dọn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        canThan:
          " Thiên Đức Hợp, Nguyệt Đức Hợp, Tứ Tướng, Lục Hợp, Bất Tương, Phổ Hộ, Trừ Thần, Bảo Quang, Ô Phệ.",
        hungThan:
          " Đại Thời, Đại Bại, Hàm Trì, Tiểu Hao, Ngũ Hư, Thổ Phù, Ngũ Li.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Quét Dọn, Gieo Trồng, Đuổi Bắt, Chăn Nuôi, Nạp Gia Súc, An Táng.  ",
        khongNen:
          " Họp Thân Hữu, Cắt Tóc, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ. ",
      },
      {
        canThan: " Thiên Mã, Phúc Sinh, Giải Thần.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tức Kích, Cửu Không, Cửu Khảm, Cửu Tiêu, Phục Nhật, Bạch Hổ",
        nen: " Cúng Tế, Giải Trừ, Tắm Gội, Mời Thầy Chữa Bệnh, Dỡ Nhà Phá Tường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        canThan: " Mẫu Thương, Bất Tương, Ngọc Đường",
        hungThan: " Du Họa, Thiên Tặc, Trùng Nhật.",
        nen: " Kê Giường, Tắm Gội, Nạp Tài, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc. ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Xuất Hành, Cưới Gả, Giải Trừ, Mời Thầy Chữa Bệnh, Sửa Kho, Mở Kho Xuất Tiền Hàng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        canThan:
          " Mẫu Thương, Nguyệt Ân, Tam Hợp, Thiên Hỉ, Thiên Y, Thiên Thương, Thánh Tâm, Ô Phệ Đối.",
        hungThan: " Qui Kị, Thiên Lao.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Giải Trừ, Tắm Gội, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, Cải Táng. ",
        khongNen: " Di Chuyển, Đi Xa Trở Về, Đan Dệt.",
      },
      {
        canThan: " Ích Hậu.",
        hungThan: " Hà Khôi, Ngũ Hư, Huyền Vũ.",
        nen: " Cúng Tế, Thu Nạp Người, Nạp Tài, Đuổi Bắt, Đánh Cá, Nạp Gia Súc.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        canThan:
          " Thiên Đức, Nguyệt Đức, Dương Đức, Vương Nhật, Dịch Mã, Thiên Hậu, Thời Dương, Sinh Khí, Lục Nghi, Tục Thế, Ngũ Hợp, Tư Mệnh, Ô Phệ Đối.",
        hungThan: " Yếm Đối, Chiêu Dao, Huyết Kị.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Xếp Đặt Buồng Đẻ, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Cúng Tế, Châm Cứu, Khơi Mương, Chặt Cây, Săn Bắn, Đánh Cá.",
      },
      {
        canThan: " Quan Nhật, Yếu An, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Nguyệt Hại, Thiên Lại, Chí Tử, Huyết Chi, Câu Trần.",
        nen: " Lấp Hang Hố.",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        canThan: " Thủ Nhật, Ngọc Vũ, Thanh Long.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Nguyệt Hình, Dương Thác.",
        nen: " ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cmay, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.   ",
      },
      {
        canThan: " Âm Đức, Tướng Nhật, Cát Kì, Ngũ Phú, Kim Đường, Minh Đường.",
        hungThan: " Kiếp Sát, Ngũ Hư, Trùng Nhật.",
        nen: " Tắm Gội, Quét Dọn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        canThan:
          " Nguyệt Không, Tứ Tướng, Thời Đức, Dân Nhật, Thiên Vu, Phúc Đức, Ô Phệ.",
        hungThan: " Tai Sát, Thiên Hỏa, Đại Sát, Thiên Hình.",
        nen: " Cúng Tế ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        canThan: " Thiên Đức, Nguyệt Đức Hợp, Tứ Tướng.",
        hungThan:
          " Thiên Cương, Tử Thần, Nguyệt Sát, Nguyệt Hư, Bát Chuyên, Chu Tước.",
        nen: " Cúng Tế, Sửa Đường",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        canThan: " Tam Hợp, Lâm Nhật, Thời Âm, Kính An, Trừ Thần, Kim Quĩ",
        hungThan:
          " Nguyệt Yếm, Địa Hỏa, Tử Khí, Vãng Vong, Phục Nhật, Ngũ Li, Cô Thần.",
        nen: " Tắm Gội, Quét Dọn, ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        canThan: " Thiên Ân, Lục Hợp, Bất Tương, Phổ Hộ, Trừ Thần,Ô Phệ ",
        hungThan:
          " Đại Thời, Đại Bại, Hàm Trì, Tiểu Hao, Ngũ Hư, Thổ Phù, Ngũ Li",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Đính Hôn, Cưới Gả, Thu Nạp Người, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đan Dệt, Nấu Rượu, Quét Dọn, Đuổi Bắt, Đánh Cá, Nạp Gia Súc, An Táng.  ",
        khongNen:
          " Họp Thân Hữu, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Phá Thổ.",
      },
      {
        canThan: " Thiên Ân, Nguyệt Ân, Thiên Mã, Phúc Sinh, Giải Thần. ",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tứ Kích, Cửu Không, Cửu Khảm, Cửu Tiêu, Bạch Hổ",
        nen: " Cúng Tế, Giải Trừ, Tắm Gội, Mời Thầy Chữa Bệnh, Dỡ Nhà Phá Tường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.  ",
      },
      {
        canThan: " Thiên Ân, Mẫu Thương, Ngọc Đường.",
        hungThan: " Du Họa, Thiên Tặc, Trùng Nhật.",
        nen: " Họp Thân Hữu, Kê Giường, Tắm Gội, Nạp Tài, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Xuất Hành, Cưới Gả, Giải Trừ, Mời Thầy Chữa Bệnh, Sửa Kho, Nấu Rượu, Mở Kho Xuất Tiền Hàng, Phá Thổ, An Táng, Cải Táng.   ",
      },
      {
        canThan:
          " Thiên Đức, Nguyệt Đức, Thiên Ân, Mẫu Thương, Tam Hợp, Thiên Hỉ, Thiên Y, Thiên Thương, Thánh Tâm, Ô Phệ Đối.",
        hungThan: " Tứ Hao, Qui Kị, Thiên Lao.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Giải Trừ, Tắm Gội, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
        khongNen: " Di Chuyển, Đi Xa Trở Về, Khơi Mương, Săn Bắn, Đánh Cá.",
      },
      {
        canThan: " Thiên Ân, Ích Hậu.",
        hungThan: " Hà Khôi, Ngũ Hư, Bát Chuyên, Xúc Thủy Long, Huyền Vũ.",
        nen: " Cúng Tế, Thu Nạp Người, Nạp Tài, Đuổi Bắt, Nạp Gia Súc.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đánh Cá, Đi Thuyền, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        canThan:
          " Dương Đức, Vương Nhật, Dịch Mã, Thiên Hậu, Thời Dương, Sinh Khí, Lục Nghi, Tục Thế, Ngũ Hợp, Tư Mệnh, Ô Phệ Đối.",
        hungThan: " Yếm Đối, Chiêu Dao, Huyết Kị, Bát Chuyên.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Xếp Đặt Buồng Đẻ, Khơi Mương, Đào Giếng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi.",
        khongNen:
          " Cúng Tế, Đính Hôn, Ăn Hỏi, Cưới Gả, Châm Cứu, Mở Kho Xuất Tiền Hàng, Chặt Cây, Săn Bắn, Đánh Cá, Đi Thuyền.",
      },
      {
        canThan: " Quan Nhật, Yếu An, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Nguyệt Hại, Thiên Lại, Chí Tử, Huyết Chi, Câu Trần.",
        nen: " Lấp Hang Hố.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.   ",
      },
      {
        canThan: " Nguyệt Không, Tứ Tướng, Thủ Nhật, Ngọc Vũ, Thanh Long",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phù, Nguyệt Hình.",
        nen: " Cúng Tế",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        canThan:
          " Thiên Đức Hợp, Nguyệt Đức Hợp, Tứ Tướng, Âm Đức, Tướng Nhật, Cát Kì, Ngũ Phú, Kim Đường, Minh Đường",
        hungThan: " Kiếp Sát, Ngũ Hư, Bát Phong, Trùng Nhật.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Tắm Gội, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc. ",
        khongNen:
          " Xuất Hành, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Săn Bắn, Đánh Cá.",
      },
      {
        canThan: " Thời Đức, Dân Nhật, Thiên Vu, Phúc Đức.",
        hungThan: " Tai Sát, Thiên Hỏa, Đại Sát, Phục Nhật, Thiên Hình.",
        nen: " Cúng Tế, ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        canThan: "",
        hungThan:
          " Thiên Cương, Tử Thần, Nguyệt Sát, Nguyệt Hư, Bát Chuyên, Chu Tước.",
        nen: "",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        canThan:
          " Nguyệt Ân, Tam Hợp, Lâm Nhật, Thời Âm, Kính An, Trừ Thần, Kim Quĩ, Ô Phệ.",
        hungThan:
          " Nguyệt Yếm, Địa Hỏa, Tử Khí, Tứ Phế, Vãng Vong, Ngũ Li, Bát Chuyên, Cô Thần, Âm Thác.",
        nen: " Cúng Tế, Tắm Gội, Quét Dọn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        canThan: " Lục Hợp, Phổ Hộ, Trừ Thần, Bảo Quang, Ô Phệ.",
        hungThan:
          " Đại Thời, Đại Bại, Hàm Trì, Tiểu Hao, Tứ Phế, Ngũ Hư, Thổ Phù, Ngũ Li",
        nen: " Cúng Tế, Tắm Gội, Cắt Tóc, Sửa Móng, Đuổi Bắt",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        canThan: " Thiên Đức, Nguyệt Đức, Thiên Mã, Phúc Sinh, Giải Thần.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tứ Kích, Cửu Không, Cửu Khảm, Cửu Tiêu, Bạch Hổ",
        nen: " Cúng Tế, Giải Trừ, Tắm Gội, Mời Thầy Chữa Bệnh, Dỡ Nhà Phá Tường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Chặt Cây, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.   ",
      },
      {
        canThan: " Mẫu Thương, Ngọc Đường",
        hungThan: " Du Họa, Thiên Tặc, Trùng Nhật.",
        nen: " Tắm Gội.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Xuất Hành, Cưới Gả, Giải Trừ, Mời Thầy Chữa Bệnh, Sửa Kho, Mở Kho Xuất Tiền Hàng, Phá Thổ, An Táng, Cải Táng.",
      },
    ],
    [
      {
        catThan: " Nguyệt Không, Thiên Ân, Thiên Mã, Bất Tương",
        hungThan: " Thiên Lại, Chí Tử, Ngũ Hư, Bạch Hổ",
        nen: " Họp Thân Hữu, Tắm Gội.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ,  Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Thiên Ân, Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y, Lục Nghi, Ngọc Đường.",
        hungThan: " Yếm Đối, Chiêu Dao, Tứ Kích, Quy Kị",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Xưới Gả, Thu  Nạp Người, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Đội Mũ Cài Trâm, Di Chuyển, Đi Xa Trở Về, Săn Bắn, Đánh Cá, Gieo Trồng.",
      },
      {
        catThan:
          " Thiên Đức Hợp, Thiên Ân, Mẫu Thương, Kính An, Ngũ Hợp, Ô Phệ Đối.",
        hungThan:
          " Thiên Cương, Kiếp Sát, Nguyệt Hại, Thổ Phù, Phục Nhật, Thiên Lao.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Lập Ước Giao Dịch, Nạp Tài, Đuổi Bắt, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Cúng Tế, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan:
          " Thiên Ân, Mẫu Thương, Âm Đức, Thời Dương, Sinh Khí, Phổ Hộ, Ngũ Hợp, Ô Phệ Đối .",
        hungThan: " Tai Sát, Thiên Hỏa, Huyền Vũ",
        nen: " Cúng Tế, Nhập Học.",
        khongNen:
          " Cắt Tóc, Mời Thầy Chữa Bệnh, Đan Dệt, Nấu Rượu, Đào Giếng, Chặt Cây, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Thiên Ân, Tứ Tướng, Thời Đức, Dương Đức, Phúc Sinh, Tư Mệnh.",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Huyết Chi, Ngũ Hư, Tuyệt Âm.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Nguyệt Ân, Tứ Tướng, Vương Nhật.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phù, Trùng Nhật, Câu Trần, Tiểu Hội Thuần Dương, Dương Thác.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Nguyệt Đức, Quan Nhật, Cát Kì, Thánh Tâm, Thanh Long, Ô Phệ",
        hungThan: " Đại Thời, Đại Bại, Hàm Trì.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.  ",
        khongNen: " Lợp Mái, Gác Đòn Dông, Đan Dệt, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Thiên Đức, Thủ Nhật, Thiên Vu, Phúc Đức, Ích Hậu, Minh Đường.",
        hungThan:
          " Nguyệt Yếm, Địa Hỏa, Cửu Không, Cửu Khảm, Cửu Tiêu, Đại Sát, Cô Thần.",
        nen: " Cúng Tế",
        khongNen:
          " Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Đi Xa Trở Về, Mời Thầy Chữa Bệnh, Rèn Đúc, Nấu Rượu, Lấp Hang Hố, Chặt Cây, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng.",
      },
      {
        catThan: " Tướng Nhật, Lục Hợp, Ngũ Phú, Tục Thế, Trừ Thần, Ô Phệ",
        hungThan:
          " Hà Khôi, Tử Thần, Nguyệt Hình, Du Họa, Ngũ Hư, Huyết Kị, Ngũ Li, Thiên Hình.",
        nen: " Cúng Tế, Tắm Gội, Quét Dọn, Sửa Đường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Dỡ Nhà Phá Tường.",
      },
      {
        catThan: " Dân Nhật, Tam Hợp, Thời Âm, Yếu An, Trừ Thần, Ô Phệ.",
        hungThan: " Tử Khí, Ngũ Li, Chu Tước.",
        nen: " Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Quét Dọn, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng. ",
        khongNen:
          " Họp Thân Hữu, Giải Trừ, Mời Thầy Chữa Bệnh, Xếp Đặt Buồng Đẻ, Gieo Trồng.",
      },
      {
        catThan: "  Nguyệt Không, Bất Tương, Ngọc Vũ, Giải Thần, Kim Quĩ.",
        hungThan: " Tiểu Hao, Thiên Tặc.",
        nen: " Dâng Biểu Sớ, Cưới Gả, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đuổi Bắt.",
        khongNen:
          " Xuất Hành, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng.",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Dịch Mã, Thiên Hậu, Thiên Thương, Bất Tương, Kim Đường, Bảo Quang.",
        hungThan: " Nguyệt Phá, Đại Hao, Vãng Vong, Trùng Nhật.",
        nen: " Cúng Tế, Giải Trừ, Tắm Gội, Dỡ Nhà Phá Tường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.   ",
      },
      {
        catThan: " Thiên Đức Hợp, Thiên Mã, Bất Tương, Ô Phệ Đối,",
        hungThan:
          " Thiên Lại, Chí Tử, Tứ Kị, Thất Điểu, Ngũ Hư, Phục Nhật, Xúc Thủy Long, Bạch Hổ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Di Chuyển, Kê Giường, Giải Trừ, Tắm Gội, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, ",
        khongNen:
          "  Đính Hôn, Ăn Hỏi, Cưới Gả, Mời Thầy Chữa Bệnh, Săn Bắn, Đánh Cá, Đi Thuyền, An Táng.",
      },
      {
        catThan: "Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y, Lục Nghi, Ngọc Đường",
        hungThan: "Yếm Đối, Chiêu Dao, Tứ Kích, Qui Kị.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học,Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Thu  Nạp Người,  Mời Thầy Chữa Bệnh,Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá,Nạp Gia Súc.",
        khongNen:
          "  Đội Mũ Cài Trâm, Cưới Gả, Di Chuyển, Đi Xa Trở Về, Cắt Tóc , Đánh Cá, Đi Thuyền.",
      },
      {
        catThan: " Mẫu Thương, Tứ Tướng, Kính An, Ngũ Hợp.",
        hungThan: " Thiên Cương, Kiếp Sát, Nguyệt Hại, Thổ Phù, Thiên Lao.",
        nen: "Đuổi Bắt.",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người,  Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường,  Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          "  Thiên Ân, Mẫu Thương, Nguyệt Ân, Tứ Tướng, Âm Đức, Thời Dương, Sinh Khí, Phổ Hộ, Ngũ Hợp.",
        hungThan: "  Tai Sát, Thiên Hỏa, Địa Nang, Huyền Vũ.",
        nen: " Cúng Tế, Nhập Học.",
        khongNen:
          "  Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ,Sửa Kho, Xếp Đặt Buồng Đẻ,Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Đức, Thiên Ân, Thời Đức, Dương Đức, Phúc Sinh, Tư Mệnh.",
        hungThan: "  Nguyệt Sát, Nguyệt Hư, Huyết Chi, Ngũ Hư.",
        nen: " Cúng Tế.",
        khongNen:
          "  Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người,  Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Săn Bắn, Đánh Cá, Gieo Trồng, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Đức, Thiên Ân, Vương Nhật.",
        hungThan: "  Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Trùng Nhật, Câu Trần.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          "  Xuất Hành, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Nấu Rượu, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây,  Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan: " Thiên Ân, Quan Nhật, Cát Kì, Thánh Tâm, Thanh Long, Ô Phệ.",
        hungThan: " Đại Thời, Đại Bại, Hàm Trì",
        nen: " Cúng Tế, Cầu Phúc, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Quét Dọn, Phá Thổ, An Táng.",
        khongNen: "  Lợp Mái, Gác Đòn Dông, Khơi Mương.",
      },
      {
        catThan:
          " Thiên Ân, Thiên Nhật, Thiên Vu, Phúc Đức, Ích Hậu, Minh Đường.",
        hungThan:
          " Nguyệt Yếm, Địa Hỏa, Cửu Không, Cửu Khảm, Cửu Tiêu, Đại Sát, Xúc Thủy Long, Cô Thần.",
        nen: " Cúng Tế ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng, Cải Táng. ",
      },
      {
        catThan:
          "  Nguyệt Không, Tướng Nhật, Lục Hợp, Ngũ Phú, Bất Tương, Tục Thế, Trừ Thần, Ô Phệ.",
        hungThan:
          " Hà Khôi, Tử Thần, Nguyệt Hình, Du Họa, Ngũ Hư, Bát Phong, Huyết Kị, Ngũ Li, Thiên Hình",
        nen: " Cúng Tế, Tắm Gội, Quét Dọn, Sửa Đường ",
        khongNen:
          "  Cầu Phúc, Cầu Tự, Xuất Hành, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Mở Kho Xuất Tiền Hàng , Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Dỡ Nhà Phá Tường,  Đánh Cá, Đi Thuyền.",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Dân Nhật, Tam Hợp, Thời Âm, Bất Tương, Yếu An, Trừ Thần, Ô Phệ.",
        hungThan: " Tử Khí, Ngũ Li, Câu Trần",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Quét Dọn, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng. ",
        khongNen:
          "  Họp Thân Hữu, Mời Thầy Chữa Bệnh, Săn Bắn, Đánh Cá, Gieo Trồng.",
      },
      {
        catThan: " Thiên Đức Hợp, Bất Tương, Giải Thần, Kim Quĩ.",
        hungThan: " Tiểu Hao, Thiên Tặc, Ngũ Mộ, Phục Nhật.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Đuổi Bắt.",
        khongNen:
          "  Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Săn Bắn, Đánh Cá,  Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.  ",
      },
      {
        catThan:
          " Dịch Mã, Thiên Hậu, Thiên Thương, Bất Tương, Kim Đường, Bảo Quang.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tứ Cùng, Thất Điểu , Vãng Vong, Trùng Nhật.",
        nen: " Tắm Gội, Dỡ Nhà Phá Tường.",
        khongNen:
          "  Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người,  Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.  ",
      },
      {
        catThan: " Tứ Tướng, Thiên Mã, Bát Tương.",
        hungThan: " Thiên Lại, Chí Tử, Ngũ Hư, Bạch Hổ.",
        nen: " Cúng Tế, Họp Thân Hữu, Cắt May.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
      },
      {
        catThan:
          " Nguyệt Ân, Tứ Tướng, Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y, Lục Nghi, Ngọc Đường.",
        hungThan: " Yếm Đối, Chiêu Dao, Tứ Kích, Qui Kị.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc",
        khongNen:
          " Đội Mũ Cài Trâm, Cưới Gả, Di Chuyển, Đi Xa Trở Về, Đánh Cá, Đi Thuyền.",
      },
      {
        catThan: " Nguyệt Đức, Mẫu Thương, Kính An, Ngũ Hợp, Ô Phệ Đối",
        hungThan: " Thiên Cương, Kiếp Sát, Nguyệt Hại, Thổ Phù, Thiên Lao.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người,  Di Chuyển, Giải Trừ, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Lập Ước Giao Dịch, Nạp Tài, Đuổi Bắt, Chăn Nuôi, Nạp Gia Súc, An Táng, Cải Táng.",
        khongNen:
          " Cúng Tế, Cầu Tự, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Đan Dệt, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường,  Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan:
          " Thiên Đức, Mẫu Thương, Âm Đức, Thời Dương, Sinh Khí, Phổ Hộ, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Tai Sát, Thiên Hỏa, Huyền Vũ",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài,  Xếp Đặt Buồng Đẻ, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Mời Thầy Chữa Bệnh, Nấu Rượu, Đào Giếng, Chặt Cây, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Thời Đức, Dương Đức, Phúc Sinh, Tư Mệnh.",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Huyết Chi, Ngũ Hư",
        nen: "",
        khongNen: "  Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Vương Nhật.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Trùng Nhật, Câu Trần",
        nen: "  Họp Thân Hữu, Lên Quan Nhậm Chức, Gặp Dân, Cắt May.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Đính Hôn, Ăn Hỏi, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Dỡ Nhà Phá Tường,  Gieo Trồng, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Không, Thiên Xá, Quan Nhật, Cát Kì, Thánh Tâm, Thanh Long, Ô Phệ.",
        hungThan: " Đại Thời, Đại Bại, Hàm Trì",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng.      ",
        khongNen:
          " Lợp Mái, Gác Đòn Dông, Mở Kho Xuất Tiền Hàng, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Thủ Nhật, Thu Vu, Phúc Đức, Ích Hậu, Minh Đường.",
        hungThan:
          " Nguyệt Yếm, Địa Hỏa, Cửu Không, Cửu Khảm, Cửu Tiêu, Đại Sát, Hành Ngận",
        nen: " Cúng Tế",
        khongNen:
          " Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Đi Xa Trở Về, Mời Thầy Chữa Bệnh, Rèn Đúc, Lấp Hang Hố, Chặt Cây, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng.",
      },
      {
        catThan:
          " Thiên Đức Hợp, Thiên Nguyện, Tướng Nhật, Lục Hợp, Ngũ Phú, Bất Tương, Tục Thế, Trừ Thần, Ô Phệ.",
        hungThan:
          " Hà Khôi, Tử Thần, Nguyệt Hình, Du Họa, Ngũ Hư, Huyết Kị, Phục Nhật, Ngũ Li, Thiên Hình.",
        nen: " Cúng Tế, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người,  Di Chuyển, Tắm Gội, Cắt Tóc, Sửa Móng, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Quét Dọn, Sửa Tường, Sửa Đường, Gieo Trồng, Nạp Gia Súc,  ",
        khongNen: " ",
      },
      {
        catThan:
          " Dân Nhật, Tam Hợp, Thời Âm, Bất Tương, Yếu An, Trừ Thần, Ô Phệ.",
        hungThan: " Tử Khí, Ngũ Li, Chu Tước.",
        nen: " Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Tắm Gội, Sửa Móng, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt,Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Quét Dọn, Chăn Nuôi, Nạp Gia Súc. Phá Thổ, An Táng.",
        khongNen:
          " Họp Thân Hữu, Giải Trừ, Cắt Tóc, Mời Thầy Chữa Bệnh, Xếp Đặt Buồng Đẻ, Gieo Trồng.",
      },
      {
        catThan: " Tứ Tướng, Bất Tương, Ngọc Vũ, Giải Thần, Kim Quĩ",
        hungThan: " Tiểu Hao, Thiên Tặc.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Họp Thân Hữu, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đuổi Bắt, Gieo Trồng, Chăn Nuôi. ",
        khongNen:
          "  Xuất Hành, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng.",
      },
      {
        catThan:
          " Nguyệt Ân, Tứ Tướng, Dịch Mã, Thiên Hậu, Thiên Thương, Kim Đường, Bảo Quang.",
        hungThan: " Nguyệt Phá, Đại Hao, Vãng Vong, Trùng Nhật.",
        nen: " Cúng Tế, Giải Trừ, Tắm Gội, Dỡ Nhà Phá Tường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người,  Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Nguyệt Đức, Thiên Mã, Ô Phệ Đối.",
        hungThan: " Thiên Lại, Chí Tử, Ngũ Hư, Bạch Hổ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Tắm Gội, Cắt May,Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
        khongNen: " Mời Thầy Chữa Bệnh, Đan Dệt, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Thiên Đức, Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y, Lục Nghi, Ngọc Đường.",
        hungThan: " Yếm Đối, Chiêu Dao, Tứ Kích, Qui Kị.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng. ",
        khongNen:
          " Đội Mũ Cài Tram, Di Chuyển, Đi Xa Trở Về, Nấu Rượu, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Mẫu Thương, Kính An, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Thiên Cương, Kiếp Sát, Nguyệt Hại, Thổ Phù, Thiên Lao.",
        nen: " Đuổi Bắt.",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người,  Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường,  Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Mẫu Thương, Âm Đức, Thời Dương, Sinh Khí, Phổ Hộ, Ngũ Hợp, Ô Phệ Đối",
        hungThan: " Tai Sát, Thiên Hỏa, Huyền Vũ.",
        nen: " Cúng Tế, Nhập Học.",
        khongNen:
          " Mời Thầy Chữa Bệnh, Đan Dệt, Nấu Rượu, Đào Giếng, Chặt Cây, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Nguyệt Không, Thời Đức, Dương Đức, Phúc Sinh, Tư Mệnh",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Huyết Chi, Ngũ Hư, Bát Phong.",
        nen: "",
        khongNen: "  Mọi Việc Không Nên Làm",
      },
      {
        catThan: " Nguyệt Đức Hợp, Vương Nhật.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Trùng Nhật, Câu Trần.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Mời Thầy Chữa Bệnh, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Chăn Nuôi, Nạp Gia Súc.  ",
        khongNen:
          " Xuất Hành, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan:
          " Thiên Đức Hợp, Quan Nhật, Cát Kì, Thánh Tâm, Thanh Long, Ô Phệ.",
        hungThan: " Đại Thời, Đại Bại, Hàm Trì, Phục Nhật, Tuế Bạc, ",
        nen: " Cúng Tế, Tắm Gội, Quét Dọn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Lợp Mái, Gác Đòn Dông, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thủ Nhật, Thiên Vu, Phúc Đức, Ích Hậu, Minh Đường.",
        hungThan:
          " Nguyệt Yếm, Địa Hỏa, Cửu Không, Cửu Khảm, Cửu Tiêu, Đại Sát, Bát Chuyên, Liễu Lệ, Âm Thác.",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường,  Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Tứ Tướng, Tướng Nhật, Lục Hợp, Ngũ Phú, Bất Tương, Tục Thế, Trừ Thần.",
        hungThan:
          " Hà Khôi, Tử Thần, Nguyệt Hình, Du Họa, Ngũ Hư, Huyết Kị, Ngũ Li, Thiên Hình.",
        nen: " Cúng Tế, Tắm Gội, Quét Dọn, Sửa Đường ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Kê Giường, Mời Thầy Chữa Bệnh, Châm Cứu.",
      },
      {
        catThan:
          " Thiên Ân, Nguyệt Ân, Tứ Tướng, Dân Nhật, Tam Hợp, Thời Âm, Yếu An, Trừ Thần, Ô Phệ.",
        hungThan: " Tử Khí, Địa Nang, Ngũ Li, Câu Trần.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người,  Di Chuyển, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Quét Dọn, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Họp Thân Hữu, Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá,Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan: " Nguyệt Đức, Thiên Ân, Ngọc Vũ, Giải Thần, Kim Quĩ.",
        hungThan: " Tiểu Hao, Thiên Tặc.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đuổi Bắt, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Xuất Hành, Sửa Kho, Đan Dệt, Mở Kho Xuất Tiền Hàng, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Thiên Đức, Thiên Ân, Dịch Mã, Thiên Hậu, Thiên Thương, Kim Đường, Bảo Quang.",
        hungThan: " Nguyệt Phá, Đại Hao, Vãng Vong, Trùng Nhật.",
        nen: " Cúng Tế, Giải Trừ, Tắm Gội, Dỡ Nhà Phá Tường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người,  Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Ân, Thiên Mã, Ô Phệ Đối",
        hungThan: " Thiên Lại, Chí Tử, Tứ Phế, Ngũ Hư, Bạch Hổ, ",
        nen: " Tắm Gội",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người,  Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Thiên Ân, Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y, Lục Nghi, Ngọc Đường",
        hungThan:
          " Yếm Đối, Chiêu Dao, Tử Kích, Qui Kị, Bát Chuyên, Xúc Thủy Long.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Thu Nạp Người, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Nạp Gia Súc.",
        khongNen:
          " Đội Mũ Cài Trâm, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Đi Xa Trở Về, Đánh Cá, Đi Thuyền.",
      },
      {
        catThan: " Nguyệt Không, Mẫu Thương, Kính An, Ngũ Hợp, Ô Phệ Đối.",
        hungThan:
          " Thiên Cương, Kiếp Sát, Nguyệt Hại, Thổ Phù, Bát Chuyên, Thiên Lao.",
        nen: " Đuổi Bắt.",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Mẫu Thương, Âm Đức, Thời Dương, Sinh Khí, Phổ Hộ, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Tai Sát, Thiên Hỏa, Tứ Hao, Huyền Vũ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Xếp Đặt Buồng Đẻ, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Mời Thầy Chữa Bệnh, Đào Giếng, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng.",
      },
      {
        catThan: " Thiên Đức Hợp, Thời Đức, Dương Đức, Phúc Sinh, Tư Mệnh.",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Huyết Kị, Ngũ Hư, Phục Nhật.",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường,Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Vương Nhật.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Trùng Nhật, Câu Trần, Dương Thác.",
        nen: " Họp Thân Hữu, Lên Quan Nhậm Chức, Gặp Dân, Cắt May.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Đính Hôn, Ăn Hỏi, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Gieo Trồng, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Tứ Tướng, Quan Nhật, Cát Kì, Thánh Tâm, Thanh Long.",
        hungThan: " Đại Thời, Đại Bại, Hàm Trì, Tuế Bạc.",
        nen: " Cúng Tế, Tắm Gội, Quét Dọn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Lợp Mái, Gác Đòn Dông, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
      },
      {
        catThan:
          " Nguyệt Ân, Tứ Tướng, Thủ Nhật, Thiên Vu, Phúc Đức, Ích Hậu, Minh Đường.",
        hungThan:
          " Nguyệt Yếm, Địa Hỏa, Cửu Không, Cửu Khảm, Cửu Tiêu, Đại Sát, Bát Chuyên, Cô Thần, Âm Thác.",
        nen: " Cúng Tế ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Đức, Tướng Nhật, Lục Hợp, Ngũ Phú, Tục Thế, Trừ Thần, Ô Phệ.",
        hungThan:
          " Hà Khôi, Tử Thần, Nguyệt Hình, Du Họa, Ngũ Hư, Huyết Kị, Ngũ Li, Bát Chuyên, Thiên Hình.",
        nen: " Cúng Tế, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Thu Nạp Người, Di Chuyển, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Quét Dọn, Sửa Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Đính Hôn, Ăn Hỏi, Cưới Gả, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Châm Cứu, Đan Dệt, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Thiên Đức, Dân Nhật, Tam Hợp, Thời Âm, Yếu An, Trừ Thần, Ô Phệ.",
        hungThan: " Tử Khí, Ngũ Li, Chu Tước",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng. ",
        khongNen:
          " Họp Thân Hữu, Mời Thầy Chưa Bệnh, Nấu Rượu, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Ngọc Vũ, Giải Thần, Kim Quĩ",
        hungThan: " Tiêu Hao, Thiên Tặc.",
        nen: " Dâng Biểu Sớ, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đuổi Bắt.",
        khongNen:
          " Xuất Hành, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Khơi Mương.",
      },
      {
        catThan: " Dịch Mã, Thiên Hậu, Thiên Thương, Kim Đường, Bảo Quang.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tứ Phế, Vãng Vong, Trùng Nhật, Âm Dương Giao Phá",
        nen: "",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
    ],
    [
      {
        catThan: " Thiên Ân, Lục Nghi, Giải Thần, Kim Quĩ",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tai Sát, Thiên Hỏa, Yếm Đối, Chiêu Dao, Ngũ Hư",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Thiên Ân, Âm Đức, Thánh Tâm, Bảo Quang",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Nguyệt Hại, Tứ Kích",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Đức, Thiên Ân, Mẫu Thương, Tam Hợp, Thiên Mã, Thiên Hỉ, Thiên Y, Ích Hậu, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Đại Sát, Qui Kị, Bạch Hổ.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
        khongNen: " Cúng Tế, Di Chuyển, Đi Xa Trở Về, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Thiên Ân, Mẫu Thương, Tục Tế, Ngũ Hợp, Ngọc Đường, Ô Phệ Đối.",
        hungThan:
          " Hà Khôi, Đại Thời, Đại Bại, Hàm Trì, Cửu Khảm, Cửu Tiêu, Huyết Kị, Vãng Vong, Phục Nhật.",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Mời Thầy Chữa Bệnh, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Đuổi Bắt, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Thiên Ân, Nguyệt Ân, Tứ Tướng, Thời Đức, Thời Dương, Sinh Khí, Yếu An.",
        hungThan: " Ngũ Hư, Cửu Không, Thiên Lao.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi.",
        khongNen:
          " Thu Nạp Người, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Chặt Cây, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Tứ Tướng, Vương Nhật, Ngọc Vũ.",
        hungThan: " Du Họa, Huyết Chi, Trùng Nhật, Huyền Vũ.",
        nen: " Cúng Tế, Cắt May, Sửa Đê, Nạp Tài, Lấp Hang Hố, Gieo Trồng, Chăn Nuôi.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Khai Trương, Nhập Trạch, Mở Kho Xuất Tiền Hàng, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Dương Đức, Quan Nhật, Kim Đường, Tư Mệnh, Ô Phệ.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Nguyệt Hình, Nguyệt Yếm, Địa Hỏa, Thổ Phù.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Nguyệt Đức Hợp, Thủ Nhật, Cát Kì, Lục Hợp ",
        hungThan: " Câu Trần.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người,  Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Lập Ước Giao Dịch, Nạp Tài, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen: " Mời Thầy Chữa Bệnh, Rèn Đúc, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Nguyệt Không, Tướng Nhật, Dịch Mã, Thiên Hậu, Thiên Vu, Phúc Đức, Trừ Thần, Thanh Long, Ô Phệ.",
        hungThan: " Ngũ Hư, Ngũ Li.",
        nen: " Cúng Tế, Cầu Phúc, Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Đắp Đê, Đan Dệt, Khai Trương, Nhập Trạch, Nạp Tài, Lấp Hang Hố, Quét Dọn, Phá Thổ, An Táng.",
        khongNen:
          " Họp Thân Hữu, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Kê Giường, Mời Thầy Chữa Bệnh, Sửa Kho, Lập Ước Giao Dịch, Mở Kho Xuất Tiền Hàng, Khơi Mương.",
      },
      {
        catThan: " Dân Nhật, Bất Tương, Kính An, Trừ Thần, Minh Đường, Ô Phệ.",
        hungThan:
          " Thiên Cương, Tử Thần, Thiên Lại, Chí Tử, Thiên Tặc, Ngũ Li.",
        nen: " Tắm Gội, Cắt Tóc, Sửa Mong, Sửa Tường, Sửa Đường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Tam Hợp, Lâm Nhật, Thời Âm, Thiên Thương, Bất Tương, Phổ Hộ.",
        hungThan: " Tử Khí, Thiên Hình.",
        nen: " Cúng Tế, Cầu Phúc, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Nạp Gia Súc.",
        khongNen:
          " Giải Trừ, Mời Thầy Chữa Bệnh, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Gieo Trồng.",
      },
      {
        catThan: " Ngũ Phú, Bất Tương, Phúc Sinh.",
        hungThan: " Kiếp Sát, Tiểu Hao, Trùng Nhật, Chu Tước.",
        nen: " Cúng Tế, Tắm Gội, Đuổi Bắt.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người,  Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Nguyệt Đức, Lục Nghi, Giải Thần, Kim Quĩ, Ô Phệ Đối.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tai Sát, Thiên Hỏa, Yếm Đối, Chiêu Dao, Tứ Kị, Thất Mã, Ngũ Hư, Xúc Thủy Long.",
        nen: " Cúng Tế, Tắm Gội.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường,  Chặt Cây, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Âm Đức, Thánh Tâm, Bảo Quang.",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Nguyệt Hại, Tứ Kích, Phục Nhật.",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Mẫu Thương, Nguyệt Ân, Tứ Tướng, Tam Hợp, Thiên Mã, Thiên Hỷ, Thiên Y, Ích Hậu, Ngũ Hợp.",
        hungThan: " Đại Sát, Qui Kị, Bạch Hổ.",
        nen: " Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Cúng Tế, Di Chuyển, Đi Xa Trở Về.",
      },
      {
        catThan:
          " Thiên Ân, Mẫu Thương, Tứ Tướng, Tục Thế, Ngũ Hợp, Ngọc Đường.",
        hungThan:
          " Hà Khôi, Đại Thời, Đại Bại, Hàm Trì, Cửu Khảm, Cửu Tiêu, Huyết Kị, Vãng Vong.",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Đuổi Bắt, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Ân, Thời Đức, Thời Dương, Sinh Khí, Yếu An.",
        hungThan: " Ngũ Hư, Cửu Không, Thiên Lao.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi.",
        khongNen:
          " Thu Nạp Người, Sửa Kho, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Chặt Cây, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Nguyệt Đức Hợp, Thiên Ân, Vương Nhật, Ngọc Vũ.",
        hungThan: " Du Họa, Huyết Chi, Trùng Nhật, Huyền Vũ.",
        nen: " Cúng Tế, Cắt May, Đắp Đê, Sửa Kho, Lấp Hang Hố, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Xuất Hành, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Rèn Đúc, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Nguyệt Không, Thiên Ân, Dương Đức, Quan Nhật, Kim Đường, Tư Mệnh, Ô Phệ.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Nguyệt Hình, Nguyệt Yếm, Địa Hỏa, Thổ Phù.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan: " Thiên Ân, Thủ Nhật, Cát Kì, Lục Hợp, Bất Tương, ",
        hungThan: " Xúc Thủy Long, Câu Trần.",
        nen: " Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Quét Dọn, Nạp Gia Súc, An Táng.",
        khongNen: " Mời Thầy Chữa Bệnh, Đánh Cá, Đi Thuyền.",
      },
      {
        catThan:
          " Tướng Nhật, Dịch Mã, Thiên Hậu, Thiên Vu, Phúc Đức, Bất Tương, Trừ Thần, Thanh Long, Ô Phệ.",
        hungThan: " Ngũ Hư, Bát Phong, Ngũ Li.",
        nen: " Cúng Tế, Cầu Phúc, Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Đan Dệt, Khai Trương, Nhập Trạch, Nạp Tài, Lấp Hang Hố, Quét Dọn, Phá Thổ, An Táng. ",
        khongNen:
          " Họp Thân Hữu, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Kê Giường, Mời Thầy Chữa Bệnh, Sửa Kho, Lập Ước Giao Dịch, Mở Kho Xuất Tiền Hàng, Đánh Cá, Đi Thuyền.",
      },
      {
        catThan: " Dân Nhật, Bất Tương, Kính An, Trừ Thần, Minh Đường, Ô Phệ.",
        hungThan:
          " Thiên Cương, Tử Thần, Thiên Lại, Chí Tử, Thiên Tặc, Ngũ Li.",
        nen: " Tắm Gội, Cắt Tóc, Sửa Móng, Quét Dọn, Sửa Tường, Sửa Đường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Đức, Tam Hợp, Lâm Nhật, Thời Âm, Thiên Thương, Bất Tương, Phổ Hộ",
        hungThan: " Tử Khí, Ngũ Mộ, Thiên Hình.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng. ",
        khongNen: " Mời Thầy Chữa Bệnh, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Ngũ Phú, Phúc Sinh.",
        hungThan:
          " Kiếp Sát, Tiểu Hao, Tứ Cùng, Thất Mã, Phục Nhật, Trùng Nhật, Chu Tước.",
        nen: " Cúng Tế, Tắm Gội, Đuổi Bắt.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Nguyệt Ân, Tứ Tướng, Lục Nghi, Giải Thần, Kim Quĩ.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tai Sát, Thiên Hỏa, Yếm Đối, Chiêu Dao, Ngũ Hư.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Tứ Tướng, Âm Đức, Thánh Tâm, Bảo Quang.",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Tứ Kích, Nguyệt Hại.",
        nen: " Cúng Tế ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Mẫu Thương, Tam Hợp, Thiên Mã, Thiên Hỉ, Thiên Y, Ích Hậu, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Đại Sát, Qui Kị, Bạch Hổ.",
        nen: " Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, Cải Táng. ",
        khongNen: " Cúng Tế, Di Chuyển, Đi Xa Trở Về, Đan Dệt.",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Mẫu Thương, Tục Thế, Ngũ Hợp, Ngọc Đường, Ô Phệ Đối.",
        hungThan:
          " Hà Khôi, Đại Thời, Đại Bại, Hàm Trì, Cửu Khảm, Cửu Tiêu, Huyết Kị, Vãng Vong.",
        nen: " Cúng Tế.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Châm Cứu, Rèn Đúc, Nấu Rượu, Đào Giếng, Lấp Hang Hố, Đuổi Bắt, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng. ",
      },
      {
        catThan: " Nguyệt Không, Thời Đức, Thời Dương, Sinh Khí, Yếu An",
        hungThan: " Ngũ Hư, Cửu Không, Thiên Lao.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Xếp Đặt Buồng Đẻ, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi.",
        khongNen:
          " Thu Nạp Người, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Khơi Mương, Chặt Cây, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Vương Nhật, Ngọc Vũ.",
        hungThan: " Du Họa, Huyết Chi, Trùng Nhật, Huyền Vũ.",
        nen: " Cắt May, Đắp Đê, Lấp Hang Hố. ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Khai Trương, Nhập Trạch, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Thiên Xá, Đương Đức, Quan Nhật, Kim Đường, Tư Mệnh, Ô Phệ.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Nguyệt Hình, Nguyệt Yếm, Địa Hỏa, Thổ Phù",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thủ Nhật, Cát Kì, Lục Hợp, Bất Tương.",
        hungThan: " Câu Trần.",
        nen: " Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Cưới Gả, Thu Nạp Người, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Quét Dọn, Nạp Gia Súc, An Táng.",
        khongNen: " Mời Thầy Chữa Bệnh, Gieo Trồng.",
      },
      {
        catThan:
          " Nguyệt Đức, Tướng Nhật, Dịch Mã, Thiên Hậu, Thiên Vu, Phúc Đức, Bất Tương, Trừ Thần, Thanh Long, Ô Phệ.",
        hungThan: " Ngũ Hư, Ngũ Li.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Lấp Hang Hố, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng.",
        khongNen: " Kê Giường, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Dân Nhật, Kính An, Trừ Thần, Minh Đường, Ô Phệ .",
        hungThan:
          " Thiên Cương, Tử Thần, Thiên Lại, Chí Tử, Thiên Tặc, Phục Nhật, Ngũ Li.",
        nen: " Tắm Gội, Quét Dọn, Sửa Tường, Sửa Đường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Ân, Tứ Tướng, Tam Hợp, Lâm Nhật, Thời Âm, Thiên Thương, Bất Tương, Phổ Hộ.",
        hungThan: " Tử Khí, Thiên Hình.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Giải Trừ, Mời Thầy Chữa Bệnh, Xếp Đặt Buồng Đẻ, Gieo Trồng.",
      },
      {
        catThan: " Tứ Tướng, Ngũ Phú, Phúc Sinh.",
        hungThan: " Kiếp Sát, Tiểu Hao, Trùng Nhật, Chu Tước.",
        nen: " Cúng Tế, Tắm Gội, Đuổi Bắt.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Lục Nghi, Giải Thần, Kim Quĩ, Ô Phệ Đối.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tai Sát, Thiên Hỏa, Yếm Đối, Chiêu Dao, Ngũ Hư.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan: " Nguyệt Đức Hợp, Âm Đức, Thánh Tâm, Bảo Quang.",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Nguyệt Hại, Tứ Kích",
        nen: " Cúng Tế.",
        khongNen:
          " Đội Mũ Cài Trâm, Mời Thầy Chữa Bệnh, Nấu Rượu, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Nguyệt Không, Mẫu Thương, Tam Hợp, Thiên Mã, Thiên Hỉ, Thiên Y, Ích Hậu, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Đại Sát, Qui Kị, Bạch Hổ.",
        nen: " Dâng Biểu Sớ, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
        khongNen: " Cúng Tế, Di Chuyển, Đi Xa Trở Về, Khơi Mương.",
      },
      {
        catThan: " Mẫu Thương, Tục Thế, Ngũ Hợp, Ngọc Đường, Ô Phệ Đối.",
        hungThan:
          " Hà Khôi, Đại Thời, Đại Bại, Hàm Trì, Cửu Khảm, Cửu Tiêu, Huyết Kị, Vãng Vong.",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Đuổi Bắt, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thời Đức, Thời Dương, Sinh Khí, Yếu An.",
        hungThan: " Nguyệt Hư, Bát Phong, Cửu Không, Địa Nang, Thiên Lao.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Chăn Nuôi.",
        khongNen:
          " Thu Nạp Người, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan: " Vương Nhật, Ngọc Vũ.",
        hungThan: " Du Họa, Huyết Chi, Trùng Nhật, Huyền Vũ.",
        nen: " Cắt May, Đắp Đê, Lấp Hang Hố.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Châm Cứu, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Khai Trương, Nhập Trạch, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Nguyệt Đức, Dương Đức, Quan Nhật, Kim Đường, Tư Mệnh, Ô Phệ.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Nguyệt Hình, Nguyệt Yếm, Địa Hỏa, Thổ Phù, Đại Hội, Âm Dương Câu Thác.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Thiên Nguyện, Thủ Nhật, Cát Kì, Lục Hợp.",
        hungThan: " Phục Nhật, Bát Chuyên, Câu Trần.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Cắt Tóc, Mời Thầy Chữa Bệnh.",
      },
      {
        catThan:
          " Nguyệt Ân, Tứ Tướng, Tướng Nhật, Dịch Mã, Thiên Hậu, Thiên Vu, Phúc Đức, Bất Tương, Trừ Thần, Thanh Long.",
        hungThan: " Ngũ Hư, Ngũ Li.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Khai Trương, Nhập Trạch, Nạp Tài, Lấp Hang Hố, Quét Dọn, Gieo Trồng, Chăn Nuôi.",
        khongNen:
          " Họp Thân Hữu, Đính Hôn, Ăn Hỏi, Kê Giường, Sửa Kho, Lập Ước Giao Dịch, Mở Kho Xuất Tiền Hàng.",
      },
      {
        catThan:
          " Thiên Ân, Tứ Tường, Dân Nhật, Kính An, Trừ Thần, Minh Đường, Ô Phệ.",
        hungThan:
          " Thiên Cương, Tử Thần, Thiên Lại, Chí Tử, Thiên Tặc, Ngũ Li.",
        nen: " Cúng Tế, Tắm Gội, Cắt Tóc, Sửa Móng, Quét Dọn, Sửa Tường, Sửa Đường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Ân, Tam Hợp, Lâm Nhật, Thời Âm, Thiên Thương, Phổ Hộ.",
        hungThan: " Tử Khí, Thiên Hình.",
        nen: " Cúng Tế, Cầu Phúc, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Nạp Người, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Nạp Gia Súc. ",
        khongNen:
          " Giải Trừ, Mời Thầy Chữa Bệnh, Đan Dệt, Xếp Đặt Buồng Đẻ, Gieo Trồng",
      },
      {
        catThan: " Nguyệt Đức Hợp, Thiên Ân, Ngũ Phú, Phúc Sinh.",
        hungThan: " Kiếp Sát, Tiểu Hao, Trùng Nhật, Chu Tước.",
        nen: " Cúng Tế, Tắm Gội, Đuổi Bắt.",
        khongNen:
          " Cưới Gả, Mời Thầy Chữa Bệnh, Sửa Kho, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Săn Bắn, Đánh Cá. ",
      },
      {
        catThan:
          " Nguyệt Không, Thiên Ân, Lục Nghi, Giải Thần, Kim Quĩ, Ô Phệ Đối.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tai Sát, Thiên Hỏa, Yếm Đối, Chiêu Dao, Tứ Phế, Ngũ Hư, Âm Dương Kích Xung.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Thiên Ân, Âm Đức, Thánh Tâm, Bảo Quang.",
        hungThan:
          " Nguyệt Sát, Nguyệt Hư, Nguyệt Hại, Tứ Kích, Bát Chuyên, Xúc Thủy Long.",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Mẫu Thương, Tam Hợp, Thiên Mã, Thiên Hỉ, Thiên Y, Ích Hậu, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Đại Sát, Qui Kị, Bát Chuyên, Bạch Hổ.",
        nen: " Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Thu Nạp Người, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, Cải Táng.",
        khongNen:
          " Cúng Tế, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Đi Xa Trở Về, Mở Kho Xuất Tiền Hàng.",
      },
      {
        catThan: " Mẫu Thương, Tục Thế, Ngũ Hợp, Ngọc Đường, Ô Phệ Đối.",
        hungThan:
          " Hà Khôi, Đại Thời, Đại Bại, Hàm Trì, Tứ Hao, Cửu Khảm, Cửu Tiêu, Huyết Kị, Vãng Vong.",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Lấp Hang Hố, Đuổi Bắt, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Nguyệt Đức, Thời Đức, Thời Dương, Sinh Khí, Yếu An.",
        hungThan: " Ngũ Hư, Cửu Không, Thiên Lao.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Chặt Cây, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Vương Nhật, Ngọc Vũ.",
        hungThan: " Du Họa, Huyết Chi, Phục Nhật, Trùng Nhật, Huyền Vũ.",
        nen: " Cắt May, Đắp Đê, Lấp Hang Hố.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Khai Trương, Nhập Trạch, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Nguyệt Ân, Tứ Tướng, Dương Đức, Quan Nhật, Kim Đường, Tư Mệnh.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Nguyệt Hình, Nguyệt Yếm, Địa Hỏa, Thổ Phù, Tiểu Hội.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Tứ Tướng, Thủ Nhật, Cát Kì, Lục Hợp.",
        hungThan: " Bát Chuyên, Câu Trần.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng.  ",
        khongNen: " Đính Hôn, Ăn Hỏi, Cưới Gả, Mời Thầy Chữa Bệnh.",
      },
      {
        catThan:
          " Tướng Nhật, Dịch Mã, Thiên Hậu, Thiên Vu, Phúc Đức, Trừ Thần, Thanh Long, Ô Phệ.",
        hungThan: " Ngũ Hư, Ngũ Li, Bát Chuyên.",
        nen: " Cúng Tế, Cầu Phúc, Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Khai Trương, Nhập Trạch, Nạp Tài, Lấp Hang Hố, Quét Dọn, Phá Thổ, An Táng.",
        khongNen:
          " Họp Thân Hữu, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Kê Giường, Mời Thầy Chữa Bệnh, Sửa Kho, Đan Dệt, Lập Ước Giao Dịch, Mở Kho Xuất Tiền Hàng.",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Dân Nhật, Kính An, Trừ Thần, Minh Đường, Ô Phệ.",
        hungThan:
          " Thiên Cương, Tử Thần, Thiên Lại, Chí Tử, Thiên Tặc, Ngũ Li.",
        nen: " Cúng Tế, Tắm Gội, Cắt Tóc, Sửa Móng, Quét Dọn, Sửa Tường, Sửa Đường.",
        khongNen:
          " Họp Thân Hữu, Xuất Hành, Mời Thầy Chữa Bệnh, Sửa Kho, Nấu Rượu, Mở Kho Xuất Tiền Hàng, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Nguyệt Không, Tam Hợp, Lâm Nhật, Thời Âm, Thiên Thương, Phổ Hộ.",
        hungThan: " Tử Khí, Địa Nang, Thiên Hình.",
        nen: " Cúng Tế, Cầu Phúc, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Nạp Gia Súc.",
        khongNen:
          " Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan: " Ngũ Phú, Bất Tương, Phúc Sinh.",
        hungThan: " Kiếp Sát, Tiểu Hao, Tứ Phế, Trùng Nhật, Chu Tước.",
        nen: " Cúng Tế, Tắm Gội.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
    ],
    [
      {
        catThan: " Thiên Đức, Nguyệt Đức, Thiên Ân, Kim Đường, Giải Thần.",
        hungThan:
          " Nguyệt Hại, Đại Thời, Đại Bại, Hàm Trì, Tiểu Hao, Ngũ Hư, Cửu Khảm, Cửu Tiêu, Qui Kị, Thiên Hình.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đuổi Bắt, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Di Chuyển, Đi Xa Trở Về, Mời Thầy Chữa Bệnh, Rèn Đúc, Mở Kho Xuất Tiền Hàng, Lấp Hang Hố, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng.",
      },
      {
        catThan: " Thiên Ân.",
        hungThan: " Nguyệt Phá, Đại Hao, Tứ Kích, Cửu Không, Chu Tước.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Thiên Ân, Mẫu Thương, Ngũ Phú, Ngũ Hợp, Kim Quĩ, Ô Phệ Đối.",
        hungThan: " Du Họa",
        nen: " Họp Thân Hữu, Đính Hôn, Kê Giường, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, Cải Táng.  ",
        khongNen: " Cúng Tế, Cầu Phúc, Cầu Tự, Giải Trừ, Mời Thầy Chữa Bệnh.",
      },
      {
        catThan:
          " Thiên Ân, Mẫu Thương, Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y, Kính An, Ngũ Hợp, Bảo Quang, Ô Phệ Đối.",
        hungThan: " Đại Sát.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, Cải Táng. ",
        khongNen: " Cắt Tóc, Đào Giếng.",
      },
      {
        catThan: " Thiên Ân, Tứ Tướng, Thời Đức, Thiên Mã, Phổ Hộ.",
        hungThan: " Thiên Cương, Ngũ Hư, Ngũ Mộ, Bạch Hổ.",
        nen: " Cúng Tế, Nạp Tài, Đuổi Bắt.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm,Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Đức Hợp, Tứ Tướng, Vương Nhật, Dịch Mã, Thiên Hậu, Thời Dương, Sinh Khí, Phúc Sinh, Ngọc Đường",
        hungThan: " Nguyệt Yếm, Địa Hỏa, Trùng Nhật, Phục Nhật, Âm Thác.",
        nen: " Cúng Tế, Nhập Học.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Nguyệt Không, Quan Nhật, Lục Hợp, Ô Phệ.",
        hungThan: " Thiên Lại, Chí Tử, Huyết Chi, Vãng Vong, Thiên Lao.",
        nen: " Nấu Rượu, Lấp Hang Hố, Phá Thổ, An Táng.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Lợp Mái, Gác Đòn Dông, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
      },
      {
        catThan: " Nguyệt Ân, Thủ Nhật, Thánh Tâm.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Huyền Vũ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Đính Hôn, Ăn Hỏi, Di Chuyển, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Nạp Tài, Mở Kho Xuất Tiền Hàng, Chăn Nuôi.",
        khongNen:
          " Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Nấu Rượu, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan:
          " Dương Đức, Tướng Nhật, Cát Kì, Bất Tương, Ích Hậu, Trừ Thần, Tư Mệnh, Ô Phệ.",
        hungThan: " Kiếp Sát, Thiên Tặc, Ngũ Hư, Ngũ Li.",
        nen: " Cúng Tế, Tắm Gội, Quét Dọn.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
      },
      {
        catThan:
          " Dân Nhật, Thiên Vu, Phúc Đức, Thiên Thương, Bất Tương, Tục Thế, Trừ Thần, Ô Phệ.",
        hungThan: " Tai Sát, Thiên Hỏa, Huyết Kị, Ngũ Li, Câu Trần.",
        nen: " Cúng Tế, Tắm Gội, Quét Dọn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Châm Cứu,Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Đức, Nguyệt Đức, Bất Tương, Yếu An, Thanh Long.",
        hungThan: " Hà Khôi, Tử Thần, Nguyệt Sát, Nguyệt Hư, Thổ Phù",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Săn Bắn, Đánh Cá,  Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Âm Đức, Tam Hợp, Thời Âm, Lục Nghị, Ngọc Vũ, Minh Đường",
        hungThan: " Yếm Đối, Chiêu Dao, Tử Khí, Trùng Nhật.",
        nen: " Họp Thân Hữu, Đội Mũ Cài Trâm, Gặp Dân, Đính Hôn, Ăn Hỏi, Thu Nạp Người, Tắm Gội, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc. ",
        khongNen:
          " Cưới Gả, Giải Trừ, Mời Thầy Chữa Bệnh, Xếp Đặt Buồng Đẻ, Đánh Cá, Đi Thuyền, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Kim Đường, Giải Thần, Ô Phệ Đối.",
        hungThan:
          " Nguyệt Hại, Đại Thời, Đại Bại, Hàm Trì, Tiểu Hao, Tứ Kị, Thất Mã, Ngũ Hư, Cửu Khảm, Cửu Tiêu, Qui Kị, Xúc Thủy Long, Thiên Hình.",
        nen: " Tắm Gội, Cắt Tóc, Sửa Móng, Đuổi Bắt.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người,  Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Lấp Hang Hố, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: "",
        hungThan:
          " Nguyệt Phá, Đại Hao, Nguyệt Hình, Tứ Kích, Cửu Không, Chu Tước.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Mẫu Thương, Tứ Tướng, Ngũ Phú, Ngũ Hợp, Kim Quĩ.",
        hungThan: " Du Họa.",
        nen: " Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Di Chuyển, Kê Giường, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trường, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Cúng Tế, Cầu Phúc, Cầu Tự, Giải Trừ, Mời Thầy Chữa Bệnh.",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Đức Hợp, Thiên Ân, Mẫu Thương, Tứ Tướng, Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y, Kính An, Ngũ Hợp, Bảo Quang.",
        hungThan: " Đại Sát, Phục Nhật.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Đào Giếng, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Nguyệt Không, Thiên Ân, Thời Đức, Thiên Mã, Phổ Hộ.",
        hungThan: " Thiên Cương, Ngũ Hư, Bạch Hổ.",
        nen: " Cúng Tế, Thu Nạp Người, Nạp Tài, Đuổi Bắt, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Thiên Ân, Nguyệt Ân, Vương Nhật, Dịch Mã, Thiên Hậu, Thời Dương, Sinh Khí, Phúc Sinh, Ngọc Đường.",
        hungThan: " Nguyệt Yếm, Địa Hỏa, Trùng Nhật.",
        nen: " Cúng Tế, Nhập Học.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Ân, Quan Nhật, Lục Hợp, Bất Tương, Ô Phệ.",
        hungThan: " Thiên Lại, Chí Tử, Huyết Chi, Vãng Vong, Thiên Lao.",
        nen: " Đan Dệt, Nấu Rượu, Lấp Hang Hố, Phá Thổ, An Táng.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
      },
      {
        catThan: " Thiên Ân, Thủ Nhật, Bất Tương, Thánh Tâm",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Xúc Thủy Long, Huyền Vũ",
        nen: " Cúng Tế, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Cưới Gả",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Đính Hôn, Ăn Hỏi, Giải Trừi, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Mở Kho Xuất Tiền Hàng, Sắp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Thiên Đức, Nguyệt Đức, Dương Đức, Tướng Nhật, Cát Kì, Bất Tương, Ích Hậu, Trừ Thần, Tư Mệnh, Ô Phệ.",
        hungThan: " Kiếp Sát, Thiên Tặc, Ngũ Hư, Bát Phong, Ngũ Li.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
        khongNen:
          " Xuất Hành, Kê Giường, Mời Thầy Chữa Bệnh, Sửa Kho, Mở Kho Xuất Tiền Hàng, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Dân Nhật, Thiên Vu, Phúc Đức, Thiên Thương, Bất Tương, Tục Thế, Trừ Thần, Ô Phệ.",
        hungThan: " Tai Sát, Thiên Hỏa, Huyết Kị, Ngũ Li, Câu Trần.",
        nen: " Cúng Tế, Tắm Gội, Quét Dọn. ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Yếu An, Thanh Long.",
        hungThan:
          " Hà Khôi, Tử Thần, Nguyệt Sát, Nguyệt Hư, Thổ Phù, Địa Nang.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Âm Đức, Tam Hợp, Thời Âm, Lục Nghi, Ngọc Vũ, Minh Đường.",
        hungThan: " Yếm Đối, Chiêu Dao, Tử Khí, Tứ Cùng, Thất Mã, Trùng Nhật.",
        nen: " Họp Thân Hữu, Đội Mũ Cài Trâm, Gặp Dân, Tắm Gội, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Nấu Rượu, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Đính Hôn, Ăn Hỏi,Cưới Gả, Thu Nạp Người, Giải Trừ, Cắt Tóc, Mời Thầy Chữa Bệnh, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Đánh Cá, Đi Thuyền, Gieo Trồng, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Tứ Tướng, Kim Đường, Giải Thần.",
        hungThan:
          " Nguyệt Hại, Đại Thời, Đại Bại, Hàm Trì, Tiểu Hao, Ngũ Hư, Cửu Khảm, Cửu Tiêu, Qui Kị, Thiên Hình.",
        nen: " Cúng Tế, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Đuổi Bắt.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Đức Hợp, Nguyệt Đức Hợp, Tứ Tướng",
        hungThan:
          " Nguyệt Phá, Đại Hao, Nguyệt Hình, Tứ Kích, Cửu Không, Phục Nhật, Chu Tước.",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Không, Mẫu Thương, Ngũ Phú, Ngũ Hợp, Kim Quĩ, Ô Phệ Đối.",
        hungThan: " Du Họa.",
        nen: " Dâng Biểu Sớ, Họp Thân Hữu, Dính Hôn, Kê Giường, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, Cải Táng. ",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Giải Trừ, Mời Thầy Chữa Bệnh, Đan Dệt.",
      },
      {
        catThan:
          " Mẫu Thương, Nguyệt Ân, Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y, Kính An, Ngũ Hợp, Bảo Quang, Ô Phệ Đối.",
        hungThan: " Đại Sát.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Học Hành, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, Cải Táng. ",
        khongNen: " Nấu Rượu, Đào Giếng.",
      },
      {
        catThan: " Thời Đức, Thiên Mã, Phổ Hộ.",
        hungThan: " Thiên Cương, Ngũ Hư, Bạch Hổ.",
        nen: " Cúng Tế, Thu Nạp Người, Nạp Tài, Đuổi Bắt, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Vương Nhật, Dịch Mã, Thiên Hậu, Thời Dương, Sinh Khí, Phúc Sinh, Ngọc Đường.",
        hungThan: " Nguyệt Yếm, Địa Hỏa, Trùng Nhật.",
        nen: " Cúng Tế, Nhập Học.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Thiên Đức, Nguyệt Đức, Thiên Xá, Quan Nhật, Lục Hợp, Bất Tương, Ô Phệ",
        hungThan: " Thiên Lại, Chí Tử, Huyết Chi, Vãng Vong, Thiên Lao.",
        nen: " Cúng Tế, Cắt May, Đan Dệt, Nấu Rượu, Lấp Hang Hố, Phá Thổ, An Táng.",
        khongNen: " ",
      },
      {
        catThan: " Thủ Nhật, Bất Tương, Thánh Tâm.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Huyền Vũ.",
        nen: " Cúng Tế, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Cưới Gả.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Đính Hôn, Ăn Hỏi, Giải Trừ, Cắt Tóc , Sửa Móng, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Dương Đức, Tướng Nhật, Cát Kì, Ích Hậu, Trừ Thần, Tư Mệnh, Ô Phệ.",
        hungThan: " Kiếp Sát, Thiên Tặc, Ngũ Hư, Ngũ Li.",
        nen: " Cúng Tế, Tắm Gội, Quét Dọn.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
      },
      {
        catThan:
          " Dân Nhật, Thiên Vu, Phúc Đức, Thiên Thương, Tục Thế, Trừ Thần, Ô Phệ",
        hungThan: " Tai Sát, Thiên Hỏa, Huyết Kị, Ngũ Li, Câu Trần.",
        nen: " Cúng Tế, Tắm Gội, Quét Dọn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Tứ Tướng, Bất Tương, Yếu An, Thanh Long.",
        hungThan: " Hà Khôi, Tử Thần, Nguyệt Sát, Nguyệt Hư, Thổ Phù",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Đức Hợp, Tứ Tướng, Âm Đức, Tam Hợp, Thời Âm, Lục Nghi, Ngọc Vũ, Minh Đường.",
        hungThan: " Yếm Đối, Chiêu Dao, Tử Khí, Phục Nhật, Trùng Nhật.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Thu  Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Cưới Gả, Mời Thầy Chữa Bệnh, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Nguyệt Không, Kim Đường, Giải Thần, Ô Phệ Đối.",
        hungThan:
          " Nguyệt Hại, Đại Thời, Đại Bại, Hàm Trì, Tiểu Hao, Ngũ Hư, Cửu Khảm, Cửu Tiêu, Qui Kị, Thiên Hình.",
        nen: " Tắm Gội, Cắt Tóc, Sửa Móng, Đuổi Bắt.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Lấp Hang Hố, Đánh Cá, Đi Thuyền, Gieo Trồng Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Nguyệt Ân.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Nguyệt Hình, Tứ Kích, Cửu Không, Chu Tước.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Mẫu Thương, Ngũ Phú, Ngũ Hợp, Kim Quĩ, Ô Phệ Đối.",
        hungThan: " Du Họa,",
        nen: " Họp Thân Hữu, Đính Hôn, Kê Giường, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng. ",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Giải Trừ, Mời Thầy Chữa Bệnh, Khơi Mương.",
      },
      {
        catThan:
          " Mẫu Thương, Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y, Kính An, Ngũ Hợp, Bảo Quang, Ô Phệ Đối.",
        hungThan: " Đại Sát.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, Cải Táng. ",
        khongNen: " Đào Giếng.",
      },
      {
        catThan: " Thiên Đức, Nguyệt Đức, Thời Đức, Thiên Mã, Phổ Hộ.",
        hungThan: " Thiên Cương, Ngũ Hư, Bát Phong, Bạch Hổ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Nạp Tài, Đuổi Bắt, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Mời Thầy Chữa Bệnh, Mở Kho Xuất Tiền Hàng, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Vương Nhật, Dịch Mã, Thiên Hậu, Thời Dương, Sinh Khí, Phúc Sinh, Ngọc Đường.",
        hungThan: " Nguyệt Yếm, Địa Hỏa, Trùng Nhật.",
        nen: " Cúng Tế, Nhập Học.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt Tóc, Sửa Móng, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.  ",
      },
      {
        catThan: " Quan Nhật, Lục Hợp, Ô Phệ.",
        hungThan:
          " Thiên Lại, Chí Tử, Huyết Chi, Vãng Vong, Thiên Lao, Trục Trận.",
        nen: " ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Lợp Mái, Gác Đòn Dông, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
      },
      {
        catThan: " Thủ Nhật, Thánh Tâm.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Bát Chuyên, Huyền Vũ, Dương Thác.",
        nen: " Cúng Tế, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Đính Hôn, Ăn Hỏi, Cưới Gả, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Gieo Trồng, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Tứ Tướng, Dương Đức, Tướng Nhật, Cát Kì, Bất Tương, Ích Hậu, Trừ Thần, Tư Mệnh.",
        hungThan: " Kiếp Sát, Thiên Tặc, Ngũ Hư, Ngũ Li.",
        nen: " Cúng Tế, Tắm Gội, Quét Dọn.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Đính Hôn, Ăn Hỏi, Kê Giường, Mời Thầy Chữa Bệnh, Sửa Kho, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Đức Hợp, Thiên Ân, Tứ Tướng, Dân Nhật, Thiên Vu, Phúc Đức, Thiên Thương, Tục Thế, Trừ Thần, Ô Phệ.",
        hungThan: " Tai Sát, Thiên Hỏa, Huyết Kị, Phục Nhật, Ngũ Li, Câu Trần.",
        nen: " Cúng Tế, Cầu Phú Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Lấp Hang Hố, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Họp Thân Hữu, Mời Thầy Chữa Bệnh, Châm Cứu, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Nguyệt Không, Thiên Ân, Yếu An, Thanh Long.",
        hungThan: " Hà Khôi, Tử Thần, Nguyệt Sát, Nguyệt Hư, Thổ Phù.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan:
          " Thiên Ân, Nguyệt Ân, Âm Đức, Tam Hợp, Thời Âm, Lục Nghi, Ngọc Vũ, Minh Đường.",
        hungThan: " Yếm Đối, Chiêu Dao, Tử Khí, Trùng Nhật.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Thu Nạp Người, Di Chuyển, Tắm Gội, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Cưới Gả, Giải Trừ, Mời Thầy Chữa Bệnh, Nấu Rượu, Xếp Đặt Buồng Đẻ, Đánh Cá, Đi Thuyền, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Thiên Ân, Kim Đường, Giải Thần, Ô Phệ Đối.",
        hungThan:
          " Nguyệt Hại, Đại Thời, Đại Bại, Hàm Trì, Tiểu Hao, Tứ Phế, Ngũ Hư, Cửu Khảm, Cửu Tiêu, Qui Kị, Thiên Hình",
        nen: " Tắm Gội, Cắt Tóc, Sửa Móng, Đuổi Bắt.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Ân.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Nguyệt Hình, Tứ Kích, Cửu Không, Bát Chuyên, Xúc Thủy Long, Chu Tước, Dương Phá Âm Xung.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan:
          " Thiên Đức, Nguyệt Đức, Mẫu Thương, Ngũ Phú, Ngũ Hợp, Kim Quĩ, Ô Phệ Đối.",
        hungThan: " Du Họa, Bát Chuyên.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Di Chuyển, Kê Giường, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Đính Hôn, Ăn Hỏi, Cưới Gả, Giải Trừ, Mời Thầy Chữa Bệnh, Mở Kho Xuất Tiền Hàng, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Mẫu Thương, Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y, Kính An, Ngũ Hợp, Bảo Quang, Ô Phệ Đối.",
        hungThan: " Tứ Hao, Đại Sát",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, Cải Táng. ",
        khongNen: " Đào Giếng, Gieo Trồng.",
      },
      {
        catThan: " Thời Đức, Thiên Mã, Phổ Hộ.",
        hungThan: " Thiên Cương, Ngũ Hư, Địa Nang, Bạch Hổ.",
        nen: " Cúng Tế, Thu Nạp Người, Nạp Tài, Đuổi Bắt, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Vương Nhật, Dịch Mã, Thiên Hậu, Thời Dương, Sinh Khí, Phúc Sinh, Ngọc Đường.",
        hungThan: " Nguyệt Yếm, Địa Hỏa, Trùng Nhật, Đại Hội, Âm Thác.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Thiên Nguyện, Tứ Tướng, Quan Nhật, Lục Hợp, Bất Tương.",
        hungThan:
          " Thiên Lại, Chí Tử, Huyết Chi, Vãng Vong, Thiên Lao, Trục Trận.",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Lợp Mái, Gác Đòn Dông, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Đức Hợp, Tứ Tướng, Thủ Nhật, Thánh Tâm.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Phục Nhật, Bát Chuyên, Huyền Vũ, Dương Thác.",
        nen: " Cúng Tế, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Di Chuyển, Cắt May, Nạp Tài, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Đính Hôn, Ăn Hỏi, Cưới Gả, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước , Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Không, Dương Đức, Tướng Nhật, Cát Kì, Ích Hậu, Trừ Thần, Tư Mệnh, Ô Phệ.",
        hungThan: " Kiếp Sát, Thiên Tặc, Ngũ Hư, Ngũ Li, Bát Chuyên.",
        nen: " Cúng Tế, Tắm Gội, Quét Dọn.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc. ",
      },
      {
        catThan:
          " Nguyệt Ân, Dân Nhật, Thiên Vu, Phúc Đức, Thiên Thương, Tục Thế, Trừ Thần, Ô Phệ.",
        hungThan: " Tai Sát, Thiên Hỏa, Huyết Kị, Ngũ Li, Câu Trần.",
        nen: " Cúng Tế, Tắm Gội, Quét Dọn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Bất Tương, Yếu An, Thanh Long.",
        hungThan: " Hà Khôi, Tử Thần, Nguyệt Sát, Nguyệt Hư, Thổ Phù",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan: " Âm Đức, Tam Hợp, Thời Âm, Lục Nghi, Ngọc Vũ, Minh Đường.",
        hungThan: " Yếm Đối, Chiêu Dao, Tử Khí, Tứ Phế, Trùng Nhật.",
        nen: " Tắm Gội.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
    ],
    [
      {
        catThan:
          " Thiên Ân, Thời Đức, Dân Nhật, Tam Hợp, Lâm Nhật, Thời Âm, Phúc Sinh, Thanh Long.",
        hungThan: " Tử Khí.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Tắm Gội, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Giải Trừ, Mời Thầy Chữa Bệnh, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Gieo Trồng.",
      },
      {
        catThan: " Thiên Ân, Mẫu Thương, Minh Đường.",
        hungThan: " Tiểu Hao, Qui Kị.",
        nen: " Họp Thân Hữu, Đuổi Bắt, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Đội Mũ Cài Trâm, Di Chuyển, Đi Xa Trở Về, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Gieo Trồng.",
      },
      {
        catThan:
          " Nguyệt Không, Thiên Ân, Dịch Mã, Thiên Hậu, Thánh Tâm, Giải Thần, Ngũ Hơp, Ô Phệ Đối.",
        hungThan: " Nguyệt Phá, Đại Hao, Nguyệt Hình, Thiên Hình.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Nguyệt Đức Hợp, Thiên Ân, Ích Hậu, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Thiên Lại, Chí Tử, Ngũ Hư, Thổ Phù, Chu Tước.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đuổi Bắt, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Xuất Hành, Mời Thầy Chữa Bệnh.",
      },
      {
        catThan:
          "Thiên Đức Hợp, Thiên Ân, Mẫu Thương, Tam Hợp, Thiên Hỉ, Thiên Y, Tục Thế, Kim Quĩ.",
        hungThan: " Nguyệt Yếm, Địa Hỏa, Tứ Kích, Đại Sát, Huyết Kị.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Thu Nạp Người Giải Trừ, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Đi Xa Trở Về, Mời Thầy Chữa Bệnh, Châm Cứu, Săn Bắn, Đánh Cá, Gieo Trồng.",
      },
      {
        catThan: " Thiên Nguyện, Lục Hợp, Ngũ Phú, Yếu An, Bảo Quang.",
        hungThan: " Hà Khôi, Kiếp Sát, Trùng Nhật.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đuổi Bắt,Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Xuất Hành, Mời Thầy Chữa Bệnh.",
      },
      {
        catThan: " Thiên Mã, Thời Dương, Sinh Khí, Ngọc Vũ, Ô Phệ.",
        hungThan: " Tai Sát, Thiên Hỏa, Phục Nhật, Bạch Hổ.",
        nen: " Cúng Tế, Nhập Học.",
        khongNen:
          " Đội Mũ Cài Trâm, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Mời Thầy Chữa Bệnh, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Chặt Cây, Săn Bắn, Đánh Cá, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Mẫu Thương, Kim Đường, Ngọc Đường.",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Huyết Chi, Thiên Tặc, Ngũ Hư.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan:
          " Nguyệt Đức, Nguyệt Ân, Tứ Tướng, Vương Nhật, Thiên Thương, Bất Tương, Trừ Thần, Ô Phệ.",
        hungThan: " Nguyệt Kiến, Tiểu Hao, Thổ Phủ, Ngũ Li, Thiên Lao.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Nạp Tài, Mở Kho Xuất Tiền Hàng, Quét Dọn, Chăn Nuôi, Nạp Gia Súc, An Táng, Cải Táng.",
        khongNen:
          " Kê Giường, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan:
          " Thiên Đức, Tứ Tướng, Âm Đức, Quan Nhật, Cát Kì, Bất Tương, Trừ Thần, Ô Phệ.",
        hungThan:
          " Đại Thời, Đại Bại, Hàm Trì, Cửu Khảm, Cửu Tiêu, Vãng Vong, Ngũ Li, Huyền Vũ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Đính Hôn, Ăn Hỏi, Giải Trừ, Tắm Gội, Xắt Tóc, Sửa Móng, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Nạp Tài, Mở Kho Xuất Tiền Hàng, Quét Dọn, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Cưới Gả, Thu  Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Rèn Đúc, Lấp Hang Hố, Đuổi Bắt, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng.",
      },
      {
        catThan:
          " Mẫu Thương, Dương Đức, Thủ Nhật, Thiên Vu, Phúc Đức, Lục Nghi, Kính An, Tư Mệnh.",
        hungThan: " Yếm Đối, Chiêu Dao, Thiên Cẩu, Cửu Không.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Cắt May, Đan Dệt, Lấp Hang Hố, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Cúng Tế, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Mời Thầy Chữa Bệnh, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đánh Cá, Đi Thuyền.",
      },
      {
        catThan: " Tướng Nhật, Phổ Hộ.",
        hungThan:
          " Thiên Cương, Tử Thần, Nguyệt Hại, Du Họa, Ngũ Hư, Trùng Nhật, Câu Trần.",
        nen: " Cúng Tế, Tắm Gội, Sửa Đường, Sửa Tường, ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Không, Thời Đức, Dân Nhật, Tam Hợp, Lâm Nhật, Thời Âm, Phúc Sinh, Thanh Long, Ô Phệ Đối.",
        hungThan: " Tử Khí, Xúc Thủy Long.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Tắm Gội, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, Cải Táng. ",
        khongNen:
          " Giải Trừ, Mời Thầy Chữa Bệnh, Xếp Đặt Buồng Đẻ, Đánh Cá, Đi Thuyền, Gieo Trồng.",
      },
      {
        catThan: " Nguyệt Đức Hợp, Mẫu Thương , Minh Đường",
        hungThan: " Tiểu Hao, Qui Kị.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Nạp Tài, Đuổi Bắt, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Đội Mũ Cài Trâm, Di Chuyển, Đi Xa Trở Về, Cắt Tóc, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Thiên Đức Hợp, Dịch Mã, Thiên Hậu, Thánh Tâm, Giải Thần, Ngũ Hợp.",
        hungThan: " Nguyệt Phá, Đại Hao, Nguyệt Hình, Thiên Hình.",
        nen: " Tắm Gội.",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Ân, Ích Hậu, Ngũ Hợp.",
        hungThan: " Thiên Lại, Chí Tử, Ngũ Hư, Thổ Phù, Chu Tước.",
        nen: " Cúng Tế, Họp Thân Hữu.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ.",
      },
      {
        catThan:
          " Thiên Ân, Mẫu Thương, Tam Hợp, Thiên Hỉ, Thiên Y, Tục Thế, Kim Quĩ.",
        hungThan:
          " Nguyệt Yếm, Địa Hỏa, Tứ Kích, Đại Sát, Huyết Kị, Phục Nhật, Đại Hội",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Thiên Ân, Lục Hợp, Ngũ Phú, Yếu An, Bảo Quang.",
        hungThan: " Hà Khôi, Kiếp Sát, Trùng Nhật.",
        nen: " Họp Thân Hữu, Đính Hôn, Cưới Gả, Thu Nạp Người, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đuổi Bắt, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Xuất Hành, Mời Thầy Chữa Bệnh, Nấu Rượu.",
      },
      {
        catThan:
          " Nguyệt Đức, Thiên Ân, Nguyệt Ân, Tứ Tướng, Thiên Mã, Thời Dương, Sinh Khí, Bất Tương, Ngọc Vũ, Ô Phệ.",
        hungThan: " Tai Sát, Thiên Hỏa, Bạch Hổ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Dặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Mời Thầy Chữa Bệnh, Lợp Mái, Gác Đòn Dông, Khơi Mương, Chặt Cây, Săn Bắn, Đánh Cá",
      },
      {
        catThan:
          " Thiên Đức, Thiên Ân, Mẫu Thương, Tứ Tướng, Bất Tương, Kim Đường, Ngọc Vũ.",
        hungThan:
          " Nguyệt Sát, Nguyệt Hư, Huyết Chi, Thiên Tặc, Ngũ Hư, Xúc Thủy Long.",
        nen: " Cúng Tế ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Ddính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Vương Nhật, Thiên Thương, Bất Tương, Trừ Thần, Ô Phệ.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Ngũ Li, Thiên Lao.",
        nen: " Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Cưới Gả, Thu Nạp Người, Tắm Gội, Cắt May, Nạp Tài, Quét Dọn, Nạp Gia Súc.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đính Hôn, Ăn Hỏi, Giải Trừ, Sửa Móng, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Lập Ước Giao Dịch, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Gieo Trồng, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Âm Đức, Quan Nhật, Cát Kì, Bất Tương, Trừ Thần, Ô Phệ.",
        hungThan:
          " Đại Thời, Đại Bại, Hàm Trì, Cửu Khảm, Cửu Tiêu, Vãng Vong, Ngũ Li, Huyền Vũ.",
        nen: " Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Quét Dọn, Phá Thổ, An Táng.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Rèn Đúc, Lập Ước Giao Dịch, Lấp Hang Hố, Đuổi Bắt, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng. ",
      },
      {
        catThan:
          " Nguyệt Không, Mẫu Thương, Dương Đức, Thủ Nhật, Thiên Vu, Phúc Đức, Lục Nghi, Kính An, Tư Mệnh.",
        hungThan: " Yếm Đối, Chiêu Dao, Thiên Cẩu, Cửu Không, ",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Cắt May, Đan Dệt, Lấp Hang Hố, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Cúng Tế, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Mời Thầy Chữa Bệnh, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đánh Cá, Đi Thuyền.",
      },
      {
        catThan: " Nguyệt Đức Hợp, Tướng Nhật, Phổ Hộ.",
        hungThan:
          " Thiên Cương, Tử Thần, Nguyệt Hai, Du Họa, Ngũ Hư, Bát Phong, Địa Nang, Trùng Nhật, Câu Trần.",
        nen: " Cúng Tế, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Di Chuyển, Tắm Gội, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Chăn Nuôi, Nạp Gia Súc.  ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Cưới Gả, Giải Trừ, Cắt Tóc, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Gieo Trồng, Phá Thổ. ",
      },
      {
        catThan:
          " Thiên Đức Hợp, Thời Đức, Dân Nhật, Tam Hợp, Lâm Nhật, Thời Âm, Phúc Sinh, Thanh Long.",
        hungThan: " Tử Khí",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng.  ",
        khongNen: " Mời Thầy Chữa Bệnh, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Mẫu Thương, Minh Đường.",
        hungThan: " Tiểu Hao, Qui Kị.",
        nen: " Đuổi Bắt, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Đội Mũ Cài Trâm, Di Chuyển, Đi Xa Trở Về, Sửa Kho, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng.",
      },
      {
        catThan:
          " Dịch Mã, Thiên Hậu, Thánh Tâm, Giải Thần, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Nguyệt Phá, Đại Hao, Nguyệt Hình, Phục Nhật, Thiên Hình.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Ích Hậu, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Thiên Lại, Chí Tử, Ngũ Hư, Thổ Phù, Câu Trần.",
        nen: " Cúng Tế, Họp Thân Hữu, Cải Táng.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ.",
      },
      {
        catThan:
          " Nguyệt Đức, Mẫu Thương, Nguyệt Ân, Tứ Tướng, Tam Hợp, Thiên Hỉ, Thiên Y, Tục Thế, Kim Quĩ",
        hungThan: " Nguyệt Yến, Địa Hỏa, Tứ Kích, Đại Sát, Huyết Kị.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Thu Nạp Người, Giải Trừ, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc, An Táng.  ",
        khongNen:
          " Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Đi Xa Trở Về, Mời Thầy Chữa Bệnh, Châm Cứu, Khơi Mương, Săn Bắn, Đánh Cá, Gieo Trồng.  ",
      },
      {
        catThan:
          " Thiên Đức, Tứ Tướng, Lục Hợp, Ngũ Phú, Bất Tương, Yếu An, Bảo Quang.",
        hungThan: " Hà Khôi, Kiếp Sát, Trùng Nhật.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đuổi Bắt, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.  ",
        khongNen: " Xuất Hành, Mời Thầy Chữa Bệnh, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Thiên Mã, Thời Dương, Sinh Khí, Bất Tương, Ngọc Vũ, Ô Phệ.",
        hungThan: " Tai Sát, Thiên Hỏa, Bạch Hổ.",
        nen: " Cúng Tế, Nhập Học.",
        khongNen:
          " Đội Mũ Cài Trâm, Đính Hôn, Ăn Hỏi, Thu Nạp Người, Kê Giường, Mời Thầy Chữa Bệnh, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Mở Kho Xuất Tiền Hàng, Chặt Cây, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Mẫu Thương, Bất Tương, Kim Đường, Ngọc Đường.",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Huyết Chi, Thiên Tặc, Ngũ Hư.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Nguyệt Không, Vương Nhật, Thiên Thương, Trừ Thần, Ô Phệ.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Ngũ Li, Thiên Lao.",
        nen: " Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Thu Nạp Người, Tắm Gội, Cắt May, Nạp Tài, Quét Dọn, Nạp Gia Súc.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đính Hôn, Ăn Hỏi, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Lập Ước Giao Dịch, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Gieo Trồng, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Nguyệt Đức Hợp, Âm Đức, Quan Nhật, Cát Kì, Trừ Thần, Ô Phệ.",
        hungThan:
          " Đại Thời, Đại Bai, Hàm Trì, Cửu Khảm, Cửu Tiêu, Vãng Vong, Ngũ Li, Huyền Vũ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Đính Hôn, Ăn Hỏi, Giải Trừ, Tắm Gội, Sửa Móng, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Quét Dọn, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng. ",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Cưới Gả, Thu Nạp Người, Di Chuyển, Cắt Tóc, Mời Thầy Chữa Bệnh, Rèn Đúc, Lấp Hang Hố, Đuổi Bắt, Săn Bắn,  Đánh Cá, Đi Thuyền, Gieo Trồng.",
      },
      {
        catThan:
          " Thiên Đức Hợp, Mẫu Thương, Dương Đức, Thủ Nhật, Thiên Vu, Phúc Đức, Lục Nghi, Kính An, Tư Mệnh.",
        hungThan: " Yếm Đối, Chiêu Dao, Thiên Cẩu, Cửu Không.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Lấp Hang Hố, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng. ",
        khongNen: " Cúng Tế, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Tướng Nhật, Phổ Hộ.",
        hungThan:
          " Thiên Cương, Tử Thần, Nguyệt Hại, Du Họa, Ngũ Hư, Trùng Nhật, Câu Trần.",
        nen: " Cúng Tế, Tắm Gội, Sửa Tường, Sửa Đường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Thời Đức, Dân Nhật, Tam Hợp, Lâm Nhật, Thời Âm, Phúc Sinh, Thanh Long, Ô Phệ Đối.",
        hungThan: " Tử Khí, Tứ Kị, Cửu Hổ, Phục Nhật.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Thu Nạp Người, Di Chuyển, Tắm Gội, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc.    ",
        khongNen:
          " Đính Hôn, Ăn Hỏi, Cưới Gả, Giải Trừ, Mời Thầy Chữa Bệnh, Đan Dệt, Xếp Đặt Buồng Đẻ, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Mẫu Thương, Minh Đường.",
        hungThan: " Tiểu Hao, Ngũ Mộ, Qui Kị.",
        nen: " Đuổi Bắt.",
        khongNen:
          " Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Đức, Nguyệt Ân, Tứ Tướng, Dịch Mã, Thiên Hậu, Thánh Tâm, Giải Thần, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Nguyệt Phá, Đại Hao, Nguyệt Hình, Thiên Hình.",
        nen: " Tắm Gội.",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.   ",
      },
      {
        catThan: " Thiên Đức, Tứ Tướng, Ích Hậu, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Thiên Lại, Chí Tử, Ngũ Hư, Thổ Phù, Chu Tước",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Kê Giường, Giải Trừ, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Chăn Nuôi, Nạp Gia Súc, An Táng, Cải Táng.  ",
        khongNen:
          " Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan: " Mẫu Thương, Tam Hợp, Thiên Hỉ, Thiên Y, Tục Thế, Kim Quĩ.",
        hungThan: " Nguyệt Yếm, Địa Hỏa, Tứ Kích, Đại Sát, Huyết Kị, Âm Thác.",
        nen: " Cúng Tế, Nhập Học.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường,  Chặt Cây, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Lục Hợp, Ngũ Phú, Bất Tương, Yếu An, Bảo Quang.",
        hungThan: " Hà Khôi, Kiếp Sát, Trùng Nhật.",
        nen: " Họp Thân Hữu, Đính Hôn, Cưới Gả, Thu Nạp Người, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đuổi Bắt, Chăn Nuôi, Nạp Gia Súc. ",
        khongNen: " Xuất Hành, Mời Thầy Chữa Bệnh, Gieo Trồng.",
      },
      {
        catThan:
          " Nguyệt Không, Thiên Mã, Thời Dương, Sinh Khí, Ngọc Vũ, Ô Phệ.",
        hungThan: " Tai Sát, Thiên Hỏa, Bạch Hổ.",
        nen: " Cúng Tế, Nhập Học.",
        khongNen:
          " Đội Mũ Cài Trâm, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Mời Thầy Chữa Bệnh, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Chặt Cây, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Nguyệt Đức Hợp, Mẫu Thương, Kim Đường, Ngọc Đường.",
        hungThan:
          " Nguyệt Sát, Nguyệt Hư, Huyết Chi, Thiên Tặc, Ngũ Hư, Bát Phong, Bát Chuyên.",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Săn Bắn, Đánh Cá, Đi Thuyền. Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Thiên Đức Hợp, Thiên Xá, Vương Nhật, Thiên Thương, Bất Tương, Trừ Thần.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phù, Ngũ Li, Thiên Lao.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Nạp Tài, Quét Dọn, Chăn Nuôi, Nạp Gia Súc, An Táng. ",
        khongNen: " ",
      },
      {
        catThan: " Thiên Ân, Âm Đức, Quan Nhật, Cát Kì, Trừ Thần, Ô Phệ.",
        hungThan:
          " Đại Thời, Đại Bại, Hàm Trì, Cửu Khảm, Cửu Tiêu, Vãng Vong, Ngũ Li, Huyền Vũ.",
        nen: " Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Quét Dọn, Phá Thổ, An Táng.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Rèn Đúc, Lập Ước Giao Dịch, Lấp Hang Hố, Đuổi Bắt, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng.",
      },
      {
        catThan:
          " Thiên Ân, Mẫu Thương, Dương Đức, Thủ Nhật, Thiên Vu, Phúc Đức, Lục Nghi, Kính An, Tư Mệnh.",
        hungThan: " Yếm Đối, Chiêu Dao, Thiên Cẩu, Cửu Không, Phục Nhật.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Cắt May, Lấp Hang Hố, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Cúng Tế, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Mời Thầy Chữa Bệnh, Sửa Kho , Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đánh Cá, Đi Thuyền, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Ân, Tướng Nhật, Phổ Hộ.",
        hungThan:
          " Thiên Cương, Tử Thần, Nguyệt Hại, Du Họa, Tứ Cung, Cửu Thổ, Ngũ Hư, Trùng Nhật, Câu Trần.",
        nen: " Cúng Tế, Tắm Gội, Sửa Tường, Sửa Đường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Đức, Thiên Ân, Nguyệt Ân, Tứ Tướng, Thời Đức, Dân Nhật, Tam Hợp, Lâm Nhật, Thời Âm, Phúc Sinh, Thanh Long, Ô Phệ Đối.",
        hungThan: " Tử Khí",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
        khongNen: " Mời Thầy Chữa Bệnh, Khơi Mương, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Thiên Đức, Thiên Ân, Mẫu Thương, Tứ Tướng, Minh Đường.",
        hungThan: " Tiểu Hao, Qui Kị, Bát Chuyên, Xúc Thủy Long.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đuổi Bắt, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng. ",
        khongNen:
          " Đội Mũ Cài Trâm, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Đi Xa Trở Về, Săn Bắn, Đánh Cá, Đi Thuyền.",
      },
      {
        catThan:
          " Dịch Mã, Thiên Hậu, Thánh Tâm, Giải Thần, Ngũ Hợp, Ô Phệ Đối.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Nguyệt Hình, Tứ Phế, Bát Chuyên, Thiên Hình.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Ích Hậu, Ngũ Hợp, Ô Phệ Đối.",
        hungThan:
          " Thiên Lại, Chí Tử, Tứ Phế, Ngũ Hư, Thổ Phù, Chu Tước, Tam Âm.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan:
          " Nguyệt Không, Mẫu Thương, Tam Hợp, Thiên Hỉ, Thiên Y, Tục Thế, Kim Quĩ.",
        hungThan: " Nguyệt Yếm, Địa Hỏa, Tứ Kích, Đại Sát, Huyết Kị.",
        nen: " Cúng Tế, Nhập Học.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Phá Thổ.",
      },
      {
        catThan: " Nguyệt Đức Hợp, Lục Hợp, Ngũ Phú, Yếu An, Bảo Quang.",
        hungThan: "  Hà Khôi, Kiếp Sát, Địa Nang, Trùng Nhật.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đuổi Bắt, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Xuất Hành, Cắt Tóc , Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng , Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan:
          " Thiên Đức Hợp, Thiên Mã, Thời Dương, Sinh Khí, Bất Tương, Ngọc Vũ",
        hungThan: " Tai Sát, Thiên Hỏa, Tứ Hao, Bạch Hổ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Mở Kho Xuất Tiền Hàng, Chăn Nuôi, Nạp Gia Súc. ",
        khongNen:
          " Mời Thầy Chữa Bệnh, Lợp Mái, Gác Đòn Dông, Chặt Cây, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Mẫu Thương, Kim Đường, Ngọc Đường.",
        hungThan:
          "  Nguyệt Sát, Nguyệt Hư, Huyết Chi, Thiên Tặc, Ngũ Hư, Bát Chuyên.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Vương Nhật, Thiên Thương, Trừ Thần, Ô Phệ.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Phục Nhật, Ngũ Li, Bát Chuyên, Thiên Lao, Dương Thác.",
        nen: " Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Thu Nạp Người, Tắm Gội, Cắt May, Nạp Tài, Quét Dọn, Nạp Gia Súc.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đính Hôn, Ăn Hỏi, Cưới Gả, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Âm Đức, Quan Nhật, Cát Kì, Trừ Thần, Ô Phệ ",
        hungThan:
          " Đại Thời, Đại Bại, Hàm Trì, Cửu Khảm, Cửu Tiêu, Vãng Vong, Ngũ Li, Huyền Vũ.",
        nen: "  Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Quét Dọn, Phá Thổ, An Táng.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Rèn Đúc, Nấu Rượu, Lập Ước Giao Dịch, Lấp Hang Hố, Đuổi Bắt, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng.",
      },
      {
        catThan:
          " Nguyệt Đức, Mẫu Thương, Nguyệt Ân, Tứ Tướng, Dương Đức, Thủ Nhật, Thiên Vu, Phúc Đức, Lục Nghi, Kính An, Tư Mệnh.",
        hungThan: " Yếm Đối, Chiêu Dao, Thiên Cẩu, Cửu Không.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu  Nạp Người,  Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Lấp Hang Hố , Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen: " Cúng Tế, Khơi Mương, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Thiến Đức, Tứ Tướng, Tướng Nhật, Phổ Hộ.",
        hungThan:
          " Thiên Cương, Tử Thần, Nguyệt Hại, Du Họa, Ngũ Hư, Trùng Nhật, Câu Trần.",
        nen: " Cúng Tế, Tắm Gội, Sửa Tường, Sửa Đường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Cưới Gả, Giải Trừ, Mời Thầy Chữa Bệnh, Săn Bắn, Đánh Cá.",
      },
    ],
    [
      {
        catThan:
          " Nguyệt Không, Thiên Ân, Thời Đức, Dương Đức, Dân Nhật, Ngọc Vũ, Tư Mệnh.",
        hungThan: " Hà Khôi, Tử Thần, Thiên Lại, Chí Tử, Vãng Vong, ",
        nen: " Cúng Tế, Tắm Gội, Sửa Đường, Sửa Tường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đuổi Bắt, Săn Bắn, Sánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Thiên Ân, Mẫu Thương, Tam Hợp, Thời Âm, Kim Đường.",
        hungThan: " Tử Khí, Câu Trần.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc, An Táng",
        khongNen:
          " Đội Mũ Cài Trâm, Mời Thầy Chữa Bệnh, Săn Bắn, Đánh Cá, Gieo Trồng.",
      },
      {
        catThan: " Thiên Ân, Giải Thần, Ngũ Hợp, Thanh Long, Ô Phệ Đối.",
        hungThan: " Kiếp Sát, Tiểu Hao, Địa Nang, Qui Kị",
        nen: " Tắm Gội, Đuổi Bắt.",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Ân, Ngũ Hợp, Minh Đường, Ô Phệ Đối.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tai Sát, Thiên Hỏa, Nguyệt Yếm, Địa Hỏa, Ngũ Hư.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan: " Thiên Ân, Mẫu Thương, Lục Hợp, Bất Tương, Kính An.",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Tứ Kích, Thiên Hình",
        nen: " ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường.",
      },
      {
        catThan: " Tam Hợp, Lâm Nhật, Thiên Y, Phổ Hộ.",
        hungThan: " Trùng Nhật, Chu Tước",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Xuất Hành, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Nguyệt Đức, Phúc Sinh, Kim Quĩ, Ô Phệ.",
        hungThan:
          " Thiên Cương, Đại Thời, Đại Bại, Hàm Trì, Thiên Tặc, Cửu Khảm, Cửu Tiêu",
        nen: " Cúng Tế, Đuổi Bắt, ",
        khongNen:
          " Xuất Hành, Mời Thầy Chữa Bệnh, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Lắp Hang Hố, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng.",
      },
      {
        catThan:
          " Mẫu Thương, Âm Đức, Thời Dương, Sinh Khí, Thiên Thương, Bất Tương, Bảo Quang.",
        hungThan: " Ngũ Hư, Cửu Không, Thổ Phù, Phục Nhật.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Cưới Gả, Di Chuyển, Giải Trừ, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Chăn Nuôi, Nạp Gia Súc. ",
        khongNen:
          " Thu Nạp Người, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Tứ Tướng, Vương Nhật, Thiên Mã, Ngũ Phú, Bất Tương, Thánh Tâm, Trừ Thần, Ô Phệ.",
        hungThan: " Du Họa, Huyết Chi, Ngũ Li, Bạch Hổ.",
        nen: " Cúng Tế, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Đắp Đê, Sửa Kho, Đan Dệt, Nấu Rượu, Nạp Tài, Lấp Hang Hố, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương, Đào Giếng.",
      },
      {
        catThan:
          " Nguyệt Ân, Tứ Tướng, Quan Nhật, Lục Nghi, Ích Hậu, Trừ Thần, Ngọc Đường, Ô Phệ.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Nguyệt Hình, Yếm Đối, Chiêu Dao, Ngũ Li.",
        nen: " Cúng Tế, Tắm Gội, Quét Dọn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.  ",
      },
      {
        catThan: " Nguyệt Không, Mẫu Thương, Thủ Nhật, Cát Kì, Tục Thế.",
        hungThan: " Nguyệt Hại, Huyết Kị, Thiên Lao.",
        nen: " Cúng Tế, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Quét Dọn, Gieo Trồng.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đính Hôn, Ăn Hỏi, Cưới Gả Thu Nạp Người, Mời Thầy Chữa Bệnh, Châm Cứu, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Tướng Nhật, Dịch Mã, Thiên Hậu, Thiên Vu, Phúc Đức, Yếu An.",
        hungThan: " Ngũ Hư, Đại Sát, Trùng Nhật, Huyền Vũ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Lấp Hang Hố, Chăn Nuôi, Nạp Gia Súc. ",
        khongNen: " Cưới Gả, Săn Bắn, Đánh Cá, Gieo Trồng.",
      },
      {
        catThan: " Thời Đức, Dương Đức, Dân Nhật, Ngọc Vũ, Tư Mệnh, Ô Phệ Đối",
        hungThan:
          " Hà Khôi, Tử Thần, Thiên Lại, Chí Tử, Vãng Vong, Xúc Thủy Long.",
        nen: "  Cúng Tế, Tắm Gội, Sửa Tường, Sửa Đường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đuổi Bắt, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Mẫu Thương, Tam Hợp, Thời Âm, Kim Đường.",
        hungThan: " Tử Khí, Câu Trần.",
        nen: " Họp Thân Hữu, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Đội Mũ Cài Trâm, Giải Trừ, Cắt Tóc, Mời Thầy Chữa Bệnh, Xếp Đặt Buồng Đẻ, Gieo Trồng.",
      },
      {
        catThan: " Giải Thần, Ngũ Hợp, Thanh Long.",
        hungThan: " Kiếp Sát, Tiểu Hao, Qui Kị",
        nen: " Tắm Gội, Đuổi Bắt.",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Ân, Ngũ Hợp, Minh Đường.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tai Sát, Thiên Hỏa, Nguyệt Yếm, Địa Hỏa, Ngũ Hư, Âm Đạo Xung Dương.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan:
          " Nguyệt Đức, Thiên Ân, Mẫu Thương, Thiên Nguyện, Lục Hợp, Kính An.",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Tứ Kích, Thiên Hình.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng.   ",
        khongNen: "  ",
      },
      {
        catThan:
          " Thiên Ân, Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y, Bất Tương, Phổ Hộ.",
        hungThan: " Phục Nhật, Trùng Nhật, Chu Tước.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người,  Di Chuyển, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Xuất Hành, Nấu Rượu, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Thiên Ân, Tứ Tướng, Bất Tương, Phúc Sinh, Kim Quĩ, Ô Phệ.",
        hungThan:
          " Thiên Cương, Đại Thời, Đại Bại, Hàm Trì, Thiên Tặc, Cửu Khảm, Cửu Tiêu.",
        nen: " Cúng Tế, Đuổi Bắt.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Lấp Hang Hố, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Thiên Ân, Mẫu Thương, Nguyệt Ân, Tứ Tướng, Âm Đức, Thời Dương, Sinh Khí, Thiên Thương, Bất Tương, Bảo Quang.",
        hungThan: " Ngũ Hư, Cửu Không, Thổ Phù, Xúc Thủy Long.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Thu Nạp Người, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng, Phá Thổ. ",
      },
      {
        catThan:
          " Nguyệt Không, Vương Nhật, Thiên Mã, Ngũ Phú, Bất Tương, Thánh Tâm, Trừ Thần, Ô Phệ.",
        hungThan: " Du Họa, Huyết Chi, Ngũ Li, Bạch Hổ.",
        nen: " Cúng Tế, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Đắp Đê, Đan Dệt, Nấu Rượu, Nạp Tài, Lấp Hang Hố, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng. ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch,  Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng.",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Quan Nhật, Lục Nghi, Ích Hậu, Trừ Thần, Ngọc Đường, Ô Phệ ",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Nguyệt Hình, Yếm Đối, Chiêu Dao, Ngũ Li.",
        nen: " Cúng Tế, Tắm Gội, Quét Dọn.",
        khongNen:
          " Họp Thân Hữu, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ.  ",
      },
      {
        catThan: " Mẫu Thương, Thủ Nhật, Cát Kì, Tục Thế.",
        hungThan: " Nguyệt Hại, Huyết Kị, Thiên Lao.",
        nen: " Cúng Tế, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Quét Dọn, Gieo Trồng.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Mời Thầy Chữa Bệnh, Châm Cứu, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.  ",
      },
      {
        catThan: " Tướng Nhật, Dịch Mã, Thiên Hậu, Thiên Vu, Phúc Đức, Yếu An.",
        hungThan: " Ngũ Hư, Bát Phong, Đại Sát, Trùng Nhật, Huyền Vũ.",
        nen: " Cúng Tế, Cầu Phúc, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Thu Nạp Người, Di Chuyển, Tắm Gội, Cắt May, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Lấp Hang Hố.",
        khongNen:
          " Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Cắt Tóc , Mời Thầy Chữa Bệnh, Sửa Kho, Mở Kho Xuất Tiền Hàng, Đánh Cá, Đi Thuyền, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Thời Đức, Dương Đức, Dân Nhật, Ngọc Vũ, Tư Mệnh.",
        hungThan: " Hà Khôi, Tử Thần, Thiên Lại, Chí Tử, Vãng Vong.",
        nen: " Cúng Tế, Tắm Gội, Sửa Tường, Sửa Đường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Đào Giếng, Đuổi Bắt, Săn Bắn, Đánh Cá,  Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Mẫu Thương, Tam Hợp, Thời Âm, Kim Đường.",
        hungThan: " Tử Khí, Câu Trần",
        nen: " Họp Thân Hữu, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Đội Mũ Cài Trâm, Giải Trừ, Mời Thầy Chữa Bệnh, Xếp Đặt Buồng Đẻ, Gieo Trồng.",
      },
      {
        catThan: " Nguyệt Đức, Giải Thần, Ngũ Hợp, Thanh Long, Ô Phệ Đối.",
        hungThan: " Kiếp Sát, Tiểu Hao, Qui Kị",
        nen: " Tắm Gội, Đuổi Bắt.",
        khongNen:
          " Cúng Tế, Di Chuyển, Đi Xa Trở Về, Mời Thầy Chữa Bệnh, Sửa Kho, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Ngũ Hợp, Minh Đường, Ô Phệ Đối.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tai Sát, Thiên Hỏa, Nguyệt Yếm, Địa Hỏa, Ngũ Hư, Phục Nhật, Đại Hội.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan: " Mẫu Thương, Tứ Tướng, Lục Hợp, Bất Tương, Kính An.",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Tứ Kích, Thiên Hình",
        nen: " Cúng Tế.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Mời Thầy Chữa Bệnh, Khơi Mương.",
      },
      {
        catThan:
          " Nguyệt Ân, Tứ Tướng, Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y, Bất Tương, Phổ Hộ",
        hungThan: " Trùng Nhật, Chu Tước",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Xuất Hành, Phá Thổ, An Táng, Cải Táng",
      },
      {
        catThan: " Nguyệt Không, Bất Tương, Phúc Sinh, Kim Quĩ, Ô Phệ.",
        hungThan:
          " Thiên Cương, Đại Thời, Đại Bại, Hàm Trì, Thiên Tặc, Cửu Khảm, Cửu Tiêu.",
        nen: " Cúng Tế, Đuổi Bắt.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.   ",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Mẫu Thương, Âm Đức, Thời Dương, Sinh Khí, Thiên Thương, Bảo Quang.",
        hungThan: " Ngũ Hư, Cửu Không, Thổ Phù",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Khai Trương, Nhập Trạch, Nạp Tài, Chăn Nuôi, Nạp Gia Súc. ",
        khongNen:
          " Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan: " Vương Nhật, Thiên Mã, Ngũ Phú, Thánh Tâm, Trừ Thần, Ô Phệ.",
        hungThan: " Du Họa, Huyết Chi, Địa Nang, Ngũ Li, Bạch Hổ.",
        nen: " Cúng Tế, Tắm Gội, Cắt Tóc , Sửa Móng, Cắt May, Đan Dệt, Nấu Rượu, Nạp Tài, Quét Dọn, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan: " Quan Nhật, Lục Nghi, Ích Hậu, Trừ Thần, Ngọc Đường, Ô Phệ.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Nguyệt Hình, Yếm Đối, Chiêu Dao, Ngũ Li.",
        nen: " Cúng Tế, Tắm Gội, Quét Dọn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Mẫu Thương, Thủ Nhật, Cát Kì, Tục Thế.",
        hungThan: " Nguyệt Hại, Huyết Kị, Thiên Lao.",
        nen: " Cúng Tế, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Quét Dọn, Gieo Trồng.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Mời Thầy Chữa Bệnh, Châm Cứu, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Tướng Nhật, Dịch Mã, Thiên Hậu, Thiên Vu, Phúc Đức, Yếu An.",
        hungThan: " Ngũ Hư, Đại Sát, Trùng Nhật, Huyền Vũ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Thu Nạp Người, Di Chuyển, Tắm Gội, Cắt May, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Lấp Hang Hố.  ",
        khongNen:
          " Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Mời Thầy Chữa Bệnh, Sửa Kho, Mở Kho Xuất Tiền Hàng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Nguyệt Đức, Thời Đức, Dương Đức, Dân Nhật, Ngọc Vũ, Tư Mệnh, Ô Phệ Đối.",
        hungThan:
          " Hà Khôi, Tử Thần, Thiên Lại, Chí Tử, Tứ Kị, Cửu Hổ, Vãng Vong.",
        nen: " Cúng Tế, Tắm Gội, Sửa Tường, Sửa Đường.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Đan Dệt, Đuổi Bắt, Săn Bắn, Đánh Cá, An Táng.",
      },
      {
        catThan: " Mẫu Thương, Tam Hợp, Thời Âm, Kim Đường.",
        hungThan: " Tử Khí, Ngũ Mộ, Trùng Nhật, Cầu Trần.",
        nen: " Họp Thân Hữu, Cắt May, Sửa Kho, Đan Dệt, Nạp Tài, Đặt Cối Đá.",
        khongNen:
          " Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Xếp Đặt Buồng Đẻ, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Tứ Tướng, Giải Thần, Ngũ Hợp, Thanh Long, Ô Phệ Đối.",
        hungThan: " Kiếp Sát, Tiểu Hao, Qui Kị.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.   ",
        khongNen: " ",
      },
      {
        catThan: " Nguyệt Ân, Tứ Tướng, Ngũ Hợp, Minh Đường, Ô Phệ Đối.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tai Sát, Thiên Hỏa, Nguyệt Yếm, Địa Hỏa, Ngũ Hư.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan: " Nguyệt Không, Mẫu Thương, Lục Hợp, Bất Tương, Kính An.",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Tứ Kích, Thiên Hình.",
        nen: " ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường.",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y, Phổ Hộ",
        hungThan: " Trùng Nhật, Chu Tước.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc. ",
        khongNen: " Xuất Hành, Săn Bắn, Đánh Cá, Gieo Trồng.",
      },
      {
        catThan: " Phúc Sinh, Kim Quĩ, Ô Phệ.",
        hungThan:
          " Thiên Cương, Đại Thời, Đại Bại, Hàm Trì, Thiên Tặc, Cửu Khảm, Cửu Tiêu.",
        nen: " Cúng Tế, Đuổi Bắt.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Lấp Hang Hố, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Mẫu Thương, Âm Đức, Thời Dương, Sinh Khí, Thiên Thương, Bảo Quang.",
        hungThan: " Ngũ Hư, Bát Phong, Cửu Không, Thổ Phủ, Bát Chuyên.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Di Chuyển, Giải Trừ, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Cắt Tóc, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng, Phá Thổ.  ",
      },
      {
        catThan:
          " Thiên Xá, Vương Nhật, Thiên Mã, Ngũ Phú, Bất Tương, Thánh Tâm, Trừ Thần.",
        hungThan: " Du Họa, Huyết Chi, Ngũ Li, Bạch Hổ.",
        nen: " Cúng Tế, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Đắp Đê, Sửa Kho, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Lấp Hang Hố, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Thiên Ân, Quan Nhật, Lục Nghi, Ích Hậu, Trừ Thần, Ngọc Đường, Ô Phệ.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Nguyệt Hình, Yếm Đối, Chiêu Dao, Ngũ Li, Tiểu Hội.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan:
          " Nguyệt Đức, Thiên Ân, Mẫu Thương, Thủ Nhật, Cát Kì, Tục Thế.",
        hungThan: " Nguyệt Hại, Huyết Kị, Thiên Lao.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Nạp Tài, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen: " Mời Thầy Chữa Bệnh, Châm Cứu, Đan Dệt, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Thiên Ân, Tướng Nhật, Thiên Hậu, Thiên Vu, Phúc Đức, Yếu An.",
        hungThan:
          " Tứ Cùng, Cửu Hổ, Ngũ Hư, Đại Sát, Phục Nhật, Trùng Nhật, Huyền Vũ.",
        nen: " Cúng Tế, Cầu Phúc, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Di Chuyển, Tắm Gội, Cắt May, Đan Dệt, Lấp Hang Hố.",
        khongNen:
          " Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Mời Thầy Chữa Bệnh, Sửa Kho, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Phá Thổ, An Táng, Cải Táng.  ",
      },
      {
        catThan:
          " Thiên Ân, Tứ Tướng, Thời Đức, Dương Đức, Dân Nhật, Ngọc Vũ, Tư Mệnh, Ô Phệ Đối.",
        hungThan: " Hà Khôi, Tử Thần, Thiên Lại, Chí Tử, Vãng Vong.",
        nen: " Cúng Tế, Tắm Gội, Sửa Tường, Sửa Đường.	",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Thiên Ân, Mẫu Thương, Nguyệt Ân, Tứ Tương, Tam Hợp, Thời Âm, Kim Đường.",
        hungThan: " Tử Khí, Bát Chuyên, Xúc Thủy Long, Câu Trần.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Thu Nạp Người, Di Chuyển, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Lước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc.  ",
        khongNen:
          " Đội Mũ Cài Trâm, Đính Hôn, Ăn Hỏi, Cưới Gả, Giải Trừ, Mời Thầy Chữa Bệnh, Xếp Đặt Buồng Đẻ, Đánh Cá, Đi Thuyền, Gieo Trồng.",
      },
      {
        catThan: " Nguyệt Không, Giải Thần, Ngũ Hợp, Thanh Long, Ô Phệ Đối.",
        hungThan: " Kiếp Sát, Tiểu Hao, Tứ Phế, Qui Kị, Bát Chuyên.",
        nen: " Tắm Gội, Đuổi Bắt.",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Nguyệt Đức Hợp, Ngũ Hợp, Minh Đường, Ô Phệ Đối.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tai Sát, Thiên Hỏa, Nguyệt Yếm, Địa Hỏa, Tứ Phế, Ngũ Hư, Âm Thác",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Mẫu Thương, Lục Hợp, Kính An.",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Tứ Kích, Thiên Hình.",
        nen: " ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Giải Trừ, Cắt Tóc, Smóng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường.",
      },
      {
        catThan: " Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y, Phổ Hộ",
        hungThan: " Trùng Nhật, Chu Tước.",
        nen: " Cúng Tế, Cầu Phúc, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Xúc.",
        khongNen: " Xuất Hành, Cắt Tóc, Phá Thổ, An Tang, Cải Táng  ",
      },
      {
        catThan: " Bất Tương, Phúc Sinh, Kim Quĩ",
        hungThan:
          " Thiên Cương, Đại Thời, Đại Bại, Hàm Trì, Thiên Tặc, Tứ Hao, Cửu Khảm, Cửu Tiêu.",
        nen: " Cúng Tế, Đuổi Bắt.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.   ",
      },
      {
        catThan:
          " Mẫu Thương, Âm Đức, Thời Dương, Sinh Khí, Thiên Thương, Bảo Quang.",
        hungThan: " Ngũ Hư, Cửu Không, Thổ Phù, Bát Chuyên.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Di Chuyển, Giải Trừ, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Đính Hôn, Awn Hỏi, Cưới Gả, Thu Nạp Người, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan:
          " Nguyệt Đức, Vương Nhật, Thiên Mã, Ngũ Phú, Thánh Tâm, Trừ Thần, Ô Phệ",
        hungThan: " Du Họa, Huyết Chi, Ngũ Li, Bát Chuyên, Bạch Hổ.",
        nen: " Cúng Tế, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Đắp Đê, Sửa Kho, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Lấp Hang Hố, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng.,  ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Đính Hôn, Ăn Hỏi, Cưới Gả, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Đan Dệt, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Quan Nhật, Lục Nghi, Ích Hậu, Trừ Thần, Ngọc Đường, Ô Phệ.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Nguyệt Hình, Yếm Đối, Chiêu Dao, Phục Nhật, Ngũ Li, Dương Thác.",
        nen: " Cúng Tế, Tắm Gội, Quét Dọn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Mẫu Thương, Tứ Tướng, Thủ Nhật, Cát Kì, Tục Thế, ",
        hungThan: " Nguyệt Hại, Huyết Kị, Thiên Lao.",
        nen: " Cúng Tế, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Quét Dọn, Gieo Trồng.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Mời Thầy Chữa Bệnh, Châm Cứu, Sửa Kho, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.  ",
      },
      {
        catThan:
          " Nguyệt Ân, Tứ Tướng, Tướng Nhật, Dịch Mã, Thiên Hậu, Thiên Vu, Phúc Đức, Yếu An, ",
        hungThan: " Ngũ Hư, Đại Sát, Trùng Nhật, Huyền Vũ.",
        nen: " Cúng Tế, Giải Trừ, Tắm Gội.",
        khongNen:
          " Cưới Gả, Sửa Kho, Mở Kho Xuất Tiền Hàng, Phá Thổ, An Táng, Cải Táng.",
      },
    ],
    [
      {
        catThan: " Thiên Ân, Thời Đức, Dân Nhật, Thiên Phú, Phúc Đức, Phổ Hộ.",
        hungThan: " Tai Sát, Thiên Hỏa, Đại Sát, Qui Kị, Thiên Lao",
        nen: " Cúng Tế, Tắm Gội",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương, Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Tang",
      },
      {
        catThan: " Thiên Ân, Mẫu Thương, Phúc Sinh",
        hungThan: " Thiên Cương, Tử Thần, Nguyệt Sát, Nguyệt Hư, Huyền Vũ",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan:
          " Thiên Đức, Nguyệt Đức, Thiên Ân, Dương Đức, Tam Hợp, Lâm Nhật, Ngũ Hợp, Tư Mệnh, Ô Phệ Đối.",
        hungThan: " Nguyệt Yếm, Địa Hỏa, Tử Khí, Cửu Khảm, Cửa Tiêu, Cô Thần",
        nen: " ",
        khongNen:
          " Cúng Tế, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Đi Xa Trở Về, Mời Thầy Chữa Bệnh, Rèn Đúc, Lấp Hang Hố, Chặt Cây, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng.  ",
      },
      {
        catThan: " Thiên Ân, Lục Hợp, Thánh Tâm, Ngũ Hợp, Ô Phệ Đối",
        hungThan: " Đại Thời, Đại Bại, Hàm Trì, Tiểu Hao, Ngũ Hư, Câu Trần",
        nen: " Cúng Tế, Cầu Phúc, Họp Thân Hữu, Đính Hôn, Cưới Gả, Thu Nạp Người, Đan Dệt, Nấu Rượu, Đuổi Bắn, Săn Bắn, Nạp Gia Súc, Phá Thổ, An Tang, Cải Tang.",
        khongNen:
          " Cắt Tóc, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho, Xuất Tiền Hang, Đào Giếng.",
      },
      {
        catThan:
          " Thiên Ân, Mẫu Thương, Bất Tương, Ích Hậu, Giải Thần, Thanh Long",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tứ Kích, Ngũ Mộ, Cửu Không, Vãng Vong, Phục Nhật",
        nen: " Cúng Tế, Tắm Gội, Dỡ Nhà, Phá Tường",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương, Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Chặt Cây, Đuổi Bắn, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.  ",
      },
      {
        catThan: " Âm Đức, Tục Thế, Minh Đường",
        hungThan: " Du Họa, Thiên Tặc, Huyết Kị, Trùng Nhật",
        nen: " Cúng Tế, Kê Giường, Săn Bắn",
        khongNen:
          " Cầu Phúc, Cầu Tự, Xuất Hành, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Châm Cứu, Sửa Kho, Mở Kho Xuất Tiền Hàng, Phá Thổ, An Táng, Cải Táng.  ",
      },
      {
        catThan:
          " Cát Thần, Nguyệt Ân, Tam Hợp, Thiên Hỉ, Thiên Y, Thiên Thương, Bất Tương, Yếu An, Ô Phệ",
        hungThan: " Thiên Hình",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Nhập Học, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hang, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Tang, Cải Táng.",
        khongNen: " Lợp Mái, Gác Đòn Dông, Đan Dệt",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Đức Hợp, Mẫu Thương, Bất Tương, Ngọc Vũ",
        hungThan: " Hà Khôi, Nguyệt Hình, Ngũ Hư, Địa Nang, Chu Tước.",
        nen: " Cúng Tế, Đuổi Bắt.",
        khongNen:
          " Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Nấu Rượu, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan:
          " Nguyệt Không, Tứ Tướng, Vương Nhật, Dịch Mã, Thiên Hậu, Thời Dương, Sinh Khí, Lục Nghi, Kim Đường, Trừ Thần, Kim Quĩ, Ô Phệ.",
        hungThan: " Yếm Đối, Chiêu Dao, Ngũ Li.",
        nen: " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Khai Trương, Nhập Trạch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Đặt Cối Đá, Quét Dọn, Gieo Trồng, Chăn Nuôi.",
        khongNen:
          " Họp Thân Hữu, Đính Hôn, Ăn Hỏi, Cưới Gả, Kê Gường, Lập Ước Giao Dịch, Khơi Mương, Chặt Cây, Săn Bắn, Đánh Cá, Đi Thuyền.",
      },
      {
        catThan: " Tứ Tướng, Quan Nhật, Trừ Thần, Bảo Quang, Ô Phệ",
        hungThan: " Nguyệt Hại, Thiên Lại, Chí Tử, Huyết Chi, Ngũ Li.",
        nen: " Cúng Tế, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Lấp Hang Hố, Quét Dọn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Mẫu Thương, Thủ Nhật, Thiên Mã.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Bạch Hổ, Âm Vị.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Tướng Nhật, Cát Kì, Ngũ Phú, Kính An, Ngọc Đường.",
        hungThan: " Kiếp Sát, Ngũ Hư, Thổ Phù, Trùng Nhật.",
        nen: " Tắm Gội, Quét Dọn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Thiên Đức, Nguyệt Đức, Thời Đức, Dân Nhật, Thiên Vu, Phúc Đức, Phổ Hộ, Ô Phệ.",
        hungThan:
          " Tai Sát, Thiên Hỏa, Đại Sát, Qui Kị, Xúc Thủy Long, Thiên Lao.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Tắm Gội, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Lấp Hang Hố, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
        khongNen:
          " Di Chuyển, Đi Xa Trở Về, Mời Thầy Chữa Bệnh, Săn Bắn, Đánh Cá, Đi Thuyền.",
      },
      {
        catThan: " Mẫu Thương, Phúc Sinh.",
        hungThan: " Thiên Cương, Tử Thần, Nguyệt Sát, Nguyệt Hư, Huyền Vũ.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan: " Dương Đức, Tam Hợp, Lâm Nhật, Thời Âm, Ngũ Hư, Tư Mệnh.",
        hungThan:
          " Nguyệt Yếm, Địa Hỏa, Tử Khí, Cửu Khảm, Cửu Tiêu, Phục Nhật, Cô Thần.",
        nen: " ",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Thiên Ân, Lục Hợp, Thánh Tâm, Ngũ Hợp.",
        hungThan: " Đại Thời, Đại Bại, Hàm Trì, Tiểu Hao, Ngũ Hư, Câu Trần.",
        nen: " Cúng Tế, Cầu Phúc, Họp Thân Hữu, Đính Hôn, Cưới Gả, Thu Nạp Người, Đan Dệt, Nấu Rượu, Đuổi Bắt, Săn Bắn, Nạp Gia Súc, An Táng.",
        khongNen:
          " Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đào Giếng.",
      },
      {
        catThan:
          " Thiên Ân, Mẫu Thương, Nguyệt Ân, Bất Tương, Ích Hậu, Giải Thần, Thanh Long.",
        hungThan: " Nguyệt Phá, Đại Hao, Tứ Kích, Cửu Không, Vãng Vong.",
        nen: " Cúng Tế, Giải Trừ, Tắm Gội, Dỡ Nhà Phá Tường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.   ",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Đức Hợp, Thiên Ân, Âm Đức, Bất Tương, Tục Thế, Minh Đường.",
        hungThan: " Du Họa, Thiên Tặc, Huyết Kị, Trùng Nhật.",
        nen: " Cúng Tế, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Kê Giường, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc. ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Giải Trừ, Mời Thầy Chữa Bệnh, Châm Cứu, Sửa Kho, Nấu Rượu, Mở Kho Xuất Tiền Hàng, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Nguyệt Không, Thiên Ân, Tứ Tướng, Tam Hợp, Thiên Hỉ, Thiên Y, Thiên Thương, Yếu An, Ô Phệ.",
        hungThan: " Thiên Hình.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Họp Thân Hữu, Lên Quan Nhậm Chức, Gặp Dân, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng.  ",
        khongNen: " Lợp Mái, Gác Đòn Dông, Đào Giếng.",
      },
      {
        catThan: " Thiên Ân, Mẫu Thương, Tứ Tướng, Bất Tương, Ngọc Vũ.",
        hungThan: " Hà Khôi, Nguyệt Hình, Ngũ Li, Xúc Thủy Long, Chu Tước.",
        nen: " Cúng Tế, Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Đánh Cá, Đi Thuyền, Gieo Trồng, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Vương Nhật, Dịch Mã, Thiên Hậu, Thời Dương, Sinh Khí, Lục Nghi, Kim Đường, Trừ Thần, Kim Quĩ, Ô Phệ",
        hungThan: " Yếm Đối, Chiêu Dao, Ngũ Li ",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Khai Trương, Nhập Trạch, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Quét Dọn, Gieo Trồng, Chăn Nuôi.   ",
        khongNen:
          " Họp Thân Hữu, Đính Hôn, Ăn Hỏi, Cưới Gả, Kê Giường, Lập Ước Giao Dịch, Mở Kho Xuất Tiền Hàng, Chặt Cây, Săn Bắn, Đánh Cá, Đi Thuyền.",
      },
      {
        catThan: " Quan Nhật, Trừ Thần, Bảo Quang, Ô Phệ.",
        hungThan: " Nguyệt Hại, Thiên Lại, Chí Tử, Huyết Kị, Ngũ Li.",
        nen: " Tắm Gội, Cắt Tóc, Sửa Móng, Lấp Hang Hố, Quét Dọn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Đức, Nguyệt Đức, Mẫu Thương, Thủ Nhật, Thiên Mã.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Bạch Hổ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Nạp Tài, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan: " Tướng Nhật, Cát Kì, Ngũ Phú, Kính An, Ngọc Đường.",
        hungThan: " Kiếp Sát, Ngũ Hư, Bát Phong, Thổ Phù, Trùng Nhật.",
        nen: " Tắm Gội, Quét Dọn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Đánh Cá, Đi Thuyền, Gieo Trồng, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thời Đức, Dân Nhật, Thiên Vu, Phúc Đức, Phổ Hộ.",
        hungThan: " Tai Sát, Thiên Hỏa, Đại Sát, Qui Kị, Phục Nhật, Thiên Lao.",
        nen: " Cúng Tế, Tắm Gội.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.    ",
      },
      {
        catThan: " Mẫu Thương, Phúc Sinh.",
        hungThan: " Thiên Cương, Tử Thần, Nguyệt Sát, Nguyệt Hư, Huyền Vũ.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan:
          " Nguyệt Ân, Dương Đức, Tam Hợp, Lâm Nhật, Thời Âm, Ngũ Hợp, Tư Mệnh, Ô Phệ Đối.",
        hungThan:
          " Nguyệt Yếm, Địa Hỏa, Tử Khí, Cửu Khảm, Cửu Tiêu, Hành Ngận.",
        nen: " ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Đức Hợp, Thiên Nguyện, Lục Hợp, Bất Tương, Thánh Tâm, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Đại Thời, Đại Bại, Hàm Trì, Tiểu Hao, Ngũ Hư, Câu Trần.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đuổi Bắt, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.  ",
        khongNen: " ",
      },
      {
        catThan:
          " Nguyệt Không, Mẫu Thương, Tứ Tướng, Bất Tương, Ích Hậu, Giải Thần, Thanh Long.",
        hungThan: " Nguyệt Phá, Đại Hao, Tứ Kích, Cửu Không, Vãng Vong.",
        nen: " Cúng Tế, Giải Trừ, Tắm Gội, Dỡ Nhà Phá Tường. ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.   ",
      },
      {
        catThan: " Tứ Tướng, Âm Đức, Bất Tương, Tục Thế, Minh Đường, ",
        hungThan: " Du Họa, Thiên Tặc, Huyết Kị, Trùng Nhật.",
        nen: " Cúng Tế, Họp Thân Hữu, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Kê Giường, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Nạp Tài, Săn Bắn, Gieo Trồng, Chăn Nuôi.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Xuất Hành, Giải Trừ, Mời Thầy Chữa Bệnh, Châm Cứu, Sửa Kho, Mở Kho Xuất Tiền Hàng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Tam Hợp, Thiên Hỉ, Thiên Y, Thiên Thương, Yếu An, Ô Phệ.",
        hungThan: " Thiên Hình.",
        nen: " Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Nạp Tài, Đặt Cối Đá, Nạp Gia Súc, Phá Thổ, An Táng.",
        khongNen: " Lợp Mái, Gác Đòn Dông, Mở Kho Xuất Tiền Hàng.",
      },
      {
        catThan: " Mẫu Thương, Ngọc Vũ",
        hungThan: " Hà Khôi, Nguyệt Hình, Ngũ Hư, Chu Tước.",
        nen: " Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Thiên Đức, Nguyệt Đức, Vương Nhật, Dịch Mã, Thiên Hậu, Thời Dương, Sinh Khí, Kim Đường, Trừ Thần, Kim Quĩ, Ô Phệ.",
        hungThan: " Yếm Đối, Chiêu Dao, Ngũ Li",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.  ",
        khongNen: " Kê Giường, Chặt Cây, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Quan Nhật, Trừ Thần, Bảo Quang, Ô Phệ.",
        hungThan: " Nguyệt Hai, Thiên Lại, Chí Tử, Huyết Chi, Ngũ Li.",
        nen: " Tắm Gội, Sửa Móng, Lấp Hang Hố, Quét Dọn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Mẫu Thương, Thủ Nhật, Thiên Mã",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Phục Nhật, Bạch Hổ, Tiểu Hội, Cô Dương.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Tướng Nhật, Cát Kì, Ngũ Phú, Kính An, Ngọc Đường",
        hungThan: " Kiếp Sát, Ngũ Hư, Thổ Phù, Trùng Nhật.",
        nen: " Tắm Gội, Quét Dọn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.   ",
      },
      {
        catThan:
          " Nguyệt Ân, Thời Đức, Dân Nhật, Thiên Vu, Phúc Đức, Phổ Hộ, Ô Phệ Đối",
        hungThan:
          " Tai Sát, Thiên Hỏa, Tứ Kị, Cửu Hổ, Đại Sát, Qui Kị, Thiên Lao.",
        nen: " Cúng Tế, Tắm Gội.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Vưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Thiên Đức Hợp, Nguyệt Đức Hợp, Mẫu Thương, Phúc Sinh.",
        hungThan:
          " Thiên Cương, Tử Thần, Nguyệt Sát, Nguyệt Hư, Địa Nang, Huyền Vũ.",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Nguyệt Không, Tứ Tướng, Dương Đức, Tam Hợp, Lâm Nhật, Thời Âm, Ngũ Hợp, Tư Mệnh, Ô Phệ Đối.",
        hungThan: " Nguyệt Yếm, Địa Hỏa, Tử Khí, Cửu Khảm, Cửu Tiêu, Liễu Lệ.",
        nen: " ",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Tứ Tướng, Lục Hợp, Bất Tương, Thánh Tâm, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Đại Thời, Đại Bại, Hàm Trì, Tiểu Hao, Ngũ Hư, Câu Trần",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Dính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Nấu Rượu, Đuổi Bắt, Săn Bắn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
        khongNen:
          " Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đào Giếng.",
      },
      {
        catThan: " Mẫu Thương, Ích Hậu, Giải Thần, Thanh Long, ",
        hungThan: " Nguyệt Phá, Đại Hao, Tứ Kích, Cửu Không, Vãng Vong.",
        nen: " Cúng Tế, Giải Trừ, Tắm Gội, Dỡ Nhà Phá Tường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.  ",
      },
      {
        catThan: " Âm Đức, Tục Thế, Minh Đường.",
        hungThan: " Du Họa, Thiên Tặc, Huyết Kị, Trùng Nhật.",
        nen: " Cúng Tế, Kê Giường, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Xuất Hành, Giải Trừ, Mời Thầy Chữa Bệnh, Châm Cứu, Sửa Kho, Mở Kho Xuất Tiền Hàng, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Thiên Đức, Nguyệt Đức, Tam Hợp, Thiên Hỉ, Thiên Y, Thiên Thương, Yếu An, Ô Phệ.",
        hungThan: " Thiên Hình.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng. ",
        khongNen: " Lợp Mái, Gác Đòn Dông, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Mẫu Thương, Ngọc Vũ.",
        hungThan:
          " Hà Khôi, Nguyệt Hình, Ngũ Hư, Bát Phong, Bát Chuyên, Chu Tước.",
        nen: " Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Thiên Xá, Vương Nhật, Dịch Mã, Thiên Hậu, Thời Dương, Sinh Khí, Lục Nghi, Kim Đường, Trừ Thần, Kim Quĩ.",
        hungThan: " Yếm Đối, Chiêu Dao, Phục Nhật, Ngũ Li.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc. ",
        khongNen: " Kê Giường, Chặt Cây, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Thiên Ân, Quan Nhật, Trừ Thần, Bảo Quang, Ô Phệ.",
        hungThan: " Nguyệt Hại, Thiên Lại, Chí Tử, Huyết Chi, Ngũ Li.",
        nen: " Tắm Gội, Cắt Tóc, Sửa Móng, Lấp Hang Hố, Quét Dọn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.   ",
      },
      {
        catThan: " Thiên Ân, Mẫu Thương, Nguyệt Ân, Thủ Nhật, Thiên Mã.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Bạch Hổ, Dương Thác.",
        nen: " Cúng Tế, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Di Chuyển, Cắt May, Nạp Tài, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Đính Hôn, Ăn Hỏi, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch Lập Ước Giao Dịch, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Đức Hợp, Thiên Ân, Tướng Nhật, Cát Kì, Ngũ Phú, Kính An, Ngọc Đường.",
        hungThan: " Kiếp Sát, Tứ Cùng, Cửu Vĩ, Ngũ Hư, Thổ Phù, Trùng Nhật.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Di Chuyển, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Quét Dọn, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Không, Thiên Ân, Tứ Tướng, Thời Đức, Dân Nhật, Thiên Vu, Phúc Đức, Thổ Hộ, Ô Phệ Đối.",
        hungThan: " Tai Sát, Thiên Hỏa, Đại Sát, Qui Kị, Thiên Lao.",
        nen: " Cúng Tế, Tắm Gội.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Ân, Mẫu Thương, Tứ Tướng, Phúc Sinh.",
        hungThan:
          " Thiên Cương, Tử Thần, Nguyệt Sát, Nguyệt Hư, Bát Chuyên, Xúc Thủy Long, Huyền Vũ.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan:
          " Dương Đức, Tam Hợp, Lâm Nhật, Thời Âm, Ngũ Hợp, Tư Mệnh, Ô Phệ Đối.",
        hungThan:
          " Nguyệt Yếm, Địa Hỏa, Tử Khí, Tứ Phế, Cửu Khảm, Cửu Tiêu, Bát Chuyên, Cô Thần, Âm Thác.",
        nen: " ",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Lục Hợp, Thánh Tâm, Ngũ Hợp, Ô Phệ Đối.",
        hungThan:
          " Đại Thời, Đại Bại, Hàm Trì, Tiểu Hao, Tứ Phế, Ngũ Hư, Câu Trần.",
        nen: " Cúng Tế, Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Thiên Đức, Nguyệt Đức, Mẫu Thương, Ích Hậu, Giải Thần, Thanh Long",
        hungThan: " Nguyệt Phá, Đại Hao, Tứ Kích, Cửu Không, Vãng Vong.",
        nen: " Cúng Tế, Giải Trừ, Tắm Gội, Dỡ Nhà Phá Tường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.   ",
      },
      {
        catThan: " Âm Đức, Tục Thế, Minh Đường.",
        hungThan: " Du Họa, Thiên Tặc, Huyết Kị, Trùng Nhật.",
        nen: " Cúng Tế, Kê Giường, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Xuất Hành, Giải Trừ, Cắt Tóc, Mời Thầy Chữa Bệnh, Châm Cứu, Sửa Kho, Mở Kho Xuất Tiền Hàng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Tam Hợp, Thiên Hỉ, Thiên Y, Thiên Thương, Bất Tương, Yếu An. ",
        hungThan: " Tứ Hao, Phục Nhật, Thiên Hình",
        nen: " Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Nạp Gia Súc.",
        khongNen: " Lợp Mái, Gác Đòn Dông, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Mẫu Thương, Ngọc Vũ",
        hungThan: " Hà Khôi, Nguyệt Hình, Ngũ Hư, Bát Chuyên, Chu Tước.",
        nen: " Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường,  Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Ân, Vương Nhật, Dịch Mã, Thiên Hậu, Thời Dương, Sinh Khí, Lục Nghi, Kim Đường, Trừ Thần, Kim Quĩ, Ô Phệ.",
        hungThan: " Yếm Đối, Chiêu Dao, Ngũ Li, Bát Chuyên.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Khai Trương, Nhập Trạch, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
        khongNen:
          " Họp Thân Hữu, Đính Hôn, Ăn Hỏi, Cưới Gả, Kê Giường, Giải Trừ, Đan Dệt, Lập Ước Giao Dịch, Chặt Cây, Săn Bắn, Đánh Cá, Đi Thuyền.",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Đức Hợp, Quan Nhật, Trừ Thần, Bảo Quang, Ô Phệ.",
        hungThan: " Nguyệt Hại, Thiên Lại, Chí Tử, Huyết Chi, Ngũ Li.",
        nen: " Cúng Tế, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Lấp Hang Hố, Quét Dọn.",
        khongNen:
          " Họp Thân Hữu, Mời Thầy Chữa Bệnh, Chữa Mắt, Nấu Rượu, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Nguyệt Không, Mẫu Thương, Tứ Tướng, Thủ Nhật, Thiên Mã.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Bạch Hổ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Nạp Tài, Mở Kho Xuất Tiền Hàng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan: " Tứ Tướng, Tướng Nhật, Cát Kì, Ngũ Phú, Kính An, Ngọc Đường.",
        hungThan: " Kiếp Sát, Ngũ Hư, Thổ Phù, Trùng Nhật.",
        nen: " Cúng Tế, Tắm Gội, Quét Dọn.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Cưới Gả, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Phá Thổ, An Táng, Cải Táng.",
      },
    ],
    [
      {
        catThan:
          " Nguyệt Đức, Thiên Ân, Thiên Xá, Tứ Tướng, Quan Nhật, Thiên Mã, Cát Kì, Yếu An.",
        hungThan: "  Đại Thời, Đại Bại, Hàm Trì, Bạch Hổ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Nạp Tài , Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng. ",
        khongNen: " ",
      },
      {
        catThan:
          " Thiên Đức, Thiên Ân, Nguyệt Ân, Tứ Tướng, Thủ Nhật, Thiên Vu, Phúc Đức, Ngọc Vũ, Ngọc Đường.",
        hungThan: " Nguyệt Yếm, Địa Hỏa, Cửu Không, Đại Sát, Qui Kị, Cô Thần",
        nen: " Cúng Tế.",
        khongNen:
          " Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Đi Xa Trở Về, Mời Thầy Chữa Bệnh, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng.",
      },
      {
        catThan:
          " Thiên Ân, Thời Đức, Tướng Nhật, Lục Hợp, Ngũ Phú, Kim Đường, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Hà Khôi, Tử Thần, Du Họa, Ngũ Hư, Thiên Lao. ",
        nen: " Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Sửa Tường, Sửa Đường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
        khongNen: " Cúng Tế, Cầu Phúc , Cầu Tự, Giải Trừ, Mời Thầy Chữa Bệnh.",
      },
      {
        catThan:
          " Thiên Ân, Âm Đức, Dân Nhật, Tam Hợp, Thời Đức, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: "  Tử Khí, Huyền Vũ.",
        nen: " Họp Thân Hữu, Đội Mũ, Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Sửa Tường, Sửa Đường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, Cải Táng. ",
        khongNen:
          " Giải Trừ, Cắt Tóc , Mời Thầy Chữa Bệnh, Xếp Đặt Buồng Đẻ, Đào Giếng, Gieo Trồng.",
      },
      {
        catThan: " Thiên Ân, Dương Đức, Giải Thần, Tư Mệnh.",
        hungThan: " Tiểu Hao, Thiên Tặc, Thổ Phù",
        nen: " Dâng Biểu Sớ, Họp Thân Hữu, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Xuất Hành, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Dịch Mã, Thiên Hậu, Thiên Thương, Bất Tương, Kính An.",
        hungThan: "  Nguyệt Phá, Đại Hao, Trùng Nhật, Câu Trần.",
        nen: " Cúng Tế, Giải Trừ,Mời Thầy Chữa Bệnh, Dỡ Nhà Phá Tường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường,  Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Không, Bất Tương, Phổ Hộ, Thanh Long, Ô Phệ.",
        hungThan: "  Thiên Lại, Chí Tử, Ngũ Hư",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Kê Giường, Cắt May, Giải Trừ, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Chặt Cây, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng. ",
        khongNen:
          " Mời Thầy Chữa Bệnh, Lợp Mái, Gác Đòn Dông, Đan Dệt, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y, Lục Nghi, Phúc Sinh, Minh Đường.",
        hungThan: "  Yếm Đối, Chiêu Dao, Tứ Kích, Vãng Vong.",
        nen: " Cúng Tế, Cầu Phúc, Họp Thân Hữu, Nhập Học, Đính Hôn, Ăn Hỏi, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Đặt Cối Đá, Nạp Gia Súc.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Nấu Rượu, Đuổi Bắt, Săn Bắn, Đánh Cá, Đi Thuyền.",
      },
      {
        catThan: " Mẫu Thương, Trừ Thần, Ô Phệ.",
        hungThan:
          " Thiên Cương, Kiếp Sát, Nguyệt Hại, Phục Nhật, Ngũ Li, Thiên Hình",
        nen: " Tắm Gội, Quét Dọn, Chặt Cây, Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường,  Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Mẫu Thương, Thời Dương, Sinh Khí, Thánh Tâm, Trừ Thần, Ô Phệ.",
        hungThan: " Tai Sát, Thiên Hỏa, Ngũ Li, Chu Tước.",
        nen: " Cúng Tế, Nhập Học, Tắm Gội, Quét Dọn.",
        khongNen:
          " Họp Thân Hữu, Đội Mũ Cài Trâm, Đính Hôn, Ăn Hỏi, Cưới Gả, Mời Thầy Chữa Bệnh, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Chặt Cây, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Nguyệt Đức, Tứ Tướng, Ích Hậu, Kim Quĩ",
        hungThan: "  Nguyệt Sát, Nguyệt Hư, Huyết Chi, Ngũ Hư, Bát Phong",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Thiên Đức, Nguyệt Ân, Tứ Tướng, Vương Nhật, Tục Thế, Bảo Quang.",
        hungThan:
          "  Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Nguyệt Hình, Cửu Khảm, Cửu Tiêu, Huyết Chi, Trùng Nhật.",
        nen: " Cúng Tế, Tắm Gội.",
        khongNen:
          " Đội Mũ Cài Trâm, Cưới Gả, Mời Thầy Chữa Bệnh, Châm Cứu, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Rèn Đúc, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường,  Chặt Cây, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan: " Quan Nhật, Thiên Mã, Cát Kì, Yếu An, Ô Phệ Đối.",
        hungThan: " Đại Thời, Đại Bại, Hàm Trì, Xúc Thủy Long, Bạch Hổ.",
        nen: " Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc , Sửa Móng, Mời Thầy Chữa Bệnh, Quét Dọn, Phá Thổ, Cải Táng.",
        khongNen: " Đánh Cá, Đi Thuyền.",
      },
      {
        catThan: " Thủ Nhật, Thiên Vu, Phúc Đức, Ngọc Vũ, Ngọc Đường",
        hungThan: "  Nguyệt Yếm, Địa Hỏa, Cửu Không, Đại Sát, Qui Kị, Cô Thần.",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường,  Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Thời Đức, Tướng Nhật, Lục Hợp, Ngũ Phú, Kim Đường, Ngũ Hợp",
        hungThan: " Hà Khôi, Tử Thần, Du Họa, Ngũ Hư, Địa Nang, Thiên Lao.",
        nen: " Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.  ",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo, Động Thổ, Sửa Kho, Xếp Đặt Buồng Đẻ, Khơi Mương, Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Thiên Ân, Âm Đức, Dân Nhật, Tam Hợp, Thời Âm, Bất Tương, Ngũ Hợp.",
        hungThan: "  Tử Khí, Huyền Vũ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng.  ",
        khongNen: " Mời Thầy Chữa Bệnh, Đào Giếng, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Không, Thiên Ân, Dương Đức, Bất Tương, Giải Thần, Tư Mệnh.",
        hungThan: " Tiểu Hao, Thiên Tặc, Thổ Phù.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đuổi Bắt, Chăn Nuôi, Nạp Gia Súc, An Táng. ",
        khongNen:
          " Xuất Hành, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho , Đan Dệt, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan:
          " Thiên Ân, Dịch Mã, Thiên Hậu, Thiên Thương, Bất Tương, Kính An.",
        hungThan: "  Nguyệt Phá, Đại Hao, Trùng Nhật, Câu Trần",
        nen: " Mời Thầy Chữa Bệnh, Dỡ Nhà Phá Tường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Chặt Cây, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Ân, Bất Tương, Phổ Hộ, Thanh Long, Ô Phệ.",
        hungThan: " Thiên Lại, Chí Tử, Ngũ Hư, Phục Nhật.",
        nen: " Cúng Tế, Họp Thân Hữu, Cắt May, Chặt Cây, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Nhà, Sửa Kho, Lợp Mái, Gác Đòn Dông, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ,  Khơi Mương Đào Giếng, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Thiên Ân, Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y, Lục Nghi, Phúc Sinh, Minh Đường.",
        hungThan: " Yếm Đối, Chiêu Dao, Tứ Kích, Vãng Vong, Xúc Thủy Long.",
        nen: " Cúng Tế, Cầu Phúc, Họp Thân Hữu, Nhập Học, Đính Hôn, Ăn Hỏi, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Nạp Gia Súc.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Đuổi Bắt, Săn Bắn, Đánh Cá, Đi Thuyền.",
      },
      {
        catThan: " Nguyệt Đức, Mẫu Thương, Tứ Tướng, Trừ Thần, Ô Phệ.",
        hungThan: "  Thiên Cương, Kiếp Sát, Nguyệt Hại, Ngũ Li, Thiên Hình.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Nạp Tài, Quét Dọn, Chặt Cây, Đuổi Bắt, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.  ",
        khongNen:
          " Kê Giường, Mời Thầy Chữa Bệnh, Mở Kho Xuất Tiền Hàng, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Thiên Đức, Mẫu Thương, Nguyệt Ân, Tứ Tướng, Thời Dương, Sinh Khí, Thánh Tâm, Trừ Thần, Ô Phệ.",
        hungThan: "  Kiếp Sát, Thiên Hỏa, Ngũ Li, Chu Tước.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Quét Dọn, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Họp Thân Hữu, Mời Thầy Chữa Bệnh, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng.",
      },
      {
        catThan: " Ích Hậu, Kim Quĩ.",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Huyết Chi, Ngũ Hư.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan: " Vương Nhật, Tục Thế, Bảo Quang.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Nguyệt Hình, Cửu Khảm, Cửu Tiêu, Huyết Kị, Trùng Nhật.",
        nen: " Cúng Tế, Tắm Gội.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Châm Cứu, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Thủ Nhật, Thiên Vu, Phúc Đức, Ngọc Vũ, Ngọc Đường",
        hungThan: " Nguyệt Yếm, Địa Hỏa, Cửu Không, Đại Sát, Qui Kị, Cô Thần.",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Thủ Nhật, Thiên Vu, Phúc Đức, Ngọc Vũ, Ngọc Đường",
        hungThan: " Nguyệt Yếm, Địa Hỏa, Cửu Không, Đại Sát, Qui Kị, Cô Thần.",
        nen: " Cúng Tế.",
        khongNen:
          " Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Đi Xa Trở Về, Mời Thầy Chữa Bệnh, Chặt Cây, Săn Bắt, Đánh Cá, Gieo Trồng.",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Không, Thời Đức, Tướng Nhật, Lục Hợp, Ngũ Phú, Bất Tương, Kim Đường, Ngũ Hợp.",
        hungThan: " Hà Khôi, Tử Thần, Du Họa, Ngũ Hư, Thiên Lao.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Sửa Tường, Sửa Đường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.     ",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Giải Trừ, Mời Thầy Chữa Bệnh, Đan Dệt, Săn Bắt, Đánh Cá.",
      },
      {
        catThan:
          " Âm Đức, Dân Nhật, Tam Hợp, Thời Âm, Bất Tương, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Tử Khí, Huyền Vũ.",
        nen: " Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, Cải Táng. ",
        khongNen:
          " Giải Trừ, Mời Thầy Chữa Bệnh, Nấu Rượu, Xếp Đặt Buồng Đẻ, Đào Giếng, Gieo Trồng.",
      },
      {
        catThan: " Dương Đức, Bất Tương, Giải Thần, Tư Mệnh.",
        hungThan: " Tiểu Hao, Thiên Tặc, Ngũ Mộ, Thổ Phù, Phục Nhật.",
        nen: " Dâng Biểu Sớ, Tắm Gội, Cắt Tóc , Sửa Móng, Cắt May, Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Dịch Mã, Thiên Hậu, Thiên Thương, Bất Tương, Kính An.",
        hungThan: " Nguyệt Phá, Đại Hao, Trùng Nhật, Câu Trần.",
        nen: " Mời Thầy Chữa Bệnh, Dỡ Nhà Phá Tường ",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà, Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Nguyệt Đức, Tứ Tướng, Phổ Hộ, Thanh Long, Ô Phệ.",
        hungThan: " Thiên Lại, Chí Tử, Ngũ Hư",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Kê Giường, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Nạp Tài, Chặt Cây, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng. ",
        khongNen:
          " Mời Thầy Chữa Bệnh, Lợp Mái, Gác Đòn Dông, Mở Kho Xuất Tiền Hàng, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Thiên Đức, Nguyệt Ân, Tứ Tướng, Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y, Lục Nghi, Phúc Sinh, Minh Đường.",
        hungThan: " Yếm Đối, Chiêu Dao, Tứ Kích, Vãng Vong.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Nhập Học, Đính Hôn, Ăn Hỏi, Giải Trừ, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc, An Táng. ",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Đuổi Bắt, Săn Bắn, Đánh Cá, Gieo Trồng.",
      },
      {
        catThan: " Mẫu Thương, Trừ Thần, Ô Phệ.",
        hungThan: " Thiên Cương, Kiếp Sát, Nguyệt Hại, Ngũ Li, Thiên Hình",
        nen: " Tắm Gội, Quét Dọn, Chặt Cây, Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Mẫu Thương, Thời Dương, Sinh Khí, Thanh Tâm, Trừ Thần, Ô Phệ Đối.",
        hungThan: "  Tai Sát, Thiên Hỏa, Ngũ Li, Chu Tước.",
        nen: " Cúng Tế, Nhập Học, Tắm Gội, Quét Dọn.",
        khongNen:
          " Họp Thân Hữu, Đội Mũ Cài Trâm, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Cắt Tóc, Mời Thầy Chữa Bệnh, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Chặt Cây, Săn Bắt, Đánh Cá.",
      },
      {
        catThan: " Ích Hậu, Kim Quĩ.",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Huyết Chi, Ngũ Hư, Tuyệt Dương.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Nguyệt Đức Hợp, Vương Nhật, Tục Thế, Bảo Quang.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Nguyệt Hình, Cửu Khảm, Cửu Tiêu, Huyết Kị, Trùng Nhật, Tiểu Hội, Thuần Âm. ",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan:
          " Thiên Đức Hợp, Nguyệt Không, Quan Nhật, Thiên Mã, Cát Kì, Yếu An, Ô Phệ Đối.",
        hungThan: "  Đại Thời, Đại Bại, Hàm Trì, Bạch Hổ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Cưới Gả, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh,  Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.  ",
        khongNen: " Đan Dệt, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Thủ Nhật, Thiên Vu, Phúc Đức, Ngọc Vũ, Ngọc Đường.",
        hungThan:
          " Nguyệt Yếm, Địa Hỏa, Cửu Không, Đại Sát, Qui Kị, Hành Ngận.",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đắp Đê, Đan Dệt,  Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường,  Chặt Cây , Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Thiên Nguyện, Thời Đức, Tướng Nhật, Lục Hợp, Ngũ Phú, Bất Tương, Kim Đường, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: "  Hà Khôi, Tử Thần, Du Họa, Ngũ Hư, Phục Nhật, Thiên Lao.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Sửa Đường, Sửa Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Giải Trừ, Mời Thầy Chữa Bệnh, Khơi Mương.",
      },
      {
        catThan:
          " Đức, Dân Nhật, Tam Hợp, Thời Âm, Bất Tương, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: "  Tử Khí, Huyền Vũ.",
        nen: " Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Nạp Tài, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, Cải Táng.",
        khongNen:
          " Giải Trừ, Mời Thầy Chữa Bệnh, Xếp Đặt Buồng Đẻ, Đào Giếng, Gieo Trồng.",
      },
      {
        catThan: " Nguyệt Đức, Tứ Tướng, Dương Đức, Giải Thần. Tư Mệnh.",
        hungThan: " Tiểu Hao, Thiên Tặc, Thổ Phù.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Nạp Tài, Đuổi Bắt, Chăn Nuôi,Nạp Gia Súc, An Táng.",
        khongNen:
          " Xuất Hành, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho , Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Dỡ Nhà Phá Tường, Săn Bắn, Đánh Cá, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan:
          " Thiên Đức, Nguyệt Ân, Tứ Tướng, Dịch Mã, Thiên Hậu, Thiên Thương, Kính An.",
        hungThan: " Nguyệt Phá, Đại Hao, Trùng Nhật, Câu Trần.",
        nen: " Cúng Tế, Giải Trừ, Mời Thầy Chữa Bệnh, Dỡ Nhà Phá Tường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Phổ Hộ, Thanh Long, Ô Phệ.",
        hungThan: " Thiên Lại, Chí Tử, Tứ Phế, Ngũ Hư.",
        nen: " Cúng Tế, Chặt Cây, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Lợp Mái, Gác Đòn Dông, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Tam Hợp, Lâm Nhật, Thiên Hỉ, Lục Nghi, Phúc Sinh, Minh Đường.",
        hungThan: " Yếm Đối, Chiêu Dao, Tứ Kích, Vãng Vong, Bát Chuyên.",
        nen: " Cúng Tế, Cầu Phúc, Họp Thân Hữu, Nhập Học, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho , Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Nạp Gia Súc.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đuổi Bắt, Săn Bắn, Đánh Cá, Đi Thuyền.",
      },
      {
        catThan: " Mẫu Thương, Trừ Thần.",
        hungThan:
          " Thiên Cương, Kiếp Sát, Nguyệt Hại, Đại Nang, Ngũ Li, Thiên Hình.",
        nen: " Tắm Gội, Quét Dọn, Chặt Cây, Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường,  Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Thiên Ân, Mẫu Thương, Thời Dương, Sinh Khí, Thánh Tâm, Trừ Thần, Ô Phệ.",
        hungThan: " Tai Sát, Thiên Hỏa, Ngũ Li, Chu Tước.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Nạp Tài, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Họp Thân Hữu, Mời Thầy Chữa Bệnh, Chặt Cây, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Thiên Đức Hợp, Nguyệt Không, Thiên Ân, Ích Hậu, Kim Quĩ.",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Huyết Chi, Ngũ Hư",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu,Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường,  Săn Bắn, Đánh Cá,  Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Ân, Vương Nhật, Tục Thế, Bảo Quang.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Nguyệt Hình, Cửu Khảm, Cửu Tiêu, Huyết Kị, Trùng Nhật.",
        nen: " Cúng Tế, Tắm Gội.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường,  Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Ân, Quan Nhật, Thiên Mã, Cát Kì, Yếu An, Ô Phệ Đối.",
        hungThan:
          " Đại Thời, Đại Bại, Hàm Trì, Tứ Kị, Lục Xà, Phục Nhật, Bạch Hổ, Tuế Bạc.",
        nen: " Tắm Gội, Quét Dọn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ,  Khơi Mương Đào Giếng, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Thiên Ân, Thủ Nhật, Thiên Vu, Phúc Đức, Ngọc Vũ, Ngọc Đường.",
        hungThan:
          " Nguyệt Yếm, Địa Hỏa, Cửu Không, Đại Sát, Qui Kị, Bát Chuyên, Xúc Thủy Long, Liễu Lệ, Âm Thác.",
        nen: " Cúng Tế.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường,  Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Đức, Tứ Tướng, Thời Đức, Tướng Nhật, Lục Hợp, Ngũ Phú, Kim Đường, Ngũ Hợp, Ô Phệ Đối.",
        hungThan:
          " Hà Khôi, Tử Thần, Du Họa, Ngũ Hư, Bát Phong, Bát Chuyên, Thiên Lao.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Thu Nạp Người, Di Chuyển, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Sửa Đường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.  ",
        khongNen:
          " Cúng Tế, Cầu Phúc, Cầu Tự, Đính Hôn, Ăn Hỏi, Cưới Gả, Giải Trừ, Mời Thầy Chữa Bệnh, Mở Kho Xuất Tiền Hàng, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Thiên Đức, Nguyệt Ân, Tứ Tướng, Âm Đức, Dân Nhật, Tam Hợp, Thời Âm, Ngũ Hợp, Ô Phệ.",
        hungThan: " Tử Khí, Huyền Vũ.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
        khongNen:
          " Mời Thầy Chữa Bệnh, Đào Giếng, Săn Bắn, Đánh Cá. Gieo Trồng.",
      },
      {
        catThan: " Dương Đức, Giải Thần, Tư Mệnh.",
        hungThan: " Tiểu Hao, Thiên Tặc, Thổ Phù.",
        nen: " Dâng Biểu Sớ, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Xuất Hành, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Khai Trương, Nhập Trạch, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan: " Dịch Mã, Thiên Hậu, Thiên Tương, Kính An.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tứ Phế, Trùng Nhật, Câu Trần, Âm Dương Giao Phá.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan: " Phổ Hộ, Thanh Long.",
        hungThan: " Thiên Lại, Chí Tử, Ngũ Hư.",
        nen: " Cúng Tế, Chặt Cây, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Mời Thầy Chữa Bệnh, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Lợp Mái, Gác Đòn Dông, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Tam Hợp, Lâm Nhật, Thiên Hỉ, Thiên Y, Lục Nghi, Phúc Sinh, Minh Đường",
        hungThan: " Yếm Đối, Chiêu Dao, Tứ Kích, Vãng Vong, Bát Chuyên.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Nhập Học, Giải Trừ, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng. ",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Đuổi Bắt, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Thiên Đức Hợp, Nguyệt Không, Mẫu Thương, Trừ Thần, Ô Phệ.",
        hungThan:
          " Thiên Cương, Kiếp Sát, Nguyệt Hại, Ngũ Li, Bát Chuyên, Thiên Hình.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Nạp Tài, Quét Dọn, Chặt Cây, Đuổi Bắt, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng.    ",
        khongNen:
          " Đính Hôn, Ăn Hỏi, Cưới Gả, Kê Giường, Mời Thầy Chữa Bệnh, Đan Dệt, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Mẫu Thương, Thời Dương, Sinh Khí, Thánh Tâm, Trừ Thần, Ô Phệ.",
        hungThan: " Tai Sát, Thiên Hỏa, Tứ Hao, Ngũ Li, Chu Tước.",
        nen: " Cúng Tế, Nhập Học, Tắm Gội, Quét Dọn.",
        khongNen:
          " Họp Thân Hữu, Đội Mũ Cài Trâm, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Mời Thầy Chữa Bệnh, Sửa Kho , Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Chặt Cây, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Ích Hậu, Kim Quĩ.",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Huyết Chi, Ngũ Hư, Phục Nhật.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan: " Vương Nhật, Tục Thế, Bảo Quang.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Nguyệt Hình, Tứ Cùng, Lục Xà, Cửu Khảm, Cửu Tiêu, Huyết Kị, Trùng Nhật, Dương Thác.",
        nen: " Cúng Tế, Tắm Gội.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường,  Chặt Cây, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
    ],
    [
      {
        catThan:
          " Thiên Ân, Thiên Xá, Nguyệt Ân, Tứ Tướng, Quan Nhật, Kính An, Kim Quĩ.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Nguyệt Yếm, Địa Hỏa.",
        nen: " Cúng Tế, Tắm Gội.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Đi Xa Trở Về, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Chặt Cây, Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Thiên Ân, Tứ Tướng, Âm Đức, Thủ Nhật, Cát Kì, Lục Hợp, Phổ Hộ, Bảo Quang.",
        hungThan: " ",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Quét Dọn, Chăn Nuôi, Nạp Gia Súc, An Táng. ",
        khongNen: " Đội Mũ Cài Trâm, Gieo Trồng.",
      },
      {
        catThan:
          " Nguyệt Không, Thiên Ân, Thời Đức, Tướng Nhật, Dịch Mã, Thiên Hậu, Thiên Mã, Thiên Vu, Phúc Đức, Phúc Sinh, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Ngũ Hư, Qui Kị, Bạch Hổ.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành,Thu Nạp Người, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Lấp Hang Hố, Gieo Trồng, Chăn Nuôi, Phá Thổ, Cải Táng. ",
        khongNen:
          " Cúng Tế, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Di Chuyển, Đi Xa Trở Về, Mời Thầy Chữa Bệnh, Sửa Kho, Mở Kho Xuất Tiền Hàng.",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Thiên Ân, Dân Nhật, Bất Tương, Ngũ Hợp, Ngọc Đường, Ô Phệ Đối.",
        hungThan:
          " Thiên Cương, Tử Thần, Nguyệt Hình, Thiên Lại, Chí Tử, Thiên Tặc.",
        nen: " Cúng Tế, Sửa Đường.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường,  Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Thiên Ân, Tam Hợp, Lâm Nhật, Thời Âm, Thiên Thương, Thánh Tâm.",
        hungThan: " Tử Khí, Thiên Lao.",
        nen: " Cúng Tế, Cầu Phúc, Dâng Biểu Sớ, Nhận Phong Tước Vị, Đội Mũ Cài Trâm, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Nạp Gia Súc.",
        khongNen:
          " Giải Trừ, Mời Thầy Chữa Bệnh, Xếp Đặt Buồng Đẻ, Gieo Trồng.",
      },
      {
        catThan: " Ngũ Phú, Bất Tương, Ích Hậu.",
        hungThan: " Kiếp Sát, Tiểu Hao, Trùng Nhật, Huyền Vũ, ",
        nen: " Cúng Tế, Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Dương Đức, Lục Nghi, Tục Thế, Giải Thần, Tư Mệnh, Ô Phệ",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tai Sát, Thiên Hỏa, Yếm Đối, Chiêu Dao, Ngũ Hư, Huyết Kị.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan: " Yếu An.",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Nguyệt Hại, Tứ Kích, Câu Trần.",
        nen: " Chặt Cây, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường,  Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Đức, Mẫu Thương, Tam Hợp, Thiên Hỉ, Thiên Y, Ngọc Vũ, Trừ Thần, Thanh Long, Ô Phệ.",
        hungThan: " Cửu Khảm, Cửu Tiêu, Thổ Phù, Đại Sát, Ngũ Li.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Quét Dọn, Chặt Cây, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Kê Giường, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Rèn Đúc, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Săn Bắn, Đánh Cá, Đi Thuyền, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan: " Mẫu Thương, Kim Đường, Trừ Thần, Minh Đường, Ô Phệ.",
        hungThan: " Hà Khôi, Đại Thời, Đại Bại, Hàm Trì, Phục Nhật, Ngũ Li.",
        nen: " Tắm Gội, Cắt Tóc, Sửa Móng, Quét Dọn, Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo, Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Nguyệt Ân, Tứ Tướng, Thời Dương, Sinh Khí.",
        hungThan: " Ngũ Hư, Bát Phong, Cửu Không, Vãng Vong, Thiên Hình.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Nhập Học, Đính Hôn, Ăn Hỏi, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi. ",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá, Đi Thuyền. ",
      },
      {
        catThan: " Tứ Tướng, Vương Nhật.",
        hungThan: " Du Họa, Huyết Chi, Trùng Nhật, Chu Tước.",
        nen: " Cúng Tế, Tắm Gội, Cắt May, Đắp Đê, Nạp Tài, Lấp Hang Hố, Chăn Nuôi.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ,  Khơi Mương Đào Giếng, Gieo Trồng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Nguyệt Không, Quan Nhật, Kính An, Kim Quĩ, Ô Phệ Đối.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Nguyệt Yếm, Địa Hỏa, Xúc Thủy Long.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Âm Đức, Thủ Nhật, Cát Kì, Lục Hợp, Bất Tương, Phổ Hộ, Bảo Quang.",
        hungThan: " ",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Sửa Móng Tay Chân, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng. ",
        khongNen: " Đội Mũ Cài Trâm, Cắt Tóc, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Thời Đức, Tướng Nhật, Dịch Mã, Thiên Hậu, Thiên Mã, Thiên Vu, Phúc Đức, Phúc Sinh, Ngũ Hợp.",
        hungThan: " Ngũ Hư, Qui Kị, Bạch Hổ.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành,Thu Nạp Người, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Lấp Hang Hố, Gieo Trồng, Chăn Nuôi.",
        khongNen:
          " Cúng Tế, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Di Chuyển, Đi Xa Trở Về, Mời Thầy Chữa Bệnh, Sửa Kho , Mở Kho Xuất Tiền Hàng.",
      },
      {
        catThan: " Thiên Ân, Dân Nhật, Bất Tương, Ngũ Hợp, Ngọc Đường.",
        hungThan:
          " Thiên Cương, Tử Khí, Nguyệt Hình, Thiên Lại, Chí Tử, Thiên Tặc.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan:
          " Thiên Ân, Tam Hợp, Lâm Nhật, Thời Âm, Thiên Hậu, Bất Tương, Thánh Tâm.",
        hungThan: " Tử Khí, Thiên Lao.",
        nen: " Cúng Tế, Cầu Phúc, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Nấu Rượu, Lập Ước, Giao Dịch, Nạp Tài, Đặt Cối Đá, Nạp Gia Súc.",
        khongNen:
          " Giải Trừ, Mời Thầy Chữa Bệnh, Đan Dệt, Xếp Đặt Buồng Đẻ, Gieo Trồng.",
      },
      {
        catThan: " Thiên Ân, Ngũ Phú, Bất Tương, Ích Hậu.",
        hungThan: " Kiếp Sát, Tiểu Hao, Trùng Nhật, Huyền Vũ.",
        nen: " Cúng Tế, Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Đức, Thiên Ân, Dương Đức, Lục Nghi, Tục Thế, Giải Thần, Tư Mệnh, Ô Phệ.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tai Sát, Thiên Hỏa, Yếm Đối, Chiêu Dao, Ngũ Hư, Huyết Kị.",
        nen: " Cúng Tế, Tắm Gội.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Châm Cứu, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường,  Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Ân, Yếu An.",
        hungThan:
          " Nguyệt Sát, Nguyệt Hư, Nguyệt Hại, Tứ Kích, Phục Nhật, Xúc Thủy Long, Câu Trần.",
        nen: " Chặt Cây, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường,  Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Mẫu Thương, Nguyệt Ân, Tứ Tướng, Tam Hợp, Thiên Hỉ, Thiên Y, Ngọc Vũ, Trừ Thần, Thanh Long, Ô Phệ.",
        hungThan: " Cửu Khảm, Cửu Tiêu, Thổ Phù, Đại Sát, Ngũ Li.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Quét Dọn, Chặt Cây, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Kê Giường, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho , Rèn Đúc, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Đánh Cá, Đi Thuyền, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan:
          " Mẫu Thương, Tứ Tướng, Kim Đường, Trừ Thần, Bảo Quang, Ô Phệ.",
        hungThan: " Hà Khôi, Đại Thời, Đại Bại, Hàm Trì, Ngũ Li.",
        nen: " Cúng Tế, Tắm Gội, Cắt Tóc , Sửa Móng, Quét Dọn, Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, , Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Nguyệt Không, Thời Dương, Sinh Khí",
        hungThan: " Ngũ Hư, Cửu Không, Vãng Vong, Thiên Hình.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự,Họp Thân Hữu, Nhập Học, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Nguyệt Đức Hợp, Vương Nhật.",
        hungThan: " Du Họa, Huyết Chi, Trùng Nhật, Chu Tước.",
        nen: " Cúng Tế, Tắm Gội, Cắt May, Đắp Đê, Sửa Kho, Lấp Hang Hố, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Cưới Gả, Giải Trừ, Cắt Tóc , Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Quan Nhật, Kính An, Kim Quĩ.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Nguyệt Yếm, Địa Hỏa, Tiểu Hội.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan:
          " Âm Đức, Thủ Nhật, Cát Kì, Lục Hợp, Bất Tương, Phổ Hộ, Bảo Quang",
        hungThan: " ",
        nen: " Cúng Tế, Cầu Phúc, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Cưới Gả, Thu Nạp Người, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Quét Dọn, Nạp Gia Súc, An Táng.",
        khongNen: " Đội Mũ Cài Trâm.",
      },
      {
        catThan:
          " Thời Đức, Tướng Nhật, Dịch Mã, Thiên Hậu, Thiên Mã, Thiên Vu, Phúc Đức, Bất Tương, Phúc Sinh, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Ngũ Hư, Qui Kị, Bạch Hổ.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Cưới Gả, Thu Nạp Người, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Lấp Hang Hố, Gieo Trồng, Chăn Nuôi, Phá Thổ, Cải Táng.",
        khongNen:
          " Cúng Tế, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Di Chuyển, Đi Xa Trở Về, Mời Thầy Chữa Bệnh, Sửa Kho, Đan Dệt, Mở Kho Xuất Tiền Hàng.",
      },
      {
        catThan: " Dân Nhật, Bất Tương, Ngũ Hợp, Ngọc Đường, Ô Phệ Đối.",
        hungThan:
          " Thiên Cương, Tử Thần, Nguyệt Hình, Thiên Lại, Chí Tử, Thiên Tặc, Địa Nang.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan:
          " Nguyệt Đức, Tam Hợp, Thời Âm, Thiên Thương, Bất Tương, Thánh Tâm.",
        hungThan: " Tử Khí, Ngũ Mộ, Thiên Lao.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen: " Mời Thầy Chữa Bệnh, Khơi Mương, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Ngũ Phú, Ích Hậu.",
        hungThan: " Kiếp Sát, Tiểu Hao, Phục Nhật, Trùng Nhật, Huyền Vũ",
        nen: " Cúng Tế, Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Ân, Tứ Tướng, Dương Đức, Lục Nghi, Tục Thế, Giải Thần, Tư Mệnh, Ô Phệ.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tai Sát, Thiên Hỏa, Yếm Đối, Chiêu Dao, Ngũ Hư, Huyết Kị.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan: " Tứ Tướng, Yếu An.",
        hungThan: " Nguyệt Sát, Nguyệt Hư, Nguyệt Hại, Tứ Kích, Câu Trần.",
        nen: " Cúng Tế, Chặt Cây, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường,  Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Không, Mẫu Thương, Tam Hợp, Thiên Hỉ, Thiên Y, Ngọc Vũ, Trừ Thần, Thanh Long, Ô Phệ.",
        hungThan: " Cửu Khảm, Cửu Tiêu, Thổ Phù, Đại Sát, Ngũ Li.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Quét Dọn, Chặt Cây, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Kê Giường, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Rèn Đúc, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Đánh Cá, Đi Thuyền, Gieo Trồng, Phá Thổ. ",
      },
      {
        catThan:
          " Nguyệt Đức Hợp, Mẫu Thương, Kim Đường, Trừ Thần, Minh Đường, Ô Phệ.",
        hungThan: " Hà Khôi, Đại Thời, Đại Bại, Hàm Trì, Ngũ Li.",
        nen: " Cúng Tế, Tắm Gội, Sửa Móng, Quét Dọn, Đuổi Bắt.",
        khongNen:
          " Họp Thân Hữu, Cắt Tóc, Mời Thầy Chữa Bệnh, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Thời Dương, Sinh Khí.",
        hungThan: " Ngũ Li, Cửu Không, Vãng Vong, Thiên Hình.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Nhập Học, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi. ",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Sửa Kho , Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Vương Nhật. ",
        hungThan: " Du Họa, Huyết Chi, Trùng Nhật, Chu Tước.",
        nen: " Tắm Gội, Cắt May, Đắp Đê, Lấp Hang Hố.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Khai Trương, Nhập Trạch, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan: " Quan Nhật, Kính An, Kim Quĩ , Ô Phệ Đối.",
        hungThan: " Nguyệt Kiến, Tiểu Thời, Thổ Phù, Nguyệt Yếm, Địa Hỏa.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan:
          " Âm Đức, Thủ Nhật, Cát Kì, Lục Hợp, Bất Tương, Phổ Hộ, Bảo Quang.",
        hungThan: " ",
        nen: " Cúng Tế, Cầu Phúc, Họp Thân Hữu, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Đan Dệt, Lập Ước, Giao Dịch, Nạp Tài, Quét Dọn, Nạp Gia Súc, An Táng.",
        khongNen: " Đội Mũ Cài Trâm, Nấu Rượu.",
      },
      {
        catThan:
          " Nguyệt Đức, Thời Đức, Tướng Nhật, Dịch Mã, Thiên Hậu, Thiên Mã, Thiên Vu, Phúc Đức, Bất Tương, Phúc Sinh, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Ngũ Hư, Qui Kị, Bạch Hổ.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Lấp Hang Hố, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
        khongNen:
          " Cúng Tế, Di Chuyển, Đi Xa Trở Về, Khơi Mương, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Dân Nhật, Ngũ Hợp, Ngọc Đường, Ô Phệ Đối.",
        hungThan:
          " Thiên Cương, Tử Thần, Nguyệt Hình, Thiên Lại, Chí Tử, Thiên Tặc, Phục Nhật.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan:
          " Nguyệt Ân, Tứ Tướng, Tam Hợp, Lâm Nhật, Thời Âm, Thiên Thương, Thánh Tâm.",
        hungThan: " Tử Khí, Thiên Lao.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Giải Trừ, Mời Thầy Chữa Bệnh, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Gieo Trồng.",
      },
      {
        catThan: " Tứ Tướng, Ngũ Phú, Ích Hậu.",
        hungThan: " Kiếp Sát, Tiểu Hao, Trùng Nhật, Huyền Vũ.",
        nen: " Cúng Tế, Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Nguyệt Không, Dương Đức, Lục Nghi, Tục Thế, Giải Thần, Tư Mệnh, Ô Phệ.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tai Sát, Thiên Hỏa, Yếm Đối, Chiêu Dao, Tứ Phế, Ngũ Hư, Huyết Kị, Âm Dương Kích Xung.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan: " Nguyệt Đức Hợp, Yếu An.",
        hungThan:
          " Nguyệt Sát, Nguyệt Hư, Nguyệt Hại, Tứ Kích, Bát Chuyên, Câu Trần.",
        nen: " Cúng Tế, Chặt Cây.",
        khongNen:
          " Đính Hôn, Ăn Hỏi, Cưới Gả, Cắt Tóc , Mời Thầy Chữa Bệnh, Săn Bắn, Đánh Cá.",
      },
      {
        catThan:
          " Mẫu Thương, Tam Hợp, Thiên Hỉ, Thiên Y, Ngọc Vũ, Trừ Thần, Thanh Long.",
        hungThan: " Cửu Khảm, Cửu Tiêu, Thổ Phù, Đại Sát, Ngũ Li.",
        nen: " Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Quét Dọn, Chặt Cây, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Kê Giường, Đắp Đê ,Tu Tạo Động Thổ, Sửa Kho , Rèn Đúc, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường, Đánh Cá, Đi Thuyền, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan:
          " Thiên Ân, Mẫu Thương, Kim Đường, Trừ Thần, Minh Đường, Ô Phệ.",
        hungThan: " Hà Khôi, Đại Thời, Đại Bại, Hàm Trì, Ngũ Li.",
        nen: " Tắm Gội, Cắt Tóc , Sửa Móng, Lấp Hang Hố, Quét Dọn, Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Thiên Ân, Thời Dương, Sinh Khí.",
        hungThan: " Ngũ Hư, Cửu Không, Vãng Vong, Thiên Hình.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Nhập Học, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Sửa Kho , Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Thiên Ân, Vương Nhật.",
        hungThan: " Du Họa, Huyết Chi, Trùng Nhật, Chu Tước.",
        nen: " Tắm Gội, Cắt May, Đắp Đê, Lấp Hang Hố.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Kê Giường, Mời Thầy Chữa Bệnh, Châm Cứu, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Nấu Rượu, Khai Trương, Nhập Trạch, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Phá Thổ, An Táng, Cải Táng.",
      },
      {
        catThan:
          " Nguyệt Đức, Thiên Ân, Lâm Nhật, Kính An, Kim Quĩ, Ô Phệ Đối.",
        hungThan:
          " Nguyệt Kiến, Tiểu Thời, Thổ Phủ, Nguyệt Yếm, Địa Hỏa, Tứ Kị, Lục Xà, Đại Hội, Âm Dương Câu Thác.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan:
          " Thiên Ân, Thiên Nguyện, Âm Đức, Thủ Nhật, Cát Kì, Lục Hợp, Phổ Hộ, Bảo Quang.",
        hungThan: " Phục Nhật, Bát Chuyên, Xúc Thủy Long.",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Quét Dọn, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen: " Đội Mũ Cài Trâm, Đánh Cá, Đi Thuyền.",
      },
      {
        catThan:
          " Nguyệt Ân, Tứ Tướng, Thời Đức, Tướng Nhật, Dịch Mã, Thiên Hậu, Thiên Mã, Thiên Vu, Phúc Đức, Phúc Sinh, Ngũ Hợp, Ô Phệ Đối.",
        hungThan: " Ngũ Hư, Bát Phong, Qui Kị, Bát Chuyên, Bạch Hổ.",
        nen: " Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Thu Nạp Người, Giải Trừ, Mời Thầy Chữa Bệnh, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Đan Dệt, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Lấp Hang Hố, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, Cải Táng. ",
        khongNen:
          " Cúng Tế, Đính Hôn, Ăn Hỏi, Cưới Gả, Di Chuyển, Đi Xa Trở Về, Sửa Kho , Mở Kho Xuất Tiền Hàng, Đánh Cá, Đi Thuyền.",
      },
      {
        catThan: " Tứ Tướng, Dân Nhật, Ngũ Hợp, Ngọc Đường, Ô Phệ Đối.",
        hungThan:
          " Thiên Cương, Tử Thần, Nguyệt Hình, Thiên Lại, Chí Tử, Thiên Tặc.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm.",
      },
      {
        catThan:
          " Nguyệt Không, Tam Hợp, Lâm Nhật, Thời Âm, Thiên Thương, Thánh Tâm.",
        hungThan: " Tử Khí, Thiên Lao.",
        nen: " Cúng Tế, Cầu Phúc, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Đan Dệt, Nấu Rượu, Lập Ước Giao Dịch, Nạp Tài, Đặt Cối Đá, Nạp Gia Súc.",
        khongNen:
          " Giải Trừ, Mời Thầy Chữa Bệnh, Xếp Đặt Buồng Đẻ, Gieo Trồng.",
      },
      {
        catThan: " Nguyệt Đức Hợp, Ngũ Phú, Bất Tương, Ích Hậu.",
        hungThan: " Kiếp Sát, Tiểu Hao, Tứ Kích, Trùng Nhật, Huyền Vũ.",
        nen: " Cúng Tế, Đuổi Bắt.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước, Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương, Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường,  Săn Bắn, Đánh Cá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Dương Đức, Lục Nghi, Tục Thế, Giải Thần, Tư Mệnh.",
        hungThan:
          " Nguyệt Phá, Đại Hao, Tai Sát, Thiên Hỏa, Yếm Đối, Chiêu Dao, Ngũ Hư, Huyết Kị.",
        nen: " ",
        khongNen: " Mọi Việc Không Nên Làm",
      },
      {
        catThan: " Yếu An.",
        hungThan:
          " Nguyệt Sát, Nguyệt Hại, Nguyệt Hư, Tứ Kích, Bát Chuyên, Câu Trần.",
        nen: " Chặt Cây, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Dỡ Nhà Phá Tường,  Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan:
          " Tam Hợp, Thiên Hỉ, Thiên Y, Ngọc Vũ, Thanh Long, Mẫu Thương, Trừ Thần, Ô Phệ",
        hungThan: " Cửu Khảm, Cửu Tiêu, Thổ Phù, Đại Sát, Ngũ Li, Bát Chuyên.",
        nen: " Họp Thân Hữu, Nhập Học, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Thu Nạp Người, Di Chuyển, Giải Trừ, Tắm Gội, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Quét Dọn, Chặt Cây, Chăn Nuôi, Nạp Gia Súc, An Táng.",
        khongNen:
          " Đính Hôn, Ăn Hỏi, Cưới Gả, Kê Giường,, Đắp Đê, Tu Tạo Động Thổ, Sửa Kho, Rèn Đúc, Đan Dệt, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Lấp Hang Hố, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Đánh Cá, Đi Thuyền, Gieo Trồng, Phá Thổ.",
      },
      {
        catThan: " Mẫu Thương, Kim Đường, Trừ Thần, Minh Đường, Ô Phệ.",
        hungThan:
          " Hà Khôi, Đại Thời, Đại Bại, Hàm Trì, Tứ Hao, Địa Nang, Ngũ Li.",
        nen: " Tắm Gội, Cắt Tóc , Sửa Móng, Quét Dọn, Đuổi Bắt, Săn Bắn.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Đội Mũ Cài Trâm, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Cắt Tóc, Sửa Móng, Mời Thầy Chữa Bệnh, Cắt May, Đắp Đê, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Rèn Đúc, Đan Dệt, Nấu Rượu, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Đặt Cối Đá, Sửa Tường, Sửa Đường, Dỡ Nhà Phá Tường, Đánh Cá, Đi Thuyền, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc, Phá Thổ, An Táng, Cải Táng. ",
      },
      {
        catThan: " Nguyệt Đức, Thời Dương, Sinh Khí.",
        hungThan: " Ngũ Hư, Cửu Không, Vãng Vong, Thiên Hình",
        nen: " Cúng Tế, Cầu Phúc, Cầu Tự, Họp Thân Hữu, Nhập Học, Đính Hôn, Ăn Hỏi, Giải Trừ, Cắt May, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Xếp Đặt Buồng Đẻ, Đặt Cối Đá, Gieo Trồng, Chăn Nuôi, Nạp Gia Súc.",
        khongNen:
          " Dâng Biểu Sớ, Nhận Phong Tước Vị, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Cưới Gả, Thu Nạp Người, Di Chuyển, Mời Thầy Chữa Bệnh, Khơi Mương, Chặt Cây, Đuổi Bắt, Săn Bắn, Đánh Cá.",
      },
      {
        catThan: " Vương Nhật.",
        hungThan:
          " Du Họa, Huyết Chi, Tứ Cùng, Lục Xà, Phục Nhật, Trùng Nhật, Chu Tước.",
        nen: " Tắm Gội.",
        khongNen:
          " Cầu Phúc, Cầu Tự, Dâng Biểu Sớ, Nhận Phong Tước Vị, Họp Thân Hữu, Xuất Hành, Lên Quan Nhậm Chức, Gặp Dân, Đính Hôn, Ăn Hỏi, Cưới Gả, Thu Nạp Người, Di Chuyển, Kê Giường, Giải Trừ, Mời Thầy Chữa Bệnh, Chữa Mắt, Châm Cứu, Tu Tạo Động Thổ, Dựng Cột Gác Xà, Gác Đòn Dông, Lợp Mái, Sửa Kho, Khai Trương, Nhập Trạch, Lập Ước Giao Dịch, Nạp Tài, Mở Kho Xuất Tiền Hàng, Xếp Đặt Buồng Đẻ, Khơi Mương Đào Giếng, Phá Thổ, An Táng, Cải Táng. ",
      },
    ],
  ];
  static cuuTinh = [
    { code: 0, name: "Bồng", hanViet: "." },
    { code: 1, name: "Nhuệ", hanViet: "." },
    { code: 2, name: "Xung", hanViet: "." },
    { code: 3, name: "Phụ", hanViet: "." },
    { code: 4, name: "Cầm", hanViet: "." },
    { code: 5, name: "Tâm", hanViet: "." },
    { code: 6, name: "Trụ", hanViet: "." },
    { code: 7, name: "Nhậm", hanViet: "." },
    { code: 8, name: "Anh", hanViet: "." },
  ];
  static batMon = [
    { code: 0, name: "Hưu", hanViet: "." },
    { code: 1, name: "Sinh", hanViet: "." },
    { code: 2, name: "Thương", hanViet: "." },
    { code: 3, name: "Đỗ", hanViet: "." },
    { code: 4, name: "Cảnh", hanViet: "." },
    { code: 5, name: "Tử", hanViet: "." },
    { code: 6, name: "Kinh", hanViet: "." },
    { code: 7, name: "Khai", hanViet: "." },
  ];
  static batThan = [
    { code: 0, name: "Phù", hanViet: "." },
    { code: 1, name: "Xà", hanViet: "." },
    { code: 2, name: "Âm", hanViet: "." },
    { code: 3, name: "Hợp", hanViet: "." },
    { code: 4, name: "Trần (DĐ)/Hổ (ÂĐ)", hanViet: "." },
    { code: 5, name: "Tước (DĐ)/Vũ (ÂĐ)", hanViet: "." },
    { code: 6, name: "Địa", hanViet: "." },
    { code: 7, name: "Thiên", hanViet: "." },
  ];
  static batQuai = {
    CANF: "Càn",
    KHON: "Khôn",
    CHAN: "Chấn",
    TON: "Tốn",
    CANAS: "Cấn",
    KHAM: "Khảm",
    DOAI: "Đoài",
    LY: "Ly",
  };
  static HuongModal = [
    { huong: this.huong.NAM, lisConGiap: [this.Chi.Ngo] },
    { huong: this.huong.TAY_NAM, lisConGiap: [this.Chi.Mui, this.Chi.Than] },
    { huong: this.huong.TAY, lisConGiap: [this.Chi.Dau] },
    { huong: this.huong.TAY_BAC, lisConGiap: [this.Chi.Tuat, this.Chi.Hoi] },
    { huong: this.huong.BAC, lisConGiap: [this.Chi.Ty] },
    { huong: this.huong.DONG_BAC, lisConGiap: [this.Chi.Dan, this.Chi.Suu] },
    { huong: this.huong.DONG, lisConGiap: [this.Chi.Mao] },
    { huong: this.huong.DONG_NAM, lisConGiap: [this.Chi.Thin, this.Chi.Ti] },
  ];
  static KhongVongModal = [
    {
      hourChief: this.Can.Mau,
      listConGiap: [this.Chi.Tuat, this.Chi.Hoi],
      listCanChi: [
        this.CanChi.giapTy.name,
        this.CanChi.atSuu.name,
        this.CanChi.binhDan.name,
        this.CanChi.dinhMao.name,
        this.CanChi.mauThin.name,
        this.CanChi.kyTi.name,
        this.CanChi.canhNgo.name,
        this.CanChi.tanMui.name,
        this.CanChi.nhamThan.name,
        this.CanChi.quyDau.name,
      ],
    },
    {
      hourChief: this.Can.Ky,
      listConGiap: [this.Chi.Than, this.Chi.Dau],
      listCanChi: [
        this.CanChi.giapTuat.name,
        this.CanChi.atHoi.name,
        this.CanChi.binhTy.name,
        this.CanChi.dinhSuu.name,
        this.CanChi.mauDan.name,
        this.CanChi.kyMao.name,
        this.CanChi.canhThin.name,
        this.CanChi.tanTi.name,
        this.CanChi.nhamNgo.name,
        this.CanChi.quiMui.name,
      ],
    },
    {
      hourChief: this.Can.Canh,
      listConGiap: [this.Chi.Ngo, this.Chi.Mui],
      listCanChi: [
        this.CanChi.giapThan.name,
        this.CanChi.atDau.name,
        this.CanChi.binhTuat.name,
        this.CanChi.dinhHoi.name,
        this.CanChi.mauTy.name,
        this.CanChi.kySuu.name,
        this.CanChi.canhDan.name,
        this.CanChi.tanMao.name,
        this.CanChi.nhamThin.name,
        this.CanChi.quyTi.name,
      ],
    },
    {
      hourChief: this.Can.Tan,
      listConGiap: [this.Chi.Thin, this.Chi.Ti],
      listCanChi: [
        this.CanChi.giapNgo.name,
        this.CanChi.atMui.name,
        this.CanChi.binhThan.name,
        this.CanChi.dinhDau.name,
        this.CanChi.mauTuat.name,
        this.CanChi.kyHoi.name,
        this.CanChi.canhTy.name,
        this.CanChi.tanSuu.name,
        this.CanChi.nhamDan.name,
        this.CanChi.quiMao.name,
      ],
    },
    {
      hourChief: this.Can.Nham,
      listConGiap: [this.Chi.Dan, this.Chi.Mao],
      listCanChi: [
        this.CanChi.giapThin.name,
        this.CanChi.atTi.name,
        this.CanChi.binhNgo.name,
        this.CanChi.dinhMui.name,
        this.CanChi.mauThan.name,
        this.CanChi.kydau.name,
        this.CanChi.canhTuat.name,
        this.CanChi.tanHoi.name,
        this.CanChi.nhamTy.name,
        this.CanChi.quiSuu.name,
      ],
    },
    {
      hourChief: this.Can.Qui,
      listConGiap: [this.Chi.Ty, this.Chi.Suu],
      listCanChi: [
        this.CanChi.giapDan.name,
        this.CanChi.atMao.name,
        this.CanChi.binhThin.name,
        this.CanChi.dinhTi.name,
        this.CanChi.mauNgo.name,
        this.CanChi.kyMui.name,
        this.CanChi.canhThan.name,
        this.CanChi.tanDau.name,
        this.CanChi.nhamTuat.name,
        this.CanChi.quyHoi.name,
      ],
    },
  ];

  static ThienBan = {
    GIAP: "Giáp",
    AT: "Ất",
    BINH: "Bính",
    DINH: "Đinh",
    MAU: "Mậu",
    KY: "Kỷ",
    CANH: "Canh",
    TAN: "Tân",
    NHAM: "Nhâm",
    QUY: "Quý",
  };
  static CuuTinh = {
    BONG: "Bồng",
    NHUE: "Nhuệ",
    XUNG: "Xung",
    PHU: "Phụ",
    TAM: "Tâm",
    CAM: "Cầm",
    NHAM: "Nhậm",
    TRU: "Trụ",
    ANH: "Anh",
  };
  static DiaBan = {
    GIAP: "Giáp",
    AT: "Ất",
    BINH: "Bính",
    DINH: "Đinh",
    MAU: "Mậu",
    KY: "Kỷ",
    CANH: "Canh",
    TAN: "Tân",
    NHAM: "Nhâm",
    QUY: "Quý",
  };
  static BatMon = {
    KHAI: "Khai",
    HUU: "Hưu",
    SINH: "Sinh",
    THUONG: "Thương",
    DO: "Đỗ",
    CANH: "Cảnh",
    KINH: "Kinh",
    TU: "Tử",
  };
  static BatThan = {
    XA: "Xà",
    DIA: "Địa",
    THIEN: "Thiên",
    TRAN: "Trần",
    AM: "Âm",
    TUOC: "Tước",
    PHU: "Phù",
    HOP: "Hợp",
    HO: "Hổ",
    VU: "Vũ",
  };
}
