import _ from 'lodash';
import md5 from 'md5';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import '../App.css';
import Const from '../Const';
import SearchKyMonDonGiap from './SearchKyMonDonGiap';
import SearchQuai from './SearchQuai';
import * as XLSX from 'xlsx';
import Logo from '../logo.png';

import { Modal } from 'semantic-ui-react';
import Header from '../components/Header';

class SearchPage extends React.Component {
  constructor(props) {
    super(props);
    this.excelLichVanNien = [];
    this.dataAmDon = [];
    this.dataDuongDon = [];
    this.kyMonDonGiapNgay = [];
    this.state = {
      isLoading: false,
    };
  }
  componentDidMount() {
    this.init();
  }
  async init() {
    try {
      this.setState({ isLoading: true });
      await fetch('1080-Cuc.xlsx')
        .then((res) => res.arrayBuffer())
        .then((ab) => {
          const wb = XLSX.read(ab, { type: 'array' });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          //lấy sheet Duong Don
          let dataDuongDon = [];
          let dataAmDon = [];

          for (let index = 1; index <= 9; index++) {
            // const element = array[index];

            let dataBasic = wb.Sheets[`Duong Don ${index}`];
            let data = XLSX.utils.sheet_to_json(dataBasic, { header: 1 });
            for (let row = 0; row < data.length; row++) {
              let dataEachRow = data[row];
              let no = dataEachRow[1] && dataEachRow[1].toString().trim();
              if (no && no.includes('.')) {
                let temp = {};
                temp.CanChi = no.split('.')[1].trim();
                temp.sheet = `Duong Don ${index}`;
                let tuanThuTruc = dataEachRow[3].toString().trim().split('Trực Tinh');
                temp.tuanThu = tuanThuTruc[0].trim();
                temp.trucTinh = 'Trực Tinh' + tuanThuTruc[1].trim();
                temp.trucXu = data[row + 1][3].toString().trim();

                //box 1 2 3
                let row11a = data[row + 2][1].toString().trim().split(' ');
                let row11b = data[row + 2][2].toString().trim().split(' ');
                let row11c = data[row + 2][3].toString().trim().split(' ');
                let row12a = data[row + 3][1].toString().trim().split(' ');
                let row12b = data[row + 3][2].toString().trim().split(' ');
                let row12c = data[row + 3][3].toString().trim().split(' ');

                //box 4 5 6
                let row21a = data[row + 4][1].toString().trim().split(' ');
                let row21b = data[row + 4][2].toString().trim().split(' ');
                let row21c = data[row + 4][3].toString().trim().split(' ');
                let row22a = data[row + 5][1].toString().trim().split(' ');
                let row22b = data[row + 5][2].toString().trim().split(' ');
                let row22c = data[row + 5][3].toString().trim().split(' ');

                //box 7 8 9
                let row31a = data[row + 6][1].toString().trim().split(' ');
                let row31b = data[row + 6][2].toString().trim().split(' ');
                let row31c = data[row + 6][3].toString().trim().split(' ');
                let row32a = data[row + 7][1].toString().trim().split(' ');
                let row32b = data[row + 7][2].toString().trim().split(' ');
                let row32c = data[row + 7][3].toString().trim().split(' ');

                let box1 = {
                  thienBan: row11a[0].toString().trim(),
                  cuuTinh: row11a[row11a.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row12a[0].toString().trim(),
                  batMon: row12a[1].toString().trim(),
                  batThan: row12a[2].toString().trim(),
                  huong: Const.huong.DONG_NAM,
                  batQuai: 'Tốn',
                };
                let box2 = {
                  thienBan: row11b[0].toString().trim(),
                  cuuTinh: row11b[row11b.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row12b[0].toString().trim(),
                  batMon: row12b[1].toString().trim(),
                  batThan: row12b[2].toString().trim(),
                  huong: Const.huong.NAM,
                  batQuai: 'Ly',
                };
                let box3 = {
                  thienBan: row11c[0].toString().trim(),
                  cuuTinh: row11c[row11c.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row12c[0].toString().trim(),
                  batMon: row12c[1].toString().trim(),
                  batThan: row12c[2].toString().trim(),
                  huong: Const.huong.TAY_NAM,
                  batQuai: 'Khôn',
                };
                let box4 = {
                  thienBan: row21a[0].toString().trim(),
                  cuuTinh: row21a[row21a.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row22a[0].toString().trim(),
                  batMon: row22a[1].toString().trim(),
                  batThan: row22a[2].toString().trim(),
                  huong: Const.huong.DONG,
                  batQuai: 'Chấn',
                };
                let box5 = {
                  thienBan: row21b[0].toString().trim(),
                  cuuTinh: row21b[row21b.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row22b[0].toString().trim(),
                  batMon: null,
                  batThan: null,
                  batQuai: null,
                };
                let box6 = {
                  thienBan: row21c[0].toString().trim(),
                  cuuTinh: row21c[row21c.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row22c[0].toString().trim(),
                  batMon: row22c[1].toString().trim(),
                  batThan: row22c[2].toString().trim(),
                  huong: Const.huong.TAY,
                  batQuai: 'Đoài',
                };
                let box7 = {
                  thienBan: row31a[0].toString().trim(),
                  cuuTinh: row31a[row31a.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row32a[0].toString().trim(),
                  batMon: row32a[1].toString().trim(),
                  batThan: row32a[2].toString().trim(),
                  huong: Const.huong.DONG_BAC,
                  batQuai: 'Cấn',
                };
                let box8 = {
                  thienBan: row31b[0].toString().trim(),
                  cuuTinh: row31b[row31b.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row32b[0].toString().trim(),
                  batMon: row32b[1].toString().trim(),
                  batThan: row32b[2].toString().trim(),
                  huong: Const.huong.BAC,
                  batQuai: 'Khảm',
                };
                let box9 = {
                  thienBan: row31c[0].toString().trim(),
                  cuuTinh: row31c[row31c.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row32c[0].toString().trim(),
                  batMon: row32c[1].toString().trim(),
                  batThan: row32c[2].toString().trim(),
                  huong: Const.huong.TAY_BAC,
                  batQuai: 'Càn',
                };
                temp.box1 = box1;
                temp.box2 = box2;
                temp.box3 = box3;
                temp.box4 = box4;
                temp.box5 = box5;
                temp.box6 = box6;
                temp.box7 = box7;
                temp.box8 = box8;
                temp.box9 = box9;
                dataDuongDon.push(temp);
              }
            }
            this.dataDuongDon = dataDuongDon;
          }
          for (let index = 1; index <= 9; index++) {
            // const element = array[index];

            let dataBasic = wb.Sheets[`Am Don ${index}`];
            let data = XLSX.utils.sheet_to_json(dataBasic, { header: 1 });
            for (let row = 0; row < data.length; row++) {
              let dataEachRow = data[row];
              let no = dataEachRow[1] && dataEachRow[1].toString().trim();
              if (no && no.includes('.')) {
                let temp = {};
                temp.CanChi = no.split('.')[1].trim();
                temp.sheet = `Am Don ${index}`;
                let tuanThuTruc = dataEachRow[3].toString().trim().split('Trực Tinh');
                temp.tuanThu = tuanThuTruc[0].trim();
                temp.trucTinh = 'Trực Tinh' + tuanThuTruc[1].trim();
                temp.trucXu = data[row + 1][3].toString().trim();

                //box 1 2 3
                let row11a = data[row + 2][1].toString().trim().split(' ');
                let row11b = data[row + 2][2].toString().trim().split(' ');
                let row11c = data[row + 2][3].toString().trim().split(' ');
                let row12a = data[row + 3][1].toString().trim().split(' ');
                let row12b = data[row + 3][2].toString().trim().split(' ');
                let row12c = data[row + 3][3].toString().trim().split(' ');

                //box 4 5 6
                let row21a = data[row + 4][1].toString().trim().split(' ');
                let row21b = data[row + 4][2].toString().trim().split(' ');
                let row21c = data[row + 4][3].toString().trim().split(' ');
                let row22a = data[row + 5][1].toString().trim().split(' ');
                let row22b = data[row + 5][2].toString().trim().split(' ');
                let row22c = data[row + 5][3].toString().trim().split(' ');

                //box 7 8 9
                let row31a = data[row + 6][1].toString().trim().split(' ');
                let row31b = data[row + 6][2].toString().trim().split(' ');
                let row31c = data[row + 6][3].toString().trim().split(' ');
                let row32a = data[row + 7][1].toString().trim().split(' ');
                let row32b = data[row + 7][2].toString().trim().split(' ');
                let row32c = data[row + 7][3].toString().trim().split(' ');

                let box1 = {
                  thienBan: row11a[0].toString().trim(),
                  cuuTinh: row11a[row11a.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row12a[0].toString().trim(),
                  batMon: row12a[1].toString().trim(),
                  batThan: row12a[2].toString().trim(),
                  huong: Const.huong.DONG_NAM,
                };
                let box2 = {
                  thienBan: row11b[0].toString().trim(),
                  cuuTinh: row11b[row11b.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row12b[0].toString().trim(),
                  batMon: row12b[1].toString().trim(),
                  batThan: row12b[2].toString().trim(),
                  huong: Const.huong.NAM,
                };
                let box3 = {
                  thienBan: row11c[0].toString().trim(),
                  cuuTinh: row11c[row11c.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row12c[0].toString().trim(),
                  batMon: row12c[1].toString().trim(),
                  batThan: row12c[2].toString().trim(),
                  huong: Const.huong.TAY_NAM,
                };
                let box4 = {
                  thienBan: row21a[0].toString().trim(),
                  cuuTinh: row21a[row21a.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row22a[0].toString().trim(),
                  batMon: row22a[1].toString().trim(),
                  batThan: row22a[2].toString().trim(),
                  huong: Const.huong.DONG,
                };
                let box5 = {
                  thienBan: row21b[0].toString().trim(),
                  cuuTinh: row21b[row21b.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row22b[0].toString().trim(),
                  batMon: null,
                  batThan: null,
                  batQuai: null,
                };
                let box6 = {
                  thienBan: row21c[0].toString().trim(),
                  cuuTinh: row21c[row21c.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row22c[0].toString().trim(),
                  batMon: row22c[1].toString().trim(),
                  batThan: row22c[2].toString().trim(),
                  huong: Const.huong.TAY,
                };
                let box7 = {
                  thienBan: row31a[0].toString().trim(),
                  cuuTinh: row31a[row31a.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row32a[0].toString().trim(),
                  batMon: row32a[1].toString().trim(),
                  batThan: row32a[2].toString().trim(),
                  huong: Const.huong.DONG_BAC,
                };
                let box8 = {
                  thienBan: row31b[0].toString().trim(),
                  cuuTinh: row31b[row31b.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row32b[0].toString().trim(),
                  batMon: row32b[1].toString().trim(),
                  batThan: row32b[2].toString().trim(),
                  huong: Const.huong.BAC,
                };
                let box9 = {
                  thienBan: row31c[0].toString().trim(),
                  cuuTinh: row31c[row31c.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row32c[0].toString().trim(),
                  batMon: row32c[1].toString().trim(),
                  batThan: row32c[2].toString().trim(),
                  huong: Const.huong.TAY_BAC,
                };
                temp.box1 = box1;
                temp.box2 = box2;
                temp.box3 = box3;
                temp.box4 = box4;
                temp.box5 = box5;
                temp.box6 = box6;
                temp.box7 = box7;
                temp.box8 = box8;
                temp.box9 = box9;
                dataAmDon.push(temp);
              }
            }
            this.dataAmDon = dataAmDon;
          }
        });
    } catch (error) {
      console.log(error);
    }

    try {
      this.setState({ isLoading: true });
      await fetch('lich_van_nien.xlsx')
        .then((res) => res.arrayBuffer())
        .then((ab) => {
          const wb = XLSX.read(ab, { type: 'array' });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          this.excelLichVanNien = data;
        });
    } catch (error) {}
    try {
      await fetch('ky-mon-don-giap-theo-ngay.xlsx')
        .then((res) => res.arrayBuffer())
        .then((ab) => {
          const wb = XLSX.read(ab, { type: 'array' });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          this.kyMonDonGiapNgay = data;
        });
      this.setState({ isLoading: false });
    } catch (error) {}
  }
  render() {
    const { isLoading } = this.state;

    return (
      <div style={{ marginBottom: '200px' }}>
        <Modal basic open={isLoading} size="large">
          <Modal.Content>
            <img
              id="LoadingImage"
              src={Logo}
              alt="logo"
              style={{
                animation: `spin 10s linear infinite`,
                justifyContent: 'center',
                alignSelf: 'center',
              }}
            />
          </Modal.Content>
        </Modal>
        <Header isAutoSearch></Header>

        {this.excelLichVanNien.length > 0 && this.dataAmDon.length > 0 && this.dataDuongDon.length > 0 && this.kyMonDonGiapNgay.length > 0 && (
          <div>
            <SearchKyMonDonGiap
              kyMonDonGiapNgay={this.kyMonDonGiapNgay}
              lichVanNien={this.excelLichVanNien}
              dataAmDon={this.dataAmDon}
              dataDuongDon={this.dataDuongDon}
            ></SearchKyMonDonGiap>
            <SearchQuai lichVanNien={this.excelLichVanNien} dataAmDon={this.dataAmDon} dataDuongDon={this.dataDuongDon}></SearchQuai>
          </div>
        )}
      </div>
    );
  }
}

export default SearchPage;
