import Const from './Const';

export function YearToCan(year) {
  switch (year % 10) {
    case Const.Can.Canh.code:
      return Const.Can.Canh;
    case Const.Can.Tan.code:
      return Const.Can.Tan;
    case Const.Can.Nham.code:
      return Const.Can.Nham;
    case Const.Can.Qui.code:
      return Const.Can.Qui;
    case Const.Can.Giap.code:
      return Const.Can.Giap;
    case Const.Can.At.code:
      return Const.Can.At;
    case Const.Can.Binh.code:
      return Const.Can.Binh;
    case Const.Can.Dinh.code:
      return Const.Can.Dinh;
    case Const.Can.Mau.code:
      return Const.Can.Mau;
    case Const.Can.Ky.code:
      return Const.Can.Ky;
    default:
      break;
  }
}
export function YearToChi(year) {
  switch (year % 12) {
    case Const.Chi.Ty.code:
      return Const.Chi.Ty;
    case Const.Chi.Suu.code:
      return Const.Chi.Suu;
    case Const.Chi.Dan.code:
      return Const.Chi.Dan;
    case Const.Chi.Mao.code:
      return Const.Chi.Mao;
    case Const.Chi.Thin.code:
      return Const.Chi.Thin;
    case Const.Chi.Ti.code:
      return Const.Chi.Ti;
    case Const.Chi.Ngo.code:
      return Const.Chi.Ngo;
    case Const.Chi.Mui.code:
      return Const.Chi.Mui;
    case Const.Chi.Than.code:
      return Const.Chi.Than;
    case Const.Chi.Dau.code:
      return Const.Chi.Dau;
    case Const.Chi.Tuat.code:
      return Const.Chi.Tuat;
    case Const.Chi.Hoi.code:
      return Const.Chi.Hoi;
    default:
      break;
  }
}
export function MonthToCanChi(canYear, month) {
  const monthNew = month + 1;
  if (canYear.code === Const.Can.Giap.code || canYear.code === Const.Can.Ky.code) {
    switch (monthNew) {
      case 1: {
        return Const.CanChi.dinhSuu;
      }
      case 2: {
        return Const.CanChi.binhDan;
      }
      case 3: {
        return Const.CanChi.dinhMao;
      }
      case 4: {
        return Const.CanChi.mauThin;
      }
      case 5: {
        return Const.CanChi.kyTi;
      }
      case 6: {
        return Const.CanChi.canhNgo;
      }
      case 7: {
        return Const.CanChi.tanMui;
      }
      case 8: {
        return Const.CanChi.nhamThan;
      }
      case 9: {
        return Const.CanChi.quyDau;
      }
      case 10: {
        return Const.CanChi.giapTuat;
      }
      case 11: {
        return Const.CanChi.atHoi;
      }
      case 12: {
        return Const.CanChi.binhTy;
      }
      default:
        break;
    }
  } else if (canYear.code === Const.Can.At.code || canYear.code === Const.Can.Canh.code) {
    switch (monthNew) {
      case 1: {
        return Const.CanChi.kySuu;
      }
      case 2: {
        return Const.CanChi.mauDan;
      }
      case 3: {
        return Const.CanChi.kyMao;
      }
      case 4: {
        return Const.CanChi.canhThin;
      }
      case 5: {
        return Const.CanChi.tanTi;
      }
      case 6: {
        return Const.CanChi.nhamNgo;
      }
      case 7: {
        return Const.CanChi.quiMui;
      }
      case 8: {
        return Const.CanChi.giapThan;
      }
      case 9: {
        return Const.CanChi.atDau;
      }
      case 10: {
        return Const.CanChi.binhTuat;
      }
      case 11: {
        return Const.CanChi.dinhHoi;
      }
      case 12: {
        return Const.CanChi.mauTy;
      }
      default:
        break;
    }
  } else if (canYear.code === Const.Can.Binh.code || canYear.code === Const.Can.Tan.code) {
    switch (monthNew) {
      case 1: {
        return Const.CanChi.tanSuu;
      }
      case 2: {
        return Const.CanChi.canhDan;
      }
      case 3: {
        return Const.CanChi.tanMao;
      }
      case 4: {
        return Const.CanChi.nhamThin;
      }
      case 5: {
        return Const.CanChi.quyTi;
      }
      case 6: {
        return Const.CanChi.giapNgo;
      }
      case 7: {
        return Const.CanChi.atMui;
      }
      case 8: {
        return Const.CanChi.binhThan;
      }
      case 9: {
        return Const.CanChi.dinhDau;
      }
      case 10: {
        return Const.CanChi.mauTuat;
      }
      case 11: {
        return Const.CanChi.kyHoi;
      }
      case 12: {
        return Const.CanChi.canhTy;
      }
      default:
        break;
    }
  } else if (canYear.code === Const.Can.Dinh.code || canYear.code === Const.Can.Nham.code) {
    switch (monthNew) {
      case 1: {
        return Const.CanChi.quiSuu;
      }
      case 2: {
        return Const.CanChi.nhamDan;
      }
      case 3: {
        return Const.CanChi.quiMao;
      }
      case 4: {
        return Const.CanChi.giapThin;
      }
      case 5: {
        return Const.CanChi.atTi;
      }
      case 6: {
        return Const.CanChi.binhNgo;
      }
      case 7: {
        return Const.CanChi.dinhMui;
      }
      case 8: {
        return Const.CanChi.mauThan;
      }
      case 9: {
        return Const.CanChi.kydau;
      }
      case 10: {
        return Const.CanChi.canhTuat;
      }
      case 11: {
        return Const.CanChi.tanHoi;
      }
      case 12: {
        return Const.CanChi.nhamTy;
      }
      default:
        break;
    }
  } else if (canYear.code === Const.Can.Mau.code || canYear.code === Const.Can.Qui.code) {
    switch (monthNew) {
      case 1: {
        return Const.CanChi.atSuu;
      }
      case 2: {
        return Const.CanChi.giapDan;
      }
      case 3: {
        return Const.CanChi.atMao;
      }
      case 4: {
        return Const.CanChi.binhThin;
      }
      case 5: {
        return Const.CanChi.dinhTi;
      }
      case 6: {
        return Const.CanChi.mauNgo;
      }
      case 7: {
        return Const.CanChi.kyMui;
      }
      case 8: {
        return Const.CanChi.canhThan;
      }
      case 9: {
        return Const.CanChi.tanDau;
      }
      case 10: {
        return Const.CanChi.nhamTuat;
      }
      case 11: {
        return Const.CanChi.quyHoi;
      }
      case 12: {
        return Const.CanChi.giapTy;
      }
      default:
        break;
    }
  }
}
export async function DayToCanChi(selectedTime) {
  const timeDefault = new Date(1925, 0, 1).getTime();
  const timeCalculator = new Date(selectedTime).getTime();
  let result = Math.ceil(((timeCalculator - timeDefault) / (1000 * 60 * 60 * 24)) % 60);

  if ((result + 22) % 60 === 0) {
    return Const.CanChiArr[59];
  } else {
    return Const.CanChiArr.filter((v) => v.code === (result + 22) % 60)[0];
  }
}
export function HourToCanChi(canDay, chiHour) {
  const CanHour = convertHour(chiHour).code + 1;

  if (canDay === Const.Can.Giap.name || canDay === Const.Can.Ky.name) {
    switch (CanHour) {
      case 1: {
        return Const.CanChi.giapTy;
      }
      case 2: {
        return Const.CanChi.atSuu;
      }
      case 3: {
        return Const.CanChi.binhDan;
      }
      case 4: {
        return Const.CanChi.dinhMao;
      }
      case 5: {
        return Const.CanChi.mauThin;
      }
      case 6: {
        return Const.CanChi.kyTi;
      }
      case 7: {
        return Const.CanChi.canhNgo;
      }
      case 8: {
        return Const.CanChi.tanMui;
      }
      case 9: {
        return Const.CanChi.nhamThan;
      }
      case 10: {
        return Const.CanChi.quyDau;
      }
      case 11: {
        return Const.CanChi.giapTuat;
      }
      case 12: {
        return Const.CanChi.atHoi;
      }
      case 13: {
        return Const.CanChi.binhTy;
      }
      default:
        break;
    }
  } else if (canDay === Const.Can.At.name || canDay === Const.Can.Canh.name) {
    switch (CanHour) {
      case 1: {
        return Const.CanChi.binhTy;
      }
      case 2: {
        return Const.CanChi.dinhSuu;
      }
      case 3: {
        return Const.CanChi.mauDan;
      }
      case 4: {
        return Const.CanChi.kyMao;
      }
      case 5: {
        return Const.CanChi.canhThin;
      }
      case 6: {
        return Const.CanChi.tanTi;
      }
      case 7: {
        return Const.CanChi.nhamNgo;
      }
      case 8: {
        return Const.CanChi.quiMui;
      }
      case 9: {
        return Const.CanChi.giapThan;
      }
      case 10: {
        return Const.CanChi.atDau;
      }
      case 11: {
        return Const.CanChi.binhTuat;
      }
      case 12: {
        return Const.CanChi.dinhHoi;
      }
      case 13: {
        return Const.CanChi.mauTy;
      }
      default:
        break;
    }
  } else if (canDay === Const.Can.Binh.name || canDay === Const.Can.Tan.name) {
    switch (CanHour) {
      case 1: {
        return Const.CanChi.mauTy;
      }
      case 2: {
        return Const.CanChi.kySuu;
      }
      case 3: {
        return Const.CanChi.canhDan;
      }
      case 4: {
        return Const.CanChi.tanMao;
      }
      case 5: {
        return Const.CanChi.nhamThin;
      }
      case 6: {
        return Const.CanChi.quyTi;
      }
      case 7: {
        return Const.CanChi.giapNgo;
      }
      case 8: {
        return Const.CanChi.atMui;
      }
      case 9: {
        return Const.CanChi.binhThan;
      }
      case 10: {
        return Const.CanChi.dinhDau;
      }
      case 11: {
        return Const.CanChi.mauTuat;
      }
      case 12: {
        return Const.CanChi.kyHoi;
      }
      case 13: {
        return Const.CanChi.canhTy;
      }
      default:
        break;
    }
  } else if (canDay === Const.Can.Dinh.name || canDay === Const.Can.Nham.name) {
    switch (CanHour) {
      case 1: {
        return Const.CanChi.canhTy;
      }
      case 2: {
        return Const.CanChi.tanSuu;
      }
      case 3: {
        return Const.CanChi.nhamDan;
      }
      case 4: {
        return Const.CanChi.quiMao;
      }
      case 5: {
        return Const.CanChi.giapThin;
      }
      case 6: {
        return Const.CanChi.atTi;
      }
      case 7: {
        return Const.CanChi.binhNgo;
      }
      case 8: {
        return Const.CanChi.dinhMui;
      }
      case 9: {
        return Const.CanChi.mauThan;
      }
      case 10: {
        return Const.CanChi.kydau;
      }
      case 11: {
        return Const.CanChi.canhTuat;
      }
      case 12: {
        return Const.CanChi.tanHoi;
      }
      case 13: {
        return Const.CanChi.nhamTy;
      }
      default:
        break;
    }
  } else if (canDay === Const.Can.Mau.name || canDay === Const.Can.Qui.name) {
    switch (CanHour) {
      case 1: {
        return Const.CanChi.nhamTy;
      }
      case 2: {
        return Const.CanChi.quiSuu;
      }
      case 3: {
        return Const.CanChi.giapDan;
      }
      case 4: {
        return Const.CanChi.atMao;
      }
      case 5: {
        return Const.CanChi.binhThin;
      }
      case 6: {
        return Const.CanChi.dinhTi;
      }
      case 7: {
        return Const.CanChi.mauNgo;
      }
      case 8: {
        return Const.CanChi.kyMui;
      }
      case 9: {
        return Const.CanChi.canhThan;
      }
      case 10: {
        return Const.CanChi.tanDau;
      }
      case 11: {
        return Const.CanChi.nhamTuat;
      }
      case 12: {
        return Const.CanChi.quyHoi;
      }
      case 13: {
        return Const.CanChi.giapTy;
      }
      default:
        break;
    }
  }
}
export function convertHour(Hour) {
  switch (Hour) {
    case 23:
      return Const.HourArr.GioTy2;
    case 0:
      return Const.HourArr.GioTy;
    case 1:
    case 2:
      return Const.HourArr.GioSuu;
    case 3:
    case 4:
      return Const.HourArr.GioDan;
    case 5:
    case 6:
      return Const.HourArr.GioMao;
    case 7:
    case 8:
      return Const.HourArr.GioThin;
    case 9:
    case 10:
      return Const.HourArr.GioTi;
    case 11:
    case 12:
      return Const.HourArr.GioNgo;
    case 13:
    case 14:
      return Const.HourArr.GioMui;
    case 15:
    case 16:
      return Const.HourArr.GioThan;
    case 17:
    case 18:
      return Const.HourArr.GioDau;
    case 19:
    case 20:
      return Const.HourArr.GioTuat;
    case 21:
    case 22:
    case -2:
      return Const.HourArr.GioHoi;

    default:
      break;
  }
}
export function getQue(canTV, chiTV) {
  let tempCanTV = Const.CanArr.find((v) => v.name === canTV);
  let tempChiTV = Const.ChiArr.find((v) => v.name === chiTV);

  if (!tempChiTV || !tempCanTV) {
    return null;
  }
  const tenHanViet = tempCanTV.hanViet + ' ' + tempChiTV.hanViet;
  const result = Const.Que.find((v) => v.que === tenHanViet);
  return result;
}
export function getQueLast(canTV, chiTV) {
  let tempCanTV = Const.CanArr.find((v) => v.name === canTV);
  let tempChiTV = Const.ChiArr.find((v) => v.name === chiTV);

  if (!tempChiTV || !tempCanTV) {
    return null;
  }
  const tenHanViet = tempCanTV.hanViet + ' ' + tempChiTV.hanViet;
  const result = Const.Que.filter((v) => v.que === tenHanViet);
  return result[result.length - 1];
}
export function getPhiTinhMainNumber(chiDay, hour, sauDongChi) {
  if (chiDay === Const.Chi.Ty.code || chiDay === Const.Chi.Ngo.code || chiDay === Const.Chi.Mao.code || chiDay === Const.Chi.Dau.code) {
    switch (hour) {
      case 23:
      case 0:
        return sauDongChi ? 1 : 9;
      case 1:
      case 2:
        return sauDongChi ? 2 : 8;
      case 3:
      case 4:
        return sauDongChi ? 3 : 7;
      case 5:
      case 6:
        return sauDongChi ? 4 : 6;
      case 7:
      case 8:
        return sauDongChi ? 5 : 5;
      case 9:
      case 10:
        return sauDongChi ? 6 : 4;
      case 11:
      case 12:
        return sauDongChi ? 7 : 3;
      case 13:
      case 14:
        return sauDongChi ? 8 : 2;
      case 15:
      case 16:
        return sauDongChi ? 9 : 1;
      case 17:
      case 18:
        return sauDongChi ? 1 : 9;
      case 19:
      case 20:
        return sauDongChi ? 2 : 8;
      case 21:
      case 22:
        return sauDongChi ? 3 : 7;

      default:
        break;
    }
  }
  if (chiDay === Const.Chi.Dan.code || chiDay === Const.Chi.Than.code || chiDay === Const.Chi.Ti.code || chiDay === Const.Chi.Hoi.code) {
    switch (hour) {
      case 23:
      case 0:
        return sauDongChi ? 7 : 3;
      case 1:
      case 2:
        return sauDongChi ? 8 : 2;
      case 3:
      case 4:
        return sauDongChi ? 9 : 1;
      case 5:
      case 6:
        return sauDongChi ? 1 : 9;
      case 7:
      case 8:
        return sauDongChi ? 2 : 8;
      case 9:
      case 10:
        return sauDongChi ? 3 : 7;
      case 11:
      case 12:
        return sauDongChi ? 4 : 6;
      case 13:
      case 14:
        return sauDongChi ? 5 : 5;
      case 15:
      case 16:
        return sauDongChi ? 6 : 4;
      case 17:
      case 18:
        return sauDongChi ? 7 : 3;
      case 19:
      case 20:
        return sauDongChi ? 8 : 2;
      case 21:
      case 22:
        return sauDongChi ? 9 : 1;

      default:
        break;
    }
  }
  if (chiDay === Const.Chi.Thin.code || chiDay === Const.Chi.Tuat.code || chiDay === Const.Chi.Suu.code || chiDay === Const.Chi.Mui.code) {
    switch (hour) {
      case 23:
      case 0:
        return sauDongChi ? 4 : 6;
      case 1:
      case 2:
        return sauDongChi ? 5 : 5;
      case 3:
      case 4:
        return sauDongChi ? 6 : 4;
      case 5:
      case 6:
        return sauDongChi ? 7 : 3;
      case 7:
      case 8:
        return sauDongChi ? 8 : 2;
      case 9:
      case 10:
        return sauDongChi ? 9 : 1;
      case 11:
      case 12:
        return sauDongChi ? 1 : 9;
      case 13:
      case 14:
        return sauDongChi ? 2 : 8;
      case 15:
      case 16:
        return sauDongChi ? 3 : 7;
      case 17:
      case 18:
        return sauDongChi ? 4 : 6;
      case 19:
      case 20:
        return sauDongChi ? 5 : 5;
      case 21:
      case 22:
        return sauDongChi ? 6 : 4;

      default:
        break;
    }
  }
}
export function getNamAp(canChiDay) {
  const result = Const.Que.find((v) => v.queViet === canChiDay);

  return result;
}
export function thapNhiBatTu(dateTime) {
  var date1 = new Date(2019, 2, 14);
  var date2 = new Date(dateTime);
  const msBetweenDate = date2.getTime() - date1.getTime();

  const countDays = msBetweenDate / (1000 * 3600 * 24);

  let surplusForStar = null;
  if (countDays < 0) {
    surplusForStar = (Math.ceil(countDays) % 28) + 27;
  } else {
    surplusForStar = (Math.ceil(countDays) % 28) - 1;
  }
  if (surplusForStar === -1) {
    surplusForStar = 27;
  } else if (surplusForStar < -1) {
    surplusForStar = Math.abs((Math.ceil(countDays) % 28) - 1);
  }

  const star = Const.nhiThapBatTu.find((v) => v.code === surplusForStar);
  return star;
}
export function ngayGioHoangDao(month, chiDateTime) {
  //chidateTime la so thu tu
  const correctMonthNumber = month - 1 === -1 ? 0 : month - 1;
  if (chiDateTime === Const.Chi.Ty.code) {
    switch (correctMonthNumber) {
      case 0:
      case 6:
        return Const.HoangDao.thanhLong;
      case 1:
      case 7:
        return Const.HoangDao.tuMenh;
      case 2:
      case 8:
        return Const.HoangDao.thienLao;
      case 3:
      case 9:
        return Const.HoangDao.bachHo;
      case 4:
      case 10:
        return Const.HoangDao.kimQuy;
      case 5:
      case 11:
        return Const.HoangDao.thienHinh;
      default:
        break;
    }
  }
  if (chiDateTime === Const.Chi.Suu.code) {
    switch (correctMonthNumber) {
      case 0:
      case 6:
        return Const.HoangDao.minhDuong;
      case 1:
      case 7:
        return Const.HoangDao.cauTran;
      case 2:
      case 8:
        return Const.HoangDao.huyenVu;
      case 3:
      case 9:
        return Const.HoangDao.ngocDuong;
      case 4:
      case 10:
        return Const.HoangDao.thienDuc;
      case 5:
      case 11:
        return Const.HoangDao.chuTuoc;
      default:
        break;
    }
  }
  if (chiDateTime === Const.Chi.Dan.code) {
    switch (correctMonthNumber) {
      case 0:
      case 6:
        return Const.HoangDao.thienHinh;
      case 1:
      case 7:
        return Const.HoangDao.thanhLong;
      case 2:
      case 8:
        return Const.HoangDao.tuMenh;
      case 3:
      case 9:
        return Const.HoangDao.thienLao;
      case 4:
      case 10:
        return Const.HoangDao.bachHo;
      case 5:
      case 11:
        return Const.HoangDao.kimQuy;
      default:
        break;
    }
  }
  if (chiDateTime === Const.Chi.Mao.code) {
    switch (correctMonthNumber) {
      case 0:
      case 6:
        return Const.HoangDao.chuTuoc;
      case 1:
      case 7:
        return Const.HoangDao.minhDuong;
      case 2:
      case 8:
        return Const.HoangDao.cauTran;
      case 3:
      case 9:
        return Const.HoangDao.huyenVu;
      case 4:
      case 10:
        return Const.HoangDao.ngocDuong;
      case 5:
      case 11:
        return Const.HoangDao.thienDuc;
      default:
        break;
    }
  }
  if (chiDateTime === Const.Chi.Thin.code) {
    switch (correctMonthNumber) {
      case 0:
      case 6:
        return Const.HoangDao.kimQuy;
      case 1:
      case 7:
        return Const.HoangDao.thienHinh;
      case 2:
      case 8:
        return Const.HoangDao.thanhLong;
      case 3:
      case 9:
        return Const.HoangDao.tuMenh;
      case 4:
      case 10:
        return Const.HoangDao.thienLao;
      case 5:
      case 11:
        return Const.HoangDao.bachHo;
      default:
        break;
    }
  }
  if (chiDateTime === Const.Chi.Ti.code) {
    switch (correctMonthNumber) {
      case 0:
      case 6:
        return Const.HoangDao.thienDuc;
      case 1:
      case 7:
        return Const.HoangDao.chuTuoc;
      case 2:
      case 8:
        return Const.HoangDao.minhDuong;
      case 3:
      case 9:
        return Const.HoangDao.cauTran;
      case 4:
      case 10:
        return Const.HoangDao.huyenVu;
      case 5:
      case 11:
        return Const.HoangDao.ngocDuong;
      default:
        break;
    }
  }
  if (chiDateTime === Const.Chi.Ngo.code) {
    switch (correctMonthNumber) {
      case 0:
      case 6:
        return Const.HoangDao.bachHo;
      case 1:
      case 7:
        return Const.HoangDao.kimQuy;
      case 2:
      case 8:
        return Const.HoangDao.thienHinh;
      case 3:
      case 9:
        return Const.HoangDao.thanhLong;
      case 4:
      case 10:
        return Const.HoangDao.tuMenh;
      case 5:
      case 11:
        return Const.HoangDao.thienLao;
      default:
        break;
    }
  }
  if (chiDateTime === Const.Chi.Mui.code) {
    switch (correctMonthNumber) {
      case 0:
      case 6:
        return Const.HoangDao.ngocDuong;
      case 1:
      case 7:
        return Const.HoangDao.thienDuc;
      case 2:
      case 8:
        return Const.HoangDao.chuTuoc;
      case 3:
      case 9:
        return Const.HoangDao.minhDuong;
      case 4:
      case 10:
        return Const.HoangDao.cauTran;
      case 5:
      case 11:
        return Const.HoangDao.huyenVu;
      default:
        break;
    }
  }
  if (chiDateTime === Const.Chi.Than.code) {
    switch (correctMonthNumber) {
      case 0:
      case 6:
        return Const.HoangDao.thienLao;
      case 1:
      case 7:
        return Const.HoangDao.bachHo;
      case 2:
      case 8:
        return Const.HoangDao.kimQuy;
      case 3:
      case 9:
        return Const.HoangDao.thienHinh;
      case 4:
      case 10:
        return Const.HoangDao.thanhLong;
      case 5:
      case 11:
        return Const.HoangDao.tuMenh;
      default:
        break;
    }
  }
  if (chiDateTime === Const.Chi.Dau.code) {
    switch (correctMonthNumber) {
      case 0:
      case 6:
        return Const.HoangDao.huyenVu;
      case 1:
      case 7:
        return Const.HoangDao.ngocDuong;
      case 2:
      case 8:
        return Const.HoangDao.thienDuc;
      case 3:
      case 9:
        return Const.HoangDao.chuTuoc;
      case 4:
      case 10:
        return Const.HoangDao.minhDuong;
      case 5:
      case 11:
        return Const.HoangDao.cauTran;
      default:
        break;
    }
  }
  if (chiDateTime === Const.Chi.Tuat.code) {
    switch (correctMonthNumber) {
      case 0:
      case 6:
        return Const.HoangDao.tuMenh;
      case 1:
      case 7:
        return Const.HoangDao.thienLao;
      case 2:
      case 8:
        return Const.HoangDao.bachHo;
      case 3:
      case 9:
        return Const.HoangDao.kimQuy;
      case 4:
      case 10:
        return Const.HoangDao.thienHinh;
      case 5:
      case 11:
        return Const.HoangDao.thanhLong;
      default:
        break;
    }
  }
  if (chiDateTime === Const.Chi.Hoi.code) {
    switch (correctMonthNumber) {
      case 0:
      case 6:
        return Const.HoangDao.cauTran;
      case 1:
      case 7:
        return Const.HoangDao.huyenVu;
      case 2:
      case 8:
        return Const.HoangDao.ngocDuong;
      case 3:
      case 9:
        return Const.HoangDao.thienDuc;
      case 4:
      case 10:
        return Const.HoangDao.chuTuoc;
      case 5:
      case 11:
        return Const.HoangDao.minhDuong;
      default:
        break;
    }
  }
}
export function gioHoangDao(chiNgay, chiGio) {
  if (chiNgay === Const.Chi.Ty.code || chiNgay === Const.Chi.Ngo.code) {
    switch (chiGio) {
      case 1:
        return Const.HoangDao.kimQuy;
      case 2:
        return Const.HoangDao.thienDuc;
      case 3:
        return Const.HoangDao.bachHo;
      case 4:
        return Const.HoangDao.ngocDuong;
      case 5:
        return Const.HoangDao.thienLao;
      case 6:
        return Const.HoangDao.huyenVu;
      case 7:
        return Const.HoangDao.tuMenh;
      case 8:
        return Const.HoangDao.cauTran;
      case 9:
        return Const.HoangDao.thanhLong;
      case 10:
        return Const.HoangDao.minhDuong;
      case 11:
        return Const.HoangDao.thienHinh;
      case 12:
        return Const.HoangDao.chuTuoc;

      default:
        break;
    }
  }
  if (chiNgay === Const.Chi.Suu.code || chiNgay === Const.Chi.Mui.code) {
    switch (chiGio) {
      case 1:
        return Const.HoangDao.thienHinh;
      case 2:
        return Const.HoangDao.chuTuoc;
      case 3:
        return Const.HoangDao.kimQuy;
      case 4:
        return Const.HoangDao.thienDuc;
      case 5:
        return Const.HoangDao.bachHo;
      case 6:
        return Const.HoangDao.ngocDuong;
      case 7:
        return Const.HoangDao.thienLao;
      case 8:
        return Const.HoangDao.huyenVu;
      case 9:
        return Const.HoangDao.thanhLong;
      case 10:
        return Const.HoangDao.minhDuong;
      case 11:
        return Const.HoangDao.thanhLong;
      case 12:
        return Const.HoangDao.minhDuong;

      default:
        break;
    }
  }
  if (chiNgay === Const.Chi.Dan.code || chiNgay === Const.Chi.Than.code) {
    switch (chiGio) {
      case 1:
        return Const.HoangDao.thanhLong;
      case 2:
        return Const.HoangDao.minhDuong;
      case 3:
        return Const.HoangDao.thienHinh;
      case 4:
        return Const.HoangDao.chuTuoc;
      case 5:
        return Const.HoangDao.kimQuy;
      case 6:
        return Const.HoangDao.thienDuc;
      case 7:
        return Const.HoangDao.bachHo;
      case 8:
        return Const.HoangDao.ngocDuong;
      case 9:
        return Const.HoangDao.thienLao;
      case 10:
        return Const.HoangDao.huyenVu;
      case 11:
        return Const.HoangDao.tuMenh;
      case 12:
        return Const.HoangDao.cauTran;

      default:
        break;
    }
  }
  if (chiNgay === Const.Chi.Mao.code || chiNgay === Const.Chi.Dau.code) {
    switch (chiGio) {
      case 1:
        return Const.HoangDao.tuMenh;
      case 2:
        return Const.HoangDao.cauTran;
      case 3:
        return Const.HoangDao.thanhLong;
      case 4:
        return Const.HoangDao.minhDuong;
      case 5:
        return Const.HoangDao.thienHinh;
      case 6:
        return Const.HoangDao.chuTuoc;
      case 7:
        return Const.HoangDao.kimQuy;
      case 8:
        return Const.HoangDao.thienDuc;
      case 9:
        return Const.HoangDao.bachHo;
      case 10:
        return Const.HoangDao.ngocDuong;
      case 11:
        return Const.HoangDao.thienLao;
      case 12:
        return Const.HoangDao.huyenVu;

      default:
        break;
    }
  }
  if (chiNgay === Const.Chi.Thin.code || chiNgay === Const.Chi.Tuat.code) {
    switch (chiGio) {
      case 1:
        return Const.HoangDao.thienLao;
      case 2:
        return Const.HoangDao.huyenVu;
      case 3:
        return Const.HoangDao.tuMenh;
      case 4:
        return Const.HoangDao.cauTran;
      case 5:
        return Const.HoangDao.thanhLong;
      case 6:
        return Const.HoangDao.minhDuong;
      case 7:
        return Const.HoangDao.thienHinh;
      case 8:
        return Const.HoangDao.chuTuoc;
      case 9:
        return Const.HoangDao.kimQuy;
      case 10:
        return Const.HoangDao.thienDuc;
      case 11:
        return Const.HoangDao.bachHo;
      case 12:
        return Const.HoangDao.ngocDuong;

      default:
        break;
    }
  }
  if (chiNgay === Const.Chi.Ti.code || chiNgay === Const.Chi.Hoi.code) {
    switch (chiGio) {
      case 1:
        return Const.HoangDao.bachHo;
      case 2:
        return Const.HoangDao.ngocDuong;
      case 3:
        return Const.HoangDao.thienLao;
      case 4:
        return Const.HoangDao.huyenVu;
      case 5:
        return Const.HoangDao.tuMenh;
      case 6:
        return Const.HoangDao.cauTran;
      case 7:
        return Const.HoangDao.thanhLong;
      case 8:
        return Const.HoangDao.minhDuong;
      case 9:
        return Const.HoangDao.thienHinh;
      case 10:
        return Const.HoangDao.chuTuoc;
      case 11:
        return Const.HoangDao.kimQuy;
      case 12:
        return Const.HoangDao.thienDuc;

      default:
        break;
    }
  }
}
export function tuongSinhTuongKhac(Ngay, Gio) {
  if (
    ((Ngay === 4 || Ngay === 9) && (Gio === 1 || Gio === 6)) ||
    ((Ngay === 1 || Ngay === 6) && (Gio === 3 || Gio === 8)) ||
    ((Ngay === 3 || Ngay === 8) && (Gio === 2 || Gio === 7)) ||
    ((Gio === 4 || Gio === 9) && (Ngay === 1 || Ngay === 6)) ||
    ((Gio === 1 || Gio === 6) && (Ngay === 3 || Ngay === 8)) ||
    ((Gio === 3 || Gio === 8) && (Ngay === 2 || Ngay === 7))
  ) {
    return 'Tương Sinh';
  } else if (
    ((Ngay === 1 || Ngay === 6) && (Gio === 2 || Gio === 7)) ||
    ((Ngay === 2 || Ngay === 7) && (Gio === 4 || Gio === 9)) ||
    ((Ngay === 4 || Ngay === 9) && (Gio === 3 || Gio === 8)) ||
    ((Gio === 1 || Gio === 6) && (Ngay === 2 || Ngay === 7)) ||
    ((Gio === 2 || Gio === 7) && (Ngay === 4 || Ngay === 9)) ||
    ((Gio === 4 || Gio === 9) && (Ngay === 3 || Ngay === 8))
  ) {
    return 'Tương Khắc';
  } else if (
    ((Ngay === 4 || Ngay === 9) && (Gio === 9 || Gio === 4)) ||
    ((Ngay === 3 || Ngay === 8) && (Gio === 3 || Gio === 8)) ||
    ((Ngay === 2 || Ngay === 7) && (Gio === 2 || Gio === 7)) ||
    ((Ngay === 1 || Ngay === 6) && (Gio === 1 || Gio === 6))
  ) {
    return 'Hà Đồ Quái';
  } else {
    return ' ';
  }
}
export function tinhHaDoQuai(canNgay, chiNgay, canGio, chiGio) {
  if (canNgay && chiNgay && canGio && chiGio) {
    const queNgay = getQue(canNgay, chiNgay);
    const queGio = getQue(canGio, chiGio);
    //huyền khôgn ngũ hành là số trên
    //huyền không quái vận là số dưới
    const trenNgay = queNgay?.huyenKhongNguHanh;
    const duoiNgay = queNgay?.huyenKhongQuaiVan;
    const trenGio = queGio?.huyenKhongNguHanh;
    const duoiGio = queGio?.huyenKhongQuaiVan;
    if (trenNgay + trenGio === 10) {
      return 'Hợp Thập Quái';
    } else if (trenNgay + trenGio === 10 && duoiNgay + duoiGio === 10) {
      return 'Hợp Thập';
    } else if (trenNgay === trenGio) {
      return 'Cùng Quái';
    } else if (trenNgay + trenGio === 5) {
      return 'Hợp Ngũ Quái';
    } else if (trenNgay + trenGio === 15) {
      return 'H.T.Ngũ Quái';
      //trên ngày so sánh với trên giờ nếu trên ngày là 1 || 6 thì đó là "thủy"
    } else {
      return tuongSinhTuongKhac(trenNgay, trenGio);
    }
  }
}
export function tinhHaDoVan(canNgay, chiNgay, canGio, chiGio) {
  if (canNgay && chiNgay && canGio && chiGio) {
    const queNgay = getQue(canNgay, chiNgay);
    const queGio = getQue(canGio, chiGio);
    //huyền khôgn ngũ hành là số trên
    //huyền không quái vận là số dưới
    const trenNgay = queNgay?.huyenKhongNguHanh;
    const duoiNgay = queNgay?.huyenKhongQuaiVan;
    const trenGio = queGio?.huyenKhongNguHanh;
    const duoiGio = queGio?.huyenKhongQuaiVan;
    if (duoiNgay + duoiGio === 10) {
      return 'Hợp Thập Vận';
    } else if (trenNgay + trenGio === 10 && duoiNgay + duoiGio === 10) {
      return 'Hợp Thập';
    } else if (duoiNgay === duoiGio) {
      return 'Cùng Vận';
    } else if (duoiNgay + duoiGio === 5) {
      return 'Hợp Ngũ Vận';
    } else if (duoiNgay + duoiGio === 15) {
      return 'H.T.Ngũ Vận';
    } else if (
      (duoiNgay === 1 && duoiGio === 6) ||
      (duoiGio === 1 && duoiNgay === 6) ||
      (duoiNgay === 2 && duoiGio === 7) ||
      (duoiGio === 2 && duoiNgay === 7) ||
      (duoiNgay === 3 && duoiGio === 8) ||
      (duoiGio === 3 && duoiNgay === 8) ||
      (duoiNgay === 4 && duoiGio === 9) ||
      (duoiGio === 4 && duoiNgay === 9)
    ) {
      return 'Hà Đồ Vận';
    } else {
      return ' ';
    }
  }
}
export function saoThanSatThang(chiThang) {
  switch (chiThang) {
    case Const.Chi.Ty.name:
      return Const.saoThanSatThangThienDucToTaiSat.thangTy;
    case Const.Chi.Suu.name:
      return Const.saoThanSatThangThienDucToTaiSat.thangSuu;

    case Const.Chi.Dan.name:
      return Const.saoThanSatThangThienDucToTaiSat.thangDan;
    case Const.Chi.Mao.name:
      return Const.saoThanSatThangThienDucToTaiSat.thangMao;
    case Const.Chi.Thin.name:
      return Const.saoThanSatThangThienDucToTaiSat.thangThin;

    case Const.Chi.Ti.name:
      return Const.saoThanSatThangThienDucToTaiSat.thangTi;
    case Const.Chi.Ngo.name:
      return Const.saoThanSatThangThienDucToTaiSat.thangNgo;

    case Const.Chi.Mui.name:
      return Const.saoThanSatThangThienDucToTaiSat.thangMui;

    case Const.Chi.Than.name:
      return Const.saoThanSatThangThienDucToTaiSat.thangThan;
    case Const.Chi.Dau.name:
      return Const.saoThanSatThangThienDucToTaiSat.thangDau;

    case Const.Chi.Tuat.name:
      return Const.saoThanSatThangThienDucToTaiSat.thangTuat;
    case Const.Chi.Hoi.name:
      return Const.saoThanSatThangThienDucToTaiSat.thangHoi;
    default:
      break;
  }
}

export function getNhiThapKienTru(monthLunar, chiNgay, canDay) {
  const correctMonthNumber = monthLunar;

  switch (correctMonthNumber) {
    //dần
    case 1:
      switch (chiNgay) {
        case Const.Chi.Ty.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Suu.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Dan.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Mao.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Thin.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Man.name, dongCong: 1 };
            case 6:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Man.name, dongCong: 1 };

            default:
              break;
          }
          break;

        case Const.Chi.Ti.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Ngo.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };
            case 8:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };

            default:
              break;
          }
          break;

        case Const.Chi.Mui.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Chap.name, dongCong: 1 };
            case 7:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Than.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 2:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Dau.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Nguy.name, dongCong: 1 };
            case 3:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Tuat.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Thanh.name, dongCong: 1 };
            case 4:
              return { truc: Const.trucSao.Thanh.name, dongCong: 1 };
            case 6:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Thanh.name, dongCong: 1 };

            default:
              break;
          }
          break;
        case Const.Chi.Hoi.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    //Mão
    case 2:
      switch (chiNgay) {
        case Const.Chi.Ty.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Suu.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Khai.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Khai.name, dongCong: 1 };
            case 9:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Dan.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Mao.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Thin.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Tru.name, dongCong: 1 };
            case 6:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Tru.name, dongCong: 1 };

            default:
              break;
          }
          break;
        case Const.Chi.Ti.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Man.name, dongCong: 3 };
            case 3:
              return { truc: Const.trucSao.Man.name, dongCong: 3 };
            case 5:
              return { truc: Const.trucSao.Man.name, dongCong: 3 };
            case 7:
              return { truc: Const.trucSao.Man.name, dongCong: 3 };
            case 9:
              return { truc: Const.trucSao.Man.name, dongCong: 3 };

            default:
              break;
          }
          break;
        case Const.Chi.Ngo.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Mui.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };
            case 5:
              return { truc: Const.trucSao.Dinh.name, dongCong: 1 };
            case 7:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Than.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Dau.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 3:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Tuat.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Nguy.name, dongCong: 1 };
            case 4:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Nguy.name, dongCong: 1 };
            case 8:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Hoi.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Thanh.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Thanh.name, dongCong: 3 };

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    //Thìn
    case 3:
      switch (chiNgay) {
        case Const.Chi.Ty.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Thanh.name, dongCong: 3 };
            case 4:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Suu.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Thu.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Thu.name, dongCong: 1 };
            case 9:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Dan.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Khai.name, dongCong: 3 };
            case 2:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };
            case 4:
              return { truc: Const.trucSao.Khai.name, dongCong: 3 };
            case 6:
              return { truc: Const.trucSao.Khai.name, dongCong: 3 };
            case 8:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Mao.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Thin.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Kien.name, dongCong: 1 };
            case 6:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Kien.name, dongCong: 1 };

            default:
              break;
          }
          break;

        case Const.Chi.Ti.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Tru.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Tru.name, dongCong: 5 };
            case 9:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Ngo.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Man.name, dongCong: 1 };
            case 2:
              return { truc: Const.trucSao.Man.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Man.name, dongCong: 3 };
            case 8:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Mui.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Binh.name, dongCong: 1 };
            case 7:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Than.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };
            case 4:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Dau.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 5:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 7:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Tuat.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 4:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 8:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Hoi.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Nguy.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };
            case 7:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Nguy.name, dongCong: 5 };

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    //Tỵ
    case 4:
      switch (chiNgay) {
        case Const.Chi.Ty.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Nguy.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Suu.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Thanh.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Thanh.name, dongCong: 1 };
            case 9:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Dan.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Mao.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };
            case 3:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };
            case 5:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 7:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Thin.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Be.name, dongCong: 3 };
            case 2:
              return { truc: Const.trucSao.Be.name, dongCong: 3 };
            case 4:
              return { truc: Const.trucSao.Be.name, dongCong: 1 };
            case 6:
              return { truc: Const.trucSao.Be.name, dongCong: 3 };
            case 8:
              return { truc: Const.trucSao.Be.name, dongCong: 1 };

            default:
              break;
          }
          break;

        case Const.Chi.Ti.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Ngo.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Tru.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Tru.name, dongCong: 5 };
            case 4:
              return { truc: Const.trucSao.Tru.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Mui.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Man.name, dongCong: 4 };
            case 3:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Man.name, dongCong: 4 };

            default:
              break;
          }
          break;

        case Const.Chi.Than.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Binh.name, dongCong: 1 };
            case 2:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Binh.name, dongCong: 1 };
            case 6:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Dau.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Tuat.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Chap.name, dongCong: 1 };
            case 4:
              return { truc: Const.trucSao.Chap.name, dongCong: 3 };
            case 6:
              return { truc: Const.trucSao.Chap.name, dongCong: 1 };
            case 8:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Hoi.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    //Ngọ
    case 5:
      switch (chiNgay) {
        case Const.Chi.Ty.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 4:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Suu.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Nguy.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Nguy.name, dongCong: 1 };
            case 9:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Dan.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 8:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Mao.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Thin.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };
            case 4:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };
            case 8:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Ti.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Be.name, dongCong: 4 };
            case 3:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Be.name, dongCong: 4 };
            case 7:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Ngo.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Kien.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Mui.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Tru.name, dongCong: 3 };
            case 3:
              return { truc: Const.trucSao.Tru.name, dongCong: 3 };
            case 5:
              return { truc: Const.trucSao.Tru.name, dongCong: 1 };
            case 7:
              return { truc: Const.trucSao.Tru.name, dongCong: 3 };
            case 9:
              return { truc: Const.trucSao.Tru.name, dongCong: 3 };

            default:
              break;
          }
          break;

        case Const.Chi.Than.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Man.name, dongCong: 3 };
            case 2:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Man.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Man.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Man.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Dau.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Tuat.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Hoi.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Chap.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 7:
              return { truc: Const.trucSao.Chap.name, dongCong: 3 };
            case 9:
              return { truc: Const.trucSao.Chap.name, dongCong: 3 };

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    //Mùi
    case 6:
      switch (chiNgay) {
        case Const.Chi.Ty.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Chap.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Chap.name, dongCong: 3 };
            case 6:
              return { truc: Const.trucSao.Chap.name, dongCong: 5 };
            case 8:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Suu.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 3:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 9:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Dan.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Nguy.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Mao.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 3:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 5:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Thin.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Thu.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Thu.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Thu.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Ti.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 5:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 7:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Ngo.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Be.name, dongCong: 3 };
            case 2:
              return { truc: Const.trucSao.Be.name, dongCong: 3 };
            case 4:
              return { truc: Const.trucSao.Be.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Be.name, dongCong: 3 };
            case 8:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Mui.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Kien.name, dongCong: 1 };
            case 7:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Than.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Tru.name, dongCong: 3 };
            case 2:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Dau.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Man.name, dongCong: 4 };
            case 3:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Man.name, dongCong: 4 };
            case 7:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Tuat.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Binh.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Hoi.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };
            case 9:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    //Thân
    case 7:
      switch (chiNgay) {
        case Const.Chi.Ty.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };
            case 8:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Suu.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Chap.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Chap.name, dongCong: 1 };
            case 9:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Dan.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Pha.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 6:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Mao.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Nguy.name, dongCong: 5 };
            case 5:
              return { truc: Const.trucSao.Nguy.name, dongCong: 1 };
            case 7:
              return { truc: Const.trucSao.Nguy.name, dongCong: 5 };
            case 9:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Thin.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 4:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Ti.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Ngo.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };
            case 4:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };
            case 8:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };

            default:
              break;
          }
          break;

        case Const.Chi.Mui.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Be.name, dongCong: 4 };
            case 3:
              return { truc: Const.trucSao.Be.name, dongCong: 5 };
            case 5:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Be.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Be.name, dongCong: 5 };

            default:
              break;
          }
          break;

        case Const.Chi.Than.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Kien.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Kien.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Kien.name, dongCong: 1 };

            default:
              break;
          }
          break;
        case Const.Chi.Dau.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Tru.name, dongCong: 1 };
            case 3:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };
            case 7:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Tuat.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Hoi.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    //Dậu
    case 8:
      switch (chiNgay) {
        case Const.Chi.Ty.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Binh.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Binh.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Binh.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Suu.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Dinh.name, dongCong: 3 };
            case 3:
              return { truc: Const.trucSao.Dinh.name, dongCong: 3 };
            case 5:
              return { truc: Const.trucSao.Dinh.name, dongCong: 3 };
            case 7:
              return { truc: Const.trucSao.Dinh.name, dongCong: 3 };
            case 9:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Dan.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Chap.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Mao.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Pha.name, dongCong: 3 };
            case 5:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 7:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Pha.name, dongCong: 3 };

            default:
              break;
          }
          break;
        case Const.Chi.Thin.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Nguy.name, dongCong: 5 };
            case 4:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Ti.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Ngo.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Thu.name, dongCong: 5 };
            case 4:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Thu.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Thu.name, dongCong: 4 };

            default:
              break;
          }
          break;

        case Const.Chi.Mui.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 3:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 5:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };

            default:
              break;
          }
          break;

        case Const.Chi.Than.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Be.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Be.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Be.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Be.name, dongCong: 5 };
            case 8:
              return { truc: Const.trucSao.Be.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Dau.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Tuat.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Tru.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Hoi.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Man.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Man.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Man.name, dongCong: 5 };
            case 9:
              return { truc: Const.trucSao.Man.name, dongCong: 5 };

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    //Tuất
    case 9:
      switch (chiNgay) {
        case Const.Chi.Ty.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Man.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Man.name, dongCong: 5 };
            case 8:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Suu.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Binh.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Binh.name, dongCong: 5 };
            case 9:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Dan.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };
            case 8:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Mao.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Chap.name, dongCong: 5 };
            case 3:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 5:
              return { truc: Const.trucSao.Chap.name, dongCong: 1 };
            case 7:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Chap.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Thin.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Pha.name, dongCong: 3 };
            case 2:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 6:
              return { truc: Const.trucSao.Pha.name, dongCong: 3 };
            case 8:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };

            default:
              break;
          }
          break;

        case Const.Chi.Ti.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Nguy.name, dongCong: 3 };
            case 3:
              return { truc: Const.trucSao.Nguy.name, dongCong: 3 };
            case 5:
              return { truc: Const.trucSao.Nguy.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Nguy.name, dongCong: 3 };
            case 9:
              return { truc: Const.trucSao.Nguy.name, dongCong: 3 };

            default:
              break;
          }
          break;
        case Const.Chi.Ngo.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 8:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };

            default:
              break;
          }
          break;

        case Const.Chi.Mui.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Thu.name, dongCong: 4 };
            case 3:
              return { truc: Const.trucSao.Thu.name, dongCong: 4 };
            case 5:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Thu.name, dongCong: 5 };

            default:
              break;
          }
          break;

        case Const.Chi.Than.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Dau.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Be.name, dongCong: 3 };
            case 7:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Tuat.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Kien.name, dongCong: 5 };
            case 8:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Hoi.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Tru.name, dongCong: 1 };
            case 3:
              return { truc: Const.trucSao.Tru.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Tru.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Tru.name, dongCong: 5 };
            case 9:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    //Hợi
    case 10:
      switch (chiNgay) {
        case Const.Chi.Ty.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Suu.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Man.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Man.name, dongCong: 1 };
            case 9:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Dan.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Binh.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Binh.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Binh.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Mao.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };
            case 3:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };
            case 5:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };
            case 7:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Thin.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Chap.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Chap.name, dongCong: 1 };

            default:
              break;
          }
          break;

        case Const.Chi.Ti.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Pha.name, dongCong: 4 };
            case 7:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 9:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Ngo.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Nguy.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Nguy.name, dongCong: 1 };
            case 8:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };

            default:
              break;
          }
          break;

        case Const.Chi.Mui.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 3:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 5:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };

            default:
              break;
          }
          break;

        case Const.Chi.Than.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Thu.name, dongCong: 1 };
            case 2:
              return { truc: Const.trucSao.Thu.name, dongCong: 3 };
            case 4:
              return { truc: Const.trucSao.Thu.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Thu.name, dongCong: 3 };
            case 8:
              return { truc: Const.trucSao.Thu.name, dongCong: 3 };

            default:
              break;
          }
          break;
        case Const.Chi.Dau.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 3:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 5:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Tuat.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Be.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Be.name, dongCong: 1 };
            case 8:
              return { truc: Const.trucSao.Be.name, dongCong: 1 };

            default:
              break;
          }
          break;
        case Const.Chi.Hoi.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Kien.name, dongCong: 3 };
            case 7:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Kien.name, dongCong: 3 };

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    //Tý
    case 11:
      switch (chiNgay) {
        case Const.Chi.Ty.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Suu.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };
            case 3:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };
            case 5:
              return { truc: Const.trucSao.Tru.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };

            default:
              break;
          }
          break;

        case Const.Chi.Dan.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Man.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Man.name, dongCong: 5 };
            case 4:
              return { truc: Const.trucSao.Man.name, dongCong: 3 };
            case 6:
              return { truc: Const.trucSao.Man.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Man.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Mao.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Binh.name, dongCong: 4 };
            case 7:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Thin.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Ti.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 3:
              return { truc: Const.trucSao.Chap.name, dongCong: 5 };
            case 5:
              return { truc: Const.trucSao.Chap.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Chap.name, dongCong: 1 };
            case 9:
              return { truc: Const.trucSao.Chap.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Ngo.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Pha.name, dongCong: 3 };
            case 4:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Mui.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Nguy.name, dongCong: 1 };
            case 7:
              return { truc: Const.trucSao.Nguy.name, dongCong: 5 };
            case 9:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };

            default:
              break;
          }
          break;

        case Const.Chi.Than.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Thanh.name, dongCong: 3 };
            case 2:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 4:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Dau.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Tuat.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Khai.name, dongCong: 1 };
            case 4:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Khai.name, dongCong: 1 };
            case 8:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Hoi.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Be.name, dongCong: 1 };
            case 3:
              return { truc: Const.trucSao.Be.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Be.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Be.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Be.name, dongCong: 5 };

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    //S
    case 0:
      switch (chiNgay) {
        case Const.Chi.Ty.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Be.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Be.name, dongCong: 3 };
            case 6:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Be.name, dongCong: 3 };

            default:
              break;
          }
          break;
        case Const.Chi.Suu.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Kien.name, dongCong: 3 };
            case 3:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Kien.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Kien.name, dongCong: 1 };
            case 9:
              return { truc: Const.trucSao.Kien.name, dongCong: 5 };

            default:
              break;
          }
          break;

        case Const.Chi.Dan.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Tru.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Tru.name, dongCong: 5 };
            case 4:
              return { truc: Const.trucSao.Tru.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Tru.name, dongCong: 5 };
            case 8:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Mao.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Man.name, dongCong: 4 };
            case 3:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Thin.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Binh.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Binh.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Ti.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };
            case 5:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Dinh.name, dongCong: 1 };
            case 9:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Ngo.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Chap.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Chap.name, dongCong: 5 };
            case 4:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Chap.name, dongCong: 1 };
            case 8:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };

            default:
              break;
          }
          break;

        case Const.Chi.Mui.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Pha.name, dongCong: 5 };
            case 5:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Pha.name, dongCong: 5 };
            case 9:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Than.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Nguy.name, dongCong: 3 };
            case 2:
              return { truc: Const.trucSao.Nguy.name, dongCong: 3 };
            case 4:
              return { truc: Const.trucSao.Nguy.name, dongCong: 3 };
            case 6:
              return { truc: Const.trucSao.Nguy.name, dongCong: 3 };
            case 8:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Dau.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 3:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 5:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Thanh.name, dongCong: 3 };
            case 9:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };

            default:
              break;
          }
          break;

        case Const.Chi.Tuat.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Thu.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Thu.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Hoi.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Khai.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }
}
export function phiTinhNam(tietKhiDate) {
  const DateNew = new Date(tietKhiDate);

  const Year = DateNew.getFullYear();
  const arrYear = String(Year).split('');
  let sumCharacter = Number(arrYear[0]) + Number(arrYear[1]) + Number(arrYear[2]) + Number(arrYear[3]);
  if (sumCharacter > 10) {
    let arrSumCharacter = String(sumCharacter).split('');
    sumCharacter = Number(arrSumCharacter[0]) + Number(arrSumCharacter[1]);
    if (sumCharacter >= 10) {
      let arrSumLast = String(sumCharacter).split('');
      sumCharacter = Number(arrSumLast[0]) + Number(arrSumLast[1]);
    }
  }
  const result = 11 - sumCharacter;
  return result;
}
export function phiTinhThang(lunarDate) {
  const DateForCount = new Date(lunarDate);
  const Lunar = new Date('1900', '01', '01');
  let distance = DateForCount.getMonth() - Lunar.getMonth();
  if (DateForCount.getTime() > Lunar.getTime()) {
    let distanceYear = DateForCount.getFullYear() - Lunar.getFullYear();
    distance = 12 * distanceYear + (DateForCount.getMonth() - Lunar.getMonth());
  }
  const phiTinhThang = 8 - (distance % 9);

  return phiTinhThang === 0 ? 9 : phiTinhThang;
}
export function phiTinhDay(lunarDate) {
  const DateForCount = new Date('1900', '05', '30');
  const Lunar = new Date('1900', '01', '02');
  const msBetweenDate = DateForCount.getTime() - Lunar.getTime();

  const countDays = msBetweenDate / (1000 * 3600 * 24);
  let result = null;
  if (countDays % 9 === 0) {
    result = 9;
  } else {
    result = countDays % 9;
  }

  return result;
}
export function kiengKy(month, canchiDay) {
  const findMonth = Const.kiengKiTrongNgay.find((v, index) => index === month);
  const result = findMonth.find((v, index) => index === canchiDay?.code - 1);
  return result;
}
export function filterHopSo(list, isQuaiKhi) {
  let listInPeriod = list;
  //filter giờ
  const newList = listInPeriod.map((v) => {
    const queThangQuai = isQuaiKhi ? v?.queThang?.huyenKhongNguHanh : v?.queThang?.huyenKhongQuaiVan;
    const queNamQuai = isQuaiKhi ? v?.queNam?.huyenKhongNguHanh : v?.queNam?.huyenKhongQuaiVan;
    const queNgayQuai = isQuaiKhi ? v?.queNgay?.huyenKhongNguHanh : v?.queNgay?.huyenKhongQuaiVan;
    // let hour = v.que12Gio.filter((e) => {
    //   const quai = isQuaiKhi ? e.huyenKhongNguHanh : e.huyenKhongQuaiVan;
    //   return quai === queNgayQuai;
    // });

    //nếu trong ngày không có giờ phù hợp thì sẽ xét tới tháng
    // if (hour.length === 0) {
    if (queThangQuai === queNgayQuai || queNamQuai === queNgayQuai) {
      // return { ...v, que12Gio: hour };
      return { ...v };
    } else {
      return;
    }
    // } else {
    //   return { ...v, que12Gio: hour };
    // }
  });

  return newList;
}
export function filterHopThap(list, isQuaiKhi) {
  let listInPeriod = list;

  const newList = listInPeriod.map((v) => {
    const queThangQuai = isQuaiKhi ? v?.queThang?.huyenKhongNguHanh : v?.queThang?.huyenKhongQuaiVan;
    const queNamQuai = isQuaiKhi ? v?.queNam?.huyenKhongNguHanh : v?.queNam?.huyenKhongQuaiVan;
    const queNgayQuai = isQuaiKhi ? v?.queNgay?.huyenKhongNguHanh : v?.queNgay?.huyenKhongQuaiVan;
    // let hour = v.que12Gio.filter((e) => {
    //   const quai = isQuaiKhi ? e.huyenKhongNguHanh : e.huyenKhongQuaiVan;
    //   return quai + queNgayQuai === 10;
    // });
    //nếu trong ngày không có giờ phù hợp thì sẽ xét tới tháng
    // if (hour.length === 0) {
    if (queThangQuai + queNgayQuai === 10 || queNamQuai + queNgayQuai === 10) {
      // return { ...v, que12Gio: hour };
      return { ...v };
    } else {
      return;
    }
    // } else {
    //   return { ...v, que12Gio: hour };
    // }
  });

  return newList;
}
export function filterHaDo(list, isQuaiKhi) {
  let listInPeriod = list;
  const newList = listInPeriod.map((v) => {
    const queThangQuai = isQuaiKhi ? v?.queThang?.huyenKhongNguHanh : v?.queThang?.huyenKhongQuaiVan;
    const queNamQuai = isQuaiKhi ? v?.queNam?.huyenKhongNguHanh : v?.queNam?.huyenKhongQuaiVan;
    const queNgayQuai = isQuaiKhi ? v?.queNgay?.huyenKhongNguHanh : v?.queNgay?.huyenKhongQuaiVan;
    //hà đồ theo cặp 1-6,2-7,3-8,4-9
    // let hour = v.que12Gio.filter((e) => {
    //   const quai = isQuaiKhi ? e.huyenKhongNguHanh : e.huyenKhongQuaiVan;
    //   return (
    //     (quai === 1 && queNgayQuai === 6) ||
    //     (quai === 6 && queNgayQuai === 1) ||
    //     (quai === 2 && queNgayQuai === 7) ||
    //     (quai === 7 && queNgayQuai === 2) ||
    //     (quai === 3 && queNgayQuai === 8) ||
    //     (quai === 8 && queNgayQuai === 3) ||
    //     (quai === 4 && queNgayQuai === 9) ||
    //     (quai === 9 && queNgayQuai === 4)
    //   );
    // });
    //nếu trong ngày không có giờ phù hợp thì sẽ xét tới tháng
    // if (hour.length === 0) {
    if (
      ((queThangQuai === 1 || queNamQuai === 1) && queNgayQuai === 6) ||
      ((queThangQuai === 6 || queNamQuai === 6) && queNgayQuai === 1) ||
      ((queThangQuai === 2 || queNamQuai === 2) && queNgayQuai === 7) ||
      ((queThangQuai === 7 || queNamQuai === 7) && queNgayQuai === 2) ||
      ((queThangQuai === 3 || queNamQuai === 3) && queNgayQuai === 8) ||
      ((queThangQuai === 8 || queNamQuai === 8) && queNgayQuai === 3) ||
      ((queThangQuai === 4 || queNamQuai === 4) && queNgayQuai === 9) ||
      ((queThangQuai === 9 || queNamQuai === 9) && queNgayQuai === 4)
    ) {
      // return { ...v, que12Gio: hour };
      return { ...v };
    } else {
      return;
    }
    // } else {
    //   return { ...v, que12Gio: hour };
    // }
  });

  return newList;
}
export function filterHopNgu(list, isQuaiKhi) {
  let listInPeriod = list;

  const newList = listInPeriod.map((v) => {
    const queThangQuai = isQuaiKhi ? v?.queThang?.huyenKhongNguHanh : v?.queThang?.huyenKhongQuaiVan;
    const queNamQuai = isQuaiKhi ? v?.queNam?.huyenKhongNguHanh : v?.queNam?.huyenKhongQuaiVan;
    const queNgayQuai = isQuaiKhi ? v?.queNgay?.huyenKhongNguHanh : v?.queNgay?.huyenKhongQuaiVan;
    // let hour = v.que12Gio.filter((e) => {
    //   const quai = isQuaiKhi ? e.huyenKhongNguHanh : e.huyenKhongQuaiVan;
    //   return quai + queNgayQuai === 5;
    // });
    //nếu trong ngày không có giờ phù hợp thì sẽ xét tới tháng
    // if (hour.length === 0) {
    if (queThangQuai + queNgayQuai === 5 || queNamQuai + queNgayQuai === 5) {
      // return { ...v, que12Gio: hour };
      return { ...v };
    } else {
      return;
    }
    // } else {
    //   return { ...v, que12Gio: hour };
    // }
  });

  return newList;
}
export function filterHopThapNgu(list, isQuaiKhi) {
  let listInPeriod = list;

  const newList = listInPeriod.map((v) => {
    const queThangQuai = isQuaiKhi ? v?.queThang?.huyenKhongNguHanh : v?.queThang?.huyenKhongQuaiVan;
    const queNamQuai = isQuaiKhi ? v?.queNam?.huyenKhongNguHanh : v?.queNam?.huyenKhongQuaiVan;
    const queNgayQuai = isQuaiKhi ? v?.queNgay?.huyenKhongNguHanh : v?.queNgay?.huyenKhongQuaiVan;
    // let hour = v.que12Gio.filter((e) => {
    //   const quai = isQuaiKhi ? e.huyenKhongNguHanh : e.huyenKhongQuaiVan;
    //   return quai + queNgayQuai === 15;
    // });
    //nếu trong ngày không có giờ phù hợp thì sẽ xét tới tháng
    // if (hour.length === 0) {
    if (queThangQuai + queNgayQuai === 15 || queNamQuai + queNgayQuai === 15) {
    } else {
      return;
    }
    // } else {
    //   return { ...v, que12Gio: hour };
    // }
  });

  return newList;
}
export function filterSoKetHopDatBiet(list, isQuaiKhi) {
  let listInPeriod = list;
  const newList = listInPeriod.map((v) => {
    const queThangQuai = isQuaiKhi ? v?.queThang?.huyenKhongNguHanh : v?.queThang?.huyenKhongQuaiVan;
    const queNamQuai = isQuaiKhi ? v?.queNam?.huyenKhongNguHanh : v?.queNam?.huyenKhongQuaiVan;
    const queNgayQuai = isQuaiKhi ? v?.queNgay?.huyenKhongNguHanh : v?.queNgay?.huyenKhongQuaiVan;
    //hà đồ theo cặp 1-6,2-7,3-8,4-9
    // let hour = v.que12Gio.filter((e) => {
    //   const quai = isQuaiKhi ? e.huyenKhongNguHanh : e.huyenKhongQuaiVan;
    //   return (
    //     (quai === 1 && queNgayQuai === 3) ||
    //     (quai === 3 && queNgayQuai === 1) ||
    //     (quai === 2 && queNgayQuai === 4) ||
    //     (quai === 4 && queNgayQuai === 2) ||
    //     (quai === 6 && queNgayQuai === 8) ||
    //     (quai === 8 && queNgayQuai === 6) ||
    //     (quai === 7 && queNgayQuai === 9) ||
    //     (quai === 9 && queNgayQuai === 7)
    //   );
    // });
    //nếu trong ngày không có giờ phù hợp thì sẽ xét tới tháng
    // if (hour.length === 0) {
    if (
      ((queThangQuai === 1 || queNamQuai === 1) && queNgayQuai === 3) ||
      ((queThangQuai === 3 || queNamQuai === 3) && queNgayQuai === 1) ||
      ((queThangQuai === 2 || queNamQuai === 2) && queNgayQuai === 4) ||
      ((queThangQuai === 4 || queNamQuai === 4) && queNgayQuai === 2) ||
      ((queThangQuai === 6 || queNamQuai === 6) && queNgayQuai === 8) ||
      ((queThangQuai === 8 || queNamQuai === 8) && queNgayQuai === 6) ||
      ((queThangQuai === 7 || queNamQuai === 7) && queNgayQuai === 9) ||
      ((queThangQuai === 9 || queNamQuai === 9) && queNgayQuai === 7)
    ) {
      // return { ...v, que12Gio: hour };
      return { ...v };
    } else {
      return;
    }
    // } else {
    //   return { ...v, que12Gio: hour };
    // }
  });

  return newList;
}
export function getBlueTextHanViet(cuc, trucTinh, canChi) {
  const tempCuuTinh = trucTinh.includes(' - ') && trucTinh.split(' - ')[0];
  const cuuTinh = (tempCuuTinh && tempCuuTinh.split('：')[1]) || '';

  const diaBan = canChi.split(' ')[0];
  if (
    (cuc.thienBan === Const.ThienBan.AT && //1
      (cuc.batQuai === Const.batQuai.TON || cuc.batQuai === Const.batQuai.KHAM)) ||
    (cuc.thienBan === Const.ThienBan.BINH && //2
      (cuc.batQuai === Const.batQuai.CHAN || cuc.batQuai === Const.batQuai.TON)) ||
    (cuc.thienBan === Const.ThienBan.DINH && //3
      (cuc.batQuai === Const.batQuai.TON || cuc.batQuai === Const.batQuai.LY))
  ) {
    return Const.tam_ky_vuong_tuong;
  } // Hoan dịch cách
  else if (
    (cuc.thienBan === Const.ThienBan.AT || cuc.thienBan === Const.ThienBan.BINH || cuc.thienBan === Const.ThienBan.DINH) &&
    (cuc.diaBan === Const.DiaBan.MAU ||
      cuc.diaBan === Const.DiaBan.QUY ||
      cuc.diaBan === Const.DiaBan.NHAM ||
      cuc.diaBan === Const.DiaBan.TAN ||
      cuc.diaBan === Const.DiaBan.CANH ||
      cuc.diaBan === Const.DiaBan.KY)
  ) {
    return Const.hoan_dich_cach;
  }
  // Tương tả cách
  else if (
    (cuc.thienBan === Const.ThienBan.MAU ||
      cuc.thienBan === Const.ThienBan.QUY ||
      cuc.thienBan === Const.ThienBan.NHAM ||
      cuc.thienBan === Const.ThienBan.TAN ||
      cuc.thienBan === Const.ThienBan.CANH ||
      cuc.thienBan === Const.ThienBan.KY) &&
    (cuc.diaBan === Const.DiaBan.AT || cuc.diaBan === Const.DiaBan.BINH || cuc.diaBan === Const.DiaBan.DINH) &&
    (cuc.batMon === Const.BatMon.KHAI || cuc.batMon === Const.BatMon.HUU || cuc.batMon === Const.BatMon.SINH)
  ) {
    return Const.tuong_ta_cach;
  }
  // Thiên độn
  // Địa độn
  // Nhân độn
  // Vận độn
  else if (
    (cuc.diaBan === Const.DiaBan.TAN && //1
      (cuc.batMon === Const.BatMon.HUU || cuc.batMon === Const.BatMon.SINH || cuc.batMon === Const.BatMon.KHAI)) ||
    (cuc.diaBan === Const.DiaBan.TAN && //2
      (cuc.batMon === Const.BatMon.HUU || cuc.batMon === Const.BatMon.SINH || cuc.batMon === Const.BatMon.KHAI) &&
      cuc.batQuai === Const.batQuai.KHON) ||
    (cuc.batMon === Const.BatMon.KHAI && //3
      cuc.batQuai === Const.batQuai.KHON) ||
    (cuc.batMon === Const.BatMon.HUU && //4
      cuc.batQuai === Const.batQuai.KHON) ||
    (cuc.diaBan === Const.DiaBan.AT && //5
      (cuc.batMon === Const.BatMon.HUU || cuc.batMon === Const.BatMon.SINH || cuc.batMon === Const.BatMon.KHAI))
  ) {
    return Const.van_don;
  }
  // Phong độn
  else if (
    (cuc.thienBan === Const.ThienBan.AT && //1
      (cuc.batMon === Const.BatMon.HUU || cuc.batMon === Const.BatMon.SINH || cuc.batMon === Const.BatMon.KHAI) &&
      cuc.batQuai === Const.batQuai.TON) ||
    (cuc.thienBan === Const.ThienBan.AT && //2
      cuc.diaBan === Const.DiaBan.TAN &&
      (cuc.batMon === Const.BatMon.HUU || cuc.batMon === Const.BatMon.SINH || cuc.batMon === Const.BatMon.KHAI) &&
      cuc.batQuai === Const.batQuai.TON) ||
    (cuc.thienBan === Const.ThienBan.BINH && //3
      cuc.batMon === Const.BatMon.KHAI &&
      cuc.batQuai === Const.batQuai.TON) ||
    (cuc.thienBan === Const.ThienBan.DINH && //4
      cuc.diaBan === Const.DiaBan.KY &&
      (cuc.batMon === Const.BatMon.HUU || cuc.batMon === Const.BatMon.SINH || cuc.batMon === Const.BatMon.KHAI)) ||
    (cuc.thienBan === Const.ThienBan.AT && //5
      cuc.batMon === Const.BatMon.SINH &&
      cuc.batQuai === Const.batQuai.TON) ||
    (cuc.thienBan === Const.ThienBan.TAN && //6
      cuc.diaBan === Const.DiaBan.AT &&
      (cuc.batMon === Const.BatMon.HUU || cuc.batMon === Const.BatMon.SINH || cuc.batMon === Const.BatMon.KHAI))
  ) {
    return Const.phong_don;
  }
  //Long độn
  else if (
    (cuc.thienBan === Const.ThienBan.AT && // 1
      cuc.diaBan === Const.DiaBan.TAN &&
      cuc.batMon === Const.BatMon.HUU &&
      cuc.batQuai === Const.batQuai.CANAS) ||
    (cuc.thienBan === Const.ThienBan.AT && // 2
      cuc.diaBan === Const.DiaBan.TAN &&
      cuc.batMon === Const.BatMon.SINH &&
      cuc.batQuai === Const.batQuai.CANAS) ||
    (cuc.thienBan === Const.ThienBan.TAN && // 3
      cuc.batMon === Const.BatMon.SINH &&
      cuc.batQuai === Const.batQuai.CANAS) ||
    (cuc.thienBan === Const.ThienBan.AT && // 4
      cuc.batMon === Const.BatMon.HUU &&
      cuc.batQuai === Const.batQuai.CANAS) ||
    (cuc.thienBan === Const.ThienBan.CANH && // 5
      cuc.diaBan === Const.DiaBan.AT &&
      cuc.batMon === Const.BatMon.KHAI &&
      cuc.batQuai === Const.batQuai.DOAI) ||
    (cuc.thienBan === Const.ThienBan.TAN && // 6
      cuc.diaBan === Const.DiaBan.TAN &&
      cuc.batMon === Const.BatMon.SINH &&
      cuc.batQuai === Const.batQuai.CANAS) ||
    (cuc.thienBan === Const.ThienBan.AT && // 7
      cuc.batMon === Const.BatMon.HUU &&
      cuc.batQuai === Const.batQuai.KHAM)
  ) {
    return Const.long_don;
  }
  //Hổ độn
  else if (
    (cuc.thienBan === Const.ThienBan.AT && // 1
      cuc.diaBan === Const.DiaBan.TAN &&
      cuc.batMon === Const.BatMon.HUU &&
      cuc.batQuai === Const.batQuai.CANAS) ||
    (cuc.thienBan === Const.ThienBan.AT && // 2
      cuc.diaBan === Const.DiaBan.TAN &&
      cuc.batMon === Const.BatMon.SINH &&
      cuc.batQuai === Const.batQuai.CANAS) ||
    (cuc.thienBan === Const.ThienBan.TAN && // 3
      cuc.batMon === Const.BatMon.SINH &&
      cuc.batQuai === Const.batQuai.CANAS) ||
    (cuc.thienBan === Const.ThienBan.AT && // 4
      cuc.batMon === Const.BatMon.HUU &&
      cuc.batQuai === Const.batQuai.CANAS) ||
    (cuc.thienBan === Const.ThienBan.CANH && // 5
      cuc.diaBan === Const.DiaBan.TAN &&
      cuc.batMon === Const.BatMon.KHAI &&
      cuc.batQuai === Const.batQuai.DOAI) ||
    (cuc.thienBan === Const.ThienBan.TAN && // 6
      cuc.diaBan === Const.DiaBan.AT &&
      cuc.batMon === Const.BatMon.SINH &&
      cuc.batQuai === Const.batQuai.CANAS) ||
    (cuc.thienBan === Const.ThienBan.AT && // 7
      cuc.batMon === Const.BatMon.HUU &&
      cuc.batQuai === Const.batQuai.KHAM)
  ) {
    return Const.ho_don;
  }
  //Thần độn
  else if (
    (cuc.thienBan === Const.ThienBan.BINH && //1
      cuc.batMon === Const.BatMon.SINH &&
      cuc.batThan === Const.BatThan.THIEN) ||
    (cuc.thienBan === Const.ThienBan.BINH && //2
      (cuc.cuuTinh === Const.CuuTinh.TAM || cuc.cuuTinh === Const.CuuTinh.CAM) &&
      cuc.batMon === Const.BatMon.SINH &&
      cuc.batThan === Const.BatThan.THIEN) ||
    (cuc.thienBan === Const.ThienBan.AT && //3
      cuc.batMon === Const.BatMon.HUU &&
      cuc.batThan === Const.BatThan.THIEN) ||
    (cuc.thienBan === Const.ThienBan.AT && //4
      (cuc.cuuTinh === Const.CuuTinh.TAM || cuc.cuuTinh === Const.CuuTinh.CAM) &&
      cuc.batMon === Const.BatMon.KHAI &&
      cuc.batThan === Const.BatThan.THIEN &&
      cuc.huong === Const.huong.TAY_BAC)
  ) {
    // Thiên tại Càn ?
    return Const.than_don;
  }
  //Quỷ độn
  else if (
    (cuc.thienBan === Const.ThienBan.AT && //1
      cuc.batMon === Const.BatMon.DO &&
      cuc.batThan === Const.BatThan.DIA) ||
    (cuc.thienBan === Const.ThienBan.AT && //2
      cuc.batMon === Const.BatMon.KHAI &&
      cuc.batThan === Const.BatThan.DIA) ||
    (cuc.thienBan === Const.ThienBan.DINH && //3
      cuc.batMon === Const.BatMon.HUU &&
      cuc.batThan === Const.BatThan.DIA) ||
    (cuc.thienBan === Const.ThienBan.DINH && //4
      cuc.batMon === Const.BatMon.KHAI &&
      cuc.batThan === Const.BatThan.DIA) ||
    (cuc.thienBan === Const.ThienBan.DINH && //4
      cuc.batMon === Const.BatMon.SINH &&
      cuc.batThan === Const.BatThan.DIA &&
      cuc.huong === Const.huong.DONG_BAC) || // Địa tại Cấn?
    (cuc.thienBan === Const.ThienBan.TAN && //5
      cuc.diaBan === Const.DiaBan.DINH &&
      cuc.batMon === Const.BatMon.HUU &&
      cuc.cuuTinh === Const.CuuTinh.PHU &&
      cuc.huong === Const.huong.DONG_BAC) || // Thiên phụ tại Cấn
    (cuc.thienBan === Const.ThienBan.DINH && //6
      cuc.batMon === Const.BatMon.SINH &&
      cuc.batThan === Const.BatThan.DIA)
  ) {
    return Const.quy_don;
  }
  //Nhật kỳ đắc xử
  else if (cuc.thienBan === Const.ThienBan.AT && (cuc.batQuai === Const.batQuai.LY || cuc.batQuai === Const.batQuai.CANF)) {
    return Const.nhat_ky_dac_xu;
  }
  //Nguyệt kỳ đắc xử
  else if (cuc.thienBan === Const.ThienBan.BINH && (cuc.batQuai === Const.batQuai.KHAM || cuc.batQuai === Const.batQuai.KHON)) {
    return Const.nguyet_ky_dac_xu;
  }
  //Tinh kỳ đắc xử
  else if (cuc.thienBan === Const.ThienBan.DINH && (cuc.batQuai === Const.batQuai.CANAS || cuc.batQuai === Const.batQuai.TON)) {
    return Const.tinh_ky_dac_xu;
  }
  //Nhật kỳ thăng điện
  else if (cuc.thienBan === Const.ThienBan.AT && cuc.batQuai === Const.batQuai.CHAN) {
    return Const.nhat_ky_thang_dien;
  }
  //Nguyệt kỳ thăng điện
  else if (cuc.thienBan === Const.ThienBan.BINH && cuc.batQuai === Const.batQuai.LY) {
    return Const.nguyet_ky_thang_dien;
  }
  //Tinh kỳ thăng điện
  else if (cuc.thienBan === Const.ThienBan.DINH && cuc.batQuai === Const.batQuai.DOAI) {
    return Const.tinh_ky_thang_dien;
  } //Chân tam trá
  else if (
    (cuc.thienBan === Const.ThienBan.AT || cuc.thienBan === Const.ThienBan.BINH || cuc.thienBan === Const.ThienBan.DINH) &&
    (cuc.batMon === Const.BatMon.HUU || cuc.batMon === Const.BatMon.SINH || cuc.batMon === Const.BatMon.KHAI) &&
    cuc.batThan === Const.BatThan.AM
  ) {
    return Const.chan_tam_tra;
  }
  //Hưu tam trá
  else if (
    (cuc.thienBan === Const.ThienBan.AT || cuc.thienBan === Const.ThienBan.BINH || cuc.thienBan === Const.ThienBan.DINH) &&
    (cuc.batMon === Const.BatMon.HUU || cuc.batMon === Const.BatMon.SINH || cuc.batMon === Const.BatMon.KHAI) &&
    cuc.batThan === Const.BatThan.HOP
  ) {
    return Const.huu_tam_tra;
  }
  //Trùng tam trá
  else if (
    (cuc.thienBan === Const.ThienBan.AT || cuc.thienBan === Const.ThienBan.BINH || cuc.thienBan === Const.ThienBan.DINH) &&
    (cuc.batMon === Const.BatMon.HUU || cuc.batMon === Const.BatMon.SINH || cuc.batMon === Const.BatMon.KHAI) &&
    cuc.batThan === Const.BatThan.DIA
  ) {
    return Const.trung_tam_tra;
  }
  //Thiên giả
  else if (
    (cuc.thienBan === Const.ThienBan.AT || cuc.thienBan === Const.ThienBan.BINH || cuc.thienBan === Const.ThienBan.DINH) &&
    cuc.batMon === Const.BatMon.CANH &&
    cuc.batThan === Const.BatThan.THIEN
  ) {
    return Const.thien_gia;
  }
  //Địa giả
  else if (
    (cuc.thienBan === Const.ThienBan.DINH || cuc.thienBan === Const.ThienBan.KY || cuc.thienBan === Const.ThienBan.QUY) &&
    cuc.batMon === Const.BatMon.DO &&
    (cuc.batThan === Const.BatThan.DIA || cuc.batThan === Const.BatThan.AM || cuc.batThan === Const.BatThan.HOP)
  ) {
    return Const.dia_gia;
  }
  //Nhân giả
  else if (
    cuc.thienBan === Const.ThienBan.NHAM &&
    ((cuc.batMon === Const.BatMon.KINH && cuc.batThan === Const.BatThan.THIEN) ||
      (cuc.batMon === Const.BatMon.THUONG && cuc.batThan === Const.BatThan.HOP))
  ) {
    return Const.nhan_gia;
  }
  //Thần giả
  else if (
    ((cuc.thienBan === Const.ThienBan.DINH || //1
      cuc.thienBan === Const.ThienBan.KY ||
      cuc.thienBan === Const.ThienBan.QUY) &&
      cuc.batMon === Const.BatMon.THUONG &&
      (cuc.batThan === Const.BatThan.DIA || cuc.batThan === Const.BatThan.AM || cuc.batThan === Const.BatThan.HOP)) ||
    (cuc.cuuTinh === cuuTinh && //2 Thiên bàn === Trực Phù ?
      cuc.batMon === Const.BatMon.KINH)
  ) {
    return Const.than_gia;
  }
  //Quỷ giả
  else if (
    ((cuc.thienBan === Const.ThienBan.DINH || //1
      cuc.thienBan === Const.ThienBan.KY ||
      cuc.thienBan === Const.ThienBan.QUY) &&
      cuc.batMon === Const.BatMon.TU &&
      (cuc.batThan === Const.BatThan.DIA || cuc.batThan === Const.BatThan.AM || cuc.batThan === Const.BatThan.HOP)) ||
    (cuc.batMon === Const.BatMon.TU && //2
      cuc.batThan === Const.BatThan.AM)
  ) {
    return Const.quy_gia;
  }

  //Thanh long đào tẩu
  else if (
    (cuc.thienBan === Const.ThienBan.AT && //1
      cuc.diaBan === Const.DiaBan.TAN) ||
    (cuc.thienBan === Const.ThienBan.AT && //2
      cuc.diaBan === Const.DiaBan.CANH)
  ) {
    return Const.thanh_long_dao_tau;
  }
  //Bạch hổ cuồng vòng
  else if (cuc.thienBan === Const.ThienBan.TAN && cuc.diaBan === Const.DiaBan.AT) {
    return Const.bach_ho_cuong_vong;
  }
  //Chu tước đầu giang
  else if (cuc.thienBan === Const.ThienBan.DINH && cuc.diaBan === Const.DiaBan.QUY) {
    return Const.chu_tuoc_dau_giang;
  }
  //Đằng xà yêu kiều
  else if (cuc.thienBan === Const.ThienBan.QUY && cuc.diaBan === Const.DiaBan.DINH) {
    return Const.dang_xa_yeu_kieu;
  }
  //Thái bạch nhập lao
  else if (cuc.thienBan === Const.ThienBan.CANH && cuc.diaBan === Const.DiaBan.BINH) {
    return Const.thai_bach_nhap_lao;
  }
  //Lao nhập thái bạch
  else if (cuc.thienBan === Const.ThienBan.BINH && cuc.diaBan === Const.DiaBan.CANH) {
    return Const.lao_nhap_thai_bach;
  }
  //Nhật cách
  //    else if (cuc.thienBan === Const.ThienBan.CANH && cuc.diaBan === Const.DiaBan.) {
  //      return Const.bach_ho_cuong_vong;
  //    }
  //THIÊN ẤT PHI CAN CÁCH
  //Đại cách
  else if (cuc.thienBan === Const.ThienBan.CANH && cuc.diaBan === Const.DiaBan.QUY) {
    return Const.dai_cach;
  }
  //Thượng cách
  else if (cuc.thienBan === Const.ThienBan.CANH && cuc.diaBan === Const.DiaBan.NHAM) {
    return Const.thuong_cach;
  }
  //Hình cách
  else if (cuc.thienBan === Const.ThienBan.CANH && cuc.diaBan === Const.DiaBan.KY) {
    return Const.hinh_cach;
  }
  //Kỳ cách
  else if (
    cuc.thienBan === Const.ThienBan.CANH &&
    (cuc.diaBan === Const.DiaBan.AT || cuc.diaBan === Const.DiaBan.BINH || cuc.diaBan === Const.DiaBan.DINH)
  ) {
    return Const.ky_cach;
  }
  //Chiến cách
  else if (cuc.thienBan === Const.ThienBan.CANH && cuc.diaBan === Const.DiaBan.CANH) {
    return Const.chien_cach;
  }
  //Tam kỳ nhập mộ
  else if (
    (cuc.thienBan === Const.ThienBan.AT && cuc.batQuai === Const.batQuai.KHON) ||
    (cuc.thienBan === Const.ThienBan.BINH && cuc.batQuai === Const.batQuai.CANF) ||
    (cuc.thienBan === Const.ThienBan.DINH && cuc.batQuai === Const.batQuai.CANAS)
  ) {
    return Const.tam_ky_nhap_mo;
  }
  //Tam kỳ thọ chế
  else if (
    ((cuc.thienBan === Const.ThienBan.BINH || cuc.thienBan === Const.ThienBan.DINH) && cuc.batQuai === Const.batQuai.KHAM) ||
    (cuc.thienBan === Const.ThienBan.AT && (cuc.batQuai === Const.batQuai.CANF || cuc.batQuai === Const.batQuai.DOAI))
  ) {
    return Const.tam_ky_tho_che;
  }
  //Lục nghi kịch hình
  else if (
    (cuc.thienBan === Const.ThienBan.MAU && //1
      cuc.batQuai === Const.batQuai.CHAN) ||
    (cuc.thienBan === Const.ThienBan.KY && //2
      cuc.batQuai === Const.batQuai.KHON) ||
    (cuc.thienBan === Const.ThienBan.CANH && //3
      cuc.batQuai === Const.batQuai.CANAS &&
      cuc.thienBan === Const.ThienBan.TAN && //4
      cuc.batQuai === Const.batQuai.LY) ||
    (cuc.thienBan === Const.ThienBan.NHAM && //5
      cuc.batQuai === Const.batQuai.TON) ||
    (cuc.thienBan === Const.ThienBan.QUY && //6
      cuc.batQuai === Const.batQuai.TON)
  ) {
    return Const.luc_nghi_kich_hinh;
  }
  //THIÊN VÕNG TỨ TRƯỚNG
  else if (cuc.thienBan === Const.ThienBan.QUY && cuc.diaBan === diaBan) {
    return Const.thien_vong_tu_truong;
  }
  //ĐỊA VÕNG
  else if (cuc.thienBan === Const.ThienBan.NHAM && cuc.diaBan === diaBan) {
    return Const.dia_vong;
  }
  //THIÊN ẤT PHỤC CUNG CÁCH
  else if (cuc.thienBan === Const.ThienBan.CANH && cuc.cuuTinh === cuuTinh) {
    return Const.thien_at_phuc_cung_cach;
  }
  //THIÊN ẤT PHI CUNG CÁCH
  else if (cuc.cuuTinh === cuuTinh && cuc.diaBan === Const.DiaBan.CANH) {
    return Const.thien_at_phi_cung_cach;
  }
  //BỘT CÁCH
  else if (
    (cuc.thienBan === Const.ThienBan.BINH && cuc.cuuTinh === cuuTinh) ||
    (cuc.cuuTinh === cuuTinh && cuc.diaBan === Const.DiaBan.BINH) ||
    (cuc.thienBan === Const.ThienBan.BINH && cuc.diaBan === Const.DiaBan.BINH)
  ) {
    return Const.bot_cach;
  } else {
    return null;
  }
}
export function GiaiThichKyMonKhacUng(thienBan, batMon) {
  if (thienBan === Const.ThienBan.AT && batMon === Const.BatMon.SINH) {
    return Const.giai_thich_at_sinh;
  } else if (thienBan === Const.ThienBan.AT && batMon === Const.BatMon.HUU) {
    return Const.giai_thich_at_huu;
  } else if (thienBan === Const.ThienBan.AT && batMon === Const.BatMon.KHAI) {
    return Const.giai_thich_at_khai;
  } else if (thienBan === Const.ThienBan.BINH && batMon === Const.BatMon.SINH) {
    return Const.giai_thich_binh_sinh;
  } else if (thienBan === Const.ThienBan.BINH && batMon === Const.BatMon.HUU) {
    return Const.giai_thich_binh_huu;
  } else if (thienBan === Const.ThienBan.BINH && batMon === Const.BatMon.KHAI) {
    return Const.giai_thich_binh_khai;
  } else if (thienBan === Const.ThienBan.DINH && batMon === Const.BatMon.SINH) {
    return Const.giai_thich_dinh_sinh;
  } else if (thienBan === Const.ThienBan.DINH && batMon === Const.BatMon.HUU) {
    return Const.giai_thich_binh_huu;
  } else if (thienBan === Const.ThienBan.DINH && batMon === Const.BatMon.KHAI) {
    return Const.giai_thich_binh_khai;
  } else {
    return null;
  }
}
export function GiaiThichTamKyPhuXuKhacUng(thienBan, cuuTinh, batMon, canGio) {
  if (
    thienBan === Const.ThienBan.AT &&
    cuuTinh === Const.CuuTinh.PHU &&
    batMon === Const.BatMon.SINH &&
    (canGio.toString().includes('ẤT') || canGio.toString().includes('CANH'))
  ) {
    return Const.giai_thich_at_thienphu_sinh_atcanh;
  } else if (
    thienBan === Const.ThienBan.BINH &&
    cuuTinh === Const.CuuTinh.NHUE &&
    batMon === Const.BatMon.HUU &&
    (canGio.toString().includes('GIÁP') || canGio.toString().includes('KỶ'))
  ) {
    return Const.giai_thich_binh_thiennhue_huu_giapky;
  } else if (
    thienBan === Const.ThienBan.DINH &&
    cuuTinh === Const.CuuTinh.ANH &&
    batMon === Const.BatMon.KHAI &&
    (canGio.toString().includes('MẬU') || canGio.toString().includes('QUÝ'))
  ) {
    return Const.giai_thich_dinh_thienanh_khai_mauquy;
  } else {
    return null;
  }
}
export function GiaiThichKhacUngBatMon(batMon) {
  if (batMon === Const.BatMon.HUU) {
    return Const.giai_thich_huu_mon;
  } else if (batMon === Const.BatMon.SINH) {
    return Const.giai_thich_sinh_mon;
  } else if (batMon === Const.BatMon.THUONG) {
    return Const.giai_thich_thuong_mon;
  } else if (batMon === Const.BatMon.DO) {
    return Const.giai_thich_do_mon;
  } else if (batMon === Const.BatMon.CANH) {
    return Const.giai_thich_canh_mon;
  } else if (batMon === Const.BatMon.TU) {
    return Const.giai_thich_tu_mon;
  } else if (batMon === Const.BatMon.KINH) {
    return Const.giai_thich_kinh_mon;
  } else if (batMon === Const.BatMon.KHAI) {
    return Const.giai_thich_khai_mon;
  } else {
    return null;
  }
}
export function GiaiThichKhacUngChoCanGio(can) {
  if (can.toString()?.includes('GIÁP')) {
    return Const.giai_thich_can_giap;
  } else if (can.toString()?.includes('ẤT')) {
    return Const.giai_thich_can_at;
  } else if (can.toString()?.includes('BÍNH')) {
    return Const.giai_thich_can_binh;
  } else if (can.toString()?.includes('ĐINH')) {
    return Const.giai_thich_can_dinh;
  } else if (can.toString()?.includes('MẬU')) {
    return Const.giai_thich_can_mau;
  } else if (can.toString()?.includes('KỶ')) {
    return Const.giai_thich_can_ky;
  } else if (can.toString()?.includes('CANH')) {
    return Const.giai_thich_can_canh;
  } else if (can.toString()?.includes('TÂN')) {
    return Const.giai_thich_can_tan;
  } else if (can.toString()?.includes('NHÂM')) {
    return Const.giai_thich_can_nham;
  } else if (can.toString()?.includes('QUÝ')) {
    return Const.giai_thich_can_quy;
  } else {
    return null;
  }
}
export function GiaiThichKhacUngChoTruChiGio(chi) {
  if (chi.toString().includes('Tý')) {
    return Const.giai_thich_chi_ty;
  } else if (chi.toString().includes('Sửu')) {
    return Const.giai_thich_chi_suu;
  } else if (chi.toString().includes('Dần')) {
    return Const.giai_thich_chi_dan;
  } else if (chi.toString().includes('Mão')) {
    return Const.giai_thich_chi_meo;
  } else if (chi.toString().includes('Thìn')) {
    return Const.giai_thich_chi_thin;
  } else if (chi.toString().includes('Tỵ')) {
    return Const.giai_thich_chi_tyj;
  } else if (chi.toString().includes('Ngọ')) {
    return Const.giai_thich_chi_ngo;
  } else if (chi.toString().includes('Mùi')) {
    return Const.giai_thich_chi_mui;
  } else if (chi.toString().includes('Thân')) {
    return Const.giai_thich_chi_than;
  } else if (chi.toString().includes('Dậu')) {
    return Const.giai_thich_chi_dau;
  } else if (chi.toString().includes('Tuất')) {
    return Const.giai_thich_chi_tuat;
  } else if (chi.toString().includes('Hợi')) {
    return Const.giai_thich_chi_hoi;
  } else {
    return null;
  }
}
export function GiaiThichKhacUngChoBatThan(batThan) {
  if (batThan === Const.BatThan.PHU) {
    return Const.giai_thich_truc_phu;
  } else if (batThan === Const.BatThan.XA) {
    return Const.giai_thich_dang_xa;
  } else if (batThan === Const.BatThan.AM) {
    return Const.giai_thich_thai_am;
  } else if (batThan === Const.BatThan.HOP) {
    return Const.giai_thich_luc_hop;
  } else if (batThan === Const.BatThan.TRAN) {
    return Const.giai_thich_cau_tran;
  } else if (batThan === Const.BatThan.TUOC) {
    return Const.giai_thich_chu_tuoc;
  } else if (batThan === Const.BatThan.DIA) {
    return Const.giai_thich_cuu_dia;
  } else if (batThan === Const.BatThan.THIEN) {
    return Const.giai_thich_cuu_thien;
  } else {
    return null;
  }
}
export function GiaiThichKhacUngTamKy(thienBan, batQuai) {
  if (thienBan === Const.ThienBan.AT && batQuai === Const.batQuai.CANF) {
    return Const.giai_thich_at_can;
  } else if (thienBan === Const.ThienBan.AT && batQuai === Const.batQuai.KHON) {
    return Const.giai_thich_at_khon;
  } else if (thienBan === Const.ThienBan.AT && batQuai === Const.batQuai.LY) {
    return Const.giai_thich_at_ly;
  } else if (thienBan === Const.ThienBan.AT && batQuai === Const.batQuai.KHAM) {
    return Const.giai_thich_at_kham;
  } else if (thienBan === Const.ThienBan.AT && batQuai === Const.batQuai.CHAN) {
    return Const.giai_thich_at_chan;
  } else if (thienBan === Const.ThienBan.AT && batQuai === Const.batQuai.TON) {
    return Const.giai_thich_at_ton;
  } else if (thienBan === Const.ThienBan.AT && batQuai === Const.batQuai.DOAI) {
    return Const.giai_thich_at_doai;
  } else if (thienBan === Const.ThienBan.AT && batQuai === Const.batQuai.CANAS) {
    return Const.giai_thich_at_canas;
  } else if (thienBan === Const.ThienBan.BINH && batQuai === Const.batQuai.CANF) {
    return Const.giai_thich_binh_can;
  } else if (thienBan === Const.ThienBan.BINH && batQuai === Const.batQuai.KHON) {
    return Const.giai_thich_binh_khon;
  } else if (thienBan === Const.ThienBan.BINH && batQuai === Const.batQuai.LY) {
    return Const.giai_thich_binh_ly;
  } else if (thienBan === Const.ThienBan.BINH && batQuai === Const.batQuai.KHAM) {
    return Const.giai_thich_binh_kham;
  } else if (thienBan === Const.ThienBan.BINH && batQuai === Const.batQuai.CHAN) {
    return Const.giai_thich_binh_chan;
  } else if (thienBan === Const.ThienBan.BINH && batQuai === Const.batQuai.TON) {
    return Const.giai_thich_binh_ton;
  } else if (thienBan === Const.ThienBan.BINH && batQuai === Const.batQuai.DOAI) {
    return Const.giai_thich_binh_doai;
  } else if (thienBan === Const.ThienBan.BINH && batQuai === Const.batQuai.CANAS) {
    return Const.giai_thich_binh_canas;
  } else if (thienBan === Const.ThienBan.DINH && batQuai === Const.batQuai.CANF) {
    return Const.giai_thich_dinh_can;
  } else if (thienBan === Const.ThienBan.DINH && batQuai === Const.batQuai.KHON) {
    return Const.giai_thich_dinh_khon;
  } else if (thienBan === Const.ThienBan.DINH && batQuai === Const.batQuai.LY) {
    return Const.giai_thich_dinh_ly;
  } else if (thienBan === Const.ThienBan.DINH && batQuai === Const.batQuai.KHAM) {
    return Const.giai_thich_dinh_kham;
  } else if (thienBan === Const.ThienBan.DINH && batQuai === Const.batQuai.CHAN) {
    return Const.giai_thich_dinh_chan;
  } else if (thienBan === Const.ThienBan.DINH && batQuai === Const.batQuai.TON) {
    return Const.giai_thich_dinh_ton;
  } else if (thienBan === Const.ThienBan.DINH && batQuai === Const.batQuai.DOAI) {
    return Const.giai_thich_dinh_doai;
  } else if (thienBan === Const.ThienBan.DINH && batQuai === Const.batQuai.CANAS) {
    return Const.giai_thich_dinh_canas;
  } else {
    return null;
  }
}
export function GiaiThichBatMonBatThan(batMon, batThan) {
  if (batThan === Const.BatThan.PHU && batMon === Const.BatMon.DO) {
    return Const.giai_thich_truc_phu_do_mon;
  } else if (batThan === Const.BatThan.PHU && batMon === Const.BatMon.CANH) {
    return Const.giai_thich_truc_phu_canh_mon;
  } else if (batThan === Const.BatThan.PHU && batMon === Const.BatMon.TU) {
    return Const.giai_thich_truc_phu_tu_mon;
  } else if (batThan === Const.BatThan.PHU && batMon === Const.BatMon.THUONG) {
    return Const.giai_thich_truc_phu_thuong_mon;
  } else if (batThan === Const.BatThan.PHU && batMon === Const.BatMon.KINH) {
    return Const.giai_thich_truc_phu_kinh_mon;
  } else if (batThan === Const.BatThan.PHU && batMon === Const.BatMon.SINH) {
    return Const.giai_thich_truc_phu_sinh_mon;
  } else if (batThan === Const.BatThan.PHU && batMon === Const.BatMon.HUU) {
    return Const.giai_thich_truc_phu_huu_mon;
  } else if (batThan === Const.BatThan.PHU && batMon === Const.BatMon.KHAI) {
    return Const.giai_thich_truc_phu_khai_mon;
  } else if (batThan === Const.BatThan.XA && batMon === Const.BatMon.DO) {
    return Const.giai_thich_dang_xa_do_mon;
  } else if (batThan === Const.BatThan.XA && batMon === Const.BatMon.CANH) {
    return Const.giai_thich_dang_xa_canh_mon;
  } else if (batThan === Const.BatThan.XA && batMon === Const.BatMon.TU) {
    return Const.giai_thich_dang_xa_tu_mon;
  } else if (batThan === Const.BatThan.XA && batMon === Const.BatMon.THUONG) {
    return Const.giai_thich_dang_xa_thuong_mon;
  } else if (batThan === Const.BatThan.XA && batMon === Const.BatMon.KINH) {
    return Const.giai_thich_dang_xa_kinh_mon;
  } else if (batThan === Const.BatThan.XA && batMon === Const.BatMon.SINH) {
    return Const.giai_thich_dang_xa_sinh_mon;
  } else if (batThan === Const.BatThan.XA && batMon === Const.BatMon.HUU) {
    return Const.giai_thich_dang_xa_huu_mon;
  } else if (batThan === Const.BatThan.XA && batMon === Const.BatMon.KHAI) {
    return Const.giai_thich_dang_xa_khai_mon;
  } else if (batThan === Const.BatThan.AM && batMon === Const.BatMon.DO) {
    return Const.giai_thich_thai_am_do_mon;
  } else if (batThan === Const.BatThan.AM && batMon === Const.BatMon.CANH) {
    return Const.giai_thich_thai_am_canh_mon;
  } else if (batThan === Const.BatThan.AM && batMon === Const.BatMon.TU) {
    return Const.giai_thich_thai_am_tu_mon;
  } else if (batThan === Const.BatThan.AM && batMon === Const.BatMon.THUONG) {
    return Const.giai_thich_thai_am_thuong_mon;
  } else if (batThan === Const.BatThan.AM && batMon === Const.BatMon.KINH) {
    return Const.giai_thich_thai_am_kinh_mon;
  } else if (batThan === Const.BatThan.AM && batMon === Const.BatMon.SINH) {
    return Const.giai_thich_thai_am_sinh_mon;
  } else if (batThan === Const.BatThan.AM && batMon === Const.BatMon.HUU) {
    return Const.giai_thich_thai_am_huu_mon;
  } else if (batThan === Const.BatThan.AM && batMon === Const.BatMon.KHAI) {
    return Const.giai_thich_thai_am_khai_mon;
  } else if (batThan === Const.BatThan.HOP && batMon === Const.BatMon.CANH) {
    return Const.giai_thich_luc_hop_canh_mon;
  } else if (batThan === Const.BatThan.HOP && batMon === Const.BatMon.TU) {
    return Const.giai_thich_luc_hop_tu_mon;
  } else if (batThan === Const.BatThan.HOP && batMon === Const.BatMon.THUONG) {
    return Const.giai_thich_luc_hop_thuong_mon;
  } else if (batThan === Const.BatThan.HOP && batMon === Const.BatMon.KINH) {
    return Const.giai_thich_luc_hop_kinh_mon;
  } else if (batThan === Const.BatThan.HOP && batMon === Const.BatMon.SINH) {
    return Const.giai_thich_luc_hop_sinh_mon;
  } else if (batThan === Const.BatThan.HOP && batMon === Const.BatMon.HUU) {
    return Const.giai_thich_luc_hop_huu_mon;
  } else if (batThan === Const.BatThan.HOP && batMon === Const.BatMon.KHAI) {
    return Const.giai_thich_luc_hop_khai_mon;
  } else if (batThan === Const.BatThan.HO && batMon === Const.BatMon.DO) {
    return Const.giai_thich_bach_ho_do_mon;
  } else if (batThan === Const.BatThan.HO && batMon === Const.BatMon.CANH) {
    return Const.giai_thich_bach_ho_canh_mon;
  } else if (batThan === Const.BatThan.HO && batMon === Const.BatMon.TU) {
    return Const.giai_thich_bach_ho_tu_mon;
  } else if (batThan === Const.BatThan.HO && batMon === Const.BatMon.THUONG) {
    return Const.giai_thich_bach_ho_thuong_mon;
  } else if (batThan === Const.BatThan.HO && batMon === Const.BatMon.KINH) {
    return Const.giai_thich_bach_ho_kinh_mon;
  } else if (batThan === Const.BatThan.HO && batMon === Const.BatMon.SINH) {
    return Const.giai_thich_bach_ho_sinh_mon;
  } else if (batThan === Const.BatThan.HO && batMon === Const.BatMon.HUU) {
    return Const.giai_thich_bach_ho_huu_mon;
  } else if (batThan === Const.BatThan.HO && batMon === Const.BatMon.KHAI) {
    return Const.giai_thich_bach_ho_khai_mon;
  } else if ((batThan === Const.BatThan.VU || batThan === Const.BatThan.TUOC) && batMon === Const.BatMon.DO) {
    return Const.giai_thich_huyen_vu_do_mon;
  } else if ((batThan === Const.BatThan.VU || batThan === Const.BatThan.TUOC) && batMon === Const.BatMon.CANH) {
    return Const.giai_thich_huyen_vu_canh_mon;
  } else if ((batThan === Const.BatThan.VU || batThan === Const.BatThan.TUOC) && batMon === Const.BatMon.TU) {
    return Const.giai_thich_huyen_vu_tu_mon;
  } else if ((batThan === Const.BatThan.VU || batThan === Const.BatThan.TUOC) && batMon === Const.BatMon.THUONG) {
    return Const.giai_thich_huyen_vu_thuong_mon;
  } else if ((batThan === Const.BatThan.VU || batThan === Const.BatThan.TUOC) && batMon === Const.BatMon.KINH) {
    return Const.giai_thich_huyen_vu_kinh_mon;
  } else if ((batThan === Const.BatThan.VU || batThan === Const.BatThan.TUOC) && batMon === Const.BatMon.SINH) {
    return Const.giai_thich_huyen_vu_sinh_mon;
  } else if ((batThan === Const.BatThan.VU || batThan === Const.BatThan.TUOC) && batMon === Const.BatMon.HUU) {
    return Const.giai_thich_huyen_vu_huu_mon;
  } else if ((batThan === Const.BatThan.VU || batThan === Const.BatThan.TUOC) && batMon === Const.BatMon.KHAI) {
    return Const.giai_thich_huyen_vu_khai_mon;
  } else if (batThan === Const.BatThan.DIA && batMon === Const.BatMon.DO) {
    return Const.giai_thich_cuu_dia_do_mon;
  } else if (batThan === Const.BatThan.DIA && batMon === Const.BatMon.CANH) {
    return Const.giai_thich_cuu_dia_canh_mon;
  } else if (batThan === Const.BatThan.DIA && batMon === Const.BatMon.TU) {
    return Const.giai_thich_cuu_dia_tu_mon;
  } else if (batThan === Const.BatThan.DIA && batMon === Const.BatMon.THUONG) {
    return Const.giai_thich_cuu_dia_thuong_mon;
  } else if (batThan === Const.BatThan.DIA && batMon === Const.BatMon.KINH) {
    return Const.giai_thich_cuu_dia_kinh_mon;
  } else if (batThan === Const.BatThan.DIA && batMon === Const.BatMon.SINH) {
    return Const.giai_thich_cuu_dia_sinh_mon;
  } else if (batThan === Const.BatThan.DIA && batMon === Const.BatMon.HUU) {
    return Const.giai_thich_cuu_dia_huu_mon;
  } else if (batThan === Const.BatThan.DIA && batMon === Const.BatMon.KHAI) {
    return Const.giai_thich_cuu_dia_khai_mon;
  } else if (batThan === Const.BatThan.THIEN && batMon === Const.BatMon.DO) {
    return Const.giai_thich_cuu_thien_do_mon;
  } else if (batThan === Const.BatThan.THIEN && batMon === Const.BatMon.CANH) {
    return Const.giai_thich_cuu_thien_canh_mon;
  } else if (batThan === Const.BatThan.THIEN && batMon === Const.BatMon.TU) {
    return Const.giai_thich_cuu_thien_tu_mon;
  } else if (batThan === Const.BatThan.THIEN && batMon === Const.BatMon.THUONG) {
    return Const.giai_thich_cuu_thien_thuong_mon;
  } else if (batThan === Const.BatThan.THIEN && batMon === Const.BatMon.KINH) {
    return Const.giai_thich_cuu_thien_kinh_mon;
  } else if (batThan === Const.BatThan.THIEN && batMon === Const.BatMon.SINH) {
    return Const.giai_thich_cuu_thien_sinh_mon;
  } else if (batThan === Const.BatThan.THIEN && batMon === Const.BatMon.HUU) {
    return Const.giai_thich_cuu_thien_huu_mon;
  } else if (batThan === Const.BatThan.THIEN && batMon === Const.BatMon.KHAI) {
    return Const.giai_thich_cuu_thien_khai_mon;
  }
}
export function GiaiThichCuuTinhTrucThoi(chi, cuuTinh) {
  if (chi.toString().includes('Tý') && cuuTinh === Const.CuuTinh.BONG) {
    return Const.giai_thich_ty_thien_bong;
  } else if (chi.toString().includes('Tý') && cuuTinh === Const.CuuTinh.NHUE) {
    return Const.giai_thich_ty_thien_nhue;
  } else if (chi.toString().includes('Tý') && cuuTinh === Const.CuuTinh.XUNG) {
    return Const.giai_thich_ty_thien_xung;
  } else if (chi.toString().includes('Tý') && cuuTinh === Const.CuuTinh.PHU) {
    return Const.giai_thich_ty_thien_phu;
  } else if (chi.toString().includes('Tý') && cuuTinh === Const.CuuTinh.CAM) {
    return Const.giai_thich_ty_thien_cam;
  } else if (chi.toString().includes('Tý') && cuuTinh === Const.CuuTinh.TAM) {
    return Const.giai_thich_ty_thien_tam;
  } else if (chi.toString().includes('Tý') && cuuTinh === Const.CuuTinh.TRU) {
    return Const.giai_thich_ty_thien_tru;
  } else if (chi.toString().includes('Tý') && cuuTinh === Const.CuuTinh.NHAM) {
    return Const.giai_thich_ty_thien_nham;
  } else if (chi.toString().includes('Tý') && cuuTinh === Const.CuuTinh.ANH) {
    return Const.giai_thich_ty_thien_anh;
  } else if (chi.toString().includes('Sửu') && cuuTinh === Const.CuuTinh.BONG) {
    return Const.giai_thich_suu_thien_bong;
  } else if (chi.toString().includes('Sửu') && cuuTinh === Const.CuuTinh.NHUE) {
    return Const.giai_thich_suu_thien_nhue;
  } else if (chi.toString().includes('Sửu') && cuuTinh === Const.CuuTinh.XUNG) {
    return Const.giai_thich_suu_thien_xung;
  } else if (chi.toString().includes('Sửu') && cuuTinh === Const.CuuTinh.PHU) {
    return Const.giai_thich_suu_thien_phu;
  } else if (chi.toString().includes('Sửu') && cuuTinh === Const.CuuTinh.CAM) {
    return Const.giai_thich_suu_thien_cam;
  } else if (chi.toString().includes('Sửu') && cuuTinh === Const.CuuTinh.TAM) {
    return Const.giai_thich_suu_thien_tam;
  } else if (chi.toString().includes('Sửu') && cuuTinh === Const.CuuTinh.TRU) {
    return Const.giai_thich_suu_thien_tru;
  } else if (chi.toString().includes('Sửu') && cuuTinh === Const.CuuTinh.NHAM) {
    return Const.giai_thich_suu_thien_nham;
  } else if (chi.toString().includes('Sửu') && cuuTinh === Const.CuuTinh.ANH) {
    return Const.giai_thich_suu_thien_anh;
  } else if (chi.toString().includes('Dần') && cuuTinh === Const.CuuTinh.BONG) {
    return Const.giai_thich_dan_thien_bong;
  } else if (chi.toString().includes('Dần') && cuuTinh === Const.CuuTinh.NHUE) {
    return Const.giai_thich_dan_thien_nhue;
  } else if (chi.toString().includes('Dần') && cuuTinh === Const.CuuTinh.XUNG) {
    return Const.giai_thich_dan_thien_xung;
  } else if (chi.toString().includes('Dần') && cuuTinh === Const.CuuTinh.PHU) {
    return Const.giai_thich_dan_thien_phu;
  } else if (chi.toString().includes('Dần') && cuuTinh === Const.CuuTinh.CAM) {
    return Const.giai_thich_dan_thien_cam;
  } else if (chi.toString().includes('Dần') && cuuTinh === Const.CuuTinh.TAM) {
    return Const.giai_thich_dan_thien_tam;
  } else if (chi.toString().includes('Dần') && cuuTinh === Const.CuuTinh.TRU) {
    return Const.giai_thich_dan_thien_tru;
  } else if (chi.toString().includes('Dần') && cuuTinh === Const.CuuTinh.NHAM) {
    return Const.giai_thich_dan_thien_nham;
  } else if (chi.toString().includes('Dần') && cuuTinh === Const.CuuTinh.ANH) {
    return Const.giai_thich_dan_thien_anh;
  } else if (chi.toString().includes('Thìn') && cuuTinh === Const.CuuTinh.BONG) {
    return Const.giai_thich_thin_thien_bong;
  } else if (chi.toString().includes('Thìn') && cuuTinh === Const.CuuTinh.NHUE) {
    return Const.giai_thich_thin_thien_nhue;
  } else if (chi.toString().includes('Thìn') && cuuTinh === Const.CuuTinh.XUNG) {
    return Const.giai_thich_thin_thien_xung;
  } else if (chi.toString().includes('Thìn') && cuuTinh === Const.CuuTinh.PHU) {
    return Const.giai_thich_thin_thien_phu;
  } else if (chi.toString().includes('Thìn') && cuuTinh === Const.CuuTinh.CAM) {
    return Const.giai_thich_thin_thien_cam;
  } else if (chi.toString().includes('Thìn') && cuuTinh === Const.CuuTinh.TAM) {
    return Const.giai_thich_thin_thien_tam;
  } else if (chi.toString().includes('Thìn') && cuuTinh === Const.CuuTinh.TRU) {
    return Const.giai_thich_thin_thien_tru;
  } else if (chi.toString().includes('Thìn') && cuuTinh === Const.CuuTinh.NHAM) {
    return Const.giai_thich_thin_thien_nham;
  } else if (chi.toString().includes('Thìn') && cuuTinh === Const.CuuTinh.ANH) {
    return Const.giai_thich_thin_thien_anh;
  } else if (chi.toString().includes('Tỵ') && cuuTinh === Const.CuuTinh.BONG) {
    return Const.giai_thich_tys_thien_bong;
  } else if (chi.toString().includes('Tỵ') && cuuTinh === Const.CuuTinh.NHUE) {
    return Const.giai_thich_tys_thien_nhue;
  } else if (chi.toString().includes('Tỵ') && cuuTinh === Const.CuuTinh.XUNG) {
    return Const.giai_thich_tys_thien_xung;
  } else if (chi.toString().includes('Tỵ') && cuuTinh === Const.CuuTinh.PHU) {
    return Const.giai_thich_tys_thien_phu;
  } else if (chi.toString().includes('Tỵ') && cuuTinh === Const.CuuTinh.CAM) {
    return Const.giai_thich_tys_thien_cam;
  } else if (chi.toString().includes('Tỵ') && cuuTinh === Const.CuuTinh.TAM) {
    return Const.giai_thich_tys_thien_tam;
  } else if (chi.toString().includes('Tỵ') && cuuTinh === Const.CuuTinh.TRU) {
    return Const.giai_thich_tys_thien_tru;
  } else if (chi.toString().includes('Tỵ') && cuuTinh === Const.CuuTinh.NHAM) {
    return Const.giai_thich_tys_thien_nham;
  } else if (chi.toString().includes('Tỵ') && cuuTinh === Const.CuuTinh.ANH) {
    return Const.giai_thich_tys_thien_anh;
  } else if (chi.toString().includes('Ngọ') && cuuTinh === Const.CuuTinh.BONG) {
    return Const.giai_thich_ngo_thien_bong;
  } else if (chi.toString().includes('Ngọ') && cuuTinh === Const.CuuTinh.NHUE) {
    return Const.giai_thich_ngo_thien_nhue;
  } else if (chi.toString().includes('Ngọ') && cuuTinh === Const.CuuTinh.XUNG) {
    return Const.giai_thich_ngo_thien_xung;
  } else if (chi.toString().includes('Ngọ') && cuuTinh === Const.CuuTinh.PHU) {
    return Const.giai_thich_ngo_thien_phu;
  } else if (chi.toString().includes('Ngọ') && cuuTinh === Const.CuuTinh.CAM) {
    return Const.giai_thich_ngo_thien_cam;
  } else if (chi.toString().includes('Ngọ') && cuuTinh === Const.CuuTinh.TAM) {
    return Const.giai_thich_ngo_thien_tam;
  } else if (chi.toString().includes('Ngọ') && cuuTinh === Const.CuuTinh.TRU) {
    return Const.giai_thich_ngo_thien_tru;
  } else if (chi.toString().includes('Ngọ') && cuuTinh === Const.CuuTinh.NHAM) {
    return Const.giai_thich_ngo_thien_nham;
  } else if (chi.toString().includes('Ngọ') && cuuTinh === Const.CuuTinh.ANH) {
    return Const.giai_thich_ngo_thien_anh;
  } else if (chi.toString().includes('Mùi') && cuuTinh === Const.CuuTinh.BONG) {
    return Const.giai_thich_mui_thien_bong;
  } else if (chi.toString().includes('Mùi') && cuuTinh === Const.CuuTinh.NHUE) {
    return Const.giai_thich_mui_thien_nhue;
  } else if (chi.toString().includes('Mùi') && cuuTinh === Const.CuuTinh.XUNG) {
    return Const.giai_thich_mui_thien_xung;
  } else if (chi.toString().includes('Mùi') && cuuTinh === Const.CuuTinh.PHU) {
    return Const.giai_thich_mui_thien_phu;
  } else if (chi.toString().includes('Mùi') && cuuTinh === Const.CuuTinh.CAM) {
    return Const.giai_thich_mui_thien_cam;
  } else if (chi.toString().includes('Mùi') && cuuTinh === Const.CuuTinh.TAM) {
    return Const.giai_thich_mui_thien_tam;
  } else if (chi.toString().includes('Mùi') && cuuTinh === Const.CuuTinh.TRU) {
    return Const.giai_thich_mui_thien_tru;
  } else if (chi.toString().includes('Mùi') && cuuTinh === Const.CuuTinh.NHAM) {
    return Const.giai_thich_mui_thien_nham;
  } else if (chi.toString().includes('Mùi') && cuuTinh === Const.CuuTinh.ANH) {
    return Const.giai_thich_mui_thien_anh;
  } else if (chi.toString().includes('Thân') && cuuTinh === Const.CuuTinh.BONG) {
    return Const.giai_thich_than_thien_bong;
  } else if (chi.toString().includes('Thân') && cuuTinh === Const.CuuTinh.NHUE) {
    return Const.giai_thich_than_thien_nhue;
  } else if (chi.toString().includes('Thân') && cuuTinh === Const.CuuTinh.XUNG) {
    return Const.giai_thich_than_thien_xung;
  } else if (chi.toString().includes('Thân') && cuuTinh === Const.CuuTinh.PHU) {
    return Const.giai_thich_than_thien_phu;
  } else if (chi.toString().includes('Thân') && cuuTinh === Const.CuuTinh.CAM) {
    return Const.giai_thich_than_thien_cam;
  } else if (chi.toString().includes('Thân') && cuuTinh === Const.CuuTinh.TAM) {
    return Const.giai_thich_than_thien_tam;
  } else if (chi.toString().includes('Thân') && cuuTinh === Const.CuuTinh.TRU) {
    return Const.giai_thich_than_thien_tru;
  } else if (chi.toString().includes('Thân') && cuuTinh === Const.CuuTinh.NHAM) {
    return Const.giai_thich_than_thien_nham;
  } else if (chi.toString().includes('Thân') && cuuTinh === Const.CuuTinh.ANH) {
    return Const.giai_thich_than_thien_anh;
  } else if (chi.toString().includes('Dậu') && cuuTinh === Const.CuuTinh.BONG) {
    return Const.giai_thich_dau_thien_bong;
  } else if (chi.toString().includes('Dậu') && cuuTinh === Const.CuuTinh.NHUE) {
    return Const.giai_thich_dau_thien_nhue;
  } else if (chi.toString().includes('Dậu') && cuuTinh === Const.CuuTinh.XUNG) {
    return Const.giai_thich_dau_thien_xung;
  } else if (chi.toString().includes('Dậu') && cuuTinh === Const.CuuTinh.PHU) {
    return Const.giai_thich_dau_thien_phu;
  } else if (chi.toString().includes('Dậu') && cuuTinh === Const.CuuTinh.CAM) {
    return Const.giai_thich_dau_thien_cam;
  } else if (chi.toString().includes('Dậu') && cuuTinh === Const.CuuTinh.TAM) {
    return Const.giai_thich_dau_thien_tam;
  } else if (chi.toString().includes('Dậu') && cuuTinh === Const.CuuTinh.TRU) {
    return Const.giai_thich_dau_thien_tru;
  } else if (chi.toString().includes('Dậu') && cuuTinh === Const.CuuTinh.NHAM) {
    return Const.giai_thich_dau_thien_nham;
  } else if (chi.toString().includes('Dậu') && cuuTinh === Const.CuuTinh.ANH) {
    return Const.giai_thich_dau_thien_anh;
  } else if (chi.toString().includes('Tuất') && cuuTinh === Const.CuuTinh.BONG) {
    return Const.giai_thich_tuat_thien_bong;
  } else if (chi.toString().includes('Tuất') && cuuTinh === Const.CuuTinh.NHUE) {
    return Const.giai_thich_tuat_thien_nhue;
  } else if (chi.toString().includes('Tuất') && cuuTinh === Const.CuuTinh.XUNG) {
    return Const.giai_thich_tuat_thien_xung;
  } else if (chi.toString().includes('Tuất') && cuuTinh === Const.CuuTinh.PHU) {
    return Const.giai_thich_tuat_thien_phu;
  } else if (chi.toString().includes('Tuất') && cuuTinh === Const.CuuTinh.CAM) {
    return Const.giai_thich_tuat_thien_cam;
  } else if (chi.toString().includes('Tuất') && cuuTinh === Const.CuuTinh.TAM) {
    return Const.giai_thich_tuat_thien_tam;
  } else if (chi.toString().includes('Tuất') && cuuTinh === Const.CuuTinh.TRU) {
    return Const.giai_thich_tuat_thien_tru;
  } else if (chi.toString().includes('Tuất') && cuuTinh === Const.CuuTinh.NHAM) {
    return Const.giai_thich_tuat_thien_nham;
  } else if (chi.toString().includes('Tuất') && cuuTinh === Const.CuuTinh.ANH) {
    return Const.giai_thich_tuat_thien_anh;
  } else if (chi.toString().includes('Hợi') && cuuTinh === Const.CuuTinh.BONG) {
    return Const.giai_thich_hoi_thien_bong;
  } else if (chi.toString().includes('Hợi') && cuuTinh === Const.CuuTinh.NHUE) {
    return Const.giai_thich_hoi_thien_nhue;
  } else if (chi.toString().includes('Hợi') && cuuTinh === Const.CuuTinh.XUNG) {
    return Const.giai_thich_hoi_thien_xung;
  } else if (chi.toString().includes('Hợi') && cuuTinh === Const.CuuTinh.PHU) {
    return Const.giai_thich_hoi_thien_phu;
  } else if (chi.toString().includes('Hợi') && cuuTinh === Const.CuuTinh.CAM) {
    return Const.giai_thich_hoi_thien_cam;
  } else if (chi.toString().includes('Hợi') && cuuTinh === Const.CuuTinh.TAM) {
    return Const.giai_thich_hoi_thien_tam;
  } else if (chi.toString().includes('Hợi') && cuuTinh === Const.CuuTinh.TRU) {
    return Const.giai_thich_hoi_thien_tru;
  } else if (chi.toString().includes('Hợi') && cuuTinh === Const.CuuTinh.NHAM) {
    return Const.giai_thich_hoi_thien_nham;
  } else if (chi.toString().includes('Hợi') && cuuTinh === Const.CuuTinh.ANH) {
    return Const.giai_thich_hoi_thien_anh;
  } else {
    return null;
  }
}
export function GiaiThichTamKyBatMon(thienBan, batMon) {
  if (batMon === Const.BatMon.HUU && thienBan === Const.ThienBan.AT) {
    return Const.giai_thich_huu_mon_at;
  } else if (batMon === Const.BatMon.HUU && thienBan === Const.ThienBan.BINH) {
    return Const.giai_thich_huu_mon_binh;
  } else if (batMon === Const.BatMon.HUU && thienBan === Const.ThienBan.DINH) {
    return Const.giai_thich_huu_mon_dinh;
  } else if (batMon === Const.BatMon.SINH && thienBan === Const.ThienBan.AT) {
    return Const.giai_thich_sinh_mon_at;
  } else if (batMon === Const.BatMon.SINH && thienBan === Const.ThienBan.BINH) {
    return Const.giai_thich_sinh_mon_binh;
  } else if (batMon === Const.BatMon.SINH && thienBan === Const.ThienBan.DINH) {
    return Const.giai_thich_sinh_mon_dinh;
  } else if (batMon === Const.BatMon.KHAI && thienBan === Const.ThienBan.AT) {
    return Const.giai_thich_khai_mon_at;
  } else if (batMon === Const.BatMon.KHAI && thienBan === Const.ThienBan.BINH) {
    return Const.giai_thich_khai_mon_binh;
  } else if (batMon === Const.BatMon.KHAI && thienBan === Const.ThienBan.DINH) {
    return Const.giai_thich_khai_mon_dinh;
  } else {
    return null;
  }
}
export function GiaiThichDinhBatThan(batThan) {
  if (batThan === Const.BatThan.XA) {
    return Const.giai_thich_dang_xa;
  } else if (batThan === Const.BatThan.AM) {
    return Const.giai_thich_thai_am;
  } else if (batThan === Const.BatThan.HOP) {
    return Const.giai_thich_luc_hop;
  } else if (batThan === Const.BatThan.TRAN || batThan === Const.BatThan.HO) {
    return Const.giai_thich_cau_tran;
  } else if (batThan === Const.BatThan.TUOC) {
    return Const.giai_thich_chu_tuoc;
  } else {
    return null;
  }
}
export function GiaiThichThienBanDiaBan(thienBan) {
  if (thienBan == Const.ThienBan.MAU) {
    return Const.giai_thich_thien_dia_mau_nghi;
  } else if (thienBan == Const.ThienBan.KY) {
    return Const.giai_thich_thien_dia_ky_nghi;
  } else if (thienBan == Const.ThienBan.CANH) {
    return Const.giai_thich_thien_dia_canh_nghi;
  } else if (thienBan == Const.ThienBan.TAN) {
    return Const.giai_thich_thien_dia_tan_nghi;
  } else if (thienBan == Const.ThienBan.NHAM) {
    return Const.giai_thich_thien_dia_nham_nghi;
  } else if (thienBan == Const.ThienBan.QUY) {
    return Const.giai_thich_thien_dia_quy_nghi;
  } else {
    return null;
  }
}
export function GiaiThichBatMonBatQuai(batMon, batQuai) {
  if (batMon === Const.BatMon.HUU && batQuai === Const.batQuai.KHAM) {
    return Const.giai_thich_huu_kham;
  } else if (batMon === Const.BatMon.HUU && batQuai === Const.batQuai.KHON) {
    return Const.giai_thich_huu_khon;
  } else if (batMon === Const.BatMon.HUU && batQuai === Const.batQuai.CHAN) {
    return Const.giai_thich_huu_chan;
  } else if (batMon === Const.BatMon.HUU && batQuai === Const.batQuai.TON) {
    return Const.giai_thich_huu_ton;
  } else if (batMon === Const.BatMon.HUU && batQuai === Const.batQuai.CANF) {
    return Const.giai_thich_huu_can;
  } else if (batMon === Const.BatMon.HUU && batQuai === Const.batQuai.DOAI) {
    return Const.giai_thich_huu_doan;
  } else if (batMon === Const.BatMon.HUU && batQuai === Const.batQuai.CANAS) {
    return Const.giai_thich_huu_canas;
  } else if (batMon === Const.BatMon.HUU && batQuai === Const.batQuai.LY) {
    return Const.giai_thich_huu_ly;
  } else if (batMon === Const.BatMon.SINH && batQuai === Const.batQuai.KHAM) {
    return Const.giai_thich_sinh_kham;
  } else if (batMon === Const.BatMon.SINH && batQuai === Const.batQuai.KHON) {
    return Const.giai_thich_sinh_khon;
  } else if (batMon === Const.BatMon.SINH && batQuai === Const.batQuai.CHAN) {
    return Const.giai_thich_sinh_chan;
  } else if (batMon === Const.BatMon.SINH && batQuai === Const.batQuai.TON) {
    return Const.giai_thich_sinh_ton;
  } else if (batMon === Const.BatMon.SINH && batQuai === Const.batQuai.CANF) {
    return Const.giai_thich_sinh_can;
  } else if (batMon === Const.BatMon.SINH && batQuai === Const.batQuai.DOAI) {
    return Const.giai_thich_sinh_doan;
  } else if (batMon === Const.BatMon.SINH && batQuai === Const.batQuai.CANAS) {
    return Const.giai_thich_sinh_canas;
  } else if (batMon === Const.BatMon.SINH && batQuai === Const.batQuai.LY) {
    return Const.giai_thich_sinh_ly;
  } else if (batMon === Const.BatMon.THUONG && batQuai === Const.batQuai.KHAM) {
    return Const.giai_thich_thuong_kham;
  } else if (batMon === Const.BatMon.THUONG && batQuai === Const.batQuai.KHON) {
    return Const.giai_thich_thuong_khon;
  } else if (batMon === Const.BatMon.THUONG && batQuai === Const.batQuai.CHAN) {
    return Const.giai_thich_thuong_chan;
  } else if (batMon === Const.BatMon.THUONG && batQuai === Const.batQuai.TON) {
    return Const.giai_thich_thuong_ton;
  } else if (batMon === Const.BatMon.THUONG && batQuai === Const.batQuai.CANF) {
    return Const.giai_thich_thuong_can;
  } else if (batMon === Const.BatMon.THUONG && batQuai === Const.batQuai.DOAI) {
    return Const.giai_thich_thuong_doan;
  } else if (batMon === Const.BatMon.THUONG && batQuai === Const.batQuai.CANAS) {
    return Const.giai_thich_thuong_canas;
  } else if (batMon === Const.BatMon.THUONG && batQuai === Const.batQuai.LY) {
    return Const.giai_thich_thuong_ly;
  } else if (batMon === Const.BatMon.DO && batQuai === Const.batQuai.KHAM) {
    return Const.giai_thich_do_kham;
  } else if (batMon === Const.BatMon.DO && batQuai === Const.batQuai.KHON) {
    return Const.giai_thich_do_khon;
  } else if (batMon === Const.BatMon.DO && batQuai === Const.batQuai.CHAN) {
    return Const.giai_thich_do_chan;
  } else if (batMon === Const.BatMon.DO && batQuai === Const.batQuai.TON) {
    return Const.giai_thich_do_ton;
  } else if (batMon === Const.BatMon.DO && batQuai === Const.batQuai.CANF) {
    return Const.giai_thich_do_can;
  } else if (batMon === Const.BatMon.DO && batQuai === Const.batQuai.DOAI) {
    return Const.giai_thich_do_doan;
  } else if (batMon === Const.BatMon.DO && batQuai === Const.batQuai.CANAS) {
    return Const.giai_thich_do_canas;
  } else if (batMon === Const.BatMon.DO && batQuai === Const.batQuai.LY) {
    return Const.giai_thich_do_ly;
  } else if (batMon === Const.BatMon.CANH && batQuai === Const.batQuai.KHAM) {
    return Const.giai_thich_canh_kham;
  } else if (batMon === Const.BatMon.CANH && batQuai === Const.batQuai.KHON) {
    return Const.giai_thich_canh_khon;
  } else if (batMon === Const.BatMon.CANH && batQuai === Const.batQuai.CHAN) {
    return Const.giai_thich_canh_chan;
  } else if (batMon === Const.BatMon.CANH && batQuai === Const.batQuai.TON) {
    return Const.giai_thich_canh_ton;
  } else if (batMon === Const.BatMon.CANH && batQuai === Const.batQuai.CANF) {
    return Const.giai_thich_canh_can;
  } else if (batMon === Const.BatMon.CANH && batQuai === Const.batQuai.DOAI) {
    return Const.giai_thich_canh_doan;
  } else if (batMon === Const.BatMon.CANH && batQuai === Const.batQuai.CANAS) {
    return Const.giai_thich_canh_canas;
  } else if (batMon === Const.BatMon.CANH && batQuai === Const.batQuai.LY) {
    return Const.giai_thich_canh_ly;
  } else if (batMon === Const.BatMon.TU && batQuai === Const.batQuai.KHAM) {
    return Const.giai_thich_tu_kham;
  } else if (batMon === Const.BatMon.TU && batQuai === Const.batQuai.KHON) {
    return Const.giai_thich_tu_khon;
  } else if (batMon === Const.BatMon.TU && batQuai === Const.batQuai.CHAN) {
    return Const.giai_thich_tu_chan;
  } else if (batMon === Const.BatMon.TU && batQuai === Const.batQuai.TON) {
    return Const.giai_thich_tu_ton;
  } else if (batMon === Const.BatMon.TU && batQuai === Const.batQuai.CANF) {
    return Const.giai_thich_tu_can;
  } else if (batMon === Const.BatMon.TU && batQuai === Const.batQuai.DOAI) {
    return Const.giai_thich_tu_doan;
  } else if (batMon === Const.BatMon.TU && batQuai === Const.batQuai.CANAS) {
    return Const.giai_thich_tu_canas;
  } else if (batMon === Const.BatMon.TU && batQuai === Const.batQuai.LY) {
    return Const.giai_thich_tu_ly;
  } else if (batMon === Const.BatMon.KINH && batQuai === Const.batQuai.KHAM) {
    return Const.giai_thich_kinh_kham;
  } else if (batMon === Const.BatMon.KINH && batQuai === Const.batQuai.KHON) {
    return Const.giai_thich_kinh_khon;
  } else if (batMon === Const.BatMon.KINH && batQuai === Const.batQuai.CHAN) {
    return Const.giai_thich_kinh_chan;
  } else if (batMon === Const.BatMon.KINH && batQuai === Const.batQuai.TON) {
    return Const.giai_thich_kinh_ton;
  } else if (batMon === Const.BatMon.KINH && batQuai === Const.batQuai.CANF) {
    return Const.giai_thich_kinh_can;
  } else if (batMon === Const.BatMon.KINH && batQuai === Const.batQuai.DOAI) {
    return Const.giai_thich_kinh_doan;
  } else if (batMon === Const.BatMon.KINH && batQuai === Const.batQuai.CANAS) {
    return Const.giai_thich_kinh_canas;
  } else if (batMon === Const.BatMon.KINH && batQuai === Const.batQuai.LY) {
    return Const.giai_thich_kinh_ly;
  } else if (batMon === Const.BatMon.KHAI && batQuai === Const.batQuai.KHAM) {
    return Const.giai_thich_khai_kham;
  } else if (batMon === Const.BatMon.KHAI && batQuai === Const.batQuai.KHON) {
    return Const.giai_thich_khai_khon;
  } else if (batMon === Const.BatMon.KHAI && batQuai === Const.batQuai.CHAN) {
    return Const.giai_thich_khai_chan;
  } else if (batMon === Const.BatMon.KHAI && batQuai === Const.batQuai.TON) {
    return Const.giai_thich_khai_ton;
  } else if (batMon === Const.BatMon.KHAI && batQuai === Const.batQuai.CANF) {
    return Const.giai_thich_khai_can;
  } else if (batMon === Const.BatMon.KHAI && batQuai === Const.batQuai.DOAI) {
    return Const.giai_thich_khai_doan;
  } else if (batMon === Const.BatMon.KHAI && batQuai === Const.batQuai.CANAS) {
    return Const.giai_thich_khai_canas;
  } else if (batMon === Const.BatMon.KHAI && batQuai === Const.batQuai.LY) {
    return Const.giai_thich_khai_ly;
  } else {
    return null;
  }
}

export const getNumberChi = async (chiAny) => {
  switch (chiAny) {
    case "Tý":
      return 1
    case "Sửu":
      return 2
    case "Dần":
      return 3
    case "Mão":
      return 4
    case "Thìn":
      return 5
    case "Tỵ":
      return 6
    case "Ngọ":
      return 7
    case "Mùi":
      return 8
    case "Thân":
      return 9
    case "Dậu":
      return 10
    case "Tuất":
      return 11
    case "Hợi":
      return 12
    default:
      break;
  }
}
