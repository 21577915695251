import vi from "date-fns/locale/vi";
import * as firebase from "firebase";

import md5 from "md5";
import React from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GoogleLogin, { GoogleLogout } from "react-google-login";
import { withRouter } from "react-router-dom";
import { Button, Dropdown, Image, Input, Modal } from "semantic-ui-react";
import "../App.css";
import globalUtils from "../globalUntils.js";
import thongTinKhoaHoc from "../image/infoSubject.jpg";
import CHUONGTRINHGIANGDAY01 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-01.png";
import CHUONGTRINHGIANGDAY02 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-02.png";
import CHUONGTRINHGIANGDAY03 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-03.png";
import CHUONGTRINHGIANGDAY04 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-04.png";
import CHUONGTRINHGIANGDAY05 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-05.png";
import CHUONGTRINHGIANGDAY06 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-06.png";
import CHUONGTRINHGIANGDAY07 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-07.png";
import CHUONGTRINHGIANGDAY08 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-08.png";
import CHUONGTRINHGIANGDAY09 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-09.png";
import CHUONGTRINHGIANGDAY10 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-10.png";
import CHUONGTRINHGIANGDAY11 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-11.png";
import Logo from "../logo.png";

const mdfive = md5("HuyenKhongTamNguyenFengShuiWebSiteHitekTuongMinh");
registerLocale("vi", vi);

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expireDate: "",
      userName: "",
      modalPressSearchCode: false,
      modalPressCode: false,
      modalErrorCode: false,
      modalUsedCode: false,
      modalSuccessCode: false,
      modalEmptyCode: false,
      isLoading: false,
    };
  }
  onSendRedeemCode = (Code) => {
    const getObj = localStorage.getItem(mdfive);
    const emailSavedStorage = JSON.parse(getObj);

    let timeStart = new Date();
    //check trình duyệt
    // var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = "" + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;
    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
      browserName = "Opera";
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
      browserName = "Microsoft Internet Explorer";
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
      browserName = "Chrome";
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
      browserName = "Safari";
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
      browserName = "Firefox";
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if (
      (nameOffset = nAgt.lastIndexOf(" ") + 1) <
      (verOffset = nAgt.lastIndexOf("/"))
    ) {
      browserName = nAgt.substring(nameOffset, verOffset);
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) !== -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) !== -1)
      fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt("" + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = "" + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    var database = firebase.app("database").firestore();
    var data = database.collection("redeem_code_HuyenKhong").doc(Code);

    var dataUser = database
      .collection("User_HuyenKhong")
      .doc(emailSavedStorage.email);
    data.get().then((doc) => {
      if (doc.exists) {
        if (!doc.data()?.isActive) {
          if (doc.data()?.level === 1) {
            timeStart.setMonth(timeStart.getMonth() + 3);
            data.update({
              ...doc.data(),
              isActive: true,
              enableHistory: true,
            });
            dataUser.set({
              ...doc.data(),
              name: this.state.userName,
              isActive: true,
              isHistory: true,
              level: 1,
              email: emailSavedStorage.email,
              activeOnBrower: browserName,
              exprireDate: new Date(timeStart.toDateString()),
              activeDate: new Date(),
            });
          } else if (doc.data()?.level === 2) {
            timeStart.setMonth(timeStart.getMonth() + 6);
            data.update({
              ...doc.data(),
              isActive: true,
              enableHistory: true,
            });
            dataUser.set({
              ...doc.data(),
              name: this.state.userName,
              isActive: true,
              isHistory: true,
              level: 2,
              email: emailSavedStorage.email,
              activeOnBrower: browserName,
              exprireDate: new Date(timeStart.toDateString()),
              activeDate: new Date(),
            });
          } else if (doc.data()?.level === 3) {
            timeStart.setMonth(timeStart.getMonth() + 12);
            data.update({
              ...doc.data(),
              isActive: true,
              enableHistory: true,
            });
            dataUser.set({
              ...doc.data(),
              name: this.state.userName,
              isActive: true,
              isHistory: true,
              level: 3,
              email: emailSavedStorage.email,
              activeOnBrower: browserName,
              exprireDate: new Date(timeStart.toDateString()),
              activeDate: new Date(),
            });
          } else if (doc.data()?.level === 4) {
            timeStart.setFullYear(timeStart.getFullYear() + 3);
            data.update({
              ...doc.data(),
              isActive: true,
              enableHistory: true,
            });
            dataUser.set({
              ...doc.data(),
              name: this.state.userName,
              isActive: true,
              isHistory: true,
              level: 4,
              email: emailSavedStorage.email,
              activeOnBrower: browserName,
              exprireDate: new Date(timeStart.toDateString()),
              activeDate: new Date(),
            });
          } else if (doc.data()?.level === 5) {
            timeStart.setFullYear(timeStart.getFullYear() + 100);
            data.update({
              ...doc.data(),
              isActive: true,
              enableHistory: true,
            });
            dataUser.set({
              ...doc.data(),
              name: this.state.userName,
              isActive: true,
              isHistory: true,
              level: 5,
              email: emailSavedStorage.email,
              activeOnBrower: browserName,
              exprireDate: new Date(timeStart.toDateString()),
              activeDate: new Date(),
            });
          }

          const dataStorage = {
            ...emailSavedStorage,
            userName: this.state.userName,
            email: emailSavedStorage.email,
            isActive: true,
            isHistory: true,
            exprireDate: timeStart,
          };
          const jsonUser = JSON.stringify(dataStorage);

          this.setState({ modalSuccessCode: true }, () =>
            localStorage.setItem(mdfive, jsonUser)
          );
        } else {
          this.setState({ modalUsedCode: true });
        }
      } else {
        this.setState({ modalErrorCode: true });
      }
    });
  };
  onSendRedeemSearchCode = (Code) => {
    const getObj = localStorage.getItem(mdfive);
    const emailSavedStorage = JSON.parse(getObj);

    if (!emailSavedStorage) {
      return;
    }

    let timeStart = new Date();
    //check trình duyệt

    var database = firebase.app("database").firestore();
    var data = database.collection("code_search_HuyenKhong").doc(Code);

    var dataUser = database
      .collection("User_HuyenKhong")
      .doc(emailSavedStorage.email);
    data.get().then((doc) => {
      if (doc.exists) {
        if (doc.data()?.isActive === false) {
          // if (doc.data()?.level === 1) {
          //   timeStart.setMonth(timeStart.getMonth() + 3);
          // data.update({
          //   ...doc.data(),
          //   isActive: true,
          // });
          dataUser.get().then((userData) => {
            dataUser.set({
              ...userData.data(),
              activeSearch: true,
            });
          });

          const dataStorage = {
            ...emailSavedStorage,
            userName: this.state.userName,
            email: emailSavedStorage.email,
            isActive: doc.data()?.isActive,
            activeSearch: true,
          };
          const jsonUser = JSON.stringify(dataStorage);

          this.setState({ modalSuccessCode: true }, () =>
            localStorage.setItem(mdfive, jsonUser)
          );
        } else {
          this.setState({ modalUsedCode: true });
        }
      } else {
        this.setState({ modalErrorCode: true });
      }
    });
  };
  async checkLogin() {
    try {
      const emailSaveStorage = localStorage.getItem(mdfive);
      const userData = JSON.parse(emailSaveStorage);
      if (userData.isCustomer) {
        localStorage.clear();
        this.logout();
      } else {
        var database = await firebase.app("database").firestore();

        if (userData?.email) {
          var data = database
            .collection("User_HuyenKhong")
            .doc(userData?.email);
          data.get().then((doc) => {
            if (doc.exists) {
              globalUtils.isLogin = true;
              this.setState({ userName: doc.data().name });
              const expireDate = new Date(
                doc.data().exprireDate.seconds * 1000
              );
              this.setState({ expireDate });
              const toDay = new Date();

              if (expireDate < toDay) {
                this.setState({ expireDate: null });
                data
                  .update({
                    isActive: false,
                  })
                  .then((valueSuccess) => {})
                  .catch((error) => {
                    console.error("Error updating document: ", error);
                  });
                const dataStorage = {
                  userName: this.state.userName,
                  email: userData.email,
                  ...userData,
                  isActive: false,
                  exprireDate: this.state.expireDate,
                };
                const jsonUser = JSON.stringify(dataStorage);
                localStorage.setItem(mdfive, jsonUser);
              } else if (doc.data().isActive) {
                const dataStorage = {
                  userName: this.state.userName,
                  email: userData.email,
                  ...userData,
                  isActive: doc.data().isActive,
                  exprireDate: this.state.expireDate,
                };
                const jsonUser = JSON.stringify(dataStorage);
                localStorage.setItem(mdfive, jsonUser);
              }
            } else {
              const dataStorage = {
                ...doc.data(),
              };
              const jsonUser = JSON.stringify(dataStorage);
              localStorage.setItem(mdfive, jsonUser);
            }
          });
        }
      }
    } catch (error) {
      localStorage.clear();
    }
  }
  componentDidMount() {
    this.checkLogin();
  }
  checkExist(doc, data, database, response) {
    var expireDate = new Date();

    return new Promise((resolve, reject) => {
      if (doc.exists) {
        globalUtils.isLogin = true;
        //giải quyết vụ 5 ngày là customer, sau 5 ngày không là gì cả
        expireDate = new Date(doc.data().exprireDate.seconds * 1000);

        const toDay = new Date();

        if (expireDate < toDay && doc.data().isActive) {
          data
            .update({
              isActive: false,
            })
            .then((valueSuccess) => {})
            .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
        }
      } else {
        // trường hợp đăng nhập lần đầu thì ghi vô data base
        expireDate.setDate(expireDate.getDate() + 5);

        database
          .collection("User_HuyenKhong")
          .doc(response.profileObj.email)
          .set({
            email: response.profileObj.email,
            name: response.profileObj.name,
            isActive: true,
            loginDate: new Date(),
            exprireDate: expireDate,
          });
      }
      const dataStorage = {
        userName: response.profileObj.name,
        email: response.profileObj.email,

        ...doc.data(),
        isActive: true,
        exprireDate: expireDate,
      };
      const jsonUser = JSON.stringify(dataStorage);
      localStorage.setItem(mdfive, jsonUser);
      resolve();
    });
  }
  login = (response) => {
    this.setState({ isLoading: true });
    const database = firebase.app("database").firestore();

    if (response.accessToken) {
      globalUtils.isLogin = true;
      this.setState({ userName: response.profileObj.name });

      // var newDoc = database.collection('User_HuyenKhong').doc();
      var data = database
        .collection("User_HuyenKhong")
        .doc(response.profileObj.email);

      data.get().then((doc) => {
        //check tồn tại
        this.checkExist(doc, data, database, response)
          .then(() => {
            this.setState({ isLoading: false });

            setTimeout(() => {
              window.location.reload(false);
            }, 1000);
          })
          .catch((err) => console.log(err));
      });
    }
  };
  logout = (response) => {
    this.setState({ isLoading: true });
    localStorage.clear();
    setTimeout(() => {
      window.location.reload(false);
      this.setState({ isLoading: false });
    }, 500);
  };
  renderModalErrorPressCode = (nameModal, actionsCloseModal, content) => {
    return (
      <Modal open={nameModal} size="small">
        <Modal.Content>
          <p>{content}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Đóng" onClick={actionsCloseModal} />
        </Modal.Actions>
      </Modal>
    );
  };
  render() {
    return (
      <div>
        {this.renderModalErrorPressCode(
          this.state.modalErrorCode,
          () => this.setState({ modalErrorCode: false }),
          "Mã code của bạn nhập sai, hệ thống chúng tôi phân biệt chữ hoa và chữ thường vì vậy vui lòng kiểm tra la mã code và thử lại."
        )}
        {this.renderModalErrorPressCode(
          this.state.modalUsedCode,
          () => this.setState({ modalUsedCode: false }),
          "Mã code của bạn đã được sử dụng vui lòng kiểm tra lại mã Code hoặc Liên hệ với chúng tôi để được hỗ trợ thêm."
        )}
        {this.renderModalErrorPressCode(
          this.state.modalSuccessCode,
          () =>
            this.setState({ modalSuccessCode: false }, () =>
              window.location.reload(false)
            ),
          "Nhập Code thành công."
        )}
        {this.renderModalErrorPressCode(
          this.state.modalEmptyCode,
          () => this.setState({ modalEmptyCode: false }),
          "Xin vui lòng nhập mã code trước khi nhấn gửi!!"
        )}
        <Modal open={this.state.modalPressCode}>
          <Modal.Content>
            <div>
              <p style={{ marginBottom: "10px" }}>
                {`Xin chào, ${this.state.userName}. Dưới đây là thông tin sử dụng WebSite của chúng
tôi, sau thông tin này bạn có thể liên hệ với chúng tôi để có thể sử
dụng web một cách toàn vẹn nhất có thể bạn nhé!!!! Xin mời bạn nhập
Code dưới này và nhấn nút gửi đi để có thể sử dụng web`}
              </p>
              <Input
                id="redeemCode"
                size="small"
                placeholder="Nhập Code..."
                type="string"
                onChange={(c) => {}}
              />
              <Button
                onClick={() => {
                  var code = document.getElementById("redeemCode");
                  if (code?.value !== "") {
                    this.onSendRedeemCode(code?.value);
                  } else {
                    this.setState({ modalEmptyCode: true });
                  }
                }}
              >
                Gửi đi
              </Button>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => this.setState({ modalPressCode: false })}
              primary
            >
              Đóng
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal open={this.state.modalPressSearchCode}>
          <Modal.Content>
            <div>
              <p style={{ marginBottom: "10px" }}>
                {`Xin chào, bạn muốn sử dụng chức năng này vui lòng nhập code, nếu chưa có code vui lòng liên hệ với chúng tôi để có thông tin chi tiết về chức năng này nhé.`}
              </p>
              <Input
                id="redeemSearchCode"
                size="small"
                placeholder="Nhập Code..."
                type="string"
                onChange={(c) => {}}
              />
              <Button
                onClick={() => {
                  var code = document.getElementById("redeemSearchCode");
                  if (code?.value !== "") {
                    this.onSendRedeemSearchCode(code?.value);
                  } else {
                    this.setState({ modalEmptyCode: true });
                  }
                }}
              >
                Gửi đi
              </Button>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => this.setState({ modalPressSearchCode: false })}
              primary
            >
              Đóng
            </Button>
          </Modal.Actions>
        </Modal>

        <table className="center-header">
          <tbody>
            <tr className="to-hide-print">
              <td>
                <div>
                  <table>
                    <tbody>
                      <tr height="70px">
                        <td width="10%">
                          <img
                            alt="logo"
                            src={Logo}
                            height="66px"
                            width="66px"
                          />
                        </td>
                        <td width="39%">
                          <h1 id="web-name">TAM NGUYÊN HUYỀN KHÔNG.</h1>
                          <h2 id="slogan-web">
                            Huyền Không Đại Quái Trạch Nhật
                          </h2>
                        </td>
                        <td>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <img
                                    src={CHUONGTRINHGIANGDAY01}
                                    alt="Học viện phong thủy"
                                    width="45px"
                                    height="56px"
                                  />
                                </td>
                                <td>
                                  <img
                                    src={CHUONGTRINHGIANGDAY02}
                                    alt="Học viện tam hợp"
                                    width="45px"
                                    height="56px"
                                  />
                                </td>
                                <td>
                                  <img
                                    src={CHUONGTRINHGIANGDAY03}
                                    alt="Học viện lục pháp"
                                    width="45px"
                                    height="56px"
                                  />
                                </td>
                                <td>
                                  <img
                                    src={CHUONGTRINHGIANGDAY04}
                                    alt="Học viện đại quái"
                                    width="45px"
                                    height="56px"
                                  />
                                </td>
                                <td>
                                  <img
                                    src={CHUONGTRINHGIANGDAY05}
                                    alt="Học viện phi tinh"
                                    width="45px"
                                    height="56px"
                                  />
                                </td>
                                <td>
                                  <img
                                    src={CHUONGTRINHGIANGDAY06}
                                    alt="Học viện nhân tướng"
                                    width="45px"
                                    height="56px"
                                  />
                                </td>
                                <td>
                                  <img
                                    src={CHUONGTRINHGIANGDAY07}
                                    alt="Học viện kinh dịch"
                                    width="45px"
                                    height="56px"
                                  />
                                </td>
                                <td>
                                  <img
                                    src={CHUONGTRINHGIANGDAY08}
                                    alt="Học viện chiêm tinh"
                                    width="45px"
                                    height="56px"
                                  />
                                </td>
                                <td>
                                  <img
                                    src={CHUONGTRINHGIANGDAY09}
                                    alt="Học viện kỳ môn"
                                    width="45px"
                                    height="56px"
                                  />
                                </td>
                                <td>
                                  <img
                                    src={CHUONGTRINHGIANGDAY10}
                                    alt="Học viện trạch nhật"
                                    width="45px"
                                    height="56px"
                                  />
                                </td>
                                <td>
                                  <img
                                    src={CHUONGTRINHGIANGDAY11}
                                    alt="Học viện bát tự"
                                    width="45px"
                                    height="56px"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td>
                          <div
                            style={{
                              float: "right",
                              flexDirection: "row",
                              display: "flex",
                            }}
                          >
                            <Modal
                              trigger={
                                <Button
                                  style={{
                                    padding: "5px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <p style={{ lineBreak: "unset" }}>
                                    Thông tin Website
                                  </p>
                                </Button>
                              }
                            >
                              <Modal.Content>
                                <div>
                                  <Image size="massive" src={thongTinKhoaHoc} />
                                </div>
                              </Modal.Content>
                            </Modal>
                            <Button icon onClick={() => window.print()}>
                              <i className="fa fa-print" aria-hidden="true"></i>
                            </Button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="4">
                          <div style={{ display: "flex" }}>
                            {!this.props.isAutoSearch && (
                              <Button
                                onClick={() => {
                                  const getObj = localStorage.getItem(mdfive);
                                  const dataSavedStorage = JSON.parse(getObj);
                                  if (dataSavedStorage?.activeSearch) {
                                    this.props.history.push("/SearchPage"); //
                                  } else {
                                    this.setState({
                                      modalPressSearchCode: true,
                                    });
                                  }
                                }}
                                style={{
                                  marginRight: "auto",
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                              >
                                TÌM KIẾM TỰ ĐỘNG
                              </Button>
                            )}
                            {globalUtils.isLogin ? (
                              <div
                                style={{
                                  display: "flex",
                                  marginLeft: "auto",
                                  flexDirection: "column",
                                }}
                              >
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "120%",
                                    marginTop: "5px",
                                    marginRight: "30px",
                                  }}
                                >
                                  {this.state.expireDate
                                    ? `Tài khoản của bạn sẽ hết hạn vào Ngày ${this.state.expireDate?.getDate()}
Tháng ${
                                        this.state.expireDate?.getMonth() + 1
                                      } Năm ${this.state.expireDate?.getFullYear()}`
                                    : "Tài khoản của bạn đã hết hạn"}
                                </p>
                                <div>
                                  <Button.Group color="blue">
                                    <Button>{` Xin chào, ${this.state.userName}`}</Button>
                                    <Dropdown
                                      style={{ height: "100%" }}
                                      className="button icon"
                                      trigger={<></>}
                                    >
                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() =>
                                            this.setState({
                                              modalPressCode: true,
                                            })
                                          }
                                        >
                                          Nhập code
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <GoogleLogout
                                            clientId="135312632635-uml0mrhqpl0jr9kfpct57clk1j1u1imj.apps.googleusercontent.com"
                                            buttonText="Logout"
                                            onLogoutSuccess={this.logout}
                                            render={(renderProps) => (
                                              <p
                                                style={{
                                                  width: "100px",
                                                  marginLeft: "0px",
                                                }}
                                                onClick={this.logout}
                                                disabled={renderProps.disabled}
                                              >
                                                Đăng Xuất
                                              </p>
                                            )}
                                          ></GoogleLogout>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </Button.Group>
                                </div>
                              </div>
                            ) : (
                              <div style={{ marginLeft: "auto" }}>
                                <div id="signInDiv"></div>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(Header);
