import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Button, Modal, Input } from 'semantic-ui-react';
import '../App.css';
import Const from '../Const';
import { convertHour } from '../convert';
import SearchTest from './SearchTest';
export default function HeaderHomePage(props) {
  const [modalSearch, setModalSearch] = useState(false);
  const hour = convertHour(props.date.getHours()).value;

  return (
      <>
        <div id="header" style={{marginBottom: 10}}>

          <div className="flex-direction-row" style={{padding: 10}}>
            <Input value={props.name} style={{width: 400}} size="large" onChange={(event) => props.changeName(event.target.value)} label='Họ tên' placeholder='Nhập họ tên' />
          </div>
        </div>
        <div id="header">
          <div className="flex-direction-row">
            <button id="header" onClick={props.clickYesterday}>
              Hôm qua
            </button>
            <button id="header" onClick={props.clickToday}>
              Hôm nay
            </button>
            <button id="header" onClick={props.clickTomorrow}>
              Ngày mai
            </button>
            <div style={{ marginLeft: '12px', marginRight: '12px' }}>
              <SearchTest ref={props.searchRef} datasearch={props.keyWordSearch} result={(keyword) => props.searchResult(keyword)} />
            </div>
            <button
              style={{ marginLeft: '12px', marginRight: '12px' }}
              disabled={!props.isCustomer}
              id="header"
              onClick={() => {
                setModalSearch(!modalSearch);
              }}
            >
              Tìm kiếm
            </button>
            <ReactDatePicker
              style={{ marginLeft: '12px', marginRight: '12px' }}
              disabled={!props.isCustomer}
              locale="vi"
              className="input-date-picker"
              selected={props.date}
              dateFormat="dd - MM - yyyy"
              showYearDropdown
              dateFormatCalendar="MMMM"
              yearDropdownItemNumber={15}
              scrollableYearDropdown
              onChange={props.onChangeDate}
            />
            <select
              style={{ marginLeft: '12px', marginRight: '12px' }}
              value={`${hour || 0}`}
              id="Time"
              onChange={props.changeHour}
              style={{
                background: 'white',
                height: '29px',
              }}
            >
              {Const.timeSelection.map((v) => {
                return (
                  <option key={`timeSelection-${v.time}`} value={v.value}>
                    {v.time}
                  </option>
                );
              })}
            </select>
            <Modal size={'small'} open={modalSearch}>
              {props.searchParam ? (
                <>
                  <Modal.Header>
                    <div style={{ flexDirection: 'row' }}>
                      <h1>{`Kết quả tìm kiếm cho từ khóa "${props.searchParam?.value}"`}</h1>
                      <select
                        value={props.searchSelectMonth}
                        id="SearchMonth"
                        onChange={props.onSelectPossibleMonth}
                        style={{
                          background: 'white',
                        }}
                      >
                        {props.monthInYear.map((v, i) => {
                          return <option key={`monthInYear-${v + i}`} value={v}>{`Tháng ${v + 1}`}</option>;
                        })}
                      </select>
                      <select
                        value={props.searchSelectYear}
                        id="SearchYear"
                        onChange={props.onSelectPossibleYear}
                        style={{
                          background: 'white',
                        }}
                      >
                        {props.possibleYearArr.map((v) => {
                          return (
                            <option key={`possibleYear-${v}`} value={v}>
                              {v}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </Modal.Header>
                  <Modal.Content scrolling>
                    <h1>{`Kết quả tìm kiếm cho từ khóa `}</h1>

                    <table width="100%" height="100%" className="searchModal">
                      <tbody>
                        <tr className="cellSearchModal">
                          <td className="cellSearchModal" width="80%">
                            <h2>Nội dung Nên làm trong ngày</h2>
                          </td>
                          <td className="cellSearchModal">
                            <h2>Ngày</h2>
                          </td>
                        </tr>
                        {props.resultSearchKeyword.map((v, i) => {
                          const dateResult = new Date(v.dateResult);

                          return (
                            <tr key={`searchResult-${i}`} className="cellSearchModal">
                              <td className="cellSearchModal">{v.content}</td>
                              <td className="cellSearchModal">
                                <Button
                                  color="blue"
                                  fluid
                                  onClick={() => {
                                    props.handleChangeSearch(dateResult);
                                    setModalSearch(!modalSearch);
                                  }}
                                >
                                  {`${dateResult.getDate()}/${dateResult.getMonth() + 1}/${dateResult?.getFullYear()}`}
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button negative onClick={() => setModalSearch(!modalSearch)}>
                      Đóng
                    </Button>
                  </Modal.Actions>
                </>
              ) : (
                <>
                  <Modal.Content>
                    <h1>Lưu ý</h1>
                    <p>Xin vui lòng nhập từ khóa trước khi Tìm Kiếm</p>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button negative onClick={() => setModalSearch(!modalSearch)}>
                      Đóng
                    </Button>
                  </Modal.Actions>
                </>
              )}
            </Modal>
          </div>
        </div>
      </>
  );
}
