import * as firebase from "firebase";

function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const SaveData = (newData, onSuccess) => {
    let database = firebase.app("database").firestore();
    let data = database.collection("Data_HuyenKhong");
    data.get().then((doc) => {

        const id = makeid(8)
        const newObj = {
            id,
            hour: newData[0],
            day: newData[1],
            month: newData[2],
            year: newData[3],
            toa: newData[4],
            namSinh: newData[5],
            name: newData.name,
            hoTen: newData.hoTen,
            userName: newData.userName,
            email: newData.email,
            namSinhNumber: newData.namSinhNumber,
            namSinhReal: newData.namSinhReal,
            toaSinhReal: newData.toaSinhReal,
            toaNumber: newData.toaNumber,
            date: newData.date,
            note: newData.note
        }
        database.collection("Data_HuyenKhong").doc(id).set(newObj).then(() => {
            onSuccess(true)
        }).catch((err) => {
            onSuccess(false)
        });

    })
}

export const getData = async (email) => {
    const markers = [];
    let database = firebase.app("database").firestore();

    await database.collection('Data_HuyenKhong')
        .get()
        .then(querySnapshot => {
            querySnapshot.docs.forEach(doc => {
                if (doc.data().email === email) {
                    let newDate
                    newDate = new Date(doc.data().date.seconds * 1000 + doc.data().date.nanoseconds / 1000000)
                    let newObj = {
                        ...doc.data(),
                        newDate
                    }
                    markers.push(newObj);
                }
            });

        });
    return markers
}

export const deleteDataSave = async (id, onSuccess) => {
    let db = firebase.app("database").firestore();
    let query = db.collection('Data_HuyenKhong').where('id', '==', id);
    query.get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
            doc.ref.delete();
        });
    });
    onSuccess(true)
}

