import React from 'react';
import '../App.css';
import Const from '../Const';
import { getPhiTinhMainNumber, phiTinhNam, phiTinhThang } from '../convert';

class PhiTinh extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      chiYear: this.props.chiYear,
      chiHour: this.props.chiHour,
      sauDongChi: true,
    };
  }
  renderTableInCuuCungPhiTinh(mainNumber, subNumber, rightBottomNumber) {
    const showMainNumber = mainNumber === 10 ? 1 : mainNumber > 10 ? mainNumber - 9 : mainNumber;
    const showSubNumber = subNumber === 10 ? 1 : subNumber > 10 ? subNumber - 9 : subNumber;
    const convertSaoNgay = rightBottomNumber === 10 ? 1 : rightBottomNumber > 10 ? rightBottomNumber - 9 : rightBottomNumber;

    return (
      <table height="100%">
        <tbody>
          {this.props.isSmall ? null : (
            <tr height="33%" style={{}}>
              <td
                width="30%"
                style={{
                  fontSize: this.props.isSmall ? '40%' : '10px',
                  width: '13px',
                  lineHeight: '1px',
                  color: showSubNumber === 1 || showSubNumber === 8 || showSubNumber === 6 || showSubNumber === 9 ? 'red' : null,
                }}
              >
                {showSubNumber}
              </td>
              <td width="30%" style={{ fontSize: this.props.isSmall ? '40%' : '40%', width: '13px' }}></td>
              <td width="30%" style={{ fontSize: this.props.isSmall ? '70%' : '40%', width: '13px' }}></td>
            </tr>
          )}

          <tr height={'33%'} style={{ borderRadius: '0px' }}>
            {this.props.isSmall ? null : <td width="30%" style={{ fontSize: this.props.isSmall ? '40%' : '40%', width: '13px' }}></td>}

            <td
              width={'30%'}
              style={{
                fontWeight: 'bold',
                fontSize: this.props.isSmall ? '12px' : '18px',
                width: '13px',

                color: showMainNumber === 1 || showMainNumber === 8 || showMainNumber === 6 || showMainNumber === 9 ? 'red' : null,
              }}
            >
              {showMainNumber ? showMainNumber : ''}
            </td>
            {this.props.isSmall ? null : <td width="30%" style={{ fontSize: this.props.isSmall ? '40%' : '40%', width: '13px' }}></td>}
          </tr>
          {this.props.isSmall ? null : (
            <tr height="33%" style={{}}>
              <td width="30%" style={{ fontSize: this.props.isSmall ? '40%' : '40%', width: '13px' }}></td>
              <td width="30%" style={{ fontSize: this.props.isSmall ? '40%' : '40%', width: '13px' }}></td>
              <td
                width="30%"
                style={{
                  fontSize: this.props.isSmall ? '40%' : '10px',
                  width: '13px',

                  lineHeight: '1px',
                }}
              >
                {convertSaoNgay ? convertSaoNgay : ''}
                {/* {rightBottomNumber} */}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  render() {
    let saoThang = phiTinhThang(this.props.dateLunar ? this.props.dateLunar : '');

    if (this.props.dateLunar?.getMonth() === 0) {
      let tempDate = new Date(this.props.dateLunar?.getFullYear() + 1, this.props.dateLunar?.getMonth(), this.props.dateLunar?.getDate());
      saoThang = phiTinhThang(tempDate);
    }
    const getChiDay = Const.ChiArr.find((v) => v.name === this.props.chiDay);
    const saoNam = phiTinhNam(this.props.dateLunar);
    const saoNgay = this.props?.starADay;
    const height = this.props.isSmall ? '80px' : '170px';
    const midleNumber = this.props.isSmall ? getPhiTinhMainNumber(getChiDay?.code, this.props.chiHour, this.props.isSauDongChi) : saoThang;
    return (
      <table
        width={height}
        height={height}
        id="borderTable"
        style={{
          backgroundColor: '#fff',
          // marginLeft: "50px",
          // marginRight: "50px",
          // alignSelf: "center",
        }}
        // style={{ margin: "20px" }}
      >
        <tbody>
          <tr height="33%" style={{}} id="borderTable">
            <td width="33%" style={{}} id="borderTable">
              {this.renderTableInCuuCungPhiTinh(midleNumber + 8, saoNam + 8, saoNgay + 8)}
            </td>
            <td width="33%" style={{}} id="borderTable">
              {this.renderTableInCuuCungPhiTinh(midleNumber + 4, saoNam + 4, saoNgay + 4)}
            </td>
            <td width="33%" style={{}} id="borderTable">
              {this.renderTableInCuuCungPhiTinh(midleNumber + 6, saoNam + 6, saoNgay + 6)}
            </td>
          </tr>
          <tr height="33%" style={{}} id="borderTable">
            <td width="33%" style={{}} id="borderTable">
              {this.renderTableInCuuCungPhiTinh(midleNumber + 7, saoNam + 7, saoNgay + 7)}
            </td>
            <td width="33%" style={{}} id="borderTable">
              {this.renderTableInCuuCungPhiTinh(midleNumber, saoNam, saoNgay)}
            </td>
            <td width="33%" style={{}} id="borderTable">
              {this.renderTableInCuuCungPhiTinh(midleNumber + 2, saoNam + 2, saoNgay + 2)}
            </td>
          </tr>
          <tr height="33%" style={{}} id="borderTable">
            <td width="33%" style={{}} id="borderTable">
              {this.renderTableInCuuCungPhiTinh(midleNumber + 3, saoNam + 3, saoNgay + 3)}
            </td>
            <td width="33%" style={{}} id="borderTable">
              {this.renderTableInCuuCungPhiTinh(midleNumber + 5, saoNam + 5, saoNgay + 5)}
            </td>
            <td width="33%" style={{}} id="borderTable">
              {this.renderTableInCuuCungPhiTinh(midleNumber + 1, saoNam + 1, saoNgay + 1)}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default PhiTinh;
