import _ from "lodash";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../App.css";
import HeaderNotLogin from "../components/HeaderNotLogin";
import Logo from "../logo.png";
import MasterPhuong from "../image/Master-Phuong.png";
import battrach from "../image/iconDichVu/battrach.png";
import battu from "../image/iconDichVu/battu.png";
import honnhan from "../image/iconDichVu/honnhan.png";
import huyenkhong from "../image/iconDichVu/huyenkhong.png";
import thuoclapcuc from "../image/iconDichVu/thuoclapcuc.png";
import thuocloban from "../image/iconDichVu/thuocloban.png";
import kinhdichWeb from "../image/iconDichVu/kinhdich_web.png";
import trachnhat from "../image/iconDichVu/trachnhat.png";
import kinhDich from "../image/iconDichVu/kinhdich.png";
import battrachapp from "../image/iconDichVu/battrachapp.png";
import huyenkhongapp from "../image/iconDichVu/huyenkhong2.png";
import tamhop from "../image/iconDichVu/labantamnguytamhop.png";
import KMDGApp from "../image/iconDichVu/KMDG.png";
import annhanthuy from "../image/iconDichVu/vatphamphongthuy_annhanthuy.png";
import chuonggio from "../image/iconDichVu/vatphamphongthuy_chuonggio.png";
import dongxuco from "../image/iconDichVu/vatphamphongthuy_dongxuco.png";
import guongbatquai from "../image/iconDichVu/vatphamphongthuy_guongbatquai.png";
import laban from "../image/iconDichVu/vatphamphongthuy_laban.png";
import labanmini from "../image/iconDichVu/vatphamphongthuy_labanmini.png";
import tuixong from "../image/iconDichVu/vatphamphongthuy_tuixong.png";
import vongtyhuu from "../image/iconDichVu/vatphamphongthuy_vongtyhuu.png";
import lechcua from "../image/iconDichVu/lechcua.png";
import kymon64quai from "../image/iconDichVu/kymon64quai.jpg";
import kyMon from "../image/iconDichVu/KyMon.png";
import battuPro from "../image/iconDichVu/battuPro.png";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <HeaderNotLogin></HeaderNotLogin>
        <section style={{ width: "100%", background: "#BF0505" }}>
          <h1
            style={{
              textAlign: "center",
              fontSize: "30px",
              marginBottom: "0px",
              color: "white",
            }}
          >
            Chân Truyền - Dương Công - Vô Thường - Giang Tây - Mao Sơn Phái
          </h1>
        </section>
        <section style={{ width: "100%", marginTop: "10px" }}>
          {this.renderHeaderContent("CÔNG CỤ WEBSITE")}
          <div style={{ textAlign: "center" }}>
            <div style={{ width: "1250px", margin: "auto", display: "flex" }}>
              {this.renderItem(
                battrach,
                "Bát trạch",
                "http://congcu.phongthuytuongminh.com/phong-thuy/cong-cu/cong-cu-bat-trach/"
              )}
              {this.renderItem(
                huyenkhong,
                "HK Phi Tinh",
                "https://thuoclapcuc.huyenkhongtamnguyen.com/#/phi-tinh"
              )}
              {/* {this.renderItem(battu, 'Bát Tự', 'http://congcu.phongthuytuongminh.com/bat-tu-va-tuong-phap/cong-cu/phan-tich-bat-tu/')} */}
              {this.renderItem(
                battuPro,
                "Bát Tự Pro",
                "https://battututru.huyenkhongtamnguyen.com/"
              )}
              {this.renderItem(
                thuocloban,
                "Thước Lỗ Ban",
                "https://play.google.com/store/books/details/Nguy%E1%BB%85n_Th%C3%A0nh_Ph%C6%B0%C6%A1ng_L%E1%BB%97_Ban_Kinh?id=jXWKEAAAQBAJ"
              )}
              {this.renderItem(
                kinhdichWeb,
                "Kinh Dịch",
                "https://kinhdich.huyenkhongtamnguyen.com"
              )}
            </div>
            <div style={{ width: "1500px", margin: "auto", display: "flex" }}>
              {this.renderItem(
                kyMon,
                "Kỳ Môn Độn Giáp",
                "https://huyenkhong-34210.web.app"
              )}
              {this.renderItem(
                thuoclapcuc,
                "Thước Lập Cực",
                "https://thuoclapcuc.huyenkhongtamnguyen.com/#/ban-do"
              )}

              {this.renderItem(
                trachnhat,
                "Trạch Nhật (HKĐQ-KMĐG)",
                "https://huyenkhongtamnguyen.com/TrachNhat"
              )}

              {this.renderItem(
                honnhan,
                "Hôn Nhân",
                "http://honnhan.huyenkhongtamnguyen.com/"
              )}

              {this.renderItem(
                lechcua,
                "Tính Toán Độ Lệch Cửa",
                "https://door-fengshui.firebaseapp.com/#/"
              )}
              {this.renderItem(
                kymon64quai,
                "Kỳ Môn 64 Quái",
                "https://hktn-ad323.web.app/"
              )}
              {/* https://hktn-ad323.web.app/ */}
            </div>
          </div>
        </section>
        <section style={{ width: "100%", marginBottom: "20px" }}>
          {this.renderHeaderContent("ỨNG DỤNG \n ANDROID - IOS")}

          <div style={{ textAlign: "center" }}>
            <div style={{ width: "1000px", margin: "auto", display: "flex" }}>
              {this.renderItem(
                kinhDich,
                "Kinh Dịch",
                "https://play.google.com/store/apps/details?id=com.hitek.iching"
              )}
              {this.renderItem(
                battrachapp,
                "Bát Trạch",
                "https://play.google.com/store/apps/details?id=com.demo.compass.battrach"
              )}
              {this.renderItem(
                huyenkhongapp,
                "HK Phi Tinh",
                "https://play.google.com/store/apps/details?id=com.demo.compass.phongthuy"
              )}
              {this.renderItem(
                tamhop,
                "Tam Hợp",
                "https://play.google.com/store/apps/details?id=com.hitek.ultimatecompass"
              )}
            </div>
            <div style={{ width: "250px", margin: "auto", display: "flex" }}>
              <a
                onClick={() => {
                  alert(
                    `Vui lòng liên hệ Tường Minh Feng Shui để biết thêm chi tiết`
                  );
                }}
              >
                {this.renderItem(KMDGApp, "KM Độn Giáp")}
              </a>
            </div>
          </div>
        </section>
        <section style={{ width: "100%", marginBottom: "20px" }}>
          {this.renderHeaderContent(`Sách - EBOOK`)}

          <p
            style={{
              paddingTop: "20px",
              paddingBottom: "20px",
              textAlign: "center",
              fontSize: "25px",
              whiteSpace: "pre-wrap",
              background: "#8080806b",
              lineHeight: "unset",
            }}
          >
            {`Hiện nay, Phong Thủy Tường Minh đã phát triển trên 20 đầu sách miễn phí và tính phí \n
thông qua thư viện sách Ebook của Google Play:\n`}
            <a href="https://play.google.com/store/books/author?id=Nguy%E1%BB%85n+Th%C3%A0nh+Ph%C6%B0%C6%A1ng">
              https://play.google.com/store/books/author?id=Nguyễn+Thành+Phương
            </a>
          </p>
        </section>
        <section style={{ width: "100%", marginBottom: "20px" }}>
          {this.renderHeaderContent("VẬT PHẨM PHONG THỦY")}
          <div style={{ textAlign: "center" }}>
            <div style={{ width: "1000px", margin: "auto", display: "flex" }}>
              {this.renderItem(
                chuonggio,
                "Chuông Gió",
                "http://vatphamdailoan.com/chuong-gio-dong-phong-thuy.html"
              )}
              {this.renderItem(
                annhanthuy,
                "An Nhẫn Thủy",
                "http://vatphamdailoan.com/hu-muoi-an-nhan-thuy-hoa-giai-ngu-hoang-dai-sat.html"
              )}
              {this.renderItem(
                laban,
                "La Bàn",
                "http://vatphamdailoan.com/la-ban-phong-thuy.html"
              )}
              {this.renderItem(
                dongxuco,
                "Đồng Xu Cỗ",
                "http://vatphamdailoan.com/chuoi-dong-xu-tai-loc.html"
              )}
            </div>
            <div style={{ width: "1000px", margin: "auto", display: "flex" }}>
              {this.renderItem(
                vongtyhuu,
                "Vòng Tỳ Hưu",
                "http://vatphamdailoan.com/vong-tay-ty-huou-da-thach-anh-hong.html"
              )}
              {this.renderItem(
                labanmini,
                "La Bàn Mini",
                "http://vatphamdailoan.com/la-ban-dai-loan-chat-luong-tot-nhat-the-gioi.html"
              )}
              {this.renderItem(
                guongbatquai,
                "Gương Bát Quái",
                "http://vatphamdailoan.com/guong-bat-quai-trong-phong-thuy.html"
              )}
              {this.renderItem(
                tuixong,
                "Túi Xông",
                "http://vatphamdailoan.com/tui-thao-moc-xong-phong-long.html"
              )}
            </div>
            <p
              style={{
                textAlign: "center",
                fontSize: "25px",
                whiteSpace: "pre-wrap",
                lineHeight: "unset",
              }}
            >
              {`Xem nhiều vật phẩm hơn trên trang web:\n`}
              <a href="http://www.vatphamdailoan.com/">{`www.vatphamdailoan.com\n`}</a>
              <a href="https://www.facebook.com/phongthuytuongminh">{`www.facebook.com/phongthuytuongminh`}</a>
            </p>
          </div>
        </section>
        <section
          style={{
            width: "100%",
            background: "#0000002e",
            marginBottom: "20px",
          }}
        >
          {this.renderHeaderContent("DỊCH VỤ PHONG THỦY")}
          <div style={{ textAlign: "center", width: "1000px", margin: "auto" }}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "500px", height: "auto" }}>
                <div>
                  <p style={{ textAlign: "left" }}>
                    MASTER <h1 style={{ color: "red" }}>NGUYỄN THÀNH PHƯƠNG</h1>{" "}
                    <sub>{`(CEO of Tuong Minh Feng Shui)`}</sub>
                  </p>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <p style={{ textAlign: "left", whiteSpace: "pre-wrap" }}>
                    {`Giảng dạy Phong Thủy Huyền Không / Mệnh Lý Học

Thiết kế Phong Thủy cho dự án Bất Động Sản

Tư vấn lựa chọn Bất Động Sản (Mua/ Thuê/ Đầu tư)
Tư vấn Phong Thủy Nhà Ở (Thiết kế/ Xây dựng)
Tư vấn Phong Thủy Âm Trạch
Tư vấn Phong Thủy Văn Phòng / Trụ Sở Công Ty / Nhà Máy 
Tư vấn Phong Thủy cho Cửa Hàng / Cơ Sở Thương Mại
Tư vấn Đặt Tên / Logo cho Công Ty
Tư vấn Chọn Ngày Khai Trương, Đàm Phán Hợp Đồng
Tư vấn Chọn Ngày Cá Nhân (Cưới Hỏi / Sinh Con…)

Phân tích Mệnh Lý cho Tuyển Dụng Nhân Sự

Thuyết trình về Phong Thủy
`}
                  </p>
                </div>
              </div>
              <div style={{ width: "500px", height: "auto" }}>
                <img alt="logo" src={MasterPhuong} height="448px" />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  renderHeaderContent(title) {
    return (
      <div style={{ marginBottom: "20px" }}>
        <h1
          style={{
            textAlign: "center",
            marginBottom: "0px",
            whiteSpace: "pre-wrap",
          }}
        >
          {title}
        </h1>
        <div
          style={{
            height: "4px",
            width: "300px",
            background: "black",
            margin: "auto",
          }}
        ></div>
      </div>
    );
  }

  renderItem(image, text, link) {
    return (
      <a href={link}>
        <div style={{ margin: "auto", width: "250px", height: "200px" }}>
          <img
            alt="logo"
            src={image}
            height={text === "Bát Tự Pro" ? "80px" : "108px"}
            width={text === "Bát Tự Pro" ? "80px" : "108px"}
          />
          <div style={{ marginTop: text === "Bát Tự Pro" ? "15px" : null }}>
            <span
              style={{
                margin: "auto",
                background: "#BF0505",
                color: "white",
                minWidth: "150px",
                borderRadius: "10px",
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingTop: "3px",
                paddingBottom: "3px",
              }}
            >
              {text}
            </span>
          </div>
        </div>
      </a>
    );
  }
}

export default HomePage;
