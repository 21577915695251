import vi from 'date-fns/locale/vi';
import * as firebase from 'firebase';
import md5 from 'md5';
import React from 'react';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Accordion, Button, Icon, Image, List, Modal, Radio, Select } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import Logo from '../logo.png';
import * as XLSX from 'xlsx';
import '../App.css';
import html2canvas from 'html2canvas';

import background from '../logo.png';

import PhiTinhDetail from '../components/PhiTinhDetail';
import emailjs from 'emailjs-com';

import Const from '../Const';
import globalUtils from '../globalUntils.js';
import moment from 'moment';
import { convertHour, DayToCanChi, getPhiTinhMainNumber, HourToCanChi, MonthToCanChi, YearToCan, YearToChi } from '../convert';
import axios from 'axios';
import { Collapse } from 'react-collapse';

const mdfive = md5('HuyenKhongTamNguyenFengShuiWebSiteHitekTuongMinh');
registerLocale('vi', vi);
const SelectionType = { ThienBan: 1, DiaBan: 2, CuuTinh: 3, BatMon: 4, BatThan: 5, PhiTinh: 6, Huong: 7, DichMa: 8 };
class SearchKyMonDonGiap extends React.PureComponent {
  constructor(props) {
    super(props);
    this.tietKhiDate = [];
    this.state = {
      errorServer: false,
      hourSelectedWaterFall: null,
      thacNuocSelected: null,
      modalMissingThacNuoc: false,
      activeResultState: -1,
      modalSentThapNuocSuccess: false,
      searchBy: 'SearchHour',
      modalSentSuccess: false,
      modalDetailDay: false,
      resultSearchDate: [],
      modalNoResult: false,
      fromDate: null,
      toDate: null,
      modalEmptySelectedHuong: false,
      modalEmptySelectedDateFromTo: false,
      isLoading: false,
      isBusiness: false,
      filterOption: [
        { key: 1, selected: -1, text: 'Thiên bàn', option: [{ code: -1, name: 'Không Chọn', hanViet: 'Không Chọn' }, ...Const.THIENBAN] },
        { key: 2, selected: -1, text: 'Địa Bàn', option: [{ code: -1, name: 'Không Chọn', hanViet: 'Không Chọn' }, ...Const.DIABAN] },
        { key: 3, selected: -1, text: 'Cửu Tinh', option: [{ code: -1, name: 'Không Chọn', hanViet: 'Không Chọn' }, ...Const.cuuTinh] },
        { key: 4, selected: -1, text: 'Bát Môn', option: [{ code: -1, name: 'Không Chọn', hanViet: 'Không Chọn' }, ...Const.batMon] },
        { key: 5, selected: -1, text: 'Bát Thần', option: [{ code: -1, name: 'Không Chọn', hanViet: 'Không Chọn' }, ...Const.batThan] },
        {
          key: 6,
          selected: -1,
          text: 'Phi Tinh',
          option: [
            { code: -1, name: 'Không Chọn' },
            { code: 0, name: '1' },
            { code: 1, name: '2' },
            { code: 2, name: '3' },
            { code: 3, name: '4' },
            { code: 4, name: '5' },
            { code: 5, name: '6' },
            { code: 6, name: '7' },
            { code: 7, name: '8' },
            { code: 8, name: '9' },
          ],
        },
        {
          key: 7,
          selected: -1,
          text: 'Hướng',
          option: [
            { code: -1, name: 'Không Chọn' },
            { code: 1, name: 'Nam' },
            { code: 2, name: 'Tây Nam' },
            { code: 3, name: 'Tây' },
            { code: 4, name: 'Tây Bắc' },
            { code: 5, name: 'Bắc' },
            { code: 6, name: 'Đông Bắc' },
            { code: 7, name: 'Đông' },
            { code: 8, name: 'Đông Nam' },
          ],
        },
        {
          key: 8,
          selected: 0,
          text: 'Dịch Mã',
          option: [
            { code: 0, name: 'Không' },
            { code: 1, name: 'Có' },
          ],
        },
      ],
    };
    this.excelLichVanNien = this.props.lichVanNien;
    this.kyMonDonGiapNgay = this.props.kyMonDonGiapNgay;
    this.dataAmDon = this.props.dataAmDon;
    this.dataDuongDon = this.props.dataDuongDon;
    this.onChangeItemWaterFall = this.onChangeItemWaterFall.bind(this);
  }
  async checkDichMa(dichMa) {
    // nếu dịch mã chọn có thì show alert đồng thời disable các hướng chính đông tây nam bắc

    if (dichMa.selected === 1) {
      alert('Dịch mã chỉ có trong trường hợp: Đông Bắc, Đông Nam, Tây Bắc, Tây Nam');
      return this.state.filterOption.map((v) => {
        if (v.key === 7) {
          return {
            ...v,
            selected: -1,
            option: v.option.map((e) => {
              if (e.code === 1 || e.code === 3 || e.code === 5 || e.code === 7) {
                return { ...e, disable: true };
              }
              return { ...e };
            }),
          };
        } else {
          return { ...v };
        }
      });
    } else {
      return this.state.filterOption.map((v) => {
        if (v.key === 7) {
          return {
            ...v,
            option: v.option.map((e) => {
              if (e.code === 2 || e.code === 4 || e.code === 6 || e.code === 8) {
                return { ...e, disable: false };
              }
              return { ...e };
            }),
          };
        } else {
          return { ...v };
        }
      });
    }

    //  return newSelection[8]
    // this.setState({ filterOption: newSelection });
  }
  disableMainDecoor() {
    return this.state.filterOption.map((v) => {
      if (v.key === 7) {
        return {
          ...v,
          // selected: -1,
          option: v.option.map((e) => {
            if (e.code === 1 || e.code === 3 || e.code === 5 || e.code === 7) {
              return { ...e, disable: true };
            }
            return { ...e };
          }),
        };
      } else {
        return { ...v };
      }
    });
  }
  enableMainDecoor() {
    return this.state.filterOption.map((v) => {
      if (v.key === 7) {
        return {
          ...v,
          // selected: -1,
          option: v.option.map((e) => {
            if (e.code === 1 || e.code === 3 || e.code === 5 || e.code === 7) {
              return { ...e, disable: false };
            }
            return { ...e };
          }),
        };
      } else {
        return { ...v };
      }
    });
  }
  nonSelectDecoor() {
    return this.state.filterOption.map((v) => {
      if (v.key === 7) {
        return {
          ...v,
          selected: -1,
          option: v.option.map((e) => {
            if (e.code === 1 || e.code === 3 || e.code === 5 || e.code === 7) {
              return { ...e, disable: true };
            }
            return { ...e };
          }),
        };
      } else {
        return { ...v };
      }
    });
  }
  async onChangeSelected(key, value) {
    let newSelection = [];

    newSelection = this.state.filterOption.map((v, i) => {
      if (v.key === key) {
        v.selected = Number(value);
      }
      return v;
    });

    // nếu v.key === 8 và numver value === 1 thì disable mấy thằng đông tây nam bắc đi
    if (newSelection[7] && newSelection[7].selected === 1) {
      newSelection = this.disableMainDecoor();
      // nếu hướng đang chọn là đông taay nam bắc thì cho về -1
      if (
        newSelection[6] &&
        (newSelection[6].selected === 1 || newSelection[6].selected === 3 || newSelection[6].selected === 5 || newSelection[6].selected === 7)
      ) {
        alert('Trường hợp dịch mã chỉ xảy ra khi hướng là Đông Nam, Đông Bắc, Tây Nam, Tây Bắc. Vui lòng chọn lại hướng.');
        newSelection = this.nonSelectDecoor();
      }
    } else {
      newSelection = this.enableMainDecoor();
    }

    this.setState({ filterOption: newSelection });
  }
  checkSelectHuong() {
    const huong = this.state.filterOption.find((v) => v.key === SelectionType.Huong);
    if (huong.selected !== -1) {
      return true;
    }
    return false;
  }
  checkSelectOneOfOther() {
    const ThienBan = this.state.filterOption.find((v) => v.key === SelectionType.ThienBan);
    const DiaBan = this.state.filterOption.find((v) => v.key === SelectionType.DiaBan);
    const CuuTinh = this.state.filterOption.find((v) => v.key === SelectionType.CuuTinh);
    const BatMon = this.state.filterOption.find((v) => v.key === SelectionType.BatMon);
    const BatThan = this.state.filterOption.find((v) => v.key === SelectionType.BatThan);
    const PhiTinh = this.state.filterOption.find((v) => v.key === SelectionType.PhiTinh);
    if (
      ThienBan.selected !== -1 ||
      DiaBan.selected !== -1 ||
      CuuTinh.selected !== -1 ||
      BatMon.selected !== -1 ||
      BatThan.selected !== -1 ||
      PhiTinh.selected !== -1
    ) {
      return true;
    }
    return false;
  }
  componentDidMount() {
    const emailSaveStorage = localStorage.getItem(mdfive);
    const userData = JSON.parse(emailSaveStorage);
    if (userData && userData.ListThacNuoc && userData?.ListThacNuoc.length > 0) {
      this.setState({ thacNuocSelected: userData.ListThacNuoc[0].code });
    }
  }
  getTietKhiAllYear(dateTimeYear) {
    const distance = dateTimeYear?.getFullYear() - 1932;
    const thangLe = distance * 42 + 36;
    const thangChan = distance * 42 + 38;
    let ngayGioDauThang1 = this.excelLichVanNien[thangLe][1].split(' - ')[0].split(' ');
    let ngayGioDauThang3 = this.excelLichVanNien[thangLe][5].split(' - ')[0].split(' ');
    let ngayGioDauThang5 = this.excelLichVanNien[thangLe][9].split(' - ')[0].split(' ');
    let ngayGioDauThang7 = this.excelLichVanNien[thangLe][13].split(' - ')[0].split(' ');
    let ngayGioDauThang9 = this.excelLichVanNien[thangLe][17].split(' - ')[0].split(' ');
    let ngayGioDauThang11 = this.excelLichVanNien[thangLe][21].split(' - ')[0].split(' ');
    let ngayGioDauThang2 = this.excelLichVanNien[thangChan][2].split(' - ')[0].split(' ');
    let ngayGioDauThang4 = this.excelLichVanNien[thangChan][6].split(' - ')[0].split(' ');
    let ngayGioDauThang6 = this.excelLichVanNien[thangChan][10].split(' - ')[0].split(' ');
    let ngayGioDauThang8 = this.excelLichVanNien[thangChan][14].split(' - ')[0].split(' ');
    let ngayGioDauThang10 = this.excelLichVanNien[thangChan][18].split(' - ')[0].split(' ');
    let ngayGioDauThang12 = this.excelLichVanNien[thangChan][22].split(' - ')[0].split(' ');

    const ngayDauThangMot = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang1[0].split('/')[1]) - 1,
      Number(ngayGioDauThang1[0].split('/')[0]),
      Number(ngayGioDauThang1[1].split(':')[0]),
      Number(ngayGioDauThang1[1].split(':')[1]),
    );
    const ngayDauThangHai = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang2[0].split('/')[1]) - 1,
      Number(ngayGioDauThang2[0].split('/')[0]),
      Number(ngayGioDauThang2[1].split(':')[0]),
      Number(ngayGioDauThang2[1].split(':')[1]),
    );
    const ngayDauThangBa = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang3[0].split('/')[1]) - 1,
      Number(ngayGioDauThang3[0].split('/')[0]),
      Number(ngayGioDauThang3[1].split(':')[0]),
      Number(ngayGioDauThang3[1].split(':')[1]),
    );
    const ngayDauThangTu = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang4[0].split('/')[1]) - 1,
      Number(ngayGioDauThang4[0].split('/')[0]),
      Number(ngayGioDauThang4[1].split(':')[0]),
      Number(ngayGioDauThang4[1].split(':')[1]),
    );
    const ngayDauThangNam = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang5[0].split('/')[1]) - 1,
      Number(ngayGioDauThang5[0].split('/')[0]),
      Number(ngayGioDauThang5[1].split(':')[0]),
      Number(ngayGioDauThang5[1].split(':')[1]),
    );
    const ngayDauThangSau = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang6[0].split('/')[1]) - 1,
      Number(ngayGioDauThang6[0].split('/')[0]),
      Number(ngayGioDauThang6[1].split(':')[0]),
      Number(ngayGioDauThang6[1].split(':')[1]),
    );
    const ngayDauThangBay = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang7[0].split('/')[1]) - 1,
      Number(ngayGioDauThang7[0].split('/')[0]),
      Number(ngayGioDauThang7[1].split(':')[0]),
      Number(ngayGioDauThang7[1].split(':')[1]),
    );
    const ngayDauThangTam = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang8[0].split('/')[1]) - 1,
      Number(ngayGioDauThang8[0].split('/')[0]),
      Number(ngayGioDauThang8[1].split(':')[0]),
      Number(ngayGioDauThang8[1].split(':')[1]),
    );
    const ngayDauThangChin = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang9[0].split('/')[1]) - 1,
      Number(ngayGioDauThang9[0].split('/')[0]),
      Number(ngayGioDauThang9[1].split(':')[0]),
      Number(ngayGioDauThang9[1].split(':')[1]),
    );
    const ngayDauThangMuoi = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang10[0].split('/')[1]) - 1,
      Number(ngayGioDauThang10[0].split('/')[0]),
      Number(ngayGioDauThang10[1].split(':')[0]),
      Number(ngayGioDauThang10[1].split(':')[1]),
    );
    const ngayDauThangMuoiMot = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang11[0].split('/')[1]) - 1,
      Number(ngayGioDauThang11[0].split('/')[0]),
      Number(ngayGioDauThang11[1].split(':')[0]),
      Number(ngayGioDauThang11[1].split(':')[1]),
    );
    const ngayDauThangMuoiHai = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang12[0].split('/')[1]) - 1,
      Number(ngayGioDauThang12[0].split('/')[0]),
      Number(ngayGioDauThang12[1].split(':')[0]),
      Number(ngayGioDauThang12[1].split(':')[1]),
    );
    let lichTietKhi = [];
    lichTietKhi.push(
      ngayDauThangMot,
      ngayDauThangHai,
      ngayDauThangBa,
      ngayDauThangTu,
      ngayDauThangNam,
      ngayDauThangSau,
      ngayDauThangBay,
      ngayDauThangTam,
      ngayDauThangChin,
      ngayDauThangMuoi,
      ngayDauThangMuoiMot,
      ngayDauThangMuoiHai,
    );
    this.tietKhiDate = lichTietKhi;
  }
  getCorrectTietKhi(solarDate) {
    var day = solarDate.getDate();
    var month = solarDate.getMonth();
    var year = solarDate < this.tietKhiDate[1] ? solarDate.getFullYear() - 1 : solarDate.getFullYear();

    switch (solarDate.getMonth()) {
      // tháng 1 dương lịch nếu sau ngày đầu tháng 1 thì là tháng 12 năm ngoái
      //input ngày 1 tháng 1 2020
      // out put ngày 1 tháng 12 2019
      case 0:
        if (solarDate < this.tietKhiDate[0]) {
          month = 11;
        }
        break;
      case 1:
        // trước ngày lập xuân thì là tháng 12 của năm ngoái, ngược lại là tháng 1 năm sau
        if (solarDate < this.tietKhiDate[1]) {
          month = month - 1;
        }
        break;
      case 2:
        if (solarDate < this.tietKhiDate[2]) {
          month = month - 1;
        }
        break;
      case 3:
        if (solarDate < this.tietKhiDate[3]) {
          month = month - 1;
        }
        break;
      case 4:
        if (solarDate < this.tietKhiDate[4]) {
          month = month - 1;
        }
        break;
      case 5:
        if (solarDate < this.tietKhiDate[5]) {
          month = month - 1;
        }
        break;
      case 6:
        if (solarDate < this.tietKhiDate[6]) {
          month = month - 1;
        }
        break;
      case 7:
        if (solarDate < this.tietKhiDate[7]) {
          month = month - 1;
        }
        break;
      case 8:
        if (solarDate < this.tietKhiDate[8]) {
          month = month - 1;
        }
        break;
      case 9:
        if (solarDate < this.tietKhiDate[9]) {
          month = month - 1;
        }
        break;
      case 10:
        if (solarDate < this.tietKhiDate[10]) {
          month = month - 1;
        }
        break;
      case 11:
        if (solarDate < this.tietKhiDate[11]) {
          month = month - 1;
        }
        break;
      default:
        break;
    }
    return new Date(year, month, day);
  }
  getAmDonOrDuongDon(date) {
    //khoảng cách từ năm chọn đến năm 1932
    const distance = date?.getFullYear() - 1932;
    //lấy dòng(ngày của năm) =  ngày đã chọn (+1 bỏ qua dòng đầu tiên của file excel) *42 (42 là số dòng data của 1 năm)
    const getDateInYear = date?.getDate() + 1 + distance * 42;
    // lấy tháng = tháng đã chọn (+1 bỏ qua cột đầu tiên "ngày" vì mỗi tháng bị lặp 2 lần nên sẽ nhân cho 2 )
    const getMonthInyear = (date?.getMonth() + 1) * 2;
    //lấy giá trị của tọa độ của ngày đã chọn trong file excel
    return this.excelLichVanNien[getDateInYear][getMonthInyear];
  }
  getAmDonOrDuongDonTheoNgay(date) {
    //khoảng cách từ năm chọn đến năm 1932
    const distance = date?.getFullYear() - 1932;
    //lấy dòng(ngày của năm) =  ngày đã chọn (+1 bỏ qua dòng đầu tiên của file excel) *42 (42 là số dòng data của 1 năm)
    const getDateInYear = date?.getDate() + 1 + distance * 42;
    // lấy tháng = tháng đã chọn (+1 bỏ qua cột đầu tiên "ngày" vì mỗi tháng bị lặp 2 lần nên sẽ nhân cho 2 )
    const getMonthInyear = (date?.getMonth() + 1) * 2;
    //lấy giá trị của tọa độ của ngày đã chọn trong file excel

    return this.kyMonDonGiapNgay[getDateInYear][getMonthInyear];
  }
  get1080cuc(amDonDuongDon, canChiHour, phiTinh) {
    if (typeof amDonDuongDon === 'number') {
      const dataCuc = this.dataDuongDon.filter((v) => v.sheet === `Duong Don ${amDonDuongDon}`);
      return { ...dataCuc.find((v) => v.CanChi === `${canChiHour[0]} ${canChiHour[1]}`), mainHourPhiTinh: phiTinh };
    } else {
      const dataCuc = this.dataAmDon.filter((v) => v.sheet === `Am Don ${amDonDuongDon.charAt(0)}`);
      return { ...dataCuc.find((v) => v.CanChi === `${canChiHour[0]} ${canChiHour[1]}`), mainHourPhiTinh: phiTinh };
    }
  }
  getIsSauDongChi(dateTime) {
    const tempDateTime = new Date(dateTime);
    //file excel bắt đầu từ năm 1932
    const distance = tempDateTime?.getFullYear() - 1932;

    //lấy tọa độ dòng trong excel
    const rowNumber = distance * 42 + 39;
    //chia nho chuoi lay ngay thang cua dong chi va ha chi
    const ngayDongChi = this.excelLichVanNien[rowNumber][21].split('-')[0].split(' ')[0].split('/');
    const stempDongChi = new Date(tempDateTime?.getFullYear(), ngayDongChi[1] - 1, ngayDongChi[0]);

    const ngayHaChi = this.excelLichVanNien[rowNumber][9].split('-')[0].split(' ')[0].split('/');
    const stempHaChi = new Date(tempDateTime?.getFullYear(), ngayHaChi[1] - 1, ngayHaChi[0]);

    // từ đầu năm đến hạ chí là sau đông chí ==> true
    // từ hạ chí đến ngày đông chí là trước đông chí ==> false
    // ngày được chọn lớn hơn ngày hạ chí là trước đông chí ==> false
    // ngày được chọn nhỏ hơn ngày hạ chí là sau đông chí ==> true

    //ngày được chọn > ngày đông chí ==>true
    //ngày được chọn < ngày hạ chí ==>true
    //ngày được chọn > ngày hạ chí ==>false
    if (tempDateTime.getTime() > stempDongChi.getTime() || tempDateTime.getTime() < stempHaChi.getTime()) {
      return true;
    } else {
      return false;
    }
    // if (tempDateTime.getTime() > stempHaChi.getTime()) {
    //   return false;
    // }
    // else {
    //   return true;
    // }
  }
  get12HourADay(canChiDay, AmDonDuongDon, sauDongchi) {
    const canChiDayArr = canChiDay?.split(' ');
    const chiDay = Const.ChiArr.find((v) => v.name === canChiDayArr[1]);

    let result = [];
    for (let index = 0; index <= 23; index++) {
      if (index % 2 === 1) {
        let CanChiHour = HourToCanChi(canChiDayArr[0], index);
        const phiTinh = getPhiTinhMainNumber(chiDay?.code, index, sauDongchi);
        const resultAnHour = this.get1080cuc(AmDonDuongDon, CanChiHour.name.split(' '), phiTinh);
        result.push(resultAnHour);
      }
    }

    return result;
  }
  getDongChiHaChi(fullYear) {
    const tempDateTime = new Date(fullYear, 1, 1);
    //theo lịch tiết khí bắt đầu từ năm 1932 để lấy khoảng cách
    const distance = tempDateTime?.getFullYear() - 1932;
    const rowNumber = distance * 42 + 39;
    const ngayDongChi = this.excelLichVanNien[rowNumber][21].split('-')[0].split(' ')[0].split('/');
    const ngayHaChi = this.excelLichVanNien[rowNumber][9].split('-')[0].split(' ')[0].split('/');

    return [ngayHaChi, ngayDongChi];
  }
  getDayStarOfADate(dateTime) {
    //6/2/1932 -> duong lich-> no7
    const exactlyDate = new Date(dateTime?.getFullYear(), dateTime.getMonth(), dateTime.getDate());
    let result = 7;
    let beginDate = new Date(1932, 1, 6);
    //ha chi mảng so 1, dong chi mảng so 2

    while (beginDate.getTime() < exactlyDate.getTime()) {
      let dongChiHaChi = this.getDongChiHaChi(beginDate?.getFullYear());
      let ngayHaChi = new Date(beginDate?.getFullYear(), dongChiHaChi[0][1] - 1, dongChiHaChi[0][0]);
      let ngayDongChi = new Date(beginDate?.getFullYear(), dongChiHaChi[1][1] - 1, dongChiHaChi[1][0]);

      // trước hạ chí thì ++

      if (beginDate.getTime() < ngayHaChi.getTime()) {
        result++;
      } else if (
        beginDate.getMonth() === ngayHaChi.getMonth() &&
        beginDate.getDate() === ngayHaChi.getDate() &&
        beginDate?.getFullYear() === ngayHaChi?.getFullYear()
      ) {
        if (
          beginDate?.getFullYear() === 1957 ||
          beginDate?.getFullYear() === 1980 ||
          beginDate?.getFullYear() === 2003 ||
          beginDate?.getFullYear() === 2026 ||
          beginDate?.getFullYear() === 2049
        ) {
          result = 6 - result;
        } else {
          result = 9 - result;
        }
      }
      //trước đông chí và sau hạ chí
      else if (beginDate.getTime() < ngayDongChi.getTime() && beginDate.getTime() > ngayHaChi.getTime()) {
        result--;
      } else if (
        beginDate.getMonth() === ngayDongChi.getMonth() &&
        beginDate.getDate() === ngayDongChi.getDate() &&
        beginDate?.getFullYear() === ngayDongChi?.getFullYear()
      ) {
        if (
          beginDate?.getFullYear() === 1934 ||
          beginDate?.getFullYear() === 1945 ||
          beginDate?.getFullYear() === 1968 ||
          beginDate?.getFullYear() === 1991 ||
          beginDate?.getFullYear() === 2014 ||
          beginDate?.getFullYear() === 2037
        ) {
          result = 14 - result;
        } else {
          result = 11 - result;
        }
      }

      //sau đông chí
      else if (beginDate.getTime() > ngayDongChi.getTime()) {
        result++;
      }

      // -----
      result = result % 9;
      if (result === 0) {
        result = 9;
      }

      beginDate.setDate(beginDate.getDate() + 1);
    }

    // result có 3 trường hợp

    //   1 là ngày hạ chí,
    // if (dateTime.getTime() === ngayHaChi.getTime()) {
    //   let tempNumber = 10 - result;
    //   return `${result}/${tempNumber}`;
    // } else {
    return result;
    // }
    //     2 là ngày đông chí
    // 3 là ngày bth,
  }
  async searchAllPeriodSelected() {
    //2 tham số truyền vào 1 là from date 2 là to date

    //đếm số vòng lặp
    var a = moment(this.state.fromDate);
    var b = moment(this.state.toDate);
    const countDay = b.diff(a, 'days') + 1;
    this.getTietKhiAllYear(this.state.fromDate);

    let allResultPeriodTime = [];
    for (let index = 0; index < countDay; index++) {
      const momentDate = new Date(moment(this.state.fromDate).add(index, 'days').format('LLLL'));
      if (momentDate.getFullYear() > Number(moment(momentDate).subtract(1, 'days').format('YYYY'))) {
        this.getTietKhiAllYear(momentDate);
      }

      const getDataAmDonDuongDon = this.getAmDonOrDuongDon(momentDate);
      const getDataAmDonDuongDonNgay = this.getAmDonOrDuongDonTheoNgay(momentDate);
      const dateTietKhi = this.getCorrectTietKhi(momentDate);
      const canYear = YearToCan(dateTietKhi.getYear());
      const chiYear = YearToChi(dateTietKhi.getYear());
      const canChiMonth = MonthToCanChi(canYear, dateTietKhi.getMonth());
      const canChiDay = await DayToCanChi(momentDate.toDateString());
      const isSauDongChi = this.getIsSauDongChi(dateTietKhi);
      const starDay = this.getDayStarOfADate(momentDate);

      if (canChiDay) {
        const DonGiap12Hour = this.get12HourADay(canChiDay.name, getDataAmDonDuongDon, isSauDongChi);
        const dataKyMonDay = this.get1080cuc(getDataAmDonDuongDonNgay, canChiDay.name.split(' '), starDay);
        allResultPeriodTime.push({
          date: moment(momentDate).format('LLLL'),
          cuc: getDataAmDonDuongDon,
          canChiYear: `${canYear.name} ${chiYear.name}`,
          canChiMonth: canChiMonth.name,
          canChiDay: canChiDay.name,
          kyMonDonGiapNgay: dataKyMonDay,
          donGiap12Hour: DonGiap12Hour,
        });
      }
    }

    return allResultPeriodTime;
  }
  filterThienBan(Arr, huong) {
    let resultFilterThienBan = Arr;

    const thienBanOption = this.state.filterOption.find((v) => v.key === SelectionType.ThienBan);
    const selectedThienBan = thienBanOption.option[thienBanOption.selected + 1];

    if (selectedThienBan.code !== -1 && resultFilterThienBan.length > 0) {
      if (this.state.searchBy === 'SearchHour') {
        resultFilterThienBan = resultFilterThienBan.map((date) => {
          let temp = date.donGiap12Hour.filter((Hour) => {
            const TuanThu = Hour.tuanThu.includes(' - ') ? Hour.tuanThu.split(' - ')[0] : Hour.tuanThu;
            const getTuanThu = TuanThu.includes(': ') ? TuanThu.split(': ')[1] : TuanThu;
            const boxSouth = Hour.box2.thienBan;
            const boxSouthwest = Hour.box3.thienBan;
            const boxWest = Hour.box6.thienBan;
            const boxNorthwest = Hour.box9.thienBan;
            const boxNorth = Hour.box8.thienBan;
            const boxNortheast = Hour.box7.thienBan;
            const boxEast = Hour.box4.thienBan;
            const boxSouthEast = Hour.box1.thienBan;
            switch (huong.code) {
              case 1:
                return selectedThienBan.code === 0 ? boxSouth === getTuanThu : boxSouth === selectedThienBan.name;
              case 2:
                return selectedThienBan.code === 0 ? boxSouthwest === getTuanThu : boxSouthwest === selectedThienBan.name;
              case 3:
                return selectedThienBan.code === 0 ? boxWest === getTuanThu : boxWest === selectedThienBan.name;
              case 4:
                return selectedThienBan.code === 0 ? boxNorthwest === getTuanThu : boxNorthwest === selectedThienBan.name;
              case 5:
                return selectedThienBan.code === 0 ? boxNorth === getTuanThu : boxNorth === selectedThienBan.name;
              case 6:
                return selectedThienBan.code === 0 ? boxNortheast === getTuanThu : boxNortheast === selectedThienBan.name;
              case 7:
                return selectedThienBan.code === 0 ? boxEast === getTuanThu : boxEast === selectedThienBan.name;
              case 8:
                return selectedThienBan.code === 0 ? boxSouthEast === getTuanThu : boxSouthEast === selectedThienBan.name;
              default:
                return;
            }
          });
          return { ...date, donGiap12Hour: temp };
        });
      } else {
        resultFilterThienBan = resultFilterThienBan.filter((date) => {
          const TuanThu = date.kyMonDonGiapNgay.tuanThu.includes(' - ')
            ? date.kyMonDonGiapNgay.tuanThu.split(' - ')[0]
            : date.kyMonDonGiapNgay.tuanThu;
          const getTuanThu = TuanThu.includes(': ') ? TuanThu.split(': ')[1] : TuanThu;
          const boxSouth = date.kyMonDonGiapNgay.box2.thienBan;
          const boxSouthwest = date.kyMonDonGiapNgay.box3.thienBan;
          const boxWest = date.kyMonDonGiapNgay.box6.thienBan;
          const boxNorthwest = date.kyMonDonGiapNgay.box9.thienBan;
          const boxNorth = date.kyMonDonGiapNgay.box8.thienBan;
          const boxNortheast = date.kyMonDonGiapNgay.box7.thienBan;
          const boxEast = date.kyMonDonGiapNgay.box4.thienBan;
          const boxSouthEast = date.kyMonDonGiapNgay.box1.thienBan;
          switch (huong.code) {
            case 1:
              return selectedThienBan.code === 0 ? boxSouth === getTuanThu : boxSouth === selectedThienBan.name;
            case 2:
              return selectedThienBan.code === 0 ? boxSouthwest === getTuanThu : boxSouthwest === selectedThienBan.name;
            case 3:
              return selectedThienBan.code === 0 ? boxWest === getTuanThu : boxWest === selectedThienBan.name;
            case 4:
              return selectedThienBan.code === 0 ? boxNorthwest === getTuanThu : boxNorthwest === selectedThienBan.name;
            case 5:
              return selectedThienBan.code === 0 ? boxNorth === getTuanThu : boxNorth === selectedThienBan.name;
            case 6:
              return selectedThienBan.code === 0 ? boxNortheast === getTuanThu : boxNortheast === selectedThienBan.name;
            case 7:
              return selectedThienBan.code === 0 ? boxEast === getTuanThu : boxEast === selectedThienBan.name;
            case 8:
              return selectedThienBan.code === 0 ? boxSouthEast === getTuanThu : boxSouthEast === selectedThienBan.name;
            default:
              return;
          }
        });
        return resultFilterThienBan;
      }
    }

    return resultFilterThienBan;
  }
  filterDiaBan(Arr, huong) {
    let resultFilterDiaBan = Arr;
    const diaBanOption = this.state.filterOption.find((v) => v.key === SelectionType.DiaBan);
    const selectedDiaBan = diaBanOption.option[diaBanOption.selected + 1];
    if (selectedDiaBan.code !== -1 && Arr.length > 0) {
      if (this.state.searchBy === 'SearchHour') {
        resultFilterDiaBan = Arr.map((date) => {
          let temp = date.donGiap12Hour.filter((Hour) => {
            const TuanThu = Hour.tuanThu.includes(' - ') ? Hour.tuanThu.split(' - ')[0] : Hour.tuanThu;
            const getTuanThu = TuanThu.includes(': ') ? TuanThu.split(': ')[1] : TuanThu;
            switch (huong.code) {
              case 1:
                return selectedDiaBan.code === 0 ? Hour.box2.diaBan === getTuanThu : Hour.box2.diaBan === selectedDiaBan.name;
              case 2:
                return selectedDiaBan.code === 0 ? Hour.box3.diaBan === getTuanThu : Hour.box3.diaBan === selectedDiaBan.name;
              case 3:
                return selectedDiaBan.code === 0 ? Hour.box6.diaBan === getTuanThu : Hour.box6.diaBan === selectedDiaBan.name;
              case 4:
                return selectedDiaBan.code === 0 ? Hour.box9.diaBan === getTuanThu : Hour.box9.diaBan === selectedDiaBan.name;
              case 5:
                return selectedDiaBan.code === 0 ? Hour.box8.diaBan === getTuanThu : Hour.box8.diaBan === selectedDiaBan.name;
              case 6:
                return selectedDiaBan.code === 0 ? Hour.box7.diaBan === getTuanThu : Hour.box7.diaBan === selectedDiaBan.name;
              case 7:
                return selectedDiaBan.code === 0 ? Hour.box4.diaBan === getTuanThu : Hour.box4.diaBan === selectedDiaBan.name;
              case 8:
                return selectedDiaBan.code === 0 ? Hour.box1.diaBan === getTuanThu : Hour.box1.diaBan === selectedDiaBan.name;
              default:
                return;
            }
          });
          return { ...date, donGiap12Hour: temp };
        });
      } else {
        resultFilterDiaBan = resultFilterDiaBan.filter((date) => {
          const TuanThu = date.kyMonDonGiapNgay.tuanThu.includes(' - ')
            ? date.kyMonDonGiapNgay.tuanThu.split(' - ')[0]
            : date.kyMonDonGiapNgay.tuanThu;
          const getTuanThu = TuanThu.includes(': ') ? TuanThu.split(': ')[1] : TuanThu;
          const boxSouth = date.kyMonDonGiapNgay.box2.diaBan;
          const boxSouthwest = date.kyMonDonGiapNgay.box3.diaBan;
          const boxWest = date.kyMonDonGiapNgay.box6.diaBan;
          const boxNorthwest = date.kyMonDonGiapNgay.box9.diaBan;
          const boxNorth = date.kyMonDonGiapNgay.box8.diaBan;
          const boxNortheast = date.kyMonDonGiapNgay.box7.diaBan;
          const boxEast = date.kyMonDonGiapNgay.box4.diaBan;
          const boxSouthEast = date.kyMonDonGiapNgay.box1.diaBan;
          switch (huong.code) {
            case 1:
              return selectedDiaBan.code === 0 ? boxSouth === getTuanThu : boxSouth === selectedDiaBan.name;
            case 2:
              return selectedDiaBan.code === 0 ? boxSouthwest === getTuanThu : boxSouthwest === selectedDiaBan.name;
            case 3:
              return selectedDiaBan.code === 0 ? boxWest === getTuanThu : boxWest === selectedDiaBan.name;
            case 4:
              return selectedDiaBan.code === 0 ? boxNorthwest === getTuanThu : boxNorthwest === selectedDiaBan.name;
            case 5:
              return selectedDiaBan.code === 0 ? boxNorth === getTuanThu : boxNorth === selectedDiaBan.name;
            case 6:
              return selectedDiaBan.code === 0 ? boxNortheast === getTuanThu : boxNortheast === selectedDiaBan.name;
            case 7:
              return selectedDiaBan.code === 0 ? boxEast === getTuanThu : boxEast === selectedDiaBan.name;
            case 8:
              return selectedDiaBan.code === 0 ? boxSouthEast === getTuanThu : boxSouthEast === selectedDiaBan.name;
            default:
              return;
          }
        });
        return resultFilterDiaBan;
      }
    }

    return resultFilterDiaBan;
  }
  filterCuuTinh(Arr, huong) {
    let resultFilterCuuTinh = Arr;
    const cuuTinhOption = this.state.filterOption.find((v) => v.key === SelectionType.CuuTinh);
    const selectedCuuTinh = cuuTinhOption.option[cuuTinhOption.selected + 1];
    if (selectedCuuTinh.code !== -1 && Arr.length > 0) {
      if (this.state.searchBy === 'SearchHour') {
        resultFilterCuuTinh = Arr.map((date) => {
          let temp = date.donGiap12Hour.filter((Hour) => {
            switch (huong.code) {
              case 1:
                return Hour.box2.cuuTinh === selectedCuuTinh.name;
              case 2:
                return Hour.box3.cuuTinh === selectedCuuTinh.name;
              case 3:
                return Hour.box6.cuuTinh === selectedCuuTinh.name;
              case 4:
                return Hour.box9.cuuTinh === selectedCuuTinh.name;
              case 5:
                return Hour.box8.cuuTinh === selectedCuuTinh.name;
              case 6:
                return Hour.box7.cuuTinh === selectedCuuTinh.name;
              case 7:
                return Hour.box4.cuuTinh === selectedCuuTinh.name;
              case 8:
                return Hour.box1.cuuTinh === selectedCuuTinh.name;
              default:
                return;
            }
          });
          return { ...date, donGiap12Hour: temp };
        });
      } else {
        resultFilterCuuTinh = resultFilterCuuTinh.filter((date) => {
          const boxSouth = date.kyMonDonGiapNgay.box2.cuuTinh;
          const boxSouthwest = date.kyMonDonGiapNgay.box3.cuuTinh;
          const boxWest = date.kyMonDonGiapNgay.box6.cuuTinh;
          const boxNorthwest = date.kyMonDonGiapNgay.box9.cuuTinh;
          const boxNorth = date.kyMonDonGiapNgay.box8.cuuTinh;
          const boxNortheast = date.kyMonDonGiapNgay.box7.cuuTinh;
          const boxEast = date.kyMonDonGiapNgay.box4.cuuTinh;
          const boxSouthEast = date.kyMonDonGiapNgay.box1.cuuTinh;
          switch (huong.code) {
            case 1:
              return boxSouth === selectedCuuTinh.name;
            case 2:
              return boxSouthwest === selectedCuuTinh.name;
            case 3:
              return boxWest === selectedCuuTinh.name;
            case 4:
              return boxNorthwest === selectedCuuTinh.name;
            case 5:
              return boxNorth === selectedCuuTinh.name;
            case 6:
              return boxNortheast === selectedCuuTinh.name;
            case 7:
              return boxEast === selectedCuuTinh.name;
            case 8:
              return boxSouthEast === selectedCuuTinh.name;
            default:
              return;
          }
        });
        return resultFilterCuuTinh;
      }
    }

    return resultFilterCuuTinh;
  }
  filterBatMon(Arr, huong) {
    let resultFilterBatMon = Arr;
    const batMonOption = this.state.filterOption.find((v) => v.key === SelectionType.BatMon);
    const selectedBatMon = batMonOption.option[batMonOption.selected + 1];
    if (selectedBatMon.code !== -1 && Arr.length > 0) {
      if (this.state.searchBy === 'SearchHour') {
        resultFilterBatMon = Arr.map((date) => {
          let temp = date.donGiap12Hour.filter((Hour) => {
            switch (huong.code) {
              case 1:
                return Hour.box2.batMon === selectedBatMon.name;
              case 2:
                return Hour.box3.batMon === selectedBatMon.name;
              case 3:
                return Hour.box6.batMon === selectedBatMon.name;
              case 4:
                return Hour.box9.batMon === selectedBatMon.name;
              case 5:
                return Hour.box8.batMon === selectedBatMon.name;
              case 6:
                return Hour.box7.batMon === selectedBatMon.name;
              case 7:
                return Hour.box4.batMon === selectedBatMon.name;
              case 8:
                return Hour.box1.batMon === selectedBatMon.name;
              default:
                return;
            }
          });
          return { ...date, donGiap12Hour: temp };
        });
      } else {
        resultFilterBatMon = resultFilterBatMon.filter((date) => {
          const boxSouth = date.kyMonDonGiapNgay.box2.batMon;
          const boxSouthwest = date.kyMonDonGiapNgay.box3.batMon;
          const boxWest = date.kyMonDonGiapNgay.box6.batMon;
          const boxNorthwest = date.kyMonDonGiapNgay.box9.batMon;
          const boxNorth = date.kyMonDonGiapNgay.box8.batMon;
          const boxNortheast = date.kyMonDonGiapNgay.box7.batMon;
          const boxEast = date.kyMonDonGiapNgay.box4.batMon;
          const boxSouthEast = date.kyMonDonGiapNgay.box1.batMon;
          switch (huong.code) {
            case 1:
              return boxSouth === selectedBatMon.name;
            case 2:
              return boxSouthwest === selectedBatMon.name;
            case 3:
              return boxWest === selectedBatMon.name;
            case 4:
              return boxNorthwest === selectedBatMon.name;
            case 5:
              return boxNorth === selectedBatMon.name;
            case 6:
              return boxNortheast === selectedBatMon.name;
            case 7:
              return boxEast === selectedBatMon.name;
            case 8:
              return boxSouthEast === selectedBatMon.name;
            default:
              return;
          }
        });
        return resultFilterBatMon;
      }
    }
    return resultFilterBatMon;
  }
  filterBatThan(Arr, huong) {
    let resultFilterBatThan = Arr;
    const batThanOption = this.state.filterOption.find((v) => v.key === SelectionType.BatThan);
    const selectedBatThan = batThanOption.option[batThanOption.selected + 1];
    if (selectedBatThan.code !== -1 && Arr.length > 0) {
      if (this.state.searchBy === 'SearchHour') {
        resultFilterBatThan = Arr.map((date) => {
          let temp = date.donGiap12Hour.filter((Hour) => {
            let batThanSelected = selectedBatThan.name;
            if (selectedBatThan.code === 4) {
              batThanSelected = 'Trần' || 'Hổ';
            } else if (selectedBatThan.code === 5) {
              batThanSelected = 'Tước' || 'Vũ';
            }
            switch (huong.code) {
              case 1:
                return Hour.box2.batThan === batThanSelected;
              case 2:
                return Hour.box3.batThan === batThanSelected;
              case 3:
                return Hour.box6.batThan === batThanSelected;
              case 4:
                return Hour.box9.batThan === batThanSelected;
              case 5:
                return Hour.box8.batThan === batThanSelected;
              case 6:
                return Hour.box7.batThan === batThanSelected;
              case 7:
                return Hour.box4.batThan === batThanSelected;
              case 8:
                return Hour.box1.batThan === batThanSelected;
              default:
                return;
            }
          });
          return { ...date, donGiap12Hour: temp };
        });
      } else {
        resultFilterBatThan = resultFilterBatThan.filter((date) => {
          const boxSouth = date.kyMonDonGiapNgay.box2.batThan;
          const boxSouthwest = date.kyMonDonGiapNgay.box3.batThan;
          const boxWest = date.kyMonDonGiapNgay.box6.batThan;
          const boxNorthwest = date.kyMonDonGiapNgay.box9.batThan;
          const boxNorth = date.kyMonDonGiapNgay.box8.batThan;
          const boxNortheast = date.kyMonDonGiapNgay.box7.batThan;
          const boxEast = date.kyMonDonGiapNgay.box4.batThan;
          const boxSouthEast = date.kyMonDonGiapNgay.box1.batThan;
          switch (huong.code) {
            case 1:
              return boxSouth === selectedBatThan.name;
            case 2:
              return boxSouthwest === selectedBatThan.name;
            case 3:
              return boxWest === selectedBatThan.name;
            case 4:
              return boxNorthwest === selectedBatThan.name;
            case 5:
              return boxNorth === selectedBatThan.name;
            case 6:
              return boxNortheast === selectedBatThan.name;
            case 7:
              return boxEast === selectedBatThan.name;
            case 8:
              return boxSouthEast === selectedBatThan.name;
            default:
              return;
          }
        });
        return resultFilterBatThan;
      }
    }
    return resultFilterBatThan;
  }
  convertPhitinh(number) {
    const showMainNumber = number === 10 ? 1 : number > 10 ? number - 9 : number;
    return showMainNumber;
  }
  filterPhiTinh(Arr, huong) {
    let resultFilterPhiTinh = Arr;

    const phiTinhOption = this.state.filterOption.find((v) => v.key === SelectionType.PhiTinh);
    const selectedPhiTinh = phiTinhOption.option[phiTinhOption.selected + 1];
    if (selectedPhiTinh.code !== -1 && Arr.length > 0) {
      if (this.state.searchBy === 'SearchHour') {
        resultFilterPhiTinh = Arr.map((date) => {
          let temp = date.donGiap12Hour.filter((Hour) => {
            const numberPhiTinh = Hour.mainHourPhiTinh;
            switch (huong.code) {
              case 1:
                return this.convertPhitinh(numberPhiTinh + 4) === Number(selectedPhiTinh.name);
              case 2:
                return this.convertPhitinh(numberPhiTinh + 6) === Number(selectedPhiTinh.name);
              case 3:
                return this.convertPhitinh(numberPhiTinh + 2) === Number(selectedPhiTinh.name);
              case 4:
                return this.convertPhitinh(numberPhiTinh + 1) === Number(selectedPhiTinh.name);
              case 5:
                return this.convertPhitinh(numberPhiTinh + 5) === Number(selectedPhiTinh.name);
              case 6:
                return this.convertPhitinh(numberPhiTinh + 3) === Number(selectedPhiTinh.name);
              case 7:
                return this.convertPhitinh(numberPhiTinh + 7) === Number(selectedPhiTinh.name);
              case 8:
                return this.convertPhitinh(numberPhiTinh + 8) === Number(selectedPhiTinh.name);
              default:
                return;
            }
          });
          return { ...date, donGiap12Hour: temp };
        });
      } else {
        resultFilterPhiTinh = resultFilterPhiTinh.filter((date) => {
          const numberPhiTinh = date.kyMonDonGiapNgay.mainHourPhiTinh;

          switch (huong.code) {
            case 1:
              return this.convertPhitinh(numberPhiTinh + 4) === Number(selectedPhiTinh.name);
            case 2:
              return this.convertPhitinh(numberPhiTinh + 6) === Number(selectedPhiTinh.name);
            case 3:
              return this.convertPhitinh(numberPhiTinh + 2) === Number(selectedPhiTinh.name);
            case 4:
              return this.convertPhitinh(numberPhiTinh + 1) === Number(selectedPhiTinh.name);
            case 5:
              return this.convertPhitinh(numberPhiTinh + 5) === Number(selectedPhiTinh.name);
            case 6:
              return this.convertPhitinh(numberPhiTinh + 3) === Number(selectedPhiTinh.name);
            case 7:
              return this.convertPhitinh(numberPhiTinh + 7) === Number(selectedPhiTinh.name);
            case 8:
              return this.convertPhitinh(numberPhiTinh + 8) === Number(selectedPhiTinh.name);
            default:
              return;
          }
        });
        return resultFilterPhiTinh;
      }
    }
    return resultFilterPhiTinh;
  }
  isDichMa(CanChi, huong) {
    const chiConGiap = CanChi?.split(' ')[1];
    if (CanChi == null || huong == null) {
      return false;
    }

    switch (chiConGiap) {
      case Const.Chi.Than.name:
      case Const.Chi.Ty.name:
      case Const.Chi.Thin.name:
        if (huong === Const.huong.DONG_BAC.show) {
          return 'DM';
        }
        return '';

      case Const.Chi.Ti.name:
      case Const.Chi.Dau.name:
      case Const.Chi.Suu.name:
        if (huong === Const.huong.TAY_BAC.show) {
          return 'DM';
        }
        return '';

      case Const.Chi.Hoi.name:
      case Const.Chi.Mao.name:
      case Const.Chi.Mui.name:
        if (huong === Const.huong.DONG_NAM.show) {
          return 'DM';
        }
        return '';

      case Const.Chi.Dan.name:
      case Const.Chi.Ngo.name:
      case Const.Chi.Tuat.name:
        if (huong === Const.huong.TAY_NAM.show) {
          return 'DM';
        }
        return '';

      default:
        return '';
    }
  }
  filterDichMa(Arr, huong) {
    let resultFilterDichMa = Arr;
    const dichMaOption = this.state.filterOption.find((v) => v.key === SelectionType.DichMa);
    const selectedDichMa = dichMaOption.option[dichMaOption.selected];

    if (selectedDichMa?.code !== 0 && Arr.length > 0) {
      if (this.state.searchBy === 'SearchHour') {
        resultFilterDichMa = Arr.map((date) => {
          let temp = date.donGiap12Hour.filter((Hour) => {
            switch (huong.code) {
              // Hương Tây nam
              case 2:
                return this.isDichMa(Hour.CanChi, Const.huong.TAY_NAM.show) === 'DM';
              // hướng tây băcs
              case 4:
                return this.isDichMa(Hour.CanChi, Const.huong.TAY_BAC.show) === 'DM';
              // hướng đông bắc
              case 6:
                return this.isDichMa(Hour.CanChi, Const.huong.DONG_BAC.show) === 'DM';
              // hướng đông nam
              case 8:
                return this.isDichMa(Hour.CanChi, Const.huong.DONG_NAM.show) === 'DM';
              default:
                return;
            }
          });
          return { ...date, donGiap12Hour: temp };
        });
      } else {
        resultFilterDichMa = resultFilterDichMa.filter((date) => {
          switch (huong.code) {
            case 2:
              return this.isDichMa(date.canChiDay, Const.huong.TAY_NAM.show) === 'DM';

            case 4:
              return this.isDichMa(date.canChiDay, Const.huong.TAY_BAC.show) === 'DM';

            case 6:
              return this.isDichMa(date.canChiDay, Const.huong.DONG_BAC.show) === 'DM';
            case 8:
              return this.isDichMa(date.canChiDay, Const.huong.DONG_NAM.show) === 'DM';
            default:
              return;
          }
        });
        return resultFilterDichMa;
      }
    }
    return resultFilterDichMa;
  }
  async searchResult() {
    try {
      const resultPeriod = await this.searchAllPeriodSelected();
      const huongOption = this.state.filterOption.find((v) => v.key === SelectionType.Huong);
      const selectedHuong = huongOption.option[huongOption.selected];
      let resultAfterFilterOption = [];

      if (selectedHuong.selected !== -1) {
        resultAfterFilterOption = await this.filterThienBan(resultPeriod, selectedHuong);
        resultAfterFilterOption = await this.filterDiaBan(resultAfterFilterOption, selectedHuong);
        resultAfterFilterOption = await this.filterCuuTinh(resultAfterFilterOption, selectedHuong);
        resultAfterFilterOption = await this.filterBatMon(resultAfterFilterOption, selectedHuong);
        resultAfterFilterOption = await this.filterBatThan(resultAfterFilterOption, selectedHuong);
        resultAfterFilterOption = await this.filterPhiTinh(resultAfterFilterOption, selectedHuong);
        resultAfterFilterOption = await this.filterDichMa(resultAfterFilterOption, selectedHuong);
      }
      let finalResult = [];
      if (this.state.searchBy === 'SearchHour') {
        finalResult = resultAfterFilterOption.filter((v) => v.donGiap12Hour.length > 0);
      } else {
        finalResult = resultAfterFilterOption;
      }

      if (finalResult.length === 0) {
        this.setState({ modalNoResult: true });
      }

      this.setState({ resultSearchDate: finalResult });
    } catch (error) {
      this.setState({ isLoading: false, errorServer: true });
    }
  }
  async onClickSearchResult() {
    try {
      this.setState({ isLoading: true });
      if (this.checkSelectHuong() && this.checkSelectOneOfOther()) {
        if (this.state.fromDate === null || this.state.toDate === null || this.state.toDate < this.state.fromDate) {
          this.setState({ modalEmptySelectedDateFromTo: true, isLoading: false });
        } else {
          setTimeout(async () => {
            await this.searchResult();
            this.setState({ isLoading: false });
          }, 800);
        }
      } else {
        this.setState({ modalEmptySelectedHuong: true }, () => this.setState({ isLoading: false }));
      }
    } catch (error) {
      this.setState({ isLoading: false, errorServer: true });
    }
  }
  renderModalErrorPressCode = (nameModal, actionsCloseModal, content) => {
    return (
      <Modal open={nameModal} size="small">
        <Modal.Content>
          <p>{content}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Đóng" onClick={actionsCloseModal} />
        </Modal.Actions>
      </Modal>
    );
  };
  convertDayWeekToLetter(NumberDayWeek) {
    switch (NumberDayWeek) {
      case 0:
        return 'Chủ Nhật';
      case 1:
        return 'Thứ Hai';

      case 2:
        return 'Thứ Ba';
      case 3:
        return 'Thứ Tư';
      case 4:
        return 'Thứ Năm';
      case 5:
        return 'Thứ Sáu';
      case 6:
        return 'Thứ Bảy';

      default:
        break;
    }
  }
  async validImageData(arrImage) {
    let finalData = [];
    if (arrImage.length > 0) {
      await arrImage.map(async (image, index) => {
        let Canvas = await html2canvas(document.getElementById(`ky-mon-don-giap-${index}`), {
          ignoreElements: (element) => element.className === 'to-hide-print',
          useCORS: true,
          allowTaint: true,
          logging: true,
          height: 1000,
          // width: 362,
        });
        finalData.push({ url: Canvas.toDataURL() });
      });
    }

    return finalData;
  }
  async onClickSendMail(data, dateToString, dateSendToServer) {
    this.setState({ isLoading: true });
    const UTCTIME = moment(`${dateSendToServer} 09:00`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');

    const emailSaveStorage = localStorage.getItem(mdfive);
    const userData = JSON.parse(emailSaveStorage);
    let imgData = await this.validImageData(data.donGiap12Hour);

    const hour = data.donGiap12Hour.map((v, i) => {
      return v.CanChi;
    });
    setTimeout(() => {
      if (userData.userName) {
        try {
          var templateParams = {
            email: userData.email,
            date: UTCTIME.split(' ')[0],
            time: UTCTIME.split(' ')[1],
            subject: `Phong Thủy Tường Minh gửi bạn thông tin ngày ${dateToString}`,
            text: 'Phong Thủy Tường Minh - Tường Minh Fengshui',
            content: `<div><p>Xin chào ${userData.userName}</p><p>Thông tin giờ kỳ môn độn giáp theo ngày ${dateToString} bạn đã theo dõi như sau:</p><p><span style={{ fontStyle: 'italic' }}>&nbsp; - Giờ: ${hour}.</span></p><p><span style={{ fontStyle: 'italic' }}>&nbsp; - Ngày: ${data.canChiDay}.</span></p><p><span style={{ fontStyle: 'italic' }}>&nbsp; - Tháng: ${data.canChiMonth}.</span></p><p><span style={{ fontStyle: 'italic' }}>&nbsp; - Năm: ${data.canChiYear}.</span></p><p>Chúc bạn một ngày tốt đẹp,<br />Phong Thủy Tường Minh</p></div>`,
            image_base64: imgData,
          };

          axios
            .post('https://huyenkhongtamnguyen.herokuapp.com/api/v1/auth/send_mail', templateParams)

            .then((response) => {
              //handle success
              this.setState({ modalSentSuccess: true, isLoading: false });

            })
            .catch((response) => {
              //handle error
              this.setState({ isLoading: false });

            });
        } catch (error) {
          console.log(error);
        }
      }
    }, 1000);
  }
  convertChiToHour(chi, day) {
    // ngày hôm trước
    // moment(`${dateSendToServer} 00:01`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
    let startDate = null;
    let endDate = null;
    // moment(day, 'DD/MM/YYY').utc().format('DD/MM/YYYY');
    switch (chi) {
      //special hour
      //23h01 ngày hôm trước đến 1 giờ ngày hôm sau.
      case Const.Chi.Ty.name:
        startDate = moment(`${day} 23:01`, 'DD/MM/YYYY HH:mm').subtract(1, 'days').utc().format('DD/MM/YYYY HH:mm');
        endDate = moment(`${day} 1:00`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
        return { startDate, endDate };
      case Const.Chi.Suu.name:
        startDate = moment(`${day} 1:01`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
        endDate = moment(`${day} 3:00`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
        return { startDate, endDate };
      case Const.Chi.Dan.name:
        startDate = moment(`${day} 3:01`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
        endDate = moment(`${day} 5:00`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
        return { startDate, endDate };
      case Const.Chi.Mao.name:
        startDate = moment(`${day} 5:01`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
        endDate = moment(`${day} 7:00`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
        return { startDate, endDate };
      case Const.Chi.Thin.name:
        startDate = moment(`${day} 7:01`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
        endDate = moment(`${day} 9:00`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
        return { startDate, endDate };
      case Const.Chi.Ti.name:
        startDate = moment(`${day} 9:01`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
        endDate = moment(`${day} 11:00`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
        return { startDate, endDate };
      case Const.Chi.Ngo.name:
        startDate = moment(`${day} 11:01`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
        endDate = moment(`${day} 13:00`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
        return { startDate, endDate };
      case Const.Chi.Mui.name:
        startDate = moment(`${day} 13:01`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
        endDate = moment(`${day} 15:00`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
        return { startDate, endDate };
      case Const.Chi.Than.name:
        startDate = moment(`${day} 15:01`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
        endDate = moment(`${day} 17:00`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
        return { startDate, endDate };
      case Const.Chi.Dau.name:
        startDate = moment(`${day} 17:01`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
        endDate = moment(`${day} 19:00`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
        return { startDate, endDate };
      case Const.Chi.Tuat.name:
        startDate = moment(`${day} 19:01`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
        endDate = moment(`${day} 21:00`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
        return { startDate, endDate };
      case Const.Chi.Hoi.name:
        startDate = moment(`${day} 21:01`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
        endDate = moment(`${day} 23:00`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
        return { startDate, endDate };
      default:
        break;
    }
  }
  sendCodeToThacNuoc(time, maThacNuoc, userData) {
    this.setState({ isLoading: true });
   

    if (maThacNuoc) {
      setTimeout(() => {
        if (userData.userName) {
          try {
            var templateParams = {
              code_water_fall: maThacNuoc,
              date_utc_open: time.startDate.split(' ')[0],
              time_utc_open: time.startDate.split(' ')[1],
              date_utc_close: time.endDate.split(' ')[0],
              time_utc_close: time.endDate.split(' ')[1],
            };

            axios
              .post('https://hitek-01.hitek.com.vn:5556/api/v1/auth/set_up', templateParams)

              .then((response) => {
                //handle success
                this.setState({ modalSentThapNuocSuccess: true, isLoading: false });
                alert('Hẹn giờ thác nước thành công');
              })
              .catch((response) => {
                //handle error
                this.setState({ isLoading: false });
                alert('Hẹn giờ thác nước thất bại, vui lòng thử lại sau');

              });
          } catch (error) {
            console.log(error);
          }
        }
      }, 1000);
    }
  }
  async onClickSetMayBom(dateSendToServer, hour) {
    const hourStartNEnd = this.convertChiToHour(hour.CanChi.split(' ')[1], dateSendToServer);

    this.setState({ hourSelectedWaterFall: hourStartNEnd });
    const emailSaveStorage = localStorage.getItem(mdfive);
    const userData = JSON.parse(emailSaveStorage);

    if (userData?.ListThacNuoc) {
      this.setState({ isBusiness: true });
    } else if (userData?.MaThacNuoc) {
      this.sendCodeToThacNuoc(hourStartNEnd, emailSaveStorage.MaThacNuoc, userData);
    } else {
      this.setState({ modalMissingThacNuoc: true });
    }
  }
  handleChangeRadioButton = (event, { value }) => {
    this.setState({ searchBy: value });
  };
  onChangeItemWaterFall(event) {

    this.setState({ thacNuocSelected: event?.target?.value });
  }
  render() {
    const { filterOption, isLoading, activeResultState } = this.state;
    const emailSaveStorage = localStorage.getItem(mdfive);
    const userData = JSON.parse(emailSaveStorage);
    return (
      <div id="Main-Body" style={{ textAlign: 'center' }}>
        {this.renderModalErrorPressCode(
          this.state.errorServer,
          () => this.setState({ errorServer: false }),
          'Hệ thống đang có lỗi xin vui lòng liên hệ nhà cung cấp để chúng tôi có thể khắc phục sớm nhất.',
        )}
        {this.renderModalErrorPressCode(
          this.state.modalMissingThacNuoc,
          () => this.setState({ modalMissingThacNuoc: false }),
          'Bạn chưa gửi thông tin mã thác nước của bạn cho chúng tôi, xin vui lòng liên hệ với chúng tôi để cung cấp mã thác nước. Xin cảm ơn.',
        )}
        {this.renderModalErrorPressCode(
          this.state.modalSentThapNuocSuccess,
          () => this.setState({ modalSentThapNuocSuccess: false }),
          'Thác nước của bạn sẽ tự động mở và tắt trong ngày bạn đã chọn.',
        )}
        {this.renderModalErrorPressCode(
          this.state.modalSentSuccess,
          () => this.setState({ modalSentSuccess: false }),
          'Gửi mail thành công, vui lòng check mail của bạn để có thông tin ngày.',
        )}
        {this.renderModalErrorPressCode(
          this.state.modalEmptySelectedHuong,
          () => this.setState({ modalEmptySelectedHuong: false }),
          'Xin vui lòng chọn hướng và một trong các Độn Giáp để tìm kiếm.',
        )}
        {this.renderModalErrorPressCode(
          this.state.modalEmptySelectedDateFromTo,
          () => this.setState({ modalEmptySelectedDateFromTo: false }),
          'Xin vui lòng chọn khoảng thời gian từ ngày nào đến ngày nào để tìm kiếm (Lưu ý: Khoảng thời gian "Từ ngày"phải nhỏ hơn khoản thời gian "Đến ngày")',
        )}
        {this.renderModalErrorPressCode(
          this.state.modalNoResult,
          () => this.setState({ modalNoResult: false }),
          'Không có kết quả nào phù hợp với khoảng thời gian bạn chọn, xin vui lòng chọn khoảng thời gian dài hơn.',
        )}
        {userData && userData.ListThacNuoc && userData.ListThacNuoc.length > 0 && (
          <Modal
            onOpen={() => {

              this.setState({ thacNuocSelected: userData.ListThacNuoc[0].code });
            }}
            open={this.state.isBusiness}
            size="large"
          >
            <Modal.Content>
              <p>Tài khoản của bạn là một đại lý, vì vậy vui lòng chọn thác nước bạn đã đăng ký với chúng tôi</p>

              <select
                value={this.state.thacNuocSelected || userData.ListThacNuoc[0].code}
                name="listThacNuoc"
                id="water fall"
                onChange={this.onChangeItemWaterFall}
              >
                {userData.ListThacNuoc.map((v) => {
                  return <option value={v.code}>{v.name}</option>;
                })}
              </select>
            </Modal.Content>
            <Modal.Actions>
              <Button
                content="Đóng"
                onClick={() => {
                  this.setState({ isBusiness: false });
                }}
              />
              <Button
                content="Hẹn giờ"
                onClick={() => {
                  this.sendCodeToThacNuoc(this.state.hourSelectedWaterFall, this.state.thacNuocSelected, userData);
                }}
              />
            </Modal.Actions>
          </Modal>
        )}

        <Modal basic open={isLoading} size="large">
          <Modal.Content>
            <img
              id="LoadingImage"
              src={Logo}
              alt="logo"
              style={{
                animation: `spin 10s linear infinite`,
                justifyContent: 'center',
                alignSelf: 'center',
              }}
            />
          </Modal.Content>
        </Modal>
        {/* isBusiness */}
        <div>
          {/* <table style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <tbody>
              <tr> */}
          <div style={{ display: 'flex' }}>
            {filterOption.map((v, i) => {
              // let isShowDichMa = 7;
              // if (
              //   filterOption[6].selected === 2 ||
              //   filterOption[6].selected === 4 ||
              //   filterOption[6].selected === 6 ||
              //   filterOption[6].selected === 8
              // ) {
              //   isShowDichMa = 8;
              // }
              // if (i !== isShowDichMa) {
              return (
                <div style={{ float: 'left', margin: 'auto' }}>
                  <p style={{ textAlign: 'left', margin: '4px' }}>{v.text}</p>
                  <select
                    value={v.selected}
                    onChange={(event) => {
                      switch (v.key) {
                        //thiên bàn
                        case 1:
                          this.onChangeSelected(1, event.target.value);
                          break;
                        //địa bàn
                        case 2:
                          this.onChangeSelected(2, event.target.value);
                          break;
                        //cửu tinh
                        case 3:
                          this.onChangeSelected(3, event.target.value);
                          break;
                        //bát môn
                        case 4:
                          this.onChangeSelected(4, event.target.value);
                          break;
                        //bát thần
                        case 5:
                          this.onChangeSelected(5, event.target.value);
                          break;
                        //phi tinh
                        case 6:
                          this.onChangeSelected(6, event.target.value);
                          break;
                        //Hướng
                        case 7:
                          this.onChangeSelected(7, event.target.value);
                          break;
                        case 8:
                          this.onChangeSelected(8, event.target.value);
                          break;
                        default:
                          break;
                      }
                    }}
                    style={{
                      background: 'white',
                      height: '29px',
                      width: '110px',
                    }}
                  >
                    {v.option.map((e) => {
                      return (
                        <option disabled={e?.disable} key={`${e.name}`} value={e.code}>
                          {e.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              );
              // }
            })}
          </div>
          {/* </tr>
            </tbody>
          </table> */}
          <div style={{ display: 'inline-flex', textAlign: 'center' }}>
            <div>
              <Radio
                onChange={this.handleChangeRadioButton}
                checked={this.state.searchBy === 'SearchHour'}
                value="SearchHour"
                name="radioGroup"
                label="Tìm kiếm Giờ"
              />
            </div>
            <div style={{ width: '30px' }}></div>
            <div>
              <Radio
                onChange={this.handleChangeRadioButton}
                checked={this.state.searchBy === 'SearchDay'}
                value="SearchDay"
                name="radioGroup"
                label="Tìm kiếm Ngày"
              />
            </div>
          </div>
        </div>
        <div style={{ width: '100%', height: '100%' }}>
          <table style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <tbody>
              <tr>
                <td>
                  <DatePicker
                    // disabled={}
                    locale="vi"
                    className="input-date-picker"
                    selected={this.state.fromDate}
                    dateFormat="dd - MM - yyyy"
                    showYearDropdown
                    placeholderText="Từ Ngày"
                    dateFormatCalendar="MMMM"
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    onChange={(value) => this.setState({ fromDate: value })}
                  />
                </td>
                <td>
                  <DatePicker
                    // disabled={}
                    placeholderText="Đến Ngày"
                    locale="vi"
                    className="input-date-picker"
                    selected={this.state.toDate}
                    dateFormat="dd - MM - yyyy"
                    showYearDropdown
                    dateFormatCalendar="MMMM"
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    onChange={(value) => this.setState({ toDate: value })}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <button
          id="header"
          onClick={() => {
            this.onClickSearchResult();
          }}
        >
          Xem kết quả
        </button>
        {this.state.resultSearchDate.length > 0 && (
          <div>
            <h1>CÁC KẾT QUẢ CÁC NGÀY GIỜ PHÙ HỢP VỚI THÔNG TIN CỦA BẠN</h1>
            <div style={{ width: '100%', textAlign: 'initial', padding: '12px', height: '500px', overflowY: 'scroll', marginBottom: '24px' }}>
              <Accordion styled>
                {this.state.resultSearchDate.map((v, i) => {
                  const date = new Date(v.date);
                  const dayWeek = this.convertDayWeekToLetter(date.getDay());
                  const dateInMonth = date.getDate();
                  const month = date.getMonth() + 1;
                  const year = date.getFullYear();
                  const hourShowTitle = v.donGiap12Hour.map((v) => ' ' + v.CanChi);

                  const stringDate = `${dayWeek}, Ngày ${dateInMonth} Tháng ${month} Năm ${year}, Giờ: ${hourShowTitle}`;
                  const dataSendToServer = `${dateInMonth}/${month}/${year}`;
                  if (this.state.searchBy === 'SearchHour') {
                    return (
                      <div>
                        <Accordion.Title
                          active={activeResultState === i}
                          index={i}
                          onClick={() => {
                            if (activeResultState === i) {
                              this.setState({ activeResultState: -1 });
                            } else {
                              this.setState({ activeResultState: i });
                            }
                          }}
                        >
                          <Icon name="dropdown" />
                          {stringDate}
                        </Accordion.Title>
                        {/* <Accordion.Content active={activeResultState === i}> */}
                        <Collapse isOpened={activeResultState === i}>
                          <h2 style={{ textAlign: 'center' }}>{stringDate}</h2>
                          {v.donGiap12Hour.map((hourResult, index) => {
                            return (
                              <div id={`ky-mon-don-giap-${index}`}>
                                <h3 style={{ textAlign: 'center' }}>{`Giờ ${hourResult.CanChi}`}</h3>
                                <div style={{ textAlign: 'center' }}>
                                  <PhiTinhDetail data1080cuc={hourResult} searchKyMonDonGiap></PhiTinhDetail>
                                </div>
                                <div style={{ justifyContent: 'center', display: 'flex', marginTop: '12px', marginBottom: '10px' }}>
                                  <Button content="Hẹn giờ thác nước" onClick={() => this.onClickSetMayBom(dataSendToServer, hourResult)} />
                                  <Button
                                    content="Thêm giờ vào lịch google"
                                    onClick={() => {
                                      const hourtemp = convertHour(date.getHours());
                                      const titleEvent = `Nội dung sự kiện`;
                                      let fromTime = new Date(date.setHours(hourtemp.value - 1, 0));
                                      let toTime = new Date(date.setHours(hourtemp.value + 1, 0));

                                      if (fromTime.getHours() === 23) {
                                        toTime = new Date(date.setDate(date.getDate() + 1));
                                      }
                                      window.open(
                                        `https://calendar.google.com/calendar/u/0/r/eventedit?text=${titleEvent}&dates=${fromTime
                                          .toISOString()
                                          .replace(/[-:.]/g, '')}/${toTime
                                          .toISOString()
                                          .replace(
                                            /[-:.]/g,
                                            '',
                                          )}&details=Thông+tin+chi+tiết+tại:+http://huyenkhongtamnguyen.com&location=Ho+Chi+Minh+City,+Vietnam&sf=true&output=xml`,
                                      );
                                    }}
                                  />
                                </div>
                                <div style={{ textAlign: 'center' }}>-------------------</div>
                              </div>
                            );
                          })}
                        </Collapse>
                        {/* </Accordion.Content> */}
                      </div>
                      // <List.Item>
                      //   <Modal
                      //     closeIcon
                      //     key={`${v.date}`}
                      //     trigger={
                      //       <div style={{ display: 'flex' }}>
                      //         <h5 style={{ marginTop: 'auto', marginBottom: 'auto' }}>{stringDate}</h5>
                      //         <Button style={{ marginLeft: 'auto' }}>Xem chi tiết</Button>
                      //       </div>
                      //     }
                      //   >
                      //     <Modal.Content>
                      //       <div>
                      //         <h2 style={{ textAlign: 'center' }}>{stringDate}</h2>
                      //         {v.donGiap12Hour.map((hourResult, index) => {
                      //           return (
                      //             <div id={`ky-mon-don-giap-${index}`}>
                      //               <h3 style={{ textAlign: 'center' }}>{`Giờ ${hourResult.CanChi}`}</h3>
                      //               <div style={{ textAlign: 'center' }}>
                      //                 <PhiTinhDetail data1080cuc={hourResult} searchKyMonDonGiap></PhiTinhDetail>
                      //               </div>
                      //               <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                      //                 <Button content="Hẹn giờ thác nước" onClick={() => this.onClickSetMayBom(dataSendToServer, hourResult)} />
                      //               </div>
                      //               <div style={{ textAlign: 'center' }}>-------------------</div>
                      //             </div>
                      //           );
                      //         })}
                      //       </div>
                      //       <Modal.Actions className="center-send-mail">
                      //         <Button content="Thông báo qua mail" onClick={() => this.onClickSendMail(v, stringDate, dataSendToServer)} />
                      //       </Modal.Actions>
                      //     </Modal.Content>
                      //   </Modal>
                      // </List.Item>
                    );
                  } else {
                    return (
                      <div>
                        <Accordion.Title active={activeResultState === i} index={i} onClick={() => this.setState({ activeResultState: i })}>
                          <Icon name="dropdown" />
                          {stringDate}
                        </Accordion.Title>
                        {/* <Accordion.Content active={activeResultState === i}> */}
                        <Collapse isOpened={activeResultState === i}>
                          <div>
                            <h2 style={{ textAlign: 'center' }}>{stringDate}</h2>
                            <div id={`ky-mon-don-giap-${i}`}>
                              <h3 style={{ textAlign: 'center' }}>{`Ngày ${v.kyMonDonGiapNgay.CanChi}`}</h3>
                              <div style={{ textAlign: 'center' }}>
                                <PhiTinhDetail data1080cuc={v.kyMonDonGiapNgay} searchKyMonDonGiap></PhiTinhDetail>
                              </div>
                            </div>
                          </div>
                          <div className="center-send-mail" style={{ display: 'flex' }}>
                            <Modal.Actions className="center-send-mail" style={{ margin: 'auto' }}>
                              <Button content="Thông báo qua mail" onClick={() => this.onClickSendMail(v, stringDate, dataSendToServer)} />
                            </Modal.Actions>
                          </div>
                        </Collapse>
                        {/* </Accordion.Content> */}
                      </div>
                      // <List.Item>
                      //   <Modal
                      //     closeIcon
                      //     key={`${v.date}`}
                      //     trigger={
                      //       <div style={{ display: 'flex' }}>
                      //         <h5 style={{ marginTop: 'auto', marginBottom: 'auto' }}>{stringDate}</h5>
                      //         <Button style={{ marginLeft: 'auto' }}>Xem chi tiết</Button>
                      //       </div>
                      //     }
                      //   >
                      //     <Modal.Content>
                      //       <div>
                      //         <h2 style={{ textAlign: 'center' }}>{stringDate}</h2>
                      //         <div id={`ky-mon-don-giap-${i}`}>
                      //           <h3 style={{ textAlign: 'center' }}>{`Ngày ${v.kyMonDonGiapNgay.CanChi}`}</h3>
                      //           <div style={{ textAlign: 'center' }}>
                      //             <PhiTinhDetail data1080cuc={v.kyMonDonGiapNgay} searchKyMonDonGiap></PhiTinhDetail>
                      //           </div>
                      //         </div>
                      //       </div>
                      //       <div className="center-send-mail" style={{ display: 'flex' }}>
                      //         <Modal.Actions className="center-send-mail">
                      //           <Button content="Thông báo qua mail" onClick={() => this.onClickSendMail(v, stringDate, dataSendToServer)} />
                      //         </Modal.Actions>
                      //       </div>
                      //     </Modal.Content>
                      //   </Modal>
                      // </List.Item>
                    );
                  }
                })}
              </Accordion>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SearchKyMonDonGiap;
