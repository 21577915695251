import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Button, Modal } from "semantic-ui-react";
import "../App.css";
import explain from "../image/explain.png";

import Const from "../Const";
import PhiTinh from "./PhiTinh";
import SearchTest from "./SearchTest";
export default function CuuCungPhiTinh(props) {
  const getHour = new Date();

  return (
    <table>
      <tbody>
        <tr>
          <td>
            <table
              style={{
                backgroundColor: "#1667AF",
              }}
            >
              <tbody>
                <tr>
                  <td style={{ color: "#fff" }}>ĐN</td>
                  <td style={{ color: "#fff" }}>N</td>
                  <td style={{ color: "#fff" }}>TN</td>
                </tr>
                <tr>
                  <td style={{ color: "#fff" }}>Đ</td>
                  <td
                    style={{
                      padding: "5px",
                      backgroundColor: "white",
                    }}
                  >
                    {/* cửu cung phi tinh lớn */}
                    <PhiTinh
                      starADay={props?.starADay}
                      starMonth={props?.starMonth}
                      dateLunar={props.dateLunar}
                      chiHour={getHour.getHours()}
                      chiDay={props.chiDay}
                      isSauDongChi={props.isSauDongChi}
                    />
                  </td>
                  <td style={{ color: "#fff" }}>T</td>
                </tr>
                <tr>
                  <td style={{ color: "#fff" }}>ĐB</td>
                  <td style={{ color: "#fff" }}>B</td>
                  <td style={{ color: "#fff" }}>TB</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <img src={explain} alt="logo" width="70%" height="60px" />
          </td>
        </tr>
      </tbody>
    </table>
  );
}
