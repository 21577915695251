import vi from "date-fns/locale/vi";
import * as firebase from "firebase";
import md5 from "md5";
import React from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Accordion,
  Button,
  Dropdown,
  Icon,
  Image,
  Input,
  List,
  Modal,
  Select,
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import Logo from "../logo.png";
import * as XLSX from "xlsx";
import "../App.css";
import arrow from "../image/arrow2.png";
import arrow2 from "../image/arrow3.png";
import five from "../image/five.png";
import LaBan from "../image/laban.png";
import LaBanV2 from "../image/laban_v2.png";
import nguHoang from "../image/nguHoangCordi.png";
import tamSat from "../image/tamSat.png";
import PhiTinhDetail from "../components/PhiTinhDetail";
import emailjs from "emailjs-com";
import _ from "lodash";
import Const from "../Const";
import globalUtils from "../globalUntils.js";
import moment from "moment";
import {
  convertHour,
  DayToCanChi,
  filterHaDo,
  filterHopNgu,
  filterHopSo,
  filterHopThap,
  filterHopThapNgu,
  filterSoKetHopDatBiet,
  getPhiTinhMainNumber,
  getQue,
  getQueLast,
  HourToCanChi,
  MonthToCanChi,
  phiTinhThang,
  YearToCan,
  YearToChi,
} from "../convert";
import TableQue from "../components/TableQue";
import axios from "axios";
import html2canvas from "html2canvas";
import { Collapse } from "react-collapse";

const mdfive = md5("HuyenKhongTamNguyenFengShuiWebSiteHitekTuongMinh");
registerLocale("vi", vi);
const optionDropdown = _.map(Const.QueSortConGiap, (state, index) => ({
  key: `${state.stt}-${index}`,
  text: `${state.queViet} ${state.huyenKhongNguHanh}/${state.huyenKhongQuaiVan}`,
  value: index,
}));
const rightPlusToCompass = 23;

class SearchQuai extends React.PureComponent {
  constructor(props) {
    super(props);
    this.tietKhiDate = [];
    this.state = {
      activeResultHour: 0,
      modalMissingThacNuoc: false,
      listNamSinhAndToa: [],
      modalSentSuccess: false,
      modalNoResultHopNamSinh: false,
      modalNoResultHopToa: false,
      queNamSinh: null,
      queToa: null,
      listHopNamSinh: [],
      listHopToa: [],
      listFilterQuaiKhi: [],
      namSinhNumber: -1,
      toaNumber: -1,
      modalDetailDay: false,
      resultSearchDate: [],
      modalNoResult: false,
      fromDate: null,
      toDate: null,
      modalEmptySelectedQuai: false,
      modalEmptySelectedDateFromTo: false,
      isLoading: false,
      optionSelectQuaiKhi: [
        { value: -1, key: "khongChon", show: "Không Chọn" },
        { value: 0, key: "hopSo", show: "Cùng Quái" },
        { value: 1, key: "hopThap", show: "Hợp Thập Quái" },
        { value: 2, key: "haDo", show: "Hà Đồ" },
        { value: 3, key: "hopNgu", show: "Hợp Ngũ Quái" },
        { value: 4, key: "hopThapNgu", show: "Hợp Thập Ngũ Quái" },
        // { value: 5, key: 'soKetHopDatBiet', show: 'Số Kết Hợp Đặc Biệt' },
      ],
      optionSelectQuaiVan: [
        { value: -1, key: "khongChon", show: "Không Chọn" },
        { value: 0, key: "hopSo", show: "Cùng Vận" },
        { value: 1, key: "hopThap", show: "Hợp Thập Vận" },
        { value: 2, key: "haDo", show: "Hà Đồ" },
        { value: 3, key: "hopNgu", show: "Hợp Ngũ Vận" },
        { value: 4, key: "hopThapNgu", show: "Hợp Thập Ngũ Vận" },
        { value: 5, key: "soKetHopDatBiet", show: "Số Kết Hợp Đặc Biệt" },
      ],
      listResult: [],

      selectedFilterQuaiKhi: -1,
      selectedFilterQuaiVan: -1,
    };
    this.excelLichVanNien = this.props.lichVanNien;
    this.dataAmDon = this.props.dataAmDon;
    this.dataDuongDon = this.props.dataDuongDon;
  }

  getTietKhiAllYear(dateTimeYear) {
    const distance = dateTimeYear?.getFullYear() - 1932;
    const thangLe = distance * 42 + 36;
    const thangChan = distance * 42 + 38;
    let ngayGioDauThang1 = this.excelLichVanNien[thangLe][1]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang3 = this.excelLichVanNien[thangLe][5]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang5 = this.excelLichVanNien[thangLe][9]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang7 = this.excelLichVanNien[thangLe][13]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang9 = this.excelLichVanNien[thangLe][17]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang11 = this.excelLichVanNien[thangLe][21]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang2 = this.excelLichVanNien[thangChan][2]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang4 = this.excelLichVanNien[thangChan][6]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang6 = this.excelLichVanNien[thangChan][10]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang8 = this.excelLichVanNien[thangChan][14]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang10 = this.excelLichVanNien[thangChan][18]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang12 = this.excelLichVanNien[thangChan][22]
      .split(" - ")[0]
      .split(" ");

    const ngayDauThangMot = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang1[0].split("/")[1]) - 1,
      Number(ngayGioDauThang1[0].split("/")[0]),
      Number(ngayGioDauThang1[1].split(":")[0]),
      Number(ngayGioDauThang1[1].split(":")[1])
    );
    const ngayDauThangHai = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang2[0].split("/")[1]) - 1,
      Number(ngayGioDauThang2[0].split("/")[0]),
      Number(ngayGioDauThang2[1].split(":")[0]),
      Number(ngayGioDauThang2[1].split(":")[1])
    );
    const ngayDauThangBa = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang3[0].split("/")[1]) - 1,
      Number(ngayGioDauThang3[0].split("/")[0]),
      Number(ngayGioDauThang3[1].split(":")[0]),
      Number(ngayGioDauThang3[1].split(":")[1])
    );
    const ngayDauThangTu = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang4[0].split("/")[1]) - 1,
      Number(ngayGioDauThang4[0].split("/")[0]),
      Number(ngayGioDauThang4[1].split(":")[0]),
      Number(ngayGioDauThang4[1].split(":")[1])
    );
    const ngayDauThangNam = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang5[0].split("/")[1]) - 1,
      Number(ngayGioDauThang5[0].split("/")[0]),
      Number(ngayGioDauThang5[1].split(":")[0]),
      Number(ngayGioDauThang5[1].split(":")[1])
    );
    const ngayDauThangSau = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang6[0].split("/")[1]) - 1,
      Number(ngayGioDauThang6[0].split("/")[0]),
      Number(ngayGioDauThang6[1].split(":")[0]),
      Number(ngayGioDauThang6[1].split(":")[1])
    );
    const ngayDauThangBay = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang7[0].split("/")[1]) - 1,
      Number(ngayGioDauThang7[0].split("/")[0]),
      Number(ngayGioDauThang7[1].split(":")[0]),
      Number(ngayGioDauThang7[1].split(":")[1])
    );
    const ngayDauThangTam = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang8[0].split("/")[1]) - 1,
      Number(ngayGioDauThang8[0].split("/")[0]),
      Number(ngayGioDauThang8[1].split(":")[0]),
      Number(ngayGioDauThang8[1].split(":")[1])
    );
    const ngayDauThangChin = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang9[0].split("/")[1]) - 1,
      Number(ngayGioDauThang9[0].split("/")[0]),
      Number(ngayGioDauThang9[1].split(":")[0]),
      Number(ngayGioDauThang9[1].split(":")[1])
    );
    const ngayDauThangMuoi = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang10[0].split("/")[1]) - 1,
      Number(ngayGioDauThang10[0].split("/")[0]),
      Number(ngayGioDauThang10[1].split(":")[0]),
      Number(ngayGioDauThang10[1].split(":")[1])
    );
    const ngayDauThangMuoiMot = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang11[0].split("/")[1]) - 1,
      Number(ngayGioDauThang11[0].split("/")[0]),
      Number(ngayGioDauThang11[1].split(":")[0]),
      Number(ngayGioDauThang11[1].split(":")[1])
    );
    const ngayDauThangMuoiHai = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang12[0].split("/")[1]) - 1,
      Number(ngayGioDauThang12[0].split("/")[0]),
      Number(ngayGioDauThang12[1].split(":")[0]),
      Number(ngayGioDauThang12[1].split(":")[1])
    );
    let lichTietKhi = [];
    lichTietKhi.push(
      ngayDauThangMot,
      ngayDauThangHai,
      ngayDauThangBa,
      ngayDauThangTu,
      ngayDauThangNam,
      ngayDauThangSau,
      ngayDauThangBay,
      ngayDauThangTam,
      ngayDauThangChin,
      ngayDauThangMuoi,
      ngayDauThangMuoiMot,
      ngayDauThangMuoiHai
    );
    this.tietKhiDate = lichTietKhi;
  }
  getCorrectTietKhi(solarDate) {
    var day = solarDate.getDate();
    var month = solarDate.getMonth();
    var year =
      solarDate < this.tietKhiDate[1]
        ? solarDate.getFullYear() - 1
        : solarDate.getFullYear();

    switch (solarDate.getMonth()) {
      // tháng 1 dương lịch nếu sau ngày đầu tháng 1 thì là tháng 12 năm ngoái
      //input ngày 1 tháng 1 2020
      // out put ngày 1 tháng 12 2019
      case 0:
        if (solarDate < this.tietKhiDate[0]) {
          month = 11;
        }
        break;
      case 1:
        // trước ngày lập xuân thì là tháng 12 của năm ngoái, ngược lại là tháng 1 năm sau
        if (solarDate < this.tietKhiDate[1]) {
          month = month - 1;
        }
        break;
      case 2:
        if (solarDate < this.tietKhiDate[2]) {
          month = month - 1;
        }
        break;
      case 3:
        if (solarDate < this.tietKhiDate[3]) {
          month = month - 1;
        }
        break;
      case 4:
        if (solarDate < this.tietKhiDate[4]) {
          month = month - 1;
        }
        break;
      case 5:
        if (solarDate < this.tietKhiDate[5]) {
          month = month - 1;
        }
        break;
      case 6:
        if (solarDate < this.tietKhiDate[6]) {
          month = month - 1;
        }
        break;
      case 7:
        if (solarDate < this.tietKhiDate[7]) {
          month = month - 1;
        }
        break;
      case 8:
        if (solarDate < this.tietKhiDate[8]) {
          month = month - 1;
        }
        break;
      case 9:
        if (solarDate < this.tietKhiDate[9]) {
          month = month - 1;
        }
        break;
      case 10:
        if (solarDate < this.tietKhiDate[10]) {
          month = month - 1;
        }
        break;
      case 11:
        if (solarDate < this.tietKhiDate[11]) {
          month = month - 1;
        }
        break;
      default:
        break;
    }
    return new Date(year, month, day);
  }
  getAmDonOrDuongDon(date) {
    //khoảng cách từ năm chọn đến năm 1932
    const distance = date?.getFullYear() - 1932;
    //lấy dòng(ngày của năm) =  ngày đã chọn (+1 bỏ qua dòng đầu tiên của file excel) *42 (42 là số dòng data của 1 năm)
    const getDateInYear = date?.getDate() + 1 + distance * 42;
    // lấy tháng = tháng đã chọn (+1 bỏ qua cột đầu tiên "ngày" vì mỗi tháng bị lặp 2 lần nên sẽ nhân cho 2 )
    const getMonthInyear = (date?.getMonth() + 1) * 2;
    //lấy giá trị của tọa độ của ngày đã chọn trong file excel
    return this.excelLichVanNien[getDateInYear][getMonthInyear];
  }
  get1080cuc(amDonDuongDon, canChiHour, phiTinh) {
    if (typeof amDonDuongDon === "number") {
      const dataCuc = this.dataDuongDon.filter(
        (v) => v.sheet === `Duong Don ${amDonDuongDon}`
      );
      return {
        ...dataCuc.find(
          (v) => v.CanChi === `${canChiHour[0]} ${canChiHour[1]}`
        ),
        mainHourPhiTinh: phiTinh,
      };
    } else {
      const dataCuc = this.dataAmDon.filter(
        (v) => v.sheet === `Am Don ${amDonDuongDon.charAt(0)}`
      );
      return {
        ...dataCuc.find(
          (v) => v.CanChi === `${canChiHour[0]} ${canChiHour[1]}`
        ),
        mainHourPhiTinh: phiTinh,
      };
    }
  }
  getIsSauDongChi(dateTime) {
    const tempDateTime = new Date(dateTime);
    //file excel bắt đầu từ năm 1932
    const distance = tempDateTime?.getFullYear() - 1932;

    //lấy tọa độ dòng trong excel
    const rowNumber = distance * 42 + 39;
    //chia nho chuoi lay ngay thang cua dong chi va ha chi
    const ngayDongChi = this.excelLichVanNien[rowNumber][21]
      .split("-")[0]
      .split(" ")[0]
      .split("/");
    const stempDongChi = new Date(
      tempDateTime?.getFullYear(),
      ngayDongChi[1] - 1,
      ngayDongChi[0]
    );

    const ngayHaChi = this.excelLichVanNien[rowNumber][9]
      .split("-")[0]
      .split(" ")[0]
      .split("/");
    const stempHaChi = new Date(
      tempDateTime?.getFullYear(),
      ngayHaChi[1] - 1,
      ngayHaChi[0]
    );

    // từ đầu năm đến hạ chí là sau đông chí ==> true
    // từ hạ chí đến ngày đông chí là trước đông chí ==> false
    // ngày được chọn lớn hơn ngày hạ chí là trước đông chí ==> false
    // ngày được chọn nhỏ hơn ngày hạ chí là sau đông chí ==> true

    //ngày được chọn > ngày đông chí ==>true
    //ngày được chọn < ngày hạ chí ==>true
    //ngày được chọn > ngày hạ chí ==>false
    if (
      tempDateTime.getTime() > stempDongChi.getTime() ||
      tempDateTime.getTime() < stempHaChi.getTime()
    ) {
      return true;
    } else {
      return false;
    }
    // if (tempDateTime.getTime() > stempHaChi.getTime()) {
    //   return false;
    // }
    // else {
    //   return true;
    // }
  }
  get12HourADay(canChiDay, AmDonDuongDon, sauDongchi) {
    const canChiDayArr = canChiDay.split(" ");
    const chiDay = Const.ChiArr.find((v) => v.name === canChiDayArr[1]);

    let result = [];
    for (let index = 0; index <= 23; index++) {
      if (index % 2 === 1) {
        let CanChiHour = HourToCanChi(canChiDayArr[0], index);
        const queGio = getQue(
          CanChiHour.name.split(" ")[0],
          CanChiHour.name.split(" ")[1]
        );

        result.push(queGio);
      }
    }
    return result;
  }

  renderModalErrorPressCode = (nameModal, actionsCloseModal, content) => {
    return (
      <Modal open={nameModal} size="small">
        <Modal.Content>
          <p>{content}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Đóng" onClick={actionsCloseModal} />
        </Modal.Actions>
      </Modal>
    );
  };
  convertDayWeekToLetter(NumberDayWeek) {
    switch (NumberDayWeek) {
      case 0:
        return "Chủ Nhật";
      case 1:
        return "Thứ Hai";

      case 2:
        return "Thứ Ba";
      case 3:
        return "Thứ Tư";
      case 4:
        return "Thứ Năm";
      case 5:
        return "Thứ Sáu";
      case 6:
        return "Thứ Bảy";

      default:
        break;
    }
  }

  getIndexOfAQueByStt(stt) {
    return Const.Que.findIndex((e) => e.stt === stt);
  }

  namSinhChange = (e) => {
    var getValue = document.getElementById("namSinhGiaChu");
    const value = getValue.value;
    if (value.length === 4) {
      const yearOfBirth = new Date(value, 1, 2);
      const canYearBorn = YearToCan(value);
      const chiYearBorn = YearToChi(yearOfBirth.getYear());
      const canChiOfBirth = canYearBorn.name + " " + chiYearBorn.name;

      const result = Const.Que.filter((v) => v.queViet === canChiOfBirth);
      const index = this.getIndexOfAQueByStt(result[0].stt);

      this.setState({ namSinhNumber: index });
    }
  };

  onClickSearchResult() {
    if (
      this.state.fromDate === null ||
      this.state.toDate === null ||
      this.state.toDate < this.state.fromDate
    ) {
      this.setState({ modalEmptySelectedDateFromTo: true });
    } else {
      this.searchResult();
    }
  }
  async searchAllPeriodSelected() {
    //2 tham số truyền vào 1 là from date 2 là to date

    //đếm số vòng lặp
    var a = moment(this.state.fromDate);
    var b = moment(this.state.toDate);
    const countDay = b.diff(a, "days") + 1;
    this.getTietKhiAllYear(this.state.fromDate);

    let allResultPeriodTime = [];
    for (let index = 0; index < countDay; index++) {
      const momentDate = new Date(
        moment(this.state.fromDate).add(index, "days").format("LLLL")
      );
      if (
        momentDate.getFullYear() >
        Number(moment(momentDate).subtract(1, "days").format("YYYY"))
      ) {
        this.getTietKhiAllYear(momentDate);
      }

      const getDataAmDonDuongDon = this.getAmDonOrDuongDon(momentDate);
      const dateTietKhi = this.getCorrectTietKhi(momentDate);
      const canYear = YearToCan(dateTietKhi.getYear());
      const chiYear = YearToChi(dateTietKhi.getYear());
      const canChiMonth = MonthToCanChi(canYear, dateTietKhi.getMonth());
      const canChiDay = await DayToCanChi(momentDate.toDateString());
      const isSauDongChi = this.getIsSauDongChi(dateTietKhi);
      const queNam = getQue(canYear.name, chiYear.name);
      const queThang = getQue(
        canChiMonth.name.split(" ")[0],
        canChiMonth.name.split(" ")[1]
      );
      const queNgay = getQue(
        canChiDay.name.split(" ")[0],
        canChiDay.name.split(" ")[1]
      );
      let realDate = new Date(dateTietKhi);
      const starYear = this.phiTinhNam(realDate);
      const huongNguHoangYear = this.convertSideToCompass(starYear);
      const starMonth = phiTinhThang(realDate);
      const huongNguHoangMonth = this.convertSideToCompass(starMonth);

      if (canChiDay) {
        const que12Hour = this.get12HourADay(
          canChiDay.name,
          getDataAmDonDuongDon,
          isSauDongChi
        );
        allResultPeriodTime.push({
          date: moment(momentDate).format("LLLL"),
          chiYear: chiYear,
          huongNguHoangYear,
          huongNguHoangMonth,
          queNam: queNam,
          queThang: queThang,
          queNgay: queNgay,
          que12Gio: que12Hour,
        });
      }
    }
    return allResultPeriodTime;
  }

  async filterQuai(allDataPeriod) {
    const {
      selectedFilterQuaiKhi,
      selectedFilterQuaiVan,
      optionSelectQuaiKhi,
      optionSelectQuaiVan,
    } = this.state;

    let finalResult = allDataPeriod;
    if (
      finalResult &&
      selectedFilterQuaiKhi === -1 &&
      selectedFilterQuaiVan === -1
    ) {
      this.setState({ modalEmptySelectedQuai: true });
    } else {
      const quaiKhi = optionSelectQuaiKhi[selectedFilterQuaiKhi + 1];
      const quaiVan = optionSelectQuaiVan[selectedFilterQuaiVan + 1];
      if (selectedFilterQuaiKhi !== -1) {
        switch (quaiKhi.value) {
          case 0: //Hợp Số
            finalResult = await filterHopSo(finalResult, true);

            break;
          case 1: //Hợp Thập
            finalResult = await filterHopThap(finalResult, true);

            break;
          case 2: //hà đồ
            finalResult = await filterHaDo(finalResult, true);

            break;
          case 3: //Hợp Ngũ
            finalResult = await filterHopNgu(finalResult, true);

            break;
          case 4: //Hợp Thập ngũ
            finalResult = await filterHopThapNgu(finalResult, true);

            break;
          case 5: //số kết hợp đặc biệt
            finalResult = await filterSoKetHopDatBiet(finalResult, true);

            break;
          default:
            break;
        }
      }
      if (selectedFilterQuaiVan !== -1) {
        switch (quaiVan.value) {
          case 0: //Hợp Số
            finalResult = await filterHopSo(finalResult, false);

            break;
          case 1: //Hợp Thập
            finalResult = await filterHopThap(finalResult, false);

            break;
          case 2: //hà đồ
            finalResult = await filterHaDo(finalResult, false);

            break;
          case 3: //Hợp Ngũ
            finalResult = await filterHopNgu(finalResult, false);

            break;
          case 4: //Hợp Thập ngũ
            finalResult = await filterHopThapNgu(finalResult, false);

            break;
          case 5: //số kết hợp đặc biệt
            finalResult = await filterSoKetHopDatBiet(finalResult, false);

            break;
          default:
            break;
        }
      }
      finalResult = finalResult?.filter((v) => v !== undefined);
      return finalResult;
    }
  }
  filterHopSoOption(list, isQuaiKhi, optionQue) {
    let listInPeriod = list;
    //filter giờ
    const newList = listInPeriod.map((v) => {
      const queNgayQuai = isQuaiKhi
        ? v?.queNgay.huyenKhongNguHanh
        : v?.queNgay.huyenKhongQuaiVan;
      const optionItem = isQuaiKhi
        ? optionQue.huyenKhongNguHanh
        : optionQue.huyenKhongQuaiVan;
      if (optionItem === queNgayQuai) {
        return v;
      }
    });

    return newList;
  }
  filterHopThapOption(list, isQuaiKhi, optionQue) {
    let listInPeriod = list;
    //filter giờ
    const newList = listInPeriod.map((v) => {
      if (v) {
        const queNgayQuai = isQuaiKhi
          ? v.queNgay.huyenKhongNguHanh
          : v.queNgay.huyenKhongQuaiVan;
        const optionItem = isQuaiKhi
          ? optionQue.huyenKhongNguHanh
          : optionQue.huyenKhongQuaiVan;
        if (optionItem + queNgayQuai === 10) {
          return v;
        } else {
          return;
        }
      }
    });
    return newList;
  }
  filterHaDoOption(list, isQuaiKhi, optionQue) {
    let listInPeriod = list;
    //filter giờ
    const newList = listInPeriod.map((v) => {
      if (v) {
        const queNgayQuai = isQuaiKhi
          ? v.queNgay.huyenKhongNguHanh
          : v.queNgay.huyenKhongQuaiVan;
        const optionItem = isQuaiKhi
          ? optionQue.huyenKhongNguHanh
          : optionQue.huyenKhongQuaiVan;
        if (
          (optionItem === 1 && queNgayQuai === 6) ||
          (optionItem === 6 && queNgayQuai === 1) ||
          (optionItem === 2 && queNgayQuai === 7) ||
          (optionItem === 7 && queNgayQuai === 2) ||
          (optionItem === 3 && queNgayQuai === 8) ||
          (optionItem === 8 && queNgayQuai === 3) ||
          (optionItem === 4 && queNgayQuai === 9) ||
          (optionItem === 9 && queNgayQuai === 4)
        ) {
          return v;
        } else {
          return;
        }
      }
    });
    return newList;
  }
  filterHopNguOption(list, isQuaiKhi, optionQue) {
    let listInPeriod = list;
    //filter giờ
    const newList = listInPeriod.map((v) => {
      if (v) {
        const queNgayQuai = isQuaiKhi
          ? v.queNgay.huyenKhongNguHanh
          : v.queNgay.huyenKhongQuaiVan;
        const optionItem = isQuaiKhi
          ? optionQue.huyenKhongNguHanh
          : optionQue.huyenKhongQuaiVan;
        if (optionItem + queNgayQuai === 5) {
          return v;
        } else {
          return;
        }
      }
    });
    return newList;
  }
  filterHopThapNguOption(list, isQuaiKhi, optionQue) {
    let listInPeriod = list;
    //filter giờ
    const newList = listInPeriod.map((v) => {
      if (v) {
        const queNgayQuai = isQuaiKhi
          ? v.queNgay.huyenKhongNguHanh
          : v.queNgay.huyenKhongQuaiVan;
        const optionItem = isQuaiKhi
          ? optionQue.huyenKhongNguHanh
          : optionQue.huyenKhongQuaiVan;
        if (optionItem + queNgayQuai === 15) {
          return v;
        } else {
          return;
        }
      }
    });
    return newList;
  }
  filterSoKetHopDatBietOption(list, isQuaiKhi, optionQue) {
    let listInPeriod = list;
    //filter giờ
    const newList = listInPeriod.map((v) => {
      if (v) {
        const queNgayQuai = isQuaiKhi
          ? v.queNgay.huyenKhongNguHanh
          : v.queNgay.huyenKhongQuaiVan;
        const optionItem = isQuaiKhi
          ? optionQue.huyenKhongNguHanh
          : optionQue.huyenKhongQuaiVan;
        if (
          (optionItem === 1 && queNgayQuai === 3) ||
          (optionItem === 3 && queNgayQuai === 1) ||
          (optionItem === 2 && queNgayQuai === 4) ||
          (optionItem === 4 && queNgayQuai === 2) ||
          (optionItem === 6 && queNgayQuai === 8) ||
          (optionItem === 8 && queNgayQuai === 6) ||
          (optionItem === 7 && queNgayQuai === 9) ||
          (optionItem === 9 && queNgayQuai === 7)
        ) {
          return v;
        } else {
          return;
        }
      }
    });
    return newList;
  }
  async filterOption(list, optionObj) {
    const {
      selectedFilterQuaiKhi,
      selectedFilterQuaiVan,
      optionSelectQuaiKhi,
      optionSelectQuaiVan,
    } = this.state;
    let finalResult = list;
    const queToa = optionObj;
    const quaiKhi = optionSelectQuaiKhi[selectedFilterQuaiKhi + 1];
    const quaiVan = optionSelectQuaiVan[selectedFilterQuaiVan + 1];
    if (selectedFilterQuaiKhi !== -1) {
      switch (quaiKhi.value) {
        case 0: //Hợp Số
          finalResult = await this.filterHopSoOption(finalResult, true, queToa);
          break;
        case 1: //Hợp Thập
          finalResult = await this.filterHopThapOption(
            finalResult,
            true,
            queToa
          );
          break;
        case 2: //hà đồ
          finalResult = await this.filterHaDoOption(finalResult, true, queToa);
          break;
        case 3: //Hợp Ngũ
          finalResult = await this.filterHopNguOption(
            finalResult,
            true,
            queToa
          );
          break;
        case 4: //Hợp Thập ngũ
          finalResult = await this.filterHopThapNguOption(
            finalResult,
            true,
            queToa
          );
          break;
        case 5: //số kết hợp đặc biệt
          finalResult = await this.filterSoKetHopDatBietOption(
            finalResult,
            true,
            queToa
          );
          break;
        default:
          break;
      }
    }

    if (selectedFilterQuaiVan !== -1) {
      switch (quaiVan.value) {
        case 0: //Hợp Số
          finalResult = await this.filterHopSoOption(
            finalResult,
            false,
            queToa
          );
          break;
        case 1: //Hợp Thập
          finalResult = await this.filterHopThapOption(
            finalResult,
            false,
            queToa
          );

          break;
        case 2: //hà đồ
          finalResult = await this.filterHaDoOption(finalResult, false, queToa);

          break;
        case 3: //Hợp Ngũ
          finalResult = await this.filterHopNguOption(
            finalResult,
            false,
            queToa
          );

          break;
        case 4: //Hợp Thập ngũ
          finalResult = await this.filterHopThapNguOption(
            finalResult,
            false,
            queToa
          );

          break;
        case 5: //số kết hợp đặc biệt
          finalResult = await this.filterSoKetHopDatBietOption(
            finalResult,
            false,
            queToa
          );

          break;
        default:
          break;
      }
    }

    finalResult = finalResult?.filter((v) => v !== undefined);
    return finalResult;
  }
  shouldDuplicateQue(thienChi, diaChi) {
    if (thienChi === Const.Can.Giap.name && diaChi === Const.Chi.Ngo.name)
      return true;
    if (thienChi === Const.Can.Giap.name && diaChi === Const.Chi.Ty.name)
      return true;
    if (thienChi === Const.Can.Canh.name && diaChi === Const.Chi.Than.name)
      return true;
    if (thienChi === Const.Can.Canh.name && diaChi === Const.Chi.Dan.name)
      return true;
    return false;
  }
  getDuplicateQue(initialQque, thienChi, diaChi) {
    let que = getQueLast(thienChi, diaChi);
    if (!initialQque || !que) {
      return null;
    }

    if (
      initialQque.huyenKhongNguHanh === que.huyenKhongNguHanh &&
      initialQque.huyenKhongQuaiVan === que.huyenKhongQuaiVan
    ) {
      que = getQue(thienChi, diaChi);
    }
    return que;
  }
  async searchResult() {
    //lấy data từng ngày trong khoảng thời gian được chọn trước đó
    let listHopToa = [];
    let listHopNamSinh = [];
    const resultPeriod = await this.searchAllPeriodSelected();
   
    //lọc lại data với điều kiện chọn option filter không chọn tọa và năm sinh
    // phần này đã lọc 1 và 2 option rồi
    const listFilterQuaiKhi = await this.filterQuai(resultPeriod);
    // nếu chọn tọa: tọa khác -1 thì sẽ có list ngày hợp tọa
    if (this.state.toaNumber !== -1) {
      const queToa = Const.QueSortConGiap[this.state.toaNumber];
      listHopToa = await this.filterOption(resultPeriod, queToa);
      this.setState({ queToa });
    }

    //option năm sinh
    //các trường hợp gặp phải: 1 là gặp 1 quẻ, 2 là gặp 2 quẻ
    if (this.state.namSinhNumber !== -1) {
      let tempFinalResult1 = [];
      let tempFinalResult2 = [];
      let queNamSinh = [Const.Que[this.state.namSinhNumber]];
      if (queNamSinh) {
        let canChi = queNamSinh[0].queViet.split(" ");
        const shouldDuplicateQue = await this.shouldDuplicateQue(
          canChi[0],
          canChi[1]
        );
        if (shouldDuplicateQue) {
          const duplicateQue = await this.getDuplicateQue(
            queNamSinh[0],
            canChi[0],
            canChi[1]
          );
          queNamSinh.push(duplicateQue);
        }
      }
      tempFinalResult1 = await this.filterOption(
        listFilterQuaiKhi,
        queNamSinh[0]
      );

      if (queNamSinh.length === 2) {
        tempFinalResult2 = await this.filterOption(
          listFilterQuaiKhi,
          queNamSinh[1]
        );
        var getValue = document.getElementById("namSinhGiaChu");
        const value = getValue.value;
        const yearOfBirth = new Date(value, 1, 2);
        const thisYear = new Date();
        if (thisYear - yearOfBirth < 30) {
          queNamSinh = queNamSinh.filter((v) => v.huyenKhongQuaiVan === 1);
        } else {
          queNamSinh = queNamSinh.filter((v) => v.huyenKhongQuaiVan !== 1);
        }
      }
      if (tempFinalResult1.length > 0) {
        listHopNamSinh.push(...tempFinalResult1);
      }
      if (tempFinalResult2.length > 0) {
        listHopNamSinh.push(...tempFinalResult2);
      }

      this.setState({ queNamSinh });
      // ra được 2 quẻ của năm sinh trong mảng đi filter từng object lấy mảng của mỗi item đưa vào trong 1 mảng kết quả ngày
    }
    if (listFilterQuaiKhi?.length === 0) {
      this.setState({ modalNoResult: true });
    } else {
      if (this.state.namSinhNumber !== -1 && this.state.toaNumber !== -1) {
        if (listHopNamSinh.length === 0 && this.state.toaNumber === -1) {
          // không có kết quả phù hợp với năm sinh
          this.setState({ modalNoResultHopNamSinh: true });
        } else if (listHopToa.length === 0 && this.state.namSinhNumber !== -1) {
          // không có kết quả phù hợp với tọa
          this.setState({ modalNoResultHopToa: true });
        }
      }
    }
    let duplicates = [];
    if (this.state.namSinhNumber !== -1 && this.state.toaNumber !== -1) {
      let mergeNamSinhToa = [...listHopNamSinh, ...listHopToa];

      const yourArrayWithoutDuplicates = [...new Set(mergeNamSinhToa)];

      duplicates = [...mergeNamSinhToa];
      yourArrayWithoutDuplicates.forEach((item) => {
        const i = duplicates.indexOf(item);
        duplicates = duplicates
          .slice(0, i)
          .concat(duplicates.slice(i + 1, duplicates.length));
      });
    }

    this.setState({
      listHopNamSinh,
      listHopToa,
      listFilterQuaiKhi,
      listNamSinhAndToa: duplicates,
    });
  }
  async validImageData(arrImage) {
    let finalData = [];
    await arrImage.forEach(async (image, index) => {
      let Canvas = await html2canvas(
        document.getElementById(`quai-khi-quai-van-${index}`),
        {
          ignoreElements: (element) => element.className === "to-hide-print",
          useCORS: true,
          allowTaint: true,
          logging: true,
          height: 1000,
          // width: 362,
        }
      );
      finalData.push({ url: Canvas.toDataURL() });
    });

    return finalData;
  }
  async onClickSendMail(data, dateToString, dateSendToServer) {
    this.setState({ isLoading: true });
    const emailSaveStorage = localStorage.getItem(mdfive);
    const userData = JSON.parse(emailSaveStorage);
    const hour = data.que12Gio.map((v, i) => {
      return `<p>&nbsp; - Giờ: ${v.queViet} - Nạp Âm: ${v.napAm} - Quẻ: ${v.tenque} - Quái Khí: ${v.huyenKhongNguHanh} - Quái Vận: ${v.huyenKhongQuaiVan}</p>`;
    });
    const day = data.queNgay;
    const month = data.queThang;
    const year = data.queNam;
    // let imgData = [];
    // if (data.que12Gio.length > 0) {
    //   imgData = await this.validImageData(data.que12Gio);
    // }
    let imgData = [];
    for (let index = 0; index < data.que12Gio.length; index++) {
      let Canvas = await html2canvas(
        document.getElementById(`quai-khi-quai-van-${index}`),
        {
          ignoreElements: (element) => element.className === "to-hide-print",
          useCORS: true,
          allowTaint: true,
          logging: true,
          height: 1000,
          // width: 362,
        }
      );
      imgData.push({ url: Canvas.toDataURL() });
    }
    const UTCTIME = moment(`${dateSendToServer} 09:00`, "DD/MM/YYYY HH:mm")
      .utc()
      .format("DD/MM/YYYY HH:mm");

    setTimeout(() => {
      if (userData.userName && imgData.length > 0) {
        try {
          var templateParams = {
            email: userData.email,
            date: UTCTIME.split(" ")[0],

            time: UTCTIME.split(" ")[1],
            subject: `Phong Thủy Tường Minh gửi bạn thông tin ngày ${dateToString}`,
            text: "hello",
            content: `<div><p>Xin chào ${userData.userName}</p><p>hông tin ngày giờ theo huyền không đại quái trạch nhật bạn đã chọn như sau: ngày ${dateToString} mà bạn đã theo dõi như sau:</p>${hour}<p>&nbsp; - Ngày: ${day.queViet} - Nạp Âm: ${day.napAm} - Quẻ: ${day.tenque} - Quái Khí: ${day.huyenKhongNguHanh} - Quái Vận: ${day.huyenKhongQuaiVan}.</p><p>&nbsp; - Tháng: ${month.queViet} - Nạp Âm: ${month.napAm} - Quẻ: ${month.tenque} - Quái Khí: ${month.huyenKhongNguHanh} - Quái Vận: ${month.huyenKhongQuaiVan}.</p><p>&nbsp; - Năm: ${year.queViet} - Nạp Âm: ${year.napAm} - Quẻ: ${year.tenque} - Quái Khí: ${year.huyenKhongNguHanh} - Quái Vận: ${year.huyenKhongQuaiVan}.</p><p>- Lưu ý: mail này sẽ tự động lặp lại trước 24h ngày đã chọn vui lòng bỏ qua nếu mail này đã được gửi trước đó.<br>Chúc bạn một ngày tốt đẹp,<br>Phong Thủy Tường Minh</p></div>`,
            image_base64: imgData,
          };
          axios
            .post(
              "https://huyenkhongtamnguyen.herokuapp.com/api/v1/auth/send_mail",
              templateParams
            )
            .then((response) => {
              //handle success
              this.setState({ modalSentSuccess: true, isLoading: false });
              // this.setState({ modalSentSuccess: true, isLoading: false });
            })
            .catch((response) => {
              //handle error
              this.setState({ isLoading: false });
            });
        } catch (error) {
          console.log(error);
        }
      }
    }, 1500);
  }
  convertChiToHour(chi, day) {
    // ngày hôm trước
    // moment(`${dateSendToServer} 00:01`, 'DD/MM/YYYY HH:mm').utc().format('DD/MM/YYYY HH:mm');
    let startDate = null;
    let endDate = null;
    // moment(day, 'DD/MM/YYY').utc().format('DD/MM/YYYY');
    switch (chi) {
      //special hour
      //23h01 ngày hôm trước đến 1 giờ ngày hôm sau.
      case Const.Chi.Ty.name:
        startDate = moment(`${day} 23:01`, "DD/MM/YYY HH:mm")
          .subtract(1, "days")
          .utc()
          .format("DD/MM/YYYY HH:mm");
        endDate = moment(`${day} 1:00`, "DD/MM/YYY HH:mm")
          .utc()
          .format("DD/MM/YYYY HH:mm");
        return { startDate, endDate };
      case Const.Chi.Suu.name:
        startDate = moment(`${day} 1:01`, "DD/MM/YYY HH:mm")
          .utc()
          .format("DD/MM/YYYY HH:mm");
        endDate = moment(`${day} 3:00`, "DD/MM/YYY HH:mm")
          .utc()
          .format("DD/MM/YYYY HH:mm");
        return { startDate, endDate };
      case Const.Chi.Dan.name:
        startDate = moment(`${day} 3:01`, "DD/MM/YYY HH:mm")
          .utc()
          .format("DD/MM/YYYY HH:mm");
        endDate = moment(`${day} 5:00`, "DD/MM/YYY HH:mm")
          .utc()
          .format("DD/MM/YYYY HH:mm");
        return { startDate, endDate };
      case Const.Chi.Mao.name:
        startDate = moment(`${day} 5:01`, "DD/MM/YYY HH:mm")
          .utc()
          .format("DD/MM/YYYY HH:mm");
        endDate = moment(`${day} 7:00`, "DD/MM/YYY HH:mm")
          .utc()
          .format("DD/MM/YYYY HH:mm");
        return { startDate, endDate };
      case Const.Chi.Thin.name:
        startDate = moment(`${day} 7:01`, "DD/MM/YYY HH:mm")
          .utc()
          .format("DD/MM/YYYY HH:mm");
        endDate = moment(`${day} 9:00`, "DD/MM/YYY HH:mm")
          .utc()
          .format("DD/MM/YYYY HH:mm");
        return { startDate, endDate };
      case Const.Chi.Ti.name:
        startDate = moment(`${day} 9:01`, "DD/MM/YYY HH:mm")
          .utc()
          .format("DD/MM/YYYY HH:mm");
        endDate = moment(`${day} 11:00`, "DD/MM/YYY HH:mm")
          .utc()
          .format("DD/MM/YYYY HH:mm");
        return { startDate, endDate };
      case Const.Chi.Ngo.name:
        startDate = moment(`${day} 11:01`, "DD/MM/YYY HH:mm")
          .utc()
          .format("DD/MM/YYYY HH:mm");
        endDate = moment(`${day} 13:00`, "DD/MM/YYY HH:mm")
          .utc()
          .format("DD/MM/YYYY HH:mm");
        return { startDate, endDate };
      case Const.Chi.Mui.name:
        startDate = moment(`${day} 13:01`, "DD/MM/YYY HH:mm")
          .utc()
          .format("DD/MM/YYYY HH:mm");
        endDate = moment(`${day} 15:00`, "DD/MM/YYY HH:mm")
          .utc()
          .format("DD/MM/YYYY HH:mm");
        return { startDate, endDate };
      case Const.Chi.Than.name:
        startDate = moment(`${day} 15:01`, "DD/MM/YYY HH:mm")
          .utc()
          .format("DD/MM/YYYY HH:mm");
        endDate = moment(`${day} 17:00`, "DD/MM/YYY HH:mm")
          .utc()
          .format("DD/MM/YYYY HH:mm");
        return { startDate, endDate };
      case Const.Chi.Dau.name:
        startDate = moment(`${day} 17:01`, "DD/MM/YYY HH:mm")
          .utc()
          .format("DD/MM/YYYY HH:mm");
        endDate = moment(`${day} 19:00`, "DD/MM/YYY HH:mm")
          .utc()
          .format("DD/MM/YYYY HH:mm");
        return { startDate, endDate };
      case Const.Chi.Tuat.name:
        startDate = moment(`${day} 19:01`, "DD/MM/YYY HH:mm")
          .utc()
          .format("DD/MM/YYYY HH:mm");
        endDate = moment(`${day} 21:00`, "DD/MM/YYY HH:mm")
          .utc()
          .format("DD/MM/YYYY HH:mm");
        return { startDate, endDate };
      case Const.Chi.Hoi.name:
        startDate = moment(`${day} 21:01`, "DD/MM/YYY HH:mm")
          .utc()
          .format("DD/MM/YYYY HH:mm");
        endDate = moment(`${day} 23:00`, "DD/MM/YYY HH:mm")
          .utc()
          .format("DD/MM/YYYY HH:mm");
        return { startDate, endDate };
      default:
        break;
    }
  }
  async onClickSetMayBom(dateSendToServer, hour) {
    const hourStartNEnd = this.convertChiToHour(
      hour.queViet.split(" ")[1],
      dateSendToServer
    );

    const emailSaveStorage = localStorage.getItem(mdfive);
    const userData = JSON.parse(emailSaveStorage);
    if (emailSaveStorage.MaThacNuoc) {
      this.setState({ isLoading: true });

      setTimeout(() => {
        if (userData.userName) {
          try {
            var templateParams = {
              email: userData.MaThacNuoc,
              date_utc_open: hourStartNEnd.startDate.split(" ")[0],
              time_utc_open: hourStartNEnd.startDate.split(" ")[1],
              date_utc_close: hourStartNEnd.endDate.split(" ")[0],
              time_utc_close: hourStartNEnd.endDate.split(" ")[1],
            };

            axios
              .post(
                "https://hitek-01.hitek.com.vn:5556/api/v1/auth/set_up",
                templateParams
              )

              .then((response) => {
                //handle success
                this.setState({
                  modalSentThapNuocSuccess: true,
                  isLoading: false,
                });

              })
              .catch((response) => {
                //handle error
                this.setState({ isLoading: false });

              });
          } catch (error) {
            console.log(error);
          }
        }
      }, 1000);
    } else {
      this.setState({ modalMissingThacNuoc: true });
    }
  }
  convertTamSatSideOnCompass(chi) {
    switch (chi) {
      case Const.Chi.Dan.name:
      case Const.Chi.Ngo.name:
      case Const.Chi.Tuat.name:
        return "0deg";
      case Const.Chi.Hoi.name:
      case Const.Chi.Mao.name:
      case Const.Chi.Mui.name:
        return "270deg";
      case Const.Chi.Ti.name:
      case Const.Chi.Dau.name:
      case Const.Chi.Suu.name:
        return "90deg";
      case Const.Chi.Than.name:
      case Const.Chi.Ty.name:
      case Const.Chi.Thin.name:
        return "180deg";
      default:
        break;
    }
  }
  convertSideToCompass(starNumber) {
    if (starNumber + 8 === 14 || starNumber + 8 === 5) {
      return { isActive: true, cordi: "135deg" };
    } else if (starNumber + 4 === 14 || starNumber + 4 === 5) {
      return { isActive: true, cordi: "180deg" };
    } else if (starNumber + 6 === 14 || starNumber + 6 === 5) {
      return { isActive: true, cordi: "225deg" };
    } else if (starNumber + 7 === 14 || starNumber + 7 === 5) {
      return { isActive: true, cordi: "90deg" };
    } else if (starNumber + 0 === 14 || starNumber + 0 === 5) {
      return { isActive: false, cordi: "0" };
    } else if (starNumber + 2 === 14 || starNumber + 2 === 5) {
      return { isActive: true, cordi: "270deg" };
    } else if (starNumber + 3 === 14 || starNumber + 3 === 5) {
      return { isActive: true, cordi: "45deg" };
    } else if (starNumber + 5 === 14 || starNumber + 5 === 5) {
      return { isActive: true, cordi: "0deg" };
    } else if (starNumber + 1 === 14 || starNumber + 1 === 5) {
      return { isActive: true, cordi: "315deg" };
    }
  }
  phiTinhNam(lunarCalendar) {
    const DateNew = new Date(lunarCalendar);

    const Year = DateNew?.getFullYear();
    const arrYear = String(Year).split("");
    let sumCharacter =
      Number(arrYear[0]) +
      Number(arrYear[1]) +
      Number(arrYear[2]) +
      Number(arrYear[3]);
    if (sumCharacter > 10) {
      let arrSumCharacter = String(sumCharacter).split("");
      sumCharacter = Number(arrSumCharacter[0]) + Number(arrSumCharacter[1]);
      if (sumCharacter >= 10) {
        let arrSumLast = String(sumCharacter).split("");
        sumCharacter = Number(arrSumLast[0]) + Number(arrSumLast[1]);
      }
    }
    const result = 11 - sumCharacter;
    return result;
  }
  render() {
    const {
      isLoading,
      selectedFilterQuaiKhi,
      selectedFilterQuaiVan,
      activeResultHour,
    } = this.state;
    return (
      <div id="Main-Body" style={{ textAlign: "center" }}>
        {this.renderModalErrorPressCode(
          this.state.modalSentSuccess,
          () => this.setState({ modalSentSuccess: false }),
          "Gửi mail thành công, vui lòng check mail của bạn để có thông tin ngày."
        )}
        {this.renderModalErrorPressCode(
          this.state.modalMissingThacNuoc,
          () => this.setState({ modalMissingThacNuoc: false }),
          "Bạn chưa gửi thông tin mã thác nước của bạn cho chúng tôi, xin vui lòng liên hệ với chúng tôi để cung cấp mã thác nước. Xin cảm ơn."
        )}
        {this.renderModalErrorPressCode(
          this.state.modalNoResultHopNamSinh,
          () => this.setState({ modalNoResultHopNamSinh: false }),
          "Khoảng thời gian bạn chọn không có kết quả hợp với năm sinh"
        )}
        {this.renderModalErrorPressCode(
          this.state.modalNoResultHopToa,
          () => this.setState({ modalNoResultHopToa: false }),
          "Khoảng thời gian bạn chọn không có kết quả hợp với Tọa"
        )}
        {this.renderModalErrorPressCode(
          this.state.modalEmptySelectedQuai,
          () => this.setState({ modalEmptySelectedQuai: false }),
          "Xin vui lòng chọn Quái vận hoặc Quái khí để có thể tìm kiếm ngày phù hợp."
        )}
        {this.renderModalErrorPressCode(
          this.state.modalEmptySelectedDateFromTo,
          () => this.setState({ modalEmptySelectedDateFromTo: false }),
          'Xin vui lòng chọn khoảng thời gian từ ngày nào đến ngày nào để tìm kiếm (Lưu ý: Khoảng thời gian "Từ ngày"phải nhỏ hơn khoản thời gian "Đến ngày")'
        )}
        {this.renderModalErrorPressCode(
          this.state.modalNoResult,
          () => this.setState({ modalNoResult: false }),
          "Không có kết quả nào phù hợp với khoảng thời gian bạn chọn, xin vui lòng chọn khoảng thời gian dài hơn."
        )}
        <Modal basic open={isLoading} size="large">
          <Modal.Content>
            <img
              id="LoadingImage"
              src={Logo}
              alt="logo"
              style={{
                animation: `spin 10s linear infinite`,
                justifyContent: "center",
                alignSelf: "center",
              }}
            />
          </Modal.Content>
        </Modal>
        <div>
          <table style={{ marginLeft: "auto", marginRight: "auto" }}>
            <tbody>
              <tr>
                <td style={{ float: "left", padding: "20px" }}>
                  <p style={{ textAlign: "left", margin: "4px" }}>
                    Tìm Ngày Quái Khí
                  </p>
                  <select
                    value={selectedFilterQuaiKhi}
                    onChange={(event) => {
                      this.setState({
                        selectedFilterQuaiKhi: Number(event.target.value),
                      });
                    }}
                    style={{
                      background: "white",
                      height: "29px",
                      width: "110px",
                    }}
                  >
                    {this.state.optionSelectQuaiKhi.map((v) => {
                      return (
                        <option key={v.key} value={v.value}>
                          {v.show}
                        </option>
                      );
                    })}
                  </select>
                </td>
                <td style={{ float: "left", padding: "20px" }}>
                  <p style={{ textAlign: "left", margin: "4px" }}>
                    Tìm Ngày Quái Vận
                  </p>
                  <select
                    value={selectedFilterQuaiVan}
                    onChange={(event) => {
                      this.setState({
                        selectedFilterQuaiVan: Number(event.target.value),
                      });
                    }}
                    style={{
                      background: "white",
                      height: "29px",
                      width: "110px",
                    }}
                  >
                    {this.state.optionSelectQuaiVan.map((v) => {
                      return (
                        <option key={v.key} value={v.value}>
                          {v.show}
                        </option>
                      );
                    })}
                  </select>
                </td>
                <td style={{ float: "left", padding: "20px", width: "190px" }}>
                  <p style={{ textAlign: "left", margin: "4px" }}>Chọn Tọa</p>
                  <Dropdown
                    onChange={(context, data) => {
                      this.setState({ toaNumber: data.value });
                    }}
                    placeholder="Giáp Tý 1/1"
                    search
                    fluid
                    value={this.state.toaNumber}
                    selection
                    options={[
                      { key: "khongChon", text: "Không chọn", value: -1 },
                      ...optionDropdown,
                    ]}
                  />
                </td>
                <td style={{ float: "left", padding: "20px", width: "190px" }}>
                  <p style={{ textAlign: "left", margin: "4px" }}>
                    Nhập Năm Sinh
                  </p>
                  <Input
                    id="namSinhGiaChu"
                    size="small"
                    placeholder="Nhập Năm Sinh..."
                    type="number"
                    onChange={_.debounce(this.namSinhChange, 1000)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ width: "100%", height: "100%" }}>
          <table style={{ marginLeft: "auto", marginRight: "auto" }}>
            <tbody>
              <tr>
                <td>
                  <DatePicker
                    // disabled={}
                    locale="vi"
                    className="input-date-picker"
                    selected={this.state.fromDate}
                    dateFormat="dd - MM - yyyy"
                    showYearDropdown
                    placeholderText="Từ Ngày"
                    dateFormatCalendar="MMMM"
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    onChange={(value) => this.setState({ fromDate: value })}
                  />
                </td>
                <td>
                  <DatePicker
                    // disabled={}
                    placeholderText="Đến Ngày"
                    locale="vi"
                    className="input-date-picker"
                    selected={this.state.toDate}
                    dateFormat="dd - MM - yyyy"
                    showYearDropdown
                    dateFormatCalendar="MMMM"
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    onChange={(value) => this.setState({ toDate: value })}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <button
          id="header"
          onClick={() => {
            this.onClickSearchResult();
          }}
        >
          Xem kết quả
        </button>
        {this.state.listFilterQuaiKhi?.length > 0 &&
          this.state.toaNumber === -1 &&
          this.state.namSinhNumber === -1 && (
            <div>
              <h1>CÁC KẾT QUẢ CÁC NGÀY GIỜ PHÙ HỢP VỚI THÔNG TIN CỦA BẠN</h1>
              <div
                style={{
                  width: "100%",
                  textAlign: "initial",
                  padding: "12px",
                  height: "500px",
                  overflowY: "scroll",
                  marginBottom: "24px",
                }}
              >
                <Accordion styled>
                  {this.state.listFilterQuaiKhi.map((v, i) => {
                    const date = new Date(v.date);
                    const dayWeek = this.convertDayWeekToLetter(date.getDay());
                    const dateInMonth = date.getDate();
                    const month = date.getMonth() + 1;
                    const year = date.getFullYear();
                    const stringDate = `${dayWeek}, Ngày ${dateInMonth} Tháng ${month} Năm ${year}`;
                    const dataSendToServer = `${dateInMonth}/${month}/${year}`;

                    return (
                      <div>
                        <Accordion.Title
                          active={activeResultHour === i}
                          index={i}
                          onClick={() => this.setState({ activeResultHour: i })}
                        >
                          <Icon name="dropdown" />
                          {stringDate}
                        </Accordion.Title>
                        <Collapse isOpened={activeResultHour === i}>
                          <h4 style={{ textAlign: "center" }}>{stringDate}</h4>
                          {v?.que12Gio?.map((hourResult, index) => {
                            const huongTamSatHour =
                              this.convertTamSatSideOnCompass(
                                hourResult.queViet.split(" ")[1]
                              );
                            const huongTamSatDay =
                              this.convertTamSatSideOnCompass(
                                v.queNgay.queViet.split(" ")[1]
                              );
                            const huongTamSatMonth =
                              this.convertTamSatSideOnCompass(
                                v.queThang.queViet.split(" ")[1]
                              );
                            const huongTamSatYear =
                              this.convertTamSatSideOnCompass(
                                v.queNam.queViet.split(" ")[1]
                              );
                            return (
                              <div id={`quai-khi-quai-van-${index}`}>
                                <div
                                  style={{ textAlign: "center" }}
                                >{`----------------`}</div>
                                <div style={{ textAlign: "center" }}>
                                  <table style={{ width: "100%" }}>
                                    <tbody>
                                      <tr>
                                        <td
                                          style={{ whiteSpace: "pre-wrap" }}
                                        >{`Giờ \n${hourResult.queViet}`}</td>
                                        <td
                                          style={{ whiteSpace: "pre-wrap" }}
                                        >{`Ngày \n${v.queNgay.queViet}`}</td>
                                        <td
                                          style={{ whiteSpace: "pre-wrap" }}
                                        >{`Tháng \n${v.queThang.queViet}`}</td>
                                        <td
                                          style={{ whiteSpace: "pre-wrap" }}
                                        >{`Năm \n${v.queNam.queViet}`}</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <TableQue
                                            data={hourResult}
                                            searchClass="each-que"
                                            center
                                          />
                                        </td>
                                        <td>
                                          <TableQue
                                            data={v.queNgay}
                                            searchClass="each-que"
                                            center
                                          />
                                        </td>
                                        <td>
                                          <TableQue
                                            data={v.queThang}
                                            searchClass="each-que"
                                            center
                                          />
                                        </td>
                                        <td>
                                          <TableQue
                                            data={v.queNam}
                                            searchClass="each-que"
                                            center
                                          />
                                        </td>
                                        <td>
                                          <div id="myImg">
                                            <Modal
                                              trigger={
                                                <div>
                                                  <img
                                                    alt="arrow"
                                                    src={arrow}
                                                    style={{
                                                      position: "absolute",
                                                      right: `${
                                                        91 + rightPlusToCompass
                                                      }px`,
                                                      top: "-14px",
                                                      height: "211px",
                                                      transform: `rotate(${this.state.queToa?.cordCompass})`,
                                                    }}
                                                  />
                                                  <img
                                                    alt="LaBan"
                                                    src={LaBan}
                                                    style={{
                                                      zIndex: "999",
                                                      width: "183px",
                                                      height: "183px",
                                                    }}
                                                  ></img>
                                                  {huongTamSatHour && (
                                                    <img
                                                      alt="tamSat"
                                                      src={tamSat}
                                                      style={{
                                                        position: "absolute",
                                                        opacity: 0.5,
                                                        right: `${
                                                          30 +
                                                          rightPlusToCompass
                                                        }px`,
                                                        top: "-36px",
                                                        height: "255px",
                                                        transform: `rotate(${huongTamSatHour})`,
                                                      }}
                                                    />
                                                  )}
                                                  {huongTamSatDay && (
                                                    <img
                                                      alt="tamSat"
                                                      src={tamSat}
                                                      style={{
                                                        position: "absolute",
                                                        opacity: 0.5,
                                                        right: `${
                                                          30 +
                                                          rightPlusToCompass
                                                        }px`,
                                                        top: "-36px",
                                                        height: "255px",
                                                        transform: `rotate(${huongTamSatDay})`,
                                                      }}
                                                    />
                                                  )}
                                                  {huongTamSatMonth && (
                                                    <img
                                                      alt="tamSat"
                                                      src={tamSat}
                                                      style={{
                                                        position: "absolute",

                                                        opacity: 0.5,
                                                        right: `${
                                                          30 +
                                                          rightPlusToCompass
                                                        }px`,
                                                        top: "-36px",
                                                        height: "255px",
                                                        transform: `rotate(${huongTamSatMonth})`,
                                                      }}
                                                    />
                                                  )}
                                                  {huongTamSatYear && (
                                                    <img
                                                      alt="tamSat"
                                                      src={tamSat}
                                                      style={{
                                                        position: "absolute",

                                                        opacity: 0.5,
                                                        right: `${
                                                          30 +
                                                          rightPlusToCompass
                                                        }px`,
                                                        top: "-36px",
                                                        height: "255px",
                                                        transform: `rotate(${huongTamSatYear})`,
                                                      }}
                                                    />
                                                  )}
                                                  <img
                                                    alt="tamSat"
                                                    src={five}
                                                    style={{
                                                      position: "absolute",

                                                      opacity: 0.5,
                                                      right: `${
                                                        83 + rightPlusToCompass
                                                      }px`,
                                                      top: "-2px",
                                                      height: "187px",
                                                      transform: `rotate(${v.chiYear?.coordinate})`,
                                                    }}
                                                  />
                                                  {v.huongNguHoangYear
                                                    ?.isActive && (
                                                    <img
                                                      alt="nguHoang"
                                                      src={nguHoang}
                                                      style={{
                                                        position: "absolute",

                                                        opacity: 0.5,
                                                        right: `${
                                                          60 +
                                                          rightPlusToCompass
                                                        }px`,
                                                        top: "0px",
                                                        height: "183px",
                                                        transform: `rotate(${v.huongNguHoangYear?.cordi})`,
                                                      }}
                                                    />
                                                  )}
                                                  {v.huongNguHoangMonth
                                                    ?.isActive && (
                                                    <img
                                                      alt="nguHoang"
                                                      src={nguHoang}
                                                      style={{
                                                        position: "absolute",

                                                        opacity: 0.5,
                                                        right: `${
                                                          60 +
                                                          rightPlusToCompass
                                                        }px`,
                                                        top: "0px",
                                                        height: "183px",
                                                        transform: `rotate(${v.huongNguHoangMonth?.cordi})`,
                                                      }}
                                                    />
                                                  )}
                                                </div>
                                              }
                                            >
                                              <Modal.Content
                                                image
                                                className="modal-container"
                                              >
                                                <img
                                                  alt="arrow2"
                                                  src={arrow2}
                                                  style={{
                                                    position: "fixed",
                                                    top: "1%",
                                                    left: "48.5%",
                                                    height: "110%",
                                                    transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${v?.cordCompass})`,
                                                  }}
                                                />
                                                <Image
                                                  wrapped
                                                  size="massive"
                                                  src={LaBanV2}
                                                  style={{
                                                    position: "fixed",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform: `translate(-50%, -50%) translate(-0px, 0px)`,
                                                  }}
                                                />
                                                {huongTamSatHour && (
                                                  <img
                                                    alt="arrow2"
                                                    src={tamSat}
                                                    style={{
                                                      opacity: 0.3,
                                                      position: "fixed",
                                                      top: "50%",
                                                      left: "50%",
                                                      height: "132%",
                                                      transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${huongTamSatHour})`,
                                                    }}
                                                  />
                                                )}
                                                {huongTamSatDay && (
                                                  <img
                                                    alt="arrow2"
                                                    src={tamSat}
                                                    style={{
                                                      opacity: 0.3,
                                                      position: "fixed",
                                                      top: "50%",
                                                      left: "50%",
                                                      height: "132%",
                                                      transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${huongTamSatDay})`,
                                                    }}
                                                  />
                                                )}
                                                {huongTamSatMonth && (
                                                  <img
                                                    alt="arrow2"
                                                    src={tamSat}
                                                    style={{
                                                      opacity: 0.3,
                                                      position: "fixed",
                                                      top: "50%",
                                                      left: "50%",
                                                      height: "132%",
                                                      transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${huongTamSatMonth})`,
                                                    }}
                                                  />
                                                )}
                                                {huongTamSatYear && (
                                                  <img
                                                    alt="arrow2"
                                                    src={tamSat}
                                                    style={{
                                                      opacity: 0.3,
                                                      position: "fixed",
                                                      top: "50%",
                                                      left: "50%",
                                                      height: "132%",
                                                      transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${huongTamSatYear})`,
                                                    }}
                                                  />
                                                )}
                                                <img
                                                  alt="arrow2"
                                                  src={five}
                                                  style={{
                                                    opacity: 0.5,
                                                    position: "fixed",
                                                    top: "1%",
                                                    left: "44.5%",
                                                    height: "98%",
                                                    transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${v.queNam?.coordinate})`,
                                                  }}
                                                />
                                                {v.huongNguHoangYear
                                                  ?.isActive && (
                                                  <img
                                                    alt="arrow2"
                                                    src={nguHoang}
                                                    style={{
                                                      opacity: 0.5,
                                                      position: "fixed",
                                                      top: "50%",
                                                      left: "50%",
                                                      height: "93%",
                                                      transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${v.huongNguHoangYear?.cordi})`,
                                                    }}
                                                  />
                                                )}
                                                {v.huongNguHoangMonth
                                                  ?.isActive && (
                                                  <img
                                                    alt="arrow2"
                                                    src={nguHoang}
                                                    style={{
                                                      opacity: 0.5,

                                                      position: "fixed",
                                                      top: "50%",
                                                      left: "50%",
                                                      height: "93%",
                                                      transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${v.huongNguHoangMonth?.cordi})`,
                                                    }}
                                                  />
                                                )}
                                                <div
                                                  style={{
                                                    position: "fixed",
                                                    right: "0px",
                                                    width: "150px",
                                                  }}
                                                >
                                                  <p>
                                                    15 độ{" "}
                                                    <mark
                                                      style={{
                                                        backgroundColor:
                                                          "#ff00007a",
                                                      }}
                                                    >
                                                      Thái Tuế
                                                    </mark>
                                                  </p>
                                                  <br></br>
                                                  <p>
                                                    15 độ{" "}
                                                    <mark
                                                      style={{
                                                        backgroundColor:
                                                          "#0080008a",
                                                      }}
                                                    >
                                                      Tuế Phá
                                                    </mark>
                                                  </p>
                                                  <br></br>
                                                  <p>
                                                    45 độ{" "}
                                                    <mark
                                                      style={{
                                                        backgroundColor:
                                                          "#ffff00ad",
                                                      }}
                                                    >
                                                      Ngũ Hoàng
                                                    </mark>
                                                  </p>
                                                  <br></br>
                                                  <p>
                                                    90 độ{" "}
                                                    <mark
                                                      style={{
                                                        backgroundColor:
                                                          "#0000ff80",
                                                      }}
                                                    >
                                                      Tam Sát
                                                    </mark>
                                                  </p>
                                                  <br></br>
                                                </div>
                                              </Modal.Content>
                                            </Modal>
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                            }}
                                          >
                                            <Button
                                              content="Hẹn giờ thác nước"
                                              onClick={() =>
                                                this.onClickSetMayBom(
                                                  dataSendToServer,
                                                  hourResult
                                                )
                                              }
                                            />
                                            <div style={{ height: "8px" }} />
                                            <Button
                                              content="Thêm giờ vào lịch google"
                                              onClick={() => {
                                                const hourtemp = convertHour(
                                                  date.getHours()
                                                );
                                                const titleEvent = `Nội dung sự kiện`;
                                                let fromTime = new Date(
                                                  date.setHours(
                                                    hourtemp.value - 1,
                                                    0
                                                  )
                                                );
                                                let toTime = new Date(
                                                  date.setHours(
                                                    hourtemp.value + 1,
                                                    0
                                                  )
                                                );

                                                if (
                                                  fromTime.getHours() === 23
                                                ) {
                                                  toTime = new Date(
                                                    date.setDate(
                                                      date.getDate() + 1
                                                    )
                                                  );
                                                }
                                                window.open(
                                                  `https://calendar.google.com/calendar/u/0/r/eventedit?text=${titleEvent}&dates=${fromTime
                                                    .toISOString()
                                                    .replace(
                                                      /[-:.]/g,
                                                      ""
                                                    )}/${toTime
                                                    .toISOString()
                                                    .replace(
                                                      /[-:.]/g,
                                                      ""
                                                    )}&details=Thông+tin+chi+tiết+tại:+http://huyenkhongtamnguyen.com&location=Ho+Chi+Minh+City,+Vietnam&sf=true&output=xml`
                                                );
                                              }}
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            );
                          })}
                          <div style={{ display: "flex" }}>
                            <Modal.Actions style={{ margin: "auto" }}>
                              <Button
                                content="Thông báo qua mail"
                                onClick={() =>
                                  this.onClickSendMail(
                                    v,
                                    stringDate,
                                    dataSendToServer
                                  )
                                }
                              />
                            </Modal.Actions>
                          </div>
                        </Collapse>
                      </div>
                    );
                  })}
                </Accordion>
              </div>
            </div>
          )}
        {(this.state.toaNumber !== -1 || this.state.namSinhNumber !== -1) && (
          <div style={{ display: "flex" }}>
            {this.state.listHopToa?.length > 0 && (
              <div style={{ width: "100%" }}>
                <h1
                  style={{ whiteSpace: "pre-wrap" }}
                >{`Các ngày phù hợp với\nTọa`}</h1>

                <div
                  style={{
                    width: "100%",
                    textAlign: "initial",
                    padding: "12px",
                    height: "500px",
                    overflowY: "scroll",
                    marginBottom: "24px",
                  }}
                >
                  <Accordion styled>
                    {this.state.listHopToa?.map((v, i) => {
                      const date = new Date(v.date);
                      const dayWeek = this.convertDayWeekToLetter(
                        date.getDay()
                      );
                      const dateInMonth = date.getDate();
                      const month = date.getMonth() + 1;
                      const year = date.getFullYear();
                      const stringDate = `${dayWeek}, Ngày ${dateInMonth} Tháng ${month} Năm ${year}`;
                      const dataSendToServer = `${dateInMonth}/${month}/${year}`;

                      return (
                        <div>
                          <Accordion.Title
                            active={activeResultHour === i}
                            index={i}
                            onClick={() =>
                              this.setState({ activeResultHour: i })
                            }
                          >
                            <Icon name="dropdown" />
                            {stringDate}
                          </Accordion.Title>
                          <Collapse isOpened={activeResultHour === i}>
                            <h4 style={{ textAlign: "center" }}>
                              {stringDate}
                            </h4>
                            {v?.que12Gio?.map((hourResult, index) => {
                              const huongTamSatHour =
                                this.convertTamSatSideOnCompass(
                                  hourResult.queViet.split(" ")[1]
                                );
                              const huongTamSatDay =
                                this.convertTamSatSideOnCompass(
                                  v.queNgay.queViet.split(" ")[1]
                                );
                              const huongTamSatMonth =
                                this.convertTamSatSideOnCompass(
                                  v.queThang.queViet.split(" ")[1]
                                );
                              const huongTamSatYear =
                                this.convertTamSatSideOnCompass(
                                  v.queNam.queViet.split(" ")[1]
                                );
                              return (
                                <div id={`quai-khi-quai-van-${index}`}>
                                  <div
                                    style={{ textAlign: "center" }}
                                  >{`----------------`}</div>
                                  <div style={{ textAlign: "center" }}>
                                    <table style={{ width: "100%" }}>
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{ whiteSpace: "pre-wrap" }}
                                          >{`Giờ \n${hourResult.queViet}`}</td>
                                          <td
                                            style={{ whiteSpace: "pre-wrap" }}
                                          >{`Ngày \n${v.queNgay.queViet}`}</td>
                                          <td
                                            style={{ whiteSpace: "pre-wrap" }}
                                          >{`Tháng \n${v.queThang.queViet}`}</td>
                                          <td
                                            style={{ whiteSpace: "pre-wrap" }}
                                          >{`Năm \n${v.queNam.queViet}`}</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <TableQue
                                              data={hourResult}
                                              searchClass="each-que"
                                              center
                                            />
                                          </td>
                                          <td>
                                            <TableQue
                                              data={v.queNgay}
                                              searchClass="each-que"
                                              center
                                            />
                                          </td>
                                          <td>
                                            <TableQue
                                              data={v.queThang}
                                              searchClass="each-que"
                                              center
                                            />
                                          </td>
                                          <td>
                                            <TableQue
                                              data={v.queNam}
                                              searchClass="each-que"
                                              center
                                            />
                                          </td>
                                          <td>
                                            <div id="myImg">
                                              <Modal
                                                trigger={
                                                  <div>
                                                    <img
                                                      alt="arrow"
                                                      src={arrow}
                                                      style={{
                                                        position: "absolute",
                                                        right: `${
                                                          91 +
                                                          rightPlusToCompass
                                                        }px`,
                                                        top: "-14px",
                                                        height: "211px",
                                                        transform: `rotate(${this.state.queToa?.cordCompass})`,
                                                      }}
                                                    />
                                                    <img
                                                      alt="LaBan"
                                                      src={LaBan}
                                                      style={{
                                                        zIndex: "999",
                                                        width: "183px",
                                                        height: "183px",
                                                      }}
                                                    ></img>
                                                    {huongTamSatHour && (
                                                      <img
                                                        alt="tamSat"
                                                        src={tamSat}
                                                        style={{
                                                          position: "absolute",
                                                          opacity: 0.5,
                                                          right: `${
                                                            30 +
                                                            rightPlusToCompass
                                                          }px`,
                                                          top: "-36px",
                                                          height: "255px",
                                                          transform: `rotate(${huongTamSatHour})`,
                                                        }}
                                                      />
                                                    )}
                                                    {huongTamSatDay && (
                                                      <img
                                                        alt="tamSat"
                                                        src={tamSat}
                                                        style={{
                                                          position: "absolute",
                                                          opacity: 0.5,
                                                          right: `${
                                                            30 +
                                                            rightPlusToCompass
                                                          }px`,
                                                          top: "-36px",
                                                          height: "255px",
                                                          transform: `rotate(${huongTamSatDay})`,
                                                        }}
                                                      />
                                                    )}
                                                    {huongTamSatMonth && (
                                                      <img
                                                        alt="tamSat"
                                                        src={tamSat}
                                                        style={{
                                                          position: "absolute",

                                                          opacity: 0.5,
                                                          right: `${
                                                            30 +
                                                            rightPlusToCompass
                                                          }px`,
                                                          top: "-36px",
                                                          height: "255px",
                                                          transform: `rotate(${huongTamSatMonth})`,
                                                        }}
                                                      />
                                                    )}
                                                    {huongTamSatYear && (
                                                      <img
                                                        alt="tamSat"
                                                        src={tamSat}
                                                        style={{
                                                          position: "absolute",

                                                          opacity: 0.5,
                                                          right: `${
                                                            30 +
                                                            rightPlusToCompass
                                                          }px`,
                                                          top: "-36px",
                                                          height: "255px",
                                                          transform: `rotate(${huongTamSatYear})`,
                                                        }}
                                                      />
                                                    )}
                                                    <img
                                                      alt="tamSat"
                                                      src={five}
                                                      style={{
                                                        position: "absolute",

                                                        opacity: 0.5,
                                                        right: `${
                                                          83 +
                                                          rightPlusToCompass
                                                        }px`,
                                                        top: "-2px",
                                                        height: "187px",
                                                        transform: `rotate(${v.chiYear?.coordinate})`,
                                                      }}
                                                    />
                                                    {v.huongNguHoangYear
                                                      ?.isActive && (
                                                      <img
                                                        alt="nguHoang"
                                                        src={nguHoang}
                                                        style={{
                                                          position: "absolute",

                                                          opacity: 0.5,
                                                          right: `${
                                                            60 +
                                                            rightPlusToCompass
                                                          }px`,
                                                          top: "0px",
                                                          height: "183px",
                                                          transform: `rotate(${v.huongNguHoangYear?.cordi})`,
                                                        }}
                                                      />
                                                    )}
                                                    {v.huongNguHoangMonth
                                                      ?.isActive && (
                                                      <img
                                                        alt="nguHoang"
                                                        src={nguHoang}
                                                        style={{
                                                          position: "absolute",

                                                          opacity: 0.5,
                                                          right: `${
                                                            60 +
                                                            rightPlusToCompass
                                                          }px`,
                                                          top: "0px",
                                                          height: "183px",
                                                          transform: `rotate(${v.huongNguHoangMonth?.cordi})`,
                                                        }}
                                                      />
                                                    )}
                                                  </div>
                                                }
                                              >
                                                <Modal.Content
                                                  image
                                                  className="modal-container"
                                                >
                                                  <img
                                                    alt="arrow2"
                                                    src={arrow2}
                                                    style={{
                                                      position: "fixed",
                                                      top: "1%",
                                                      left: "48.5%",
                                                      height: "110%",
                                                      transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${v?.cordCompass})`,
                                                    }}
                                                  />
                                                  <Image
                                                    wrapped
                                                    size="massive"
                                                    src={LaBanV2}
                                                    style={{
                                                      position: "fixed",
                                                      top: "50%",
                                                      left: "50%",
                                                      transform: `translate(-50%, -50%) translate(-0px, 0px)`,
                                                    }}
                                                  />
                                                  {huongTamSatHour && (
                                                    <img
                                                      alt="arrow2"
                                                      src={tamSat}
                                                      style={{
                                                        opacity: 0.3,
                                                        position: "fixed",
                                                        top: "50%",
                                                        left: "50%",
                                                        height: "132%",
                                                        transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${huongTamSatHour})`,
                                                      }}
                                                    />
                                                  )}
                                                  {huongTamSatDay && (
                                                    <img
                                                      alt="arrow2"
                                                      src={tamSat}
                                                      style={{
                                                        opacity: 0.3,
                                                        position: "fixed",
                                                        top: "50%",
                                                        left: "50%",
                                                        height: "132%",
                                                        transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${huongTamSatDay})`,
                                                      }}
                                                    />
                                                  )}
                                                  {huongTamSatMonth && (
                                                    <img
                                                      alt="arrow2"
                                                      src={tamSat}
                                                      style={{
                                                        opacity: 0.3,
                                                        position: "fixed",
                                                        top: "50%",
                                                        left: "50%",
                                                        height: "132%",
                                                        transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${huongTamSatMonth})`,
                                                      }}
                                                    />
                                                  )}
                                                  {huongTamSatYear && (
                                                    <img
                                                      alt="arrow2"
                                                      src={tamSat}
                                                      style={{
                                                        opacity: 0.3,
                                                        position: "fixed",
                                                        top: "50%",
                                                        left: "50%",
                                                        height: "132%",
                                                        transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${huongTamSatYear})`,
                                                      }}
                                                    />
                                                  )}
                                                  <img
                                                    alt="arrow2"
                                                    src={five}
                                                    style={{
                                                      opacity: 0.5,
                                                      position: "fixed",
                                                      top: "1%",
                                                      left: "44.5%",
                                                      height: "98%",
                                                      transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${v.queNam?.coordinate})`,
                                                    }}
                                                  />
                                                  {v.huongNguHoangYear
                                                    ?.isActive && (
                                                    <img
                                                      alt="arrow2"
                                                      src={nguHoang}
                                                      style={{
                                                        opacity: 0.5,
                                                        position: "fixed",
                                                        top: "50%",
                                                        left: "50%",
                                                        height: "93%",
                                                        transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${v.huongNguHoangYear?.cordi})`,
                                                      }}
                                                    />
                                                  )}
                                                  {v.huongNguHoangMonth
                                                    ?.isActive && (
                                                    <img
                                                      alt="arrow2"
                                                      src={nguHoang}
                                                      style={{
                                                        opacity: 0.5,

                                                        position: "fixed",
                                                        top: "50%",
                                                        left: "50%",
                                                        height: "93%",
                                                        transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${v.huongNguHoangMonth?.cordi})`,
                                                      }}
                                                    />
                                                  )}
                                                  <div
                                                    style={{
                                                      position: "fixed",
                                                      right: "0px",
                                                      width: "150px",
                                                    }}
                                                  >
                                                    <p>
                                                      15 độ{" "}
                                                      <mark
                                                        style={{
                                                          backgroundColor:
                                                            "#ff00007a",
                                                        }}
                                                      >
                                                        Thái Tuế
                                                      </mark>
                                                    </p>
                                                    <br></br>
                                                    <p>
                                                      15 độ{" "}
                                                      <mark
                                                        style={{
                                                          backgroundColor:
                                                            "#0080008a",
                                                        }}
                                                      >
                                                        Tuế Phá
                                                      </mark>
                                                    </p>
                                                    <br></br>
                                                    <p>
                                                      45 độ{" "}
                                                      <mark
                                                        style={{
                                                          backgroundColor:
                                                            "#ffff00ad",
                                                        }}
                                                      >
                                                        Ngũ Hoàng
                                                      </mark>
                                                    </p>
                                                    <br></br>
                                                    <p>
                                                      90 độ{" "}
                                                      <mark
                                                        style={{
                                                          backgroundColor:
                                                            "#0000ff80",
                                                        }}
                                                      >
                                                        Tam Sát
                                                      </mark>
                                                    </p>
                                                    <br></br>
                                                  </div>
                                                </Modal.Content>
                                              </Modal>
                                            </div>
                                          </td>
                                          <td>
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <Button
                                                content="Hẹn giờ thác nước"
                                                onClick={() =>
                                                  this.onClickSetMayBom(
                                                    dataSendToServer,
                                                    hourResult
                                                  )
                                                }
                                              />
                                              <div style={{ height: "8px" }} />

                                              <Button
                                                content="Thêm giờ vào lịch google"
                                                onClick={() => {
                                                  const hourtemp = convertHour(
                                                    date.getHours()
                                                  );
                                                  const titleEvent = `Nội dung sự kiện`;
                                                  let fromTime = new Date(
                                                    date.setHours(
                                                      hourtemp.value - 1,
                                                      0
                                                    )
                                                  );
                                                  let toTime = new Date(
                                                    date.setHours(
                                                      hourtemp.value + 1,
                                                      0
                                                    )
                                                  );

                                                  if (
                                                    fromTime.getHours() === 23
                                                  ) {
                                                    toTime = new Date(
                                                      date.setDate(
                                                        date.getDate() + 1
                                                      )
                                                    );
                                                  }
                                                  window.open(
                                                    `https://calendar.google.com/calendar/u/0/r/eventedit?text=${titleEvent}&dates=${fromTime
                                                      .toISOString()
                                                      .replace(
                                                        /[-:.]/g,
                                                        ""
                                                      )}/${toTime
                                                      .toISOString()
                                                      .replace(
                                                        /[-:.]/g,
                                                        ""
                                                      )}&details=Thông+tin+chi+tiết+tại:+http://huyenkhongtamnguyen.com&location=Ho+Chi+Minh+City,+Vietnam&sf=true&output=xml`
                                                  );
                                                }}
                                              />
                                            </div>{" "}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              );
                            })}
                            <div style={{ display: "flex" }}>
                              <Modal.Actions style={{ margin: "auto" }}>
                                <Button
                                  content="Thông báo qua mail"
                                  onClick={() =>
                                    this.onClickSendMail(
                                      v,
                                      stringDate,
                                      dataSendToServer
                                    )
                                  }
                                />
                              </Modal.Actions>
                            </div>
                          </Collapse>
                        </div>
                      );
                    })}
                  </Accordion>
                </div>
              </div>
            )}
            {this.state.listHopNamSinh?.length > 0 && (
              <div style={{ width: "100%" }}>
                <h1
                  style={{ whiteSpace: "pre-wrap" }}
                >{`Các ngày phù hợp với\nNăm Sinh`}</h1>
                <div
                  style={{
                    width: "100%",
                    textAlign: "initial",
                    padding: "12px",
                    height: "500px",
                    overflowY: "scroll",
                    marginBottom: "24px",
                  }}
                >
                  <Accordion styled>
                    {this.state.listHopNamSinh?.map((v, i) => {
                      const date = new Date(v.date);
                      const dayWeek = this.convertDayWeekToLetter(
                        date.getDay()
                      );
                      const dateInMonth = date.getDate();
                      const month = date.getMonth() + 1;
                      const year = date.getFullYear();
                      const stringDate = `${dayWeek}, Ngày ${dateInMonth} Tháng ${month} Năm ${year}`;
                      const dataSendToServer = `${dateInMonth}/${month}/${year}`;

                      return (
                        <div>
                          <Accordion.Title
                            active={activeResultHour === i}
                            index={i}
                            onClick={() =>
                              this.setState({ activeResultHour: i })
                            }
                          >
                            <Icon name="dropdown" />
                            {stringDate}
                          </Accordion.Title>
                          <Collapse isOpened={activeResultHour === i}>
                            <h4 style={{ textAlign: "center" }}>
                              {stringDate}
                            </h4>
                            {v?.que12Gio.map((hourResult, index) => {
                              const huongTamSatHour =
                                this.convertTamSatSideOnCompass(
                                  hourResult.queViet.split(" ")[1]
                                );
                              const huongTamSatDay =
                                this.convertTamSatSideOnCompass(
                                  v.queNgay.queViet.split(" ")[1]
                                );
                              const huongTamSatMonth =
                                this.convertTamSatSideOnCompass(
                                  v.queThang.queViet.split(" ")[1]
                                );
                              const huongTamSatYear =
                                this.convertTamSatSideOnCompass(
                                  v.queNam.queViet.split(" ")[1]
                                );
                              return (
                                <div id={`quai-khi-quai-van-${index}`}>
                                  <div
                                    style={{ textAlign: "center" }}
                                  >{`----------------`}</div>
                                  <div style={{ textAlign: "center" }}>
                                    <table style={{ width: "100%" }}>
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{ whiteSpace: "pre-wrap" }}
                                          >{`Giờ \n${hourResult.queViet}`}</td>
                                          <td
                                            style={{ whiteSpace: "pre-wrap" }}
                                          >{`Ngày \n${v.queNgay.queViet}`}</td>
                                          <td
                                            style={{ whiteSpace: "pre-wrap" }}
                                          >{`Tháng \n${v.queThang.queViet}`}</td>
                                          <td
                                            style={{ whiteSpace: "pre-wrap" }}
                                          >{`Năm \n${v.queNam.queViet}`}</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <TableQue
                                              data={hourResult}
                                              searchClass="each-que"
                                              center
                                            />
                                          </td>
                                          <td>
                                            <TableQue
                                              data={v.queNgay}
                                              searchClass="each-que"
                                              center
                                            />
                                          </td>
                                          <td>
                                            <TableQue
                                              data={v.queThang}
                                              searchClass="each-que"
                                              center
                                            />
                                          </td>
                                          <td>
                                            <TableQue
                                              data={v.queNam}
                                              searchClass="each-que"
                                              center
                                            />
                                          </td>
                                          <td>
                                            <div id="myImg">
                                              <Modal
                                                trigger={
                                                  <div>
                                                    <img
                                                      alt="arrow"
                                                      src={arrow}
                                                      style={{
                                                        position: "absolute",
                                                        right: `${
                                                          91 +
                                                          rightPlusToCompass
                                                        }px`,
                                                        top: "-14px",
                                                        height: "211px",
                                                        transform: `rotate(${this.state.queToa?.cordCompass})`,
                                                      }}
                                                    />
                                                    <img
                                                      alt="LaBan"
                                                      src={LaBan}
                                                      style={{
                                                        zIndex: "999",
                                                        width: "183px",
                                                        height: "183px",
                                                      }}
                                                    ></img>
                                                    {huongTamSatHour && (
                                                      <img
                                                        alt="tamSat"
                                                        src={tamSat}
                                                        style={{
                                                          position: "absolute",
                                                          opacity: 0.5,
                                                          right: `${
                                                            30 +
                                                            rightPlusToCompass
                                                          }px`,
                                                          top: "-36px",
                                                          height: "255px",
                                                          transform: `rotate(${huongTamSatHour})`,
                                                        }}
                                                      />
                                                    )}
                                                    {huongTamSatDay && (
                                                      <img
                                                        alt="tamSat"
                                                        src={tamSat}
                                                        style={{
                                                          position: "absolute",
                                                          opacity: 0.5,
                                                          right: `${
                                                            30 +
                                                            rightPlusToCompass
                                                          }px`,
                                                          top: "-36px",
                                                          height: "255px",
                                                          transform: `rotate(${huongTamSatDay})`,
                                                        }}
                                                      />
                                                    )}
                                                    {huongTamSatMonth && (
                                                      <img
                                                        alt="tamSat"
                                                        src={tamSat}
                                                        style={{
                                                          position: "absolute",

                                                          opacity: 0.5,
                                                          right: `${
                                                            30 +
                                                            rightPlusToCompass
                                                          }px`,
                                                          top: "-36px",
                                                          height: "255px",
                                                          transform: `rotate(${huongTamSatMonth})`,
                                                        }}
                                                      />
                                                    )}
                                                    {huongTamSatYear && (
                                                      <img
                                                        alt="tamSat"
                                                        src={tamSat}
                                                        style={{
                                                          position: "absolute",

                                                          opacity: 0.5,
                                                          right: `${
                                                            30 +
                                                            rightPlusToCompass
                                                          }px`,
                                                          top: "-36px",
                                                          height: "255px",
                                                          transform: `rotate(${huongTamSatYear})`,
                                                        }}
                                                      />
                                                    )}
                                                    <img
                                                      alt="tamSat"
                                                      src={five}
                                                      style={{
                                                        position: "absolute",

                                                        opacity: 0.5,
                                                        right: `${
                                                          83 +
                                                          rightPlusToCompass
                                                        }px`,
                                                        top: "-2px",
                                                        height: "187px",
                                                        transform: `rotate(${v.chiYear?.coordinate})`,
                                                      }}
                                                    />
                                                    {v.huongNguHoangYear
                                                      ?.isActive && (
                                                      <img
                                                        alt="nguHoang"
                                                        src={nguHoang}
                                                        style={{
                                                          position: "absolute",

                                                          opacity: 0.5,
                                                          right: `${
                                                            60 +
                                                            rightPlusToCompass
                                                          }px`,
                                                          top: "0px",
                                                          height: "183px",
                                                          transform: `rotate(${v.huongNguHoangYear?.cordi})`,
                                                        }}
                                                      />
                                                    )}
                                                    {v.huongNguHoangMonth
                                                      ?.isActive && (
                                                      <img
                                                        alt="nguHoang"
                                                        src={nguHoang}
                                                        style={{
                                                          position: "absolute",

                                                          opacity: 0.5,
                                                          right: `${
                                                            60 +
                                                            rightPlusToCompass
                                                          }px`,
                                                          top: "0px",
                                                          height: "183px",
                                                          transform: `rotate(${v.huongNguHoangMonth?.cordi})`,
                                                        }}
                                                      />
                                                    )}
                                                  </div>
                                                }
                                              >
                                                <Modal.Content
                                                  image
                                                  className="modal-container"
                                                >
                                                  <img
                                                    alt="arrow2"
                                                    src={arrow2}
                                                    style={{
                                                      position: "fixed",
                                                      top: "1%",
                                                      left: "48.5%",
                                                      height: "110%",
                                                      transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${v?.cordCompass})`,
                                                    }}
                                                  />
                                                  <Image
                                                    wrapped
                                                    size="massive"
                                                    src={LaBanV2}
                                                    style={{
                                                      position: "fixed",
                                                      top: "50%",
                                                      left: "50%",
                                                      transform: `translate(-50%, -50%) translate(-0px, 0px)`,
                                                    }}
                                                  />
                                                  {huongTamSatHour && (
                                                    <img
                                                      alt="arrow2"
                                                      src={tamSat}
                                                      style={{
                                                        opacity: 0.3,
                                                        position: "fixed",
                                                        top: "50%",
                                                        left: "50%",
                                                        height: "132%",
                                                        transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${huongTamSatHour})`,
                                                      }}
                                                    />
                                                  )}
                                                  {huongTamSatDay && (
                                                    <img
                                                      alt="arrow2"
                                                      src={tamSat}
                                                      style={{
                                                        opacity: 0.3,
                                                        position: "fixed",
                                                        top: "50%",
                                                        left: "50%",
                                                        height: "132%",
                                                        transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${huongTamSatDay})`,
                                                      }}
                                                    />
                                                  )}
                                                  {huongTamSatMonth && (
                                                    <img
                                                      alt="arrow2"
                                                      src={tamSat}
                                                      style={{
                                                        opacity: 0.3,
                                                        position: "fixed",
                                                        top: "50%",
                                                        left: "50%",
                                                        height: "132%",
                                                        transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${huongTamSatMonth})`,
                                                      }}
                                                    />
                                                  )}
                                                  {huongTamSatYear && (
                                                    <img
                                                      alt="arrow2"
                                                      src={tamSat}
                                                      style={{
                                                        opacity: 0.3,
                                                        position: "fixed",
                                                        top: "50%",
                                                        left: "50%",
                                                        height: "132%",
                                                        transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${huongTamSatYear})`,
                                                      }}
                                                    />
                                                  )}
                                                  <img
                                                    alt="arrow2"
                                                    src={five}
                                                    style={{
                                                      opacity: 0.5,
                                                      position: "fixed",
                                                      top: "1%",
                                                      left: "44.5%",
                                                      height: "98%",
                                                      transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${v.queNam?.coordinate})`,
                                                    }}
                                                  />
                                                  {v.huongNguHoangYear
                                                    ?.isActive && (
                                                    <img
                                                      alt="arrow2"
                                                      src={nguHoang}
                                                      style={{
                                                        opacity: 0.5,
                                                        position: "fixed",
                                                        top: "50%",
                                                        left: "50%",
                                                        height: "93%",
                                                        transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${v.huongNguHoangYear?.cordi})`,
                                                      }}
                                                    />
                                                  )}
                                                  {v.huongNguHoangMonth
                                                    ?.isActive && (
                                                    <img
                                                      alt="arrow2"
                                                      src={nguHoang}
                                                      style={{
                                                        opacity: 0.5,

                                                        position: "fixed",
                                                        top: "50%",
                                                        left: "50%",
                                                        height: "93%",
                                                        transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${v.huongNguHoangMonth?.cordi})`,
                                                      }}
                                                    />
                                                  )}
                                                  <div
                                                    style={{
                                                      position: "fixed",
                                                      right: "0px",
                                                      width: "150px",
                                                    }}
                                                  >
                                                    <p>
                                                      15 độ{" "}
                                                      <mark
                                                        style={{
                                                          backgroundColor:
                                                            "#ff00007a",
                                                        }}
                                                      >
                                                        Thái Tuế
                                                      </mark>
                                                    </p>
                                                    <br></br>
                                                    <p>
                                                      15 độ{" "}
                                                      <mark
                                                        style={{
                                                          backgroundColor:
                                                            "#0080008a",
                                                        }}
                                                      >
                                                        Tuế Phá
                                                      </mark>
                                                    </p>
                                                    <br></br>
                                                    <p>
                                                      45 độ{" "}
                                                      <mark
                                                        style={{
                                                          backgroundColor:
                                                            "#ffff00ad",
                                                        }}
                                                      >
                                                        Ngũ Hoàng
                                                      </mark>
                                                    </p>
                                                    <br></br>
                                                    <p>
                                                      90 độ{" "}
                                                      <mark
                                                        style={{
                                                          backgroundColor:
                                                            "#0000ff80",
                                                        }}
                                                      >
                                                        Tam Sát
                                                      </mark>
                                                    </p>
                                                    <br></br>
                                                  </div>
                                                </Modal.Content>
                                              </Modal>
                                            </div>
                                          </td>
                                          <td>
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <Button
                                                content="Hẹn giờ thác nước"
                                                onClick={() =>
                                                  this.onClickSetMayBom(
                                                    dataSendToServer,
                                                    hourResult
                                                  )
                                                }
                                              />
                                              <div style={{ height: "8px" }} />

                                              <Button
                                                content="Thêm giờ vào lịch google"
                                                onClick={() => {
                                                  const hourtemp = convertHour(
                                                    date.getHours()
                                                  );
                                                  const titleEvent = `Nội dung sự kiện`;
                                                  let fromTime = new Date(
                                                    date.setHours(
                                                      hourtemp.value - 1,
                                                      0
                                                    )
                                                  );
                                                  let toTime = new Date(
                                                    date.setHours(
                                                      hourtemp.value + 1,
                                                      0
                                                    )
                                                  );

                                                  if (
                                                    fromTime.getHours() === 23
                                                  ) {
                                                    toTime = new Date(
                                                      date.setDate(
                                                        date.getDate() + 1
                                                      )
                                                    );
                                                  }
                                                  window.open(
                                                    `https://calendar.google.com/calendar/u/0/r/eventedit?text=${titleEvent}&dates=${fromTime
                                                      .toISOString()
                                                      .replace(
                                                        /[-:.]/g,
                                                        ""
                                                      )}/${toTime
                                                      .toISOString()
                                                      .replace(
                                                        /[-:.]/g,
                                                        ""
                                                      )}&details=Thông+tin+chi+tiết+tại:+http://huyenkhongtamnguyen.com&location=Ho+Chi+Minh+City,+Vietnam&sf=true&output=xml`
                                                  );
                                                }}
                                              />
                                            </div>{" "}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              );
                            })}
                            <div style={{ display: "flex" }}>
                              <Modal.Actions style={{ margin: "auto" }}>
                                <Button
                                  content="Thông báo qua mail"
                                  onClick={() =>
                                    this.onClickSendMail(
                                      v,
                                      stringDate,
                                      dataSendToServer
                                    )
                                  }
                                />
                              </Modal.Actions>
                            </div>
                          </Collapse>
                        </div>
                      );
                    })}
                  </Accordion>
                </div>
              </div>
            )}
            {this.state.listHopNamSinh?.length > 0 &&
              this.state.listHopToa?.length > 0 &&
              this.state.listNamSinhAndToa?.length > 0 && (
                <div style={{ width: "100%" }}>
                  <h1
                    style={{ whiteSpace: "pre-wrap" }}
                  >{`Các ngày phù hợp với\nTọa và Năm Sinh`}</h1>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "initial",
                      padding: "12px",
                      height: "500px",
                      overflowY: "scroll",
                      marginBottom: "24px",
                    }}
                  >
                    <Accordion styled>
                      {this.state.listNamSinhAndToa.map((v, i) => {
                        const date = new Date(v.date);
                        const dayWeek = this.convertDayWeekToLetter(
                          date.getDay()
                        );
                        const dateInMonth = date.getDate();
                        const month = date.getMonth() + 1;
                        const year = date.getFullYear();
                        const stringDate = `${dayWeek}, Ngày ${dateInMonth} Tháng ${month} Năm ${year}`;
                        const dataSendToServer = `${dateInMonth}/${month}/${year}`;

                        return (
                          <div>
                            <Accordion.Title
                              active={activeResultHour === i}
                              index={i}
                              onClick={() =>
                                this.setState({ activeResultHour: i })
                              }
                            >
                              <Icon name="dropdown" />
                              {stringDate}
                            </Accordion.Title>
                            <Collapse isOpened={activeResultHour === i}>
                              <h4 style={{ textAlign: "center" }}>
                                {stringDate}
                              </h4>
                              {v?.que12Gio.map((hourResult, index) => {
                                const huongTamSatHour =
                                  this.convertTamSatSideOnCompass(
                                    hourResult.queViet.split(" ")[1]
                                  );
                                const huongTamSatDay =
                                  this.convertTamSatSideOnCompass(
                                    v.queNgay.queViet.split(" ")[1]
                                  );
                                const huongTamSatMonth =
                                  this.convertTamSatSideOnCompass(
                                    v.queThang.queViet.split(" ")[1]
                                  );
                                const huongTamSatYear =
                                  this.convertTamSatSideOnCompass(
                                    v.queNam.queViet.split(" ")[1]
                                  );
                                return (
                                  <div id={`quai-khi-quai-van-${index}`}>
                                    <div
                                      style={{ textAlign: "center" }}
                                    >{`----------------`}</div>
                                    <div style={{ textAlign: "center" }}>
                                      <table style={{ width: "100%" }}>
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{ whiteSpace: "pre-wrap" }}
                                            >{`Giờ \n${hourResult.queViet}`}</td>
                                            <td
                                              style={{ whiteSpace: "pre-wrap" }}
                                            >{`Ngày \n${v.queNgay.queViet}`}</td>
                                            <td
                                              style={{ whiteSpace: "pre-wrap" }}
                                            >{`Tháng \n${v.queThang.queViet}`}</td>
                                            <td
                                              style={{ whiteSpace: "pre-wrap" }}
                                            >{`Năm \n${v.queNam.queViet}`}</td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <TableQue
                                                data={hourResult}
                                                searchClass="each-que"
                                                center
                                              />
                                            </td>
                                            <td>
                                              <TableQue
                                                data={v.queNgay}
                                                searchClass="each-que"
                                                center
                                              />
                                            </td>
                                            <td>
                                              <TableQue
                                                data={v.queThang}
                                                searchClass="each-que"
                                                center
                                              />
                                            </td>
                                            <td>
                                              <TableQue
                                                data={v.queNam}
                                                searchClass="each-que"
                                                center
                                              />
                                            </td>
                                            <td>
                                              <div id="myImg">
                                                <Modal
                                                  trigger={
                                                    <div>
                                                      <img
                                                        alt="arrow"
                                                        src={arrow}
                                                        style={{
                                                          position: "absolute",
                                                          right: `${
                                                            91 +
                                                            rightPlusToCompass
                                                          }px`,
                                                          top: "-14px",
                                                          height: "211px",
                                                          transform: `rotate(${this.state.queToa?.cordCompass})`,
                                                        }}
                                                      />
                                                      <img
                                                        alt="LaBan"
                                                        src={LaBan}
                                                        style={{
                                                          zIndex: "999",
                                                          width: "183px",
                                                          height: "183px",
                                                        }}
                                                      ></img>
                                                      {huongTamSatHour && (
                                                        <img
                                                          alt="tamSat"
                                                          src={tamSat}
                                                          style={{
                                                            position:
                                                              "absolute",
                                                            opacity: 0.5,
                                                            right: `${
                                                              30 +
                                                              rightPlusToCompass
                                                            }px`,
                                                            top: "-36px",
                                                            height: "255px",
                                                            transform: `rotate(${huongTamSatHour})`,
                                                          }}
                                                        />
                                                      )}
                                                      {huongTamSatDay && (
                                                        <img
                                                          alt="tamSat"
                                                          src={tamSat}
                                                          style={{
                                                            position:
                                                              "absolute",
                                                            opacity: 0.5,
                                                            right: `${
                                                              30 +
                                                              rightPlusToCompass
                                                            }px`,
                                                            top: "-36px",
                                                            height: "255px",
                                                            transform: `rotate(${huongTamSatDay})`,
                                                          }}
                                                        />
                                                      )}
                                                      {huongTamSatMonth && (
                                                        <img
                                                          alt="tamSat"
                                                          src={tamSat}
                                                          style={{
                                                            position:
                                                              "absolute",

                                                            opacity: 0.5,
                                                            right: `${
                                                              30 +
                                                              rightPlusToCompass
                                                            }px`,
                                                            top: "-36px",
                                                            height: "255px",
                                                            transform: `rotate(${huongTamSatMonth})`,
                                                          }}
                                                        />
                                                      )}
                                                      {huongTamSatYear && (
                                                        <img
                                                          alt="tamSat"
                                                          src={tamSat}
                                                          style={{
                                                            position:
                                                              "absolute",

                                                            opacity: 0.5,
                                                            right: `${
                                                              30 +
                                                              rightPlusToCompass
                                                            }px`,
                                                            top: "-36px",
                                                            height: "255px",
                                                            transform: `rotate(${huongTamSatYear})`,
                                                          }}
                                                        />
                                                      )}
                                                      <img
                                                        alt="tamSat"
                                                        src={five}
                                                        style={{
                                                          position: "absolute",

                                                          opacity: 0.5,
                                                          right: `${
                                                            83 +
                                                            rightPlusToCompass
                                                          }px`,
                                                          top: "-2px",
                                                          height: "187px",
                                                          transform: `rotate(${v.chiYear?.coordinate})`,
                                                        }}
                                                      />
                                                      {v.huongNguHoangYear
                                                        ?.isActive && (
                                                        <img
                                                          alt="nguHoang"
                                                          src={nguHoang}
                                                          style={{
                                                            position:
                                                              "absolute",

                                                            opacity: 0.5,
                                                            right: `${
                                                              60 +
                                                              rightPlusToCompass
                                                            }px`,
                                                            top: "0px",
                                                            height: "183px",
                                                            transform: `rotate(${v.huongNguHoangYear?.cordi})`,
                                                          }}
                                                        />
                                                      )}
                                                      {v.huongNguHoangMonth
                                                        ?.isActive && (
                                                        <img
                                                          alt="nguHoang"
                                                          src={nguHoang}
                                                          style={{
                                                            position:
                                                              "absolute",

                                                            opacity: 0.5,
                                                            right: `${
                                                              60 +
                                                              rightPlusToCompass
                                                            }px`,
                                                            top: "0px",
                                                            height: "183px",
                                                            transform: `rotate(${v.huongNguHoangMonth?.cordi})`,
                                                          }}
                                                        />
                                                      )}
                                                    </div>
                                                  }
                                                >
                                                  <Modal.Content
                                                    image
                                                    className="modal-container"
                                                  >
                                                    <img
                                                      alt="arrow2"
                                                      src={arrow2}
                                                      style={{
                                                        position: "fixed",
                                                        top: "1%",
                                                        left: "48.5%",
                                                        height: "110%",
                                                        transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${v?.cordCompass})`,
                                                      }}
                                                    />
                                                    <Image
                                                      wrapped
                                                      size="massive"
                                                      src={LaBanV2}
                                                      style={{
                                                        position: "fixed",
                                                        top: "50%",
                                                        left: "50%",
                                                        transform: `translate(-50%, -50%) translate(-0px, 0px)`,
                                                      }}
                                                    />
                                                    {huongTamSatHour && (
                                                      <img
                                                        alt="arrow2"
                                                        src={tamSat}
                                                        style={{
                                                          opacity: 0.3,
                                                          position: "fixed",
                                                          top: "50%",
                                                          left: "50%",
                                                          height: "132%",
                                                          transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${huongTamSatHour})`,
                                                        }}
                                                      />
                                                    )}
                                                    {huongTamSatDay && (
                                                      <img
                                                        alt="arrow2"
                                                        src={tamSat}
                                                        style={{
                                                          opacity: 0.3,
                                                          position: "fixed",
                                                          top: "50%",
                                                          left: "50%",
                                                          height: "132%",
                                                          transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${huongTamSatDay})`,
                                                        }}
                                                      />
                                                    )}
                                                    {huongTamSatMonth && (
                                                      <img
                                                        alt="arrow2"
                                                        src={tamSat}
                                                        style={{
                                                          opacity: 0.3,
                                                          position: "fixed",
                                                          top: "50%",
                                                          left: "50%",
                                                          height: "132%",
                                                          transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${huongTamSatMonth})`,
                                                        }}
                                                      />
                                                    )}
                                                    {huongTamSatYear && (
                                                      <img
                                                        alt="arrow2"
                                                        src={tamSat}
                                                        style={{
                                                          opacity: 0.3,
                                                          position: "fixed",
                                                          top: "50%",
                                                          left: "50%",
                                                          height: "132%",
                                                          transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${huongTamSatYear})`,
                                                        }}
                                                      />
                                                    )}
                                                    <img
                                                      alt="arrow2"
                                                      src={five}
                                                      style={{
                                                        opacity: 0.5,
                                                        position: "fixed",
                                                        top: "1%",
                                                        left: "44.5%",
                                                        height: "98%",
                                                        transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${v.queNam?.coordinate})`,
                                                      }}
                                                    />
                                                    {v.huongNguHoangYear
                                                      ?.isActive && (
                                                      <img
                                                        alt="arrow2"
                                                        src={nguHoang}
                                                        style={{
                                                          opacity: 0.5,
                                                          position: "fixed",
                                                          top: "50%",
                                                          left: "50%",
                                                          height: "93%",
                                                          transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${v.huongNguHoangYear?.cordi})`,
                                                        }}
                                                      />
                                                    )}
                                                    {v.huongNguHoangMonth
                                                      ?.isActive && (
                                                      <img
                                                        alt="arrow2"
                                                        src={nguHoang}
                                                        style={{
                                                          opacity: 0.5,

                                                          position: "fixed",
                                                          top: "50%",
                                                          left: "50%",
                                                          height: "93%",
                                                          transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${v.huongNguHoangMonth?.cordi})`,
                                                        }}
                                                      />
                                                    )}
                                                    <div
                                                      style={{
                                                        position: "fixed",
                                                        right: "0px",
                                                        width: "150px",
                                                      }}
                                                    >
                                                      <p>
                                                        15 độ{" "}
                                                        <mark
                                                          style={{
                                                            backgroundColor:
                                                              "#ff00007a",
                                                          }}
                                                        >
                                                          Thái Tuế
                                                        </mark>
                                                      </p>
                                                      <br></br>
                                                      <p>
                                                        15 độ{" "}
                                                        <mark
                                                          style={{
                                                            backgroundColor:
                                                              "#0080008a",
                                                          }}
                                                        >
                                                          Tuế Phá
                                                        </mark>
                                                      </p>
                                                      <br></br>
                                                      <p>
                                                        45 độ{" "}
                                                        <mark
                                                          style={{
                                                            backgroundColor:
                                                              "#ffff00ad",
                                                          }}
                                                        >
                                                          Ngũ Hoàng
                                                        </mark>
                                                      </p>
                                                      <br></br>
                                                      <p>
                                                        90 độ{" "}
                                                        <mark
                                                          style={{
                                                            backgroundColor:
                                                              "#0000ff80",
                                                          }}
                                                        >
                                                          Tam Sát
                                                        </mark>
                                                      </p>
                                                      <br></br>
                                                    </div>
                                                  </Modal.Content>
                                                </Modal>
                                              </div>
                                            </td>
                                            <td>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                }}
                                              >
                                                <Button
                                                  content="Hẹn giờ thác nước"
                                                  onClick={() =>
                                                    this.onClickSetMayBom(
                                                      dataSendToServer,
                                                      hourResult
                                                    )
                                                  }
                                                />
                                                <div
                                                  style={{ height: "8px" }}
                                                />

                                                <Button
                                                  content="Thêm giờ vào lịch google"
                                                  onClick={() => {
                                                    const hourtemp =
                                                      convertHour(
                                                        date.getHours()
                                                      );
                                                    const titleEvent = `Nội dung sự kiện`;
                                                    let fromTime = new Date(
                                                      date.setHours(
                                                        hourtemp.value - 1,
                                                        0
                                                      )
                                                    );
                                                    let toTime = new Date(
                                                      date.setHours(
                                                        hourtemp.value + 1,
                                                        0
                                                      )
                                                    );

                                                    if (
                                                      fromTime.getHours() === 23
                                                    ) {
                                                      toTime = new Date(
                                                        date.setDate(
                                                          date.getDate() + 1
                                                        )
                                                      );
                                                    }
                                                    window.open(
                                                      `https://calendar.google.com/calendar/u/0/r/eventedit?text=${titleEvent}&dates=${fromTime
                                                        .toISOString()
                                                        .replace(
                                                          /[-:.]/g,
                                                          ""
                                                        )}/${toTime
                                                        .toISOString()
                                                        .replace(
                                                          /[-:.]/g,
                                                          ""
                                                        )}&details=Thông+tin+chi+tiết+tại:+http://huyenkhongtamnguyen.com&location=Ho+Chi+Minh+City,+Vietnam&sf=true&output=xml`
                                                    );
                                                  }}
                                                />
                                              </div>{" "}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                );
                              })}
                              <div style={{ display: "flex" }}>
                                <Modal.Actions style={{ margin: "auto" }}>
                                  <Button
                                    content="Thông báo qua mail"
                                    onClick={() =>
                                      this.onClickSendMail(
                                        v,
                                        stringDate,
                                        dataSendToServer
                                      )
                                    }
                                  />
                                </Modal.Actions>
                              </div>
                            </Collapse>
                          </div>
                        );
                      })}
                    </Accordion>
                  </div>
                </div>
              )}
          </div>
        )}
      </div>
    );
  }
}

export default SearchQuai;
