import vi from 'date-fns/locale/vi';
import * as firebase from 'firebase';

import md5 from 'md5';
import React from 'react';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import GoogleLogin, { GoogleLogout } from 'react-google-login';
import { withRouter } from 'react-router-dom';
import { Button, Dropdown, Image, Input, Modal } from 'semantic-ui-react';
import '../App.css';
import globalUtils from '../globalUntils.js';
import thongTinKhoaHoc from '../image/infoSubject.jpg';
import CHUONGTRINHGIANGDAY01 from '../image/KhoaHoc/CHUONGTRINHGIANGDAY-01.png';
import CHUONGTRINHGIANGDAY02 from '../image/KhoaHoc/CHUONGTRINHGIANGDAY-02.png';
import CHUONGTRINHGIANGDAY03 from '../image/KhoaHoc/CHUONGTRINHGIANGDAY-03.png';
import CHUONGTRINHGIANGDAY04 from '../image/KhoaHoc/CHUONGTRINHGIANGDAY-04.png';
import CHUONGTRINHGIANGDAY05 from '../image/KhoaHoc/CHUONGTRINHGIANGDAY-05.png';
import CHUONGTRINHGIANGDAY06 from '../image/KhoaHoc/CHUONGTRINHGIANGDAY-06.png';
import CHUONGTRINHGIANGDAY07 from '../image/KhoaHoc/CHUONGTRINHGIANGDAY-07.png';
import CHUONGTRINHGIANGDAY08 from '../image/KhoaHoc/CHUONGTRINHGIANGDAY-08.png';
import CHUONGTRINHGIANGDAY09 from '../image/KhoaHoc/CHUONGTRINHGIANGDAY-09.png';
import CHUONGTRINHGIANGDAY10 from '../image/KhoaHoc/CHUONGTRINHGIANGDAY-10.png';
import CHUONGTRINHGIANGDAY11 from '../image/KhoaHoc/CHUONGTRINHGIANGDAY-11.png';
import LogoChu from '../image/LogoChu.svg';
import Logo from '../logo.png';

const mdfive = md5('HuyenKhongTamNguyenFengShuiWebSiteHitekTuongMinh');
registerLocale('vi', vi);

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderItemKhoaHoc(image) {
    return (
      <div style={{ margin: 'auto' }}>
        <img src={image} alt="Học viện phong thủy" width="45px" height="56px" />
      </div>
    );
  }
  render() {
    return (
      <div className="center-header">
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '10px' }}>
            <img alt="logo" src={Logo} height="66px" width="66px" />
          </div>
          <div style={{}}>
            <img alt="Logo Chữ" src={LogoChu} />

            <p style={{ textAlign: 'left' }}>www.huyenkhongtamnguyen.com</p>
          </div>
          <div style={{ display: 'flex', width: '700px' }}>
            {this.renderItemKhoaHoc(CHUONGTRINHGIANGDAY01)}
            {this.renderItemKhoaHoc(CHUONGTRINHGIANGDAY02)}
            {this.renderItemKhoaHoc(CHUONGTRINHGIANGDAY03)}
            {this.renderItemKhoaHoc(CHUONGTRINHGIANGDAY04)}
            {this.renderItemKhoaHoc(CHUONGTRINHGIANGDAY05)}
            {this.renderItemKhoaHoc(CHUONGTRINHGIANGDAY06)}
            {this.renderItemKhoaHoc(CHUONGTRINHGIANGDAY07)}
            {this.renderItemKhoaHoc(CHUONGTRINHGIANGDAY08)}
            {this.renderItemKhoaHoc(CHUONGTRINHGIANGDAY09)}
            {this.renderItemKhoaHoc(CHUONGTRINHGIANGDAY10)}
            {this.renderItemKhoaHoc(CHUONGTRINHGIANGDAY11)}
            {/* <img style={{ margin: 'auto' }} src={CHUONGTRINHGIANGDAY01} alt="Học viện phong thủy" width="45px" height="56px" />
            <img style={{ margin: 'auto' }} src={CHUONGTRINHGIANGDAY02} alt="Học viện tam hợp" width="45px" height="56px" />
            <img style={{ margin: 'auto' }} src={CHUONGTRINHGIANGDAY03} alt="Học viện lục pháp" width="45px" height="56px" />
            <img style={{ margin: 'auto' }} src={CHUONGTRINHGIANGDAY04} alt="Học viện đại quái" width="45px" height="56px" />
            <img style={{ margin: 'auto' }} src={CHUONGTRINHGIANGDAY05} alt="Học viện phi tinh" width="45px" height="56px" />
            <img style={{ margin: 'auto' }} src={CHUONGTRINHGIANGDAY06} alt="Học viện nhân tướng" width="45px" height="56px" />
            <img style={{ margin: 'auto' }} src={CHUONGTRINHGIANGDAY07} alt="Học viện kinh dịch" width="45px" height="56px" />
            <img style={{ margin: 'auto' }} src={CHUONGTRINHGIANGDAY08} alt="Học viện chiêm tinh" width="45px" height="56px" />
            <img style={{ margin: 'auto' }} src={CHUONGTRINHGIANGDAY09} alt="Học viện kỳ môn" width="45px" height="56px" />
            <img style={{ margin: 'auto' }} src={CHUONGTRINHGIANGDAY10} alt="Học viện trạch nhật" width="45px" height="56px" />
            <img style={{ margin: 'auto' }} src={CHUONGTRINHGIANGDAY11} alt="Học viện bát tự" width="45px" height="56px" /> */}
          </div>
        </div>
        {/* <table className="center-header">
          <tbody>
            <tr className="to-hide-print">
              <td>
                <div>
                  <table>
                    <tbody>
                      <tr height="70px">
                        <td width="10%">
                          <img alt="logo" src={Logo} height="66px" width="66px" />
                        </td>
                        <td width="25%">
                          <div style={{ float: 'left' }}>
                            <img alt="Logo Chữ" src={LogoChu} />

                            <p style={{ textAlign: 'left' }}>www.huyenkhongtamnguyen.com</p>
                          </div>
                        </td>
                        <td>
                          <div style={{ display: 'flex' }}>
                            <img style={{ margin: 'auto' }} src={CHUONGTRINHGIANGDAY01} alt="Học viện phong thủy" width="45px" height="56px" />

                            <img style={{ margin: 'auto' }} src={CHUONGTRINHGIANGDAY02} alt="Học viện tam hợp" width="45px" height="56px" />

                            <img style={{ margin: 'auto' }} src={CHUONGTRINHGIANGDAY03} alt="Học viện lục pháp" width="45px" height="56px" />

                            <img style={{ margin: 'auto' }} src={CHUONGTRINHGIANGDAY04} alt="Học viện đại quái" width="45px" height="56px" />

                            <img style={{ margin: 'auto' }} src={CHUONGTRINHGIANGDAY05} alt="Học viện phi tinh" width="45px" height="56px" />

                            <img style={{ margin: 'auto' }} src={CHUONGTRINHGIANGDAY06} alt="Học viện nhân tướng" width="45px" height="56px" />

                            <img style={{ margin: 'auto' }} src={CHUONGTRINHGIANGDAY07} alt="Học viện kinh dịch" width="45px" height="56px" />

                            <img style={{ margin: 'auto' }} src={CHUONGTRINHGIANGDAY08} alt="Học viện chiêm tinh" width="45px" height="56px" />

                            <img style={{ margin: 'auto' }} src={CHUONGTRINHGIANGDAY09} alt="Học viện kỳ môn" width="45px" height="56px" />

                            <img style={{ margin: 'auto' }} src={CHUONGTRINHGIANGDAY10} alt="Học viện trạch nhật" width="45px" height="56px" />

                            <img style={{ margin: 'auto' }} src={CHUONGTRINHGIANGDAY11} alt="Học viện bát tự" width="45px" height="56px" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table> */}
      </div>
    );
  }
}

export default withRouter(Header);
